import React from 'react';
import Api from '../../../api';
import { Redirect, Link } from 'react-router-dom';
import {
  Button,
  Card,
  Confirm,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Label,
  List,
  Modal,
  Segment,
  Message,
} from 'semantic-ui-react';
import Uploader from '@/common/uploader';
import { dateToPacific } from '@/utils/dateTime';
import { InputField, TextareaField, FileField } from '../../../common/Form';
import UserListing from '../../../common/user-selection/user-listing';
import { isEmpty, update } from 'lodash';
import { removeEmptyKeys } from '../../../common/utils/utils';
import validate from 'validate.js';
import { default as Config, default as Constants } from '@/config/constants';
import { authoritySelector, userIdSelector } from '../../../common/selectors';
import { connect } from 'react-redux';
import moment from 'moment';

const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;

class ProjectForm extends React.Component {
  VALIDATION_CONFIG = {
    name: {
      presence: true,
    },
    adminName: {
      presence: false,
    },
    description: {
      length: { maximum: 500 },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      projectId: props.data.projectId,
      name: props.data.name,
      description: props.data.description,
      adminId: props.data.adminId,
      adminName: props.data.adminName,
      deleteWarning: false,
      validationMsg: {},
      fileuuid: '',
    };
  }

  showDeleteWarning = () => this.setState({ deleteWarning: true });

  onWarningConfirm = (event, { itemid }) => {
    event.preventDefault();
    this.setState({ deleteWarning: false });
    this.props
      .deleteCallback(itemid)
      .then(() => {
        this.setState({ redirectToProjects: true });
      })
      .catch((err) => {
        this.setState({
          showError: true,
          errorMsg: 'Something went wrong while deleting the project',
        });
      });
  };
  onWarningCancel = () => this.setState({ deleteWarning: false });

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  validate = () => {
    let result = validate(removeEmptyKeys(this.state), this.VALIDATION_CONFIG);
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.validate()) return;
    const { name, description, adminId, fileuuid } = this.state;
    let id = this.state.projectId ? this.state.projectId : 'new';
    let options = {
      name,
      description,
      adminId,
      fileuuid,
    };
    const params = { params: { id: id } };
    Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_DETAIL, options, params)
      .then((resp) => {
        this.setState({ redirectToViewProject: true, id: resp.data.id });
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: error.response.data.message,
        });
      });
  };

  componentDidMount() {}

  onSelectUser = () => {
    this.setState({ showUsersListing: true });
  };

  onDismissUserListing = () => {
    this.setState({ showUsersListing: false });
  };

  onSaveUserListing = (users) => {
    this.setState(
      {
        showUsersListing: false,
        adminId: users[0]['id'],
        adminName: users[0]['username'],
      },
      this.notifyLatestContent
    );
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  handleFileChange = (eventData) => {
    // const { data } = this.state;
    const { fileuuid } = eventData;
    // const { id } = this.props.match.params;
    if (fileuuid) {
      this.setState({
        fileuuid,
      });
    }
  };
  componentDidUpdate() {}

  render() {
    const {
      adminId,
      adminName,
      description,
      errorMsg,
      name,
      projectId,
      showError,
      validationMsg,
      fileuuid,
    } = this.state;

    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" />
                  Project Form
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={16}
                computer={8}
                largeScreen={8}
              ></Grid.Column>
            </Grid.Row>
          </Grid>
          <Form>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={11}
                  largeScreen={11}
                >
                  {projectId ? (
                    <Form.Input label="Project Name">
                      <Label>{name}</Label>
                    </Form.Input>
                  ) : (
                    <InputField
                      fluid
                      isRequired
                      label="Project Name"
                      name="name"
                      value={name}
                      errorMsg={validationMsg.name}
                      isError={validationMsg.name}
                      onChange={this.handleChange}
                      maxlength="32"
                      // width="12"
                    />
                  )}
                  <TextareaField
                    label="Description"
                    name="description"
                    value={description}
                    onChange={this.handleChange}
                    errorMsg={validationMsg.description}
                    isError={validationMsg.description}
                    maxLength="500"
                    // width="12"
                  />
                  <Divider horizontal className="font-weight-light mb-2-im">
                    Max. Length: 500 characters
                  </Divider>
                  <div className="m--display-none">
                    <InputField
                      fluid
                      type="hidden"
                      value={adminId}
                      name="adminId"
                      onChange={this.handleChange}
                    />
                  </div>
                  <FileField
                    label="Upload Project Profile Picture"
                    errorMsg="File can't be Empty"
                    isError={validationMsg.fileuuid}
                    name="file"
                    //accept="application/pdf"
                    // type="application/pdf"
                    onChange={Uploader(this.handleFileChange)}
                    value={fileuuid}
                    multipleSelection={false}
                  />
                  <InputField
                    fluid
                    label="Project Admin (if left blank, project creator will be the default admin)"
                    errorMsg="Please select a project admin"
                    isError={validationMsg.adminName}
                    value={adminName}
                    name="adminName"
                    // isRequired
                    disabled="disabled"
                  />
                  {
                    <div className="common-filter-wrapper ui form m--align-right">
                      <button
                        className="button-basic"
                        onClick={this.onSelectUser}
                      >
                        {' '}
                        Select Project Admin
                      </button>
                      <Divider horizontal></Divider>
                    </div>
                  }
                </Grid.Column>

                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={5}
                  largeScreen={5}
                >
                  <Card className="m--float-right ">
                    {this.state.projectId && (
                      <Card.Content
                        className="dark-blue-background-shade"
                        header="Update Information"
                      />
                    )}
                    <Card.Content>
                      {this.state.projectId ? (
                        <React.Fragment>
                          <Message style={{ fontSize: '11px' }}>
                            <Message.Content style={{ marginBottom: '10px' }}>
                              <Message.Header>Created By: </Message.Header>
                              <div style={{ marginLeft: '5px' }}>
                                {this.props.data.createdBy}
                              </div>
                            </Message.Content>
                            <Message.Content style={{ marginBottom: '10px' }}>
                              <Message.Header>Created On: </Message.Header>
                              <div style={{ marginLeft: '5px' }}>
                                <>
                                  {dateToPacific(
                                    this.props.data.creationDate,
                                    'MM/DD/YYYY hh:mm a'
                                  )}
                                </>
                              </div>
                            </Message.Content>
                            <Message.Content style={{ marginBottom: '10px' }}>
                              <Message.Header>Modified By: </Message.Header>
                              <div style={{ marginLeft: '5px' }}>
                                {this.props.data.modifiedBy}{' '}
                              </div>
                            </Message.Content>
                            <Message.Content style={{ marginBottom: '10px' }}>
                              <Message.Header>
                                Modification Date:{' '}
                              </Message.Header>
                              <div style={{ marginLeft: '5px' }}>
                                <>
                                  {dateToPacific(
                                    this.props.data.modificationDate,
                                    'MM/DD/YYYY hh:mm a'
                                  )}
                                </>
                              </div>
                            </Message.Content>
                          </Message>
                        </React.Fragment>
                      ) : (
                        <Card.Meta>
                          <b>Note: </b>Once you create a project you can then go
                          ahead and add members, documents, and posts from the
                          project dashboard view.
                        </Card.Meta>
                      )}
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                >
                  <button
                    type="button"
                    className="button-primary"
                    onClick={this.handleSubmit}
                  >
                    {' '}
                    Save
                  </button>
                  {this.props.deleteCallback && (
                    <button
                      className="button-danger"
                      onClick={this.showDeleteWarning}
                    >
                      {' '}
                      Delete
                    </button>
                  )}
                  <Confirm
                    open={this.state.deleteWarning}
                    onCancel={this.onWarningCancel}
                    onConfirm={this.onWarningConfirm}
                    itemid={projectId}
                    content="Are you sure you want to delete this project?"
                  />
                  <Link to={'/projects'}>
                    <button className="button-basic">Close</button>
                  </Link>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        {this.state.showUsersListing && (
          <UserListing
            selectionType="single-select"
            show={this.state.showUsersListing}
            onDismiss={this.onDismissUserListing}
            onSave={this.onSaveUserListing}
          />
        )}
        {this.state.redirectToViewProject ? (
          <Redirect to={{ pathname: '/projects/' + this.state.id }} />
        ) : (
          ''
        )}
        {this.state.redirectToProjects ? (
          <Redirect to={{ pathname: '/projects' }} />
        ) : (
          ''
        )}
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: 'warningDismiss', content: 'OK', primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
  authority: authoritySelector(state),
  // baseUrl: baseUrl,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mappedProjectForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectForm);

export default mappedProjectForm;
