import React from "react";
import { Container, Segment } from "semantic-ui-react";
import { filter } from "lodash";
import {
  parameterStringify,
  removeEmptyKeys
} from "../../../common/utils/utils";
import Wall from "../../../common/wall";
import urls from "../../../config/constants";
import Constants from "../../../config/constants";
import Api from "../../../api";

class RecentPosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: props.projectid,
      posts: [],
      urlParams: {
        page: 0,
        filter: ""
      }
    };
  }

  componentDidMount() {
    this.loadWallPosts();
  }

  filterPosts = apiResponse => {
    const filteredPost = filter(
      apiResponse.content,
      el => el.status === "CONFIRMED"
    );
    apiResponse.content = filteredPost;
    return apiResponse;
  };

  loadWallPosts = () => {
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_PROJECT_POSTS + "?id=" + this.state.projectId)
      .then(resp => {
        this.setState({ posts: resp.data });
      })
      .catch(err => {
        this.setState({
          errorMsg: "Something went wrong while deleting the project",
          showError: true
        });
      });
  };

  addPost = args => {
    const { postText } = args;
    const options = removeEmptyKeys({
      projectId: this.state.projectId,
      post: postText
    });
    Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_POST_CREATE, options)
      .then(resp => {
        this.loadWallPosts();
      })
      .catch(error => {
        this.setState({
          errors: {
            submit: "Failed to add post"
          }
        });
      });
  };

  deletePost = id => {
    const { URL_PROJECT_POST_CREATE } = urls.App;
    let parameterizedUrl =
      URL_PROJECT_POST_CREATE + "?" + parameterStringify({ postId: id });

    Api.AxiosInstance.getInstance()
      .delete(parameterizedUrl)
      .then(resp => {
        this.loadWallPosts();
      })
      .catch(err => {
        this.setState({
          errorMsg: "Something went wrong while approving the posts.",
          showError: true
        });
      });
  };

  render() {
    const { posts } = this.state;
    const { content } = posts;
    return (
      <Segment loading={false} className="m--no-pad-left-im" >
        
          <Wall
            posts={content}
            postFunction={this.addPost}
            deleteFunction={this.deletePost}
          />
      </Segment>
    );
  }
}

export default RecentPosts;
