import React, { useEffect } from "react";
import Api from "../../api";
import {useDispatch} from "react-redux";
import LocalStore from "../../store/persistence/local-storage";
import { googleLogout } from '@react-oauth/google';

const LogOut = () => {
  const dispatch = useDispatch();

  //this flow cater when user on zendesk logout
  // it will redirect to logout url of groq support
  // and clears the cached values to logout on groq support too.
  useEffect(() => {
    LocalStore.removeValue("loggedInToHelpDesk");
    dispatch(Api.User.logout());
    dispatch(googleLogout());
    window.location = `${window.location.origin}/#/login`
  }, [dispatch]);
  return <div> </div>
}

export default {
  name: "Logout",
  path: "/logout",
  component: LogOut,
  navbarVisibility: false,
};
