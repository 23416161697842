import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Divider,
  Dropdown,
  Header,
  Input,
  Item,
  Message,
  Modal,
  Pagination,
  Segment,
  Grid,
} from 'semantic-ui-react';
import update from 'immutability-helper';
import { activePageToPageIndex, parameterStringify } from '../../../../common/utils/utils';
import { UserListing } from "../../../../common/user-selection";
import Friend from "./friend";
import Api from "../../../../api";
import urls from '../../../../config/constants';

class FriendListingWithSearch extends React.Component {

  Constants = {
    SEARCH_ON_STATUS: Object.freeze([
      { key: 'all-search', text: 'All', value: '' },
      { key: 'confirm-search', text: 'Confirm Friends', value: 'CONFIRMED' },
      { key: 'pending-search', text: 'Pending Friends', value: 'PENDING' },
    ]),
  };

  componentDidMount() {
    this.fetchFriends();
  }

  constructor(props) {
    super(props);
    this.state = {
      errorMsg: '',
      friendsList: { content: [] },
      showError: false,
      showUserListing: false,
      urlParams: {
        filter: '',
        status: '',
        page: 0,
      }
    };
  }

  fetchFriends = () => {
    const { fetchApi } = this.props;
    const { urlParams } = this.state;
    const parameterizedUrl = `${fetchApi}?${parameterStringify(urlParams)}`;
    Api.AxiosInstance.getInstance().get(parameterizedUrl)
    .then(resp => {
      this.setState({ friendsList: resp.data });
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while loading your friends data.", showError: true })
    });
  };

  onPageChange = (e, { activePage }) => {
    const { urlParams } = this.state;
    const curPage = activePageToPageIndex(activePage);
    this.setState({ urlParams: update(urlParams, { page: { $set: curPage }}) }, this.fetchFriends);
  };

  onSearchType = (data) => {
    const { urlParams } = this.state;
    this.setState({ urlParams: update(urlParams, { filter: { $set: data.target.value }}) });
  };

  onSearchStatusChange = (event, data) => {
    const { urlParams } = this.state;
    this.setState({ urlParams: update(urlParams, { status: { $set: data.value }}) });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  onAddFriend = () => {
    this.setState({ showUserListing: true });
  };

  onDismissFriendsListing = () => {
    this.setState({ showUserListing: false });
  }

  addFriends = (selectedUsers) => {
    this.onDismissFriendsListing();
    const { URL_PROFILE_FRIEND_ADD } = urls.App;
    selectedUsers.map(user => {
      Api.AxiosInstance.getInstance()
      .post(URL_PROFILE_FRIEND_ADD, { id: user.id })
      .then(resp => {
        this.fetchFriends();
      }).catch(err => {
        console.error(err);
      });
    });
  };

  render() {
    const { SEARCH_ON_STATUS } = this.Constants;
    const { errorMsg, friendsList, showError, showUserListing } = this.state;
    const { title, acceptApi, deleteApi, listingType } = this.props;

    return(
      <React.Fragment>
        <Segment className="base-segment-wrapper">


        <Grid stackable>
              <Grid.Row >
                <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                  <div className="header">
                    <div className="side-marker" />
                    { title }</div>
              </Grid.Column>
{
                        listingType === "SENT" &&
                <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                  <div className="control-action-wrapper">
                   
                      
                        <button className="button-basic" onClick={ this.onAddFriend }>Add Friend</button>
                     

                    </div>
                  
                </Grid.Column> }

              </Grid.Row>
             </Grid >
          <Grid>

        
              <Grid.Row className="common-filter-wrapper ui form">
                <Grid.Column
                  mobile={16}
                  tablet={5}
                  computer={5}
                  largeScreen={5}
                >
                  <div class="field">
                    <div class="ui input">
 <input placeholder="Search" className="search-bar" onChange={this.onSearchType}>
          </input>
                    </div>
                  </div>

                     </Grid.Column>
 {
                listingType === "SENT" &&
                        <Grid.Column
                  mobile={16}
                  tablet={5}
                  computer={5}
                  largeScreen={5}
                >
                  <div class="field">
                    <div class="ui input">

                <Dropdown
                  options={ SEARCH_ON_STATUS }
                  selection
                  defaultValue=''
                  placeholder = 'All'
                  onChange={ this.onSearchStatusChange }
                />

                
             
                    </div>
                  </div>

                     </Grid.Column> }

                        <Grid.Column
                  mobile={16}
                  tablet={5}
                  computer={5}
                  largeScreen={5}
                >
                  <div class="field">
                    <div class="ui input">
<button className="button-primary m--no-margin-left-im" onClick={ this.fetchFriends } >Search</button>
                    </div>
                  </div>

                     </Grid.Column>

 </Grid.Row>
        
          
      
          <Divider />
          <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} >
          {
            friendsList.content.length === 0 &&
            <Message content='You have no friends to display.'/>
          }
          {
            friendsList.content.length > 0 &&
            <Item.Group relaxed unstackable>
              {
                friendsList.content.map( friend =>
                  <Friend
                    data={ friend }
                    acceptApi={ acceptApi }
                    deleteApi={ deleteApi }
                    refreshFunction={ this.fetchFriends }
                  />
                )
              }
            </Item.Group>
          }
          </Grid.Column>
          </Grid.Row>
          {
            friendsList.content.length !== 0 &&
            <Pagination
              activePage={ friendsList.number + 1 }
              onPageChange={ this.onPageChange }
              totalPages={ friendsList.totalPages ? friendsList.totalPages : 1 }
            />
          }
        </Grid>
        </Segment>
        <Modal open={ showError }
          onClose={ () => { this.closeErrorPopup(); } }
          centered={ true }
          size="tiny"
          content={ errorMsg }
          actions={[{ key: 'warningDismiss', content: 'OK', primary: true }]}
        >
        </Modal>
        <UserListing
        show={ showUserListing }
        onDismiss={ this.onDismissFriendsListing }
        onSave={ this.addFriends } />
      </React.Fragment>
    );
  }
}

FriendListingWithSearch.propTypes = {
  /** API URL this component will use to accept a friend request */
  acceptApi: PropTypes.string,
  /** API URL this component will use to delete a friend or friend-request */
  deleteApi: PropTypes.string,
  /** API URL this component will use to fetch friend list from server */
  fetchApi: PropTypes.string,
  /** sent = view friends page, received = pending requests page */
  listingType: PropTypes.oneOf(['RECEIVED', 'SENT']),
  /** Text which will be displayed at the top of the page. */
  title: PropTypes.string.isRequired,
};

export default FriendListingWithSearch;
