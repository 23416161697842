import Axios from "axios";
import Constants from "../../config/constants"
import Actions from "../../store/actions"
import LocalStore from "../../store/persistence/local-storage";

function loadApplicationConfig(token) {

	return (dispatch, getState) => {

		const options = {};

		if (!!token) {
			options.headers = { Authorization: token };
		}

		dispatch({
			type: Actions.Config.CONFIG_LOADING,
		})

		Axios.get(Constants.App.API_URL + "config", options)
		.then((resp) => { 
			
			const { allowedIdleTime } = resp.data;
			const lastActiveTime = LocalStore.getValue('activity-time');
			if (lastActiveTime && ((lastActiveTime + allowedIdleTime) < ((new Date().getTime())))) {
				LocalStore.setValue('activity-time', null);
				dispatch({
					type: Actions.USER.USER_LOGOUT,
					payload: {}
				});
				dispatch({
					type: Actions.Token.CLEAR_ALL,
				});
			} else {
				dispatch({
					type: Actions.Config.CONFIG_LOADING_SUCCESS,
					payload: resp.data
				});
			}

		}).catch((e) => {
			dispatch({
				type: Actions.Config.CONFIG_LOADING_FAILURE,
				payload: e.response.data
			})
		})
	}
}

export default {loadApplicationConfig}
