import React, { Component } from "react";
import {
  Divider,
  Form,
  Message,
  Segment,
  Grid,
  Modal,
} from "semantic-ui-react";
import {
  CountryCode,
  InputField,
  DateField,
  FileField,
  DropdownField,
  PhoneField,
} from "../../../common/Form";
import update from "immutability-helper";
import {
  manualSignatureValidations,
  digitalSignatureValidations,
} from "./../validations/sign-nda";
import moment from "moment";
import Axios from "axios";
import urls from "@/config/constants";
import validate from "validate.js";
import { removeEmptyKeys } from "@/common/utils/utils";
import Uploader from "@/common/uploader";
import { configSelector } from "@/common/selectors";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Api from "@/api";
import DatePickerWithTimezone from "../../../utils/DatePickerWithTimeZone";

const signatureOptions = [
  { key: "MANUAL", value: "MANUAL", text: "Manual" },
  { key: "DIGITAL", value: "DIGITAL", text: "Digital" },
];

class CreateNDA extends Component {
  isSuccess = false;
  constructor(props) {
    super(props);
    this.state = {
      validationMsg: {},
      redirectToDashboard: false,
      signDate: new Date(),
      code: "+1",
      DATE_FORMAT: "YYYY-MM-DD",
      apiError: null,
      loading: false,
      uploadProgress: 0,
      data: {
        signType: "",
        signDate: new Date(),
        signature: "",
        signerName: "",
        signerTitle: "",
        signerEmail: "",
        signerPhone: "",
        fileuuid: "",
        fileurl: "",
        filename: "",
      },
      validationMsg: {},
      showMsg: false,
      msg: "",
      isError: false,
      loading: false,
      filename: "",
      fileurl: "",
      redirectToLogin: false,
    };
  }
  componentDidMount() {
    //TODO : fetch file with name backend ...
    this.fetchData();
  }
  countryCodeChange = (event, data) => {
    this.setState({ code: data.value });
  };

  isNumeric(value) {
    return /^\d+$/.test(value);
  }

  onPhoneChange = (event, eventData) => {
    let cond = event.target.value;
    const { data } = this.state;
    var phone = "";
    for (var i = 0; i < cond.length; i++) {
      if (this.isNumeric(cond.charAt(i))) {
        phone = phone + cond.charAt(i);
      }
    }
    if (phone.length < 3) {
      this.setState({
        data: update(data, { [eventData.name]: { $set: phone } }),
      });
    } else if (
      phone.length == 3 &&
      cond.charAt(cond.length - 1) == ")" &&
      cond.length == 5
    ) {
      this.setState({
        data: update(data, {
          [eventData.name]: { $set: phone.substring(0, 2) },
        }),
      });
    } else if (phone.length == 3) {
      this.setState({
        data: update(data, {
          [eventData.name]: { $set: "(" + phone.substring(0, 3) + ") " },
        }),
      });
    } else if (phone.length > 3 && phone.length < 6) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set: "(" + phone.substring(0, 3) + ") " + phone.substring(3),
          },
        }),
      });
    } else if (phone.length == 6 && this.state.local == false) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " ",
          },
        }),
      });
      this.setState({ local: true });
    } else if (phone.length == 6 && this.state.local == true) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set: "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6),
          },
        }),
      });
      this.setState({ local: false });
    } else if (phone.length >= 6 && phone.length < 12) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              "(" +
              phone.substring(0, 3) +
              ") " +
              phone.substring(3, 6) +
              " " +
              phone.substring(6),
          },
        }),
      });
    } else if (phone.length >= 12) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              "(" +
              phone.substring(0, 3) +
              ") " +
              phone.substring(3, 6) +
              " " +
              phone.substring(6, 11),
          },
        }),
      });
    }
  };
  fetchData = () => {
    const { URL_NDA_SIGN_IN } = urls.App;
    const { token } = this.props.match.params;
    console.log("props match params", this.props.match.params);
    const params = {
      params: {
        token,
      },
    };
    const instance = Api.AxiosInstance.getInstance();
    return instance
      .get(URL_NDA_SIGN_IN, params)
      .then((resp) => {
        console.log("response", resp);
        this.setData(resp.data);

        // this.setState({data: resp.data});
      })
      .catch((error) => {
        this.setState({
          redirectToLogin: true,
        });
      });
  };
  setData = (resp) => {
    this.setState({
      filename: resp.filename,
      fileurl: resp.fileurl,
      status: resp.status,
    });
  };
  validateForm = (event) => {
    const { data } = this.state;
    // console.log("data in validateForm", data);
    let result;
    if (data.signType == "MANUAL") {
      result = validate(removeEmptyKeys(data), manualSignatureValidations);
      // console.log("result", result);
    } else {
      result = validate(removeEmptyKeys(data), digitalSignatureValidations);
      // console.log("result", result);
    }
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => {
        this.save();
      });
    } else {
      this.setState({ validationMsg: result, loading: false });
    }
  };

  save = () => {
    // TODO: submit call
    let { data } = this.state;
    data.signerPhone = this.state.code + data.signerPhone;
    const { URL_NDA_SIGN_IN } = urls.App;
    const { token } = this.props.match.params;
    const params = {
      params: {
        token,
      },
    };
    let body = {
      ...data,
    };
    // cross browser issue:
    body.signDate = data.signDate;
    return Axios.post(URL_NDA_SIGN_IN, body, params)
      .then((resp) => {
        console.log("response after post", resp);
        this.setState({
          loading: false,
          isError: false,
          showMsg: true,
          msg: "You will be notified via email once the NDA has been countersigned by Groq's signing authority. This process may take up to 2 business days to complete.",
        });
        this.isSuccess = true;
      })
      .catch((err) => {
        console.log("err after post", err);
        this.setState({
          loading: false,
          isError: true,
          msg: "Error Occurred, While Submission. Please try later on or contact the support team.",
        });
      });
  };
  closeErrorPopup = () => {
    this.setState({ showMsg: false });
    if (this.isSuccess) {
      this.setState({ redirectToDashboard: true });
      //window.location.reload();
    }
  };
  handleFileChange = (eventData) => {
    const { data } = this.state;
    const { fileuuid } = eventData;
    if (fileuuid) {
      console.log("inside", fileuuid, eventData);
      this.setState({
        data: update(data, { fileuuid: { $set: fileuuid } }),
      });
    }
  };

  handleChange = (event, eventData) => {
    const { data } = this.state;
    this.setState({
      data: update(data, { [eventData.name]: { $set: eventData.value } }),
    });
  };

  render() {
    const {
      data,
      validationMsg,
      DATE_FORMAT,
      loading,
      isError,
      msg,
      fileurl,
      filename,
      status,
    } = this.state;
    const color = isError ? "red" : "green";

    //TODO: need to discuss the business of this requirement
    if (status !== "UNSIGNED") {
      return (
        <Segment basic>
          <Segment className="mt-2-im mb-2-im base-segment-wrapper">
            <div className="side-marker" />
            <div className="header">
              Download Standard Non-Disclosure Agreement
            </div>
            <div className="empty-height-small" />
            <label>
              <strong> Download NDA File : </strong>
            </label>
            {/* TODO either url or download file by id... better would be url*/}
            <span>
              <a href={fileurl} target="_blank">
                {filename}
              </a>
            </span>
            <Divider />
          </Segment>
        </Segment>
      );
    }
    return (
      <Segment basic>
        <Segment className="mt-2-im mb-2-im base-segment-wrapper">
          <div className="side-marker" />
          <div className="header"> Sign Standard Non-Disclosure Agreement</div>
          <div className="empty-height-small" />{" "}
          <Grid>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                <Message className="nda-non-disclosure-msg">
                  <Message.List>
                    Please download the following NDA file and carefully read
                    the terms of the NDA. If you agree to the terms of the NDA,
                    you can sign the NDA in one of the following ways:
                    <Message.Item>
                      MANUAL SIGNATURE: Download the attached file, sign it and
                      then upload it, OR,
                    </Message.Item>
                    <Message.Item>
                      DIGITAL SIGNATURE: Add signature text in signature field.
                    </Message.Item>
                  </Message.List>
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          <label>
            <strong> Download NDA File : </strong>
          </label>
          {/* TODO either url or download file by id... better would be url*/}
          <span>
            <a href={fileurl} target="_blank">
              {filename}
            </a>
          </span>
          <Divider />
          <Form error={true} loading={loading}>
            <DropdownField
              errorMsg={validationMsg.signType}
              isError={validationMsg.signType}
              isRequired
              width="5"
              label="Select Signature Type"
              name="signType"
              value={data.signType}
              onChange={this.handleChange}
              options={signatureOptions}
              placeholder="Select Signature Type..."
            />
            {data.signType == "DIGITAL" ? (
              <React.Fragment>
                <InputField
                  label="Signature Text"
                  errorMsg={validationMsg.signature}
                  isError={validationMsg.signature}
                  name="signature"
                  onChange={this.handleChange}
                  value={data.signature}
                  isRequired
                />
                <InputField
                  label="Signer Name"
                  errorMsg={validationMsg.signerName}
                  isError={validationMsg.signerName}
                  name="signerName"
                  onChange={this.handleChange}
                  value={data.signerName}
                  isRequired
                />
                <InputField
                  label="Signer Title"
                  errorMsg={validationMsg.signerTitle}
                  isError={validationMsg.signerTitle}
                  name="signerTitle"
                  onChange={this.handleChange}
                  value={data.signerTitle}
                  isRequired
                />
                <InputField
                  label="Signer Email"
                  errorMsg={validationMsg.signerEmail}
                  isError={validationMsg.signerEmail}
                  name="signerEmail"
                  onChange={this.handleChange}
                  value={data.signerEmail}
                  isRequired
                />
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          mobile={16}
                          tablet={5}
                          computer={5}
                          className="col-country-code"
                        >
                          <CountryCode
                            label="Country Code"
                            errorMsg={validationMsg.code}
                            isError={!!validationMsg.code}
                            name="code"
                            onChange={this.countryCodeChange}
                            value={this.state.code}
                            width={16}
                            isRequired
                          />{" "}
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={11}
                          computer={11}
                          className="col-mobile-phone"
                        >
                          <PhoneField
                            label="Signer Phone"
                            errorMsg={validationMsg.signerPhone}
                            isError={validationMsg.signerPhone}
                            name="signerPhone"
                            onChange={this.onPhoneChange}
                            value={data.signerPhone}
                            width={16}
                            isRequired
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <DatePickerWithTimezone
                  name="signDate"
                  label="Date of Signature"
                  errorMsg={validationMsg.signDate}
                  isError={validationMsg.signDate}
                  format={DATE_FORMAT}
                  onChange={(e) => {
                    this.handleChange(null, {
                      name: "signDate",
                      value: e,
                    });
                  }}
                  selected={this.state.data.signDate}
                  isRequired
                  minDate={new Date()}
                  timezone="America/Los_Angeles"
                  readOnly={false}
                />
              </React.Fragment>
            ) : (
              ""
            )}
            {data.signType == "MANUAL" ? (
              <FileField
                label="File Field"
                errorMsg="File Can't be Empty"
                isError={validationMsg.fileuuid}
                name="file"
                onChange={Uploader(this.handleFileChange)}
                value={data.fileuuid}
                isRequired
                multipleSelection={false}
              />
            ) : (
              ""
            )}

            <button
              onClick={this.validateForm}
              className="button-primary m--no-margin-left-im"
            >
              Submit Request
            </button>
            {msg && (
              <span style={{ color: color, marginLeft: "5px" }}>{msg}</span>
            )}
          </Form>
          {this.state.redirectToDashboard ? <Redirect to="/dashboard" /> : ""}
          <Modal
            open={this.state.showMsg}
            onClose={() => {
              this.closeErrorPopup();
            }}
            centered={true}
            size="tiny"
            content={msg}
            actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
          ></Modal>
        </Segment>
      </Segment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  config: configSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNDA);
