import React from "react";
import { Tab } from "semantic-ui-react";
import ItemInfo from "./item-info";
import PackageInfo from "./package-info";
import ProductInfo from "./product-info";
import LicenseInfo from "./license";

const EditItems = (props) => {
  const panes = [
    {
      menuItem: "Item Info",
      render: () => (
        <Tab.Pane>
          <ItemInfo {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assigned Products",
      render: () => (
        <Tab.Pane>
          <ProductInfo {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assigned Packages",
      render: () => (
        <Tab.Pane>
          <PackageInfo {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assigned License",
      render: () => (
        <Tab.Pane>
          <LicenseInfo {...props} />
        </Tab.Pane>
      ),
    },
  ];

  return <Tab panes={panes} />;
};

export default EditItems;
