import { isEmpty, sortBy } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { customerContactTypesSelector } from "../../selectors";
import DropdownField from "./dropdown-field";

const getContactTypeList = contactTypes => {
  const contactTypeList = Object.keys(contactTypes).reduce((acc, el) => {
    acc.push({ key: el, value: parseInt(el), text: contactTypes[el] });
    return acc;
  }, []);
  // not needed though but still incase :P
  const sortedTypes = sortBy(contactTypeList, [
    function(o) {
      return o.text;
    }
  ]);
  return sortedTypes;
};

const CustomerContactType = ({
  contactTypes,
  errorMsg,
  isError,
  isRequired,
  label,
  name,
  onChange,
  value,
  width
}) => {
  if (isEmpty(contactTypes)) {
    return <></>;
  }

  return (
    <DropdownField
      errorMsg={errorMsg}
      isError={isError}
      isRequired={isRequired}
      label={label}
      name={name}
      onChange={onChange}
      options={getContactTypeList(contactTypes)}
      placeholder="Select Customer Contact Type..."
      value={value}
      width={width}
    />
  );
};

CustomerContactType.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string
};

const mapStateToProps = (state, props) => {
  return {
    contactTypes: customerContactTypesSelector(state, props)
  };
};

const mappedCustomerContactType = connect(mapStateToProps)(CustomerContactType);

export default mappedCustomerContactType;
