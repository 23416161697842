import React from "react";
import { Segment, List } from "semantic-ui-react";
import { get } from "lodash";


const Footer = ({ config }) => {
	const siteName = get(config, ['maps', 'websilo_site_name']);
	return (
		<Segment inverted>
			<List horizontal>
				<List.Item>
					<img src="websilo.png" />
				</List.Item>
				<List.Item>
					{ siteName }
				</List.Item>
			</List>
		</Segment>
	);
}


export default Footer;