import React from "react";
import BriefcaseLogListing from "../components/briefcase-log-listing";
import urls from "@/config/constants";

const BriefcaseReport = () => {
  const { URL_BRIEFCASE_LOG_LISTING } = urls.App;
  return (
    <BriefcaseLogListing
      title="Briefcase Log"
      fetchUrl={URL_BRIEFCASE_LOG_LISTING}
    />
  );
};

export default BriefcaseReport;
