import { CardviewMobile } from "@/common/cardview";
import FileIcon from "@/common/file-icon";
import Tableview from "@/common/tableview";
import UserListing from "@/common/user-selection/user-listing";
import Api from "@api";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Divider,
  Dropdown,
  Grid,
  Loader,
  Modal,
  Responsive,
  Segment,
} from "semantic-ui-react";

// deliberately commentting import for latest build..
// import { CSVLink, CSVDownload } from "react-csv";
// import PropDataUpdatedCSVLink from "./../utility/extendedCSV";

const headers = [
  { label: "Product Name", key: "productName" },
  { label: "Item Name", key: "itemName" },
  { label: "File Name", key: "filename" },
];

class AccessReportsListing extends React.Component {
  Constants = {
    TABLE_COLUMNS_ITEMS: Object.freeze([
      { name: "Product Name", key: "productName", showTooltip: true, cb: () => {
        this.sortByHeader("products.name", "Product Name");
      }, width: 'two' },
      { name: "Item Name", key: "itemName",showTooltip: true, cb: () => {
        this.sortByHeader("name", "Item Name");
      }, width: 'two' },
      { name: "Item Type", key: "fileType", class: "center", showTooltip: true, width: 'two' },
      { name: "File Name", key: "fileName", showTooltip: true, width: 'four' },
      { name: "Date Uploaded", key: "dateUploaded", class: "center", showTooltip: true, cb: () => {
        this.sortByHeader("modificationDate", "Date Uploaded");
      }, width: 'two' },
      { name: "Download Link", key: "downloadLink", width: "six",showTooltip: true, },
    ]),
    TABLE_COLUMNS_PRODUCTS: Object.freeze([
      { name: "Product Name", key: "productName",showTooltip: true,  cb: () => {
        this.sortByHeader("name", "Product Name");
      }, },
      { name: "Description", key: "productDescription",showTooltip: true,  cb: () => {
        this.sortByHeader("description", "Description");
      }, },
    ]),
    TABLE_COLUMNS_PACKAGE: Object.freeze([
      { name: "Package Name", key: "packageName",showTooltip: true,  cb: () => {
        this.sortByHeader("name", "Package Name");
      }, },
      { name: "Description", key: "packageDescription",showTooltip: true,  cb: () => {
        this.sortByHeader("description", "Description");
      }, },
    ]),
    TABLE_COLUMNS_PROJECT: Object.freeze([
      { name: "Document Name", key: "fileName",showTooltip: true, cb: () => {
        this.sortByHeader("name", "Document Name");
      },},
      { name: "Description", key: "projectDescription",showTooltip: true,  cb: () => {
        this.sortByHeader("description", "Description");
      },},
      { name: "Project Name", key: "projectName",class: "center",showTooltip: true,},
      { name: "Date Uploaded", key: "dateUploaded",class: "center", showTooltip: true,  cb: () => {
        this.sortByHeader("modificationDate", "Date Uploaded");
      },},
      { name: "Download Link", key: "downloadLink", class: "center", width: "7",showTooltip: true, },
    ]),
    TABLE_COLUMNS_VIDEOS: Object.freeze([
      { name: "Video Name", key: "videoName",showTooltip: true, cb: () => {
        this.sortByHeader("name", "Video Name");
      },  },
      { name: "Video Description", key: "videoDescription",showTooltip: true,  cb: () => {
        this.sortByHeader("description", "Description");
      }, },
      { name: "File Name", key: "fileName", class: "center",showTooltip: true, },
      { name: "Date Uploaded", key: "dateUploaded",class: "center", showTooltip: true,  cb: () => {
        this.sortByHeader("modificationDate", "Date Uploaded");
      },},
      { name: "Download Link", key: "downloadLink",class: "center", showTooltip: true, width: "6"},
    ]),
    REPORT_TYPE: Object.freeze([
      { text: "Item Access Report", key: "item", value: "item",showTooltip: true, },
      { text: "Package Access Report", key: "package", value: "package",showTooltip: true, },
      // { text: "Product Access Report", key: "product", value: "product",showTooltip: true, },
      { text: "Project Access Report", key: "project", value: "project",showTooltip: true, },
    ]),
    RESPONSIVE_COLUMNS_ITEMS: Object.freeze([
      { name: "Product Name", key: "productName" },
      { name: "Item Name", key: "itemName" },
      { name: "Item Type", key: "fileType", class: "center" },
      { name: "File Name", key: "fileName", class: "center" },
      { name: "Date Uploaded", key: "dateUploaded", class: "center"},
      { name: "Download Link", key: "downloadLink", class: "center"},
    ]),
    RESPONSIVE_COLUMNS_PRODUCTS: Object.freeze([
      { name: "Product Name", key: "productName" },
      { name: "Description", key: "productDescription" },
    ]),
    RESPONSIVE_COLUMNS_PACKAGE: Object.freeze([
      { name: "Package Name", key: "packageName" },
      { name: "Description", key: "packageDescription" },
    ]),
    RESPONSIVE_COLUMNS_PROJECT: Object.freeze([
      { name: "Document Name", key: "fileName" },
      { name: "Description", key: "projectDescription"},
      { name: "Project Name", key: "projectName"},
      { name: "Date Uploaded", key: "dateUploaded"},
      { name: "Download Link", key: "downloadLink"},
    ]),
    RESPONSIVE_COLUMNS_VIDEOS: Object.freeze([
      { name: "Video Name", key: "videoName"},
      { name: "Video Description", key: "videoDescription" },
      { name: "File Name", key: "fileName"},
      { name: "Date Uploaded", key: "dateUploaded"},
      { name: "Download Link", key: "downloadLink"},
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      data: { content: [], number: 0 },
      // csvData: [],
      errorMsg: "",
      selectedItems: [],
      showError: false,
      page: 0,
      deleteWarning: false,
      showUsersListing: false,
      username: "",
      userId: "",
      groupId: "",
      reportType: "item",
      prepareDownload: false,
      readyDownload: false,
      errorDownload: false,
      isDescSearch: true,
      sortBy: "",
    };

    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {}

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    let col = "TABLE_COLUMNS_ITEMS"
    if(this.state.reportType=="product"){
      col = "TABLE_COLUMNS_PRODUCTS"
    }else if(this.state.reportType=="package"){
      col = "TABLE_COLUMNS_PACKAGE"
    }else if(this.state.reportType=="project"){
      col = "TABLE_COLUMNS_PROJECT"
    }
    this.setState({ sortBy: query }, () => {
      this.Constants[col] = this.Constants[col].map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getListing();
    });
   
  };

  getListing = () => {
    const { reportType, userId, groupId, username, page } = this.state;

    let body = {
      reportType,
      groupId,
      userId,
      username,
    };

    let params = {
      params: {
        page,
        sort: this.state.sortBy,
      },
    };
    if(username){
    this.getData(
      body,
      params,
      //success
      (resp) => {
        this.setState({
          csvData: resp.data.content,
          data: this.dataMapper(resp.data),
          prepareDownload: false,
          readyDownload: false,
          errorDownload: false,
        });
      },
      //error
      (err) => {
        this.setState({
          data: { content: [], number: 0 },
          errorDownload: false,
          prepareDownload: false,
          readyDownload: false,
        });
      }
    );
   }
  };

  getData = (body, params, success, failure) => {
    const { fetchUrl } = this.props;
    Api.AxiosInstance.getInstance()
      .post(fetchUrl, body, params)
      .then(success)
      .catch(failure);
  };

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.getListing();
    }
  }

  dataMapper = (data) => {
    const { reportType } = this.state;
    let transformedData = Object.assign({}, data);
    let content = [];
    // add changes if needed, like item or file types etc

      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.fileType = (
          <React.Fragment>
            <FileIcon
              filename={row.filename ? row.filename : "other"}
              mimetype={row.item_type_name}
            ></FileIcon>
          </React.Fragment>
        );

        row.downloadLink = (
          <React.Fragment>
            <div><a href = {row.downloadLink}>{row.downloadLink}</a></div>
            </React.Fragment>
        )
        
        content.push(row);
      });
      transformedData.content = content;

    console.log("transformedData", transformedData);
    return transformedData;
  };
  onSelectUser = (e) => {
    e.preventDefault();
    this.setState({ showUsersListing: true });
  };
  onSaveUserListing = (user) => {
    user.map((data) =>
      this.setState({
        showUsersListing: false,
        userId: data.id,
        groupId: data.groupId,
        username: data.username,
      })
    );
  };
  onDismissUserListing = () => {
    this.setState({ showUsersListing: false });
  };

  download = (e) => {
    e.preventDefault();
    //TODO : add whatever we need to do for downloading csv files...
    // either fetch daata or directly populate:
    // let's try populating directly...
    this.downloadCSV();
  };

  downloadCSV = () => {
    try {
      const {
        reportType,
        userId,
        groupId,
        username,
        page,
        data,
        prepareDownload,
        readyDownload,
        errorDownload,
      } = this.state;

      if (!prepareDownload && !errorDownload && !!readyDownload) {
        window.open(readyDownload);
        return;
      }

      if (!data || !data.content || !data.content.length) {
        alert("No data present!");
        return;
      }

      let body = {
        reportType,
        groupId,
        userId,
        username,
      };

      let params = {
        timeout: 0,
        params: {
          download: 1,
          page,
          sort: "modificationDate,DESC",
        },
      };

      this.setState({
        prepareDownload: true,
      });
      this.getData(
        body,
        params,
        //success
        (resp) => {
          this.setState({
            prepareDownload: false,
            readyDownload: resp.data,
          });
        },
        //error
        (err) => {
          //Temporary Hack
          this.setState({
            prepareDownload: false,
            errorDownload: "Unable to prepare file for download.",
          });
        }
      );
    } catch (e) {
      console.log(`Axios request failed: ${e}`);
    }
  };

  onSearchTyping = (event, data) => {
    this.setState({
      username: event.target.value,
    });
  };

  onPageChange = (e) => {
    this.setState(
      {
        page: e.page,
      },
      this.getListing
    );
  };

  onItemsSelection = (event) => {
    this.setState({ selectedItems: event.data });
  };

  onDropDownChange = (name, value) => {
    this.setState({
      data: { content: [], number: 0 },
      [name]: value,
      prepareDownload: false,
      readyDownload: false,
    });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  clearState = (e) => {
    //Temporary hack: need to do something else
    this.setState({
      userId: "",
      groupId: "",
      username: "",
      reportType: "item",
      data: { content: [], number: 0 },
    });
  };

  render() {
    const { title } = this.props;
    const {
      username,
      reportType,
      data,
      errorMsg,
      showError,
      csvData,
      prepareDownload,
      readyDownload,
      errorDownload,
    } = this.state;

    const {
      REPORT_TYPE,
      TABLE_COLUMNS_ITEMS,
      TABLE_COLUMNS_PRODUCTS,
      TABLE_COLUMNS_PACKAGE,
      TABLE_COLUMNS_PROJECT,
      TABLE_COLUMNS_VIDEOS,
      RESPONSIVE_COLUMNS_PACKAGE,
      RESPONSIVE_COLUMNS_PRODUCTS,
      RESPONSIVE_COLUMNS_ITEMS,
      RESPONSIVE_COLUMNS_PROJECT,
      RESPONSIVE_COLUMNS_VIDEOS,
    } = this.Constants;

    const disableDownloadButton = prepareDownload || errorDownload;

    let tableColumns =
      reportType === "package"
      ? TABLE_COLUMNS_PACKAGE
      : reportType === "product"
      ? TABLE_COLUMNS_PRODUCTS
      : reportType==='project'
      ? TABLE_COLUMNS_PROJECT
      : reportType==='videos'
      ? TABLE_COLUMNS_VIDEOS
      : TABLE_COLUMNS_ITEMS;

    let responsiveColumns =
      reportType === "package"
        ? RESPONSIVE_COLUMNS_PACKAGE
        : reportType === "product"
        ? RESPONSIVE_COLUMNS_PRODUCTS
        : reportType ==='project'
        ? RESPONSIVE_COLUMNS_PROJECT
        : reportType ==='videos'
        ? RESPONSIVE_COLUMNS_VIDEOS
        : RESPONSIVE_COLUMNS_ITEMS;

    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" />
                  {title}
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="control-action-wrapper">
                  <button
                    className="button-basic "
                    title="Click To Download"
                    s
                    disabled={disableDownloadButton}
                    onClick={(e) => this.download(e)}
                  >
                    {!prepareDownload && !readyDownload && "Download"}
                    {!!prepareDownload && !readyDownload && (
                      <Loader>Loading</Loader>
                    )}
                    {!!prepareDownload && !readyDownload && "Preparing File"}
                    {!prepareDownload &&
                      !!readyDownload &&
                      "File Ready! Download Now!"}
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row className="common-filter-wrapper ui form">
              <Grid.Column mobile={16} tablet={5} computer={4} largeScreen={4} widescreen={4}>
                <div class="field">
                  <div class="ui input">
                    <input
                      placeholder="User Name"
                      className="search-bar"
                      onChange={this.onSearchTyping}          
                      value={username}
                      onKeyPress={this.onKeyUp}
                    ></input>
                  </div>
                  <a
                    href="#"
                    target="_blank"
                    onClick={(e) => this.onSelectUser(e)}
                  >
                    Select Customer
                  </a>
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={5} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <Dropdown
                      options={REPORT_TYPE}
                      selection
                      defaultValue={reportType}
                      value={reportType}
                      placeholder="Report Type"
                      onChange={(event, data) =>
                        this.onDropDownChange("reportType", data.value)
                      }
                    />
                  </div>
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={5}
                computer={5}
                largeScreen={5}
                widescreen={4}
                className="button-inline-display"
              >
                <div class="field">
                  <div class="ui input">
                    <button
                      onClick={this.getListing}
                      className="button-primary m--no-margin-left-im"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div class="field">
                  <div class="ui input">
                    <button
                      onClick={(e) => this.clearState(e)}
                      className="button-basic"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Divider />
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                <div className="table-wrapper-with-pagination">
                  <Responsive minWidth={1024}>
                    <div className="table-wrapper-with-pagination">
                      <Tableview
                        columns={tableColumns}
                        data={data}
                        paginate
                        onPaginationChange={this.onPageChange}
                        noDataMessage={
                          `No  ` + reportType + `s` + ` in access report`
                        }
                      />
                    </div>
                  </Responsive>
                  <Responsive maxWidth={1023}>
                    <CardviewMobile
                      data={data}
                      columns={responsiveColumns}
                      paginate
                      onPaginationChange={(e) => this.onPageChange(e)}
                    />
                  </Responsive>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <UserListing
          show={this.state.showUsersListing}
          onDismiss={this.onDismissUserListing}
          onSave={this.onSaveUserListing}
          role="websilo-customer"
          selectionType="single-select"
          title="List of Customers"
        />

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

AccessReportsListing.propTypes = {
  /** URL this component will use to fetch latest data */
  fetchUrl: PropTypes.string.isRequired,
  /** Text which will be displayed at the top of the page. */
  title: PropTypes.string.isRequired,
};

export default withRouter(AccessReportsListing);
