import React from 'react';
import PageTemplate from "../../common/page";
import CompletedSWEL from "./pages/completed-swel";
import PendingSWEL from "./pages/pending-swel";

const MENU_ITEMS = {
  Software_Evaluation_License: [
    { name: 'Completed SWELs', relativePath:'/', exact: true, subPageComponent: CompletedSWEL },
    { name: 'Pending SWELs', relativePath:'/pending', subPageComponent: PendingSWEL },
  ],
}

const SWEL = ({ baseUrl }) => {
  return(
    <PageTemplate baseUrl={ baseUrl } menuItems={ MENU_ITEMS } />
  )
}

export default SWEL;