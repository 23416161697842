import { connect } from "react-redux";
import Search from "./search";
import Config from "@/config/constants";
import { authoritySelector } from "@/common/selectors";

const baseUrl = "/global-search";

const mapDispatchToProps = () => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    authority: authoritySelector(state),
    baseUrl: baseUrl,
  };
};

let mappedSearch = connect(mapStateToProps, mapDispatchToProps)(Search);

export default {
  name: "Search",
  configKey: "search_menu_item_title",
  path: "/global-search",
  component: mappedSearch,
  isPrivateRoute: true,
  navbarVisibility: false,
  accessRoles: Config.AccessRole.ALL,
};
