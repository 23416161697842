import moment from "moment";

export const PACIFIC_TIME_ZONE = "America/Los_Angeles";
export const STANDARD_DATE = "MM/DD/YYYY";
export const STANDARD_TIME = "hh:mm a";
export const STANDARD_TIME_FORMAT = "MM/DD/YYYY hh:mm a";

export const dateToPacific = (date, format = "YYYY-MM-DD") => {
  return moment.tz(date, PACIFIC_TIME_ZONE).format(format);
};

export const formatUTC = (dateInt, addOffset = false) => {
  let date = (!dateInt || dateInt.length < 1) ? new Date() : new Date(dateInt);
  if (typeof dateInt === "string") {
      return date;
  } else {
      const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
      const offsetDate = new Date();
      offsetDate.setTime(date.getTime() + offset * 60000)
      return offsetDate;
  }
}