import React, { useEffect } from 'react';
import {
  Segment
} from "semantic-ui-react";
import { notificationMarkAsRead, notificationMarkAsUnread } from '../../../api/notifications';
import { NotificationsConnect } from '../_context';
import NotificationsContent from './notifications-content';

const NotificationsMain = (props) => {
  const { state, actions, updateNotificationCountToMain } = props;
  const { notifications } = state;
  const { content } = notifications;

  const [recent, earlier] = content ? content.reduce((agg, row) => {
    const indexToPush = agg[2] || row.isRead ? 1 : 0;
    agg[indexToPush].push({ ...row });

    // once you get a read notification, move the rest to earlier.
    if (indexToPush === 1) agg[2] = true;

    return agg;
  }, [[], [], false]) : [[], []];

  const refreshNotifications = () => {
    actions.listNotifications();
    actions.countNotifications();
    updateNotificationCountToMain();
  }

  const markAllAsSeen = () => {
    if (window.confirm('Mark all as seen?')) {
      const notificationIds = content && content.length > 0 ? content.map((notification) => notification.id) : false;
      
      if (notificationIds) {
        notificationMarkAsRead(notificationIds).then(() => {
          refreshNotifications();
        });
      }
    }
  }

  const markAsSeen = (notificationIds = []) => {
    if (notificationIds) {
      notificationMarkAsRead(notificationIds).then(() => {
        refreshNotifications();
      });
    }
  }

  const markAsUnseen = (notificationIds = []) => {
    if (notificationIds) {
      notificationMarkAsUnread(notificationIds).then(() => {
        refreshNotifications();
      });
    }
  }

  useEffect(() => {
    refreshNotifications();
  }, []);

  return <React.Fragment>
    <Segment className="base-segment-wrapper notifications-page">
      <NotificationsContent
        recent={recent} earlier={earlier} markAsSeen={markAsSeen}
        markAllAsSeen={markAllAsSeen} state={state} markAsUnseen={markAsUnseen}
        showAllNotificationsLink={false} />
    </Segment>
  </React.Fragment>
};

export default (props) => <NotificationsConnect {...props} Component={NotificationsMain} />;