import { DateField, InputField, TextareaField, DropFileField } from '@/common/Form';
import MultiUploader from '@/common/uploader/multi';
import { UserSelection } from '@/common/user-selection';
import { removeEmptyKeys } from '@/common/utils/utils';
import Api from '@api';
import urls from '@constants';
import update from 'immutability-helper';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { addDays } from 'date-fns'
import Config from "@/config/constants";
import {
  Checkbox,
  Form,
  Grid,
  Modal,
  Progress,
  Segment,
  Divider,
  Confirm,
} from "semantic-ui-react";
import { validate } from "validate.js";
import ExpirationPeriod from "./subcomponents/expiration-period";
import { extendedValidation, normalValidation } from "./validation";
import { authoritySelector } from "../../../../common/selectors";
import { dateToPacific } from "../../../../utils/dateTime";
import DatePickerWithTimezone from "../../../../utils/DatePickerWithTimeZone";
import BriefcaseShare from './briefcase-share';
import Actions from "../../../../store/actions";

const APPROVERS = Config.AccessRole.APPROVERS;
class BriefcaseSend extends React.Component {
  isSuccess = false;
  Constants = {
    DATE_FORMAT: "YYYY-MM-DD",
    DEFAULT_STATE: {
      sharePressed: false,
      apiError: null,
      loading: false,
      uploadProgress: false,
      data: {
        projectInformation: "",
        sourceFiles: {},
        sourceProgress: false,
        codeFiles: {},
        codeProgress: false,
        onnxFiles: {},
        onnxProgress: false,
        datasetFiles: {},
        datasetProgress: false,
        containerFiles: {},
        containerProgress: false,
        proxyFiles: {},
        proxyProgress: false,
        availableDate: new Date(),
        line1: "",
        // availableDate: moment(new Date()).format("YYYY-MM-DD"),
        availableMinDate: dateToPacific(new Date().getTime()),
        subject: "",
        description: "",
        recipients: [],
        recipientsComplete: [],
        files: {},
        expireType: "DAYS",
        expireValue: 7,
        watermarkEnabled: 1,
        dateStamp: 1,
      },
      validationMsg: {},
      showMsg: false,
      pdfEnabled: 1,
      msg: "",
      popupWarning: false,
      sendEmail: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = this.Constants.DEFAULT_STATE;
    this.uploader = MultiUploader(this.onFileChange);
    
    // Setting an uploader against each field name
    this.shareModelUploaders = {
      source: MultiUploader((a, b) => this.onShareFileChange(a, b, "source")),
      code: MultiUploader((a, b) => this.onShareFileChange(a, b, "code")),
      onnx: MultiUploader((a, b) => this.onShareFileChange(a, b, "onnx")),
      dataset: MultiUploader((a, b) => this.onShareFileChange(a, b, "dataset")),
      container: MultiUploader((a, b) => this.onShareFileChange(a, b, "container")),
      proxy: MultiUploader((a, b) => this.onShareFileChange(a, b, "proxy")),
    }
  }

  onDataChange = (event, eventData) => {
    const { data } = this.state;
    this.setState({
      data: update(data, { [eventData.name]: { $set: eventData.value } }),
    });
  };

  componentDidMount() {
    let { data } = this.state;
    if (this.props.authority === "websilo-customer") {
      this.setState({
        data: update(data, {
          watermarkEnabled: { $set: 0 },
        }),
      });
    }
  }

  onGenericChange = (event, eventData) => {
    this.setState({ [eventData.name]: eventData.value });
  };

  onByteChange = (event, eventData) => {
    const { data } = this.state;
    let value = eventData.value ? 1 : 0;

    this.setState({
      data: update(data, { [eventData.name]: { $set: value } }),
    });
  };

  onFileChange = (eventData, file) => {
  console.log('Progess' , eventData)
    const { data } = this.state;
    const { files } = data;

    if (eventData === "progress") {
      this.setState({
        uploadProgress: file.uploadProgress,isUploading:true
      });
    }
    if (eventData === "complete" || eventData === "error") {
      this.setState({ uploadProgress: false , isUploading:false});
      this.props.restoreIdle();
    }


    if (eventData === "removed") {
      if (files[file.fileuuid]) {
        delete files[file.fileuuid];
      }
    } else {
      if (file && file.fileuuid) {
        if (!files[file.fileuuid]) {
          this.setState({
            data: update(data, {
              files: {
                $merge: { [file.fileuuid]: file },
              },
            }),
          });
          this.props.suspendIdle();
        } else {
          this.setState({
            data: update(data, {
              files: {
                [file.fileuuid]: { $set: file },
              },
            }),
          });
        }
      }
    }

  };
  
  onShareFileChange = (eventData, file, fieldName) => {
    let filesKey = fieldName + "Files";
    let progressKey = fieldName + "Progress";
    const { data } = this.state;
    if (eventData === "progress") {
      this.setState({
        [progressKey]: file.uploadProgress,
      });
    }
    if (eventData === "complete" || eventData === "error") {
      this.setState({ 
        [progressKey]: false 
      });
      this.props.restoreIdle();
    }
    if (eventData === "removed") {
      if (data[filesKey][file.fileuuid]) {
        delete data[filesKey][file.fileuuid];
      }
    } else {
      if (file && file.fileuuid) {
        if (!data[filesKey][file.fileuuid]) {
          this.setState({
            data: update(data, {
              [filesKey]: {
                $merge: { [file.fileuuid]: { $set: file } },
              },
            }),
          });
          this.props.suspendIdle();
        } else {
          this.setState({
            data: update(data, {
              [filesKey]: {
                [file.fileuuid]: { $set: file },
              },
            }),
          });
        }
      }
    }
  };

  onExpirationChange = (type, value) => {
    const { data } = this.state;
    this.setState({
      data: update(data, {
        expireType: { $set: type },
        expireValue: { $set: value },
      }),
    });
  };

  onUserChange = (users) => {
    const { data } = this.state;
    let usersIds = [];
    let showGroupMsg = false;
    // checking if any selected user is the part of any group
    let filteredArray = users.filter((obj) => obj.groupId != null);
    showGroupMsg = filteredArray.length > 0 ? true : false;
    // to check if any selected user is admin
    let adminUsers = users.filter((obj) => [2, 4, 5, 6].includes(obj.userType));
    if (
      adminUsers.length > 0 &&
      parseInt(this.props.config.maps.pdf_admin_watermark) === 1
      && filteredArray.length === 0
    ) {
      showGroupMsg = false;
    }
    this.setState({ showGroupMsg });
    users.map((user) => usersIds.push({ userId: user.id }));
    this.setState({ data: update(data, { recipients: { $set: usersIds }, recipientsComplete: { $set: users } }) });
  };

  getFilesInfo = (files) => {
    if (!files || files.length === 0) return;

    let filesInfo = [];
    Object.keys(files).map((_key) =>
      filesInfo.push({
        filename: files[_key].file.name,
        filetype: files[_key].file.type,
        filesize: files[_key].file.size,
        id: files[_key].fileuuid,
      })
    );

    return filesInfo;
  };

  transformData = () => {
    const { data } = this.state;
    const {
      files,
      ...restOfData
    } = data;
    let postData = {
      files: this.getFilesInfo(files),
    };

    Object.keys(restOfData).map((key) => {
      if (key.indexOf('Files') > -1
        && restOfData[key]) {
        restOfData[key] = this.getFilesInfo(restOfData[key]);
      }
    });

    Object.assign(postData, restOfData);
    return removeEmptyKeys(postData);
  };

  postFiles = (postData) => {
    const { URL_BRIEFCASE_SEND } = urls.App;
    const { sendEmail } = this.state;

    Api.AxiosInstance.getInstance()
      .post(`${URL_BRIEFCASE_SEND}?sendEmail=${sendEmail}`, postData, {
        timeout: 60000,
      })
      .then((resp) => {
        this.setState({
          loading: false,
          msg: "Briefcase sent successfully.",
          showMsg: true,
        });
        this.isSuccess = true;
        // window.location.reload();
        // this.uploadFile(resp.data);
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({
          msg: errorMsg
            ? errorMsg
            : "Something went wrong while uploading the files.",
          showMsg: true,
          loading: false,
        });
      });
  };

  showpopupWarning = () => {
    const { data, watermarkEnabled } = this.state;
    let copy = { ...data };
    
    let cleanedData = this.transformData(copy);
    let result;
    if (watermarkEnabled) {
      result = validate(cleanedData, extendedValidation);
    } else {
      result = validate(cleanedData, normalValidation);
    }
    if (result) {
      this.setState({ validationMsg: result, loading: false });
      return;
    }
    this.setState({
      popupWarning: true,
    });
  };
  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  
  validateForm = (event) => {
    const { data, watermarkEnabled, DATE_FORMAT } = this.state;
    let copy = {...data};
    let cleanedData = this.transformData(copy);
    let result;
    if (watermarkEnabled) {
      result = validate(cleanedData, extendedValidation);
    } else {
      result = validate(cleanedData, normalValidation);
    }
    if (!result) {
      if (cleanedData.availableDate) {
        cleanedData.availableDate = cleanedData.availableDate;
        // let date = moment(cleanedData.availableDate);
      }

      if (cleanedData.expireType && cleanedData.expireType === "DATE") {
        cleanedData.expireValue = dateToPacific(
          cleanedData.expireValue,
          "YYYY-MM-DD"
        );
        // //moment(cleanedData.expireValue)
        // .tz("America/Los_Angeles")
        // .format("YYYY-MM-DD hh:mm a z");
      }

      this.setState({ validationMsg: {}, loading: true }, () =>
        this.postFiles(cleanedData)
      );
    } else {
      this.setState({ validationMsg: result, loading: false });
    }
  };

  closeErrorPopup = () => {
    this.setState({ showMsg: false });
    if (this.isSuccess) {
      window.location.reload();
    }
  };

  fileChanger = (a, b) => {
    this.uploader.onChange(a, b);
  };
  
  showSharePage = (newValue) => {
    this.setState({ sharePressed: newValue });
  };
  
  render() {
    const {
      data,
      pdfEnabled,
      validationMsg,
      watermarkEnabled,
      showMsg,
      msg,
      loading,
      uploadProgress,
    } = this.state;
    const { DATE_FORMAT } = this.Constants;
    const { files, availableMinDate } = data;
    const fileKeys = Object.keys(files);
    return (
      <>
        <Segment className="base-segment-wrapper briefcase-send">
            {/* <Header as='h4' size="medium">Send New Item</Header> */}
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className="header">
                    <div className="side-marker" />
                    Send New Item
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Form loading={loading} className="width-100">
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Form.Field error={validationMsg.recipients} required>
                      <label>To</label>
                      <UserSelection
                        onChange={this.onUserChange}
                        isError={validationMsg.recipients}
                        errorMsg="You have not selected any recipient"
                        initialValue={ this.state.data.recipientsComplete }
                      />
                    </Form.Field>

                <InputField
                  label="Subject"
                  errorMsg={validationMsg.subject}
                  isError={validationMsg.subject}
                  name="subject"
                  maxlength={32}
                  onChange={this.onDataChange}
                  value={data.subject}
                  isRequired
                />

                <TextareaField
                  label="Description"
                  errorMsg={validationMsg.description}
                  isError={validationMsg.description}
                  name="description"
                  maxLength={"1000"}
                  onChange={this.onDataChange}
                  value={data.description}
                  rows="8"
                  isRequired
                />

                <DropFileField 
                  errorMsg="You have not selected any files."
                  isError={validationMsg.files}
                  label="File(s)"
                  name="files"
                  onChange={this.fileChanger}
                  isRequired
                  multipleSelection={true}
                  value={ Object.values(files).map((_key) => {return _key.file}) }
                />

                {fileKeys &&
                  fileKeys.map((_key) => {
                    const file = files[_key];
                    const { isUploading, uploader, file: fileObj, uploadProgress } = file;

                    return file && this.state.isUploading ? (
                      <div className="file-list">
                        <Progress percent={uploadProgress} indicating>
                          {fileObj && fileObj.name} ({uploadProgress.toFixed(1)}%)
                          &nbsp;&nbsp;

                        </Progress>
                      </div>
                    ) : (
                      ""
                    );
                  })}

                <Segment className="share-model">
                    {
                        this.props.authority === Config.AccessRole.CUSTOMER &&
                        <button
                        className={"toggle-share-page button-primary m--no-margin-left-im " + (this.state.sharePressed ? "active" : "")}
                          onClick={() => {
                            this.showSharePage(!this.state.sharePressed);
                          }}
                        >
                          Share Your Model
                        </button>
                    }
                    { this.state.sharePressed && 
                    <>
                      <BriefcaseShare
                        onDataChange={ this.onDataChange }
                        data={ this.state.data }
                        validationMsg = { this.state.validationMsg }
                        uploaders = { this.shareModelUploaders }
                      />
                    </>
                  }
                </Segment>
                <DatePickerWithTimezone
                    name="availableDate"
                    label="Available Date"
                    minDate={new Date()}
                    timezone="America/Los_Angeles"
                    errorMsg={validationMsg.availableDate}
                    isError={validationMsg.availableDate}
                    selected={data.availableDate}
                    isRequired
                    format={"YYYY-MM-DD"}
                    onChange={(e, data) => {
                      this.onDataChange(e, {
                        name: "availableDate",
                        value: e,
                      });
                    }}
                  />
                <ExpirationPeriod
                    isError={
                      validationMsg.expireType || validationMsg.expireValue
                    }
                    errorMsg={
                      validationMsg.expireType || validationMsg.expireValue
                    }
                    minDate={addDays(data.availableDate, 1)}
                    onChange={this.onExpirationChange}
                    format={DATE_FORMAT}
                  />
                    <Form.Field>
                      <Checkbox
                        name="sendEmail"
                        label="Send Email notification to the recipient(s)"
                        checked={this.state.sendEmail}
                        onChange={(event, data) => {
                          this.setState({ sendEmail: data.checked });
                        }}
                      />
                    </Form.Field>
                    {APPROVERS.indexOf(this.props.authority) >= 0 ? (
                      <>
                        <Form.Field>
                          <Checkbox
                            name="pdfEnabled"
                            label="PDF Options"
                            checked={pdfEnabled}
                            onChange={(event, data) =>
                              this.onGenericChange(event, {
                                name: data.name,
                                value: data.checked,
                              })
                            }
                          />
                        </Form.Field>

                        {pdfEnabled && (
                          <React.Fragment>
                            <span className="text-primary">
                              <b>PDF Options</b>
                            </span>
                            <Divider />
                            <Form.Field>
                              <Checkbox
                                name="passwordProtect"
                                label="Password Protect"
                                checked={data.passwordProtect}
                                onChange={(event, data) =>
                                  this.onByteChange(event, {
                                    name: data.name,
                                    value: data.checked,
                                  })
                                }
                              />
                            </Form.Field>

                            <Form.Field>
                              <Checkbox
                                name="watermarkEnabled"
                                label="PDF Watermark"
                                checked={data.watermarkEnabled}
                                onChange={(event, data) =>
                                  this.onByteChange(event, {
                                    name: data.name,
                                    value: data.checked,
                                  })
                                }
                              />
                            </Form.Field>

                            {parseInt(data.watermarkEnabled) === 1 ? (
                              <React.Fragment>
                                <span className="text-primary">
                                  <b>PDF Watermark</b>
                                </span>
                                <Divider />

                                <InputField
                                  errorMsg={validationMsg.line1}
                                  isError={validationMsg.line1}
                                  label="Watermark Line 1"
                                  name="line1"
                                  maxlength={"64"}
                                  onChange={this.onDataChange}
                                  popup={true}
                                  value={
                                    this.state.showGroupMsg
                                      ? this.state.data.line1 ? this.state.data.line1 : ""
                                      : this.state.data.line1 ? this.state.data.line1 : this.props.config.maps.pdf_watermark_line1
                                  }
                                  content={
                                    this.state.showGroupMsg
                                      ? "Watermark applied based on group setting"
                                      : "Default: " +
                                        this.props.config.maps.pdf_watermark_line1
                                  }
                                  // isRequired
                                />
                                <InputField
                                  label="Watermark Line 2"
                                  name="line2"
                                  maxlength={64}
                                  onChange={this.onDataChange}
                                  popup={true}
                                  content={
                                    this.state.showGroupMsg
                                      ? "Watermark applied based on group setting"
                                      : "Default : {Firstname} {Lastname}"
                                  }
                                />
                                <InputField
                                  label="Watermark Line 3"
                                  name="line3"
                                  onChange={this.onDataChange}
                                  maxlength={64}
                                  popup={true}
                                  content={
                                    this.state.showGroupMsg
                                      ? "Watermark applied based on group setting"
                                      : "Default : {Company Name}"
                                  }
                                />
                                <Form.Field>
                                  <Checkbox
                                    name="dateStamp"
                                    label="Date Stamp"
                                    checked={data.dateStamp}
                                    onChange={(event, data) =>
                                      this.onByteChange(event, {
                                        name: data.name,
                                        value: data.checked,
                                      })
                                    }
                                  />
                                </Form.Field>
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Form>

              <Grid.Row>
                <Grid.Column>
                  <button
                    className="button-primary m--no-margin-left-im"
                    onClick={this.showpopupWarning}
                  >
                    Send
                  </button>{" "}
                  <br />
                </Grid.Column>
              </Grid.Row>

              <Modal
                open={showMsg}
                onClose={() => {
                  this.closeErrorPopup();
                }}
                centered={true}
                size="tiny"
                content={msg}
                actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
              ></Modal>
            </Grid>
            <Confirm
              open={this.state.popupWarning}
              onCancel={this.hidepopupWarning}
              onConfirm={() => this.hidepopupWarning(this.validateForm)}
              content={"Are you sure you want to send briefcase?"}
            />
          </Segment>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  suspendIdle: () => {
    dispatch({
      type: Actions.Config.PAUSE_IDLE_TIMER
    });
  },
  restoreIdle: () => {
    dispatch({
      type: Actions.Config.RESUME_IDLE_TIMER
    });
  }
});

const mapStateToProps = (state, props) => ({
  authority: authoritySelector(state, props),
  config: state.common.config,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BriefcaseSend));
