import React from "react";
import {
  Button,
  Icon,
  List,
  Label,
  Modal,
  Segment,
  Grid,
  Container,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { get } from "lodash";
import Moment from "react-moment";
import { dateToPacific } from "@/utils/dateTime";

const ItemInfo = ({ file, viewCallBack, tag, modelTypeMap }) => {
  const hasModelTypeMapValues = modelTypeMap && Object.keys(modelTypeMap).length > 0;

  return (
    <Modal
      trigger={
        tag ? (
          tag
        ) : (
          <span className="icon-search" title="View" onClick={viewCallBack} />
        )
      }
      // closeIcon={ <Button icon="close" size="large" /> }
      centered={true}
      size="tiny"
      closeIcon
    >
      <Modal.Header>Briefcase Item Info</Modal.Header>
      <Modal.Content className="form-info-t2" scrolling>
        <Grid>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={4}
              largeScreen={4}
              widescreen={4}
            >
              <span>To:</span>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={10}
              computer={12}
              largeScreen={12}
              widescreen={12}
            >
              <b>{get(file, ["recipients", "0", "sentTo"])}</b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={4}
              largeScreen={4}
              widescreen={4}
            >
              <span>Subject:</span>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={10}
              computer={12}
              largeScreen={12}
              widescreen={12}
            >
              <b>{get(file, ["subject"])}</b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={4}
              largeScreen={4}
              widescreen={4}
            >
              <span>Description:</span>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={10}
              computer={12}
              largeScreen={12}
              widescreen={12}
            >
              <b>{get(file, ["description"])}</b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={4}
              largeScreen={4}
              widescreen={4}
            >
              <span>Files:</span>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={10}
              computer={12}
              largeScreen={12}
              widescreen={12}
            >
              <b>
                <List>
                  {file.files.map((el) => (
                    <List.Item>{get(el, ["filename"])} { modelTypeMap && modelTypeMap[el.id] ? "(" + modelTypeMap[el.id] + ")" : (hasModelTypeMapValues ? "(other files)" : "") }</List.Item>
                  ))}
                </List>
              </b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={4}
              largeScreen={4}
              widescreen={4}
            >
              <span>Available From:</span>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={10}
              computer={12}
              largeScreen={12}
              widescreen={12}
            >
              <b>
                <>{dateToPacific(get(file, ["availableDate"]), "MM/DD/YYYY")}</>
              </b>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {file.expireDate ? (
              <>
                <Grid.Column
                  mobile={16}
                  tablet={6}
                  computer={4}
                  largeScreen={4}
                  widescreen={4}
                >
                  <span>Expires On:</span>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={10}
                  computer={12}
                  largeScreen={12}
                  widescreen={12}
                >
                  <b>
                    <>
                      {dateToPacific(get(file, ["expireDate"]), "MM/DD/YYYY")}
                    </>
                  </b>
                </Grid.Column>
              </>
            ) : (
              <>
                <Grid.Column
                  mobile={16}
                  tablet={6}
                  computer={4}
                  largeScreen={4}
                  widescreen={4}
                >
                  <span>Expires On:</span>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={10}
                  computer={12}
                  largeScreen={12}
                  widescreen={12}
                >
                  <b>
                    <>NEVER EXPIRE </>
                  </b>
                </Grid.Column>
              </>
            )}
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

ItemInfo.propTypes = {};

export default ItemInfo;
