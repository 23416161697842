import PageTemplate from "@/common/page";
import React from "react";
import GroupsListing from "./groups/index";
import EditGroup from "./groups/components/forms/groups/edit/index";
import AddGroup from "./groups/components/forms/groups/add";

import { Categories, ItemTypes, Platforms } from "./items/index";
import {
  EmailSettings,
  PDFSettings,
  SystemSettings,
  Policy,
  CCPA,
  Search,
} from "./settings/index";
import { ContactTypes, UserTypes } from "./users/index";
import AddUser from "./users/components/forms/users/add";
import ListUsers from "./users/components/forms/users/list";
import EditUser from "./users/pages/users/edit/index";
import AddUserTypes from "@/modules/admin/users/components/forms/usertypes/add";
import EditUserTypes from "@/modules/admin/users/components/forms/usertypes/edit";
import EditContactTypes from "@/modules/admin/users/components/forms/contact-types/edit";
import AddContactTypes from "@/modules/admin/users/components/forms/contact-types/edit";
// for reports
import AccessReport from "@/modules/reports/pages/access-report";
import BriefcaseLog from "@/modules/reports/pages/briefcase-log";
import DownloadLog from "@/modules/reports/pages/download-log";
import PackageList from "@/modules/reports/pages/package-list";
// for licenses
import LicensesPage from "@/modules/licenses/components/licenses-page";
import Edit from "@/modules/licenses/components/edit";
import Create from "@/modules/licenses/components/create";
// for nda
import NDAList from "@/modules/NDA/pages/nda-list";
import PendingNDA from "@/modules/NDA/pages/pending-nda";
import NDAForm from "@/modules/NDA/pages/nda-form";
import NonStandardNDAList from "@/modules/NDA/pages/nda-non-standard-list";
import NDATemplate from "@/modules/NDA/pages/nda-template";
import NDACreateForm from "@/modules/NDA/pages/nda-create-form";
import NDANonStandardForm from "@/modules/NDA/pages/nda-non-standard-form";

//articles
import { AddArticles, ListArticles, EditArticles } from "./articles";

import Config from "@/config/constants";
import emailTemplates from "./settings/pages/email-templates";
import TagsListing from './tags/pages/tags-listing';
import AddTags from './tags/components/forms/tags/add';
import EditTags from './tags/components/forms/tags/edit';

// const CUSTOMER_AUTHORITY = Config.AccessRole.CUSTOMER;
// const NON_NDA_CUSTOMER = Config.AccessRole.NON_NDA_CUSTOMER;
// const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;
const ADMINISTRATOR = Config.AccessRole.ADMINISTRATOR;
const SYSTEM_ADMIN = Config.AccessRole.SYSTEM_ADMIN;
const ACCOUNT_APPROVER = Config.AccessRole.ACCOUNT_APPROVER;
const EMPLOYEE = Config.AccessRole.EMPLOYEE;

const APPROVER_MENU_ITEMS = {
  //user_setup: [],
  access_control: [
    {
      name: "Users",
      relativePath: "/",
      subPageComponent: ListUsers,
      exact: true,
      hide: true,
    },
    {
      name: "Users",
      relativePath: "/users-listing",
      subPageComponent: ListUsers,
    },
    {
      name: "Update User",
      relativePath: "/user/edit/:id",
      hide: true,
      subPageComponent: EditUser,
    },
    {
      name: "Add New User",
      relativePath: "/user/new",
      hide: true,
      subPageComponent: AddUser,
    },
    {
      name: "User Groups",
      relativePath: "/user-groups",
      subPageComponent: GroupsListing,
      exact: true,
    },
    {
      name: "Update Group",
      relativePath: "/groups/edit/:id",
      hide: true,
      subPageComponent: EditGroup,
    },
    {
      name: "Add New Group",
      relativePath: "/group/new",
      hide: true,
      subPageComponent: AddGroup,
    },
  ],
  NDAs: [
    {
      name: "Standard NDA List",
      relativePath: "/nda",
      exact: true,
      subPageComponent: NDAList,
    },
    {
      name: "Non-Standard NDA List",
      relativePath: "/nda/non-standard",
      exact: true,
      subPageComponent: NonStandardNDAList,
    },
    {
      name: "Create Non Standard NDA",
      relativePath: "/nda/non-standard/create",
      subPageComponent: NDANonStandardForm,
    },
    {
      name: "Pending NDAs",
      relativePath: "/nda/pending",
      subPageComponent: PendingNDA,
    },

    {
      name: "Non Standard Edit Form",
      relativePath: "/nda/non-standard/update/:id",
      hide: true,
      subPageComponent: NDANonStandardForm,
    },
    {
      name: "Update NDA",
      relativePath: "/nda/update/:id",
      hide: true,
      subPageComponent: NDAForm,
    },
    {
      name: "Create NDA",
      relativePath: "/nda/create/:token",
      exact: true,
      hide: true,
      subPageComponent: NDACreateForm,
    },
    {
      name: "NDA Template",
      relativePath: "/nda/template",
      subPageComponent: NDATemplate,
    },
  ],
};
const EMPLOYEE_MENU_ITEMS = {
  // user_setup: [],
  access_control: [
    {
      name: "Users",
      relativePath: "/",
      subPageComponent: ListUsers,
      exact: true,
      hide: true,
    },
    {
      name: "Users",
      relativePath: "/users-listing",
      subPageComponent: ListUsers,
    },
    {
      name: "Update User",
      relativePath: "/user/edit/:id",
      hide: true,
      subPageComponent: EditUser,
    },
    {
      name: "Add New User",
      relativePath: "/user/new",
      hide: true,
      subPageComponent: AddUser,
    },
    {
      name: "User Groups",
      relativePath: "/user-groups",
      subPageComponent: GroupsListing,
      exact: true,
    },
    {
      name: "Update Group",
      relativePath: "/groups/edit/:id",
      hide: true,
      subPageComponent: EditGroup,
    },
    {
      name: "Add New Group",
      relativePath: "/group/new",
      hide: true,
      subPageComponent: AddGroup,
    },
  ],
};
const ADMIN_MENU_ITEMS = {
  //user_setup: [],
  access_control: [
    {
      name: "Users",
      relativePath: "/",
      subPageComponent: ListUsers,
      exact: true,
      hide: true,
    },
    {
      name: "Users",
      relativePath: "/users-listing",
      subPageComponent: ListUsers,
    },
    {
      name: "Update User",
      relativePath: "/user/edit/:id",
      hide: true,
      subPageComponent: EditUser,
    },
    {
      name: "Add New User",
      relativePath: "/user/new",
      hide: true,
      subPageComponent: AddUser,
    },
    {
      name: "User Groups",
      relativePath: "/user-groups",
      subPageComponent: GroupsListing,
      exact: true,
    },
    {
      name: "Update Group",
      relativePath: "/groups/edit/:id",
      hide: true,
      subPageComponent: EditGroup,
    },
    {
      name: "Add New Group",
      relativePath: "/group/new",
      hide: true,
      subPageComponent: AddGroup,
    },
  ],
  Reports: [
    {
      name: "Access Report",
      relativePath: "/access-log",
      exact: true,
      subPageComponent: AccessReport,
    },
    /** Temporary fix, for relativePath: same name highlighted the briefcase component as well..*/
    {
      name: "Briefcase Log",
      relativePath: "/brief-case",
      subPageComponent: BriefcaseLog,
    },
    {
      name: "Download Log",
      relativePath: "/download",
      subPageComponent: DownloadLog,
      hide: false,
    },
    {
      name: "Package Report",
      relativePath: "/packages",
      subPageComponent: PackageList,
      hide: false,
    },
  ],
  Licenses: [
    {
      name: "Licenses",
      relativePath: "/licenses",
      exact: true,
      subPageComponent: LicensesPage,
    },
    {
      name: "Licenses Edit",
      relativePath: "/licenses/edit/:id",
      subPageComponent: Edit,
      hide: true,
    },
    {
      name: "Licenses Edit",
      relativePath: "/licenses/create",
      subPageComponent: Create,
      hide: true,
      exact: true,
    },
  ],
  NDAs: [
    {
      name: "Standard NDA List",
      relativePath: "/nda",
      exact: true,
      subPageComponent: NDAList,
    },
    {
      name: "Non-Standard NDA List",
      relativePath: "/nda/non-standard",
      exact: true,
      subPageComponent: NonStandardNDAList,
    },
    {
      name: "Create Non Standard NDA",
      relativePath: "/nda/non-standard/create",
      subPageComponent: NDANonStandardForm,
    },
    {
      name: "Pending NDAs",
      relativePath: "/nda/pending",
      subPageComponent: PendingNDA,
    },

    {
      name: "Non Standard Edit Form",
      relativePath: "/nda/non-standard/update/:id",
      hide: true,
      subPageComponent: NDANonStandardForm,
    },
    {
      name: "Update NDA",
      relativePath: "/nda/update/:id",
      hide: true,
      subPageComponent: NDAForm,
    },
    {
      name: "Create NDA",
      relativePath: "/nda/create/:token",
      exact: true,
      hide: true,
      subPageComponent: NDACreateForm,
    },
    {
      name: "NDA Template",
      relativePath: "/nda/template",
      subPageComponent: NDATemplate,
    },
  ],

  item_setup: [
    {
      name: "Platforms",
      relativePath: "/platforms-listing",
      subPageComponent: Platforms,
    },
    {
      name: "Item Types",
      relativePath: "/item-types-listing",
      subPageComponent: ItemTypes,
    },
    {
      name: "Item Categories",
      relativePath: "/item-categories-listing",
      subPageComponent: Categories,
    },
  ],
};

const SYS_ADMIN_MENU_ITEMS = {
  //user_setup: [],
  access_control: [
    {
      name: "Users",
      relativePath: "/",
      subPageComponent: ListUsers,
      exact: true,
      hide: true,
    },
    {
      name: "Users",
      relativePath: "/users-listing",
      subPageComponent: ListUsers,
    },
    {
      name: "Update User",
      relativePath: "/user/edit/:id",
      hide: true,
      subPageComponent: EditUser,
    },
    {
      name: "Add New User",
      relativePath: "/user/new",
      hide: true,
      subPageComponent: AddUser,
    },
    {
      name: "User Groups",
      relativePath: "/user-groups",
      subPageComponent: GroupsListing,
      exact: true,
    },
    {
      name: "Update Group",
      relativePath: "/groups/edit/:id",
      hide: true,
      subPageComponent: EditGroup,
    },
    {
      name: "Add New Group",
      relativePath: "/group/new",
      hide: true,
      subPageComponent: AddGroup,
    },
  ],
  Reports: [
    {
      name: "Access Report",
      relativePath: "/access-log",
      exact: true,
      subPageComponent: AccessReport,
    },
    /** Temporary fix, for relativePath: same name highlighted the briefcase component as well..*/
    {
      name: "Briefcase Log",
      relativePath: "/brief-case",
      subPageComponent: BriefcaseLog,
    },
    {
      name: "Download Log",
      relativePath: "/download",
      subPageComponent: DownloadLog,
      hide: false,
    },
    {
      name: "Package Report",
      relativePath: "/packages",
      subPageComponent: PackageList,
      hide: false,
    },
  ],
  Licenses: [
    {
      name: "Licenses",
      relativePath: "/licenses",
      exact: true,
      subPageComponent: LicensesPage,
    },
    {
      name: "Licenses Edit",
      relativePath: "/licenses/edit/:id",
      subPageComponent: Edit,
      hide: true,
    },
    {
      name: "Licenses Edit",
      relativePath: "/licenses/create",
      subPageComponent: Create,
      hide: true,
      exact: true,
    },
  ],
  NDAs: [
    {
      name: "Standard NDA List",
      relativePath: "/nda",
      exact: true,
      subPageComponent: NDAList,
    },
    {
      name: "Non-Standard NDA List",
      relativePath: "/nda/non-standard",
      exact: true,
      subPageComponent: NonStandardNDAList,
    },
    {
      name: "Create Non Standard NDA",
      relativePath: "/nda/non-standard/create",
      subPageComponent: NDANonStandardForm,
    },
    {
      name: "Pending NDAs",
      relativePath: "/nda/pending",
      subPageComponent: PendingNDA,
    },

    {
      name: "Non Standard Edit Form",
      relativePath: "/nda/non-standard/update/:id",
      hide: true,
      subPageComponent: NDANonStandardForm,
    },
    {
      name: "Update NDA",
      relativePath: "/nda/update/:id",
      hide: true,
      subPageComponent: NDAForm,
    },
    {
      name: "Create NDA",
      relativePath: "/nda/create/:token",
      exact: true,
      hide: true,
      subPageComponent: NDACreateForm,
    },
    {
      name: "NDA Template",
      relativePath: "/nda/template",
      subPageComponent: NDATemplate,
    },
  ],

  item_setup: [
    {
      name: "Platforms",
      relativePath: "/platforms-listing",
      subPageComponent: Platforms,
    },
    {
      name: "Item Types",
      relativePath: "/item-types-listing",
      subPageComponent: ItemTypes,
    },
    {
      name: "Item Categories",
      relativePath: "/item-categories-listing",
      subPageComponent: Categories,
    },
  ],
  system_settings: [
    {
      name: "General Settings",
      relativePath: "/system-settings",
      subPageComponent: SystemSettings,
    },
    {
      name: "Email Settings",
      relativePath: "/email-settings",
      subPageComponent: EmailSettings,
    },
    {
      name: "Email Templates",
      relativePath: "/email-templates",
      subPageComponent: emailTemplates,
    },
    {
      name: "PDF Settings",
      relativePath: "/pdf-settings",
      subPageComponent: PDFSettings,
    },
    {
      name: "User Types",
      relativePath: "/user-types-listing",
      subPageComponent: UserTypes,
    },
    {
      name: "Search Indices",
      relativePath: "/search",
      subPageComponent: Search,
    },
    {
      name: "Tags",
      relativePath: "/tags",
      exact: true,
      subPageComponent: TagsListing,
    },
    {
      name: "Add Tags",
      relativePath: "/tags/new",
      hide: true,
      exact: true,
      subPageComponent: AddTags,
    },
    {
      name: "Edit Tags",
      relativePath: "/tags/edit/:id",
      hide: true,
      exact: true,
      subPageComponent: EditTags,
    },
    {
      name: "Add User Types",
      relativePath: "/user-types/new",
      hide: true,
      subPageComponent: AddUserTypes,
    },
    {
      name: "Edit User Types",
      relativePath: "/user-types/edit/:id",
      hide: true,
      subPageComponent: EditUserTypes,
    },
    /** no longer needed */
    {
      name: "Customer Contact Types",
      relativePath: "/customer-contact-types-listing",
      hide: true,
      subPageComponent: ContactTypes,
    },
    {
      name: "Add Contact Types",
      relativePath: "/contact-types/new",
      hide: true,
      subPageComponent: AddContactTypes,
    },
    {
      name: "Edit Contact Types",
      relativePath: "/contact-types/edit/:id",
      hide: true,
      subPageComponent: EditContactTypes,
    },
    /* HIDING on client request BASECAMP 086 */
    {
      name: "GPDR",
      relativePath: "/gdpr",
      subPageComponent: Policy,
      hide: true,
    },
    {
      name: "CCPA",
      relativePath: "/ccpa",
      subPageComponent: CCPA,
      hide: true,
    },
  ],
  zendesk: [
    {
      name: "Articles",
      relativePath: "/zendesk",
      subPageComponent: ListArticles,
      exact: true,
      hide: false,
    },
    {
      name: "Add Articles",
      relativePath: "/zendesk/articles",
      subPageComponent: AddArticles,
      exact: true,
      hide: true,
    },
    {
      name: "Edit Articles",
      relativePath: "/zendesk/articles/edit/:id",
      subPageComponent: EditArticles,
      exact: true,
      hide: true,
    },
  ],
};

// return <PageTemplate baseUrl={baseUrl} menuItems={ADMIN_MENU_ITEMS} />;

const Admin = ({ baseUrl, authority }) => {
  const template =
    authority === ADMINISTRATOR ? (
      <PageTemplate baseUrl={baseUrl} menuItems={ADMIN_MENU_ITEMS} />
    ) : authority === EMPLOYEE ? (
      <PageTemplate baseUrl={baseUrl} menuItems={EMPLOYEE_MENU_ITEMS} />
    ) : authority == SYSTEM_ADMIN ? (
      <PageTemplate baseUrl={baseUrl} menuItems={SYS_ADMIN_MENU_ITEMS} />
    ) : authority == ACCOUNT_APPROVER ? (
      <PageTemplate baseUrl={baseUrl} menuItems={APPROVER_MENU_ITEMS} />
    ) : (
      //TODO: need to rethink on this
      <PageTemplate baseUrl={baseUrl} menuItems={APPROVER_MENU_ITEMS} />
    );
  return template;
};

export default Admin;
