import React, { useContext, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder as folderSolid,
  faFolderOpen,
  faFolderPlus,
  faPencilAlt,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import Api from "@api";
import Constants from "@constants";
import { Dimmer, Loader, Image, Segment, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Context from "../_context";

function containsNumbers(str) {
  return str.match(/^[0-9]+$/);
}

function FileActions() {
  const context = useContext(Context.Context);
  const baseUrl =
  context && context.data && context.data.baseUrl ? context.data.baseUrl : "";

  const isAdmin = context && context.data && context.data.isAdmin;

  const location = useLocation();
  let [selectedProduct, setSelectedProduct] = useState(context && context.data && context.data.selectedProduct !== null
    ? context.data.selectedProduct
    : "");
  let [selectedProductData, setSelectedProductData] = useState(
    context && context.data && context.data.selectedProductData !== null
    ? context.data.selectedProductData
    : null);
  let [isWritable, setIsWritable] = useState(false);
    
  useEffect(() => {
  
    if ((context && context.data && (!context.data.selectedProduct || !context.data.selectedProductData))){
      // Generating slug
      let path = window.location.hash;
    
      path = path.replace("#/downloads/browser", "");
      path = path.replace(/^\/+|\/+$/g, ''); // The regular expression matches slashes at start and end
      let checkId = path.split("/");
      let slug ;
      let id = 0;
      if(!path){
        id = 1;
      }
      else if(containsNumbers(checkId[0]) == null){
        id = null;
        slug = path;
      }
      
      if(id || slug){
        const instance = Api.AxiosInstance.getInstance();
        const params = { params: { id, slug }, timeout: 0 };
        const { URL_PRODUCTS_SEARCH } = Constants.App;
        instance
          .get(URL_PRODUCTS_SEARCH, params)
          .then((response) => {
            if (response.data) {
              setSelectedProductData(response.data);
              setSelectedProduct(String(response.data.id));
              setIsWritable(isAdmin ||
                (response.data.isWritable));
            }
          });
      }
    }
    else{
      setSelectedProduct(context && context.data && context.data.selectedProduct
        ? context.data.selectedProduct
        : "");
      setSelectedProductData(context && context.data && context.data.selectedProductData !== null
        ? context.data.selectedProductData
        : null);
      setIsWritable(isAdmin ||
    (selectedProduct && selectedProductData && selectedProductData.isWritable));
    }
  }, [context, location]);
  
  return (
    <div className="control-action-wrapper">
      {isWritable && selectedProduct && selectedProductData && (
        <Link 

          to={
            `${baseUrl}/` +
            (selectedProductData ? selectedProduct : "") +
            "/items/add"
          }
          className={  isWritable ? "button-basic" : "button-basic disabled-link "}
        >
        Add New
        </Link>
      )}
    </div>
  );
}

export default FileActions;
