import React from "react";
import urls from "../../../config/constants";
import DocumentListing from "../components/documents-listing";


const Documents = (props) => (
  <DocumentListing fetchUrl={urls.App.URL_PROJECT_DOCUMENT} label="All Documents" />
);


export default Documents;
