import Api from "@/api";
import { GroupListing } from "@/common/group-selection";
import Tableview from "@/common/tableview";
import { CardviewMobile } from "@/common/cardview";
import urls from "@/config/constants";
import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import {
  Confirm,
  Divider,
  Dropdown,
  Grid,
  Modal,
  Segment,
  Responsive,
} from "semantic-ui-react";
import { authoritySelector } from "@/common/selectors";
import { dateToPacific } from "@/utils/dateTime";
import { connect } from "react-redux";

import Constants, { default as Config } from "@/config/constants";

const EDIT_APPROVERS = Config.AccessRole.EDIT_APPROVERS;

class GroupsListing extends React.Component {
  Constants = {
    columns: Object.freeze([
      {
        name: "Users",
        key: "users_count",
        width: "1",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("users_count", "Users");
        },
      },
      {
        name: "Packages",
        key: "packages_count",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("packages_count", "Packages");
        },
      },
      {
        name: "Group Name",
        key: "name",
        width: "4",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("name", "Group Name");
        },
      },
      {
        name: "Status",
        key: "status",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("status", "Status");
        },
      },

      {
        name: "Last Updated By",
        key: "modified_by",
        width: "3",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("modifiedBy", "Last Updated By");
        },
      },
      {
        name: "NDA Expiration",
        key: "expiry_date",
        width: "3",
        showTooltip: false,
        cb: () => {
          this.sortByHeader("expiry_date", "NDA Expiration");
        },
      },
      {
        name: "Last Updated On",
        key: "modification_date",
        width: "3",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("modificationDate", "Last Updated On");
        },
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]),
    responsive_columns: Object.freeze([
      {
        name: "Users",
        key: "users_count",
        width: "1",
      },
      {
        name: "Packages",
        key: "packages_count",
        width: "2",
      },
      {
        name: "Group Name",
        key: "name",
        width: "4",
      },
      {
        name: "Status",
        key: "status",
        width: "2",
        showTooltip: true,
      },

      {
        name: "Last Updated By",
        key: "modified_by",
        width: "3",
      },
      {
        name: "NDA Expiration",
        key: "expiry_date",
        width: "3",
        showTooltip: false,
      },
      {
        name: "Last Updated On",
        key: "modification_date",
        width: "3",
      },
    ]),
    search_in_options: Object.freeze([
      {
        key: "both",
        text: "Both",
        value: "",
      },
      {
        key: "Group-Name",
        text: "Group-Name",
        value: "name",
      },
      {
        key: "Description",
        text: "Description",
        value: "description",
      },
    ]),
  };

  constructor(props) {
    super(props);

    this.state = {
      date_format: "YYYY-MM-DD",
      createMode: false,
      editMode: false,
      editId: null,
      userMode: false,
      userSelectionMode: false,
      showGroupsPopup: false,
      pageNum: 0,
      deleteWarning: false,
      groupListingId: null,
      listings: {
        content: [],
        number: 0,
      },
      itemLoadXHR: false,
      keyword: "",
      search_in: "",
      deleteMsg: "",
      messageModal: {
        open: false,
        message: "",
      },
      isDescSearch: true,
      sortBy: "creationDate,DESC",
      popupWarning: false,
      groups:{}
      
    };
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    this.getList();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.columns = this.Constants.columns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getList();
    });
   
  };

  getList = () => {
    let { location } = this.props
    let { keyword, search_in, pageNum } = this.state;
    if(!keyword && location.query){ 
      keyword = location.query
      this.setState({keyword:location.query})
    } 
    if(location.pageNum){
      pageNum = location.pageNum
    }

    let params = {
      params: {
        page: pageNum,
        keyword,
        search_in,
        sort: this.state.sortBy,
      },
    };
    location.pageNum=0
    return Api.AxiosInstance.paginate(
      urls.App.URL_ADMIN_GROUP_LIST,
      params
    ).then((resp) => {
      this.setState({
        listings: this.transformData(resp.data),
      });
    });
  };

  onKeyUp(event) {
    if (event.charCode === 13) {
      if (this.state.search_in == "") {
        this.setState({ search_in: "name" });
      }
      this.getList();
    }
  }

  transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);

        {
          EDIT_APPROVERS.indexOf(this.props.authority) >= 0
            ? (row.actions = (
                <React.Fragment>
                  <Link 
                  to={{pathname:"/admin/groups/edit/" + row.id,query:this.state.keyword,pageNum:this.state.pageNum}}
                  >
                    <span
                      className="icon-edit"
                      style={{
                        margin: "3px",
                      }}
                      title="Edit"
                      name="edit"
                    />
                  </Link>
                  <span
                    className="icon-delete"
                    onClick={(e) =>
                      this.showDeleteWarningAndSetGroupListingId(e, row)
                    }
                    style={{ margin: "5px" }}
                    title="Remove"
                    name="remove"
                  />
                </React.Fragment>
              ))
            : (row.actions = <span>No Privileges</span>);
        }
        row.modification_date = (
          <>{dateToPacific(el.modification_date, "MM/DD/YYYY hh:mm a")}</>
        );
        row.expiry_date =   row.expiry_date ? dateToPacific(row.expiry_date,"MM/DD/YYYY"): "N/A"
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  onPageChange = (e) => {
    this.setState(
      {
        pageNum: e.page,
      },
      this.getList
    );
  };

  create = () => {
    this.setState({
      createMode: true,
      listings: {
        content: [],
      },
    });
  };

  edit = (e, id) => {
    e.preventDefault();
    this.setState({
      editMode: true,
      editId: id,
      listings: {
        content: [],
      },
    });
  };
  showpopupWarning = (groups) => {
    this.setState(
      {
        popupWarning: true,
        groups: groups
      },);
  }  
  hidepopupWarning = (cb) => {
    this.setState(
      {
         popupWarning: false,
     },
      () => {
          if (cb instanceof Function) cb();
        }
      );
  };
  showDeleteWarningAndSetGroupListingId = (e, row) => {
    if (row.users_count > 0) {
      this.setState({
        deleteMsg:
          "There are user(s) assigned with this group, Are you sure you want to delete this?",
      });
    } else {
      this.setState({
        deleteMsg: "Are you sure, you wish to delete this Group??",
      });
    }
    this.setState({
      groupListingId: row.id,
      deleteWarning: true,
    });
  };
  hideDeleteWarning = (cb) => {
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  remove = () => {
    const { groupListingId } = this.state;
    // e.preventDefault();
    let params = {
      params: {
        id: groupListingId,
      },
    };
    return Api.AxiosInstance.getInstance()
      .get(urls.App.URL_ADMIN_REMOVE_GROUP, params)
      .then((resp) => {
        this.getList();
      });
  };

  showGroupsPopup = (groups) => {
    this.setState({ showGroupsPopup: true });
  };

  onDismissGroupPopup = (groups) => {
    this.setState({ showGroupsPopup: false });
  };

  groupSelectionOnSave = () => {
    const { groups } = this.state;
    // will check this later on
    let groupIds = "";
    for (var i = 0; i < groups.length; i++) {
      groupIds += groups[i].id + ",";
    }
    groupIds = groupIds.slice(0, -1);
    let params = {
      params: {
        groupIds,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(urls.App.URL_ADMIN_GROUP_FORCE_RESET, params)
      .then((resp) => {
        this.setState({
          messageModal: {
            open: true,
            message: "Password reset requested",
          },
        });
      });

    this.setState({ showGroupsPopup: false });
  };
  closeMessageModal = () => {
    this.setState({
      messageModal: {
        open: false,
        message: "",
      },
    });
  };
  render() {
    const { deleteMsg } = this.state;
    return (
      <React.Fragment>
        {this.state.listings.content &&
          !this.state.editMode &&
          !this.state.createMode && (
            <Segment className="base-segment-wrapper">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    largeScreen={8}
                  >
                    <div className="header">
                      <div className="side-marker" />
                      User Groups
                    </div>
                  </Grid.Column>

                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    largeScreen={8}
                  >
                    <div className="control-action-wrapper">
                      {EDIT_APPROVERS.indexOf(this.props.authority) >= 0 && (
                        <>
                          <Link to={"/admin/group/new"}>
                            <button className="button-basic">Add New</button>
                          </Link>
                          <input
                            type="button"
                            className="button-basic"
                            value="Force Password Reset"
                            onClick={this.showGroupsPopup}
                          />{" "}
                        </>
                      )}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <div className="empty-height" />

              <Grid>
                <Grid.Row className="common-filter-wrapper">
                  <Grid.Column
                    mobile={16}
                    tablet={5}
                    computer={5}
                    largeScreen={5}
                  >
                    <div className="ui form">
                      <div className="field">
                        <input
                          type="text"
                          placeholder="Search"
                          className="search-bar"
                          onChange={(event) => {
                            this.setState({ keyword: event.target.value });
                          }}
                          onKeyPress={this.onKeyUp}
                          defaultValue={this.state.keyword}
                        />
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={5}
                    computer={5}
                    largeScreen={5}
                  >
                    <div className="ui form ">
                      <div className="field">
                        <Dropdown
                          options={this.Constants.search_in_options}
                          selection
                          defaultValue=""
                          onChange={(event, data) => {
                            this.setState({ search_in: data.value });
                          }}
                          placeholder="Both"
                        />
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={5}
                    computer={5}
                    largeScreen={5}
                  >
                    <input
                      type="button"
                      className="button-primary"
                      value="Search"
                      onClick={(event) => {
                        this.setState(
                          {
                            pageNum: 0,
                          },
                          this.getList
                        );
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="row">
                <div className="col-md-12">
                  <span
                    id="msgspan"
                    style={{
                      color: this.state.msgSpanColor,
                    }}
                  >
                    {this.state.msgSpanText}
                  </span>
                  <GroupListing
                    show={this.state.showGroupsPopup}
                    onDismiss={this.onDismissGroupPopup}
                    onSave={this.showpopupWarning}
                  />
                </div>
              </div>
              <div className="empty-height" />
              <Divider />

              <Responsive minWidth={1024}>
                <div className="table-wrapper-with-pagination">
                  <Tableview
                    paginate
                    data={this.state.listings}
                    columns={this.Constants.columns}
                    onPaginationChange={this.onPageChange}
                    noDataMessage="No Group(s)"
                  />
                </div>
              </Responsive>

              <Responsive maxWidth={1023}>
                <CardviewMobile
                  data={this.state.listings}
                  columns={this.Constants.responsive_columns}
                  paginate
                  onPaginationChange={(e) => this.onPageChange(e)}
                />
              </Responsive>
            </Segment>
          )}

        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.remove)}
          content={deleteMsg}
        />
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.groupSelectionOnSave)}
          content={"Are you sure you want to request password reset for selected"+(this.state.groups.length>1?" groups?":" group?")}
       
        />
        <>
          <Modal
            open={this.state.messageModal.open}
            onClose={this.closeMessageModal}
            centered={true}
            size="tiny"
            content={this.state.messageModal.message}
            actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
          ></Modal>
        </>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  // incase i'll need config values
  // config: configSelector(state),
  // baseUrl: baseUrl,
  authority: authoritySelector(state),
});

const mappedGroupsListing = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsListing);
export default mappedGroupsListing;
