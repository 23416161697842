import React from "react";
import { Grid } from "semantic-ui-react";
import { InputField } from "@/common/Form";

const { Row, Column } = Grid;

export default ({
  validationMsg,
  onKeyUp,
  userId,
  firstnameError,
  lastnameError,
  emailError,
  index,
}) => {
  return (
    <>
      <Grid>
        <Row>
          <Column mobile={16} tablet={16} computer={5}>
            <InputField
              label="First Name"
              errorMsg={validationMsg[`firstname${index}`] || firstnameError}
              isError={!!validationMsg[`firstname${index}`] || firstnameError}
              name={`firstname${index}`}
              onKeyup={(e) => onKeyUp(e,index)}
              onFocus={(e) => onKeyUp(e, index)}
              onChange={() => {}}
              isRequired
              disabled={userId}
              maxlength={32}
            />
          </Column>
          <Column mobile={16} tablet={16} computer={5}>
            <InputField
              label="Last Name"
              errorMsg={validationMsg[`lastname${index}`] || lastnameError}
              isError={!!validationMsg[`lastname${index}`] || lastnameError}
              name={`lastname${index}`}
              onKeyup={(e) => onKeyUp(e,index)}
              onFocus={(e) => onKeyUp(e, index)}
              onChange={() => {}}
              isRequired
              disabled={userId}
              maxlength={32}
            />
          </Column>
          <Column mobile={16} tablet={16} computer={5}>
            <InputField
              label="Email"
              errorMsg={validationMsg[`email${index}`] || emailError}
              isError={!!validationMsg[`email${index}`] || emailError}
              name={`email${index}`}
              onChange={() => {}}
              onKeyup={(e) => onKeyUp(e,index)}
              onFocus={(e) => onKeyUp(e, index)}
              disabled={userId}
              maxlength={64}
              isRequired
              placeholder="You must register with a corporate email address"
            />
          </Column>
        </Row>
      </Grid>
    </>
  );
};
