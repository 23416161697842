const normalValidation = {
  name: {
    presence: true,
    length: {
      maximum: 32
    }
  },
  description: {
    presence: false,
    length: {
      maximum: 50,
    },
  },
};

export { normalValidation };
