import React from "react";
import { get, isEmpty } from "lodash";
import {
  Button,
  Icon,
  Modal,
  Segment,
  Confirm,
  Responsive,
} from "semantic-ui-react";
import update from "immutability-helper";
import ProductListing from "./product-listing";
import Tableview from "../../../../../common/tableview";
import urls from "../../../../../config/constants";
import Api from "../../../../../api";
import { CardviewMobile } from "@/common/cardview";

class ProductInfo extends React.Component {
  Constants = {
    TABLE_COLUMNS: Object.freeze([
      { name: "Product Name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Product Name");
      },  },
      { name: "Description", key: "description", showTooltip: true, cb: () => {
        this.sortByHeader("description", "Description");
      },  },
      { name: "Modified by", key: "modifiedBy", showTooltip: true, cb: () => {
        this.sortByHeader("modifiedBy", "Modified by");
      },  },
    ]),
    RESPONSIVE_TABLE_COLUMNS: Object.freeze([
      { name: "Product Name", key: "name" },
      { name: "Description", key: "description" },
      { name: "Modified by", key: "modifiedBy" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      item: null,
      assignedProducts: { content: [] },
      errorMsg: null,
      showError: false,
      selectedProducts: [],
      showProductListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
      isDescSearch: true,
      sortBy: "creationDate,DESC",
      sortingKey: "name",
      selectedStatedProducts:[],
      checkedProductsIds:[],
      selectedProductsIndexes:[],
    };
  }

  componentDidMount() {
    this.fetchAssignedProducts();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    this.setState({
      sortingKey: key
    },()=>{
      if (!isDesc) {
        query = `${key},ASC`;
      }
      this.setState({ sortBy: query }, () => {
        this.Constants.TABLE_COLUMNS = this.Constants.TABLE_COLUMNS.map((obj) => {
          if (obj.name == title) {
            return {
              ...obj,
              isDesc: isDesc,
              isActive: true,
            };
          } else
            return {
              ...obj,
              isActive: false,
            };
        });
        this.setState({ isDescSearch: !isDesc });
        this.fetchAssignedProductsSorted();
      });
    });
  };

  fetchAssignedProductsSorted = () => {
    const { assignedProducts } = this.state;
    const { sortingKey } = this.state;
    const { isDescSearch } = this.state;
    let data = this.state.assignedProducts.content.sort(function(a, b){
        var objA=(String(a[sortingKey])).toLowerCase(), objB=(String(b[sortingKey])).toLowerCase();
        sortingKey=="name"?typeof(a.name)==="object"?objA=(a.name.props.children[0]).toLowerCase():
        objA=objA:
        objA=objA;
        sortingKey=="name"?typeof(b.name)==="object"?objB=(b.name.props.children[0]).toLowerCase():
        objB=objB:
        objB=objB;
        if(!isDescSearch){
        if (objA < objB)
         return -1;
        if (objA > objB)
         return 1;
        return 0;}
        else{
         if (objA > objB)
          return -1;
         if (objA < objB)
          return 1;
         return 0;
        }
       });
    this.setState({
      assignedProducts: update(assignedProducts, {
        content: { $set:data,}})
    });
  }

  fetchAssignedProducts = () => {
    const { assignedProducts } = this.state;
    const { URL_ITEMS_DETAILS } = urls.App;
    let params = {
      id: get(this.props, ["match", "params", "itemId"]),
      page: 0,
      //sort: this.state.sortBy,
    };
    Api.AxiosInstance.getInstance()
      .get(URL_ITEMS_DETAILS, { params: params })
      .then((resp) => {
        this.setState({
          item: resp.data,
          assignedProducts: update(assignedProducts, {
            content: { $set: this.transformAndExtractProducts(resp.data) },
          }),
        });
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: "Something went wrong while loading the products list.",
        });
      });
  };

  transformAndExtractProducts = (item) => {
    const e =
      item && item.products && item.products.length
        ? item.products.map((product) => {
            if (item.parent_id == product.id) {
              product.name = (
                <div>
                  {product.name} <small>(Primary)</small>
                </div>
              );
            }
            return product;
          })
        : [];
    return e;
  };

  onAssign = () => {
    this.setState({ showProductListing: true });
  };

  doAssign = (data) => {
    this.setState({ showProductListing: false, selectedProducts: data, selectedStatedProducts:[], checkedProductsIds:[], selectedProductsIndexes:[], }, () => {
      this.updateProducts(
        "ADD_PRODUCT",
        "Something went wrong while assigning new product(s)."
      );
    });
  };

  onUnassign = () => {
    const { selectedProducts, item } = this.state;
    if (isEmpty(selectedProducts)) {
      this.setState({
        showError: true,
        errorMsg:
          "No product selected for deletion. Please select one or more products.",
      });
    } else {
      //primary product cannot be removed
      const parentPresent = Object.values(selectedProducts).filter(
        (product) => {
          return product.id === item.parent_id;
        }
      );
      if (!parentPresent || !parentPresent.length) {
        this.showDeleteWarningAndSetValues(
          "REMOVE_PRODUCT",
          "Something went wrong while unassigning the product(s)."
        );
      } else {
        this.setState({
          showError: true,
          errorMsg: "Primary product cannot be removed!",
        });
      }
    }
  };

  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb(action, msg);
      }
    );
  };
  updateProducts = (actionType, errorMsg) => {
    const { selectedProducts } = this.state;
    const { URL_ITEMS_DETAILS } = urls.App;

    let url = `${URL_ITEMS_DETAILS}?id=${get(this.props, [
      "match",
      "params",
      "itemId",
    ])}`;
    let payload = { actiontype: actionType, products: selectedProducts };
    Api.AxiosInstance.getInstance()
      .post(url, payload)
      .then((resp) => {
        this.fetchAssignedProducts();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };

  onProductSelection = (e) => {
    //this.setState({ selectedProducts: e.data });
    let selectedStatedProducts = this.state.selectedStatedProducts;
    let ids = this.state.checkedProductsIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedProducts.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedProducts.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedProducts = selectedStatedProducts.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedProducts.map((s) => s.id);
    }
    this.setState({ checkedProductsIds: ids });

    this.setState({
      selectedStatedProducts: selectedStatedProducts,
      selectedProducts: selectedStatedProducts,
    });
  };

  onAllItemSelection = (selectedProducts, flag) => {
    let {selectedStatedProducts} = this.state;
    let ids=[];
    if(flag){
      selectedProducts.map((prod)=>{
        selectedStatedProducts = selectedStatedProducts.filter((entity) => {
          return entity.id !== prod.id;
        })
      });
    }else{
      selectedProducts.map((prod)=>{
          if (prod) {
            const alreadyPushed = selectedStatedProducts.find(
              (element) => element.id == prod.id
            );
            if (!alreadyPushed) {
              selectedStatedProducts.push(prod);
            } }
          } );
    }
    ids = selectedStatedProducts.map((s) => s.id);
    this.setState({
      selectedStatedProducts: selectedStatedProducts,
      selectedProducts: selectedStatedProducts,
      checkedProductsIds: ids,
       });
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closeProductListing = () => {
    this.setState({ showProductListing: false });
  };

  render() {
    const { TABLE_COLUMNS, RESPONSIVE_TABLE_COLUMNS } = this.Constants;
    const { assignedProducts, errorMsg, showError, showProductListing, selectedStatedProducts,
      selectedProductsIndexes,
      checkedProductsIds, } =
      this.state;

    return (
      <React.Fragment>
        <Segment basic className="p-0">
          <div className="control-action-wrapper mb-1">
            <div className="control-action-wrapper mb-1">
              <Button className="button-basic mini" onClick={this.onAssign}>
                <Icon name="plus" />
                Assign New Products
              </Button>
              <Button
                color="red"
                className="button-basic mini mr-0-im"
                onClick={this.onUnassign}
              >
                <Icon name="remove circle" />
                Unassign
              </Button>
            </div>
          </div>
        </Segment>

        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination">
            <Tableview
              columns={TABLE_COLUMNS}
              data={assignedProducts}
              onSelectionChange={this.onProductSelection}
              noDataMessage="No products found."
              selectionType="multi-select"
              onAllSelectionChange={this.onAllItemSelection}
              checkedIndexes={selectedProductsIndexes}
              checkedIds={checkedProductsIds}
            />
          </div>
        </Responsive>
        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={assignedProducts}
            columns={RESPONSIVE_TABLE_COLUMNS}
            onSelectionChange={this.onProductSelection}
            noDataMessage="No products found."
            selectionType="multi-select"
            onAllSelectionChange={this.onAllItemSelection}
            checkedIndexes={selectedProductsIndexes}
            checkedIds={checkedProductsIds}
          />
        </Responsive>

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <ProductListing
          show={showProductListing}
          onDismiss={this.closeProductListing}
          onSave={this.doAssign}
          entity="ItemProducts"
          entityId={get(this.props, ["match", "params", "itemId"])}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updateProducts)}
          content={`Are you sure you want to delete this product(s)?`}
        />
      </React.Fragment>
    );
  }
}

export default ProductInfo;
