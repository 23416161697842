import Api from "@api";
import Constants from "@constants";
import Axios from "axios";

export default function (callback) {
  if (!callback) return;
  return function (event, eventData) {
    const { value } = eventData;
    const file = value[0];

    if (!file) {
      callback("removed");
      return null;
    }

    const cancelTokenSource = Axios.CancelToken.source();
    const fileObj = {
      filename: file.name,
      filetype: file.type,
      filesize: file.size,
      fileuuid: file.fileuuid,
    };

    callback({ isUploading: true, file, cancelToken: cancelTokenSource });

    const instance = Api.AxiosInstance.getInstance();

    instance
      .post(Constants.App.URL_FILE_UPLOAD, fileObj, { timeout: 0 })
      .then((response) => {
        const { pathToUpload, id } = response.data;
        callback({ fileuuid: id });
        Axios.put(
          pathToUpload,
          file,
          {
            onUploadProgress: (progressEvent) => {
              callback({
                uploadProgress: (progressEvent.loaded / file.size) * 100,
              });
            },
            headers: {
              "Content-Type": file.type,
            },
            cancelToken: cancelTokenSource.token
          },
          { timeout: 0 }
        )
          .then((data) => {
            callback({ uploadProgress: 100 });
          })
          .catch((err) => {
            callback({
              errorMsg: "Some of the file(s) could not be uploaded.",
            });
          });
      })
      .catch((error) => {
        callback({
          errorMsg:
            "Something went wrong while uploading the file. Please remove and attach again.",
        });
      });
  };
}
