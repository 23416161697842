import Template from "./template";
import { connect } from "react-redux";
import Api from "../../../api";
import {
  loggedInUserSelector,
  configSelector,
  authoritySelector,
} from "../../../common/selectors";
import Actions from "../../../store/actions";

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(Api.User.logout());
    },
    dispatchSupportTabStatus: (status) => {
      dispatch({
        type: Actions.Config.CONFIG_SUPPORT_ENABLED,
        payload: status
      });
    },
    showHideSupportErrorDialog: (state, errMsg) => {
      dispatch({
        type: Actions.Config.SHOW_HIDE_ERROR_DIALOG,
        payload: { state, errMsg }
      });
    },
    showHideMainLoader: (state) => {
      dispatch({
        type: Actions.Config.SHOW_HIDE_LOADER,
        payload: state
      });
    },
    showHideMainOverlay: (state, ) => {
      dispatch({
        type: Actions.Config.SHOW_HIDE_OVERLAY,
        payload: state
      });
    },
    hideSidebarNotifications: () => {
      dispatch({
        type: Actions.USER.NOTIFICATIONS_SIDEBAR_HIDE
      });
    },
    showSidebarNotifications: () => {
      dispatch({
        type: Actions.USER.NOTIFICATIONS_SIDEBAR_SHOW
      });
    },
    setIdleTimerRef: (ref) => {
      dispatch({
        type: Actions.Config.SET_IDLE_TIMER_REF,
        payload: ref
      });
    }
  };
};

const mapStateToProps = (state, props) => {
  return {
    user: loggedInUserSelector(state, props),
    config: configSelector(state, props),
    authority: authoritySelector(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
