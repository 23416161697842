import React from "react";
import NDAListing from "../components/nda-listing";
import urls from "@/config/constants";

const NDAList = () => {
  const {
    URL_NDA_LISTING,
    URL_REMOVE_PENDING_NDA,
    URL_DOWNLOAD_PENDING_NDA_DOC,
  } = urls.App;
  return (
    <NDAListing
      title="Standard NDA List"
      fetchUrl={URL_NDA_LISTING}
      editUrl="/admin/nda/update/"
      deleteUrl={URL_REMOVE_PENDING_NDA}
      downloadUrl={URL_DOWNLOAD_PENDING_NDA_DOC}
    />
  );
};

export default NDAList;
