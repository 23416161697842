import React from "react";
import { Container, Header, Pagination, Segment,Grid } from "semantic-ui-react";
import update from "immutability-helper";
import {
  activePageToPageIndex,
  parameterStringify
} from "../../../common/utils/utils";
import Wall from "../../../common/wall";
import urls from "../../../config/constants";
import Api from "../../../api";

class PendingPosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: { content: [] },
      urlParams: {
        page: 0,
        filter: "",
        status: "PENDING"
      }
    };
  }

  componentDidMount() {
    this.loadWallPosts();
  }

  componentDidUpdate() {
    this.handleEmptyPage();
  }

  handleEmptyPage = () => {
    const { totalPages } = this.state.posts;
    const { page } = this.state.urlParams;
    // changing page index to page number.
    let curPage = page + 1;
    // sometimes server send 0 total pages.
    // but for our logic total page should at least 1.
    let minTotalPages = totalPages > 0 ? totalPages : 1;
    if (curPage > minTotalPages) {
      this.onPageChange(null, { activePage: minTotalPages });
    }
  };

  loadWallPosts = () => {
    const { URL_PROFILE_WALL_POST } = urls.App;
    const { urlParams } = this.state;
    let parameterizedUrl =
      URL_PROFILE_WALL_POST + "?" + parameterStringify(urlParams);

    Api.AxiosInstance.getInstance()
      .get(parameterizedUrl)
      .then(resp => {
        this.setState({ posts: resp.data });
      })
      .catch(err => {
        this.setState({
          errorMsg: "Something went wrong while loading your wall posts.",
          showError: true
        });
      });
  };

  onSearchFunction = searchTerm => {
    const { urlParams } = this.state;
    this.setState(
      { urlParams: update(urlParams, { filter: { $set: searchTerm } }) },
      this.loadWallPosts
    );
  };

  acceptPost = id => {
    const { URL_PROFILE_POST_APPROVE } = urls.App;
    let parameterizedUrl =
      URL_PROFILE_POST_APPROVE + "?" + parameterStringify({ postId: id });

    Api.AxiosInstance.getInstance()
      .get(parameterizedUrl)
      .then(resp => {
        this.loadWallPosts();
      })
      .catch(err => {
        this.setState({
          errorMsg: "Something went wrong while approving the posts.",
          showError: true
        });
      });
  };

  rejectPost = id => {
    const { URL_PROFILE_POST_ACTION } = urls.App;
    let parameterizedUrl =
      URL_PROFILE_POST_ACTION + "?" + parameterStringify({ postId: id });

    Api.AxiosInstance.getInstance()
      .delete(parameterizedUrl)
      .then(resp => {
        this.loadWallPosts();
      })
      .catch(err => {
        this.setState({
          errorMsg: "Something went wrong while approving the posts.",
          showError: true
        });
      });
  };

  onPageChange = (e, { activePage }) => {
    const { urlParams } = this.state;
    const curPage = activePageToPageIndex(activePage);
    this.setState(
      { urlParams: update(urlParams, { page: { $set: curPage } }) },
      this.loadWallPosts
    );
  };

  render() {
    const { posts } = this.state;
    const { content } = posts;
    return (
        <Segment className="base-segment-wrapper">
              <Grid stackable>
              <Grid.Row >
                <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                  <div className="header">
                    <div className="side-marker" />
                      My Pending Posts</div>
              </Grid.Column>
               
              </Grid.Row>
             </Grid >

        <Grid>


          <Wall
          posts={content}
          acceptFunction={this.acceptPost}
          deleteFunction={this.rejectPost}
          searchFunction={this.onSearchFunction}
          />
       
          <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>

        <Pagination
          activePage={posts.number + 1}
          onPageChange={this.onPageChange}
          totalPages={posts.totalPages ? posts.totalPages : 1}
        />
          </Grid.Column>
        </Grid.Row>
        <br/>
        </Grid>
      </Segment>
    );
  }
}

export default PendingPosts;
