import React from 'react';
import { Card, Divider, Grid, Header, Item, List, Loader, Modal, Segment, Container } from 'semantic-ui-react';
import { isEmpty, filter } from "lodash";
import validate from "validate.js";
import { connect } from "react-redux";
import { countriesSelector, statesSelector } from "@/common/selectors";
import { parameterStringify } from "@/common/utils/utils";
import Wall from "@/common/wall";
import urls from '@/config/constants';
import Api from "@/api";
import ImageIcon from "../../../assets/icon-image.png";


class ViewProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      profileData: {},
      wallPosts: [],
      showError: false,
      errorMsg: null,
    };
  }

  componentDidMount() {
    this.loadProfileData();
    this.loadWallPosts();
  }

  loadProfileData = () => {
    Api.AxiosInstance.getInstance()
    .get(urls.App.URL_PROFILE_USERDATA)
    .then(resp => {
      this.setState({ profileData: resp.data })
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while loading your profile information.", showError: true })
    });
  };

  loadWallPosts = () => {
    Api.AxiosInstance.getInstance()
    .get(urls.App.URL_PROFILE_WALL_POST)
    .then(resp => {
      let confirmWallPost = filter(resp.data.content, el => el.status === "CONFIRMED");
      this.setState({ wallPosts: confirmWallPost })
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while loading your wall posts.", showError: true })
    });
  };

  addPost = ({ profileId, postText }) => {
    Api.AxiosInstance.getInstance()
    .post(urls.App.URL_PROFILE_POST_ACTION, { profileId: profileId, post: postText })
    .then(resp => {
      this.loadWallPosts();
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while posting your post on the wall", showError: true })
    });
  };

  deletePost = (postId) => {
    const { URL_PROFILE_POST_ACTION } = urls.App;
    let parameterizedUrl = URL_PROFILE_POST_ACTION + '?' + parameterStringify({ postId: postId });

    Api.AxiosInstance.getInstance()
    .delete(parameterizedUrl)
    .then(resp => {
      this.loadWallPosts();
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while deleting the post.", showError: true })
    });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  render() {
    const { profileData, wallPosts, showError, errorMsg } = this.state;
    const { countries, states } = this.props;
    return(
      <React.Fragment>
        <Segment className="base-segment-wrapper" loading={ isEmpty(profileData) }>
          <div className="side-marker" />
          <div className="header">Profile</div>
          <div className="empty-height-small"/>
          {
            !isEmpty(profileData) &&
            <React.Fragment>



             <Grid>
              <Grid.Row className="profile-info-box">
                <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>


                <Item.Group  >

                    <Item className="white-background">
                      <div class="profile-image-wrapper">
                        <img
                          className="image-icon"
                          src={profileData && profileData.picture ? profileData.picture : ImageIcon}
                          alt="Image-Icon"/>
                      </div>

                      <Item.Content>
                        <Item.Header as='a' className="m--no-pad-left-right-im">
                          <strong className="items-heading">
                          {profileData.firstName} {profileData.lastName}
                          {
                            profileData.username &&
                            <React.Fragment>({ profileData.username })</React.Fragment>
                          } </strong>
                        </Item.Header>
                        {/* <Item.Meta>Hello - Edit</Item.Meta> */}
                        <Item.Description>

                        <List>
                          {
                            ['title', 'company'].map( el =>
                              <List.Item>
                                <span >
                                  { `${validate.capitalize(el)}: ` }
                                </span>
                                <span>{ profileData[el] }</span>
                              </List.Item>
                            )}
                            {
                            <List.Item>
                            <span >
                              { `${validate.capitalize('office phone')}: ` }
                            </span>
                            <span>{ profileData.phone }</span>
                            </List.Item>
                          }
                        </List>
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  </Item.Group>

                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                    <Item>
                    <Item.Content>
                    <Item.Description>
                    <List >
                      <List.Item>
                        <span >Address: </span>
                        <span>{ profileData.address }</span>
                      </List.Item>
                      <List.Item>
                        <span >State: </span>
                        <span>{ states[profileData.state] }</span>
                      </List.Item>
                      <List.Item>
                        <span >Country: </span>
                        <span>{ countries[profileData.country] }</span>
                      </List.Item>
                      <List.Item>
                        <span >Postal Code / Zip Code: </span>
                        <span>{ profileData.postalCode }</span>
                      </List.Item>
                    </List>
                    </Item.Description>
                  </Item.Content>
                </Item>
               </Grid.Column>
               </Grid.Row>

              </Grid>


           
              <Divider section />
    <Segment className="base-segment-wrapper m-0 p-0">
              <Grid columns="2" doubling>
                <Grid.Row>
                  <Grid.Column width="12">

              
                  <div className="side-marker" />
                  <div  className="header" >My Wall</div>
                    <Wall profileId={ profileData.id } posts={ wallPosts } postFunction={ this.addPost } deleteFunction={ this.deletePost } />
                  
                  </Grid.Column>
                    <Grid.Column width="4">
                      <Card className="base-card-wrapper">
                        <Card.Content className="dark-blue-background-shade">
                          <Card.Header >Projects</Card.Header>
                        </Card.Content>
                        {
                          profileData.projects &&
                          <Card.Content>
                            <List>
                              {
                                profileData.projects.map( project =>
                                  <List.Item>
                                    { project.name }
                                  </List.Item>
                                )
                              }
                            </List>
                          </Card.Content>
                        }
                      </Card>
                    </Grid.Column>
                </Grid.Row>
              </Grid>
  </Segment>
            </React.Fragment>
          }
        </Segment>
        <Modal open={ showError }
          onClose={ () => { this.closeErrorPopup(); } }
          centered={ true }
          size="tiny"
          content={ errorMsg }
          actions={[{ key: 'warningDismiss', content: 'OK', primary: true }]}
        >
        </Modal>
      </React.Fragment>
    );
  }

};

ViewProfile.propTypes = {

};

const mapStateToProps = (state, props) => {
  return {
    states: statesSelector(state, props),
    countries: countriesSelector(state, props),
  }
};

const mappedViewProfile = connect(mapStateToProps)(ViewProfile);


export default mappedViewProfile;
