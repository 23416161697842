import React from 'react';
import PageTemplate from "../../common/page";
import CompletedSLA from "./pages/completed-sla";
import PendingSLA from "./pages/pending-sla";

const MENU_ITEMS = {
  Software_License_Agreements: [
    { name: 'Completed SLAs', relativePath:'/', exact: true, subPageComponent: CompletedSLA },
    { name: 'Pending SLAs', relativePath:'/pending', subPageComponent: PendingSLA },
  ],
}

const SLA = ({ baseUrl }) => {
  return(
    <PageTemplate baseUrl={ baseUrl } menuItems={ MENU_ITEMS } />
  )
}

export default SLA;