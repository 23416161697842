import GroupListing from "@/common/group-selection/group-listing";
import Tableview from "@/common/tableview";
import UserListing from "@/common/user-selection/user-listing";
import { removeEmptyKeys } from "@/common/utils/utils";
import Api from "@api";
import Constants from "@constants";
import { isEmpty } from "lodash";
import React from "react";
import Moment from "react-moment";
import { Redirect } from "react-router-dom";
import { Button, Confirm, Container, Form, Icon, Tab } from "semantic-ui-react";
import validate from "validate.js";
import { dateToPacific } from "@/utils/dateTime";
import Details from "../components/_product-details";
import ProductsContext from "../_context";

class EditProducts extends React.Component {
  VALIDATION_CONFIG = {
    status: {
      presence: true,
    },
    name: {
      presence: true,
    },
  };

  constantValues = {
    statusOptions: [
      { text: "Active", key: "ACTIVE", value: "ACTIVE" },
      { text: "Disabled", key: "DISABLED", value: "DISABLED" },
    ],
    groupSegment: {
      addColumns: [
        { name: "Group Name", key: "name" },
        { name: "Description", key: "description" },
        { name: "Type", key: "groupType" },
        { name: "Created By", key: "createdBy" },
        { name: "Created On", key: "creationDate" },
      ],
      listColumns: [
        {
          name: "Group Name",
          key: "name",
          render: (data, key) => (
            <span>
              {data.writeable ? <Icon title="Write" name="write square" /> : ""}{" "}
              {data.name}
            </span>
          ),
        },
        { name: "Propagated From", key: "propagatedFromName" },
        { name: "Updated By", key: "updatedBy" },
        { name: "Updated On", key: "updatedOn" },
      ],
    },
    userSegment: {
      addColumns: [
        { name: "Username", key: "username" },
        { name: "Title", key: "title" },
        { name: "Company", key: "company" },
        { name: "Type", key: "type" },
      ],
      listColumns: [
        {
          name: "Username",
          key: "username",
          render: (data, key) => (
            <span>
              {data.writeable ? <Icon title="Write" name="write square" /> : ""}{" "}
              {data.username}
            </span>
          ),
        },
        { name: "Title", key: "name" },
        { name: "Company", key: "company" },
        { name: "Propagated From", key: "propagatedFromName" },
        { name: "Updated By", key: "updatedBy" },
        { name: "Updated On", key: "updatedOn" },
      ],
    },
    productOptions: [],
    tabs: [
      { type: "details", menuItem: "Details", render: () => null }, //this.detailsSegment
      // { type: "groups", menuItem: "Groups", render: () => null }, //this.groupsSegment
      // { type: "user", menuItem: "Users", render: () => null }, //this.usersSegment
    ],
  };

  defaultState = {
    isGroupToAddLoading: false,
    groupsModalShow: false,
    groupsToAddList: {},
    selectedGroups: [],
    selectedGroupsWriteable: false,
    addGroups: [],
    isUsersToAddLoading: false,
    usersModalShow: false,
    usersToAddList: {},
    selectedUsers: [],
    selectedUserWriteable: false,
    addUsers: [],
    deleteWarning: false,
    type: null,
    action: null,
  };

  constructor(props) {
    super(props);
    const defaultState = this.defaultState;
    this.state = {
      itemId: -1,
      oldData: null,
      isLoaded: false,
      isSaving: false,
      activeTabIndex: 0,
      validationMsg: {},
      popupWarning: false,
      ...defaultState,
    };

    this.constantValues.productOptions = props.productIds;
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedProduct } = this.props.data;
    if (
      selectedProduct !== null &&
      selectedProduct != -1 &&
      this.state.itemId !== selectedProduct
    ) {
      this.loadData();
    }
  }

  loadData(id) {
    const { selectedProduct } = this.props.data;

    this.setState({
      itemId: selectedProduct,
      oldData: {},
    });

    if (!selectedProduct || selectedProduct == -1) {
      return;
    }

    this.props.dispatch({ type: "loading-list" });
    const { URL_PRODUCTS_DETAILS } = Constants.App;
    const params = { params: { id: selectedProduct } };
    const instance = Api.AxiosInstance.getInstance();

    instance
      .get(URL_PRODUCTS_DETAILS, params)
      .then((resp) => {
        const data = resp.data;
        this.setProductData(selectedProduct, data);
        this.props.dispatch({ type: "update-list", details: "" });
      })
      .catch((error) => {
        this.setState({
          itemId: -1,
          oldData: null,
        });
        this.props.dispatch({ type: "update-list", details: "" });
      });
  }

  handleTabChange = (e, { activeIndex }) =>
    this.setState({ activeTabIndex: activeIndex });

  setProductData = (id, data) => {
    if (data.parent_id === null) {
      data.parent_id = -1;
    }
    const defaultState = this.defaultState;

    const groupIdList = this.transformData({ content: data.groupids });
    const userIdList = this.transformData({ content: data.userids });

    this.setState({
      activeTabIndex: this.state.activeTabIndex,
      itemId: id,
      oldData: JSON.parse(JSON.stringify(data)),
      isLoaded: true,
      ...defaultState,
      ...data,
      groupIdList,
      userIdList,
    });
  };

  closeGroupsModal = () => {
    this.setState({
      groupsModalShow: false,
      groupSearch: "",
    });
  };

  loadGroupsToAdd = () => {
    const { itemId } = this.state;
    if (itemId === -1) return;

    this.setState({
      isGroupToAddLoading: true,
      groupsModalShow: true,
    });
  };

  closeUsersModal = () => {
    this.setState({
      usersModalShow: false,
    });
  };

  loadUsersToAdd = () => {
    const { itemId } = this.state;
    if (itemId === -1) return;

    this.setState({
      isUsersToAddLoading: true,
      usersModalShow: true,
    });
  };
  showDeleteWarningAndSetValues = (type, action) => {
    this.setState({
      type: type,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { type, action } = this.state;
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb(type, action);
      }
    );
  };
  actOnSelection = (type, actiontype) => {
    let postObj = { actiontype: actiontype };
    const { selectedUsers } = this.state;

    if (type === "users") {
      postObj.userids = this.state.selectedUsers.map((user) => {
        return { id: user.id };
      });
    } else {
      postObj.groupids = this.state.selectedGroups.map((group) => {
        return { id: group.id };
      });
    }

    const { URL_PRODUCTS_DETAILS } = Constants.App;
    const instance = Api.AxiosInstance.getInstance();
    const params = { params: { id: this.state.itemId } };

    instance
      .post(URL_PRODUCTS_DETAILS, postObj, params)
      .then((resp) => {
        //this.setProductData(this.state.itemId, resp.data)
        this.loadData();
      })
      .catch((error) => {});
  };

  saveUsers = (users) => {
    if (users.length == 0) return;

    var postObj = {
      actiontype: "ADD_USER",
      userids: users.map((user) => {
        return { id: user.id };
      }),
    };

    const { URL_PRODUCTS_DETAILS } = Constants.App;
    const instance = Api.AxiosInstance.getInstance();
    const params = { params: { id: this.state.itemId } };
    this.closeUsersModal();
    instance
      .post(URL_PRODUCTS_DETAILS, postObj, params)
      .then((resp) => {
        this.loadData();
      })
      .catch((error) => {});
  };

  saveGroups = (groups) => {
    if (groups.length === 0) return;

    var postObj = {
      actiontype: "ADD_GROUP",
      groupids: groups.map((group) => {
        return { id: group.id };
      }),
    };

    const { URL_PRODUCTS_DETAILS } = Constants.App;
    const instance = Api.AxiosInstance.getInstance();
    const params = { params: { id: this.state.itemId } };
    this.closeGroupsModal();
    instance
      .post(URL_PRODUCTS_DETAILS, postObj, params)
      .then((resp) => {
        this.loadData();
      })
      .catch((error) => {});
  };

  validate = () => {
    let result = validate(removeEmptyKeys(this.state), this.VALIDATION_CONFIG);
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };

  saveDetails = () => {
    if (!this.validate()) return;

    var postObj = {
      actiontype: "UPDATE_PRODUCT",
      name: this.state.name,
      description: this.state.description,
      status: this.state.status,
    };
    this.props.dispatch({ type: "loading-list" });

    const { URL_PRODUCTS_DETAILS } = Constants.App;
    const instance = Api.AxiosInstance.getInstance();
    const params = { params: { id: this.state.itemId } };

    instance
      .post(URL_PRODUCTS_DETAILS, postObj, params)
      .then((resp) => {
        //  deliberately commented out redirectToParent() method ,
        // bug# 56661
        this.props.dispatch({ type: "reload-products" });
        this.redirectToParent();
      })
      .catch((error) => {
        this.props.dispatch({ type: "update-list", details: "" });
        this.setState({showPopupWarning: true,
        msg: error.response.data.message})
      });
  };

  redirectToParent = () => {
    const { selectedProduct, baseUrl } = this.props.data;
    this.setState({ redirectTo: baseUrl + "/" + selectedProduct });
  };

  onChange = (event, data) => {
    const { name, value } = data;

    if (typeof this.state[name] != "undefined") {
      this.setState({ [name]: value });
    }
  };

  showpopupWarning = () => {
    if (!this.validate()) return;
    this.setState(
      {
        popupWarning: true,
      },);
  }
    
  hidepopupWarning = (cb) => {
    this.setState(
      {
         popupWarning: false,
     },
      () => {
          if (cb instanceof Function) cb();
        }
      );
  };

  handleUpdate = (segment, data) => {
    if (segment === "users") {
      this.setState({
        selectedUsers: data.data,
        selectedUserWriteable: data.data.reduce(
          (r, user) => r && user.writeable,
          true
        ),
      });
    } else if (segment === "groups") {
      this.setState({
        selectedGroups: data.data,
        selectedGroupsWriteable: data.data.reduce(
          (r, group) => r && group.writeable,
          true
        ),
      });
    }
  };

  detailsSegment = () => {
    const { createdBy, creationDate, modifiedBy, modifiedDate } = this.state;
    const metaInfo = {
      createdBy,
      creationDate,
      modifiedBy,
      modifiedDate,
    };
    return (
      <div>
        <div className="empty-height"></div>
        <Details
          name={this.state.name}
          description={this.state.description}
          status={this.state.status}
          onChange={this.onChange}
          validationMsg={this.state.validationMsg}
          statusOptions={this.constantValues.statusOptions}
          productsDropDownMap={this.props.data.productsDropDownMap}
          parentId={this.state.parent_id}
          parentDropDownDisabled={true}
          metaInfo={metaInfo}
        />
      </div>
    );
  };
  transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.updatedOn = (
          <React.Fragment>
            <>{dateToPacific(row.updatedOn,"MM/DD/YYYY hh:mm a")}</>
          </React.Fragment>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };
  groupsSegment = () => {
    const { groupIdList } = this.state;
    return (
      <div>
        <div className="empty-height"></div>
        <Button
          className="button-basic "
          onClick={() => this.loadGroupsToAdd()}
        >
          <Icon name="plus" /> Add Groups
        </Button>
        <Button
          color="red"
          size="mini"
          disabled={this.state.selectedGroups.length === 0}
          className="button-danger "
          onClick={() =>
            this.showDeleteWarningAndSetValues("groups", "DELETE_GROUP")
          }
        >
          <Icon name="remove circle" /> Delete Groups
        </Button>

        {/*<Button
          size="mini"
          disabled={
            this.state.selectedGroups.length === 0 ||
            this.state.selectedGroupsWriteable
          }
          onClick={() => this.actOnSelection("groups", "GRANT_RW_GROUP")}
        >
          <Icon name="write" /> Grant Write privilege
        </Button>
        <Button
          disabled={
            this.state.selectedGroups.length === 0 ||
            !this.state.selectedGroupsWriteable
          }
          onClick={() => this.actOnSelection("groups", "GRANT_R_GROUP")}
        >
          <Icon name="minus" /> Revoke Write privilege
        </Button> */}
        <GroupListing
          show={this.state.groupsModalShow}
          onDismiss={this.closeGroupsModal}
          onSave={this.saveGroups}
          entity="ProductGroups"
          entityId={this.state.itemId}
        />
        <div className="empty-height"></div>
        <Tableview
          data={groupIdList}
          selectionType="multi-select"
          columns={this.constantValues.groupSegment.listColumns}
          onSelectionChange={(data) => this.handleUpdate("groups", data)}
          title=""
          noDataMessage="No Groups!"
        />
      </div>
    );
  };

  usersSegment = () => {
    const { userIdList } = this.state;
    return (
      <div>
        <div className="empty-height"></div>
        <Button onClick={this.loadUsersToAdd} className="button-basic ">
          <Icon name="plus" />
          Add Users
        </Button>
        <Button
          color="red"
          disabled={this.state.selectedUsers.length === 0}
          onClick={() =>
            this.showDeleteWarningAndSetValues(
              "users",
              "DELETE_USER",
              this.selectedUsers
            )
          }
          className="button-danger "
        >
          <Icon name="remove circle" /> Delete User
        </Button>

        {/* <Button
          disabled={
            this.state.selectedUsers.length === 0 ||
            this.state.selectedUserWriteable
          }
          className="button-basic "
          onClick={() => this.actOnSelection("users", "GRANT_RW_USER")}
        >
          <Icon name="write" /> Grant Write privilege
        </Button>
        <Button
          disabled={
            this.state.selectedUsers.length === 0 ||
            !this.state.selectedUserWriteable
          }
          className="button-danger "
          onClick={() => this.actOnSelection("users", "GRANT_R_USER")}
        >
          <Icon name="minus" /> Revoke Write privilege
        </Button> */}
        <UserListing
          show={this.state.usersModalShow}
          onDismiss={this.closeUsersModal}
          onSave={this.saveUsers}
          entity="ProductUsers"
          entityId={this.state.itemId}
        />
        <div className="empty-height"></div>
        <Tableview
          selectionType="multi-select"
          data={userIdList}
          columns={this.constantValues.userSegment.listColumns}
          onSelectionChange={(data) => this.handleUpdate("users", data)}
          title=""
          noDataMessage="No Users!"
        />
      </div>
    );
  };

  render() {
    const { isLoaded, activeTabIndex, itemId, redirectTo } = this.state;
    if (!isLoaded) return <></>;
    const panes = this.constantValues.tabs;

    if (redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <React.Fragment>
        <Container>
          {itemId != -1 && (
            <Form>
              <Tab
                panes={panes}
                activeIndex={activeTabIndex}
                onTabChange={this.handleTabChange}
              />
              {activeTabIndex == 0 && this.detailsSegment()}
              {activeTabIndex == 1 && this.groupsSegment()}
              {activeTabIndex == 2 && this.usersSegment()}

              <div className="empty-height"></div>
              <div className="row">
                {activeTabIndex == 0 && (
                  <Button
                    primary
                    type="submit"
                    disabled={this.props.data.isListLoading}
                    onClick={() =>
                      this.showpopupWarning()
                    }
                  >
                    Save
                  </Button>
                )}
                <Button
                  type="reset"
                  disabled={this.props.data.isListLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    // User Story #56701
                    this.redirectToParent();
                    if (this.props.reset) this.props.reset();
                  }}
                >
                  Cancel
                </Button>
              </div>
              <Confirm
                open={this.state.deleteWarning}
                onCancel={this.hideDeleteWarning}
                onConfirm={() => this.hideDeleteWarning(this.actOnSelection)}
                content={`Are you sure you want to delete these ${this.state.type}?`}
              />
              <Confirm
                    open={this.state.popupWarning}
                    onCancel={this.hidepopupWarning}
                    onConfirm={() => this.hidepopupWarning(this.saveDetails)}
                    content="Are you sure you want to update this product?"
              />
              <Confirm
                    open={this.state.showPopupWarning}
                    onCancel={() => this.setState({showPopupWarning: false})}
                    onConfirm={() => this.setState({showPopupWarning: false})}
                    content={this.state.msg}
                    size = "mini"
              />
            </Form>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default ProductsContext.Consumer(EditProducts);
