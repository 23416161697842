import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import routes from "./config/routes";
import LocalStore from "./store/persistence/local-storage";
import Api from "@/api";
import { GoogleOAuthProvider } from "@react-oauth/google";

if (window.location.pathname.search(/\/okta|\/sso/) < 0 && window.location.hash.search(/\/auth|returnTo|sso|code/) < 0) {
  LocalStore.removeValue('returnTo')
}

// if location uri
if (window.location.pathname == "/sso") {
  window.location =
    window.location.href.replace("/sso", "#/auth?") + window.location.hash;
} else if (window.location.pathname == "/okta") {
  window.location =
  window.location.href.replace("/okta", "#/auth?") + window.location.hash;
} else if (window.location.search.startsWith('?brand_id')) {
  LocalStore.setValue("returnTo", 'https://groq.zendesk.com/hc');
  window.location = `${(window.location.origin + window.location.pathname)}${window.location.hash}/${window.location.search}`;
} else if (window.location.hash.search('return_to') > -1) {
  let st = window.location.href;
  LocalStore.setValue("returnTo", decodeURIComponent(st.substring(st.lastIndexOf("return_to")+10,st.search("timestamp")-1)));
}
// For Backward compatibility as we have changed the base URL from /producst to /downloads
else if(window.location.href.search("#/products") >1)
  window.location = window.location.href.replace("#/products","#/downloads")
else if((window.location.href.search("#/downloads") >1) && 
!((window.location.href.search("#/downloads/browser")>1) || 
   (window.location.href.search("#/downloads/packages")>1) || 
    (window.location.href.search("#/downloads/view")>1)))
  window.location = window.location.href.replace("#/downloads","#/downloads/view")
 
ReactDOM.render(
  <GoogleOAuthProvider clientId="385372371524-msvv9b7rm635fn1keopo7fpqd24qj5if.apps.googleusercontent.com">
    <App store={store} routes={routes} />
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
