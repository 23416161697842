import Api from '@/api';
import { InputField } from '@/common/Form';
import Tableview from '@/common/tableview';
import { UserSelection } from '@/common/user-selection';
import { removeEmptyKeys, validateDomain } from '@/common/utils/utils';
import Constants from '@/config/constants';
import { CardviewMobile } from '@/common/cardview';
import {
  faArrowLeft,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import {
  Checkbox,
  Confirm,
  Form,
  Segment,
  Tab,
  Grid,
  Responsive,
} from 'semantic-ui-react';
import { validate } from 'validate.js';
import { normalValidation } from './../../../../../validation';
import Packages from './package';
import SignedNDA from './nda';
import { connect } from 'react-redux';

// TODO: clean this component and break this into sub modules
class GroupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      description: null,
      domain: null,
      community_feature_enabled: null,
      tech_support: null,
      tier1: null,
      group_type: null,
      status: null,
      created_by: null,
      creation_date: null,
      modification_date: null,
      watermark: null,
      modified_by: null,
      msgSpanText: '',
      msgSpanColor: 'black',
      msgSpanTextAssign: '',
      msgSpanColorAssign: 'black',
      selectedUsers: null,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: '',
      usersPageNum: 0,
      userId: null,
      deleteWarning: false,
      cancelButton: 'Cancel',
      userColumns: [
        {
          name: 'Username',
          key: 'username',
          width: '4',
          showTooltip: true,
          cb: () => {
            this.sortByHeader('username', 'Username');
          },
        },
        {
          name: 'First Name',
          key: 'firstName',
          width: '4',
          showTooltip: true,
          cb: () => {
            this.sortByHeader('firstName', 'First Name');
          },
        },
        {
          name: 'Last Name',
          key: 'lastName',
          width: '3',
          showTooltip: true,
          cb: () => {
            this.sortByHeader('lastName', 'Last Name');
          },
        },
        {
          name: 'Company',
          key: 'company',
          width: '4',
          showTooltip: true,
          cb: () => {
            this.sortByHeader('company', 'Company');
          },
        },
        { name: 'Actions', key: 'remove', width: '1' },
      ],
      responsiveUserColumns: [
        { name: 'Username', key: 'username', width: '4' },
        { name: 'First Name', key: 'firstName', width: '4' },
        { name: 'Last Name', key: 'lastName', width: '3' },
        { name: 'Company', key: 'company', width: '4' },
        { name: 'Actions', key: 'remove', width: '1' },
      ],
      userListings: { content: [], number: 0 },
      packagesPageNum: 0,
      packageColumns: [
        { name: 'Select', key: 'select', width: '1', showTooltip: true },
        {
          name: 'Package Name',
          key: 'packageName',
          width: '6',
          showTooltip: true,
        },
        {
          name: 'Assigned By',
          key: 'createdBy',
          width: '4',
          showTooltip: true,
        },
        {
          name: 'Assigned On',
          key: 'creationDate',
          width: '5',
          showTooltip: true,
        },
      ],
      packageListings: { content: [], number: 0 },
      popupWarning: false,
      isDescSearch: true,
      sortBy: '',
    };
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.state.userColumns = this.state.userColumns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getGroupUsers();
    });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    console.log(this.props);

    if (id) {
      let params = {
        params: {
          id,
        },
      };
      Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_ADMIN_SINGLE_GROUP, params)
        .then((resp) => {
          let data = resp.data[0];
          this.setState({
            id: data.id,
            name: data.name,
            description: data.description,
            community_feature_enabled: data.community_feature_enabled,
            tech_support: data.tech_support === 1 ? 'ENABLED' : 'DISABLED',
            tier1: data.tier1,
            group_type: data.group_type,
            status: data.status,
            created_by: data.created_by,
            creation_date: data.creation_date,
            modification_date: data.modification_date,
            modified_by: data.modified_by,
            domain: data.domain,
            watermark: data.watermark,
          });
        });
      this.getGroupWatermarks();
      this.getGroupUsers();
      this.getGroupPackages();
    }
  }
  getGroupWatermarks = () => {
    const { id } = this.props.match.params;

    if (id) {
      let params = {
        params: {
          id,
        },
      };
      Api.AxiosInstance.getInstance()
        .get('/admin/group/group-watermark', params)
        .then((resp) => {
          // let data = resp.data[0];
          this.setState({
            line1: resp.data.line1,
            line2: resp.data.line2,
            line3: resp.data.line3,
            dateStamp: resp.data.dateStamp,
          });
        });
    }
  };
  getGroupUsers = () => {
    const { id } = this.props.match.params;
    const { usersPageNum } = this.state;
    let configOption = {
      id,
      page: usersPageNum,
      sort: this.state.sortBy,
    };
    const params = {
      params: configOption,
    };
    return Api.AxiosInstance.paginate(
      Constants.App.URL_ADMIN_GROUP_USERS,
      params
    ).then((resp) => {
      this.setState({
        userListings: this.transformData(resp.data, 'user'),
      });
    });
  };

  getGroupPackages = () => {
    const { searchpackage, packagesPageNum } = this.state;
    const { id } = this.props.match.params;

    let params = {
      params: {
        keyword: searchpackage,
        page: packagesPageNum,
        packageId: id,
      },
    };
    return Api.AxiosInstance.paginate(
      Constants.App.URL_ADMIN_GROUP_PACKAGES,
      params
    ).then((resp) => {
      let res = {};
      if (resp.data) {
        res = resp.data;
      } else {
        return;
      }
      let checkedPkgs = [];
      let uncheckedPkgs = [];
      for (var obj in res.content) {
        if (res.content[obj].groupIds.includes(id)) {
          checkedPkgs.push(res.content[obj]);
        } else {
          uncheckedPkgs.push(res.content[obj]);
        }
      }

      res.content = checkedPkgs.concat(uncheckedPkgs);

      this.setState({
        packageListings: this.transformData(res, 'package'),
      });
    });
  };

  showpopupWarning = () => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (result) {
      this.setState({
        validationMsg: result,
        msgSpanText:
          'There are some errors with the submission. Please fix and retry.',
        msgSpanColor: 'red',
      });
      return;
    }
    this.setState({
      popupWarning: true,
    });
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  showDeleteWarningAndSetId = (e, id) => {
    this.setState({
      deleteWarning: true,
      userId: id,
    });
  };
  transformData = (data, type) => {
    const { id } = this.props.match.params;

    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.creationDate = (
          <Moment format="MM/DD/YYYY hh:mm a">{row.creationDate}</Moment>
        );
        if (type == 'user') {
          row.remove = (
            <span
              className="icon-delete"
              onClick={(e) => this.showDeleteWarningAndSetId(e, row.id)}
              title="Remove"
              name="remove"
            />
          );
        } else {
          row.select = (
            <Checkbox
              checked={row.groupIds.includes(id) ? 'checked=checked' : ''}
              onChange={(e) => {
                this.togglePackage(e, row.packageId, row.groupIds.includes(id));
              }}
            />
          );
        }
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  onUserPageChange = (e) => {
    this.setState({ usersPageNum: e.page }, this.getGroupUsers);
  };

  onPackagePageChange = (e) => {
    this.setState({ packagesPageNum: e.page }, this.getGroupPackages);
  };

  save = () => {
    const { id } = this.props.match.params;
    const {
      name,
      description,
      community_feature_enabled,
      tier1,
      group_type,
      status,
      createdBy,
      tech_support,
      creationDate,
      modificationDate,
      modifiedBy,
      domain,
      watermark,
      dateStamp,
      line1,
      line2,
      line3,
    } = this.state;
    let data = {
      id,
      name,
      description,
      communityFeatureEnabled: community_feature_enabled,
      techSupport: tech_support === 'ENABLED' ? 1 : 0,
      tier1,
      groupType: group_type,
      status,
      createdBy,
      creationDate,
      modificationDate,
      modifiedBy,
      domain,
      watermark: watermark ? 1 : 0,
      dateStamp: dateStamp ? 1 : 0,
      line1,
      line2,
      line3,
    };

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_ADD_GROUP, data)
      .then((resp) => {
        let msg = '';
        let color = 'black';
        if (resp.data.id > 0) {
          msg = 'Group Updated';
          color = 'green';
        } else {
          msg = 'Request failed, Please try later.';
          color = 'red';
        }

        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
          cancelButton: 'Back to Listing',
        });
      })
      .catch((e, d) => {
        let msg = e.response.data.message;
        let color = 'red';
        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
          cancelButton: 'Back to Listing',
        });
      });
  };
  hideDeleteWarning = (cb) => {
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  remove = () => {
    const { userId } = this.state;
    const { id } = this.props.match.params;
    let params = {
      params: {
        userId,
        groupId: id,
      },
    };
    // e.preventDefault();
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ADMIN_GROUP_REMOVE_USER, params)
      .then((resp) => {
        this.getGroupUsers();
      });
  };

  assignUserToGroup = () => {
    const { id } = this.props.match.params;
    let userIds = '';
    if (this.state.selectedUsers) {
      for (var i = 0; i < this.state.selectedUsers.length; i++) {
        userIds += this.state.selectedUsers[i].id + ',';
      }
      userIds = userIds.slice(0, -1);
      let params = {
        params: {
          userId: userIds,
          groupId: id,
        },
      };
      return Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_ADMIN_GROUP_ASSIGN_USER, params)
        .then((resp) => {
          let msg = '';
          let color = 'black';

          if (resp.data == 'OK') {
            msg = 'User(s) added';
            color = 'green';
          } else {
            msg = 'Request failed, Please try later.';
            color = 'red';
          }

          this.setState({
            msgSpanTextAssign: msg,
            msgSpanColorAssign: color,
          });

          this.getGroupUsers();
        });
    } else {
      this.setState({
        msgSpanTextAssign: 'please select atleast one user to assign',
        msgSpanColorAssign: 'red',
      });
    }
  };

  togglePackage = (e, packageId, checked) => {
    //e.preventDefault();
    const { id } = this.props.match.params;
    let pkgTickUrl = Constants.App.URL_ADMIN_GROUP_REMOVE_PACKAGE;
    if (!checked) {
      pkgTickUrl = Constants.App.URL_ADMIN_GROUP_ASSIGN_PACKAGE;
    }
    let params = {
      params: {
        packageId,
        groupId: id,
      },
    };
    return Api.AxiosInstance.getInstance()
      .get(pkgTickUrl, params)
      .then((resp) => {
        this.getGroupPackages();
      });
  };

  handleChange = (e) => {
    const value = e.target.value;
    this.setState(
      {
        [e.target.name]: value,
      },
      () => {
        const validated = validateDomain(value);
        this.setState({
          validationMsg: {
            domain: !validated ? 'Please enter a valid domain.' : null,
          },
        });
      }
    );
  };
  onByteChange = (event, eventData) => {
    let value = eventData.value ? 1 : 0;
    this.setState({
      [eventData.name]: value,
    });
  };

  handleStatusChange = (e) => {
    this.setState({
      status: e.target.value,
    });
  };

  handleTechSupportChange = (e) => {
    this.setState({
      tech_support: e.target.value,
    });
  };

  handleGroupTypeChange = (e) => {
    this.setState({
      group_type: e.target.value,
    });
  };

  userSelectionCallback = (users) => {
    this.setState({
      selectedUsers: users,
    });
  };

  validateForm = (event) => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    const invalid_domain = !validateDomain(this.state.domain);
    if (!result && !invalid_domain) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: {
          ...result,
          domain: invalid_domain ? 'Please enter a valid domain.' : null,
        },
        msgSpanText:
          'There are some errors with the submission. Please fix and retry.',
        msgSpanColor: 'red',
      });
    }
  };
  onGenericChange = (event, eventData) => {
    this.setState({ [eventData.name]: eventData.value });
  };
  getForm = () => {
    return (
      <React.Fragment>
        <row className="base-segment-wrapper">
          <div className="empty-height-small" />

          <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Name"
                    errorMsg={this.state.validationMsg.name}
                    isError={this.state.validationMsg.name}
                    name="name"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    maxlength="32"
                    isRequired
                    placeholder="Enter Group Name"
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Description"
                    errorMsg={this.state.validationMsg.description}
                    isError={this.state.validationMsg.description}
                    name="description"
                    id="description"
                    maxlength="50"
                    value={this.state.description}
                    onChange={this.handleChange}
                    placeholder="Enter Group Description"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <InputField
                    label="Domains (comma separated)"
                    errorMsg={this.state.validationMsg.domain}
                    isError={this.state.validationMsg.domain}
                    name="domain"
                    id="domain"
                    maxlength="250"
                    value={this.state.domain}
                    onChange={this.handleChange}
                    placeholder="Domains (comma separated)"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label style={{ fontWeight: 'bold' }}>
                    Status <span style={{ color: 'red' }}>*</span>
                  </label>

                  <div className="field">
                    <select
                      className="ui fluid dropdown"
                      id="status"
                      name="status"
                      value={this.state.status}
                      onChange={this.handleStatusChange}
                    >
                      <option value="ACTIVE">Active</option>
                      <option value="DISABLED">Disabled</option>
                    </select>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label style={{ fontWeight: 'bold' }}>Support</label>

                  <div className="field">
                    <select
                      className="ui fluid dropdown"
                      id="tech_support"
                      name="tech_support"
                      value={this.state.tech_support}
                      onChange={this.handleTechSupportChange}
                    >
                      <option value="ENABLED">Enabled</option>
                      <option value="DISABLED">Disabled</option>
                    </select>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="label-signup-policy">
                  <Checkbox
                    name="watermark"
                    label="Customize PDF watermark"
                    checked={this.state.watermark}
                    // isRequired
                    onChange={(event, data) =>
                      this.onByteChange(event, {
                        name: data.name,
                        value: data.checked,
                      })
                    }
                  />
                </div>
              </Grid.Column>
              {this.state.watermark ? (
                <React.Fragment>
                  <InputField
                    // errorMsg={validationMsg.line1}
                    //isError={validationMsg.line1}
                    label="Watermark Line 1"
                    name="line1"
                    maxlength={'64'}
                    value={this.state.line1}
                    onChange={this.handleChange}
                    popup={true}
                    content={
                      'Default: ' + this.props.config.maps.pdf_watermark_line1
                    }
                    // isRequired
                  />
                  <InputField
                    label="Watermark Line 2"
                    name="line2"
                    maxlength={64}
                    value={this.state.line2}
                    onChange={this.handleChange}
                    popup={true}
                    content={'Default: {Firstname} {Lastname}'}
                  />
                  <InputField
                    label="Watermark Line 3"
                    name="line3"
                    onChange={this.handleChange}
                    maxlength={64}
                    popup={true}
                    content={'Default: {Company Name}'}
                    value={this.state.line3}
                  />
                  <Form.Field>
                    <Checkbox
                      name="dateStamp"
                      label="Date Stamp"
                      checked={this.state.dateStamp}
                      onChange={(event, data) =>
                        this.onByteChange(event, {
                          name: data.name,
                          value: data.checked,
                        })
                      }
                    />
                  </Form.Field>
                </React.Fragment>
              ) : (
                ''
              )}
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="button"
                    className="button-primary"
                    value="Save"
                    onClick={this.showpopupWarning}
                  />
                  &nbsp;&nbsp;
                  <Link to={{pathname:"/admin/user-groups",query:this.props.location.query,pageNum:this.props.location.pageNum}}>
                    <button className="button-basic">
                      {this.state.cancelButton}
                    </button>
                  </Link>
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </row>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content="Are you sure you want to update group?"
        />
      </React.Fragment>
    );
  };

  getUserSelectionComponent = () => {
    return (
      <React.Fragment>
        <row className="base-segment-wrapper">
          <div className="empty-height-small" />

          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                <label>Group</label>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={5}>
                <span>{this.state.name}</span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <label>Users</label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <UserSelection
                  label="Select Users"
                  onChange={this.userSelectionCallback}
                  entityId={this.props.match.params.id}
                  entity="GroupUsers"
                  role="websilo-customer,websilo-non-nda-customer"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <input
                  type="Button"
                  className="button-basic"
                  value="Assign"
                  onClick={this.assignUserToGroup}
                />
                &nbsp;&nbsp;
                <span
                  id="msgspan"
                  style={{ color: this.state.msgSpanColorAssign }}
                >
                  {this.state.msgSpanTextAssign}
                </span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination">
                    {
                      <Tableview
                        paginate
                        data={this.state.userListings}
                        columns={this.state.userColumns}
                        onPaginationChange={this.onUserPageChange}
                        noDataMessage="No items"
                      />
                    }
                  </div>
                </Responsive>

                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    data={this.state.userListings}
                    columns={this.state.responsiveUserColumns}
                    paginate
                    onPaginationChange={this.onUserPageChange}
                    noDataMessage="No items"
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </row>
      </React.Fragment>
    );
  };

  getPackageSelectionComponent = () => {
    return (
      <React.Fragment>
        <row className="base-segment-wrapper">
          <div className="empty-height-small" />

          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                <label>Group</label>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={5}>
                <span>{this.state.name}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                <label>Search</label>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={6}>
                <div className="ui form">
                  <div className="field">
                    <input
                      type="text"
                      id="searchpackage"
                      name="searchpackage"
                      defaultValue="" /*onChange={this.packageSelectionCallback}*/
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={2}>
                <div className="ui form">
                  <div className="field">
                    <input
                      type="Button"
                      defaultValue="Search"
                      className="button-basic"
                      onClick={this.getGroupPackages}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="table-wrapper-with-pagination">
                  {
                    <Tableview
                      paginate
                      data={this.state.packageListings}
                      columns={this.state.packageColumns}
                      onPaginationChange={this.onPackagePageChange}
                      noDataMessage="No items"
                    />
                  }
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </row>
      </React.Fragment>
    );
  };

  render() {
    let tab1Title = 'Create Group';
    const { id } = this.props.match.params;

    if (id != null) {
      tab1Title = 'Edit Group';
    }
    let tab1 = {
      menuItem: tab1Title,
      render: () => <Tab.Pane>{this.getForm()}</Tab.Pane>,
    };
    let tab2 = {
      menuItem: 'Assigned Users',
      render: () => <Tab.Pane>{this.getUserSelectionComponent()}</Tab.Pane>,
    };
    let tab3 = {
      menuItem: 'Assigned Packages',
      render: () => (
        <Tab.Pane>
          <Packages {...this.props} />
        </Tab.Pane>
      ),
    };
    let tab4 = {
      menuItem: 'Signed NDA',
      render: () => (
        <Tab.Pane>
          <SignedNDA {...this.props} />
        </Tab.Pane>
      ),
    };
    // send new wala component here...
    let panes = [tab1];
    if (id != null) {
      panes = [tab1, tab2, tab3, tab4];
    }
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">
            Group Management
            <Link to={{pathname:"/admin/user-groups",query:this.props.location.query,pageNum:this.props.location.pageNum}} className="btn-back-main mr-1-im">
              <FontAwesomeIcon icon={faArrowLeft} title="Back" name="back" />
              &nbsp;Back
            </Link>
          </div>
          <div className="empty-height" />

          <Tab panes={panes} />
        </Segment>
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.remove)}
          content="Are you sure you want to remove this user from the group?"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.common.config,
});

const GroupFormMapped = connect(mapStateToProps)(GroupForm);
export default GroupFormMapped;
