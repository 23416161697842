import Actions from "../../store/actions";

import Constants from "../../config/constants";
import Axios from "axios";

export const receiveAccessToken = (accessToken) => ({
  type: Actions.Token.RECEIVE_ACCESS_TOKEN,
  payload: { accessToken },
});

//

export const generateStateAndNonce = () => ({
  type: Actions.Token.GENERATE_STATE_AND_NONCE,
});
