import { connect } from "react-redux";
import Api from "@/api";
import {
  configSelector,
  restrictedEmailDomainsSelector,
  ccpaSelector,
  gdprSelector,
} from "@/common/selectors";
import Registration from "./registration";
const UserApi = Api.User;

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(UserApi.login(username, password)),
    logout: () => dispatch(UserApi.logout()),
    oAuthLogin: (type, code) => dispatch(UserApi.oAuthLogin(type, code)),
  };
};

const mapStateToProps = (state) => ({
  config: configSelector(state),
  restrictedEmailDomains: restrictedEmailDomainsSelector(state),
  ccpa: ccpaSelector(state),
  gdpr: gdprSelector(state),
});

const mappedRegistration = connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration);

export default {
  name: "Register",
  path: "/register",
  component: mappedRegistration,
  isAccessRoute: true,
  navbarVisibility: false,
};
