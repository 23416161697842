import FriendlyDownloads from "./_routes";
import { connect } from "react-redux";
import Config from "@/config/constants";
import { authoritySelector } from "@/common/selectors";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const baseUrl = "/downloads";

const mapStateToProps = (state) => ({
  authority: authoritySelector(state),
  baseUrl: baseUrl,
});

const mappedItems = connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendlyDownloads);

export default {
  name: "Friendly Download",
  path: baseUrl,
  component: mappedItems,
  isPrivateRoute: true,
  accessRoles: Config.AccessRole.ALL,
  navbarVisibility: false,
};
