function isAdmin() {
  return { type: "is-admin" };
}
function routeUpdate(id, product) {
  return { type: "route-update", id, product };
}
// default case in which we don't have any user specified product id in url
function defaultBrowser(id, product) {
  return { type: "default-browser", id, product };
}
function baseurlUpdate(url) {
  return { type: "baseurl-update", url };
}
function breadcrumbsUpdate(breadcrumbs) {
  return { type: "breadcrumbs-update", breadcrumbs };
}
function reloadProducts() {
  return { type: "reload-products" };
}
function loadingProducts() {
  return { type: "loading-products" };
}
function updateProducts(
  products,
  rawProducts,
  productsDropDownMap,
  productParentMap
) {
  return {
    type: "update-products",
    products,
    rawProducts,
    productsDropDownMap,
    productParentMap
  };
}
function loadingList() {
  return { type: "loading-list" };
}
function updateList(details) {
  return { type: "update-list", details };
}
function expandProducts(expand) {
  return { type: "expand-products", expand };
}

export default {
    isAdmin,
    routeUpdate,
    baseurlUpdate,
    breadcrumbsUpdate,
    reloadProducts,
    loadingProducts,
    updateProducts,
    loadingList,
    updateList,
    expandProducts,
    defaultBrowser
}
