import React from "react";
import Api from "@api";
import Axios from "axios";
import { Redirect, Link } from "react-router-dom";
import validate from "validate.js";
import { isEmpty } from "lodash";
import {
  Button,
  Header,
  Segment,
  Container,
  Form,
  Progress,
  Message,
  Label,
  Confirm,
  Grid,
} from "semantic-ui-react";
import { DropFileField, InputField, TextareaField } from "@/common/Form";
import Uploader from "@/common/uploader";
import Constants from "@constants";
import update from "immutability-helper";
import ErrorMessage from "@/common/Form/subcomponents/error-message";
import _ from "lodash";
import { authoritySelector } from "../../../../common/selectors";
import { connect } from "react-redux";
import { validateUrl } from "../../../../common/utils/utils";

class DocumentForm extends React.Component {
  constructor(props) {
    super(props);
    let id = props.match.params.id;
    this.state = {
      projectId: id,
      data: {},
      options: [],
      searchValue: "",
      uploadProgress: 0,
      loading: false,
      isUploading: false,
      validationMsg: {},
      redirectTo: false,
      popupWarning: false,
    };
  }
  componentDidMount() {
    this.getTagsFromServer();
  }

  validationConfig = {
    documentname: {
      presence: true,
      length: {
        minimum: 1,
        message: "can't be blank",
      },
    },
    description: {
      presence: true,
      length: {
        minimum: 1,
        maximum: 250,
      },
    },
    // file: {
    // presence: true,
    // length: { minimum: 1 }
    // },
    tags: {
      presence: true
    },
  };
  onType = (event) => {
    if (event.target.value.length <= 0) {
      this.setState({ searchValue: "" });
    } else if (event.target.value.length <= 15) {
      this.setState({ searchValue: event.target.value });
    }
  };

  getTagsFromServer = () => {
    // call some Api to get the data    
    Api.AxiosInstance.getInstance()
      .get("tags/list",
      {
        params: {
          name: this.state.searchValue
        }
      }
      )
      .then((resp) => {
        const mockTags = resp.data;
        const stateOptions = _.map(mockTags, (state, index) => ({
          key: index,
          text: mockTags[index].name,
          value: mockTags[index],
        }));
        this.setState({ options: stateOptions });
      })
      .catch((error) => {
        this.setState({
          apiError: "Failed to submit the document",
          isUploading: false,
        });
      });
  };

  handleAddition = (e, { value }) => {
    this.setState({ searchValue: "" });
    if (value.length < 50) {
      this.setState((prevState) => ({
        options: [{ text: value.name, value }, ...prevState.options],
      }));
    }
  };

  handleDropDownChange = (e, { value }) => {
    const { data } = this.state;
    this.setState({
      data: update(data, { tags: { $set: value } }),
      searchValue: "",
    });
  };

  onTagType = (event) => {
    if (event.target.value.length <= 0) {
      this.setState({ searchValue: "" }, () => this.getTagsFromServer());
    } else if (event.target.value.length <= 15) {
      this.setState({ searchValue: event.target.value}, () => this.getTagsFromServer());
    }
  };

  handleChange = (e, { name, value }) => {
    const { data } = this.state;
    this.setState({ data: update(data, { [name]: { $set: value } }) });
  };

  onFileChange = (eventData) => {
   // bug GP3-148
    if (eventData == "removed") {
      this.setState({ fileuuid: null });
    };

    if (eventData.isUploading) {
      const { file, isUploading } = eventData;
      let { data } = this.state;

      if (file.name && file.size) {
        data = update(data, { file: { $set: file } });
        this.setState({ isUploading, data });
      }
    } else if (typeof eventData.uploadProgress !== "undefined") {
      const { uploadProgress } = eventData;
      const isUploading = uploadProgress !== 100;
      this.setState({ isUploading, uploadProgress });
    } else if (typeof eventData.fileuuid !== "undefined") {
      const { fileuuid } = eventData;
      this.setState({ fileuuid });
    } else if (typeof eventData.errorMsg !== "undefined") {
      const { errorMsg } = eventData;
      this.setState({
        apiError: errorMsg,
        showError: true,
        isUploading: false,
      });
    }
  };

  showpopupWarning = () => {
    const { data } = this.state;

    let result = validate(data, this.validationConfig);
    this.setState({ validationMsg: result || {} });
    if (!isEmpty(result)) {
      return;
    }
    this.setState({
      popupWarning: true,
    });
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb
          instanceof Function) cb();
      }
    );
  };

  handleSubmit = (event, { target }) => {
    const { data, projectId, fileuuid } = this.state;

    let result = validate(data, this.validationConfig) || {};
    if (!data.file && (!data.directLink || !data.directLink.length)) {
      result.directLink = ["You have not provided any url"];
      result.file = ["You have not selected any file"];
    }

    if (data.directLink && data.directLink.length) {
      const isValid = validateUrl(data.directLink);
      if (!isValid) {
        console.log("re", result);
        result.directLink = ["Please enter valid URL"];
      }
    }
    this.setState({ validationMsg: result || {} });
    if (!isEmpty(result)) {
      return;
    }

    let tagIds = data.tags && data.tags.map((_tag) => {return _tag.id});
    let id = projectId ? projectId : "new";

    let options = {
      name: data.documentname,
      filename: data.file && data.file.name,
      description: data.description,
      tagIds: tagIds,
      filetype: data.file && data.file.type,
      filesize: data.file && data.file.size,
      fileuuid,
      directLink: data.directLink,
    };

    this.setState({ isUploading: true, apiError: false });
    Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_DOCUMENT + "?id=" + id, options)
      .then((resp) => {
        this.setState({ redirectToViewProject: true, isUploading: false });
      })
      .catch((error) => {
        this.setState({
          apiError: "Failed to submit the document",
          isUploading: false,
        });
      });
  };

  componentDidUpdate() { }

  render() {
    const {
      apiError,
      data,
      uploadProgress,
      isUploading,
      validationMsg,
      projectId,
    } = this.state;
    const { tags } = data;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header" style={{ float: "left" }}>
            {" "}
            Document Form
          </div>
          <div className="empty-height"> </div>

          <Form error={apiError} loading={isUploading}>
            <Message
              error
              header="Document doesn't exist"
              content={apiError}
            />
            <InputField
              label="Document Name"
              errorMsg={validationMsg.documentname}
              isError={validationMsg.documentname}
              name="documentname"
              onChange={this.handleChange}
              value={data.documentname}
              maxlength="32"
              isRequired
            />
            <TextareaField
              label="Description"
              errorMsg={validationMsg.description}
              isError={validationMsg.description}
              name="description"
              onChange={this.handleChange}
              value={data.description}
              maxLength={250}
              isRequired
            />

            <Grid>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="ui form">
                  <div className="field required">
                    <label>Attachment</label>
                    <p className="custom-form-note">
                      ( Note: Please attached file or Add direct file. ){" "}
                    </p>
                  </div>
                </div>

                <DropFileField
                  errorMsg="You have not selected any file"
                  isError={validationMsg.file}
                  label=""
                  name="file"
                  onChange={Uploader(this.onFileChange)}
                  // isRequired
                  disabled={this.state.data.directLink ? true : false}
                  multipleSelection={false}
                  value={this.state.data && this.state.data.file && Object.values(this.state.data.file).map((_key) => {return _key.file})}
                />
                {isUploading && (
                  <Progress percent={uploadProgress} indicating />
                )}

                <div style={{ textAlign: "center", fontWeight: "bold" }}>
                  {" "}
                  —————— OR ——————{" "}
                </div>

                <InputField
                  label="Direct Link"
                  errorMsg={validationMsg.directLink}
                  isError={validationMsg.directLink}
                  name="directLink"
                  onChange={this.handleChange}
                  value={data.directLink}
                  disabled={this.state.fileuuid ? true : false}
                  maxlength="100"
                // isRequired
                />
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Form.Field required={true} error={validationMsg.tags}>
                  <label>Tags</label>
                  <Form.Dropdown
                    id="document-dropdown-tags"
                    name="tags"
                    options={this.state.options}
                    placeholder="Add Tags"
                    search
                    selection
                    multiple={
                    true
                    }

                    value={tags}
                    onChange={this.handleDropDownChange}
                    onSearchChange={e => this.onTagType(e)}
                    width={16}
                  />
                  <ErrorMessage isError={validationMsg.tags} errorMsg={validationMsg.tags} />
                </Form.Field>
              </Grid.Column>
            </Grid>

            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Button
                    className="button-primary"
                    type="submit"
                    onClick={this.handleSubmit}
                  >
                    Save
                  </Button>

                  <Button
                    className="button-primary"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Close
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Confirm
            open={this.state.popupWarning}
            onCancel={this.hidepopupWarning}
            onConfirm={() => this.hidepopupWarning(this.handleSubmit)}
            content={"Are you sure you want to add new document?"}
          />
        </Segment>
        {this.state.redirectToViewProject ? (
          <Redirect to={{ pathname: "/projects/" + projectId }} />
        ) : (
          ""
        )}
        {this.state.redirectToProjects ? (
          <Redirect to={{ pathname: "/projects" }} />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authority: authoritySelector(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);