import React from "react";
import {
  Button,
  Icon,
  List,
  Label,
  Modal,
  Segment,
  Input,
  Grid,
  GridRow,
  GridColumn,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import ErrorMessage from "@/common/Form/subcomponents/error-message";
import DownloadOptionsBar from "./download-options-bar";

const BriefcaseDownload = ({
  files,
  modelTypeMap,
  onClose,
  show,
  isProtected,
  verifyPassword,
  onPasswordChange,
  verified,
  showError,
  onDownload,
}) => {
  const hasModelTypeMapValues = modelTypeMap && Object.keys(modelTypeMap).length > 0;

  return (
    <Modal
      open={show}
      onClose={onClose}
      centered={true}
      size="small"
      closeIcon={true}
    >
      <Modal.Header>Download Briefcase Items</Modal.Header>
      <Modal.Content scrolling>
        {isProtected ? (
          !verified ? (
            showError ? (
              <>
                <div>
                  <ErrorMessage isError={showError} errorMsg="wrong password" />
                  {/* <Label color="red">wrong password</Label> */}
                </div>{" "}
                <Input onChange={onPasswordChange}></Input>
                <Button onClick={verifyPassword}> Verify</Button>
                <div>
                  <em>Enter Password to download Briefcase Items</em>
                </div>
                <List>
                  {files.map((file) => (
                    <List.Item>
                      <button onClick={onDownload}>
                        <a
                          style={{ cursor: "not-allowed" }}
                          href={null}
                          target="_blank"
                        >
                          <Label size="large">
                            <Icon size="large" name="download" />
                            {file.filename} {modelTypeMap && modelTypeMap[file.id]
                              ? "(" + modelTypeMap[file.id] + ")" : (hasModelTypeMapValues ? "(other files)" : "")}
                          </Label>
                        </a>
                      </button>
                    </List.Item>
                  ))}
                </List>{" "}
              </>
            ) : (
              <>
                <Input
                  onChange={onPasswordChange}
                  style={{ marginLeft: "10px" }}
                ></Input>
                <Button onClick={verifyPassword}> Verify</Button>
                <div>
                  <em>Enter Password to download Briefcase Items</em>
                </div>
                <List>
                  {files.map((file) => (
                    <List.Item>
                      <button onClick={onDownload}>
                        <a
                          style={{ cursor: "not-allowed" }}
                          href={null}
                          target="_blank"
                        >
                          <Label size="large">
                            <Icon size="large" name="download" />
                            {file.filename} {modelTypeMap && modelTypeMap[file.id]
                              ? "(" + modelTypeMap[file.id] + ")" : (hasModelTypeMapValues ? "(other files)" : "")}
                          </Label>
                        </a>
                      </button>
                    </List.Item>
                  ))}
                </List>{" "}
              </>
            )
          ) : (
            <>
              <ErrorMessage isError errorMsg="Verified" />
              {/* <Label color="green">Verified</Label>{" "} */}
              <List>
                {files.map((file) => (
                  <List.Item>
                    <button onClick={onDownload}>
                      <a href={file.link} target="_blank">
                        <Label size="large">
                          <Icon size="large" name="download" />
                          {file.filename} {modelTypeMap && modelTypeMap[file.id]
                            ? "(" + modelTypeMap[file.id] + ")" : (hasModelTypeMapValues ? "(other files)" : "")}
                        </Label>
                      </a>
                    </button>
                  </List.Item>
                ))}
              </List>
            </>
          )
        ) : (
           <Grid >
            

           

            {files.map((file) => (
              <GridRow className="briefcase-download-popup-list" >
                <GridColumn mobile={16} tablet={16} computer={16}  largeScreen={16} widescreen={16} verticalAlign='middle'>
                  <span className="label">
                    {file.filename} { modelTypeMap && modelTypeMap[file.id]
                            ? "(" + modelTypeMap[file.id] + ")" : (hasModelTypeMapValues ? "(other files)" : "")}
                  </span>
                </GridColumn>

                <GridColumn mobile={16} tablet={16} computer={16}  largeScreen={16} widescreen={16}>
                  <DownloadOptionsBar file={file} onDownload={onDownload}  />
                </GridColumn>
              </GridRow>
            ))} 
          </Grid>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button primary onClick={onClose} className="mr-0-im mt-0-im">
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

BriefcaseDownload.propTypes = {
  /** urls which will be used as `href` for downloading files. */
  files: PropTypes.array.isRequired,
  /** Callback which is fired when Popup is closed. */
  onClose: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
};

BriefcaseDownload.defaultProps = {
  files: [],
};

export default BriefcaseDownload;
