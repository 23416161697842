import actions from "../actions";

const initialState = {
  tickets: {},
  loading: false,
  errors: {
    isError: false,
    message: "",
  },
  articles: [],
  article: {},
  maxSequence: 0,
  existingSequence: {},
  pagination: {
    page: 0,
    perPage: 10,
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.Zendesk.INITIATE_GET_TICKETS:
      return {
        ...state,
        loading: true,
      };
    case actions.Zendesk.GET_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: payload,
        loading: false,
      };
    case actions.Zendesk.GET_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case actions.Zendesk.INITIATE_GET_ARTICLES:
      return {
        ...state,
        loading: true,
      };
    case actions.Zendesk.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: payload.data,
        pagination: {
          ...state.pagination,
          page: payload.page,
        },
        loading: false,
      };
    case actions.Zendesk.GET_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case actions.Zendesk.ADD_ARTICLES_INITIATE:
      return {
        ...state,
        loading: true,
      };
    case actions.Zendesk.ADD_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.Zendesk.ADD_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {
          isError: true,
          message: payload,
        },
      };
    case actions.Zendesk.GET_ARTICLE_BY_ID_INITIATE:
      return {
        ...state,
        loading: true,
      };
    case actions.Zendesk.GET_ARTICLE_BY_ID_SUCCESS:
      return {
        ...state,
        article: payload,
        loading: false,
      };
    case actions.Zendesk.GET_ARTICLE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {
          isError: true,
          message: payload,
        },
      };
      case actions.Zendesk.UPDATE_ARTICLE_INITIATE:
      return {
        ...state,
        loading: true,
      };
    case actions.Zendesk.UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        article: payload,
        loading: false,
      };
    case actions.Zendesk.UPDATE_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {
          isError: true,
          message: payload,
        },
      };
      case actions.Zendesk.GET_MAX_SEQUENCE_INITIATE:
        return {
          ...state,
          loading: true,
        };
      case actions.Zendesk.GET_MAX_SEQUENCE_SUCCESS:
        return {
          ...state,
          maxSequence: payload,
          loading: false,
        };
      case actions.Zendesk.GET_MAX_SEQUENCE_FAILURE:
        return {
          ...state,
          loading: false,
          errors: {
            isError: true,
            message: payload,
          },
        };
        case actions.Zendesk.GET_EXISTING_SEQUENCE_INITIATE:
        return {
          ...state,
          loading: true,
        };
      case actions.Zendesk.GET_EXISTING_SEQUENCE_SUCCESS:
        return {
          ...state,
          existingSequence: payload,
          loading: false,
        };
      case actions.Zendesk.GET_EXISTING_SEQUENCE_FAILURE:
        return {
          ...state,
          loading: false,
          errors: {
            isError: true,
            message: payload,
          },
        };
      case actions.Zendesk.GET_ZENDESK_SUPPORT_URL:
        return {
          ...state,
          loading: true
        };
      case actions.Zendesk.GET_ZENDESK_SUPPORT_URL_RESPONSE:
        return {
          ...state,
          loading: false,
          payload
        };
      case actions.Zendesk.SET_PAGE_NUMBER:
        return {
          ...state,
          pagination: {
            ...state.pagination,
            page: payload
          },
        };
    default:
      return {
        ...state,
      };
  }
};
