import { CardviewMobile } from "@/common/cardview";
import Tableview from "@/common/tableview";
import Api from "@api";
import urls from "@constants";
import update from "immutability-helper";
import { merge } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Button, Grid, Modal, Responsive } from "semantic-ui-react";

class PackageListing extends React.Component {
  Constants = {
    COLUMNS: [
      { name: "Package Name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Package Name");
      }, },
      { name: "Created By", key: "createdBy", showTooltip: true, cb: () => {
        this.sortByHeader("createdBy", "Created By");
      }, },
      { name: "status", key: "status", showTooltip: true, cb: () => {
        this.sortByHeader("status", "status");
      }, },
    ],
    RESPONSIVE_COLUMNS: [
      { name: "Package Name", key: "name" },
      { name: "Created By", key: "createdBy" },
      { name: "status", key: "status" },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      PackageList: {},
      configOption: {},
      selectedPackages: [],
      selectedStatedPackages: [],
      selectedPackagesIndexes: [],
      checkedPackagesIds: [],
      isDescSearch: true,
      sortBy: "creationDate,DESC",
    };
    
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.onSearchClicked();
    }
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.COLUMNS = this.Constants.COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchUsers();
    });
   
  };

  fetchUsers = () => {
    const { URL_PACKAGE_LIST } = urls.App;
    let { configOption } = this.state;
    const { entity, entityId } = this.props;
    configOption = merge(configOption,{sort:this.state.sortBy}) 

    const paramsForPerspective = {
      entity,
      entityId,
    };
    // entityId concat configOptions
    const params = {
      params: merge(
        configOption,
        entity && entityId ? paramsForPerspective : {}
      ),
    };
    const parameterizedUrl = `${URL_PACKAGE_LIST}`;
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(parameterizedUrl, params).then((resp) => {
      this.setState({
        PackageList: resp.data,
      });
    });
  };
  onItemSelection = (e) => {
    let selectedStatedPackages = this.state.selectedStatedPackages;
    let ids = this.state.checkedPackagesIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedPackages.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedPackages.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedPackages = selectedStatedPackages.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedPackages.map((s) => s.id);
    }
    this.setState({ checkedPackagesIds: ids });

    this.setState({
      selectedStatedPackages: selectedStatedPackages,
    });
  };

  onAllItemSelection = (selectedPackages, flag) => {
    let {selectedStatedPackages} = this.state;
    let ids=[];
    if(flag){
      selectedPackages.map((group)=>{
        selectedStatedPackages = selectedStatedPackages.filter((entity) => {
          return entity.id !== group.id;
        })
      });
    }else{
      selectedPackages.map((group)=>{
          if (group) {
            const alreadyPushed = selectedStatedPackages.find(
              (element) => element.id == group.id
            );
            if (!alreadyPushed) {
              selectedStatedPackages.push(group);
            } }
          } );
    }
    ids = selectedStatedPackages.map((s) => s.id);
    this.setState({
      selectedStatedPackages: selectedStatedPackages,
      checkedPackagesIds: ids,
       });
  }

  onPageChange = (e) => {
    const { configOption } = this.state;
    this.setState(
      { configOption: update(configOption, { page: { $set: e.page } }) },
      this.fetchUsers
    );
  };

  onSearchClicked = () => {
    // always fetch the first page while searching.
    // You never know if it has got more than one page data or not.
    this.onPageChange({ page: 0 });
  };

  onSearchTextChange = (event) => {
    const { configOption } = this.state;
    this.setState({
      configOption: update(configOption, {
        search: { $set: event.target.value },
      }),
    });
  };

  resetSearchConfig = () => {
    this.setState({ configOption: {} });
  };

  onModalClose = () => {
    this.resetSearchConfig();
    const { onDismiss } = this.props;
    if (onDismiss) onDismiss();
    this.setState({
      selectedStatedPackages: [],
      checkedPackagesIds: [],
    });
  };

  render() {
    const { 
      PackageList,
      selectedStatedPackages,
      selectedPackagesIndexes,
      checkedPackagesIds, } = this.state;
    const { onSave, show, selectionType } = this.props;
    const { COLUMNS, RESPONSIVE_COLUMNS } = this.Constants;

    return (
      <Modal
        open={show}
        onMount={this.fetchUsers}
        onClose={this.onModalClose}
        centered={true}
        size="large"
      >
        <Modal.Header>Select a Package to Edit</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row className="common-filter-wrapper ui form">
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                {" "}
                <div class="field">
                  <input
                    placeholder="Search"
                    className="search-bar"
                    onChange={(e) => this.onSearchTextChange(e)}
                    onKeyPress={this.onKeyUp}
                  ></input>
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div class="field">
                  <button
                    className="button-primary"
                    onClick={this.onSearchClicked}
                  >
                    Search
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination mt-2">
                    <Tableview
                      paginate
                      selectionType={selectionType}
                      data={PackageList}
                      columns={COLUMNS}
                      onSelectionChange={this.onItemSelection}
                      onAllSelectionChange={this.onAllItemSelection}
                      onPaginationChange={this.onPageChange}
                      checkedIndexes={selectedPackagesIndexes}
                      checkedIds={checkedPackagesIds}
                      noDataMessage="No Package found."
                    />
                  </div>
                </Responsive>

                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    paginate
                    selectionType={selectionType}
                    data={PackageList}
                    columns={RESPONSIVE_COLUMNS}
                    onSelectionChange={this.onItemSelection}
                    onAllSelectionChange={this.onAllItemSelection}
                    onPaginationChange={this.onPageChange}
                    noDataMessage="No Package found."
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="mt-2"
              >
                <Button
                  className="button-primary m--no-margin-left-im"
                  onClick={() => {
                    onSave(selectedStatedPackages);
                    this.setState({
                      selectedStatedPackages: [],
                      checkedPackagesIds: [],
                    });
                  }}
                >
                  Save
                </Button>
                <Button onClick={this.onModalClose} className="button-basic">
                  Close
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

PackageListing.propTypes = {
  /** Callback which is fired when Popup is closed. */
  onDismiss: PropTypes.func.isRequired,
  /** Callback which is fired when `resend` button is clicked. */
  onSave: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
  /** single-select = radio buttons, multi-select = checkboxes */
  selectionType: PropTypes.oneOf(["single-select", "multi-select"]),
  /** Perspective: */
  entity: PropTypes.oneOf(["PackageItem", "ItemPackage"]),
  /** */
  entityId: PropTypes.number,
};

PackageListing.defaultProps = {
  selectionType: "multi-select",
};

export default PackageListing;
