import Api from "@/api";
import FileIcon from "@/common/file-icon";
import Tableview from "@/common/tableview";
import Constants from "@/config/constants";
import { get, isEmpty } from "lodash";
import React from "react";
import Moment from "react-moment";
import { dateToPacific } from "@/utils/dateTime";
import { Confirm, Modal, Segment, Responsive, Grid } from "semantic-ui-react";
import { CardviewMobile } from "@/common/cardview";

class PackageItems extends React.Component {
  Constants = {
    GROUP_COLUMNS: Object.freeze([
      { name: "Item name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Item name");
      }, },
      { name: "Item type", key: "itemType", class: "center", showTooltip: true, },
      { name: "Description", key: "description", showTooltip: true, cb: () => {
        this.sortByHeader("description", "Description");
      }, },
      { name: "Item Created By", key: "modifiedBy", showTooltip: true, cb: () => {
        this.sortByHeader("modifiedBy", "Item Created By");
      }, },
      { name: "Item Updated On", key: "modifiedDate", showTooltip: true, cb: () => {
        this.sortByHeader("modifiedDate", "Item Updated On");
      }, },
      { name: "Action", key: "actions", class: "center" },
    ]),
    RESPONSIVE_GROUP_COLUMNS: Object.freeze([
      { name: "Item name", key: "name" },
      { name: "Item type", key: "itemType", class: "center" },
      { name: "Description", key: "description" },
      { name: "Item Created By", key: "modifiedBy" },
      { name: "Item Updated On", key: "modifiedDate" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedProducts: { content: [] },
      assignedItems: { content: [] },
      groups: {},
      errorMsg: null,
      showError: false,
      selectedItems: [],
      msg: null,
      action: null,
      deleteWarning: false,
      data: {},
      redirectTo: false,
      packageId: props.match.params.packageId,
      groupId: null,
      pageNum: 0,
      isDescSearch: true,
      sortBy: "",
      direction:"DESC",
    };
  }

  componentDidMount() {
    this.fetchAssignedItems();
  }

  // transformData
  transformData = (data) => {
    let transformedData = Object.assign({}, data.content);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.itemType = (
          <FileIcon
            filename={row.filename ? row.filename : "other"}
            mimetype={row.itemType}
          ></FileIcon>
        );
        row.modifiedDate = (
          <>{dateToPacific(row.modifiedDate, "MM/DD/YYYY hh:mm a")}</>
        );
        row.actions = (
          <a onClick={() => this.showDeleteWarningAndSetItem(row)}>
            {
              <span
                style={{ margin: "5px" }}
                className="icon-delete"
                title="Remove"
                name="remove"
              />
            }
          </a>
        );

        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  sortByHeader = (key, title) => {
    let sort = `${key}`;
    let dir = `DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      sort = `${key}`;
      dir = `ASC`;
    }
    this.setState({ sortBy: sort, direction:dir }, () => {
      this.Constants.GROUP_COLUMNS = this.Constants.GROUP_COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchAssignedItems();
    });
   
  };

  fetchAssignedItems = () => {
    const { packageId, assignedItems, groups } = this.state;
    if (packageId > 0) {
      // Need to add this constants file
      const { URL_PACKAGE_DETAILS } = Constants.App;
      const params = {
        params: { id: this.state.packageId, page: this.state.pageNum,
        target:"items",sort:this.state.sortBy,direction:this.state.direction },
      };
      const instance = Api.AxiosInstance.getInstance();
      instance.get(URL_PACKAGE_DETAILS, params).then((resp) => {
        const transformedData = this.transformData({
          content: resp.data.pkgItems,
        });
        this.setState({
          assignedItems: transformedData,
          isLoaded: true,
        });
      });
    }
  };

  doAssign = (data) => {
    this.setState(
      {
        selectedItems: data,
        action: "ADD_ITEM",
        msg: "Something went wrong while assigning new group(s) to package.",
      },
      () => {
        this.updatePackages();
      }
    );
  };

  onUnassign = () => {
    const { selectedItems } = this.state;

    if (isEmpty(selectedItems)) {
      this.setState({
        showError: true,
        errorMsg:
          "No Item selected for deletion. Please select one or more Items.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "DELETE_ITEM",
        "Something went wrong while unassigning the item(s) to package."
      );
    }
  };
  showDeleteWarningAndSetItem = (row) => {
    this.setState({
      deleteWarning: true,
      selectedItem: row,
      msg: "Something went wrong while unassigning the item(s) to package.",
      action: "DELETE_ITEM",
    });
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;

    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  updatePackages = () => {
    const packageId = get(this.props, ["match", "params", "packageId"]);
    const { action, msg, selectedItem } = this.state;
    const params = { params: { id: packageId } };
    let itemIds = [];
    itemIds.push({ id: selectedItem["id"] });
    //selectedItem.map(item => itemIds.push({ id: item.id }));
    let url = Constants.App.URL_PACKAGE_DETAILS;
    let payload = { action: action, items: itemIds };
    Api.AxiosInstance.getInstance()
      .post(url, payload, params)
      .then((resp) => {
        this.fetchAssignedItems();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: msg,
        });
      });
  };

  onPackageSelection = (e) => {
    this.setState({ selectedItems: e.data });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  onPageChange = (e) => {
    this.setState({ pageNum: e.page }, () => this.fetchAssignedItems());
  };

  render() {
    const { GROUP_COLUMNS, RESPONSIVE_GROUP_COLUMNS } = this.Constants;
    const { groups, assignedItems, errorMsg, showError } = this.state;
    return (
      <React.Fragment>
        <Segment basic></Segment>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Responsive minWidth={1024}>
              <div className="table-wrapper-with-pagination">
                <Tableview
                  //paginate
                  columns={GROUP_COLUMNS}
                  data={assignedItems}
                  onSelectionChange={this.onPackageSelection}
                  noDataMessage="No Items found."
                  //onPaginationChange={this.onPageChange}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={1023}>
              <CardviewMobile
                data={assignedItems}
                columns={RESPONSIVE_GROUP_COLUMNS}
                onSelectionChange={this.onPackageSelection}
                noDataMessage="No Items found."
                onPaginationChange={this.onPageChange}
              />
            </Responsive>
          </Grid.Column>
        </Grid.Row>

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>

        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updatePackages)}
          content={`Are you sure you want to delete this Item Packages(s)?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageItems;
