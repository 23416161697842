import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { get, debounce } from 'lodash';
import {
  Dropdown,
  Menu,
  Responsive,
  Grid,
  Icon,
  Form,
  Input,
  Confirm,
  Dimmer,
  Loader,
  Container,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import IdleTimer from "react-idle-timer";
import LocalStore from "@/store/persistence/local-storage";
import Config from "@/config/constants";
import Constants from "@/config/constants";
import { redirectToHelpdeskURL } from "@/common/utils/utils";
import NotificationsSidebar from "@/modules/notifications/components/notifications-sidebar";

const ADMINISTRATOR = Config.AccessRole.ADMINISTRATORS;
const EMPLOYEES = Config.AccessRole.EMPLOYEES;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
    this.state = {
      isOpen: false,
    };
    // this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.onAction = debounce(() => {
      if (this.props.config.success && this.props.user.isLoggedIn) {
        LocalStore.setValue('activity-time', (new Date()).getTime());
      }
    }, 5000, {'leading': true});
  }

  componentDidMount() {
    this.props.dispatchSupportTabStatus(LocalStore.getValue('supportEnabled'));
  }

  _onIdle(e) {
    LocalStore.setValue('idleTimeout', true);
    this.props.logout();
  }
  setIsOpen = (e) => {
    this.setState({
      isOpen: e,
    });
  };

  onRouteClick(route) {
    const { dispatch } = this.props;
    if (!route.onRouteClick) {
      return;
    }
    const showHideSupportErrorDialog = this.props.showHideSupportErrorDialog;
    const showHideMainLoader = this.props.showHideMainLoader;
    return function () {
      showHideMainLoader(true);
      route.onRouteClick().catch((error) => {
        showHideMainLoader(false);
        showHideSupportErrorDialog(true, error.response.data.error);
      });
    };
  }

  onHelpdeskOptionClick(url) {
    const showHideSupportErrorDialog = this.props.showHideSupportErrorDialog;
    const showHideMainLoader = this.props.showHideMainLoader;
    return function () {
      showHideMainLoader(true);
      redirectToHelpdeskURL(url).catch((error) => {
        showHideSupportErrorDialog(true, error.response.data.error);
        showHideMainLoader(false);
      });
    };
  }

  toggleNotificationsVisibility = () => {
    const isNotificationsVisible = get(this, ["props", "user", "notificationsSidebarVisible"], false);
    this.props.showHideMainOverlay(!isNotificationsVisible);
    if (isNotificationsVisible) {
      this.props.hideSidebarNotifications();
    } else {
      this.props.showSidebarNotifications();
    }
  }

  setIdleTimerRef = (ref) => {
    this.idleTimer = ref;
    this.props.setIdleTimerRef(ref);
  }

  handleSubmit(event) {
    this.props.history.push(`/global-search/${this.state.keyword}`);
    this.setState({ keyword: '' });
    event.preventDefault();
  }

  signOut = () => {
    this.props.logout();
  };

  render() {
    const { location, logout, routes, user, userAccessRole, visible, config } =
      this.props;
    const notificationsCount = get(user, ["notificationsCount", "count"], 0);
    const isNotificationsVisible = get(user, ["notificationsSidebarVisible"], false);
    const username = get(user, ['user', 'name']);
    const fullName = get(user, ['user', 'principal', 'name']);
    const picture = get(user, ['user', 'principal', 'picture']);
    
    // handling , just incase inactivity timeout is not set
    const { allowedIdleTime } = config;
    const helpFile =
      ADMINISTRATOR.indexOf(this.props.authority) >= 0
        ? "help.html"
        : this.props.authority === "websilo-employee"
          ? "help_employee.html"
          : this.props.authority === "websilo-account-approver"
            ? "help_approver.html"
            : this.props.authority === 'websilo-customer'
            ? 'HelpGuide_Customer.html'
            : 'HelpGuide_Public.html';
    return (
      <React.Fragment>
        <IdleTimer
          ref={this.setIdleTimerRef}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={allowedIdleTime}
        />
        {/* Mobile   */}
        <Responsive
          as="Menu"
          minWidth={320}
          maxWidth={767}
          className="responsive-menu-wrapper"
        >
          <Grid>
            <Grid.Row>
              {user.isLoggedIn && (
                <Grid.Column
                  mobile={10}
                  tablet={8}
                  computer={8}
                  largeScreen={4}
                  widescreen={4}
                >
                  <div>
                    {visible ? (
                      <span
                        className="icon-ico-menu-close btn-responsive"
                        onClick={this.props.toggle}
                      />
                    ) : (
                      <span
                        className="icon-menu btn-responsive"
                        onClick={this.props.toggle}
                      />
                    )}

                    <a href="#" className="app-logo">
                      <img src="nlogo.svg" />
                    </a>
                  </div>
                </Grid.Column>
              )}

              {/* <Grid.Column mobile={8} tablet={8}>
               
              </Grid.Column> */}

              <Grid.Column
                mobile={6}
                tablet={8}
                computer={8}
                largeScreen={4}
                widescreen={4}
              >
                {user.isLoggedIn && (
                  <>
                    <div className="login-user-details">
                      <div className="button-notification mr-1-im" onClick={this.toggleNotificationsVisibility}>
                        <span class="icon-notification">
                          {notificationsCount > 0 && <span className="number-box">
                            {notificationsCount}
                          </span>}
                        </span>
                      </div>
                      <div className="lud-inner">
                        <Dropdown
                          pointing
                          icon={null}
                          trigger={<>
                            { !picture && <span className="icon-g1-state1">
                              {" "}
                              <span className="path1"></span>{" "}
                              <span className="path2"></span>{" "}
                              <span className="path3"></span>{" "}
                              </span>
                            }
                            { picture && <img src={picture} class="profile-picture"/> }
                            </>
                          }
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <span className="login-user-name">{`${!!username ? username : "User"
                                }`}</span>
                            </Dropdown.Item>
                            {(config.supportEnabled && (userAccessRole == Config.AccessRole.CUSTOMER || userAccessRole == Config.AccessRole.PUBLIC_NON_NDA_CUSTOMER)) && <Dropdown.Item>
                              <Link to="#" onClick={this.onHelpdeskOptionClick(Constants.App.SUBMIT_A_TICKET)}>Submit a Request</Link>
                            </Dropdown.Item>}
                            {(config.supportEnabled && (userAccessRole == Config.AccessRole.CUSTOMER|| userAccessRole == Config.AccessRole.PUBLIC_NON_NDA_CUSTOMER)) && <Dropdown.Item>
                              <Link to="#" onClick={this.onHelpdeskOptionClick(Constants.App.MY_REQUESTS)}>
                                My Requests
                              </Link>
                            </Dropdown.Item>}
                            <Dropdown.Item>
                              <Link to={`/profile`}>Profile</Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link to="#"
                                onClick={(e) => {
                                  window.open(
                                    `${window.location.protocol}//${window.location.host}/static/${helpFile}`,
                                    "_blank",
                                    "width=800,height=600"
                                  );
                                }}
                              >
                                Site Help
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link to="#" onClick={this.signOut}>Sign Out</Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                  </>

                )}
              </Grid.Column>
            </Grid.Row>

            {user.isLoggedIn && (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className="ui form header-search-wrapper">
                    <div class="field">
                      <Input
                        icon={
                          <Icon
                            name="search"
                            link
                            onClick={() => {
                              this.props.history.push(
                                `/global-search/${this.state.keyword}`
                              );
                              this.setState({ keyword: '' });
                            }}
                          />
                        }
                        placeholder="Type your keyword here"
                        value={this.state.keyword}
                        maxLength="64"
                        onChange={(e) =>
                          this.setState({ keyword: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Responsive>

        {/* Mobile - End */}

        {/* Tablet */}
        <Responsive
          as="Menu"
          minWidth={768}
          maxWidth={1024}
          className="responsive-menu-wrapper"
        >
          <Grid>
            <Grid.Row className="app-header-wrapper">
              {user.isLoggedIn && (
                <Grid.Column
                  mobile={8}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                  widescreen={8}
                >
                  <div>
                    {visible ? (
                      <span
                        className="icon-ico-menu-close btn-responsive"
                        onClick={this.props.toggle}
                      />
                    ) : (
                      <span
                        className="icon-menu btn-responsive"
                        onClick={this.props.toggle}
                      />
                    )}

                    <a href="#" className="app-logo">
                      <img src="nlogo.svg" />
                    </a>
                  </div>
                </Grid.Column>
              )}
              <Grid.Column
                mobile={8}
                tablet={8}
                computer={8}
                largeScreen={8}
                widescreen={8}
              >
                <Menu
                  size="large"
                  borderless
                  stackable
                  floated="right"
                  className="main-right-menu"
                >
                  {user.isLoggedIn && (
                    <>
                      <div className="login-user-details">
                      { !picture && <span className="icon-g1-state1">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span> 
                        </span> }
                        { picture && <img src={picture} class="profile-picture"/> }
                        <div className="lud-inner">
                          <span className="login-user-name">{`${!!username ? username : "User"
                            }`}</span>

                          <Dropdown
                            item
                            text="My Activities"
                            className=""
                            pointing
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <Link to={`/profile`}>Profile</Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link to={`/notifications`}>Notifications</Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link to="#"
                                  onClick={(e) => {
                                    window.open(
                                      `${window.location.protocol}//${window.location.host}/static/${helpFile}`,
                                      "_blank",
                                      "width=800,height=600"
                                    );
                                  }}
                                >
                                  Site Help
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Link to="#" onClick={this.signOut}>Sign Out</Link>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="button-notification" onClick={this.toggleNotificationsVisibility}>
                        <span class="icon-notification">
                          {notificationsCount > 0 && <span className="number-box">
                            {notificationsCount}
                          </span>}
                        </span>
                      </div>
                    </>
                  )}
                </Menu>
              </Grid.Column>
            </Grid.Row>

            {user.isLoggedIn && (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className="ui form header-search-wrapper">
                    <div class="field">
                      <Input
                        icon={
                          <Icon
                            name="search"
                            link
                            onClick={() => {
                              this.props.history.push(
                                `/global-search/${this.state.keyword}`
                              );
                              this.setState({ keyword: '' });
                            }}
                          />
                        }
                        placeholder="Type your keyword here"
                        value={this.state.keyword}
                        maxLength="64"
                        onChange={(e) =>
                          this.setState({ keyword: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Responsive>

        {/* Tablet - End */}

        {/* Desktop */}
        <Responsive as={Grid} minWidth={1025}>
          <React.Fragment>
            <Grid.Row className="app-header-wrapper">
              <Grid.Column
                mobile={16}
                tablet={10}
                computer={10}
                largeScreen={10}
                widescreen={10}
              >
                <Menu size="large" borderless stackable>
                  <Menu.Item>
                    <a href="#">
                      <img src="nlogo.svg" />
                    </a>
                  </Menu.Item>

                  {user.isLoggedIn &&
                    routes
                      .filter(
                        (route) =>
                          (typeof route.navbarVisibility === 'undefined' ||
                            route.navbarVisibility !== false) &&
                          (typeof route.accessRoles === 'undefined' ||
                            route.accessRoles.indexOf(userAccessRole) >= 0) &&
                          (!route.enableWithSupport ||
                            (route.enableWithSupport && config.supportEnabled))
                      )
                      .map((route) => (
                        <Link to={route.path} key={`link--${route.name}`}>
                          <Menu.Item
                            link
                            active={location.pathname.indexOf(route.path) >= 0}
                            onClick={this.onRouteClick(route) || (() => { })}
                            key={`menu-item--${routes.name}`}
                          >
                            {config.maps[route.configKey]
                              ? config.maps[route.configKey]
                              : route.name}
                          </Menu.Item>
                        </Link>
                      ))}
                </Menu>
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={6}
                computer={6}
                largeScreen={6}
                widescreen={6}
              >
                <Menu
                  size="large"
                  borderless
                  stackable
                  floated="right"
                  className="main-right-menu"
                >
                  {user.isLoggedIn && (
                    <div className="login-user-details">
                      { !picture && <span className="icon-g1-state1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </span> }
                      { picture && <img src={picture} className="profile-picture"/> }
                      <div className="lud-inner">
                        <span className="login-user-name">{`${!!username ? fullName : "User"
                          }`}</span>

                        <Dropdown
                          item
                          text="My Activities "
                          className=""
                          pointing
                        >
                          <Dropdown.Menu>
                            {(config.supportEnabled && (userAccessRole == Config.AccessRole.CUSTOMER || userAccessRole == Config.AccessRole.PUBLIC_NON_NDA_CUSTOMER)) && <Dropdown.Item>
                              <Link to="#" onClick={this.onHelpdeskOptionClick(Constants.App.SUBMIT_A_TICKET )}>Submit a Request</Link>
                            </Dropdown.Item>}
                            {(config.supportEnabled && (userAccessRole == Config.AccessRole.CUSTOMER || userAccessRole == Config.AccessRole.PUBLIC_NON_NDA_CUSTOMER)) && <Dropdown.Item>
                              <Link to="#" onClick={this.onHelpdeskOptionClick(Constants.App.MY_REQUESTS)}>
                                My Requests
                              </Link>
                            </Dropdown.Item>}
                            <Dropdown.Item>
                              <Link to={`/profile`}>Profile</Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link to={`/notifications`}>Notifications</Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link to="#"
                                onClick={(e) => {
                                  window.open(
                                    `${window.location.protocol}//${window.location.host}/static/${helpFile}`,
                                    '_blank',
                                    'width=800,height=600'
                                  );
                                }}
                              >
                                Site Help
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link to="#" onClick={this.signOut}>Sign Out</Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}

                  {user.isLoggedIn && (
                    <>
                      <div className="button-notification" onClick={this.toggleNotificationsVisibility}>
                        <span className="icon-notification">
                          {notificationsCount > 0 && <span className="number-box">
                            {notificationsCount}
                          </span>}
                        </span>
                      </div>


                      <div className="ui form main-header-search-wrapper">
                        <form onSubmit={this.handleSubmit}>
                          <div className="field">
                            <Input
                              icon={
                                <Icon
                                  name="search"
                                  link
                                  onClick={() => {
                                    this.props.history.push(
                                      `/global-search/${this.state.keyword}`
                                    );
                                    this.setState({ keyword: "" });
                                  }}
                                />
                              }
                              placeholder="Type your keyword here"
                              value={this.state.keyword}
                              maxLength="64"
                              onChange={(e) =>
                                this.setState({ keyword: e.target.value })
                              }
                            />
                          </div>
                        </form>
                      </div>
                    </>         
                  )}


                </Menu>
              </Grid.Column>
            </Grid.Row>


          </React.Fragment>
        </Responsive>
        {/* Desktop - End */}

        {user.isLoggedIn && <NotificationsSidebar visible={isNotificationsVisible} />}
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  // ToDo: to be defined. The reason I am leaving it for now is, I am not sure
  // about their propTypes. These objects (inside props) are very complex and
  // needs to be simplified. One possible reason can be that props data is not
  // being retrieved properly from Redux store, and its containg too much extra
  // irrelavant data.
  toggle: PropTypes.func.isRequired,
};

export default withRouter(Header);
