import React from "react";
import { Button, Icon } from "semantic-ui-react";
import PDFviwer from "../../../../common/pdf-viewer";

class DownloadOptionsBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableCurl: false,
      disableDownload: false,
      disableWget: false,
      isCopied: false,
    };
  }

  copyToClipboard(url, type) {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    type === "link"
      ? this.setState({ disableDownload: true })
      : type === "curl"
      ? this.setState({ disableCurl: true })
      : this.setState({ disableWget: true });
    this.setState({ isCopied: true });
    setTimeout(() => {
      this.setState({
        isCopied: false,
        disableDownload: false,
        disableWget: false,
        disableCurl: false,
      });
    }, 500);
  }

  render() {
    //todo : this need to be fixed according to ui layout
    const grayBtn = {
      backgroundColor: "#767676",
      color: "rgba(0,0,0,.6)",
      textShadow: "none",
      cursor: "pointer",
      display: "inline-block",
      minHeight: "1em",
      outline: "0",
      border: "none",
      verticalAlign: "baseline",
      background: "#e0e1e2 none",
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      margin: "0 .25em 0 0",
      padding: ".78571429em 1.5em .78571429em",
      textTransform: "none",
      fontWeight: "700",
      lineHeight: "1em",
      fontStyle: "normal",
      textAlign: "center",
      textDecoration: "none",
      borderRadius: ".28571429rem",
      boxShadow:
        "0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      userSelect: "none",
      transition:
        "opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease",
      willChange: "''",
      WebkitTapHighlightColor: "transparent",
      backgroundImage: "none",
    };

    const { file, onDownload } = this.props;
    return (
      <div className="download-action">
        {this.state.isCopied && (
          <span className="copy-msg">Copied to clipboard</span>
        )}
        <PDFviwer
          filename={file.filename}
          itemType={file.itemType}
          url={file.link}
          style={grayBtn}
        ></PDFviwer>
        <a href={file.link} target="_blank" title="Download now">
          <Button className="button-basic mini" onClick={onDownload}>
            <span class="icon-download" title="Download">
              {" "}
            </span>
          </Button>
        </a>
        <a
          title="Copy download link to clipboard"
          onClick={() => this.copyToClipboard(file.directLink, "link")}
        >
          <Button
            className="button-basic mini"
            disabled={this.state.disableDownload}
          >
            <span class="icon-external-link2" title="Download Link">
              {" "}
            </span>
          </Button>
        </a>
        <a
          title="Copy cURL command for CLI to clipboard"
          onClick={() => this.copyToClipboard(file.curlCommand, "curl")}
        >
          <Button
            className="button-basic mini"
            disabled={this.state.disableCurl}
          >
            cURL
          </Button>
        </a>
        <a
          title="Copy Wget command for CLI to clipboard"
          onClick={() => this.copyToClipboard(file.wgetCommand, "wget")}
        >
          <Button
            className="button-basic mini"
            disabled={this.state.disableWget}
          >
            Wget
          </Button>
        </a>{" "}
      </div>
    );
  }
}

export default DownloadOptionsBar;
