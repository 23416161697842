import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PACIFIC_TIME_ZONE, STANDARD_DATE, STANDARD_TIME } from '@/utils/dateTime';

const NotificationsRow = (props) => {
  const { senderName, senderPicture, content, createdAt, isRead, type, entityId, userId, markSeen, markUnseen } = props;
  const initials = senderName ? senderName.split(' ').map((part) => part ? part.charAt(0).toUpperCase() : '').join('') : 'AU';
  const momentObj = moment.tz(createdAt, PACIFIC_TIME_ZONE);
  const date = momentObj.format(STANDARD_DATE);
  const time = momentObj.format(STANDARD_TIME);
  const isInvitation = content.indexOf('invited') > -1;

  const linkOptions = (() => {
    switch (type) {
      case 'PROJECT_INVITATION':
      case 'DOCUMENT_ADD_USER':
      case 'NEW_DOCUMENT_ADD_GROUP_USER':
      case 'PROJECT_WALL_POST':
      case 'PROJECT_DOCUMENT':
      case 'PROJECT':
        return { text: 'Go to Project', link: (isInvitation ? 'projects/invites/all' : '/projects/' + entityId) };
      case 'VIDEO_POST_ADD_GROUP_USER':
      case 'VIDEO_POST_ADD_USER':
      case 'TRAINING_VIDEO':
        return { text: 'Go to Videos', link: '/videos' };
      case 'BRIEFCASE_ITEM':
        return { text: 'Go to Briefcase', link: '/briefcase/inbox/' + userId };
      case 'DOCUMENT':
        const { redirectUrl } = props;
        return { text: 'Go to Downloads', link: redirectUrl ? ('/downloads/view' + redirectUrl) : '/downloads' };
      default:
        return false;
    }
  })();
  
  return (
    <>
      <div className={'notification-item ' + (isRead ? '' : 'unread')}>
        { !senderPicture && <div className='initials-box' title={ initials === 'AU' ? 'Anonynous User' : senderName}>
            { initials }
          </div> }
          { senderPicture && <img className='initials-box' src={senderPicture} /> }
          <div className='notify-content'>
            <div className='description'>
              <div
              className="label"
              dangerouslySetInnerHTML={{__html: content}}></div>
            <div className='time'>
              {time}
                <small>
                  {date}
                </small>
              </div>
            </div>
            <div className='more-details'>
            <Link
               target="_blank" 
              to={linkOptions.link}
              onClick={() => markSeen(props)}
              className='link'>
                {linkOptions.text}
            </Link>
            {
              markUnseen && isRead &&  <Link
              onClick={() => markUnseen(props)}
              className='link'>
                Mark as Unread
            </Link>
            }
            </div>
          </div>
        </div>
    </>
  );
}

export default NotificationsRow;