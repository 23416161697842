import React from "react";
import PropTypes from "prop-types";
import { Form, Icon, Label, Radio, Segment } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerWithTimezone from "../../../../utils/DatePickerWithTimeZone";

class ExpirationPeriod extends React.Component {
  Constants = {
    EXPIRY_TYPE: Object.freeze({ NEVER: "NEVER", DAYS: "DAYS", DATE: "DATE" }),
  };

  constructor(props) {
    super(props);
    const { EXPIRY_TYPE } = this.Constants;
    this.state = {
      expireDays: 7,
      expireDate: props.defaultExpiration,
      expireType:
        (props.editMode && props.defaultExpiration && EXPIRY_TYPE.DATE) ||
        (props.editMode && !props.defaultExpiration && EXPIRY_TYPE.NEVER) ||
        EXPIRY_TYPE.NEVER,
    };
  }

  onInputChange = (event) => {
    this.setState({ expireDays: event.target.value }, this.notifyChange);
  };

  onDateChange = (date) => {
    this.setState({ expireDate: date }, this.notifyChange);
  };

  onClick = (val) => {
    this.setState({ expireType: val }, this.notifyChange);
  };

  notifyChange = () => {
    const { expireType, expireDays, expireDate } = this.state;
    const { format, onChange } = this.props;
    const { EXPIRY_TYPE } = this.Constants;

    if (expireType === EXPIRY_TYPE.DAYS) {
      onChange(expireType, expireDays);
    } else if (expireType === EXPIRY_TYPE.DATE) {
      onChange(expireType, moment(expireDate).format(format));
    } else {
      onChange(expireType, "NULL");
    }
  };

  render() {
    const { expireDays, expireDate, expireType } = this.state;
    const { EXPIRY_TYPE } = this.Constants;
    const { isError, errorMsg, availableDate } = this.props;

    return (
      <Segment className="p-0">
        {isError && (
          <Label basic color="red" pointing="below">
            {errorMsg}
          </Label>
        )}
        <Form.Field required>
          <label>Expiration Period</label>
        </Form.Field>
        <Form.Field>
          <Radio
            label="Never Expires"
            checked={expireType === EXPIRY_TYPE.NEVER}
            onClick={() => {
              this.onClick(EXPIRY_TYPE.NEVER);
            }}
          />
        </Form.Field>
        <Form.Field className="inline-items">
          <Radio
            label=""
            checked={expireType === EXPIRY_TYPE.DAYS}
            onClick={() => {
              this.onClick(EXPIRY_TYPE.DAYS);
            }}
          />
          <span>
            <label>
              Expires in
              <input
                type="number"
                style={{ width: "75px" }}
                value={expireDays}
                onChange={this.onInputChange}
                onClick={() => {
                  this.onClick(EXPIRY_TYPE.DAYS);
                }}
                min="1"
              />
              <span> day(s) from Available Date</span>
            </label>
          </span>
        </Form.Field>
        <Form.Field>
          <Radio
            label={
              <label>
                <span>Expires on </span>
                <DatePickerWithTimezone
                  selected={expireDate}
                  onChange={this.onDateChange}
                  minDate={availableDate}
                  timezone="America/Los_Angeles"
                />
              </label>
            }
            checked={expireType === EXPIRY_TYPE.DATE}
            onClick={() => {
              this.onClick(EXPIRY_TYPE.DATE);
            }}
          />
        </Form.Field>
      </Segment>
    );
  }
}

ExpirationPeriod.propTypes = {
  /** Set a date on UI.
   * Only intended for edit mode where there is already a expiration date present.
   */
  defaultExpiration: PropTypes.string,
  /** Will highlight a default selection.
   * Only intended for edit mode where there is already a expiration date present.
   */
  editMode: PropTypes.bool,
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** Format of the data/time. */
  format: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
};

export default ExpirationPeriod;
