import { connect } from "react-redux";
import Dashboard from "./dashboard";
import Config from "@/config/constants";
import {
  authoritySelector,
  userIdSelector,
  ccpaSelector,
  gdprSelector,
} from "@/common/selectors";

const mapDispatchToProps = () => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    authority: authoritySelector(state),
    userId: userIdSelector(state),
    ccpa: ccpaSelector(state),
    gdpr: gdprSelector(state),
  };
};

let mappedDashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default {
  name: "Home",
  configKey: "dashboard_menu_item_title",
  path: "/dashboard",
  component: mappedDashboard,
  isPrivateRoute: true,
  accessRoles: Config.AccessRole.ALL,
};
