import React from "react";
import { Divider, Segment, Accordion, Icon } from "semantic-ui-react";
import {dateToPacific} from "@/utils/dateTime";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userIdSelector } from "@/common/selectors";
import { Cardview } from "@/common/cardview";
import Api from "@/api";
import urls from "@/config/constants";
import FileIcon from "@/common/file-icon";

class ReceivedFilesCard extends React.Component {
  COLUMNS = [
    { name: "Status", key: "recipients.0.status", showTooltip: true, },
    { name: "From", key: "senderName", showTooltip: true, },
    { name: "Subject", key: "subject", showTooltip: true, },
    { name: "File Name", key: "filename", showTooltip: true, },
    { name: "Date", key: "sendDate", width: 6, showTooltip: true, },
    { name: "Type", key: "fileType", showTooltip: false, },
    { name: "Actions", key: "actionButtons" },
  ];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: { content: [] },
      isCollapsed: true,
      page: 0,
    };
  }

  componentDidMount() {
    this.load();
  }

  onPageChange = (e) => {
    this.setState({ page: e.page }, this.load);
  };

  load = (options) => {
    this.setState({ loading: true });
    const { page } = this.state;
    let params = {
      params: {
        page,
      },
    };
    Api.AxiosInstance.paginate(urls.App.URL_BRIEFCASE_RECEIVED_FILES, params)
      .then((resp) => {
        this.setState({
          data: resp.data,
          loading: false,
        });
      })
      .catch((err) => this.setState({ loading: false }));
  };

  transformData = (data) => {
    const { userId } = this.props;
    let transformedData = Object.assign({}, data);
    let content = [];
    transformedData.content.map((el) => {
      let row = Object.assign({}, el);
      row.actionButtons = (
        <>
          <Link to={`briefcase/inbox/${userId}`}>
            <span className="icon-edit" title="Edit" />
          </Link>
        </>
      );

      row.sendDate = <>{dateToPacific(el.sendDate,"MM/DD/YYYY")}</>;
      row.fileType =
        el.files.length === 0 ? (
          <i>-</i>
        ) : (
          <FileIcon
            filename={el.files.length > 1 ? "other" : el.files[0].filename}
            mimetype={el.files.length > 1 ? "multiple" : el.files[0].filetype}
          ></FileIcon>
        );
      row.filename =
        el.files.length === 0 ? (
          <i>File not available</i>
        ) : el.files.length > 1 ? (
          <i>Multiple files</i>
        ) : (
          el.files[0].filename
        );
    });
    transformedData.content = content;
    return transformedData;
  };

  render() {
    const { loading, data, isCollapsed } = this.state;
    let transformedData = this.transformData(data);
    return (
      <Segment className="dashboard-card">
        <Accordion>
          <Accordion.Title
            onClick={(e) => this.setState({ isCollapsed: !isCollapsed })}
          >
            <Icon
              name={isCollapsed ? "caret right" : "caret down"}
              color="blue"
            />
            <div className="side-marker" />
            <div className="header">Received Files</div>
          </Accordion.Title>
          <Accordion.Content active={!isCollapsed}>
            <Cardview
              data={transformedData}
              columns={this.COLUMNS}
              loading={loading}
              paginate
              onPaginationChange={(e) => this.onPageChange(e)}
            />
            {transformedData.content && (
              <React.Fragment>
                <Divider />
                <em>Showing top {transformedData.content.length} records</em>
              </React.Fragment>
            )}
          </Accordion.Content>
        </Accordion>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
});

export default connect(mapStateToProps, null)(ReceivedFilesCard);
