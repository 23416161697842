import React, { memo } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

function DocumentViewer({ uri }) {
  return (
    <React.Fragment>
      <div style={{ textAlign: "center", overflow: "auto" }} className="document-viewer-wrapper">
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={[{ uri }]}
          key={uri}
        />
      </div>
    </React.Fragment>
  );
}

export default memo(({ licenseLink }) => <DocumentViewer uri={licenseLink} />);
