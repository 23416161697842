import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import "semantic-ui-css/semantic.min.css";
import { oktaobj } from "@/modules/oktasignin/configs";
import Layout from "./layout";
import "./App.scss";
import { MainLoader } from "./common/loaders";
// const LazyLoadLayout = React.lazy(() => import("./layout"));
const oktaAuth = new window.OktaAuth(oktaobj.oidc);

const App = ({ routes, store }) => {
  return (
    // <Suspense fallback={<MainLoader />}>
      <Provider store={store}>
        <Layout routes={routes} oktaAuth={oktaAuth} />
      </Provider>
    // </Suspense>
  );
};

App.propTypes = {
  /** List of routes  */
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      // =========== ToDo: FILL THIS ==========
    })
  ).isRequired,
  /** ReDux Store object */
  store: PropTypes.object.isRequired,
};

export default App;
