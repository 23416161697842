import React from 'react'
import {Card, Image, List, Button} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import Api from "../../../../api"

function Panel(props) {
  return (
   <Card>
       <Card.Content className="dark-blue-background-shade" header={
         <React.Fragment >
           <Card.Header >{props.title}</Card.Header>
         </React.Fragment>
       } />
         <Card.Content>
         <Link to={`${props.projectId}/members/`}>view all</Link>

            <List relaxed>
              {
                props.data && props.data.length>0 && props.data.map((item, i) => {
                 return (
                   <List.Item>
                    <Image src='https://react.semantic-ui.com/images/wireframe/image.png' width='25' />
                    <List.Content>
                      <List.Header as='a'>{item.firstName} {item.lastName}</List.Header>
                    </List.Content>
                   </List.Item>
                 )
                })}
                {(!props.data || props.data.length==0) && 'No Members!'}
            </List>
         </Card.Content>
         {
           props.panelbuttons &&
           <Card.Content extra>
              {
               props.panelbuttons.map((item, i) => {
                  if(typeof(item.callback) !== 'undefined') {
                   return (
                    <Button size='mini' onClick={ item.callback }>{item.title}</Button>
                   )
                  }
               })
              }
           </Card.Content>
         }
   </Card>
  );
}

export default Panel
