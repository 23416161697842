import { get } from "lodash";
import { createSelector } from "reselect";

const userSelector = (state, props) => state.user;
const commonSelector = (state, props) => state.common;

const loggedInUserSelector = createSelector([userSelector], (user) =>
  get(user, ["loggedin"])
);

const authoritySelector = createSelector([loggedInUserSelector], (loggedIn) =>
  get(loggedIn, ["user", "authorities", 0, "authority"])
);

const userIdSelector = createSelector([loggedInUserSelector], (loggedIn) =>
  get(loggedIn, ["user", "principal", "id"])
);

const userNameSelector = createSelector([loggedInUserSelector], (loggedIn) =>
  get(loggedIn, ["user", "principal", "username"])
);

const configSelector = createSelector(
  [commonSelector],
  (commons) => commons.config
);

const restrictedEmailDomainsSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.restrictedDomains
);
const countriesSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.countries
);

const gdprSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.gdpr_info
);
const ccpaSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.ccpa_info
);

const categoriesSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.item_categories
);

const platformSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.platforms
);
const userTypesSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.user_types
);
const customerContactTypesSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.customerContactTypes
);
const groupTypesSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.user_groups
);
const statesSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.states
);

export {
  authoritySelector,
  loggedInUserSelector,
  userIdSelector,
  userNameSelector,
  configSelector,
  countriesSelector,
  categoriesSelector,
  platformSelector,
  statesSelector,
  restrictedEmailDomainsSelector,
  userTypesSelector,
  groupTypesSelector,
  customerContactTypesSelector,
  gdprSelector,
  ccpaSelector,
};
