import Axios from "../common";
import Constants from "../../config/constants";

export const notificationsList = async (page, limit = 15, offset = 0) => {
  return await Axios
    .getInstance()
    .get(Constants.App.URL_NOTIFICATIONS_LIST, {
      params: {
        page_no: page,
        page_size: limit,
        offset
      }
    });
  
}

export const notificationsCount = async () => {
  return await Axios
    .getInstance()
    .get(Constants.App.URL_NOTIFICATIONS_COUNT);
}

export const notificationsRecent = async (limit = 10) => {
  return await Axios
    .getInstance()
    .get(Constants.App.URL_NOTIFICATIONS_RECENT, { param: { limit }});
}

export const notificationMarkAsRead = async (notificationIds) => {
  return await Axios
    .getInstance()
    .post(Constants.App.URL_NOTIFICATIONS_MARK_AS_READ, { notificationIds });
}

export const notificationMarkAsUnread = async (notificationIds) => {
  return await Axios
    .getInstance()
    .post(Constants.App.URL_NOTIFICATIONS_MARK_AS_UNREAD, { notificationIds });
}

export default {
  notificationsList,
  notificationsCount,
  notificationsRecent,
  notificationMarkAsRead,
  notificationMarkAsUnread
}