import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid, List } from "semantic-ui-react";


const EditField = ({
  children,
  isPublic,
  onMakePublic,
  publicKey,
  disabled,
}) => {
  return(
    <Grid className="m-0 p-0">
    <Grid.Row verticalAlign='middle'>
      <Grid.Column mobile={2} tablet={1} computer={1}>
        <List horizontal>
          {/* <List.Item style={{ color: '#006699' }}>
            Make it public?
          </List.Item> */}
          <List.Item>
            <Checkbox checked={ isPublic } onChange={ (event, data) => onMakePublic(publicKey, data.checked) } disabled={disabled} />
          </List.Item>
        </List>
      </Grid.Column>
      <Grid.Column  mobile={14} tablet={15} computer={15} className="pr-0-im">
        { children }
      </Grid.Column>
    </Grid.Row>
    </Grid>
  );
};

EditField.propTypes = {
  /** Any valid HTML node which will be displayed below the label. */
  children: PropTypes.node.isRequired,
  /** determines if the checkbox is ticked or not. */
  isPublic: PropTypes.bool,
  /** The `key` attribute which is used in `onMakePublic` callback */
  publicKey: PropTypes.string.isRequired,
  /** The callback which is fired whenever `make public` checkbox is clicked */
  onMakePublic: PropTypes.func.isRequired,
  /** input field disabled*/
  disabled: PropTypes.bool,
};

export default EditField;
