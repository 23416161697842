import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "@/api";
import { decodeJWT } from "@/utils/jwt";

const userApi = API.User;

export default ({ history }) => {
  const dispatch = useDispatch();
  const unsubscribe = useSelector((state) => state.token.unsubscribe);

  useEffect(() => {
    const token = history.location.pathname.split("/")[2];
    if (token) {
      const decode = decodeJWT(token);
      dispatch(userApi.unsubscribeUser(decode.id));
    }
  }, [dispatch, history.location.pathname]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "50px",
      }}
    >
      {unsubscribe && unsubscribe.message}
      <a href="/">Go to Home</a>
    </div>
  );
};
