import Api from "@api";
import Constants from "@constants";
import React, { useEffect, useState } from "react";
import { Button, Card, Icon, Label, Form, TextArea } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import LicensePreview from "../../modules/products/products/components/license-modal";
import { configSelector, userIdSelector } from "../selectors";
import { connect } from "react-redux";
import { dateToPacific } from "../../utils/dateTime";

function DownloadFriendlyItems({ item, cancel, config }) {
  const [proceedToDownload, setProceedToDownload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);
  const [acceptLicense, setAcceptLicense] = useState(true);
  const [licenseLink, setLicenseLink] = useState(null);
  const pageParams = useParams();

  useEffect(() => {
    fetchLicenseLink();
  }, [fetchLicenseLink]);

  const instance = Api.AxiosInstance.getInstance();
  const { URL_FRIENDLY_NAME_DOWNLOAD_ITEM, URL_ITEMS_ASSOCIATED_LICENSE_LINK } =
    Constants.App;

  const itemToken = pageParams && pageParams.id ? pageParams.id : false;
  const params = {
    params: { name: itemToken, itemId: item.id },
    timeout: 0,
  };

  const fetchLicenseLink = () => {
    instance.get(URL_ITEMS_ASSOCIATED_LICENSE_LINK, params).then((resp) => {
      if (resp.data) {
        setLicenseLink(resp.data);
        setAcceptLicense(false);
      }
    });
  };
  const proceedToDownloadEvent = () => {
    if (licenseLink) {
      params.params.isAgreed = acceptLicense ? 1 : 0;
    }
    instance.get(URL_FRIENDLY_NAME_DOWNLOAD_ITEM, params).then((resp) => {
      setIsDownloading(false);
      setDownloadLink(resp.data.url);
    });
    setIsDownloading(true);
    setProceedToDownload(true);
  };
  const handleAcceptLicense = (type) => {
    if (type === "agree") {
      setAcceptLicense(true);
    } else {
      cancel();
    }
  };
  return (
    <React.Fragment>
      <div className="base-segment-wrapper product-file-downlaod">
        <div className="ui form mb-2-im">
          <Form.Input label="Name">
            <Label>
              <b>{item.name}</b>
            </Label>
          </Form.Input>

          {item.description && (
            <Form.Input label="Description">
              <Label className="info-text-area">{item.description}</Label>
            </Form.Input>
          )}
          {item.category && (
            <Form.Input label="Category ">
              <Label>
                <b>{config.maps.item_categories[item.category]}</b>
              </Label>
            </Form.Input>
          )}
          {item.platform && (
            <Form.Input label="Platform/OS">
              <Label>
                <b>{config.maps.platforms[item.platform]}</b>
              </Label>
            </Form.Input>
          )}
          {item.modifiedDate && (
            <Form.Input label="Updated on">
              <Label>
                <b>{dateToPacific(item.modifiedDate, "MM/DD/YYYY")}</b>
              </Label>
            </Form.Input>
          )}
          {item.location && (
            <Form.Input label="Location">
              <Label>
                <b>{item.location}</b>
              </Label>
            </Form.Input>
          )}
        </div>
      </div>
      <div className="ui divider mt-1-im"></div>
      {!proceedToDownload && (
        <Button
          onClick={proceedToDownloadEvent}
          disabled={isDownloading}
          className="button-primary m--no-margin-left-im"
        >
          Proceed to download
        </Button>
      )}
      {proceedToDownload && isDownloading && (
        <React.Fragment>
          <Icon loading name="spinner" /> Readying the file for download
        </React.Fragment>
      )}
      {proceedToDownload && !isDownloading && (
        <a href={downloadLink} target="_blank">
          <Button
            className="button-primary m--no-margin-left-im"
            disabled={isDownloading}
          >
            Download
          </Button>
        </a>
      )}{" "}
      <button onClick={cancel} className="button-basic">
        Cancel
      </button>
      {licenseLink && (
        <React.Fragment>
          <LicensePreview
            accept={handleAcceptLicense}
            cancel={handleAcceptLicense}
            isLicenseAttached={true}
            licenseLink={licenseLink}
            name={item.filename}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  config: configSelector(state),
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
const DownloadFriendlyItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadFriendlyItems);

export default DownloadFriendlyItem;
