import React from "react"
import { useState } from "react"
import MultiUploader from '@/common/uploader/multi';
import {DropFileField, InputField, TextareaField} from "@/common/Form"
import {
  Checkbox,
  Form,
  Grid,
  Modal,
  Progress,
  Segment,
  Divider,
  Confirm,
  GridRow,
} from "semantic-ui-react";
import { UserSelection } from "@/common/user-selection";

let BriefcaseShare = ({ 
                        onDataChange,
                        data,
                        validationMsg,
                        uploaders,
                      }) => {

  let fileChanger = (a, b) => {
    uploaders[b.name].onChange(a, b);
  };
  
  let filesProgress = (files) => {
    return (
      <>
        {
          Object.keys(files).map((key) => {
            const file = files[key];
            const { isUploading, uploader, file: fileObj, uploadProgress } = file;
            const isUploadingInProgress = uploader && uploader.processor && !uploader.processor.paused;

            return file && isUploading ? (
              <div className="file-list">
                <Progress percent={uploadProgress} indicating>
                  {fileObj && fileObj.name} ({uploadProgress.toFixed(1)}%)
                  &nbsp;&nbsp;
                  <small>
                    <a onClick={(e) => {
                      isUploadingInProgress ? uploader.pause() : uploader.unpause();
                      e.preventDefault();
                      }} href="#">
                      {isUploadingInProgress ? "Pause" : "Resume"}
                    </a>
                  </small>
                </Progress>
              </div>
            ) : (
              ""
            );
          })
        }
      </>
    );
  };
  
  return (
    <>
        <Grid className="share-model-grid">
            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <DropFileField
                    errorMsg="You have not selected any files."
                    isError={validationMsg.sourceFiles}
                    label="Source"
                    name="source"
                    onChange={fileChanger}
                    isRequired={false}
                    multipleSelection={true}
                    data
                    value={Object.values(data.sourceFiles).map((_key) => {return _key.file})}
                  />
                  {data.sourceFiles &&
                    filesProgress(data.sourceFiles)
                  }
                  <DropFileField
                    errorMsg="You have not selected any files."
                    isError={validationMsg.onnxFiles}
                    label="ONNX File"
                    name="onnx"
                    onChange={fileChanger}
                    isRequired={false}
                    multipleSelection={true}
                    value={Object.values(data.onnxFiles).map((_key) => {return _key.file})}
                  />
                  {data.onnxFiles &&
                    filesProgress(data.onnxFiles)
                  }
                  <DropFileField
                    errorMsg="You have not selected any files."
                    isError={validationMsg.containerFiles}
                    label="Container, or the output of pip list"
                    name="container"
                    onChange={fileChanger}
                    isRequired={false}
                    multipleSelection={true}
                    value={Object.values(data.containerFiles).map((_key) => {return _key.file})}
                  />
                  {data.containerFiles &&
                    filesProgress(data.containerFiles)
                  }
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <DropFileField
                    errorMsg="You have not selected any files."
                    isError={validationMsg.codeFiles}
                    label="Code"
                    name="code"
                    onChange={fileChanger}
                    isRequired={false}
                    multipleSelection={true}
                    value={Object.values(data.codeFiles).map((_key) => {return _key.file})}
                  />
                  {data.codeFiles &&
                    filesProgress(data.codeFiles)
                  }
                  <DropFileField
                    errorMsg="You have not selected any files."
                    isError={validationMsg.datasetFiles}
                    label="Dataset used for training/inference"
                    name="dataset"
                    onChange={fileChanger}
                    isRequired={false}
                    multipleSelection={true}
                    value={Object.values(data.datasetFiles).map((_key) => {return _key.file})}
                  />
                  {data.datasetFiles &&
                    filesProgress(data.datasetFiles)
                  }
                  <DropFileField
                    errorMsg="You have not selected any files."
                    isError={validationMsg.proxyFiles}
                    label="Proxy/representation model and data"
                    name="proxy"
                    onChange={fileChanger}
                    isRequired={false}
                    multipleSelection={true}
                    value={Object.values(data.proxyFiles).map((_key) => {return _key.file})}
                  />
                  {data.proxyFiles &&
                    filesProgress(data.proxyFiles)
                  }
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <TextareaField
                    label="Project Information"
                    errorMsg={validationMsg.projectInformation}
                    isError={validationMsg.projectInformation}
                    name="projectInformation"
                    placeholder="Include information about the hardware used, software versions, and system configuration"
                    maxLength={1000}
                    onChange={onDataChange}
                    value={data.projectInformation}
                  />
              </Grid.Column>
            </Grid.Row>
      </Grid>
      <hr/>
    </>
  );
}

export default BriefcaseShare;