const digitalSignatureValidations = {
  signerName: {
    presence: true,
    length: {
      maximum: 50,
    },
  },
  signType: {
    presence: true,
  },
  signDate: {
    presence: true,
  },
  signature: {
    presence: true,

    numericality: false,
  },
  signerTitle: {
    presence: true,
  },
  signerEmail: {
    presence: true,
    email: true,
  },
  signerPhone: {
    presence: true,
    format: {
      pattern: /^[\d ()+-]+$/,
      message:
        "^Phone format is invalid. It should only contain Numbers, +/- or ()",
    },
    length: {
      minimum: 14,
      message: "number should not be less than 10 digits",
    },
  },
};

const manualSignatureValidations = {
  signType: {
    presence: true,
  },
  fileuuid: {
    presence: true,
  },
};

export { digitalSignatureValidations, manualSignatureValidations };
