import React from 'react';
import { Icon, Item, Confirm } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ImageIcon from '../../../../assets/icon-image.png';

class ItemDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      showModal: false,
    };
  }

  doShowModal = () => this.setState({ showModal: true });

  onModalConfirm = (event, { itemid }) => {
    event.preventDefault();
    this.setState({ showModal: false }, () =>
      this.props.deleteCallback(itemid)
    );
  };
  onModalCancel = () => this.setState({ showModal: false });

  render() {
    const { item, showModal } = this.state;
    return (
      item && (
        <Item className="project-item">
          <div className='project-avatar-box'>
            <img src={item.fileurl} />
          </div>

          <Item.Content>
            <Item.Header className="width-100">
              <Link to={item.viewUrl} className="m--float-left mr-half-im">
                <strong className="items-heading">{item.title} </strong>
              </Link>

              <div className="m--float-right">
                {this.props.showEditButton && item.isAdmin && (
                  <Link to={item.editUrl} floated="right">
                    <span className="icon-edit" alt="Edit" title="Edit" />
                  </Link>
                )}
                {this.props.deleteCallback && item.isAdmin && (
                  <Link
                    to="#"
                    onClick={this.doShowModal}
                    floated="right"
                    className="pl-3"
                  >
                    <span
                      className="icon-delete delete-icon"
                      title="Delete"
                      alt="Delete"
                    />
                  </Link>
                )}
                <Confirm
                  open={showModal}
                  onCancel={this.onModalCancel}
                  onConfirm={this.onModalConfirm}
                  itemid={item.id}
                  content="Are you sure you want to delete this project?"
                />
              </div>
            </Item.Header>
            <div style={{ marginLeft: '5px' }}>
              {item.creationDate && (
                <Item.Extra className="m-0">
                  Date Created: {item.creationDate}
                </Item.Extra>
              )}
              {item.companies && (
                <Item.Extra>Companies: {item.companies.join(', ')}</Item.Extra>
              )}
              {item.groups && (
                <Item.Extra>Groups: {item.groups.join(', ')}</Item.Extra>
              )}
              {item.itemDetail && (
                <Item.Extra>
                  <b> Created By: </b>{' '}
                  <b className="items-upload-sub-heading">
                    {' '}
                    {item.itemDetail.name}{' '}
                  </b>
                </Item.Extra>
              )}{' '}
            </div>
          </Item.Content>
        </Item>
      )
    );
  }
}

export default ItemDetail;
