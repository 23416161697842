// Non nda standard list / edit
import Api from '@/api';
import {
  CountryField,
  DateField,
  DropdownField,
  FileField,
  InputField,
  PlainField,
  StateField,
  TextareaField,
  CountryCode,
  PhoneField,
} from '@/common/Form';
import { GroupSelection } from '@/common/group-selection';
import { configSelector } from '@/common/selectors';
import Uploader from '@/common/uploader';
import { removeEmptyKeys } from '@/common/utils/utils';
import urls from '@/config/constants';
import update from 'immutability-helper';
import { merge } from 'lodash';
import moment from 'moment';
import Moment from 'react-moment'
import React from 'react';
import { connect, ReactReduxContext } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider, Form, Grid, Segment, Confirm, Checkbox} from 'semantic-ui-react';
import validate from 'validate.js';
import { Redirect } from 'react-router';
import postalCodes from 'postal-codes-js';
import {
  acceptanceValidation,
  normalValidations,
  rejectionValidation,
  newNonStandardNDA,
  newNonStandardNDALink,
  newNonStandardNDAFile
} from './../validations/non-standard-nda';
import DatePickerWithTimezone from '../../../utils/DatePickerWithTimeZone';
import ExpirationPeriod from '../../briefcase/pages/briefcase-send/subcomponents/expiration-period';
import { addDays } from 'date-fns'

const statusOptions = [
  { key: 'PENDING', value: 'PENDING', text: 'PENDING' },
  { key: 'APPROVED', value: 'ACTIVE', text: 'ACTIVE' },
  { key: 'REJECTED', value: 'REJECTED', text: 'REJECTED' },
];

class NDANonStandardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DATE_FORMAT: 'YYYY-MM-DD',
      phonelocal: false,
      phoneform: true,
      codeform: true,
      code: '+1',
      local: false,
      localcountry: 'US',
      localpostal: '',
      localresult: true,
      data: {
        id: null,
        status: 'ACTIVE',
        company: '',
        initiatorName: '',
        initiatorEmail: '',
        initiatorPhone: '',
        address1: '',
        infoBeingDisclosed: '',
        specificUsage: '',
        ndaDuration: '',
        contactName: '',
        contactEmail: '',
        signerName: '',
        signerTitle: '',
        city: '',
        country: '',
        state: '',
        postCode: '',
        principleBusiness: '',
        expiryDate: '',
        effectiveDate: new Date(),
        ndaUrl: '',
        groups: [],
        filename: '',
        fileuuid: '',
        localpostalresult: false,
        statusNotificationEmail: "",
        hasNdaLink:0,
        expireType:'',
      },
      validationMsg: {},
      isError: false,
      msg: null,
      recipients: [],
      selectedItems: [],
      popupWarning: false,
    };
  }

  componentDidMount() {
    //TODO: fetch data and assign this to data:\
    const { id } = this.props.match.params;
    if (id) {
      this.getData();
      this.getEmployeesList();
    }
  }
  getData = () => {
    const { URL_NDA_NON_STANDARD_EDIT } = urls.App;
    const { id } = this.props.match.params;
    // console.log("props match params", this.props.match.params);
    const params = {
      params: {
        ndaId: id,
      },
    };
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(URL_NDA_NON_STANDARD_EDIT, params).then((resp) => {
      // console.log("response", resp);
      console.log(resp.data);
      this.setState({ data: resp.data });
      this.state.localcountry = resp.data.country;
      this.state.localpostal = resp.data.postCode;
      // this.state.expire= resp.data.ndaDuration>0?true:false
    });
  };

  getEmployeesList = () => {
    const { URL_LISTINGS_USERS } = urls.App;
    const params = {
      params: {
        roles: "websilo-sysadmin,websilo-account-approver,websilo-admin",
        size: 1000
      },
    };
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(URL_LISTINGS_USERS, params).then((resp) => {
      this.setState({ recipients: resp.data.content });
    });
  };
  formatCountryCode = (value) => {
    this.state.code = String(value);
    if (this.state.codeform) {
      var phone = String(this.state.data.initiatorPhone);
      if (phone[0] == '+') {
        this.state.code = phone.substring(0, phone.indexOf('('));
      }
      return this.state.code;
    } else {
      this.state.code = value;
      return value;
    }
  };
  countryCodeChange = (event, data) => {
    this.setState({ phoneform: false });
    this.setState({ codeform: false });
    this.setState({ code: data.value });
  };
  isNumeric(value) {
    return /^\d+$/.test(value);
  }
  formatphone = (value) => {
    var phone = String(value);
    if (this.state.phoneform) {
      var phone = String(value);
      var plusvalue = '';
      if (phone[0] == '+') {
        this.state.code = phone.substring(0, phone.indexOf('('));
        this.state.data.initiatorPhone = phone.substring(phone.indexOf('('));
        for (var i = phone.indexOf('('); i < phone.length; i++) {
          if (this.isNumeric(phone.charAt(i))) {
            plusvalue = plusvalue + phone.charAt(i);
          }
        }
        phone = plusvalue;
        phone =
          '(' +
          phone.substring(0, 3) +
          ') ' +
          phone.substring(3, 6) +
          ' ' +
          phone.substring(6, phone.length);
        value = phone;
        return phone;
      } else if (phone[0] == '(' && phone[4] == ')' && phone[5] == ' ') {
        return value;
      } else {
        if (phone != '') {
          phone =
            '(' +
            phone.substring(0, 3) +
            ') ' +
            phone.substring(3, 6) +
            ' ' +
            phone.substring(6, phone.length);
        }
        value = phone;
        return phone;
      }
    } else if (phone[0] == '+') {
      return phone.substring(phone.indexOf('('));
    } else {
      return phone;
    }
  };
  isNumeric(value) {
    return /^\d+$/.test(value);
  }
  onPhoneChange = (event, eventData) => {
    this.setState({ phoneform: false });
    this.setState({ codeform: false });
    let cond = event.target.value;
    const { data } = this.state;
    var phone = '';
    for (var i = 0; i < cond.length; i++) {
      if (this.isNumeric(cond.charAt(i))) {
        phone = phone + cond.charAt(i);
      }
    }
    if (phone.length < 3) {
      this.setState({
        data: update(data, { [eventData.name]: { $set: phone } }),
      });
    } else if (
      phone.length == 3 &&
      cond.charAt(cond.length - 1) == ')' &&
      cond.length == 5
    ) {
      this.setState({
        data: update(data, {
          [eventData.name]: { $set: phone.substring(0, 2) },
        }),
      });
    } else if (phone.length == 3) {
      this.setState({
        data: update(data, {
          [eventData.name]: { $set: '(' + phone.substring(0, 3) + ') ' },
        }),
      });
    } else if (phone.length > 3 && phone.length < 6) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set: '(' + phone.substring(0, 3) + ') ' + phone.substring(3),
          },
        }),
      });
    } else if (phone.length == 6 && this.state.local == false) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + ' ',
          },
        }),
      });
      this.setState({ local: true });
    } else if (phone.length == 6 && this.state.local == true) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set: '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6),
          },
        }),
      });
      this.setState({ local: false });
    } else if (phone.length >= 6 && phone.length < 12) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              '(' +
              phone.substring(0, 3) +
              ') ' +
              phone.substring(3, 6) +
              ' ' +
              phone.substring(6),
          },
        }),
      });
    } else if (phone.length >= 12) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              '(' +
              phone.substring(0, 3) +
              ') ' +
              phone.substring(3, 6) +
              ' ' +
              phone.substring(6, 11),
          },
        }),
      });
    }
  };

  checkPostalCode() {
    var res = postalCodes.validate(
      this.state.localcountry,
      this.state.localpostal
    );
    if (this.state.localpostal == '') {
      res = true;
    }
    this.state.data.localpostalresult = res;
    this.state.localresult = res;
  }

  showpopupWarning = () => {
    const { data } = this.state;
    const { id } = this.props.match.params;
    data.localpostalresult = this.state.localresult;
    let normalResult, extendedResult, urlOrFileResult, result;
    if (data.status == 'ACTIVE') {
      extendedResult = validate(removeEmptyKeys(data), acceptanceValidation);
    } else if (data.status == 'REJECTED') {
      extendedResult = validate(removeEmptyKeys(data), rejectionValidation);
    }
    if (id) {
      normalResult = validate(removeEmptyKeys(data), normalValidations);
    }
      const dataTemp = {... this.state.data}
      const emailAddresses = dataTemp.statusNotificationEmail

      if(emailAddresses !== null && emailAddresses!==undefined && emailAddresses!==""){
        emailAddresses.split(",").every(element =>{
          dataTemp.statusNotificationEmail = element;
          normalResult = validate(removeEmptyKeys(dataTemp), newNonStandardNDA);
          return !normalResult;
        })
      }
      else
        normalResult=validate(removeEmptyKeys(data), newNonStandardNDA)
      if(id===undefined || id === null){
        data.ndaUrl!== undefined && data.ndaUrl !== null && data.ndaUrl !== ''?
        urlOrFileResult = validate(removeEmptyKeys(data),newNonStandardNDALink):
        urlOrFileResult = validate(removeEmptyKeys(data),newNonStandardNDAFile)
      }
    result = merge(
      normalResult ? normalResult : '',
      extendedResult ? extendedResult : '',
      urlOrFileResult ? urlOrFileResult : '',
    );
    if (normalResult || extendedResult || urlOrFileResult) {
      this.setState({
        validationMsg: result,
        loading: false,
        isError: true,
        msg: 'Validation Issue Occurred. Please Fix before submission',
      });
      return;
    }
    this.setState({
      popupWarning: true,
    });
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  handleChange = (event, eventData) => {
    const { data } = this.state;
    if (eventData.name == 'country') {
      this.state.localcountry = eventData.value;
      this.setState({
        data: update(data, { [eventData.name]: { $set: eventData.value } }),
      });
      this.checkPostalCode();
    } else if (eventData.name == 'postCode') {
      this.state.localpostal = eventData.value;
      this.setState({
        data: update(data, { [eventData.name]: { $set: eventData.value } }),
      });
      this.checkPostalCode();
    } else {
      this.setState({
        data: update(data, { [eventData.name]: { $set: eventData.value } }),
      });
    }
  };
  handleFileChange = (eventData) => {
    const { data } = this.state;
    const { fileuuid } = eventData;
    const { id } = this.props.match.params;
    if (fileuuid) {
      if (id) {
        this.setState({
          data: update(data, {
            fileuuid: { $set: fileuuid },
          }),
        });
      } else {
        this.setState({
          data: update(data, {
            fileuuid: { $set: fileuuid },
          }),
        });
      }
    }
  };
  onExpirationChange = (type, value) => {
    const { data } = this.state;
    this.setState({
      data: update(data, {
        expireType: { $set: type },
        expiryDate: { $set: value },
      }),
    });
  };
  onGroupChange = (groups) => {
    const { data } = this.state;
    this.setState({
      data: update(data, { groups: { $set: groups } }),
    });
  };
  validateForm = (event) => {
    const { data } = this.state;
    const { id } = this.props.match.params;
    data.localpostalresult = this.state.localresult;
    let normalResult, extendedResult, urlOrFileResult,result;
    if (data.status == 'ACTIVE') {
      extendedResult = validate(removeEmptyKeys(data), acceptanceValidation);
    } else if (data.status == 'REJECTED') {
      extendedResult = validate(removeEmptyKeys(data), rejectionValidation);
    }
    if (id) {
      normalResult = validate(removeEmptyKeys(data), normalValidations);
    } else {
      const dataTemp = {... this.state.data}
      const emailAddresses = dataTemp.statusNotificationEmail

      if(emailAddresses !== null && emailAddresses!=="" && emailAddresses !==undefined){
        emailAddresses.split(",").every(element =>{
          dataTemp.statusNotificationEmail = element;
          normalResult = validate(removeEmptyKeys(dataTemp), newNonStandardNDA);
          return !normalResult;
        })
      }
      else
       normalResult=validate(removeEmptyKeys(data), newNonStandardNDA)
       data.ndaUrl!== undefined && data.ndaUrl !== null && data.ndaUrl !== ''?
       urlOrFileResult = validate(removeEmptyKeys(data),newNonStandardNDALink):
       urlOrFileResult = validate(removeEmptyKeys(data),newNonStandardNDAFile)
    }
    result = merge(
      normalResult ? normalResult : '',
      extendedResult ? extendedResult : '',
      urlOrFileResult ? urlOrFileResult : '',
    );
    if (!normalResult && !extendedResult && !urlOrFileResult) {
      this.setState({ validationMsg: {}, loading: true }, () => {
        this.save();
      });
    } else {
      this.setState({
        validationMsg: result,
        loading: false,
        isError: true,
        msg: 'Validation Issue Occurred. Please Fix before submission',
      });
    }
  };

  save = () => {
    const { data } = this.state;
    data.initiatorPhone = this.state.code + data.initiatorPhone;
    const { config } = this.props;
    const { URL_NDA_NON_STANDARD_SAVE } = urls.App;
    const { id } = this.props.match.params;

    const params = {
      params: {
        id,
      },
    };
    let body = {
      ...data,
    };
    // cross browser issue:
    body.effectiveDate = data.effectiveDate;
    // body.expiryDate = moment(data.expiryDate).format();

    //To override NDA Link over File UUID in case both are provided
    if(body.fileuuid !=undefined && body.fileuuid.length>0 && body.ndaUrl!=undefined && body.ndaUrl.length>0)
      body.fileuuid = ""

    if(body.expireType === ""){
      body.expireType = "DAYS"
      body.expiryDate = 6
    }
    if(body.expireType === "DAYS"){
      let expiryDays = new Date(body.effectiveDate)
      expiryDays.setDate(expiryDays.getDate()+parseInt(body.expiryDate))
      body.expiryDate = expiryDays
      body.expireType = "DATE"
    }else if(body.expireType === "NEVER")
      body.expiryDate = null

    const instance = Api.AxiosInstance.getInstance();
    return instance
      .post(URL_NDA_NON_STANDARD_SAVE, body, params)
      .then((resp) => {
        console.log('response after post', resp);
        let msg, isRedirect;
        if (id) {
          msg = 'Successfully Updated  NDA';
          isRedirect = false;
        } else {
          msg = 'Successfully Added  NDA';
          isRedirect = true;
        }
        this.setState({
          loading: false,
          msg,
          isError: false,
          redirectTo: isRedirect,
        });
      })
      .catch((err) => {
        let msg;
        if (id) {
          msg = 'Error Occurred While Updating NDA';
        } else msg = 'Error Occurred While Adding  NDA';
        this.setState({
          loading: false,
          msg,
          isError: true,
        });
      });
  };

  onSelectionChange = (e) => {
    const { data, selectedItems } = this.state;
    selectedItems.push({ id: e.target.value });
    this.setState({
      data: update(data, { recipients: { $set: selectedItems } }),
    });
  };

  download = (e, fileuuid,filename,filesize) => {
    //To Open Nda Links (filesize of Link Type NDAs is 0)
    if(filesize==0)
         window.open(filename)
    else{
    // To Download NDA File
    const { API_URL } = urls.App;
    const URL_DOWNLOAD_PENDING_NDA_DOC = API_URL + 'nda/download-non-standard';
    const { id } = this.props.match.params;
    // add fileuuid into the call as well...
    const params = {
      params: {
        id,
        fileuuid,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(URL_DOWNLOAD_PENDING_NDA_DOC, params)
      .then((resp) => {
        let win = window.open(resp.data, '_blank');
        win.focus();
      })
      .catch((err) =>
        this.setState({
          isError: true,
          msg: 'An error occured while downloading the NDA document.',
        })
      );
    }
  };

  render() {
    const {
      validationMsg,
      data,
      DATE_FORMAT,
      loading,
      msg,
      isError,
      recipients,
    } = this.state;
    const { id } = this.props.match.params;

    const color = isError ? 'red' : 'green';
    if (this.state.redirectTo) {
      return <Redirect to={'/admin/nda/non-standard'} />;
    }
    return (
      <Segment className="base-segment-wrapper">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
              <div className="header">
                <div className="side-marker" />
                {id ? 'Update  Non-Standard NDA' : 'Create Non Standard NDA'}
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
              <Form error={true} loading={loading} className="width-100">
          
                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={8}
                      computer={8}
                      largeScreen={8}
                    >    
                    </Grid.Column>
                  </Grid.Row>{' '}
                </Grid>
                <Grid>
                <Grid.Column
                      mobile={16}
                      tablet={8}
                      computer={8}
                      largeScreen={8}
                    >
                        <InputField
                          label="Enter Url For New NDA"
                          errorMsg={validationMsg.ndaUrl}
                          isError={validationMsg.ndaUrl}
                          name="ndaUrl"
                          onChange={this.handleChange}
                          value={data.ndaUrl}
                          className="m-0 p-0"
                          isRequired
                        />
                        <div style={{ textAlign: "center", fontWeight: "bold" }}>
                        {    " "}
                       —————— OR ——————{" "}
                      </div>
                      {id ? (
                        <FileField
                          label="New Nda File (PDF file only)"
                          name="file"
                          accept="application/pdf"
                          type="application/pdf"
                          onChange={ Uploader(this.handleFileChange)}
                          value={data.newNdaFile}
                          multipleSelection={false}
                        />
                      ) : (
                        <FileField
                          label="Upload Nda File (PDF file only)"
                          errorMsg="Please provide NDA URL or attach NDA File"
                          isError={validationMsg.fileuuid}
                          name="file"
                          accept="application/pdf"
                          type="application/pdf"
                          onChange={Uploader(this.handleFileChange)}
                          value={data.fileuuid}
                          isRequired
                          multipleSelection={false}
                        />
                      )}
                 
                        {data.history && (
                        <React.Fragment>
                          <Form.Field>
                            <label> History </label>
                            {data.history.map((history) => (
                              <div key={history.id}>
                                {history.filetype !== "URL"
                                ? <a
                                  href={window.location.href}
                                  onClick={(e) => this.download(e, history.id,history.filename,history.filesize)}
                                >
                                  {history.filename}
                                </a> : <a href={history.filename}>{history.filename}</a>} 
                                - {history.addedBy} - <Moment format="MM/DD/YYYY hh:mm a">{history.uploadDate}</Moment>
                              </div>
                            ))}
                          </Form.Field>
                        </React.Fragment>
                      )}
                       <InputField
                          label="Company Name"
                          // width="7"
                          errorMsg={validationMsg.company}
                          isError={validationMsg.company}
                          name="company"
                          onChange={this.handleChange}
                          value={data.company}
                          isRequired
                          className="m-0 p-0"
                        />
                    
                    </Grid.Column>
                
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={8}
                      computer={8}
                      largeScreen={8}
                    >
                      <TextareaField
                        label="Information Being Disclosed (Max 1000)"
                        // width="8"
                        errorMsg={validationMsg.infoBeingDisclosed}
                        isError={validationMsg.infoBeingDisclosed}
                        name="infoBeingDisclosed"
                        value={data.infoBeingDisclosed}
                        onChange={this.handleChange}
                        maxLength={1000}
                        className="textarea-height-fixed-t1"
                      />
                      <DatePickerWithTimezone
                        name="effectiveDate"
                        label="Effective Date"
                        errorMsg={validationMsg.effectiveDate}
                        isError={validationMsg.effectiveDate}
                        timezone="America/Los_Angeles"
                        format={DATE_FORMAT}
                        minDate={null}
                        // width="5"
                        onChange={(e) =>
                          this.handleChange(e, {
                            value: e,
                            name: 'effectiveDate',
                          })
                        }
                        selected={data.effectiveDate}
                        isRequired
                      />
                { 
                  window.location.href.includes("/nda/non-standard/update/")?
                    (data.expireType ||
                    data.expiryDate) &&
                  <ExpirationPeriod
                    isError={
                      validationMsg.expireType || validationMsg.expireValue
                    }
                    errorMsg={
                      validationMsg.expireType || validationMsg.expireValue
                    }
                    expireType={data.expireType}
                    expireDate={data.expiryDate}
                    minDate={data.effectiveDate}
                    onChange={this.onExpirationChange}
                    format={DATE_FORMAT}
                  />:                    
                  <ExpirationPeriod
                    isError={
                      validationMsg.expireType || validationMsg.expireValue
                    }
                    errorMsg={
                      validationMsg.expireType || validationMsg.expireValue
                    }
                    expireType={data.expireType}
                    expireDate={data.expiryDate}
                    minDate={data.effectiveDate}
                    onChange={this.onExpirationChange}
                    format={DATE_FORMAT}
                  />
                 }
                      {/* History Table...*/}
                    </Grid.Column>
                    </Grid.Row>

                  
                </Grid>
                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={16}
                      largeScreen={16}
                    >
                        <React.Fragment>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column
                                mobile={16}
                                tablet={8}
                                computer={8}
                                largeScreen={8}
                              >
                                <InputField
                                  label="Status Notification Email"
                                  errorMsg={
                                    validationMsg.statusNotificationEmail
                                  }
                                  isError={
                                    validationMsg.statusNotificationEmail
                                  }
                                  name="statusNotificationEmail"
                                  // width="5"
                                  onChange={this.handleChange}
                                  value={data.statusNotificationEmail}
                                />
                             
                                  <DropdownField
                                    errorMsg={validationMsg.status}
                                    isError={validationMsg.status}
                                    isRequired
                                    label="Status"
                                    name="status"
                                    // width="5"
                                    value={data.status}
                                    onChange={this.handleChange}
                                    options={statusOptions}
                                    placeholder="Select Status Type..."
                                  />
                                
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>   
                            <React.Fragment>
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column
                                    mobile={16}
                                    tablet={8}
                                    computer={8}
                                    largeScreen={8}
                                  >
                                    <label> Groups </label>
                                    <GroupSelection
                                      onChange={this.onGroupChange}
                                      isError={validationMsg.groups}
                                      errorMsg="You have not selected any group"
                                      recipients={
                                        data.groups ? data.groups : []
                                      }
                                      entity="NdaGroups"
                                      entityId={id}
                                      createGroup ={true}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </React.Fragment>
                          <Divider />
                        </React.Fragment>
                    </Grid.Column>
                  </Grid.Row>{' '}
                </Grid>

                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={10}
                      computer={10}
                      largeScreen={10}
                    >
                      <button
                        onClick={this.showpopupWarning}
                        disabled={loading}
                        className="button-primary"
                      >
                        {id ? 'Update' : 'Submit'}
                      </button>
                      {id && (
                        <Link to="/admin/nda/non-standard">
                          <button className="button-basic">Discard</button>
                        </Link>
                      )}
                      {msg && (
                        <span style={{ color: color, marginLeft: '5px' }}>
                          {msg}
                        </span>
                      )}{' '}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content={
            'Are you sure you want to ' +
            (id ? 'update' : 'create') +
            ' non-standard non-disclosure agreement??'
          }
        />
      </Segment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  config: configSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NDANonStandardForm);
