import React from 'react';
import PropTypes from 'prop-types';
import { Form, Label } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import ErrorMessage from "./error-message"

const notifyChange = (date, name, format, callback) => {
  if (callback instanceof Function) {
    if (format) {
      let text = moment
      .utc(date, "YYYY-MM-DD hh:mm a z");
      callback(null, { name: name, value: text });
    } else {
      callback(null, { name: name, value: date });
    }
  }
};

const DateField = ({
  errorMsg,
  format,
  isError,
  isRequired,
  label,
  name,
  onChange,
  value,
  width,
  readOnly,
  minDate
}) => {
  return (
    <Form.Field required={isRequired} width={width} error={isError}>
      <label>{label}</label>
      <DatePicker
        selected={new Date(value)}
        onChange={date => notifyChange(date, name, format, onChange)}
        readOnly = {readOnly}
        minDate={minDate}
      />
      <ErrorMessage isError={isError} errorMsg={errorMsg} />
    </Form.Field>
  );
}

DateField.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** Format of the data/time. */
  format: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string,
};

DateField.defaultProps = {
  width: "16",
};

export default DateField;
