import Api from "@/api";
import { CardviewMobile } from "@/common/cardview";
import NDAListing from "@/common/nda-selection";
import Tableview from "@/common/tableview";
import urls from "@/config/constants";
import { get, isEmpty } from "lodash";
import React from "react";
import Moment from "react-moment";
import {
  Button,
  Confirm,
  Icon,
  Modal,
  Responsive,
  Segment,
} from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";
class SignedNDA extends React.Component {
  Constants = {
    TABLE_COLUMNS: Object.freeze([
      { name: "Company Name", key: "companyName" },
      { name: "Effective Date", key: "effectiveDate" },
      { name: "Expire Date", key: "expiryDate" },
      { name: "Status", key: "ndaStatus" },
      { name: "Nda Type", key: "ndaType" },
      { name: "NDA File", key: "actions" },
    ]),

    RESPONSIVE_COLUMNS: Object.freeze([
      { name: "Company Name", key: "companyName" },
      { name: "Effective Date", key: "effectiveDate" },
      { name: "Expire Date", key: "expiryDate" },
      { name: "Status", key: "ndaStatus" },
      { name: "Nda Type", key: "ndaType" },
      { name: "NDA File", key: "actions" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedNDA: { content: [] },
      errorMsg: null,
      showError: false,
      selectedNdas: [],
      selectedStatedNdas: [],
      selectedNdasIndexes: [],
      checkedNdasIds: [],
      showNDAListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
    };
  }

  componentDidMount() {
    this.fetchAssignedNDA();
  }

  fetchAssignedNDA = () => {
    const { assignedNDA } = this.state;
    const { id } = this.props.match.params;
    const { URL_ADMIN_GROUP_SIGNED_NDA } = urls.App;
    let params = {
      params: {
        groupId: id,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(URL_ADMIN_GROUP_SIGNED_NDA, params)
      .then((resp) => {
        this.setState({
          assignedNDA: this.transformResponse(resp.data),
        });
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: "Something went wrong while loading the NDA..",
        });
      });
  };

  transformResponse = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el, idx) => {
        let row = Object.assign({}, el);
        console.log(row);
        row.id = idx;
        row.creationDate = (
          <>{dateToPacific(row.creationDate, "MM/DD/YYYY hh:mm a")}</>
        );
        row.expiryDate = (
          <>
            {" "}
            {(row.ndaType == "STANDARD" || !row.expiryDate)
              ? "--"
              : dateToPacific(row.expiryDate, "MM/DD/YYYY")}
          </>
        );
        row.effectiveDate = (
          <>{dateToPacific(row.effectiveDate, "MM/DD/YYYY")}</>
        );
        row.actions = (
          <a href={row.downloadPath} target="_blank">
            {
              <span
                className="icon-download"
                style={{ margin: "5px" }}
                title="Download"
                name="download"
              />
            }
          </a>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };
  onAssign = () => {
    this.setState({ showNDAListing: true });
  };

  doAssign = (data) => {
    this.setState({ showNDAListing: false, selectedNdas: data,selectedStatedNdas: [], selectedNdasIndexes: [], checkedNdasIds: [], }, () => {
      this.updateNDA("Something went wrong while assigning new NDA.");
    });
  };

  onUnassign = () => {
    const { selectedNdas } = this.state;
    if (isEmpty(selectedNdas)) {
      this.setState({
        showError: true,
        errorMsg: "No NDA selected for deletion. Please select  NDA.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "Something went wrong while unassigning the NDA"
      );
    }
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { msg } = this.state;
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb(msg);
      }
    );
  };

  updateNDA = (errorMsg) => {
    const { selectedNdas } = this.state;
    const { URL_ADMIN_GROUP_SIGNED_NDA } = urls.App;

    let url = `${URL_ADMIN_GROUP_SIGNED_NDA}?groupId=${get(this.props, [
      "match",
      "params",
      "id",
    ])}&ndaType=${selectedNdas[0].ndaType}`;

    let ndaIds = [];
    selectedNdas.map((pkg) => {
      ndaIds.push(pkg.id);
    });

    Api.AxiosInstance.getInstance()
      .post(url, ndaIds)
      .then((resp) => {
        this.fetchAssignedNDA();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };
  removeNDA = (errorMsg) => {
    const { selectedNdas } = this.state;
    const { URL_ADMIN_GROUP_SIGNED_NDA } = urls.App;
    let body = {
      data: [selectedNdas[0].ndaId],
    };
    let url = `${URL_ADMIN_GROUP_SIGNED_NDA}?groupId=${get(this.props, [
      "match",
      "params",
      "id",
    ])}&ndaType=${selectedNdas[0].ndaType}`;

    Api.AxiosInstance.getInstance()
      .delete(url, body)
      .then((resp) => {
        this.fetchAssignedNDA();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };

  onNdaSelection = (e) => {
    //  this.setState({ selectedNdas: e.data });
    let selectedStatedNdas = this.state.selectedStatedNdas;
    let ids = this.state.checkedNdasIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedNdas.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedNdas.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedNdas = selectedStatedNdas.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedNdas.map((s) => s.id);
    }
    this.setState({ checkedNdasIds: ids });

    this.setState({
      selectedStatedNdas: selectedStatedNdas,
      selectedNdas: selectedStatedNdas,
    });
  };

  onAllItemSelection = (selectedNdas, flag) => {
    let {selectedStatedNdas} = this.state;
    let ids=[];
    if(flag){
      selectedNdas.map((nda)=>{
        selectedStatedNdas = selectedStatedNdas.filter((entity) => {
          return entity.id !== nda.id;
        })
      });
    }else{
      selectedNdas.map((nda)=>{
          if (nda) {
            const alreadyPushed = selectedStatedNdas.find(
              (element) => element.id == nda.id
            );
            if (!alreadyPushed) {
              selectedStatedNdas.push(nda);
            } }
          } );
    }
    ids = selectedStatedNdas.map((s) => s.id);
    this.setState({
      selectedStatedNdas: selectedStatedNdas,
      selectedNdas: selectedStatedNdas,
      checkedNdasIds: ids,
       });
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closeNdaListing = () => {
    this.setState({ showNDAListing: false });
  };

  render() {
    const { TABLE_COLUMNS, RESPONSIVE_COLUMNS } = this.Constants;
    const { assignedNDA, errorMsg, showError, showNDAListing,  selectedStatedNdas, selectedNdasIndexes, checkedNdasIds, } = this.state;
    const isData =
      assignedNDA && assignedNDA.content && assignedNDA.content.length > 0
        ? true
        : false;

    return (
      <React.Fragment>
        <Segment basic>
          <div className="control-action-wrapper mb-1">
            <Button
              disabled={false}
              className="button-basic mini"
              onClick={this.onAssign}
            >
              <Icon name="plus" />
              Assign NDA
            </Button>
            <Button
              disabled={false}
              color="red"
              className="button-basic mini mr-0-im"
              onClick={this.onUnassign}
            >
              <Icon name="remove circle" />
              Delete Associated NDA
            </Button>
          </div>
        </Segment>

        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination mt-2">
            <Tableview
              columns={TABLE_COLUMNS}
              data={assignedNDA}
              onSelectionChange={this.onNdaSelection}
              noDataMessage="No NDA Associated."
              selectionType="multi-select"
              onAllSelectionChange={this.onAllItemSelection}             
              checkedIndexes={selectedNdasIndexes}
              checkedIds={checkedNdasIds}
            />
          </div>
        </Responsive>

        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={assignedNDA}
            columns={RESPONSIVE_COLUMNS}
            onSelectionChange={this.onNdaSelection}
            noDataMessage="No NDA Associated."
            selectionType="multi-select"
            paginate
            onAllSelectionChange={this.onAllItemSelection}             
            checkedIndexes={selectedNdasIndexes}
            checkedIds={checkedNdasIds}
          />
        </Responsive>

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <NDAListing
          show={showNDAListing}
          onDismiss={this.closeNdaListing}
          onSave={this.doAssign}
          entity="NdaGroups"
          entityId={get(this.props, ["match", "params", "id"])}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.removeNDA)}
          content={`Are you sure you want to un-associate non-disclosure agreement from group?`}
        />
      </React.Fragment>
    );
  }
}

export default SignedNDA;
