import React, { useEffect, useState } from "react";
import {
  Divider,
  Segment,
  Responsive,
  Accordion,
  Icon,
  Grid,
} from "semantic-ui-react";
import Tableview from "@/common/tableview";
import { Cardview } from "@/common/cardview";
import { getTickets } from "../../../api/zendesk";
import { useDispatch, useSelector } from "react-redux";
import { dateToPacific } from "@/utils/dateTime.js";
import Config from "@/config/constants";
import { redirectToSubmitATicketURL } from "../../../api/newticket";
import {redirectToHelpdeskURL} from "../../../common/utils/utils";
import Actions from "../../../store/actions";

const COLUMNS = [
  { name: "Subject", key: "subject", showTooltip: true },
  { name: "Status", key: "status", showTooltip: true },
  { name: "Created on", key: "created_at", width: 6, showTooltip: true },
  { name: "Actions", key: "action" },
];

export default () => {
  const dispatch = useDispatch();
  const tickets = useSelector((state) => state.zendeskReducer.tickets);
  const loading = useSelector((state) => state.zendeskReducer.loading);
  const isCustomer = useSelector(
    (state) =>
      state.user.loggedin.user.authorities[0].authority ===
      Config.AccessRole.CUSTOMER
  );
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    dispatch(getTickets());
  }, [dispatch]);
  const redirectToTicket = (url) => {
    return () => {
      dispatch({
        type: Actions.Config.SHOW_HIDE_LOADER,
        payload: true
      });
      redirectToHelpdeskURL(url).catch((error) => {
        dispatch({
          type: Actions.Config.SHOW_HIDE_ERROR_DIALOG,
          payload: { state: true, errMsg: error.response.data.error }
        });
        dispatch({
          type: Actions.Config.SHOW_HIDE_LOADER,
          payload: false
        });
      })
    };
  };
  const submitATicket = () => {
    dispatch({
      type: Actions.Config.SHOW_HIDE_LOADER,
      payload: true
    });
    return redirectToSubmitATicketURL().catch((error) => {
      dispatch({
        type: Actions.Config.SHOW_HIDE_ERROR_DIALOG,
        payload: { state: true, errMsg: error.response.data.error }
      });
      dispatch({
        type: Actions.Config.SHOW_HIDE_LOADER,
        payload: false
      });
    })
  };
  const transformData = (data) => {
    let transformedData = { ...data };
    let content = [];
    data.tickets.map((el) => {
      content.push({
        subject: el.subject,
        status: el.status,
        created_at: el.created_at ? (
          <>{dateToPacific(el.created_at, "MM/DD/YYYY hh:mm a")}</>
        ) : (
          "-"
        ),
        action: (
            <a href='javascript:void(0)' onClick={redirectToTicket(el.url)}>
            <span
              className="icon-external-link2"
              style={{
                margin: "3px",
              }}
              title="Open link"
              name="open"
            />
          </a>
        ),
      });
    });
    transformedData.content = content;
    return transformedData;
  };
  const transformedData = tickets && tickets.tickets && transformData(tickets);
  return (
    <Segment className="dashboard-card">
      <Responsive minWidth={1024}>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
              <div className="header">
                <div className="side-marker" />
                Recent Requests
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
              <div className="control-action-wrapper">
                <>
                  {isCustomer && (
                    <button
                      className="button-primary mb-1"
                      onClick={submitATicket}
                    >
                      Submit a Request
                    </button>
                  )}
                </>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="sp-case-row">
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
              <div className="table-wrapper">
                <Tableview
                  data={transformedData ? transformedData : []}
                  columns={COLUMNS}
                  loading={loading}
                  noDataMessage={"No Requests"}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {transformedData && transformedData.content && (
          <React.Fragment>
            <Divider />
            <em>Showing top {transformedData.content.length} records</em>
          </React.Fragment>
        )}
      </Responsive>
      <Responsive maxWidth={1023}>
        <Accordion>
          <Accordion.Title onClick={(e) => setIsCollapsed(!isCollapsed)}>
            <Icon
              name={!isCollapsed ? "caret right" : "caret down"}
              color="blue"
            />
            <div className="side-marker" />
            <div className="header">Recent Requests</div>
          </Accordion.Title>
          <Accordion.Content active={isCollapsed}>
            <Cardview
              data={transformedData}
              columns={COLUMNS}
              loading={loading}
              noDataMessage={"No Requests"}
            />

            {transformedData && transformedData.content && (
              <React.Fragment>
                <Divider />
                <em>Showing top {transformedData.content.length} records</em>
              </React.Fragment>
            )}
          </Accordion.Content>
        </Accordion>
      </Responsive>
    </Segment>
  );
};
