const text = `
<p class="label-top">
To get started with Groq, you can request access to our public materials using the <span> Request Public Materials Access </span> button. This approval is typically completed within one business day.
To securely access proprietary and confidential materials, please submit a new NDA request via the link provided.  If your company already has an NDA in place with Groq, please notify Groq's portal administrator at <a>portal_admin@groq.com.</a>
If you run into any issues, contact us as <a>support@groq.com</a>.
<p/>
<h5>
  Below is a list of the functionality and resources accessible to you on this website. 
</h5>
<ul class="welcome-info">
<li>
  <b>HOME</b> - <p>View top articles, recent downloads, received files and your technical requests.</p>
</li>
<li>
  <b>DOWNLOADS</b> - <p>Browse, search, and download confidential documentation and software for Groq products.</p>
</li>
<li>
  <b>SUPPORT</b> - <p>Browse available knowledge base articles for getting started with Groq products and software.</p>
</li>
<li>
  <b>PROJECTS</b> - <p>Create your own project page to collaborate and share information with your colleagues and Groq personnel.</p>
</li>
<li>
  <b>VIDEOS</b> - <p>Watch on demand video trainings to jumpstart your project with Groq products.</p>
</li>
<li>
  <b>BRIEFCASE</b> - <p>Securely exchange files with Groq personnel.</p>
</li>
<li>
  <b>MY ACTIVITIES</b> - <p>Submit or update your technical requests. View the site help page. Access your profile where you can update your information, delete your user account, view your documents and passwords. You can also sign out here when you are done. </p>.
</li>
<li style="display: none;">
  <b>MY PROFILE</b> - <p>Update your information.  Enable Two Factor Authentication. Deactivate your account.  View your documents.  Change your login password.  Access or customize your document password for protected files shared with you via the briefcase.</p>.
</li>
</ul>
<p class="label-bottom">We are excited to have you become a part of the Groq community and looking forward to working with you. </p>
`;

export default text;
