import React from "react";
import PropTypes from "prop-types";
import Constants from "@constants";
import Uploader from "@/common/uploader";
import {
  CountryField,
  InputField,
  DropdownField,
  TextareaField,
  PlainField,
  FileField,
  StateField,
} from "@/common/Form";
import {
  Button,
  Divider,
  Image,
  Modal,
  Segment,
  Header,
  List,
  GridColumn,
  Form,
  Grid,
} from "semantic-ui-react";
import Api from "@api";
import update from "immutability-helper";
import PlaceholderList from "./../components/placeholder-list";
import { configSelector } from "@/common/selectors";
import { connect } from "react-redux";

class NDATemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      loading: false,
      data: {},
    };
  }

  componentDidMount() {
    this.loadData();
    this.loadTemplateDate();
  }

  loadData = () => {
    const { URL_NDA_SET_TEMPLATE } = Constants.App;
    console.log("template", URL_NDA_SET_TEMPLATE, Constants);
    Api.AxiosInstance.getInstance()
      .get(URL_NDA_SET_TEMPLATE)
      .then((resp) => {
        this.setState({ file: resp.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadTemplateDate = () => {
    const { URL_TEMPLATE_PLACEHOLDERS } = Constants.App;
    //  console.log("template", URL_NDA_SET_TEMPLATE, Constants);
    Api.AxiosInstance.getInstance()
      .get(URL_TEMPLATE_PLACEHOLDERS)
      .then((resp) => {
        this.setState({ data: resp.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  saveTemplate = () => {
    const data = {
      id: this.state.fileuuid,
    };
    const { URL_NDA_SET_TEMPLATE } = Constants.App;
    console.log("post template", URL_NDA_SET_TEMPLATE, Constants);
    Api.AxiosInstance.getInstance()
      .post(URL_NDA_SET_TEMPLATE, data)
      .then((resp) => {
        this.setState({ file: resp.data }, () => this.loadData());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onFileChange = (eventData) => {
    if (eventData.isUploading) {
      const { file, isUploading } = eventData;
      let { data } = this.state;

      if (file.name && file.type && file.size) {
        //data = update(data, { file: { $set: file } });
        this.setState({ isUploading, file });
      }
    } else if (typeof eventData.uploadProgress !== "undefined") {
      const { uploadProgress } = eventData;
      const isUploading = uploadProgress !== 100;
      this.setState({ isUploading, uploadProgress });
    } else if (typeof eventData.fileuuid !== "undefined") {
      const { fileuuid } = eventData;
      this.setState({ fileuuid });
    } else if (typeof eventData.errorMsg !== "undefined") {
      const { errorMsg } = eventData;
      this.setState({ errorMsg, showError: true, isUploading: false });
    }
  };

  render() {
    const {
      loading,
      file,
      validationMsg,
      showError,
      fileuuid,
      uploadProgress,
      data,
    } = this.state;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">NDA Template</div>
          <Form loading={loading} className="width-100">
            {file && file.filename && (
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                ></Grid.Column>
                <h3>Existing Template Link: </h3>
                <a href={file.downloadLink} target="_blank">
                  {file.filename}
                </a>
              </Grid.Row>
            )}
            <hr />
            <FileField
              errorMsg="You have not selected any files."
              isError={validationMsg && validationMsg.files}
              label="Upload NDA Template "
              name="files"
              onChange={Uploader(this.onFileChange)}
              isRequired
              multipleSelection={false}
            />
            <PlaceholderList data={data} config={this.props.config} />
            <br />
            <Button
              type="submit"
              disabled={!fileuuid && !showError}
              onClick={this.saveTemplate}
              className="button-primary m--no-margin-left-im mt-1"
            >
              Save
            </Button>
          </Form>
        </Segment>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  config: configSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NDATemplate);
