import React from "react";
import DownloadLogListing from "../components/download-log-listing";
import urls from "@/config/constants";

const DownloadReport = () => {
  const { URL_DOWNLOAD_LOG_LISTING } = urls.App;
  return (
    <DownloadLogListing
      title="Download Log"
      fetchUrl={URL_DOWNLOAD_LOG_LISTING}
    />
  );
};

export default DownloadReport;
