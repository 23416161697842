import React from 'react';
import { Icon, Button, Modal } from 'semantic-ui-react';
import FilesListing from "./subcomponents/files-listing";
import urls from '../../../../config/constants';
import Api from "../../../../api";


class ViewDocuments extends React.Component {

  Constants = {
    ITEMS_COLUMNS: [
      { name:'Product Name', key:'parent_product_name', width: "3", showTooltip: true, },
      { name:'Name', key:'name', width: "3", showTooltip: true, },
      { name:'Description', key:'description', width: "3", showTooltip: true, },
      { name:'Updated on', key:'modification_date', showTooltip: true, },
      { name: 'Updated by', key: 'modified_by', showTooltip: true, },
      {name:'Type', key:'type', class:"center", },
      { name:'Actions', key: 'actions', width: "2"},

    ],
    DOCS_COLUMNS: [
      { name:'Project Name', key:'projectName', width: "3", showTooltip: true, },
      { name:'Name', key:'name', width: "3", showTooltip: true, },
      { name:'Description', key:'description', width: "3", showTooltip: true, },
      { name:'Updated on', key:'modificationDate', showTooltip: true, },
      { name: 'Updated by', key: 'modifiedBy', showTooltip: true, },
      {name:'Type', key:'type', class:"center",},
      { name:'Actions', key: 'actions', width: "2"},
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMsg: null,
      showError: false,
    };
  }

  downloadDocument = (doc) => {
    Api.AxiosInstance.getInstance()
    .get( `/projects/document/download?documentId=${doc.docId}` )
    .then(resp => {
      // debugger;
      let win = window.open(resp.data, "_blank");
      win.focus();
    })
    .catch(err => this.setState({ showError: true, errorMsg: "An error occured while downloading the document." }) );
  };

  downloaditem = (item) => {
    Api.AxiosInstance.getInstance()
    .get( `/items/download?itemId=${item.id}` )
    .then(resp => {
      // debugger;
      let win = window.open(resp.data, "_blank");
      win.focus();
    })
    .catch(err => this.setState({ showError: true, errorMsg: "An error occured while downloading the item." }) );
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  render() {
    const { ITEMS_COLUMNS, DOCS_COLUMNS } = this.Constants;
    const { URL_PROFILE_ITEMS, URL_PROFILE_DOCUMENTS } = urls.App;
    const { errorMsg, showError } = this.state;

    return(
      <React.Fragment>
        <FilesListing
          columns={ ITEMS_COLUMNS }
          fetchUrl={ URL_PROFILE_ITEMS }
          title="My Items"
          downloadFunction={ this.downloaditem }
          editUrl={ null }
        />
        <FilesListing
          columns={ DOCS_COLUMNS }
          fetchUrl={ URL_PROFILE_DOCUMENTS }
          title="My Documents"
          downloadFunction={ this.downloadDocument }
          editUrl={ null }
        />
        <Modal open={ showError }
          onClose={ () => { this.closeErrorPopup(); } }
          centered={ true }
          size="tiny"
          content={ errorMsg }
          actions={[{ key: 'warningDismiss', content: 'OK', primary: true }]}
        />
      </React.Fragment>
    );
  }
};

export default ViewDocuments;
