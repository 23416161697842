let _apiUrl = '';
if (process.env.NODE_ENV === 'production') {
  // _apiUrl = "https://websilo.folio3.com:9080/serviceqa/";
  //_apiUrl = "https://groq.folio3.com:8011/serviceqa/";
  // _apiUrl = "https://groq.folio3.com.8018/serviceqa/";
  // _apiUrl = "https://groq.folio3.com:8000/serviceqa/";
  //_apiUrl = "http://groq.folio3.com:8018/serviceqa/";
  // _apiUrl = "https://staging-groq.folio3.com/support/";

  //_apiUrl = 'https://staging-groq.folio3.com/support/';
  // @todo: add apiUrl and other variables in env files.
  // _apiUrl = 'https://staging-ph2-groq.folio3.com/support/';
  // _apiUrl = 'https://' + window.location.hostname + '/support/';

  _apiUrl = "https://support.groq.com/";

  //_apiUrl = "http://staging-groq.folio3.com/sendgrid/";
} else if (process.env.NODE_ENV === 'development') {
  // _apiUrl = "http://websilo.folio3.com:9080/service/";
  // _apiUrl = "http://websilo.folio3.com:9080/serviceqa/";
  // _apiUrl = "https://groq.folio3.com:8011/serviceqa/";
  // _apiUrl = "http://ajalil-lt:8089/";
  // _apiUrl = "https://staging-groq.folio3.com/support/";
  //_apiUrl = "https://groq.folio3.com:8000/serviceqa/";
  // _apiUrl = 'http://' + window.location.hostname + ':8089/';
  // _apiUrl = 'https://qa-groq.folio3.com/support/';
  // _apiUrl = "https://groq.folio3.com:8011/serviceqa/";
  //_apiUrl = "https://staging-ph2-groq.folio3.com/support/";
  //_apiUrl = "https://qa-groq.folio3.com/support/";
} else {
  _apiUrl = process.env.PUBLIC_URL;
}
// _apiUrl = 'https://' + window.location.hostname + '/support/';
const API_URL = _apiUrl;
const BASE_URL = process.env.BASE_URL || _apiUrl;
// const API_URL = "http://fzia-lt:8089/";
// const API_URL = "http://10.164.1.45:8089/";
// const API_URL = "http://websilo.folio3.com:9080/service/";
// console.log('env var', process.env.PUBLIC_URL);
const LINK_LOGIN_PAGE = _apiUrl + '#/login/';

const URL_FILE_UPLOAD = API_URL + 'upload';
const URL_RECENT_DOWNLOADS = API_URL + 'dashboard/downloads';
const URL_TOP_DOWNLOADS = API_URL + 'dashboard/downloads/top';
const URL_RECENT_UPLOADS = API_URL + 'dashboard/uploads';
const URL_USER_APPROVAL_LIST = API_URL + 'approval/user';
const URL_SEARCH_LOG = API_URL + 'dashboard/search';
const URL_IS_INITIATED_NDA = API_URL + 'nda/is-initiated';
const URL_INITIATE_NDA = API_URL + 'nda/initiate';
const URL_REMOVE_PENDING_NDA = API_URL + 'nda';
const URL_DOWNLOAD_PENDING_NDA_DOC = API_URL + 'nda/download';
const URL_NDA_REMINDER = API_URL + 'nda/reminder';
const URL_SEND_MULTIPLE_MESSAGE =
  API_URL + 'twoFactorAuthKey/send-authentication-code';

const URL_LOGIN_EMAIL = API_URL + 'auth/signin';
const URL_LOGGEDIN_USER = API_URL + 'auth/user';
const URL_SSO_VERIFICATION = API_URL + 'auth/sso/verification';

const URL_GOOGLE_AUTH_LOGIN = API_URL + 'auth/google/login-url';
const URL_GOOGLE_AUTH_CODE = API_URL + 'auth/google/code';

const URL_RESEND_VERIFICATION_EMAIL = API_URL + 'auth/resend-validation-email';
const URL_REGISTER = API_URL + 'auth/registration';
const URL_CHECK_EMAIL = API_URL + 'auth/registration/check-email';
const URL_CHECK_USERNAME = API_URL + 'auth/registration/check-username';
const URL_VERIFY_ACCOUNT = API_URL + 'auth/verify';
const URL_ADMIN_USER_RESEND_INVITE = API_URL + 'auth/registration/resend-invite';
const URL_REGISTRATION_TOKEN = API_URL + 'auth/sso/register-token';
const URL_SSO_GOOGLE = API_URL + 'auth/sso/google';
const URL_SSO_MICROSOFT = API_URL + 'auth/ms';

const URL_FORGET_PASSWORD = API_URL + 'auth/forgot-password';
const URL_FORGET_PASSWORD_TOKEN = API_URL + 'auth/forgot-password-token';
const URL_RESET_PASSWORD = API_URL + 'auth/forgot-password-change';

const URL_PRODUCTS_LIST = API_URL + 'products';
const URL_PRODUCTS_DETAILS = API_URL + 'products/details';
const URL_PRODUCTS_SEARCH = API_URL + 'products/search';
const URL_PRODUCTS_ITEMS_DETAILS = API_URL + 'items/details';
const URL_PRODUCTS_NON_PRODUCT_GROUPS = API_URL + 'products/list/groups';
const URL_PRODUCTS_NON_PRODUCT_USERS = API_URL + 'products/list/users';
const URL_PRODUCTS_FRIENDLY_NAME_AVAILABLE =
  API_URL + 'items/check-friendly-name';
const URL_FRIENDLY_NAME_DOWNLOAD_ITEM = API_URL + 'items/downloads/';
const URL_FRIENDLY_NAME_ITEM_DETAILS = API_URL + 'items/details/name/';
const URL_NDA_SET_TEMPLATE = API_URL + 'nda/template';

const URL_PACKAGE_LIST = API_URL + 'listings/packages';
const URL_PRODUCT_PAGED_LIST = API_URL + 'listings/products';
const URL_PACKAGE_DETAILS = API_URL + 'packages/details';
const URL_PACKAGE_NEW = API_URL + 'packages/new';
const URL_PACKAGE_DELETE = API_URL + 'packages/delete';

const URL_SEARCH_ITEMS = API_URL + 'item_search/search_items';
const URL_PRODUCTS_ITEMS_LIST = API_URL + 'items';
const URL_ITEMS_DETAILS = API_URL + 'items/details';
const URL_ITEM_DELETE = API_URL + 'items/delete';
const URL_ITEM_DOWNLOAD = API_URL + 'items/download';

const URL_PROJECTS_LIST = API_URL + 'projects';
const URL_PROJECT_DETAIL = API_URL + 'projects/details';
const URL_PROJECT_DELETE = API_URL + 'projects/delete';
const URL_PROJECT_CREATE = API_URL + 'projects/create';

const URL_PROJECT_POST_CREATE = API_URL + 'projects/post';
const URL_PROJECT_POSTS = API_URL + 'projects/posts';
const URL_PROJECT_ADD_MEMBERS = API_URL + 'projects/members/add';
const URL_PROJECT_MEMBERS = API_URL + 'projects/members';
const URL_PROJECT_REMOVE_MEMBER = API_URL + 'projects/members/remove';
const URL_PROJECT_LEAVE = API_URL + 'projects/members/leave';
const URL_PROJECT_RESEND_INVITE = API_URL + 'projects/members/resend-invite';
const URL_PROJECT_ACCEPT_INVITATION = API_URL + 'projects/members/accept';
const URL_PROJECT_LIST_INVITATIONS = API_URL + 'projects/members/list/invites';

const URL_PROJECT_DOCUMENTS = API_URL + 'projects/documents';
const URL_PROJECT_DOCUMENT = API_URL + 'projects/document';
const URL_PROJECT_DOCUMENT_DOWNLOAD = API_URL + 'projects/document/download';

const URL_PROFILE_USERDATA = API_URL + 'profile';
const URL_PROFILE_EDITDATA = API_URL + 'profile/editprofile';
const URL_PROFILE_CHANGE_PWD = API_URL + 'profile/changepwd';
const URL_PROFILE_CHANGE_PWD_PDF = API_URL + 'profile/password/pdf';
const URL_PROFILE_WALL_POST = API_URL + 'profile/posts';
const URL_PROFILE_POST_ACTION = API_URL + 'profile/post';
const URL_PROFILE_POST_APPROVE = API_URL + 'profile/post/approve';

const URL_PROFILE_FRIENDS = API_URL + 'profile/friends';
const URL_PROFILE_FRIENDS_PENDING = API_URL + 'profile/requests';
const URL_PROFILE_FRIEND_ADD = API_URL + 'profile/friend/add';
const URL_PROFILE_FRIEND_ACCEPT = API_URL + 'profile/friend/accept';
const URL_PROFILE_FRIEND_REMOVE = API_URL + 'profile/friend/remove';

const URL_PROFILE_ITEMS = API_URL + 'profile/items';
const URL_PROFILE_DOCUMENTS = API_URL + 'profile/documents';

const URL_ADMIN_GROUP_LIST = API_URL + 'admin/group/list';
const URL_ADMIN_ADD_GROUP = API_URL + 'admin/group/add';
const URL_ADMIN_REMOVE_GROUP = API_URL + 'admin/group/remove';
const URL_ADMIN_SINGLE_GROUP = API_URL + 'admin/group/single';
const URL_ADMIN_GROUP_FORCE_RESET = API_URL + 'admin/group/forcereset';
const URL_ADMIN_GROUP_USERS = API_URL + 'admin/group/users';
const URL_ADMIN_GROUP_REMOVE_USER = API_URL + 'admin/group/user/remove';
const URL_ADMIN_GROUP_ASSIGN_USER = API_URL + 'admin/group/user/assign';
const URL_ADMIN_GROUP_PACKAGES = API_URL + 'admin/group/packages';
const URL_ADMIN_GROUP_ASSIGN_PACKAGE = API_URL + 'admin/group/package/assign';
const URL_ADMIN_GROUP_ASSIGNED_PACKAGES =
  API_URL + 'admin/group/packages/assigned';

const URL_ADMIN_GROUP_REMOVE_PACKAGE = API_URL + 'admin/group/package/remove';

const URL_ADMIN_GROUP_SIGNED_NDA = API_URL + 'admin/group/nda-assigned';

const URL_ADMIN_USER_LIST = API_URL + 'admin/user/list';
const URL_ADMIN_USER_EDIT = API_URL + 'admin/editlist';
const URL_ADMIN_USER_SINGLE = API_URL + 'admin/user/single';
const URL_ADMIN_USER_SAVE = API_URL + 'admin/user/save';
const URL_ADMIN_USER_REMOVE = API_URL + 'admin/user/remove';
const URL_ADMIN_USER_SEARCH = API_URL + 'admin/user/search';
const URL_ADMIN_USER_FORCE_RESET = API_URL + 'admin/user/forcereset';
const URL_STATES_FULL_LIST = API_URL + 'admin/user/dropdownsdata';
const URL_ADMIN_USER_PACKAGES = API_URL + 'admin/user/packages';
const URL_ADMIN_USER_ASSIGN_PACKAGE = API_URL + 'admin/user/package/assign';
const URL_ADMIN_USER_ASSIGNED_PACKAGES =
  API_URL + 'admin/user/packages/assigned';
const URL_ADMIN_USER_REMOVE_PACKAGE = API_URL + 'admin/user/package/remove';
const URL_ADMIN_USER_CONTACTS = API_URL + 'admin/user/contacts';
const URL_ADMIN_USER_ASSIGN_CONTACT = API_URL + 'admin/user/contact/assign';
const URL_ADMIN_USER_REMOVE_CONTACT = API_URL + 'admin/user/contact/remove';

const URL_ADMIN_USER_TYPES_LISTING = API_URL + 'admin/user-type/list';
const URL_ADMIN_USER_TYPE_SINGLE = API_URL + 'admin/user-type/single';
const URL_ADMIN_USER_TYPE_SAVE = API_URL + 'admin/user-type/save';
const URL_ADMIN_USER_TYPE_REMOVE = API_URL + 'admin/user-type/remove';

const URL_ADMIN_CONTACT_TYPES_LISTING = API_URL + 'admin/contact-type/list';
const URL_ADMIN_CONTACT_TYPE_SINGLE = API_URL + 'admin/contact-type/single';
const URL_ADMIN_CONTACT_TYPE_SAVE = API_URL + 'admin/contact-type/save';
const URL_ADMIN_CONTACT_TYPE_REMOVE = API_URL + 'admin/contact-type/remove';

const URL_ADMIN_PLATFORM_LISTING = API_URL + 'admin/platform/list';
const URL_ADMIN_PLATFORM_SINGLE = API_URL + 'admin/platform/single';
const URL_ADMIN_PLATFORM_SAVE = API_URL + 'admin/platform/save';
const URL_ADMIN_PLATFORM_REMOVE = API_URL + 'admin/platform/remove';

const URL_ADMIN_ITEM_TYPES_LISTING = API_URL + 'admin/item-type/list';
const URL_ADMIN_ITEM_TYPE_SINGLE = API_URL + 'admin/item-type/single';
const URL_ADMIN_ITEM_TYPE_SAVE = API_URL + 'admin/item-type/save';
const URL_ADMIN_ITEM_TYPE_REMOVE = API_URL + 'admin/item-type/remove';

const URL_ADMIN_ITEM_CATEGORIES_LISTING =
  API_URL + 'admin/itemcategory/listing';
const URL_ADMIN_ITEM_CATEGORY_SINGLE = API_URL + 'admin/itemcategory/single';
const URL_ADMIN_ITEM_CATEGORY_SAVE = API_URL + 'admin/itemcategory/save';
const URL_ADMIN_ITEM_CATEGORY_REMOVE = API_URL + 'admin/itemcategory/remove';

const URL_ADMIN_SETTINGS = API_URL + 'admin/settings';
const URL_ADMIN_SETTINGS_SAVE = API_URL + 'admin/settings/save';

const URL_ADMIN_REINDEX = URL_ADMIN_SETTINGS + '/reindex';
const URL_ADMIN_REINDEX_STATUS = URL_ADMIN_REINDEX + '/status';

const URL_ADMIN_TAGS_LISTING = API_URL + 'tags/all';
const URL_ADMIN_TAGS_REMOVE = API_URL + 'tags';
const URL_ADMIN_TAGS_ADD = API_URL + 'tags';
const URL_ADMIN_TAGS_EDIT = API_URL + 'tags';
const URL_ADMIN_TAGS_INFO = API_URL + 'tags/info';

const URL_LICENSE_LIST = API_URL + 'licenses/list';
const URL_LICENSE_SEARCH = API_URL + 'licenses/search';
const URL_LICENSE_SINGLE = API_URL + 'licenses/single';
const URL_LICENSE_REMOVE = API_URL + 'licenses/remove';
const URL_LICENSE_SAVE = API_URL + 'licenses/save';
const URL_LICENSE_CREATE = API_URL + 'licenses/create';
const URL_LICENSE_DOWNLOAD = API_URL + 'licenses/download';

const URL_LICENSE_ASSIGNED_ITEMS = '/licenses/assigned-items';
const URL_LICENSE_ASSIGN_ITEMS = '/licenses/asssign-items';
const URL_LICENSE_DELETE_ITEMS = '/licenses/delete-items';

const URL_ITEMS_ASSOCIATED_LICENSES = '/items/associated-licenses';
const URL_ITEMS_ASSOCIATED_LICENSE_LINK = '/items/associated-license-link';
const URL_ITEMS_LICENSE_AGREED = '/items/license-agreed';
const URL_BRIEFCASE_RECEIVED_FILES = API_URL + 'briefcase';
const URL_BRIEFCASE_SENT_FILES = API_URL + 'briefcase/sent';
const URL_BRIEFCASE_SEND = API_URL + 'briefcase/new';
const URL_BRIEFCASE_DOWNLOAD = API_URL + 'briefcase/download';
const URL_BRIEFCASE_RESEND = API_URL + 'briefcase/resend';
const URL_BRIEFCASE_DELETE = API_URL + 'briefcase/delete';
const URL_BRIEFCASE_VIEWED = API_URL + 'briefcase/viewed';
const URL_BRIEFCASE_FILE_RECEIVED_COUNT = API_URL + 'briefcase/not-viewed-count';

const URL_NDA_LISTING = API_URL + 'nda/list';
const URL_NON_STANDARD_NDA_LISTING = API_URL + 'nda/list-non-standard';

const URL_SLA_LISTING = API_URL + 'sla/list';
const URL_SWEL_LISTING = API_URL + 'swel/list';

const URL_LISTINGS_USERS = API_URL + 'listings/users';
const URL_LISTINGS_GROUPS = API_URL + 'listings/groups';
const URL_LISTINGS_USERS_VERIFICATION_PENDING = API_URL + 'listings/verification-pending';


const URL_NDA_DOCUMENT_DOWNLOAD = API_URL + 'nda/download';
const URL_NDA_EDIT = API_URL + 'nda/';
const URL_NDA_CREATE = API_URL + 'nda/create';
const URL_NDA_NON_STANDARD_SAVE = API_URL + 'nda/save-non-standard-nda';
const URL_NDA_NON_STANDARD_EDIT = API_URL + 'nda/non-standard';
const URL_NDA_NON_STANDARD_DELETE = API_URL + 'nda/delete-non-standard-nda';
const URL_NDA_NON_STANDARD_DOWNLOAD = API_URL + 'nda/download-non-standard';

const URL_NDA_SIGN_IN = API_URL + 'nda/nda';
const URL_ACCESS_REPORT_LISTING = API_URL + 'reports/access';
const URL_BRIEFCASE_LOG_LISTING = API_URL + 'reports/briefcase';
const URL_DOWNLOAD_LOG_LISTING = API_URL + 'reports/downloads';
const URL_PACKAGE_REPORT_LIST = API_URL + 'reports/package';
const URL_TEMPLATE_PLACEHOLDERS = API_URL + 'nda/template-placeholders';

const URL_GLOBAL_SEARCH = API_URL + 'global-search/';

const URL_VIDEOS = API_URL + 'video';
const URL_ADD_VIDEOS_DIR = API_URL + 'video/dir';
const URL_GET_VIDEOS = API_URL + 'video/parent';
const URL_VIDEO_PACKAGE = API_URL + 'video/packages';
const URL_PACKAGE_VIDEOS = API_URL + 'packages/videos';

const URL_GET_ALL_VIDEOS = API_URL + 'video/all';
const URL_STREAM_VIDEO = API_URL + 'video/download';

const URL_HELPCENTER = 'https://helpdesk.groq.com/hc/en-us/';
const URL_SUPPORT_ENABLED = API_URL + 'support/enabled';
const ZENDESK_REDIRECT_URL_SYNCUSER =
  API_URL + 'zendesk/redirect-syncuser?returnTo=';
const ZENDESK_REDIRECT_URL = API_URL + 'zendesk/redirect?returnTo=';
const SUBMIT_A_TICKET = 'https://helpdesk.groq.com/hc/en-us/requests/new';
const MY_REQUESTS = 'https://helpdesk.groq.com/hc/en-us/requests';
const UNSUBSCRIBE_URL = API_URL + 'unsubscribe';

const URL_NOTIFICATIONS_LIST = API_URL + 'notifications/list';
const URL_NOTIFICATIONS_RECENT = API_URL + 'notifications/recent';
const URL_NOTIFICATIONS_MARK_AS_READ = API_URL + 'notifications/markAsRead';
const URL_NOTIFICATIONS_MARK_AS_UNREAD = API_URL + 'notifications/markAsUnread';
const URL_NOTIFICATIONS_DELETE = API_URL + 'notifications/softDelete/';
const URL_NOTIFICATIONS_COUNT = API_URL + 'notifications/count';

const URL_EMAIL_TEMPLATE_IFRAME = API_URL + "mailer/iframe";
const URL_EMAIL_TEMPLATE_PREVIEW = API_URL + "mailer/preview";


export default {
  API_URL,
  BASE_URL,

  URL_TEMPLATE_PLACEHOLDERS,
  URL_NDA_SIGN_IN,
  URL_NDA_DOCUMENT_DOWNLOAD,
  URL_NDA_EDIT,
  URL_NDA_CREATE,
  URL_NDA_NON_STANDARD_EDIT,
  URL_NDA_NON_STANDARD_SAVE,
  URL_NON_STANDARD_NDA_LISTING,
  URL_NDA_NON_STANDARD_DELETE,
  URL_NDA_NON_STANDARD_DOWNLOAD,

  URL_ACCESS_REPORT_LISTING,
  URL_BRIEFCASE_LOG_LISTING,
  URL_DOWNLOAD_LOG_LISTING,
  URL_PACKAGE_REPORT_LIST,

  URL_FILE_UPLOAD,
  URL_RECENT_DOWNLOADS,
  URL_RECENT_UPLOADS,
  URL_TOP_DOWNLOADS,
  URL_USER_APPROVAL_LIST,
  URL_SEARCH_LOG,
  URL_IS_INITIATED_NDA,
  URL_INITIATE_NDA,
  URL_REMOVE_PENDING_NDA,
  URL_DOWNLOAD_PENDING_NDA_DOC,
  URL_NDA_REMINDER,
  URL_SEND_MULTIPLE_MESSAGE,
  URL_SSO_VERIFICATION,
  URL_LOGIN_EMAIL,
  URL_LOGGEDIN_USER,
  URL_GOOGLE_AUTH_CODE,
  URL_GOOGLE_AUTH_LOGIN,
  URL_REGISTER,
  URL_RESEND_VERIFICATION_EMAIL,
  URL_CHECK_EMAIL,
  URL_CHECK_USERNAME,
  URL_VERIFY_ACCOUNT,
  URL_ADMIN_USER_RESEND_INVITE,
  URL_REGISTRATION_TOKEN,
  URL_SSO_GOOGLE,
  URL_SSO_MICROSOFT,

  URL_FORGET_PASSWORD,
  URL_FORGET_PASSWORD_TOKEN,
  URL_RESET_PASSWORD,

  URL_PRODUCTS_SEARCH,
  URL_PRODUCTS_LIST,
  URL_PRODUCTS_DETAILS,
  URL_PRODUCTS_ITEMS_DETAILS,
  URL_PRODUCTS_NON_PRODUCT_GROUPS,
  URL_PRODUCTS_NON_PRODUCT_USERS,
  URL_PROJECTS_LIST,
  URL_PROJECT_DETAIL,
  URL_PROJECT_DELETE,
  URL_PROJECT_CREATE,
  URL_PRODUCTS_FRIENDLY_NAME_AVAILABLE,

  URL_NDA_SET_TEMPLATE,

  URL_PACKAGE_LIST,
  URL_PRODUCT_PAGED_LIST,
  URL_PACKAGE_DETAILS,
  URL_PACKAGE_NEW,
  URL_PACKAGE_DELETE,

  URL_PROJECT_POST_CREATE,
  URL_PROJECT_POSTS,
  URL_PROJECT_ADD_MEMBERS,
  URL_PROJECT_MEMBERS,
  URL_PROJECT_REMOVE_MEMBER,
  URL_PROJECT_LEAVE,
  URL_PROJECT_RESEND_INVITE,
  URL_PROJECT_LIST_INVITATIONS,
  URL_PROJECT_ACCEPT_INVITATION,
  URL_PROJECT_DOCUMENTS,
  URL_PROJECT_DOCUMENT,
  URL_PROJECT_DOCUMENT_DOWNLOAD,

  URL_SEARCH_ITEMS,
  URL_PRODUCTS_ITEMS_LIST,
  URL_ITEMS_DETAILS,
  URL_ITEM_DELETE,
  URL_ITEM_DOWNLOAD,

  URL_PROFILE_USERDATA,
  URL_PROFILE_EDITDATA,
  URL_PROFILE_CHANGE_PWD,
  URL_PROFILE_CHANGE_PWD_PDF,
  URL_ADMIN_GROUP_LIST,
  URL_ADMIN_ADD_GROUP,
  URL_ADMIN_REMOVE_GROUP,
  URL_ADMIN_SINGLE_GROUP,
  URL_ADMIN_GROUP_FORCE_RESET,
  URL_ADMIN_GROUP_USERS,
  URL_ADMIN_GROUP_REMOVE_USER,
  URL_ADMIN_GROUP_ASSIGN_USER,
  URL_ADMIN_GROUP_PACKAGES,
  URL_ADMIN_GROUP_ASSIGN_PACKAGE,
  URL_ADMIN_GROUP_ASSIGNED_PACKAGES,
  URL_ADMIN_GROUP_REMOVE_PACKAGE,
  URL_ADMIN_GROUP_SIGNED_NDA,
  URL_ADMIN_USER_LIST,
  URL_ADMIN_USER_EDIT,
  URL_ADMIN_USER_SINGLE,
  URL_STATES_FULL_LIST,
  URL_ADMIN_USER_SAVE,
  URL_ADMIN_USER_REMOVE,
  URL_ADMIN_USER_SEARCH,
  URL_ADMIN_USER_FORCE_RESET,
  URL_ADMIN_USER_PACKAGES,
  URL_ADMIN_USER_ASSIGN_PACKAGE,
  URL_ADMIN_USER_ASSIGNED_PACKAGES,
  URL_ADMIN_USER_REMOVE_PACKAGE,
  URL_ADMIN_USER_CONTACTS,
  URL_ADMIN_USER_ASSIGN_CONTACT,
  URL_ADMIN_USER_REMOVE_CONTACT,
  URL_ADMIN_USER_TYPES_LISTING,
  URL_ADMIN_USER_TYPE_SAVE,
  URL_ADMIN_USER_TYPE_SINGLE,
  URL_ADMIN_USER_TYPE_REMOVE,
  URL_ADMIN_CONTACT_TYPES_LISTING,
  URL_ADMIN_CONTACT_TYPE_SINGLE,
  URL_ADMIN_CONTACT_TYPE_SAVE,
  URL_ADMIN_CONTACT_TYPE_REMOVE,
  
  URL_ADMIN_TAGS_LISTING,
  URL_ADMIN_TAGS_ADD,
  URL_ADMIN_TAGS_EDIT,
  URL_ADMIN_TAGS_REMOVE,
  URL_ADMIN_TAGS_INFO,
  
  URL_ADMIN_SETTINGS,
  URL_ADMIN_SETTINGS_SAVE,

  URL_ADMIN_REINDEX,
  URL_ADMIN_REINDEX_STATUS,

  URL_PROFILE_WALL_POST,
  URL_PROFILE_POST_ACTION,
  URL_PROFILE_POST_APPROVE,

  URL_PROFILE_FRIENDS,
  URL_PROFILE_FRIENDS_PENDING,
  URL_PROFILE_FRIEND_ADD,
  URL_PROFILE_FRIEND_ACCEPT,
  URL_PROFILE_FRIEND_REMOVE,

  URL_PROFILE_ITEMS,
  URL_PROFILE_DOCUMENTS,

  URL_LICENSE_LIST,
  URL_LICENSE_SEARCH,
  URL_LICENSE_SAVE,
  URL_LICENSE_REMOVE,
  URL_LICENSE_SINGLE,
  URL_LICENSE_CREATE,
  URL_LICENSE_DOWNLOAD,
  URL_LICENSE_ASSIGNED_ITEMS,
  URL_LICENSE_ASSIGN_ITEMS,
  URL_LICENSE_DELETE_ITEMS,

  URL_ITEMS_ASSOCIATED_LICENSES,
  URL_ITEMS_ASSOCIATED_LICENSE_LINK,
  URL_ITEMS_LICENSE_AGREED,

  URL_BRIEFCASE_RECEIVED_FILES,
  URL_BRIEFCASE_SENT_FILES,
  URL_BRIEFCASE_SEND,
  URL_BRIEFCASE_DOWNLOAD,
  URL_BRIEFCASE_RESEND,
  URL_BRIEFCASE_DELETE,
  URL_BRIEFCASE_VIEWED,
  URL_BRIEFCASE_FILE_RECEIVED_COUNT,

  URL_LISTINGS_USERS,
  URL_LISTINGS_GROUPS,
  URL_LISTINGS_USERS_VERIFICATION_PENDING,

  URL_NDA_LISTING,
  URL_SLA_LISTING,
  URL_SWEL_LISTING,

  URL_ADMIN_PLATFORM_LISTING,
  URL_ADMIN_PLATFORM_SINGLE,
  URL_ADMIN_PLATFORM_SAVE,
  URL_ADMIN_PLATFORM_REMOVE,
  URL_ADMIN_ITEM_TYPES_LISTING,
  URL_ADMIN_ITEM_TYPE_SINGLE,
  URL_ADMIN_ITEM_TYPE_SAVE,
  URL_ADMIN_ITEM_TYPE_REMOVE,
  URL_ADMIN_ITEM_CATEGORIES_LISTING,
  URL_ADMIN_ITEM_CATEGORY_SINGLE,
  URL_ADMIN_ITEM_CATEGORY_SAVE,
  URL_ADMIN_ITEM_CATEGORY_REMOVE,
  LINK_LOGIN_PAGE,

  URL_GLOBAL_SEARCH,

  URL_VIDEOS,
  URL_ADD_VIDEOS_DIR,
  URL_GET_VIDEOS,
  URL_GET_ALL_VIDEOS,
  URL_STREAM_VIDEO,
  URL_VIDEO_PACKAGE,
  URL_PACKAGE_VIDEOS,

  URL_NOTIFICATIONS_COUNT,
  URL_NOTIFICATIONS_DELETE,
  URL_NOTIFICATIONS_LIST,
  URL_NOTIFICATIONS_MARK_AS_READ,
  URL_NOTIFICATIONS_MARK_AS_UNREAD,
  URL_NOTIFICATIONS_RECENT,

  URL_FRIENDLY_NAME_DOWNLOAD_ITEM,
  URL_FRIENDLY_NAME_ITEM_DETAILS,
  URL_HELPCENTER,
  URL_SUPPORT_ENABLED,
  ZENDESK_REDIRECT_URL,
  ZENDESK_REDIRECT_URL_SYNCUSER,
  SUBMIT_A_TICKET,
  MY_REQUESTS,
  UNSUBSCRIBE_URL,
  URL_EMAIL_TEMPLATE_IFRAME,
  URL_EMAIL_TEMPLATE_PREVIEW,
};
