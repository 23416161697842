import ErrorMessage from '@/common/Form/subcomponents/error-message';
import LocalStore from '@/store/persistence/local-storage';
import Api from '@api';
import Constants from '@constants';
import Axios from 'axios';
import { get } from 'lodash';
import React from 'react';
import { Link, matchPath, Redirect } from 'react-router-dom';
//import OTPInput, { ResendOTP } from "otp-input-react";
import ReactCodeInput from 'react-verification-code-input';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Loader,
  Message,
  Modal,
  Radio,
  Segment,
  Checkbox,
} from 'semantic-ui-react';
import { validate } from 'validate.js';
import { redirectToHelpdeskURL, removeEmptyKeys } from '../../common/utils/utils';
import OktaSignInWidget from '../oktasignin';
import { GoogleLogin } from '@react-oauth/google'
import LoginPage from './onelogin';

class Login extends React.Component {
  validationConfig = {
    username: {
      presence: true,
    },
    password: {
      presence: true,
    },
  };
  validationConfigCode = {
    code: {
      presence: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      token: '',
      validationMsg: {},
      isVerificationTokenPresent: false,
      isVerifyingToken: false,
      isVerifiedToken: false,
      isGoogleAuthFailed: false,
      resendingValidationEmail: false,
      resendValidationEmail: false,
      timeOut: false,
      code: '',
      isLoginScreen: true,
      isEmailCode: false,
      type: '',
      isError: false,
      errorMsg: '',
      timeOutMsg: 'Your session has expired. Please login again to continue.',
      redirectToOneLogin: false,
      isMobileCode: false,
      resendEmailModal: false,
      redirectTo: null,
      googleButtonRendered: false,
    };
  }

  componentDidMount() {
    this.redirectIfLoggedIn();
    this.checkForAccessToken();
    //this.renderGoogleButton();
    if (this.props.location.search.includes('code') || this.props.oktaLoader) {
      this.setState({
        showLoader: true,
      });
    }

    const { URL_VERIFY_ACCOUNT } = Constants.App;
    const match = matchPath(this.props.location.pathname, '/login/:id');
    if (match && match.params && match.params.id) {
      this.setState({
        isVerificationTokenPresent: true,
        isVerifyingToken: true,
      });
      Axios.get(URL_VERIFY_ACCOUNT, {
        params: { token: match.params.id },
      })
        .then(() => {
          this.setState({
            isVerifiedToken: true,
            isVerifyingToken: false,
          });
        }) // test
        .catch((e) => {
          this.setState({
            isVerifiedToken: false,
            isVerifyingToken: false,
          });
        });
    }

    if (LocalStore.getValue('idleTimeout') == true) {
      this.setState({ timeOut: true });
      LocalStore.setValue('idleTimeout', null);
    }
  }

  componentDidUpdate() {
    // check for OneLogin valid token, if valid access_token
    // auto login
    this.redirectIfLoggedIn();
    //this.renderGoogleButton();
  }
  checkForAccessToken = () => {
    const { receiveTokens, token } = this.props;

    if (token) {
      this.setState({
        showLoader: true,
      });
    }
  };

  redirectIfLoggedIn = () => {
    const { user } = this.props;
    const isLoggedIn = get(user, ['loggedin', 'isLoggedIn']);

    if (isLoggedIn) {
      // fetch the token from local storage
      const token = LocalStore.getValue('token');
      // create Axios Instance if its already not created.
      if (!Api.AxiosInstance.hasInstance()) Api.AxiosInstance.create(token);

      if(LocalStore.getValue("supportEnabled")==true && !(LocalStore.getValue("returnTo") == null)){
        redirectToHelpdeskURL(LocalStore.getValue("returnTo"))
      }

      let { from } = this.props.location.state || {
        from: { pathname: '/dashboard' },
      };
      this.props.history.replace(from);
      localStorage.setItem('url', 'login');
    }
  };

  login = (e) => {
    const { login } = this.props;
    e.preventDefault();
    //console.log
    let result = validate(removeEmptyKeys(this.state), this.validationConfig);
    if (!result) {
      const twoFactorRenewalCycle = this.props.config.maps[
        'two_factor_renewal_cycle'
      ]
        ? this.props.config.maps['two_factor_renewal_cycle']
        : 30;
      this.setState({ validationMsg: {} }, () =>
        login(
          this.state.username,
          this.state.password,
          this.state.code,
          this.state.isLoginScreen,
          this.state.isEmailCode,
          this.state.type,
          twoFactorRenewalCycle
        )
      );
    } else {
      this.setState({ validationMsg: result });
    }
  };

  verifyCode = (e) => {

      const { user } = this.props;
      const { code, type, addTrustedDevice } = this.state;
      const username = get(user, ['loggedin', 'username']);
      const password = get(user, ['loggedin', 'password']);

      const { login } = this.props;

      e.preventDefault();
      let result = validate(
        removeEmptyKeys(this.state),
        this.validationConfigCode
      );
      if (!result) {
      } else {
      }
      const twoFactorRenewalCycle = this.props.config.maps[
        'two_factor_renewal_cycle'
      ]
        ? this.props.config.maps['two_factor_renewal_cycle']
        : 30;
      login(
        username,
        password,
        code,
        false,
        type ? type : '2',
        twoFactorRenewalCycle,
        addTrustedDevice
      );
      // }
  };

  microsoftLogin = () => {
    const { origin } = window.location;
    const msalConfig = {
      auth: {
          // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
          clientId: "8c030c18-3fd7-4668-b34a-3b72aca3d43a",
          // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
          // authority: "https://login.microsoftonline.com/c9c64a76-39c4-421b-b93e-47a8969a66f6",
          // Full redirect URL, in form of http://localhost:3000
          redirectUri: origin + "/oauth/ms",
      },
      cache: {
          cacheLocation: "sessionStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      }
    };

    const myMSALObj = new window.msal.PublicClientApplication(msalConfig);
    myMSALObj.loginPopup({loginPopup: {scopes: 'openid offline_access https://graph.microsoft.com/user.read'}})
        .then((data) => {
          const { idToken } = data;
          window.location = window.location.href.replace("/login", "/auth?#id_token=" + idToken)
        })
        .catch(error => {
            console.error(error);
        });
  }
  
  renderGoogleButton = () => {
    const isMobileView = window.innerWidth < 603;
    if (window.google && !this.state.googleButtonRendered) {
      
      window.google.accounts.id.initialize({
        client_id: "385372371524-msvv9b7rm635fn1keopo7fpqd24qj5if.apps.googleusercontent.com",
        callback: this.googleLoginHandler
      });
      
      window.google.accounts.id.prompt((notification) => {
        
        if (notification.isNotDisplayed()) {
            // no sign-in happened, display the button
            window.google.accounts.id.renderButton(
              document.getElementById("google-wrapper"),
              {
                theme: "outline",
                'width': isMobileView ? window.innerWidth + 'px' : '308px',
                'height': 50,
                'longtitle': true
              }  // customization attributes
            );
            this.setState({googleButtonRendered: true});
        }
      });
      // Commented to hide the one tap view
      // if (!isMobileView) window.google.accounts.id.prompt(); // also display the One Tap dialog
    }
  }

  googleLoginHandler = (response) => {
    console.log('google response', response)
    console.log({...this.props.history})
    this.setState({
      redirectTo: this.props.history.location.pathname.replace("/login", "/auth?#id_token=" + response.credential)
    });
  }

  resendValidationEmail = (e) => {
    e.preventDefault();
    const { username, resendingValidationEmail, resendValidationEmail } =
      this.state;

    if (resendingValidationEmail) {
      return;
    }
    if (!username) {
      return;
    }
    const { URL_RESEND_VERIFICATION_EMAIL } = Constants.App;
    this.setState({
      resendingValidationEmail: true,
    });
    Axios.post(URL_RESEND_VERIFICATION_EMAIL, {
      usernameOrEmail: this.state.username,
    })
      .then((resp) => {
        this.setState({
          resendingValidationEmail: false,
          resendValidationEmail: true,
          resendEmailModal: true,
        });
      })
      .catch((e) => {
        this.setState({
          resendingValidationEmail: false,
          resendValidationEmail: false,
        });
      });
  };

  closeErrorPopup = () => {
    this.setState({ timeOut: false, isMobileCode: false });
    this.props.close2FaPopup();
  };

  onGenericChange = (event, eventData) => {
    this.setState({ [eventData.name]: eventData.value });
  };

  radioChange = (e, data) => {
    this.setState({ type: data.value });
  };
  checkShowMessage = (e, value) => {
    this.setState({ addTrustedDevice: value.checked });
  };
  codeChanging = () => {
    if (this.state.isMobileCode) {
      this.setState({ isMobileCode: false });
      this.requestCode('email');
    } else {
      this.setState({ isMobileCode: true });
      this.requestCode('mobile');
    }
  };

  requestCode = (type) => {
    this.setState({ type: type === 'mobile' ? '3' : '2' });
    const { URL_SEND_MULTIPLE_MESSAGE } = Constants.App;
    const { user } = this.props;
    const username = get(user, ['loggedin', 'username']);
    const password = get(user, ['loggedin', 'password']);
    const body = {
      username,
      password,
      type,
    };
    return Axios.post(URL_SEND_MULTIPLE_MESSAGE, body)
      .then((resp) => {
        console.log(resp);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  onCloseResendEmailModal = () => {
    this.setState({
      resendEmailModal: false,
    });
  };
  render() {
    const { user, token } = this.props;

    const {
      username,
      password,
      validationMsg,
      isVerificationTokenPresent,
      isVerifiedToken,
      isVerifyingToken,
      isGoogleAuthFailed,
      resendingValidationEmail,
      resendValidationEmail,
      timeOut,
      timeOutMsg,
      type,
      errorMsg,
      isError,
      redirectTo
    } = this.state;

    if (!!redirectTo) {
      return (<Redirect to={this.state.redirectTo} />);
    }
    
    const error = get(user, ['loggedin', 'loginAction', 'message']);
    const isLoggingIn = get(user, ['loggedin', 'isLoggingIn']);
    let showTwoFactor = get(user, ['loggedin', 'showTwoFactor']);
    const { accessToken } = this.props;

    let showValidateLink = false;
    if (error && error.indexOf('validate') > -1) {
      showValidateLink = true;
    }
    if (this.state.showLoader && accessToken && !user.loggedIn)
      return (
        <Dimmer active={this.state.showLoader}>
          <Loader size="huge">Logging In....</Loader>
        </Dimmer>
      );
    else if (this.props.oktaLoader)
      return (
        <Dimmer active>
          <Loader size="huge">Logging In....</Loader>
        </Dimmer>
      );
    const isMobileView = window.innerWidth < 603;
    return (
      <>
        <Grid
          textAlign="center"
          verticalAlign="middle"
          className="login-wrapper"
        >
          <Grid.Column style={{ maxWidth: 644 }}>
            <Header as="h1" className="base-heading" textAlign="center">
              Welcome to the Groq Secure Customer Portal
              <br /> Login to Your Account!
            </Header>
            {this.state.redirectToOneLogin && <LoginPage />}

            <Modal
              size="tiny"
              open={showTwoFactor}
              className="two-factor-popup"
              // onClose={() => {
              //   this.setState({ showTwoFactor: false });
              // }}
              closeIcon
              onClose={() => {
                this.closeErrorPopup();
              }}
            >
              {' '}
              <Modal.Header>Two-Step Verification</Modal.Header>
              <Modal.Content>
                <div className="ui message tiny">
                  <div className="header">
                    Please enter the 6 digit verification code which has been
                    sent to your{' '}
                    {this.state.isMobileCode ? (
                      <b className="clr-text-secondary"> Mobile </b>
                    ) : (
                      <b className="clr-text-secondary"> Email </b>
                    )}{' '}
                    account.
                    {/* Verification code validity is{" "}
                    <b className="clr-text-secondary">
                    {this.state.isMobileCode
                      ? this.props.config.maps["sms_expiry_time"] / 60 +
                      " " +
                      "minute(s)"
                      : this.props.config.maps["email_expiry_time"] / 60 +
                      " " +
                      "minute(s)"}
                    </b> */}
                  </div>
                </div>

                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <label className="lable-2-factor">
                        Your Verification Code
                      </label>

                      <ReactCodeInput className="factor-block"
                        onComplete={(e) => {
                          this.setState(
                            { code: e },
                            document.getElementById('verify-button').focus()
                          );
                        }}
                      />
                      <ErrorMessage
                        isError={isError}
                        color="red"
                        errorMsg={errorMsg}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={16}
                      className="m--align-center-im"
                    >
                      <label className="lable-2-factor m--align-center-im m-0">
                        {' '}
                        Didn't receive the code?
                      </label>
                      <button
                        class="ui primary button"
                        onClick={() => {
                          this.codeChanging();
                        }}
                      >
                        {this.state.isMobileCode
                          ? 'Send to Email'
                          : 'Send to Mobile'}
                      </button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Content>
              <Modal.Actions>
                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={12}
                      computer={8}
                      className="m--align-left"
                    >
                      <p
                        className="d-inline"
                        title="If you want to add this device as trusted device, select this checkbox, otherwise you will have to re-enter two factor authentication code."
                      >
                        <Checkbox
                          label="Add as trusted device"
                          onChange={($event, data) =>
                            this.checkShowMessage($event, data)
                          }
                        />
                      </p>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={4} computer={8}>
                      <Button
                        type="submit"
                        id={'verify-button'}
                        primary
                        onClick={(e) => {
                          this.verifyCode(e);
                        }}
                      >
                        Verify
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Actions>
            </Modal>

            <Segment stacked>
              {/* <Button
                size="large"
                className="m--no-margin-im button-primary"
                fluid
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    redirectToOneLogin: true,
                  });
                }}
              >
                Sign-in with OneLogin
                      </Button> */}
              <Form
                size="large"
                error={!!error}
                onSubmit={this.login}
                disabled={isLoggingIn}
              >
                {' '}
                {!error && this.props.oktaUserDisabled && (
                  <Message
                    error
                    header="Login Failed"
                    content={this.props.oktaErrorOnVerification}
                  />
                )}
                {isVerificationTokenPresent && (
                  <Message
                    header="Account Verification!"
                    content={
                      isVerifyingToken
                        ? 'Verifying your account! Please wait.'
                        : isVerifiedToken
                        ? 'Account verified. Please proceed to login.'
                        : 'Account has already been verified, Please Login'
                    }
                  />
                )}
                {isGoogleAuthFailed && (
                  <Message
                    error
                    header="Account Verification!"
                    content="Failed to login with Google."
                  />
                )}
                <Message
                  error
                  header={
                    showValidateLink
                      ? 'Verify your Email Address!'
                      : 'Login Failed'
                  }
                  content={error}
                />
                {showValidateLink && (
                  <Segment stacked>
                    <Dimmer active={resendingValidationEmail}>
                      <Loader>Loading!</Loader>
                    </Dimmer>

                    <em>
                      A verification email has been sent! Follow the
                      instructions in the email to finish creating your account.
                      <br />
                      <br />
                      Didn't get an email?{' '}
                      <a href="#" onClick={this.resendValidationEmail}>
                        Send again!
                      </a>
                    </em>
                  </Segment>
                )}
                {/* <span className="block-or">OR</span> */}
                <Form.Field required error={validationMsg.username}>
                  <Form.Input
                    fluid
                    placeholder="Username"
                    value={this.state.username}
                    onChange={(e) => {
                      this.setState({username: e.target.value});
                    }}
                  />
                  <ErrorMessage
                    isError={validationMsg.username}
                    errorMsg={
                      validationMsg.username && validationMsg.username[0]
                    }
                  />
                </Form.Field>
                <Form.Field required error={validationMsg.password}>
                  <Form.Input
                    fluid
                    placeholder="Password"
                    type="password"
                    value={this.state.password}
                    onChange={(e) => {
                      this.setState({password: e.target.value})
                    }}
                  />
                  <ErrorMessage
                    isError={validationMsg.password}
                    errorMsg={
                      validationMsg.password && validationMsg.password[0]
                    }
                  />
                </Form.Field>
                <Button
                  className="m--no-margin-im"
                  primary
                  fluid
                  size="large"
                  disabled={get(user, ['loggedin', 'isLoggingIn'])}
                >
                  Login
                </Button>
                <Grid stackable className="forgot-signup-block">
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={10}
                      computer={10}
                      largeScreen={10}
                      textAlign="left"
                      verticalAlign="middle"
                    >
                      <Link to="/login-help">
                        Don't remember your password?
                      </Link>
                      <br />

                      <OktaSignInWidget
                        receiveTokens={this.props.receiveTokens}
                        gettingOktaToken={this.props.gettingOktaToken}
                        recievedOktaToken={this.props.recievedOktaToken}
                        {...this.props}
                      />
                    </Grid.Column>

                    <Grid.Column
                      mobile={16}
                      tablet={6}
                      computer={6}
                      largeScreen={6}
                      textAlign="right"
                    >
                      &nbsp;
                      {/* New to us? <Link to="/register">Sign Up</Link> */}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Segment>
            <div className='sso-wrapper'>
              {/**
               * Commenting this because there were too many problems with this implementation
               */}
              {/*
                <div id="google-wrapper" className='google-wrapper'></div>
              */}
              <GoogleLogin width={isMobileView ? window.innerWidth + 'px' : '300px'} id="google-wrapper" className="google-wrapper" onSuccess={this.googleLoginHandler} useOneTap />
              <div className='microsoft-wrapper' style={{width: isMobileView ? window.innerWidth + 'px' : '305px'}}>
                  <button class="btn btn-primary" id="login-microsoft" onClick={this.microsoftLogin}>
                    <img src="https://learn.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png"/>
                    Sign in with Microsoft
                  </button>
              </div>
            </div>
            
          </Grid.Column>
          {/* <Modal
            open={timeOut}
            onClose={() => {
              this.closeErrorPopup();
            }}
            centered={true}
            size="tiny"
            content={timeOutMsg}
            actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
          ></Modal>4*/}
        </Grid>
        ={' '}
        <Modal
          open={this.state.resendEmailModal}
          onClose={this.onCloseResendEmailModal}
          centered={true}
          size="tiny"
          content="Verification email has been sent, Please check your email to verify your account."
          actions={[{ key: 'warningDismiss', content: 'OK', primary: true }]}
        />
      </>
    );
  }
}

export default Login;
