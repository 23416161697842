import { CardviewMobile } from "@/common/cardview";
import update from "immutability-helper";
import { merge } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  Dropdown,
  Grid,
  Modal,
  Responsive,
  Segment,
} from "semantic-ui-react";
import Api from "../../api";
import urls from "../../config/constants";
import { authoritySelector } from "../selectors";
import Tableview from "../tableview";
import { connect } from "react-redux";

class MembersListing extends React.Component {
  Constants = {
    COLUMNS: [
      {
        name: "Username",
        key: "username",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("username", "Username");
        },
      },
      {
        name: "First Name",
        key: "firstName",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("firstName", "First Name");
        },
      },
      {
        name: "Last Name",
        key: "lastName",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("lastName", "Last Name");
        },
      },
      {
        name: "Company",
        key: "company",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("company", "Company");
        },
      },
      {
        name: "Email",
        key: "email",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("email", "Email");
        },
      },
      {
        name: "Group",
        key: "group",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("groups.name", "Group");
        },
      },
      {
        name: "Type",
        key: "type",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("userType", "Type");
        },
      },
    ],
    RESPONSIVE_COLUMNS: [
      {
        name: "Username",
        key: "username",
      },
      {
        name: "First Name",
        key: "firstName",
      },
      {
        name: "Last Name",
        key: "lastName",
      },
      {
        name: "Company",
        key: "company",
      },
      {
        name: "Email",
        key: "email",
      },
      {
        name: "Group",
        key: "group",
      },
      {
        name: "Type",
        key: "type",
      },
    ],
    SearchOptions: [
      {
        key: "username-search",
        text: "Username",
        value: "username",
      },
      {
        key: "firstName-search",
        text: "First Name",
        value: "firstname",
      },
      {
        key: "lastName-search",
        text: "Last Name",
        value: "lastname",
      },
      {
        key: "company-search",
        text: "Company",
        value: "company",
      },
      {
        key: "email-search",
        text: "Email",
        value: "email",
      },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      usersList: {},
      configOption: {},
      selectedStatedUsers: [],
      selectedUsersIndexes: [],
      checkedUserIds: [],
    };
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.COLUMNS = this.Constants.COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchUsers();
    });
  };

  fetchUsers = () => {
    const { URL_LISTINGS_USERS } = urls.App;
    const { configOption } = this.state;
    const { entity, entityId, role } = this.props;
    const paramsForPerspective = {
      entity,
      entityId,
    };
    const config = Object.assign(
      {
        roles: role,
        sort: this.state.sortBy,
      },
      configOption
    );
    const params = {
      params: merge(config, { id: this.props.match.params.id , status: "ACTIVE"}),
    };
    const instance = Api.AxiosInstance.getInstance();
    return instance.get("projects/members", params).then((resp) => {
      this.setState({ usersList: resp.data });
    });
  };

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.fetchUsers();
    }
  }

  onItemSelection = (e) => {
    let selectedStatedUsers = this.state.selectedStatedUsers;
    let ids = this.state.checkedUserIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedUsers.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedUsers.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedUsers = selectedStatedUsers.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedUsers.map((s) => s.id);
    }
    this.setState({ checkedUserIds: ids });

    this.setState({
      selectedStatedUsers: selectedStatedUsers,
    });
  };

  onAllItemSelection = (selectedUsers, flag) => {
    let { selectedStatedUsers } = this.state;
    let ids = [];
    if (flag) {
      selectedUsers.map((user) => {
        selectedStatedUsers = selectedStatedUsers.filter((entity) => {
          return entity.id !== user.id;
        });
      });
    } else {
      selectedUsers.map((user) => {
        if (user) {
          const alreadyPushed = selectedStatedUsers.find(
            (element) => element.id == user.id
          );
          if (!alreadyPushed) {
            selectedStatedUsers.push(user);
          }
        }
      });
    }
    ids = selectedStatedUsers.map((s) => s.id);
    this.setState({
      selectedStatedUsers: selectedStatedUsers,
      checkedUserIds: ids,
    });
  };
  onPageChange = (e) => {
    const { configOption } = this.state;
    this.setState(
      {
        configOption: update(configOption, {
          page: {
            $set: e.page,
          },
        }),
      },
      this.fetchUsers
    );
  };

  onSearchClicked = () => {
    // always fetch the first page while searching. You never know if it has got
    // more than one page data or not.
    this.onPageChange({ page: 0 });
  };

  onSearchTextChange = (data) => {
    const { configOption } = this.state;
    this.setState({
      configOption: update(configOption, {
        search: {
          $set: data.target.value,
        },
      }),
    });
  };

  onSearchTypeChange = (e, data) => {
    const { configOption } = this.state;
    this.setState({
      configOption: update(configOption, {
        type: {
          $set: data.value,
        },
      }),
    });
  };

  resetSearchConfig = () => {
    this.setState({ configOption: {} });
  };

  onModalClose = () => {
    this.resetSearchConfig();
    const { onDismiss } = this.props;
    if (onDismiss) onDismiss();
    this.setState({
      selectedStatedUsers: [],
      checkedUserIds: [],
    });
  };

  render() {
    if (this.state.usersList.content) {
      this.state.usersList.content = this.state.usersList.content.map((x) => {
        if (this.props.authority === "websilo-customer") {
          if (x.userType === 1) {
            return {
              ...x,
            };
          } else {
            return {
              ...x,
              email: "--",
              type: "--",
            };
          }
        } else {
          return {
            ...x,
          };
        }
      });
    }
    const {
      usersList,
      selectedStatedUsers,
      selectedUsersIndexes,
      checkedUserIds,
    } = this.state;
    const { onSave, show, selectionType, title } = this.props;
    const { COLUMNS, SearchOptions, RESPONSIVE_COLUMNS } = this.Constants;
    return (
      <Modal
        open={show}
        onMount={this.fetchUsers}
        onClose={this.onModalClose}
        centered={true}
        size="large"
      >
        <Modal.Header>
          <div className="header">
            <div className="side-marker" />
            {title}
          </div>
        </Modal.Header>
        <Modal.Content>
          <Segment
            className="p-0"
            basic
            // size="mini"
            // padded={false}
          >
            <Grid>
              <Grid.Row className="common-filter-wrapper ui form">
                <Grid.Column
                  mobile={16}
                  tablet={6}
                  computer={6}
                  largeScreen={6}
                >
                  <div class="field">
                    <input
                      placeholder="Search"
                      className="search-bar"
                      onChange={this.onSearchTextChange}
                      onKeyPress={this.onKeyUp}
                    ></input>
                  </div>
                </Grid.Column>
                {/* <Grid.Column
                  mobile={16}
                  tablet={6}
                  computer={6}
                  largeScreen={6}
                >
                  <div class="field">
                    <Dropdown
                      options={SearchOptions}
                      selection
                      placeholder="Field Type"
                      onChange={this.onSearchTypeChange}
                    />
                  </div>
                </Grid.Column> */}

                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={4}
                  largeScreen={4}
                >
                  <div class="field">
                    <button
                      onClick={this.onSearchClicked}
                      className="button-primary"
                    >
                      Search
                    </button>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Responsive minWidth={1024}>
                    <div className="table-wrapper-with-pagination">
                      <Tableview
                        paginate
                        selectionType={selectionType}
                        data={usersList}
                        checkedIndexes={selectedUsersIndexes}
                        checkedIds={checkedUserIds}
                        columns={COLUMNS}
                        onSelectionChange={this.onItemSelection}
                        onAllSelectionChange={this.onAllItemSelection}
                        onPaginationChange={this.onPageChange}
                        noDataMessage="No user found."
                      />
                    </div>
                  </Responsive>

                  <Responsive maxWidth={1023}>
                    <CardviewMobile
                      noDataMessage="No user found."
                      selectionType={selectionType}
                      data={usersList}
                      columns={RESPONSIVE_COLUMNS}
                      paginate
                      onPaginationChange={this.onPageChange}
                      onSelectionChange={this.onItemSelection}
                      onAllSelectionChange={this.onAllItemSelection}
                    />
                  </Responsive>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="button"
                    className="button-primary"
                    value="Save"
                    onClick={() => {
                      onSave(selectedStatedUsers);
                      this.setState({
                        selectedStatedUsers: [],
                        checkedUserIds: [],
                      });
                    }}
                  />

                  <Button onClick={this.onModalClose} className="button-basic">
                    Close
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}

MembersListing.propTypes = {
  /** Callback which is fired when Popup is closed. */
  onDismiss: PropTypes.func.isRequired,
  /** Callback which is fired when `resend` button is clicked. */
  onSave: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
  /** single-select = radio buttons, multi-select = checkboxes */
  selectionType: PropTypes.oneOf(["single-select", "multi-select"]),
  /** Perspective: */
  entity: PropTypes.oneOf([
    "ProjectMembers",
    "PackageUsers",
    "GroupUsers",
    "ProductUsers",
  ]),
  /** */
  entityId: PropTypes.number,
  /** */
  role: PropTypes.string,
};

MembersListing.defaultProps = {
  selectionType: "multi-select",
  title: "List of Project Members",
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  authority: authoritySelector(state),
});

const allUsers = connect(mapStateToProps, mapDispatchToProps)(MembersListing);

export default allUsers;
