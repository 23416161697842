import React from 'react'
import {Card, Image, List, Confirm, Button} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import Api from "../../../api"

function Notifications(props) {
 const { title, data } = props;
  return (
   <Card>
     <Card.Content className="dark-blue-background-shade" header={title} />
       <Card.Content>
          <List relaxed>
             <List.Item>
              <List.Content>
                 {data && data.members ? data.members + ' new Member(s)!' : "» No New Member" }
              </List.Content>
             </List.Item>
             <List.Item>
              <List.Content>
                {data && data.documents ? data.documents + ' new Document(s)!' : "» No New Document" }
              </List.Content>
             </List.Item>
             <List.Item>
              <List.Content>
                {data && data.posts ? data.posts + ' new Post(s)' : "» No New Wall post" }
              </List.Content>
             </List.Item>
          </List>
       </Card.Content>
   </Card>
  );
}

export default Notifications
