import { stringify } from 'query-string';
import urls from '@/config/constants';

// Static oidc params for a single provider
const authority = 'https://groq.onelogin.com/oidc/2';
const client_id = '01f6de90-1649-0139-bee4-0670b1bbb8bb91930';
// const redirect_uri = "http://localhost:3000/sso";
const redirect_uri = 'https://staging-ph2-groq.folio3.com/sso';
// const redirect_uri = "https://groq.folio3.com:8011/auth";
// const redirect_uri = 'https://staging-groq.folio3.com/sso';
// const redirect_uri = "https://groq.folio3.com:8000/auth";
// const redirect_uri = "https://groq.folio3.com.8018/auth";
// for prod test-release
//const redirect_uri = "https://support.groq.com/sso";
// const redirect_uri = "https://34.82.62.53/auth";
// const redirect_uri = urls.App.BASE_URL + "auth";

const response_type = 'id_token token';
const scope = 'openid profile';

export const beginAuth = ({ state, nonce }) => {
  // Generate authentication URL
  const params = stringify({
    client_id,
    redirect_uri,
    response_type,
    scope,
    state,
    nonce,
  });
  const authUrl = `${authority}/auth?${params}`;
  console.log(authUrl);

  // Attempt login by navigating to authUrl
  window.location.assign(authUrl);
};
