import { removeEmptyKeys } from "@/common/utils/utils";
import Api from "@api";
import Constants from "@constants";
import update from "immutability-helper";
import { debounce, isEmpty, merge } from "lodash";
import moment from "moment";
import React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Confirm,
  Container,
  Form,
  Message,
  Modal,
} from "semantic-ui-react";
import validate from "validate.js";
import { dateToPacific } from "../../../../utils/dateTime";
import ItemDetails from "../components/_item-details";
import ProductsContext from "../_context";

class AddItems extends React.Component {
  VALIDATION_CONFIG = {
    categoryId: {
      presence: true,
    },
    platformId: {
      presence: true,
    },
    status: {
      presence: true,
    },
    name: {
      presence: true,
    },
    file: {
      presence: true,
    },
    availableDate: {
      presence: true,
    },
    expireType: {
      presence: true,
    },
  };

  constructor(props) {
    super(props);
    const { selectedProduct } = this.props.data;
    this.state = {
      redirectTo: false,
      data: {
        name: "",
        friendlyName: "",
        description: "",
        parentId: selectedProduct,
        availableDate: dateToPacific(new Date().getTime()),
        file: null,
        expireValue: dateToPacific(new Date().getTime()),
        expireType: "NEVER",
        isFriendlyNameOccupied: 0,
        isFriendlyNameWarningAccepted: 0,
      },
      isSaving: false,
      isUploading: false,
      validationMsg: {},
      uploadProgress: 0,
      fileuuid: "",
      fileCancelToken: null,
      showFriendlyNameWarningPopup: false,
      friendlyNameWarningMsg: "",
      popupWarning: false,
    };
  }

  onChange = (event, eventData) => {
    const { data } = this.state;
    const { name, value } = eventData;
    if (event === "NEVER" || event === "DAYS" || event === "DATE") {
      const expireValue = eventData,
        expireType = event;
      this.setState({
        data: update(data, {
          expireValue: { $set: expireValue },
          expireType: { $set: expireType },
        }),
      });
    } else {
      this.setState({ data: update(data, { [name]: { $set: value } }) });
    }

    if (eventData.name == "friendlyName") {
      this.isDisplayNameAvailable();
    }
  };

  isDisplayNameAvailable = debounce(() => {
    const instance = Api.AxiosInstance.getInstance();
    const url = Constants.App.URL_PRODUCTS_FRIENDLY_NAME_AVAILABLE;
    let { friendlyName } = this.state.data;
    instance
      .get(url, { params: { friendlyName } })
      .then((resp) => {
        const isAvailable = resp.data.isAvailable;
        const itemName = resp.data.itemName;

        if (!isAvailable) {
          this.setState({ showFriendlyNameWarningPopup: true });
          this.setState({
            friendlyNameWarningMsg: `The friendly name provided is already in use for another file "${itemName}". Do you want to transfer the friendly name to this file?`,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, 1000);

  hideFNWarning = (cb) => {
    this.setState(
      {
        showFriendlyNameWarningPopup: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  updateFriendlyName = () => {
    const { data } = this.state;
    data.isFriendlyNameOccupied = 1;
    data.isFriendlyNameWarningAccepted = 1;
    this.setState({ data });
  };

  clearFriendlyName = () => {
    const { data } = this.state;
    data.friendlyName = "";
    this.setState({ data });
  };

  onFileChange = (eventData) => {
    if (!eventData) return;
    if ((typeof eventData === "string") && eventData === "removed") {
      if (!!this.state.fileCancelToken) {
        this.state.fileCancelToken.cancel("File removed manually.");
        this.setState({ isUploading: false, fileCancelToken: null });
      }
    } else if (eventData.isUploading) {
      const { file, isUploading } = eventData;
      let { data } = this.state;
      
      if (!!this.state.fileCancelToken) {
        this.state.fileCancelToken.cancel("File replaced manually.");
      }

      if (file.name && file.size) {
        data = update(data, { file: { $set: file } });
        this.setState({ isUploading, data, fileCancelToken: eventData.cancelToken });
      }
    } else if (typeof eventData.uploadProgress !== "undefined") {
      const { uploadProgress } = eventData;
      const isUploading = uploadProgress !== 100;
      this.setState({ isUploading, uploadProgress });
    } else if (typeof eventData.fileuuid !== "undefined") {
      const { fileuuid } = eventData;
      this.setState({ fileuuid });
    } else if (typeof eventData.errorMsg !== "undefined") {
      const { errorMsg } = eventData;
      this.setState({ errorMsg, showError: true, isUploading: false });
    }
  };

  redirectToParent = () => {
    this.setState({
      redirectTo:
        this.props.data.baseUrl + "/" + this.props.data.selectedProduct,
    });
  };

  validate = () => {
    let result = validate(
      removeEmptyKeys(this.state.data),
      this.VALIDATION_CONFIG
    );
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };

  showpopupWarning = () => {
    if (!this.props.data.selectedProduct) {
      return;
    }
    if (!this.validate()) return;
    this.setState({
      popupWarning: true,
    });
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  saveDetails = () => {
    if (!this.state.data.parentId) {
      return;
    }
    if (!this.validate()) return;

    const { fileuuid, DATE_FORMAT } = this.state;
    const {
      name,
      friendlyName,
      isFriendlyNameOccupied,
      isFriendlyNameWarningAccepted,
      description,
      categoryId,
      platformId,
      status,
      file,
      expireValue,
      expireType,
      availableDate,
    } = this.state.data;

    var postObj = {
      actiontype: "ADD_ITEM",
      parent_id: this.state.data.parentId,
      name: name,
      description: description,
      status: status,
      category: categoryId,
      platform: platformId,
      expireType: expireType,
      availableDate: availableDate,
      expireValue: expireValue,
      fileuuid: fileuuid,
      friendlyName,
      isFriendlyNameOccupied,
      isFriendlyNameWarningAccepted,
    };

    var fileObj = {
      filename: file.name,
      filetype: file.type,
      filesize: file.size,
    };

    postObj = merge(postObj, fileObj);

    const params = {};
    let url = Constants.App.URL_ITEMS_DETAILS;
    this.props.dispatch({ type: "loading-list" });
    this.setState({ isSaving: true });
    const instance = Api.AxiosInstance.getInstance();
    if (postObj.availableDate) {
      if (typeof postObj.availableDate == "object") {
        postObj.availableDate = moment(postObj.availableDate).format(
          "YYYY-MM-DD"
        );
      } else {
        postObj.availableDate = postObj.availableDate;
      }
    }
    if (postObj.expireType && postObj.expireType == "DATE") {
      postObj.expireValue = moment(postObj.expireValue).format("YYYY-MM-DD");
    }
    instance
      .post(url, postObj, params)
      .then((resp) => {
        this.setState({ isSaving: false });
        this.redirectToParent();
      })
      .catch((error) => {
        this.props.dispatch({ type: "update-list", details: "" });
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ isSaving: false, showError: true, errorMsg: errorMsg });
      });
  };
  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  render() {
    const {
      name,
      friendlyName,
      description,
      categoryId,
      platformId,
      availableDate,
      status,
    } = this.state.data;
    const {
      validationMsg,
      isSaving,
      isUploading,
      uploadProgress,
      showError,
      errorMsg,
      data,
    } = this.state;
    // const formattedAvailableDate = availableDate;
    // availableDate && moment(new Date(availableDate)).format();

    return (
      <Container>
        <Form>
          <ItemDetails
            onChange={this.onChange}
            minDate={new Date()}
            defaultExpiry={data.expireValue}
            name={name}
            friendlyName={friendlyName}
            description={description}
            parentId={this.state.data.parentId}
            categoryId={categoryId}
            platformId={platformId}
            availableDate={availableDate}
            status={status}
            parentDropDownDisabled={true}
            productsOptions={this.props.data.productsDropDownMap}
            validationMsg={validationMsg}
            onFileChange={this.onFileChange}
            uploadProgress={{ isUploading, uploadProgress }}
          />

          <div className="empty-height"></div>
          {showError && <Message error content={errorMsg} />}
          <Modal
            open={showError}
            onClose={() => {
              this.closeErrorPopup();
            }}
            centered={true}
            size="tiny"
            content={errorMsg}
            actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
          ></Modal>
          <div className="row">
            <Button
              type="submit"
              className="button-primary m--no-margin-left-im"
              disabled={isSaving}
              onClick={this.showpopupWarning}
            >
              Save
            </Button>
            <Button
              className="button-basic"
              type="reset"
              disabled={isSaving}
              onClick={(e) => {
                e.preventDefault();
                this.redirectToParent();
              }}
            >
              Cancel
            </Button>
          </div>
          <br />
        </Form>
        {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}

        <Confirm
          open={this.state.showFriendlyNameWarningPopup}
          onCancel={() => this.hideFNWarning(this.clearFriendlyName)}
          onConfirm={() => this.hideFNWarning(this.updateFriendlyName)}
          content={this.state.friendlyNameWarningMsg}
          confirmButton="Transfer"
        />
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.saveDetails)}
          content="Are you sure you want to add new item?"
        />
      </Container>
    );
  }
}

export default ProductsContext.Consumer(AddItems);
