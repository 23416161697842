import React, { Component } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import CountryField from "./../../utility/country-field";
import DateField from "./../../utility/date-field";

class SearchFields extends Component {
  Constants = {
    EXPORT_CONTROL: Object.freeze([
      { text: "ALL", key: "ALL", value: "ALL" },
      { text: "Yes", key: "yes", value: "YES" },
      { text: "No", key: "NO", value: "NO" },
    ]),

    DATE_RANGE: Object.freeze([
      { text: "ALL", key: "ALL", value: "ALL" },
      { text: "Today", key: "today", value: "today" },
      { text: "Last Week", key: "lastWeek", value: "lastWeek" },
      { text: "This Week", key: "thisWeek", value: "thisWeek" },
      { text: "This Month", key: "thisMonth", value: "thisMonth" },
      { text: "last 6 Months", key: "last6Months", value: "last6Months" },
      { text: "Last Year", key: "lastYear", value: "lastYear" },
      { text: "This Year", key: "thisYear", value: "thisYear" },
      { text: "Custom", key: "Custom", value: "custom" },
    ]),
  };
  constructor(props) {
    super(props);
    this.onKeyUp = this.onKeyUp.bind(this);
  }
  onKeyUp(event) {
    if (event.charCode === 13) {
      this.props.getDownloadLogs();
    }
  }
  render() {
    const {
      username,
      country,
      itemName,
      company,
      toDate,
      customfromDate,
      DATE_FORMAT,
      exportControlled,
      dateRange,
    } = this.props;
    const { EXPORT_CONTROL, DATE_RANGE } = this.Constants;
    return (
      <Grid.Row className="common-filter-wrapper ui form">
        <Grid.Column mobile={16} tablet={5} computer={4} largeScreen={4}>
          <div class="field">
            <div class="ui input">
              <input
                placeholder="User Name"
                className="search-bar"
                onChange={(e) => this.props.setFields(e, "username")}
                value={username}
                onKeyPress={this.onKeyUp}
              ></input>
            </div>
            <a
              href="#"
              target="_blank"
              onClick={(e) => this.props.onSelectUser(e)}
            >
              Select User
            </a>
          </div>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={5} computer={4} largeScreen={4}>
          <div class="field">
            <div class="ui input">
              <input
                placeholder="Item Name"
                className="search-bar"
                onChange={(e) => this.props.setFields(e, "itemName")}
                value={itemName}
                onKeyPress={this.onKeyUp}
              ></input>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={4} largeScreen={4}>
          <div class="field">
            <div class="ui input">
              <input
                placeholder="Company Name"
                className="search-bar"
                onChange={(e) => this.props.setFields(e, "company")}
                value={company}
                onKeyPress={this.onKeyUp}
              ></input>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={4} largeScreen={4}>
          <div class="field">
            <div class="ui input">
              <Dropdown
                options={EXPORT_CONTROL}
                selection
                defaultValue={exportControlled}
                value={exportControlled}
                placeholder="Export Control"
                onChange={(event, data) =>
                  this.props.onDropDownChange("exportControlled", data.value)
                }
              />
            </div>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={4} largeScreen={4}>
          <div class="field">
            <div class="ui input">
              <CountryField
                value={country}
                name="country"
                onChange={(event, data) =>
                  this.props.onDropDownChange("country", data.value)
                }
              />
            </div>
          </div>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={5} computer={4} largeScreen={4}>
          <div class="field">
            <div class="ui input">
              <Dropdown
                options={DATE_RANGE}
                selection
                defaultValue={dateRange}
                value={dateRange}
                placeholder="Date Range"
                value={dateRange}
                onChange={(event, data) =>
                  this.props.onDropDownChange("dateRange", data.value)
                }
              />
            </div>
          </div>
        </Grid.Column>
        {dateRange === "custom" && (
          <Grid.Column mobile={16} tablet={5} computer={4} largeScreen={4}>
            <div class="field">
              <div class="ui input">
                <label style={{ margin: "5px 2px 5px 1px" }}> From: </label>
                <DateField
                  name="customfromDate"
                  format={DATE_FORMAT}
                  onChange={this.props.handleChange}
                  value={customfromDate}
                />
                <label style={{ margin: "5px 2px 5px 1px" }}> To: </label>
                <DateField
                  name="toDate"
                  format={DATE_FORMAT}
                  onChange={this.props.handleChange}
                  value={toDate}
                />
              </div>
            </div>
          </Grid.Column>
        )}

        <Grid.Column
          mobile={16}
          tablet={6}
          computer={4}
          largeScreen={4}

          className="button-inline-display"
        >
          <div class="field">
            <div class="ui input">
              <button
                onClick={this.props.getDownloadLogs}
                className="button-primary m--no-margin-left-im"
              >
                Search
              </button>{" "}
            </div>
          </div>
          <div class="field">
            <div class="ui input">
              <button
                onClick={(e) => this.props.clearState(e)}
                className="button-basic"
              >
                Clear
              </button>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

export default SearchFields;
