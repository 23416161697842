import Axios from "axios";
import Actions from "../../store/actions";
import Constants from "../../config/constants";
import LocalStore from "@/store/persistence/local-storage";

export const inviteUsersApi = (data) => async (dispatch) => {
  dispatch({
    type: Actions.Admin.INVITE_USER_INITIATE,
  });
  try {
    const token = LocalStore.getValue("token");
    await Axios.post(`${Constants.App.API_URL}admin/user/invite-user`, data, {
      headers: {
        Authorization: token,
      },
    });
    dispatch({
      type: Actions.Admin.INVITE_USER_SUCCESS,
    });
  } catch (ex) {
    dispatch({
      type: Actions.Admin.INVITE_USER_FAILURE,
      payload: ex.response.message,
    });
  }
};
