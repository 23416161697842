import { isEmpty, sortBy, isArray } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userTypesSelector, authoritySelector } from "../../selectors";
import DropdownField from "./dropdown-field";

const getUserTypesList = (usertype, userRole,licenseUsers) => {
  const userTypeList = usertype ? Object.keys(usertype).reduce((acc, el) => {
    acc.push({ key: el, value: parseInt(el), text: usertype[el] });
    return acc;
  }, []) : [];

  // as per basecamp todo:https://3.basecamp.com/3953713/buckets/19656542/todos/3715741239
  // GP3-241 to short  down roles to customer and non-nda customer
  // TODO REVISIT HOW ALL THIS WORKS.
  if(licenseUsers){
    var filtered = userTypeList.filter(function(value, index, arr) {
      return !(value.key == 2 || value.key == 4 || value.key == 5 || value.key == 6 || value.key == 7);
    });
  }
  else if (userRole == "websilo-sysadmin") {
    var filtered = userTypeList;
  } else if (userRole == "websilo-admin") {
    var filtered = userTypeList.filter(function (value, index, arr) {
      return value.key != 4;
    });
  } else {
    var filtered = userTypeList.filter(function (value, index, arr) {
      return !(value.key == 4 || value.key == 6);
    });
  }
  const sortedusertypes = sortBy(filtered, [
    function (o) {
      return o.text;
    },
  ]);
  return sortedusertypes;
};

const UserTypeField = ({
  userTypes,
  errorMsg,
  isError,
  isRequired,
  label,
  name,
  onChange,
  value,
  width,
  userRole,
  multiple,
  licenseUsers,
  className,
  placeholder,
  extraOptions,
}) => {
  const isMultiple = !!multiple;
  
  // TODO REVISIT HOW ALL THIS WORKS.
  const [selectedValues, setSelectedValues] = useState(isArray(value) ? [...value] : value);
  const [options, setOptions] = useState(getUserTypesList(userTypes, userRole, licenseUsers));
  
  useEffect(() => {
    if (value != 0 && isEmpty(value)) {
      const defaultValuesForLicensedUsers = options.map((userType) => userType.value);
      setSelectedValues(defaultValuesForLicensedUsers);
      onChange(null, {name, value: defaultValuesForLicensedUsers});
    }
  }, []);

  useEffect(() => {
    setOptions(getUserTypesList(userTypes, userRole, licenseUsers));
  }, [userTypes, userRole, licenseUsers]);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  const onChangeWrapper = (event, { name, value }) => {
    setSelectedValues(value);
    onChange(event, { name, value });
  };
  
  if (isEmpty(userTypes)) {
    return <></>;
  }

  return (
    <DropdownField
      errorMsg={errorMsg}
      isError={isError}
      isRequired={isRequired}
      label={label}
      name={name}
      onChange={onChangeWrapper}
      options={extraOptions ? extraOptions.concat(options) : options}
      placeholder={placeholder ? placeholder : "Select User Type..."}
      value={selectedValues}
      width={width}
      multiple={isMultiple}
      className={className}
    />
  );
};

UserTypeField.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string,
  /** If true, will allow multiple selection. */
  multiple: PropTypes.bool,
  
  className: PropTypes.string,
  placeholder: PropTypes.string,
  extraOptions: PropTypes.array,
};

const mapStateToProps = (state, props) => {
  return {
    userTypes: userTypesSelector(state, props),
    userRole: authoritySelector(state, props),
  };
};

const mappedUserTypeField = connect(mapStateToProps)(UserTypeField);

export default mappedUserTypeField;
