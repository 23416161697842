const INVITE_USER_INITIATE = "INVITE_USER_INITIATE";
const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";

const OPEN_INVITE_MODAL = "OPEN_INVITE_MODAL";
const CLOSE_INVITE_MODAL = "CLOSE_INVITE_MODAL";
const CLOSE_INVITE_SUCCESS_MODAL = "CLOSE_INVITE_SUCCESS_MODAL";

export default {
  INVITE_USER_FAILURE,
  INVITE_USER_SUCCESS,
  INVITE_USER_INITIATE,
  CLOSE_INVITE_MODAL,
  OPEN_INVITE_MODAL,
  CLOSE_INVITE_SUCCESS_MODAL
};
