import React from "react";
import { Card, Button } from "semantic-ui-react";

function ChangeOwner(props) {
  return (
    <Card>
      <Card.Content
        className="dark-blue-background-shade"
        header={
          <React.Fragment>
            <Card.Header>{props.title}</Card.Header>
          </React.Fragment>
        }
      />
      <Card.Content>Current Owner : {props.adminName}</Card.Content>
      {props.isAdmin && (
        <Card.Content extra>
          <Button size="mini" onClick={props.openModal}>
            Change Owner
          </Button>
        </Card.Content>
      )}
    </Card>
  );
}

export default ChangeOwner;
