import React from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Form,
  Message,
  Segment,
  Select,
  Grid,
  Checkbox,
  Confirm,
  Progress,
} from "semantic-ui-react";
import { validate } from "validate.js";
import update from "immutability-helper";
import EditField from "./subcomponents/edit-field";
import { isEmpty } from "lodash";
import Uploader from "@/common/uploader";
import {
  normalValidationConfig,
  normalValidationConfigForSelfRegister,
} from "@/common/validations/userValidation";
import Modal from "./subcomponents/modal";
import { removeEmptyKeys } from "@/common/utils/utils";
import Api from "@/api";
import urls from "@/config/constants";
import {
  CountryField,
  InputField,
  TextareaField,
  StateField,
  PhoneField,
  FileField,
} from "@/common/Form";
import {
  DropdownField,
  GroupTypeField,
  UserTypeField,
  CountryCode,
} from "@/common/Form";
import postalCodes from "postal-codes-js";
class EditProfile extends React.Component {
  Constants = {
    MATURITY_OPTIONS: [
      { key: "Maturity-NONE", value: "NONE", text: "Never" },
      { key: "Maturity-ASAP", value: "0", text: "As soon as possible" },
      { key: "Maturity-DAILY", value: "DAILY", text: "Daily" },
      { key: "Maturity-WEEKLY", value: "WEEKLY", text: "Weekly" },
      { key: "Maturity-MONTHLY", value: "MONTHLY", text: "Monthly" },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      profile: { privacy: { isFirstNamePublic: 1, isLastNamePublic: 1 } },
      validationMsg: {},
      apiError: null,
      isActive: false,
      phonelocal: false,
      phoneform: true,
      codeform: true,
      code: "",
      local: false,
      localcountry: "US",
      localpostal: "",
      localresult: true,
      data: {
        file: null
      },
      uploadProgress: 0,
      fileuuid: "",
      isUploading: false,
    };
  }

  componentDidMount() {
    this.loadProfileData();
    this.checkTwoFactorEnable();
  }
  onBlur = (e) => {
    const { validationMsg } = this.state;
    const { profile } = this.state;
    const { restrictedEmailDomains } = this.props;
    let value = e.target.value ? { [e.target.name]: e.target.value } : [];
    if (e.target.name === "email") {
      value.restrictedDomains =
        restrictedEmailDomains && restrictedEmailDomains
          ? restrictedEmailDomains
          : "";
    }
    if (e.target.name === "phone" || e.target.name === "cellPhone") {
      value.countryCode = profile.countryCode;
    }
    if (e.target.name === "postalCode") {
      return;
    }

    let obj = { [e.target.name]: normalValidationConfig[e.target.name] };
    const singleFieldValidation = validate(value, obj);
    if (isEmpty(singleFieldValidation)) {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: singleFieldValidation[e.target.name] },
        }),
      });
    }
  };
  checkTwoFactorEnable = () => {
    // check if 2fa already enabled for logged in user
    Api.AxiosInstance.getInstance()
      .get("/twoFactorAuthKey/checkTwoFactorEnable")
      .then((resp) => {
        this.setState({ isActive: resp.data });
      })
      .catch((error) => {
        // this.setState({ apiError: error.response.data.message });
      });
  };

  loadProfileData = () => {
    const { profile } = this.state;
    Api.AxiosInstance.getInstance()
      .get(urls.App.URL_PROFILE_USERDATA)
      .then((resp) => {
        this.setState({ profile: resp.data });
        this.state.localcountry = resp.data.countryCode;
        this.state.localpostal = resp.data.postalCode;
      })
      .catch((error) => {
        this.setState({ apiError: error.response.data.message });
      });
  };
  onkeyup = (e) => {
    // this will be enable after discussion with the team.
    // this.checkEmailAvailability();
  };
  hideDeleteWarning = (cb) => {
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  checkEmailAvailability = () => {
    const { email } = this.state.profile;
    let params = {
      params: {
        email,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(urls.App.URL_CHECK_EMAIL, params)
      .then((resp) => {
        this.setState({
          // formDisabled: false,
          emailError: null,
          msg: null,
          apiError: null,
        });
      })
      .catch(() => {
        this.setState({
          validationMsg: { email: "Email address already exists." },
          emailError: "Email address already exists.",
        });
      });
  };
  checkPostalCode = (e) => {
    var res = postalCodes.validate(
      this.state.localcountry,
      this.state.localpostal
    );
    if (this.state.localpostal == "") {
      res = true;
    }
    this.state.localresult = res;
    const { validationMsg } = this.state;
    let value = res ? { postalCode: res } : [];
    value.localresult = this.state.localresult;
    let obj = {
      postalCode: normalValidationConfigForSelfRegister["postalCode"],
    };
    const singleFieldValidation = validate(value, obj);
    if (isEmpty(singleFieldValidation)) {
      this.setState({
        validationMsg: update(validationMsg, {
          postalCode: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          postalCode: { $set: singleFieldValidation["postalCode"] },
        }),
      });
    }
  };
  onGenericFieldChange = (event, data) => {
    const { profile } = this.state;
    if (data.name == "countryCode") {
      this.state.localcountry = data.value;
      this.setState({
        profile: update(profile, { [data.name]: { $set: data.value } }),
      });
      this.checkPostalCode(event);
    } else if (data.name == "postalCode") {
      this.state.localpostal = data.value;
      this.setState({
        profile: update(profile, { [data.name]: { $set: data.value } }),
      });
      this.checkPostalCode(event);
    } else {
      this.setState({
        profile: update(profile, { [data.name]: { $set: data.value } }),
      });
    }
  };
  formatCountryCode(value) {
    this.state.code = value;
    if (this.state.codeform) {
      var phone = String(this.state.profile.phone);
      this.state.code = phone.substring(0, phone.indexOf("("));
      return this.state.code;
    } else {
      this.state.code = value;
      return this.state.code;
    }
  }
  countryCodeChange = (event, data) => {
    this.setState({ phoneform: false });
    this.setState({ codeform: false });
    this.state.code = data.value;
  };
  isNumeric(value) {
    return /^\d+$/.test(value);
  }
  formatphone(value) {
    var phone = String(value);
    if (this.state.phoneform) {
      var plusvalue = "";
      if (phone[0] == "+") {
        this.state.code = phone.substring(0, phone.indexOf("("));
        this.state.phone = phone.substring(phone.indexOf("("));
        for (var i = phone.indexOf("("); i < phone.length; i++) {
          if (this.isNumeric(phone.charAt(i))) {
            plusvalue = plusvalue + phone.charAt(i);
          }
        }
        phone = plusvalue;
        phone =
          "(" +
          phone.substring(0, 3) +
          ") " +
          phone.substring(3, 6) +
          " " +
          phone.substring(6, phone.length);
        value = phone;
        return phone;
      } else if (phone[0] == "(" && phone[4] == ")" && phone[5] == " ") {
        return value;
      } else {
        phone =
          "(" +
          phone.substring(0, 3) +
          ") " +
          phone.substring(3, 6) +
          " " +
          phone.substring(6, phone.length);
        value = phone;
        return phone;
      }
    } else if (phone[0] == "+") {
      return phone.substring(phone.indexOf("("));
    } else {
      return phone;
    }
  }

  onPhoneChange = (event, data) => {
    let cond = event.target.value;
    const { profile } = this.state;
    this.setState({ phoneform: false });
    this.setState({ codeform: false });
    var phone = "";
    for (var i = 0; i < cond.length; i++) {
      if (this.isNumeric(cond.charAt(i))) {
        phone = phone + cond.charAt(i);
      }
    }
    if (phone.length < 3) {
      this.setState({
        profile: update(profile, { [data.name]: { $set: phone } }),
      });
    } else if (
      phone.length == 3 &&
      cond.charAt(cond.length - 1) == ")" &&
      cond.length == 5
    ) {
      this.setState({
        profile: update(profile, {
          [data.name]: { $set: phone.substring(0, 2) },
        }),
      });
    } else if (phone.length == 3) {
      this.setState({
        profile: update(profile, {
          [data.name]: { $set: "(" + phone.substring(0, 3) + ") " },
        }),
      });
    } else if (phone.length > 3 && phone.length < 6) {
      this.setState({
        profile: update(profile, {
          [data.name]: {
            $set: "(" + phone.substring(0, 3) + ") " + phone.substring(3),
          },
        }),
      });
    } else if (phone.length == 6 && this.state.local == false) {
      this.setState({
        profile: update(profile, {
          [data.name]: {
            $set:
              "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " ",
          },
        }),
      });
      this.setState({ local: true });
    } else if (phone.length == 6 && this.state.local == true) {
      this.setState({
        profile: update(profile, {
          [data.name]: {
            $set: "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6),
          },
        }),
      });
      this.setState({ local: false });
    } else if (phone.length >= 6 && phone.length < 12) {
      this.setState({
        profile: update(profile, {
          [data.name]: {
            $set:
              "(" +
              phone.substring(0, 3) +
              ") " +
              phone.substring(3, 6) +
              " " +
              phone.substring(6),
          },
        }),
      });
    } else if (phone.length >= 12) {
      this.setState({
        profile: update(profile, {
          [data.name]: {
            $set:
              "(" +
              phone.substring(0, 3) +
              ") " +
              phone.substring(3, 6) +
              " " +
              phone.substring(6, 11),
          },
        }),
      });
    }
  };
  getPublicKey = (field) => {
    return `is${validate.capitalize(field)}Public`;
  };

  togglePublicField = (name, value) => {
    const boolToInt = { true: 1, false: 0 };
    const { profile } = this.state;
    // const key = this.getPublicKey(name);
    this.setState({
      profile: update(profile, {
        privacy: { [name]: { $set: boolToInt[value] } },
      }),
    });
  };
  onByteChange = (event, eventData) => {
    const { profile } = this.state;
    let value = eventData.value ? 1 : null;

    this.setState({
      profile: update(profile, { [eventData.name]: { $set: value } }),
    });
  };
  validateForm = (event) => {
    const { profile, isUploading } = this.state;
    
    if(isUploading){
      this.setState({
        validationMsg: "Profile image is uploading. Please wait for it to upload completely.",
        msg: "Profile image is uploading. Please wait for it to upload completely.",
        msgSpanColor: "red",
      });
    }
    else{
      // FIXME : always null since postalCode is not on state
      profile.postalCode = this.state.postalCode;
      const { restrictedEmailDomains } = this.props;
      let cleanedProfile = removeEmptyKeys(profile);
      cleanedProfile.restrictedDomains = restrictedEmailDomains;
      delete cleanedProfile.cellPhone;
      const validationResult = validate(cleanedProfile, normalValidationConfig);
      // FIXME : I find an issue with postal code validation require proper fix
      profile.postalCode = this.state.localpostal;
      if (!validationResult) {
        this.saveProfile();
      } else {
        this.setState({
          validationMsg: validationResult,
          msg: "Validation failed, please fix the errors before submitting",
          msgSpanColor: "red",
        });
      }
    }
  };

  saveProfile = () => {
    const { profile, fileuuid } = this.state;
    let cleanedPofile = removeEmptyKeys(profile);
    cleanedPofile.phone =
      this.state.code +
      cleanedPofile.phone.substring(cleanedPofile.phone.indexOf("("));

    if (fileuuid) {
      cleanedPofile.file = fileuuid;
    }
    
    Api.AxiosInstance.getInstance()
      .post(urls.App.URL_PROFILE_EDITDATA, cleanedPofile)
      .then((resp) => {
        this.setState(
          {
            validationMsg: {},
            msg: "User Information updated, Successfully",
            msgSpanColor: "green",
          },
          () => {
            this.changeLocation();
            this.props.reloadUserObject();
          },
        );
      })
      .catch((error) => {
        this.setState({
          apiError: error.response.data.message,
          msgSpanColor: "red",
          msg: error.response.data.message,
        });
      });
  };
  handleClick = () => {
    this.setState((prevState) => ({ active: !prevState.active }));
    this.setState({
      show2FAPopup: true,
    });
  };
  onModalClose = () => {
    this.setState({
      show2FAPopup: false,
    });
  };
  changeLocation = () => {
    // basecamp todo: profile picture not loading
    window.location.reload(); 
    // this.props.history.push("/profile/");
  };
  setIsActive = () => {
    this.setState({ isActive: true });
  };
  handleDownload = () => {
    const { profile } = this.state;
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("key").value], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${profile.username}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  remove = () => {
    let { profile } = this.state;
    let params = {
      params: {
        id: profile.id,
      },
    };
    Api.AxiosInstance.getInstance()
      .delete("/profile/delete", params)
      .then((resp) => {
        this.props.logout();
        // logout redirect automatically
      })
      .catch((error) => {
        this.setState({
          apiError: error.response.data.message,
          msgSpanColor: "red",
          msg: error.response.data.message,
        });
      });
  };

  onFileChange = (eventData) => {
    if (!eventData) return;
    if (eventData.isUploading) {
      const { file, isUploading } = eventData;
      let { data } = this.state;
      if (file.name && file.size) {
        data = update(data, { file: { $set: file } });
        this.setState({ isUploading, data });
      }
    } else if (typeof eventData.uploadProgress !== "undefined") {
      const { uploadProgress } = eventData;
      const isUploading = uploadProgress !== 100;
      this.setState({ isUploading, uploadProgress });
    } else if (typeof eventData.fileuuid !== "undefined") {
      const { fileuuid } = eventData;
      this.setState({ fileuuid });
    } else if (typeof eventData.errorMsg !== "undefined") {
      const { errorMsg } = eventData;
      this.setState({ errorMsg, showError: true, isUploading: false });
    }
  }

  render() {
    const { apiError, profile, validationMsg, msg, msgSpanColor, emailError, uploadProgress, data } =
      this.state;
    const { MATURITY_OPTIONS } = this.Constants;
    const privacy = profile ? profile.privacy : {};
    const { file } = data;
    return (
      <Segment className="base-segment-wrapper">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={3} computer={5} largeScreen={6}>
              <div className="header">
                <div className="side-marker" />
                Update My Information
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={13} computer={11} largeScreen={10}>
              <div className="control-action-wrapper">
                {!isEmpty(profile) && profile.key ? (
                  <>
                    {/* Two factor authentication refactor */}
                    {/* <Button
                      className="button-basic"
                      onClick={this.handleDownload}
                    >
                      Download Two Factor Authentication Key
                    </Button>
                    <input hidden id="key" value={profile.key} /> */}
                  </>
                ) : (
                  <></>
                  // <Button
                  //   className="button-basic"
                  //   style={{ float: "right" }}
                  //   toggle
                  //   active={!this.state.isActive}
                  //   onClick={this.handleClick}
                  //   disabled={this.state.isActive}
                  //   title={
                  //     this.state.isActive
                  //       ? "Two Factor Enabled"
                  //       : "Click to enable Two Factor Authentication"
                  //   }
                  // >
                  //   Enable Two Factor Authentication
                  // </Button>
                )}{" "}
                <button
                  title="Deleting your account will result in removing all your personal data from our system"
                  className="button-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      deleteWarning: true,
                    });
                  }}
                >
                  Delete My Account
                </button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row className="p-0 m-0">
            <Grid.Column mobile={16} tablet={16} computer={16}>
              Select particular checkbox to enable or disable public information
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Form
                error={apiError}
                onSubmit={this.validateForm}
                loading={!profile}
              >
                <Message error header="Error" content={apiError} />
                {profile && (
                  <React.Fragment>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                          <EditField
                            isPublic={privacy.isFirstNamePublic}
                            publicKey="isFirstNamePublic"
                            onMakePublic={this.togglePublicField}
                            disabled
                          >
                            <InputField
                              label="First Name"
                              errorMsg={validationMsg.firstName}
                              isError={validationMsg.firstName}
                              name="firstName"
                              onChange={this.onGenericFieldChange}
                              value={profile.firstName}
                              isRequired
                              onBlur={this.onBlur}
                            />
                          </EditField>
                          <EditField
                            isPublic={privacy.isMiddleInitialPublic}
                            publicKey="isMiddleInitialPublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <InputField
                              label="Middle Initial"
                              errorMsg={validationMsg.middleInitial}
                              isError={validationMsg.middleInitial}
                              name="middleInitial"
                              onChange={this.onGenericFieldChange}
                              value={profile.middleInitial}
                              // width="3"
                              maxlength="1"
                              onBlur={this.onBlur}
                            />
                          </EditField>
                          <EditField
                            isPublic={privacy.isLastNamePublic}
                            publicKey="isLastNamePublic"
                            onMakePublic={this.togglePublicField}
                            disabled
                          >
                            <InputField
                              label="Last Name"
                              errorMsg={validationMsg.lastName}
                              isError={validationMsg.lastName}
                              name="lastName"
                              onChange={this.onGenericFieldChange}
                              value={profile.lastName}
                              isRequired
                              onBlur={this.onBlur}
                            />
                          </EditField>
                          <EditField
                            isPublic={privacy.isTitlePublic}
                            publicKey="isTitlePublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <InputField
                              label="Title"
                              errorMsg={validationMsg.title}
                              isError={validationMsg.title}
                              name="title"
                              onChange={this.onGenericFieldChange}
                              value={profile.title}
                              // isRequired
                              onBlur={this.onBlur}
                            />
                          </EditField>
                          <EditField
                            isPublic={privacy.isCompanyPublic}
                            publicKey="isCompanyPublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <InputField
                              label="Company"
                              errorMsg={validationMsg.company}
                              isError={validationMsg.company}
                              name="company"
                              onChange={this.onGenericFieldChange}
                              value={profile.company}
                              onBlur={this.onBlur}
                              isRequired
                            />
                          </EditField>
                          <EditField
                            isPublic={privacy.isAddressPublic}
                            publicKey="isAddressPublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <InputField
                              label="Address"
                              errorMsg={validationMsg.address}
                              isError={validationMsg.address}
                              name="address"
                              onChange={this.onGenericFieldChange}
                              value={profile.address}
                              onBlur={this.onBlur}
                              isRequired
                            />
                          </EditField>
                          <EditField
                            isPublic={privacy.isCityPublic}
                            publicKey="isCityPublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <InputField
                              label="City"
                              errorMsg={validationMsg.city}
                              isError={validationMsg.city}
                              name="city"
                              onChange={this.onGenericFieldChange}
                              value={profile.city}
                              onBlur={this.onBlur}
                              isRequired
                            />
                          </EditField>
                          <EditField
                            isPublic={privacy.isCountryPublic}
                            publicKey="isCountryPublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <CountryField
                              label="Country"
                              errorMsg={validationMsg.countryCode}
                              isError={validationMsg.countryCode}
                              name="countryCode"
                              onChange={this.onGenericFieldChange}
                              value={profile.countryCode}
                              onBlur={this.onBlur}
                              isRequired
                            />
                          </EditField>
                          <EditField
                            isPublic={privacy.isStatePublic}
                            publicKey="isStatePublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <StateField
                              label="State"
                              errorMsg={validationMsg.stateCode}
                              isError={validationMsg.stateCode}
                              name="stateCode"
                              onChange={this.onGenericFieldChange}
                              value={profile.stateCode}
                              country={profile.countryCode}
                              onBlur={this.onBlur}
                              isRequired
                            />
                          </EditField>
                          <EditField
                            isPublic={privacy.isPostalCodePublic}
                            publicKey="isPostalCodePublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <InputField
                              label="Postal Code"
                              errorMsg={validationMsg.postalCode}
                              isError={validationMsg.postalCode}
                              name="postalCode"
                              onChange={this.onGenericFieldChange}
                              value={profile.postalCode}
                              onBlur={this.onBlur}
                              // width="5"
                              maxlength="10"
                            />
                          </EditField>

                          <EditField
                            isPublic={privacy.isPhonePublic}
                            publicKey="isPhonePublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <Grid.Row>
                              <Grid.Column mobile={16} tablet={16} computer={8}>
                                <Grid>
                                  <Grid.Row>
                                    <Grid.Column
                                      mobile={16}
                                      tablet={5}
                                      computer={5}
                                      className="col-country-code"
                                    >
                                      <CountryCode
                                        label="Mobile Phone"
                                        errorMsg={validationMsg.code}
                                        isError={!!validationMsg.code}
                                        name="code"
                                        onChange={this.countryCodeChange}
                                        value={this.formatCountryCode(
                                          this.state.code
                                        )}
                                        width={16}
                                        isRequired
                                      />{" "}
                                    </Grid.Column>
                                    <Grid.Column
                                      mobile={16}
                                      tablet={11}
                                      computer={11}
                                      className="col-mobile-phone"
                                    >
                                      <PhoneField
                                        //label="Mobile Phone"
                                        errorMsg={validationMsg.phone}
                                        isError={validationMsg.phone}
                                        name="phone"
                                        onChange={this.onPhoneChange}
                                        value={this.formatphone(profile.phone)}
                                        width={16}
                                        onBlur={this.onBlur}
                                        country={profile.countryCode}
                                        isRequired
                                      />
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                            </Grid.Row>
                          </EditField>

                          {/* <EditField
                        isPublic={privacy.isCellphonePublic}
                        publicKey="isCellphonePublic"
                        onMakePublic={this.togglePublicField}
                      >
                        <PhoneField
                          label="Mobile Phone"
                          errorMsg={validationMsg.cellPhone}
                          isError={validationMsg.cellPhone}
                          name="cellPhone"
                          onBlur={this.onBlur}
                          onChange={this.onGenericFieldChange}
                          value={profile.cellPhone}
                          country={profile.countryCode}
                        />
                      </EditField> */}
                          <EditField
                            isPublic={privacy.isEmailPublic}
                            publicKey="isEmailPublic"
                            onMakePublic={this.togglePublicField}
                          >
                            <InputField
                              label="Email"
                              errorMsg={validationMsg.email || emailError}
                              isError={validationMsg.email || emailError}
                              name="email"
                              onChange={this.onGenericFieldChange}
                              value={profile.email}
                              onBlur={this.onBlur}
                              onKeyup={this.onkeyup}
                              disabled
                              isRequired
                              placeholder="You must register with a corporate email address"
                            />
                          </EditField>
                          <EditField
                            isPublic={true}
                            publicKey="isPicturePublic"
                            onMakePublic={this.togglePublicField}
                            disabled
                          >
                            <FileField
                              errorMsg={validationMsg.file}
                              isError={validationMsg.file}
                              accept="image/jpeg"
                              type="image/jpeg"
                              label="Upload Profile Picture (*.jpeg/jpg)"
                              name="file"
                              files={file ? [file] : []}
                              onChange={Uploader(this.onFileChange)}
                              multipleSelection={false}
                              // isRequired
                            />
                            {uploadProgress.isUploading && (
                              <Progress percent={uploadProgress.uploadProgress} indicating />
                            )}
                          </EditField>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column
                                mobile={16}
                                tablet={16}
                                computer={16}
                              >
                                <TextareaField
                                  label="Project Details (maximum 250 characters allowed)"
                                  errorMsg={validationMsg.projectDetails}
                                  isError={validationMsg.projectDetails}
                                  name="projectDetails"
                                  onChange={this.onGenericFieldChange}
                                  value={profile.projectDetails}
                                  rows="5"
                                  // isRequired
                                  maxLength={250}
                                  // width="9"
                                />

                                <Form.Field required={true}>
                                  <label>
                                    Item(s) Update Notification Frequency
                                  </label>
                                  <Select
                                    options={MATURITY_OPTIONS}
                                    value={profile.updateNotificationFrequency || MATURITY_OPTIONS[1].value}
                                    onChange={(event, data) =>
                                      this.onGenericFieldChange(event, {
                                        name: "updateNotificationFrequency",
                                        value: data.value,
                                      })
                                    }
                                  />
                                </Form.Field>

                                {/* <Form.Field width="8">
                <Checkbox name="makeFriendsPublic" label="Make Friends Public" checked={ profile.makeFriendsPublic } onChange={ this.onGenericFieldChange } />
              </Form.Field>
              <Form.Field >
                <Checkbox name="showProjects" label="Projects" checked={ profile.showProjects } onChange={ this.onGenericFieldChange } />
              </Form.Field> */}
                                {/* <Form.Field required error={validationMsg.gdprCheck}>
                      <Checkbox
                        name="gdprCheck"
                        errorMsg={validationMsg.gdprCheck}
                        label="I accept all the terms and conditions of the GDPR Policy"
                        checked={profile.gdprCheck}
                        isRequired
                        onChange={(event, data) =>
                          this.onByteChange(event, {
                            name: data.name,
                            value: data.checked,
                          })
                        }
                      />
                      <ErrorMessage
                        isError={validationMsg.gdprCheck}
                        errorMsg={validationMsg.gdprCheck}
                      /> 
                      </Form.Field> */}
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <Grid>
                      <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                          <button
                            className="button-primary m--no-mar-left"
                            type="submit"
                          >
                            Save
                          </button>
                          {/* <Link to="/profile"> */}
                            <button className="button-basic" onClick={() => {this.props.history.goBack()}}>Cancel</button>
                          {/* </Link> */}

                          {msg && (
                            <span
                              style={{ color: msgSpanColor, marginLeft: "5px" }}
                            >
                              {msg}
                            </span>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </React.Fragment>
                )}
              </Form>
              <Confirm
                open={this.state.deleteWarning}
                onCancel={this.hideDeleteWarning}
                // cancelButton="N"
                confirmButton="YES, DELETE MY ACCOUNT"
                onConfirm={() => this.hideDeleteWarning(this.remove)}
                content="Your information will be deleted permanently and you will lose access. Are you sure you want to delete your account?"
              />
              <Modal
                onActivateTwoFactor={this.activateTwoFactor}
                onIsActive={this.setIsActive}
                show2FAPopup={this.state.show2FAPopup}
                onModalClose={this.onModalClose}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

EditProfile.propTypes = {};

export default withRouter(EditProfile);
