import React from "react";
import Api from "../../../api";
import Constants from "../../../config/constants";
import Moment from "react-moment";
import ProjectForm from "../components/projectform";

class ProjectEdit extends React.Component {
  constructor(props) {
    super(props);
    let id = props.match.params.id;
    this.state = {
      redirectToViewProject: false,
      projectId: id,
      loading: false
    };
  }

  componentDidMount() {
    this.loadProject();
  }

  loadProject = () => {
    this.setState({ loading: true });
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_PROJECT_DETAIL + "?id=" + this.state.projectId)
      .then(resp => {
        let data = resp.data;
        this.setState({
          id: data.id,
          name: data.name,
          description: data.description,
          adminId: data.adminId,
          adminName: data.adminName,
          createdBy: data.createdBy,
          creationDate: data.creationDate,
          modifiedBy: data.modifiedBy,
          modificationDate: data.modificationDate,
          loading: false,
          showUsersListing: false
        });
      });
  };

  deleteProject = projectId => {
    return new Promise((resolve, reject) => {
      Api.AxiosInstance.getInstance()
        .post(Constants.App.URL_PROJECT_DELETE + "?id=" + projectId)
        .then(resp => {
          resolve();
        })
        .catch(err => {
          reject();
        });
    });
  };

  componentDidUpdate() {}

  render() {
    const project = {
      projectId: this.state.projectId,
      name: this.state.name,
      description: this.state.description,
      adminId: this.state.adminId,
      adminName: this.state.adminName,
      createdBy: this.state.createdBy,
      creationDate: this.state.creationDate,
      modifiedBy: this.state.modifiedBy,
      modificationDate: this.state.modificationDate
    };
    return (
      !this.state.loading && (
        <ProjectForm data={project} deleteCallback={this.deleteProject} />
      )
    );
  }
}

export default ProjectEdit;
