import React from "react";
import { Input,
  Button,
  Container,
  Label,
  Dropdown,
  Tab,
  TextArea,
  Form,
  Modal,
  Image,
  Header,
  Segment,
  Dimmer,
  Loader,
  Icon,
  Select,
  Pagination,
  Confirm} from 'semantic-ui-react'
import { FileField, InputField, TextareaField, DateField, DropdownField } from "@/common/Form";
import {Redirect} from 'react-router-dom'
import Api from "@/api"
import Constants from "@/config/constants"
import ProductsContext from "../_context"
import Details from "../components/_product-details"

class AddProducts extends React.Component {
  constantValues = {
    statusOptions: [
      {text: 'Active', key: 'ACTIVE', value: 'ACTIVE'},
      {text: 'Disabled', key: 'DISABLED', value: 'DISABLED'}
    ],
    productOptions: []
  }

  constructor(props) {
    super(props);
    console.log('props', props);
    this.state = {
      isSaving: false,
      name: '',
      description: '',
      status: 'ACTIVE',
      validationMsg: {},
      redirectTo: false,
      popupWarning: false,
    }

    this.constantValues.productOptions = props.productIds;
  }

  setLoading = (bool) => {
    if(this.props.loading) this.props.loading(bool);
  }

  onChange = (event, data) => {
    const {name, value} = data;
    if(typeof(this.state[name]) != 'undefined') {
      this.setState({[name]: value});
    }

  }

  redirectToParent = (id) => {
    const {selectedProduct, baseUrl} = this.props.data;
    const productId = id ? id : selectedProduct;
    this.setState({redirectTo: baseUrl + '/' + productId})
  }

  showpopupWarning = () => {
    if ( !this.props.data.selectedProduct ) {
      return;
    }
    let validationMsg = {};
    if(!this.state.name) {
      validationMsg['name'] = ('Name cannot be empty!');
    }
    if(!this.state.status) {
      validationMsg['status'] = ('Status cannot be empty!');
    }

    if(validationMsg && (validationMsg['name'] || validationMsg['status'])) {
      this.setState({validationMsg})
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  saveDetails = () => {
    if ( !this.props.data.selectedProduct ) {
      return;
    }
    let validationMsg = {};
    if(!this.state.name) {
      validationMsg['name'] = ('Name cannot be empty!');
    }
    if(!this.state.status) {
      validationMsg['status'] = ('Status cannot be empty!');
    }

    if(validationMsg && (validationMsg['name'] || validationMsg['status'])) {
      this.setState({validationMsg})
      return;
    }

    var postObj = {
      actiontype: 'ADD_PRODUCT',
      parent_id: this.props.data.selectedProduct,
      name: this.state.name,
      description: this.state.description,
      status: this.state.status,
    }

    const params = {/*params:{id: this.props.data.selectedProduct}*/};
    let url = Constants.App.URL_PRODUCTS_DETAILS;// + "?id=" + this.props.data.selectedProduct;
    this.props.dispatch({type: 'loading-list'})

    const instance = Api.AxiosInstance.getInstance();
    instance.post(url, postObj, params).then((resp) => {
      this.props.dispatch({type: 'reload-products'})
      this.redirectToParent()
    }).catch((error) => {
      this.props.dispatch({type: 'update-list', details: ''})
      this.setState({showPopupWarning: true,
        msg: error.response.data.message})
    })
  }

  render() {
    return (
      <Container>
        <Form>
          <div className="empty-height"></div>
          <Details
            name={ this.state.name }
            description={ this.state.description }
            status={ this.state.status }
            onChange={ this.onChange }
            validationMsg={ this.state.validationMsg }
            statusOptions={ this.constantValues.statusOptions }
            productsDropDownMap={ this.props.data.productsDropDownMap }
            parentId={ this.props.data.selectedProduct }
            parentDropDownDisabled={ true }
          />
          <div className="row">
            <Button type="submit" disabled={this.props.data.isListLoading} onClick={this.showpopupWarning}>Save</Button>
            <Button type="reset"
              disabled={this.props.data.isListLoading}
              onClick={(e) => {
                e.preventDefault();
                this.redirectToParent()
              }}>Cancel</Button>
            {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
          </div>
          <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.saveDetails)}
          content="Are you sure you want to add new product?"
        />
        <Confirm
                    open={this.state.showPopupWarning}
                    onCancel={() => this.setState({showPopupWarning: false})}
                    onConfirm={() => this.setState({showPopupWarning: false})}
                    content={this.state.msg}
                    size = "mini"
              />
        </Form>
      </Container>
    )
  }
}

export default ProductsContext.Consumer(AddProducts);
