import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Confirm,
  Icon,
  Item,
  Label,
  List,
  Modal,
} from 'semantic-ui-react';
import Api from "../../../../api";
import urls from '../../../../config/constants';
import ImageIcon from "../../../../assets/icon-image.png";


class Friend extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMsg: null,
      projects: [],
      showConfirmDialog: false,
      showError: false,
    };
  }

  componentDidMount() {
    this.fetchPublicProfile();
  }

  fetchPublicProfile = () => {
    const { data } = this.props;
    Api.AxiosInstance.getInstance()
    .get( `${urls.App.URL_PROFILE_USERDATA}?id=${data.id}` )
    .then(resp => {
      this.setState({ projects: resp.data.projects || [] });
    }).catch(err => {
      console.error(err);
    });
  };

  toggleConfirmation = (val) => {
    this.setState({ showConfirmDialog: val });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  deleteFriend = () => {
    const { deleteApi, data, refreshFunction } = this.props;
    this.toggleConfirmation(false);

    Api.AxiosInstance.getInstance()
    .delete(deleteApi + '?id=' + data.id)
    .then(resp => {
      if (refreshFunction instanceof Function) refreshFunction();
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while deleting the friend", showError: true })
    });
  };

  acceptFriend = () => {
    const { acceptApi, data, refreshFunction } = this.props;

    Api.AxiosInstance.getInstance()
    .post(acceptApi + '?id=' + data.id)
    .then(resp => {
      if (refreshFunction instanceof Function) refreshFunction();
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while accepting the request.", showError: true })
    });
  };

  render() {
    const { acceptApi, deleteApi, data } = this.props;
    const { firstName, lastName, username, status } = data;
    const { errorMsg, projects, showConfirmDialog, showError } = this.state;

    return (
      <React.Fragment>
        <Item>
           <img className="image-icon" src={ImageIcon} alt="Image-Icon"/>
          <Item.Content>
            <Item.Header as='a' onClick={ this.fetchPublicProfile }>
             <strong className="items-heading">

              { `${firstName} ${lastName}` }
              {
                username &&
                <i>{ `(${username})`}</i>
              }
              {
                status === "PENDING" &&
                <Label basic color="teal">
                  <Icon name='user outline' /> Pending
                </Label>
              } </strong>
            </Item.Header>
            <Item.Description>
              <List>

                  Projects:

                <List.Content>
                  {
                    projects.map( el =>
                      <List.Item>
                        { el.name }
                      </List.Item>
                    )
                  }
                  {
                    projects.length === 0 &&
                    <List.Item>
                      -- N/A --
                    </List.Item>
                  }
                </List.Content>
              </List>
            </Item.Description>
            <Item.Extra>
              {
                acceptApi &&
                <button className="button-basic" onClick={ this.acceptFriend }>Accept</button>
              }
              {
                deleteApi &&
                <button className="button-danger" onClick={() => this.toggleConfirmation(true) }> Delete </button>
              }
            </Item.Extra>
          </Item.Content>
        </Item>
        <Confirm open={ showConfirmDialog }
          content="Do you want to delete this friend?"
          confirmButton="Delete"
          cancelButton="Cancel"
          onCancel={ () => this.toggleConfirmation(false) }
          onConfirm={ this.deleteFriend }
        />
        <Modal open={ showError }
          onClose={ () => { this.closeErrorPopup(); } }
          centered={ true }
          size="tiny"
          content={ errorMsg }
          actions={[{ key: 'warningDismiss', content: 'OK', primary: true }]}
        >
        </Modal>
      </React.Fragment>
    );
  }
};

Friend.propTypes = {
  /** API URL this component will use to accept a friend request */
  acceptApi: PropTypes.string,
  /** API URL this component will use to delete a friend or friend-request */
  deleteApi: PropTypes.string,
  /** Data of the friend like firstname, lastname, etc. */
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
    state: PropTypes.oneOf(['PENDING', 'CONFIRMED']),
  }),
  /** the function which will reload the list from the server. */
  refreshFunction: PropTypes.func,
};

export default Friend;
