import { CardviewMobile } from "@/common/cardview";
import FileIcon from "@/common/file-icon";
import Tableview from "@/common/tableview";
import UserListing from "@/common/user-selection/user-listing";
import { removeEmptyKeys } from "@/common/utils/utils";
import Api from "@api";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Moment from "react-moment";
import {dateToPacific} from "@/utils/dateTime";
import { Divider, Grid, Loader, Responsive, Segment } from "semantic-ui-react";
import SearchField from "./subcomponents/download-search-fields";

class DownloadReportsListing extends React.Component {
  Constants = {
    TABLE_COLUMNS: Object.freeze([
      { name: "Username", key: "username",showTooltip: true, cb: () => {
        this.sortByHeader("username", "Username");
      }, },
      { name: "Company", key: "company",showTooltip: true, cb: () => {
        this.sortByHeader("company", "Company");
      }, },
      { name: "Product Name", key: "productName",showTooltip: true, cb: () => {
        this.sortByHeader("prdName", "Product Name");
      }, },
      { name: "Item Name", key: "itemName",showTooltip: true, cb: () => {
        this.sortByHeader("itemName", "Item Name");
      }, },
      { name: "Item Type", key: "fileType", class: "center",showTooltip: false, },
      { name: "Downloaded On", key: "downloadDate", isDate: true,showTooltip: true, cb: () => {
        this.sortByHeader("downloadTime", "Downloaded On");
      }, },
      // { name: "Export Control", key: "exportControlled" },
      { name: "License Name", key: "licenseFilename",showTooltip: true, cb: () => {
        this.sortByHeader("licenseFilename", "License Name");
      }, },
      { name: "License Agreed", key: "licenseAgreed",showTooltip: true, cb: () => {
        this.sortByHeader("licenseAgreed", "License Agreed");
      }, },
    ]),
    RESPONSIVE_COLUMNS: Object.freeze([
      { name: "Username", key: "username" },
      { name: "Company", key: "company" },
      { name: "Product Name", key: "productName" },
      { name: "Item Name", key: "itemName" },
      { name: "Item Type", key: "fileType", class: "center" },
      { name: "Downloaded On", key: "downloadDate", isDate: true },
      //  { name: "Export Control", key: "exportControlled" },
      { name: "License Name", key: "licenseFilename" },
      { name: "License Agreed", key: "licenseAgreed" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      data: { content: [] },
      errorMsg: "",
      selectedItems: [],
      showError: false,
      DATE_FORMAT: "YYYY-MM-DD",
      // need to check with pagination:
      page: 0,
      deleteWarning: false,
      showUsersListing: false,
      userId: "",
      username: "",
      exportControlled: "ALL",
      dateRange: "",
      customfromDate: dateToPacific(new Date()),
      toDate: dateToPacific(new Date()),
      itemName: null,
      company: null,
      country: "",
      prepareDownload: false,
      readyDownload: false,
      errorDownload: false,
      isDescSearch: true,
      sortBy: "downloadTime,DESC",
    };
  }

  componentDidMount() {
    this.getDownloadLogs();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.TABLE_COLUMNS = this.Constants.TABLE_COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getDownloadLogs();
    });
   
  };

  getDownloadLogs = () => {
    const {
      itemName,
      company,
      country,
      toDate,
      page,
      username,
      dateRange,
      exportControlled,
    } = this.state;

    // helper function to change dropdown values to date
    let fromDate = this.getFromDateFromSelection();
    // assigning values after deciding which values to send...

    let options = {
      username,
      country,
      dateRange,
      itemName,
      company,
      exportControlled,
      fromDate,
      toDate,
    };

    let body = removeEmptyKeys(options);
    // Username should be sufficient i guess (no need to add userId)
    let params = {
      params: {
        page,
        sort: this.state.sortBy,
      },
    };
    this.getData(
      body,
      params,
      (resp) => {
        this.setState({
          data: this.dataMapper(resp.data),
          errorDownload: false,
          prepareDownload: false,
          readyDownload: false,
        });
      },
      (err) => {
        //Temporary Hack
        this.setState({
          data: { content: [] },
          errorDownload: false,
          prepareDownload: false,
          readyDownload: false,
        });
      }
    );
  };

  getData = (body, params, success, failure) => {
    const { fetchUrl } = this.props;
    Api.AxiosInstance.getInstance()
      .post(fetchUrl, body, params)
      .then(success)
      .catch(failure);
  };

  getFromDateFromSelection = () => {
    const { dateRange, DATE_FORMAT, customfromDate } = this.state;
    if (dateRange === "custom") {
      // this case is the special case in which start date is selected by specifically customer
      return customfromDate;
    }
    // rest of the cases are predefined cases :
    if (dateRange === "today") {
      return dateToPacific(new Date(),"YYYY-MM-DD");
    }
    if (dateRange === "lastWeek") {
      // start of last week : this sunday (0) -7
      return dateToPacific(moment(new Date()).day(-7).format(DATE_FORMAT));
    }
    if (dateRange === "thisWeek") {
      // start of this week from sunday (0)
      return dateToPacific(moment(new Date()).startOf("week").format(DATE_FORMAT));
    }
    if (dateRange === "thisMonth") {
      return dateToPacific(moment(new Date()).startOf("month").format(DATE_FORMAT));
    }
    if (dateRange === "thisYear") {
      return dateToPacific(moment(new Date()).startOf("year").format(DATE_FORMAT));
    }
    if (dateRange === "lastYear") {
      return dateToPacific(moment(new Date())
        .startOf("year")
        .subtract(1, "year")
        .format(DATE_FORMAT));
    }
    if (dateRange === "last6Months") {
      // from the day one, 1st of last 6 month
      return dateToPacific(moment(new Date())
        .startOf("month")
        .subtract(6, "month")
        .format(DATE_FORMAT));
    }
  };
  dataMapper = (data) => {
    const { field } = this.state;
    let transformedData = Object.assign({}, data);
    let content = [];
    // add changes if needed, like item or file types etc
    transformedData.content.map((el) => {
      let row = Object.assign({}, el);

      row.fileType = (
        <React.Fragment>
          <FileIcon
            filename={row.filename ? row.filename : "other"}
            mimetype={null}
          ></FileIcon>
        </React.Fragment>
      );
      row.downloadDate = <>{dateToPacific(row.downloadDate,"MM/DD/YYYY hh:mm a")}</>;
      row.licenseAgreed = row.licenseAgreed ? "Yes" : "NA";
      row.licenseName = row.licenseName ? row.licenseName : "NA";
      //  row.exportControlled = row.exportControlled ? row.exportControlled : "NA";

      content.push(row);
    });
    transformedData.content = content;
    return transformedData;
  };
  onSelectUser = (e) => {
    e.preventDefault();
    this.setState({ showUsersListing: true });
  };
  onSaveUserListing = (user) => {
    user.map((data) =>
      this.setState({
        showUsersListing: false,
        userId: data.id,
        username: data.username,
      })
    );
  };
  onDismissUserListing = () => {
    this.setState({ showUsersListing: false });
  };

  download = (e, id) => {
    e.preventDefault();
    //TODO : add whatever we need to do for downloading csv files...
    const {
      itemName,
      company,
      country,
      toDate,
      page,
      username,
      dateRange,
      exportControlled,
      prepareDownload,
      errorDownload,
      readyDownload,
    } = this.state;

    if (!prepareDownload && !errorDownload && !!readyDownload) {
      window.open(readyDownload);
      return;
    }

    // helper function to change dropdown values to date
    let fromDate = this.getFromDateFromSelection();
    // assigning values after deciding which values to send...

    let options = {
      username,
      country,
      dateRange,
      itemName,
      company,
      exportControlled,
      fromDate,
      toDate,
    };

    let body = removeEmptyKeys(options);
    // Username should be sufficient i guess (no need to add userId)
    let params = {
      params: {
        page,
        download: 1,
        sort: "downloadTime,DESC",
      },
    };
    this.setState({
      prepareDownload: true,
    });
    this.getData(
      body,
      params,
      (resp) => {
        this.setState({
          prepareDownload: false,
          readyDownload: resp.data,
        });
      },
      (err) => {
        this.setState({
          prepareDownload: false,
          errorDownload: "Unable to prepare file for download.",
        });
      }
    );
  };

  onSearchTyping = (event, data) => {
    this.setState({
      username: event.target.value,
    });
  };
  setFields = (event, name) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  onPageChange = (e) => {
    const { urlParams } = this.state;
    this.setState(
      {
        page: e.page,
      },
      this.getDownloadLogs
    );
  };

  onItemsSelection = (event) => {
    this.setState({ selectedItems: event.data });
  };

  onDropDownChange = (name, value) => {
    this.setState({
      [name]: value,
      toDate: dateToPacific(new Date(),"YYYY-MM-DD"),
    });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  clearState = (e) => {
    //Temporary hack: need to do something else
    this.setState(
      {
        userId: null,
        username: "",
        itemName: "",
        company: "",
        exportControlled: "ALL",
        dateRange: null,
        country: null,
        customfromDate: dateToPacific(new Date(),"YYYY-MM-DD"),
        toDate: dateToPacific(new Date(),"YYYY-MM-DD"),
      },
      this.getDownloadLogs
    );
  };
  handleChange = (event, eventData) => {
    this.setState({
      [eventData.name]: eventData.value,
    });
  };

  render() {
    const { title } = this.props;
    const {
      username,
      data,
      company,
      itemName,

      exportControlled,
      dateRange,
      customfromDate,
      toDate,
      country,
      DATE_FORMAT,
      prepareDownload,
      readyDownload,
      errorDownload,
    } = this.state;

    const disableDownloadButton = prepareDownload || errorDownload;
    const { TABLE_COLUMNS, RESPONSIVE_COLUMNS } = this.Constants;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column mobile={10} tablet={10} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" />
                  {title}
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={6} computer={8} largeScreen={8}>
                <div className="control-action-wrapper">
                  <button
                    className="button-basic "
                    title="Currently Unavailable"
                    disabled={disableDownloadButton}
                    onClick={this.download}
                  >
                    {!prepareDownload && !readyDownload && "Download"}
                    {!!prepareDownload && !readyDownload && (
                      <Loader>Loading</Loader>
                    )}
                    {!!prepareDownload && !readyDownload && "Preparing File"}
                    {!prepareDownload &&
                      !!readyDownload &&
                      "File Ready! Download Now!"}
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <SearchField
              // exportControlled={exportControlled}
              clearState={this.clearState}
              getDownloadLogs={this.getDownloadLogs}
              handleChange={this.handleChange}
              toDate={toDate}
              DATE_FORMAT={DATE_FORMAT}
              customfromDate={customfromDate}
              dateRange={dateRange}
              onDropDownChange={this.onDropDownChange}
              company={company}
              country={country}
              itemName={itemName}
              setFields={this.setFields}
              onSelectUser={this.onSelectUser}
              username={username}
              onKeyPress={this.onKeyUp}
            />
            <Divider />
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination">
                    <Tableview
                      columns={TABLE_COLUMNS}
                      data={data}
                      paginate
                      onPaginationChange={this.onPageChange}
                    />
                  </div>
                </Responsive>
                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    data={data}
                    columns={RESPONSIVE_COLUMNS}
                    paginate
                    onPaginationChange={(e) => this.onPageChange(e)}
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <UserListing
          show={this.state.showUsersListing}
          onDismiss={this.onDismissUserListing}
          onSave={this.onSaveUserListing}
          selectionType="single-select"
          title="List of Customers"
        />
      </React.Fragment>
    );
  }
}

DownloadReportsListing.propTypes = {
  /** URL this component will use to fetch latest data */
  fetchUrl: PropTypes.string.isRequired,
  /** Text which will be displayed at the top of the page. */
  title: PropTypes.string.isRequired,
};

export default DownloadReportsListing;
