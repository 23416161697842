import Api from "@/api";
import { InputField, FileField } from "@/common/Form";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { Divider, Form, Grid, Segment, Image, Confirm } from "semantic-ui-react";
import { validate } from "validate.js";
import { normalValidation } from "./../components/validations/system";
import Uploader from "@/common/uploader";
import LocalStore from "@/store/persistence/local-storage";

class SystemSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyValuePairs: [],
      community_feature: false,
      cross_group: false,
      cross_group_friends: false,
      enforce_validation: false,
      nda_enforcement: false,
      nda_auto_reminder: false,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: "",
      msgSpanText: "",
      msgSpanColor: "black",
      itemLoadXHR: false,
      fileuuid: null,
      popupWarning: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ADMIN_SETTINGS)
      .then((resp) => {
        let data = resp.data;
        let content = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].value.length > 0) content[data[i].name] = data[i].value;
        }
        this.setState({
          keyValuePairs: content,
          community_feature:
            content["community_feature"] == "ENABLED" ? true : false,
          cross_group: content["cross_group"] == "ENABLED" ? true : false,
          cross_group_friends:
            content["cross_group_friends"] == "ENABLED" ? true : false,
          enforce_validation:
            content["enforce_validation"] == "ON" ? true : false,
          nda_enforcement: content["nda_enforcement"] == "ON" ? true : false,
          nda_auto_reminder:
            content["nda_auto_reminder"] == "ENABLED" ? true : false,
        });
      });
  };
  showpopupWarning = () => {
    let result;
    result = validate(
      removeEmptyKeys(this.state.keyValuePairs),
      normalValidation
    );
    if (result) {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red",
      });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  onDataChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };
  loadConfigOptions = () => {
    const token = LocalStore.getValue("token");
    this.props.loadApplicationConfig(token);
  };
  save = () => {
    const { restrictedDomains, fileuuid } = this.state;
    let options = {};
    let kpKeys = Object.keys(this.state.keyValuePairs);
    let kpVals = Object.values(this.state.keyValuePairs);
    for (var i = 0; i < kpKeys.length; i++) {
      options[kpKeys[i]] = kpVals[i];
    }
    // options["community_feature"] = document.getElementById("community_feature")
    //   .checked
    //   ? "ENABLED"
    //   : "DISABLED";
    // options["cross_group"] = document.getElementById("cross_group").checked
    //   ? "ENABLED"
    //   : "DISABLED";
    // options["cross_group_friends"] = document.getElementById(
    //   "cross_group_friends"
    // ).checked
    //   ? "ENABLED"
    //   : "DISABLED";
    // options["enforce_validation"] = document.getElementById(
    //   "enforce_validationOn"
    // ).checked
    //   ? "ON"
    //   : "OFF";
    // options["nda_enforcement"] = document.getElementById("nda_enforcementOn")
    //   .checked
    //   ? "ON"
    //   : "OFF";
    // options["nda_auto_reminder"] = document.getElementById(
    //   "nda_auto_reminderEnabled"
    // ).checked
    //   ? "ENABLED"
    //   : "DISABLED";
    options["fileuuid"] = fileuuid;
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_SETTINGS_SAVE, options)
      .then((resp) => {
        let msg = "";
        let color = "black";
        if (resp.data && resp.data == "OK") {
          msg = "Settings updated";
          color = "green";
        } else {
          msg = "Request failed, Please try later.";
          color = "red";
        }
        // fix for loading config file..
        this.setState(
          {
            msgSpanText: msg,
            msgSpanColor: color,
            loading: false,
          },
          () => {
            this.loadConfigOptions();

            this.loadData();
          }
        );
      });
  };

  handleChange = (e) => {
    let checked = e.target.checked;
    if (e.target.value == "OFF" || e.target.value == "DISABLED") {
      checked = false;
    }
    this.setState({
      [e.target.name]: checked,
    });
  };

  handleSelectChange = (e) => {
    let value = e.target.value;
    let kp = this.state.keyValuePairs;
    kp[e.target.name] = value;
    this.setState({
      keyValuePairs: kp,
    });
  };

  handleTextChange = (e) => {
    let value = e.target.value;
    let kp = this.state.keyValuePairs;
    kp[e.target.name] = value;
    if (value.length < 1) delete kp[e.target.name];

    this.setState({
      keyValuePairs: kp,
    });
  };
  validateForm = (event) => {
    let result;
    result = validate(
      removeEmptyKeys(this.state.keyValuePairs),
      normalValidation
    );
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red",
      });
    }
  };

  getFormTextField = (label, name, errorMsg, defaultVal, mandatory,length) => {
    return (
      <InputField
        label={label}
        errorMsg={errorMsg}
        isError={errorMsg}
        name={name}
        id={name}
        value={defaultVal}
        onChange={this.handleTextChange}
        isRequired={mandatory}
        maxlength={length}
      />
    );
  };

  getDuoRadioField = (
    label,
    name,
    errorMsg,
    defaultVal,
    mandatory,
    option1,
    option2
  ) => {
    return (
      <React.Fragment>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <label style={{ fontWeight: "bold" }}>
            {label}{" "}
            {mandatory == true ? <span style={{ color: "red" }}>*</span> : ""}
          </label>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <input
            id={name + option1}
            name={name}
            type="radio"
            value={option1.toUpperCase()}
            checked={defaultVal}
            onChange={this.handleChange}
          />
          &nbsp; &nbsp;
          <span>{option1}</span>
          &nbsp; &nbsp;
          <input
            id={name + option2}
            name={name}
            type="radio"
            value={option2.toUpperCase()}
            checked={!defaultVal}
            onChange={this.handleChange}
          />
          &nbsp; &nbsp;
          <span>{option2}</span>
        </Grid.Column>
      </React.Fragment>
    );
  };

  getCheckboxField = (
    label,
    name,
    errorMsg,
    defaultCheck,
    mandatory,
    title,
    span
  ) => {
    return (
      <React.Fragment>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <label style={{ fontWeight: "bold" }}>
            {label}{" "}
            {mandatory == true ? <span style={{ color: "red" }}>*</span> : ""}
          </label>
          &nbsp;&nbsp;
          <FontAwesomeIcon icon={faQuestionCircle} title={title} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <input
            id={name}
            name={name}
            type="checkbox"
            checked={defaultCheck}
            onChange={this.handleChange}
            style={{ marginTop: "0.5%" }}
          />
          &nbsp;&nbsp;<span>{span}</span>
        </Grid.Column>
      </React.Fragment>
    );
  };
  handleFileChange = (eventData) => {
    const { data } = this.state;
    const { fileuuid } = eventData;
    console.log("file", fileuuid, eventData);
    if (fileuuid) {
      console.log("inside", fileuuid, eventData);
      this.setState({
        fileuuid: fileuuid,
      });
    }
  };

  render() {
    const { validationMsg, restrictedDomains } = this.state;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">General Settings</div>
          <div className="empty-height-small" />

          <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Company Name",
                    "company_name",
                    this.state.validationMsg.company_name,
                    this.state.keyValuePairs["company_name"],
                    true,
                    32
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Email From Name",
                    "email_from_name",
                    this.state.validationMsg.email_from_name,
                    this.state.keyValuePairs["email_from_name"],
                    true,
                    32
                  )}
                </Grid.Column>
                {/* <Grid.Column mobile={16} tablet={16} computer={6}>
                  {this.getFormTextField(
                    "Websilo Site Name",
                    "websilo_site_name",
                    this.state.validationMsg.websilo_site_name,
                    this.state.keyValuePairs["websilo_site_name"],
                    true
                  )}
                  </Grid.Column> */}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Company Website",
                    "company_website_url",
                    this.state.validationMsg.company_website_url,
                    this.state.keyValuePairs["company_website_url"],
                    true,
                    1024
                  )}
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Inactivity Timeout (mins)",
                    "inactivity_timeout",
                    this.state.validationMsg.inactivity_timeout,
                    this.state.keyValuePairs["inactivity_timeout"],
                    true
                  )}
                </Grid.Column>

                {/* <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Websilo Site Url",
                    "websilo_site_url",
                    this.state.validationMsg.websilo_site_url,
                    this.state.keyValuePairs["websilo_site_url"],
                    true
                  )}
                </Grid.Column> */}
              </Grid.Row>
              <Grid.Row>

              <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Restricted Email Domains (comma separated)"
                    errorMsg={this.state.validationMsg.restrictedDomains}
                    isError={!!this.state.validationMsg.restrictedDomains}
                    name="restrictedDomains"
                    onChange={this.handleTextChange}
                    value={this.state.keyValuePairs["restrictedDomains"]}
                    isRequired
                    maxlength="512"
                  />
                </Grid.Column>
               
                {/* <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label style={{ fontWeight: "bold" }}>
                    Approval Flow <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id="approval_flow"
                    name="approval_flow"
                    onChange={this.handleSelectChange}
                    value={this.state.keyValuePairs["approval_flow"]}
                  >
                    <option value="NEED APPROVAL">Require Approval</option>
                    <option value="AUTO APPROVE">Auto Approve</option>
                  </select>
                  </Grid.Column> */}
                   <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Account Renewal Cycle (Days)",
                    "account_renewal_cycle",
                    this.state.validationMsg.account_renewal_cycle,
                    this.state.keyValuePairs["account_renewal_cycle"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Registration Link Timeout (mins)",
                    "registration_link_timeout",
                    this.state.validationMsg.registration_link_timeout,
                    this.state.keyValuePairs["registration_link_timeout"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row>
         
              {/* below function calls auto add grid columns */}
              {/* <Grid.Row>
                {this.getCheckboxField(
                  "Community Feature",
                  "community_feature",
                  this.state.validationMsg.community_feature,
                  this.state.community_feature,
                  true,
                  "This feature allows users to view other users in their group. Selecting this will enable this feature for all groups in the system.",
                  "Enable this feature"
                )}
                {this.getCheckboxField(
                  "Allow Cross Group",
                  "cross_group",
                  this.state.validationMsg.cross_group,
                  this.state.cross_group,
                  true,
                  "This feature allows users to view public profiles of users from other groups. Selecting this will enable this feature for all users in the system.",
                  "Enable this feature"
                )}
              </Grid.Row>
              <Grid.Row>
                {this.getCheckboxField(
                  "Allow Cross Group Friends",
                  "cross_group_friends",
                  this.state.validationMsg.cross_group_friends,
                  this.state.cross_group_friends,
                  true,
                  "This feature allows users to make friends from other groups too. Selecting this will enable this feature for all users in the system.",
                  "Enable this feature"
                )}
                {this.getDuoRadioField(
                  "Enforce Validation",
                  "enforce_validation",
                  this.state.validationMsg.enforce_validation,
                  this.state.enforce_validation,
                  true,
                  "On",
                  "Off"
                )}
              </Grid.Row> */}
              {/* <Grid.Row> */}
               
                {/* <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "File Upload Location",
                    "upload_location",
                    this.state.validationMsg.upload_location,
                    this.state.keyValuePairs["upload_location"],
                    true
                  )}
                </Grid.Column> */}
              {/* </Grid.Row>
              <Grid.Row> */}
                {/* <Grid.Column mobile={16} tablet={16} computer={6}>
                  <label style={{ fontWeight: "bold" }}>
                    Application Mode <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id="application_mode"
                    name="application_mode"
                    value={this.state.keyValuePairs["application_mode"]}
                    onChange={this.handleSelectChange}
                  >
                    <option value="RELEASE">Release</option>
                    <option value="DEVELOPMENT">Development</option>
                  </select>
                </Grid.Column> */}
                {/* <Grid.Column mobile={16} tablet={16} computer={4}>
                  {this.getFormTextField(
                    "Password Link Expiry (Hrs)",
                    "password_link_expr_time",
                    this.state.validationMsg.password_link_expr_time,
                    this.state.keyValuePairs["password_link_expr_time"],
                    true
                  )}
                  </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  {this.getFormTextField(
                    "Name Of VP of Sales",
                    "vp_sales_name",
                    this.state.validationMsg.vp_sales_name,
                    this.state.keyValuePairs["vp_sales_name"],
                    true
                  )}
                </Grid.Column> */}
              {/* </Grid.Row> */}
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className="header">
                    <div className="side-marker" />
                    2FA Settings
                  </div>
                </Grid.Column>

                
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={5}>
                  {this.getFormTextField(
                    "Two Factor Authentication Renewal Cycle (Days)",
                    "two_factor_renewal_cycle",
                    this.state.validationMsg.two_factor_renewal_cycle,
                    this.state.keyValuePairs["two_factor_renewal_cycle"],
                    true
                  )}
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={6}>
                  {this.getFormTextField(
                    "Two Factor Authentication Email Code Expiry Time (sec)",
                    "email_expiry_time",
                    this.state.validationMsg.email_expiry_time,
                    this.state.keyValuePairs["email_expiry_time"],
                    true
                  )}
                </Grid.Column>
                 <Grid.Column mobile={16} tablet={16} computer={5}>
                  {this.getFormTextField(
                    "Two Factor Authentication SMS Code Expiry Time (sec)",
                    "sms_expiry_time",
                    this.state.validationMsg.sms_expiry_time,
                    this.state.keyValuePairs["sms_expiry_time"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row>
              
             
              {/* <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Project Document Upload Location",
                    "project_doc_upload_location",
                    this.state.validationMsg.project_doc_upload_location,
                    this.state.keyValuePairs["project_doc_upload_location"],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Temporary Upload Location",
                    "upload_temp_location",
                    this.state.validationMsg.upload_temp_location,
                    this.state.keyValuePairs["upload_temp_location"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Briefcase Upload Location",
                    "briefcase_upload_location",
                    this.state.validationMsg.briefcase_upload_location,
                    this.state.keyValuePairs["briefcase_upload_location"],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "License Upload Location",
                    "license_upload_location",
                    this.state.validationMsg.license_upload_location,
                    this.state.keyValuePairs["license_upload_location"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row> */}
              {/* <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label style={{ fontWeight: "bold" }}>
                    License Non-Acceptance/Expire Action{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id="license_expire_action"
                    name="license_expire_action"
                    onChange={this.handleSelectChange}
                    value={this.state.keyValuePairs["license_expire_action"]}
                  >
                    <option value="DENY_ACCESS">Deny Access</option>
                    <option value="GRANT_ACCESS">Grant Access</option>
                  </select>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Send Item Notification Email Address",
                    "send_item_notification_email_address",
                    this.state.validationMsg
                      .send_item_notification_email_address,
                    this.state.keyValuePairs["websilo_site_name"],
                    false
                  )}
                </Grid.Column>
              </Grid.Row> */}
              {/* <Grid.Row>
                {this.getDuoRadioField(
                  "NDA Enforcement",
                  "nda_enforcement",
                  this.state.validationMsg.nda_enforcement,
                  this.state.nda_enforcement,
                  true,
                  "On",
                  "Off"
                )}
                {this.getDuoRadioField(
                  "Pending NDA Auto Reminder",
                  "nda_auto_reminder",
                  this.state.validationMsg.nda_auto_reminder,
                  this.state.nda_auto_reminder,
                  true,
                  "Enabled",
                  "Disabled"
                )}
              </Grid.Row>
              {/* <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "NDA Upload Location",
                    "nda_upload_location",
                    this.state.validationMsg.nda_upload_location,
                    this.state.keyValuePairs["nda_upload_location"],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "NDA Backup Upload Location",
                    "nda_backup_upload_location",
                    this.state.validationMsg.nda_backup_upload_location,
                    this.state.keyValuePairs["nda_backup_upload_location"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row> */}
              {/* <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Pending NDA Automatic Reminder Interval (Days)",
                    "nda_pending_reminder_interval",
                    this.state.validationMsg.nda_pending_reminder_interval,
                    this.state.keyValuePairs["nda_pending_reminder_interval"],
                    true
                  )}
                  </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Profile URL",
                    "profile_http_path",
                    this.state.validationMsg.profile_http_path,
                    this.state.keyValuePairs["profile_http_path"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row> */}
              
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className="header">
                    <div className="side-marker" />
                    Top Menu Bar Settings
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Title for Home Menu Item",
                    "dashboard_menu_item_title",
                    this.state.validationMsg.dashboard_menu_item_title,
                    this.state.keyValuePairs["dashboard_menu_item_title"],
                    false,
                    32
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Title for Downloads Menu Item",
                    "products_menu_item_title",
                    this.state.validationMsg.products_menu_item_title,
                    this.state.keyValuePairs["products_menu_item_title"],
                    false,
                    32
                  )}
                </Grid.Column>
                
              </Grid.Row>
              <Grid.Row>
                 <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Title for Projects Menu Item",
                    "projects_menu_item_title",
                    this.state.validationMsg.projects_menu_item_title,
                    this.state.keyValuePairs["projects_menu_item_title"],
                    false,
                    32
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                {this.getFormTextField(
                    "Title for Briefcase Menu Item",
                    "briefcase_menu_item_title",
                    this.state.validationMsg.briefcase_menu_item_title,
                    this.state.keyValuePairs["briefcase_menu_item_title"],
                    false,
                    32
                  )}
                </Grid.Column>
                {/* <Grid.Column mobile={16} tablet={16} computer={5}>
                  {this.getFormTextField(
                    "Title for SLA Menu Item",
                    "sla_menu_item_title",
                    this.state.validationMsg.sla_menu_item_title,
                    this.state.keyValuePairs["sla_menu_item_title"],
                    false
                  )}
                  </Grid.Column> 
                <Grid.Column mobile={16} tablet={16} computer={5}>
                  {this.getFormTextField(
                    "Title for SWEL Menu Item",
                    "swel_menu_item_title",
                    this.state.validationMsg.swel_menu_item_title,
                    this.state.keyValuePairs["swel_menu_item_title"],
                    false
                  )}
                </Grid.Column>*/}
              </Grid.Row>
              <Grid.Row>
                {/*   <Grid.Column mobile={16} tablet={16} computer={6}>
                  {this.getFormTextField(
                    "Title for NDA Menu Item",
                    "nda_menu_item_title",
                    this.state.validationMsg.nda_menu_item_title,
                    this.state.keyValuePairs["nda_menu_item_title"],
                    false
                  )}
                  </Grid.Column> */}
                <Grid.Column mobile={16} tablet={16} computer={8}>
                 {this.getFormTextField(
                    "Title for Profile Menu Item",
                    "profile_menu_item_title",
                    this.state.validationMsg.profile_menu_item_title,
                    this.state.keyValuePairs["profile_menu_item_title"],
                    false,
                    32
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                {this.getFormTextField(
                    "Title for Admin Menu Item",
                    "admin_menu_item_title",
                    this.state.validationMsg.admin_menu_item_title,
                    this.state.keyValuePairs["admin_menu_item_title"],
                    false,
                    32
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Title for Videos Menu Item",
                    "training_videos_menu_item_title",
                    this.state.validationMsg.training_videos_menu_item_title,
                    this.state.keyValuePairs["training_videos_menu_item_title"],
                    false
                  )}
                </Grid.Column>
              </Grid.Row>
           
                {/* <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Title for Reports Menu Item",
                    "reports_menu_item_title",
                    this.state.validationMsg.reports_menu_item_title,
                    this.state.keyValuePairs["reports_menu_item_title"],
                    false
                  )}
                  </Grid.Column> */}
              
              {/* <Grid.Row> */}
                {/*
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <FileField
                    label="Upload Company Signature Image"
                    errorMsg={validationMsg.fileuuid}
                    isError={validationMsg.fileuuid}
                    name="fileuuid"
                    onChange={Uploader(this.handleFileChange)}
                    value={this.state.fileuuid}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <div className="field">
                    <label>Company Signature </label>
                    {this.state.keyValuePairs[
                      "company_signature_image_url"
                    ] && (
                      <Image
                        size="tiny"
                        src={
                          this.state.keyValuePairs[
                            "company_signature_image_url"
                          ]
                        }
                        wrapped
                      />
                    )}
                  </div>
                      </Grid.Column> */}
              {/* </Grid.Row> */}
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="button"
                    value="Save"
                    className="button-primary"
                    onClick={this.showpopupWarning}
                  />
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content="Are you sure you want to update general settings?"
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadApplicationConfig: (token) => {
      dispatch(Api.Config.loadApplicationConfig(token));
    },
  };
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SystemSettingsForm);
