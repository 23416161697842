import React, { Component } from "react";
import { Button, Checkbox, Grid, Header, Modal } from "semantic-ui-react";
import { isEmpty } from "lodash";
import employeeText from "@/common/files/employee-text.js";
import customerText from "@/common/files/customer-text.js";
import NonNdaCustomer from "@/common/files/non-nda-customer-text.js";
import LocalStore from "@/store/persistence/local-storage";

export default class WelcomePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      checkPopupState: false,
    };
  }

  componentDidMount() {
    const uid = LocalStore.getValue("uid");
    if (uid) {
      const localObj = LocalStore.getValue("welcomeModalState");
      const from = LocalStore.getValue("routeFrom");
      if (!isEmpty(localObj)) {
        if (localObj[uid] === "temporary") {
          if (from === "login") {
            this.setState({ isOpen: true });
          } else {
            this.setState({ isOpen: false });
          }
        } else if (localObj[uid] === "permanent") {
          this.setState({ isOpen: false });
        }
      } else {
        this.setState({ isOpen: true });
      }
    }
  }
 
  closeModal = () => {
    const uid = LocalStore.getValue("uid");
    const welcomeModalState = LocalStore.getValue("welcomeModalState") || {};
    welcomeModalState[`${uid}`] = this.state.checkPopupState
      ? "permanent"
      : "temporary";
    LocalStore.setValue(`welcomeModalState`, welcomeModalState);
    LocalStore.setValue("routeFrom", "other");
    this.setState({ isOpen: false });
  };

  checkShowMessage = (e, value) => {
    this.setState({ checkPopupState: value.checked });
  };

  render() {
    return (
      <div>
        <Modal
          open={this.state.isOpen}
          closeIcon
          onClose={() => this.closeModal()}
          className="welcome-popup-wrapper"
        >
          <Header>
            <h2>
              <div class="side-marker"></div>Welcome to Groq's Secure Customer
              Portal
            </h2>
          </Header>
          <Modal.Content>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  this.props.value === "customer-popup"
                    ? customerText
                    : this.props.value== "non-nda-customer-popup" ? NonNdaCustomer :  employeeText,
              }}
            ></div>
          </Modal.Content>
          <Modal.Actions>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={12}
                  computer={12}
                  className="m--align-left"
                >
                  <p
                    className="d-inline"
                    title="If you want to show this message again, click OK. Otherwise, please select the checkbox ‘Don’t show this message again’ and then click OK"
                  >
                    <Checkbox
                      label="Don’t show this message again "
                      onChange={($event, data) =>
                        this.checkShowMessage($event, data)
                      }
                    />
                  </p>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                  <Button
                    className="ui primary button"
                    color="green"
                    onClick={() => this.closeModal()}
                  >
                    OK
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
