const normalValidations = {
 /* initiatorName: {
    presence: true,
    length: {
      maximum: 50,
    },
  },
  initiatorEmail: {
    presence: true,
    email: true,
  },
  initiatorPhone: {
    presence: true,
    format: {
      pattern: /^[\d ()+-]+$/,
      message:
        "^Initiator phone format is invalid. It should only contain Numbers, +/- or ()",
    },
  },*/
  company: {
    presence: true,
  },
  infoBeingDisclosed: {
    presence: false,
    length: {
      maximum: 1000,
    },
  },
  // specificUsage: {
  //   presence: false,
  // },
  effectiveDate: {
    presence: true,
  },

  ndaDuration: {
    presence: false,
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
    }
  },

 /* contactEmail: {
    presence: true,
    email: true,
  },
  contactName: {
    presence: true,
  },
  signerName: {
    presence: false,
    length: {
      maximum: 50,
    },
  },
  signerTitle: {
    presence: false,
  },
  signerEmail: {
    presence: false,
    email: true,
  },*/
  postCode: function (value, attributes){
    if(attributes.localpostalresult==true)
    {
      return;
  }
  else{
    return {
      format: {
        pattern: "",
        message:
          " format is invalid",
      }
    };

  }
  },
  address1: {
    presence: false,
  },
  city: { presence: false },
  country: { presence: false },
  state: { presence: false },
  principleBusiness: { presence: false },
  // statusNotificationEmail: {
  //   presence: false,
  //   email: true,
  // },
  status: {
    presence: true,
  },
};
const rejectionValidation = {
  rejectionReason: {
    presence: true,
  },
};

const newStandardNDA = {
  ...normalValidations,
  fileuuid: {
    presence: true,
  },
};

const newNonStandardNDA = {
  ...normalValidations,
  fileuuid: {
    presence: false,
  },
  ndaUrl: {
    presence:false,
    url: true
  },
  statusNotificationEmail: {
    presence: false,
    email: true,
  },
};
const acceptanceValidation = {
  companySignature: {
    presence: false,
    length: {
      maximum: 2,
    },
    numericality: true,
  },
};

const newNonStandardNDALink = {
  ndaUrl: {
    presence:true,
    url: true
  }
};

const newNonStandardNDAFile = {
  fileuuid: {
    presence: true,
  }
}
export {
  newStandardNDA,
  newNonStandardNDA,
  normalValidations,
  rejectionValidation,
  acceptanceValidation,
  newNonStandardNDALink,
  newNonStandardNDAFile
};
