import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Segment, Grid, Form } from "semantic-ui-react";
import { InputField } from "@/common/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  addArticle,
  getMaximumSequence,
} from "../../../../../api/zendesk/articles";
import _ from "lodash";

const validationObj = {
  urlError: false,
  textToDisplayError: false,
  msg: "",
};
export default () => {
  const store = useSelector((state) => state.zendeskReducer);
  const [formValidations, setFormValidations] = useState({ ...validationObj });
  const [sequence, setSequence] = useState(0);
  const [urlError, setUrlError] = useState({
    isError: false,
    msg: "",
  });
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    const { sequence, url, textToDisplay } = e.target;
    if (textToDisplay.value == "") {
      setFormValidations({
        ...validationObj,
        textToDisplayError: true,
        msg: "Text To Display is required",
      });
      return;
    } else if (url.value == "") {
      setFormValidations({
        ...validationObj,
        urlError: true,
        msg: "Url is required",
      });
      return;
    } else {
      const dto = {
        sequence: sequence.value,
        url: url.value,
        textToDisplay: textToDisplay.value,
      };
      dispatch(addArticle(dto));
    }
  };

  useEffect(() => {
    dispatch(getMaximumSequence());
  }, [dispatch]);

  useEffect(() => {
    setSequence(store.maxSequence);
  }, [store]);

  const setUrlAndValidate = (val) => {
    setUrl(val);
    if (!validURL(val)) {
      setUrlError({
        isError: true,
        msg: "Please enter the valid url.",
      });
    } else {
      setUrlError({
        isError: false,
        msg: "",
      });
    }
  };
  const validURL =(str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }
  
  return (
    <Segment className="base-segment-wrapper">
      <Form
        loading={store ? store.loading : false}
        onSubmit={(e) => onSubmit(e)}
      >
        <Grid stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
              <div className="header">
                <div className="side-marker" />
                Add Articles
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
              <div className="control-action-wrapper"></div>
            </Grid.Column>
            <div className="empty-height" />
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <InputField
                label="Sequence"
                name="sequence"
                type="number"
                value={sequence}
                onChange={(e) => console.log(e)}
                isRequired
                maxlength={32}
                disabled
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <InputField
                label="Text To Display"
                name="textToDisplay"
                isError={formValidations.textToDisplayError}
                onChange={(e) => console.log(e)}
                isRequired
                maxlength={32}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <InputField
                label="URL"
                isError={formValidations.urlError}
                name="url"
                isError={urlError.isError}
                errorMsg={urlError.msg}
                onChange={(e) => setUrlAndValidate(e.target.value)}
                isRequired
              />
            </Grid.Column>
          </Grid.Row>
          <div className="ui divider"></div>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <button type="submit" className="button-primary">
                Save
              </button>
              <Link to={"/admin/zendesk"}>
                <button className="button-basic">Cancel</button>
              </Link>
              {store && store.errors.isError && (
                <span
                  style={{
                    color: "red",
                    marginLeft: "5px",
                  }}
                >
                  {store.errors.message}
                </span>
              )}
              {formValidations.textToDisplayError ||
              formValidations.urlError ? (
                <span
                  style={{
                    color: "red",
                    marginLeft: "5px",
                  }}
                >
                  {formValidations.msg}
                </span>
              ) : (
                <></>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Segment>
  );
};
