import Axios from "axios";
import Constants from "../../config/constants";

class AxiosInstance {
  static instance;
  static paginationDefaults = {
    size: 10,
    page: 0,
    sort: [],
    direction: "ASC",
  };

  static axios() {
    return Axios;
  }

  static getInstance() {
    return this.instance || Axios;
  }

  static hasInstance() {
    return this.instance;
  }

  static create(token) {
    this.instance = Axios.create({
      baseURL: Constants.App.API_URL,
      timeout: 30000,
      headers: { Authorization: token },
    });

    // console.log('axios instance created!', this.instance);
  }

  static destroy() {
    this.instance = null;
  }

  static paginate(url, options) {
    return this.instance.get(url, options);
  }

  static fileUpload() {}
}

// const axiosInstance = new AxiosInstance()

export default AxiosInstance;
