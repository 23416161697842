import React, { Component } from "react";
import { Dropdown, Grid, Segment } from "semantic-ui-react";
import TabView from "./../components/tabs";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      searchKeyword: "",
      queryParams: null,
      selected: "all",
      loading: false,
      pages: {
        products_page: null,
        items_page: null,
        projects_page: null,
        projectdocs_page: null,
        projectposts_page: null,
        corporatesite_page: null,
      },
      products_page: null,
      items_page: null,
      projects_page: null,
      projectdocs_page: null,
      projectposts_page: null,
      corporatesite_page: null,
      sort: {
        sortBy: "_score",
        sortOrder: "DESC",
      },
      pagination: {
        pageSize: 1000,
        pageNumber: 0,
      },
    };
  }

  componentDidMount() {
    if (this.props.location.pathname.split("/")[2]) {
      this.setState({
        searchKeyword: this.props.location.pathname.split("/")[2],
      });
    }
  }
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (location.pathname.split("/")[2]) {
        this.setState({
          keyword: location.pathname.split("/")[2],
          searchKeyword: location.pathname.split("/")[2],
        });
      }
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  Constants = {
    SEARCH_ON_TYPE: Object.freeze([
      {
        key: "ALL",
        text: "ALL",
        value: "ALL",
      },
      {
        key: "downloads",
        text: "Downloads",
        value: "downloads",
      },
      {
        key: "videos",
        text: "Videos",
        value: "videos",
      },
      {
        key: "projects",
        text: "Projects",
        value: "projects",
      },
      {
        key: "corporate",
        text: "Corporate Site",
        value: "corporate",
      },
      {
        key: "tickets",
        text: "Support Cases",
        value: "tickets",
      },
      {
        key: "articles",
        text: "KB Articles",
        value: "articles",
      },
      {
        key: "tickets",
        text: "Support Cases",
        value: "tickets",
      },
      {
        key: "articles",
        text: "KB Articles",
        value: "articles",
      },
    ]),
    SORT_COLUMNS: Object.freeze([
      {
        key: "_score,DESC",
        text: "Most Relevant",
        value: "_score,DESC",
      },
      // {
      //   key: "_score,ASC",
      //   text: "Relevancy (Low to High)",
      //   value: "_score,ASC",
      // },
      {
        key: "creationDate,DESC",
        text: "Most Recent",
        value: "creationDate,DESC",
      },
      // {
      //   key: "creationDate,ASC",
      //   text: "Most recent last",
      //   value: "creationDate,ASC",
      // },
    ]),
  };

  onSearchTyping = (data) => {
    this.setState({
      searchKeyword: data.target.value,
    });
  };

  onSearchStatusChange = (event, data) => {
    this.setState({
      selected: data.value,
    });
  };
  onSortChange = (event, data) => {
    this.setState({
      sort: {
        sortBy: data.value.split(",")[0],
        sortOrder: data.value.split(",")[1],
      },
    });
  };
  searchResults = () => {
    this.setState({ keyword: this.state.searchKeyword });
  };

  onProductPageChange = (e) => {
    this.setState(
      {
        products_page: e.page,
      },
      this.searchResults
    );
  };
  onProjectsPageChange = (e) => {
    this.setState(
      {
        projects_page: e.page,
      },
      this.searchResults
    );
  };
  onPostPageChange = (e) => {
    this.setState(
      {
        projectposts_page: e.page,
      },
      this.searchResults
    );
  };
  onItemsPageChange = (e) => {
    this.setState(
      {
        items_page: e.page,
      },
      this.searchResults
    );
  };
  onDocsPageChange = (e) => {
    this.setState(
      {
        projectdocs_page: e.page,
      },
      this.searchResults
    );
  };
  onSitePageChange = (e) => {
    this.setState(
      {
        corporatesite_page: e.page,
      },
      this.searchResults
    );
  };
  onVideosPageChange = (e) => {
    this.setState(
      {
        videos_page: e.page,
      },
      this.searchResults
    );
  };
  render() {
    return (
      <React.Fragment>
        <Segment basic>
          <Grid>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                <Segment className="base-segment-wrapper">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={16}
                        largeScreen={16}
                      >
                        <div className="header">
                          <div className="side-marker" />
                          Global Search{" "}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="common-filter-wrapper ui form global-search-field-box">
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <form onSubmit={this.searchResults}>
                          <div>
                            <div class="field">
                              <div class="ui input">
                                <input
                                  placeholder="Type your keyword here"
                                  className="search-bar"
                                  value={this.state.searchKeyword}
                                  onChange={this.onSearchTyping}
                                ></input>
                              </div>
                            </div>
                          </div>
                        </form>
                      </Grid.Column>

                      <Grid.Column
                        mobile={16}
                        tablet={8}
                        computer={8}
                        largeScreen={8}
                        className="button-inline-display"
                      >
                        <div class="field">
                          <div class="ui input">
                            {/* <Dropdown
                              placeholder="Search In"
                              selection
                              defaultValue="ALL"
                              options={this.Constants.SEARCH_ON_TYPE}
                              onChange={this.onSearchStatusChange}
                            /> */}
                            <Dropdown
                              placeholder="Search In"
                              selection
                              defaultValue="_score,DESC"
                              options={this.Constants.SORT_COLUMNS}
                              onChange={this.onSortChange}
                            />
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui input">
                            <button
                              onClick={this.searchResults}
                              className="button-primary"
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>{" "}
                    <Grid.Row>
                      <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={16}
                        largeScreen={16}
                      >
                        <TabView
                          {...this.state}
                          // selection={"corporate"}
                          {...this.props}
                          onProductPageChange={this.onProductPageChange}
                          onProjectsPageChange={this.onProjectsPageChange}
                          onPostPageChange={this.onPostPageChange}
                          onItemsPageChange={this.onItemsPageChange}
                          onDocsPageChange={this.onDocsPageChange}
                          onSitePageChange={this.onSitePageChange}
                          onVideosPageChange={this.onVideosPageChange}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </React.Fragment>
    );
  }
}

export default Search;
