import Videos from "./videos";
import { connect } from "react-redux";
import { configSelector, authoritySelector } from "@/common/selectors";
import Config from "@/config/constants";

const baseUrl = "/videos";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  // incase i'll need config values
  config: configSelector(state),
  baseUrl: baseUrl,
  authority: authoritySelector(state),
});

const mappedVideos = connect(mapStateToProps, mapDispatchToProps)(Videos);

export default {
  name: "Videos",
  configKey: "video_menu_item_title",
  path: baseUrl,
  component: mappedVideos,
  // navBarVisibility: false,
  isPrivateRoute: true,
  accessRoles: Config.AccessRole.SPECIAL_ROLES,
  // accessRoles: [Config.AccessRole.SYSTEM_ADMIN],
};
