import React, { useEffect } from "react";
import { Button, Card, Form, Label, TextArea } from "semantic-ui-react";
import { dateToPacific } from "../../../utils/dateTime";

function DownloadProjectItem({ item, cancel }) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className="base-segment-wrapper product-file-downlaod">
        <div className="ui form mb-2-im">
          {item.filename && (
            <Form.Input label="File Name">
              <Label>
                <b>{item.filename}</b>
              </Label>
            </Form.Input>
          )}{" "}
          {item.description && (
            <Form.Input label="Description">
              <Label className="info-text-area">{item.description}</Label>
            </Form.Input>
          )}
          {item.creationDate && (
            <Form.Input label="Creation Date">
              <Label>
                <b>{dateToPacific(item.creationDate, "MM/DD/YYYY")}</b>
              </Label>
            </Form.Input>
          )}
          {item.createdBy && (
            <Form.Input label="Created By">
              <Label>
                <b>{item.createdBy}</b>
              </Label>
            </Form.Input>
          )}
        </div>
      </div>
      <a href={item.downloadLink} target="_blank">
        <Button className="button-primary m--no-margin-left-im">
          Download
        </Button>
      </a>{" "}
      <button onClick={cancel} className="button-basic">
        Cancel
      </button>
    </React.Fragment>
  );
}

export default DownloadProjectItem;
