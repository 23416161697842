export default class LocalStore {
	static setValue(name, value) {
		try {
			const serializedState = JSON.stringify(value);
			localStorage.setItem(name, serializedState);
		} catch {
			// ignore write errors
		}
	}

	static removeValue(name) {
		try {
			localStorage.removeItem(name);
		} catch {
			// ignore write errors
		}
	}

	static getValue(name, defaultValue=undefined) {
		try {
			const serializedState = localStorage.getItem(name);
			if (serializedState === null) {
				return defaultValue;
			}
			return JSON.parse(serializedState);
		} catch (err) {
			return defaultValue;
		}
	}
}
