import React, { useState } from "react";
import ProductsContext from "../_context";
import { Segment, Button, Message } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import Api from "../../../../api";
import Constants from "../../../../config/constants";
// import { get } from "lodash";

function DeleteProduct(props) {
  const { data, history, dispatch, successRedirect } = props;
  const { products, productsDropDownMap, selectedProduct } = data;

  const [isDeleting, setIsDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);

  if (selectedProduct === null || !products || !productsDropDownMap) {
    return <>r</>;
  }

  const get = (products, ladder) => {
    // debugger;
    if(!products || !ladder) return ;
    const productId = ladder.pop();
    const product = products.filter((p) => p.id === parseInt(productId))[0];
    if(ladder.length === 0) return product;
    if (product && product.children && product.children.length)
      return get(product.children, ladder);
    return null;
  }

  const productName = productsDropDownMap.filter(
    p => p.key === parseInt(selectedProduct)
  )[0];
  if (!productName) return <>v</>;
  const cancel = () => setRedirect(true);

  const deleteProduct = () => {
    const { URL_PRODUCTS_DETAILS } = Constants.App;
    const params = { params: { id: selectedProduct } };
    const instance = Api.AxiosInstance.getInstance();
    const postObj = { actiontype: "DELETE_PRODUCT" };

    setIsDeleting(true);
    instance.post(URL_PRODUCTS_DETAILS, postObj, params).then(resp => {
      dispatch({ type: "reload-products" });
      history.push(successRedirect);
    }).catch((e) => {
        setIsDeleting(false);
        setError(e && e.response && e.response.data && e.response.data.message);
    });
  };

  if (redirect) {
    history.goBack();
    return <></>;
  }

  return (
    <>
      <Segment raised>
        Are you sure you want to delete "{productName.text}"?
        <hr />
        <Button color="red" onClick={deleteProduct} disabled={isDeleting}>
          Delete
        </Button>{" "}
        <Button disabled={isDeleting} onClick={cancel}>
          Cancel
        </Button>
      </Segment>
      {error && <Message error header="Delete failed!" content={error} />}
    </>
  );
}

export default ProductsContext.Consumer(DeleteProduct);
