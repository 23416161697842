import React, { Component } from "react";
import { Segment, Button, Container, Modal, Icon } from "semantic-ui-react";
import Api from "@/api";

class ActivationCodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secretKey: null,
      active: false,
      keyCopiedToClipboard: false,
    };
  }
  componentDidMount = () => {
    //do nothing
  };
  fetchCode = () => {
    //twoFactorAuthKey
    Api.AxiosInstance.getInstance()
      .get("/twoFactorAuthKey")
      .then((resp) => {
        this.setState({ secretKey: resp.data.secretKey });
      })
      .catch((error) => {
        this.setState({ apiError: error.response.data.message });
      });
  };
  onSave = () => {
    console.log("save call here");
  };
  onModalClose = () => {
    console.log("close");
  };
  downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([document.getElementById("key").value], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `mykey.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  activate2FA = () => {
    const { secretKey } = this.state;
    let params = {
      secretKey,
    };
    Api.AxiosInstance.getInstance()
      .post("/twoFactorAuthKey/active", params)
      .then((resp) => {
        this.setState({ secretKey: resp.data.key, active: resp.data.active });
        this.props.onIsActive();
        this.props.onModalClose();
      })
      .catch((error) => {
        this.setState({ apiError: error.response.data.message });
      });
  };
  copyToClipboard = () => {
    const { secretKey } = this.state;
    const el = document.createElement("textarea");
    el.value = secretKey;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ keyCopiedToClipboard: true });
  };
  render() {
    return (
      <Modal
        open={this.props.show2FAPopup}
        onMount={this.fetchCode}
        onClose={this.props.onModalClose}
        centered={true}
        size="large"
      >
        <Modal.Header>Two Factor Activation</Modal.Header>
        <Modal.Content>
          <Segment basic size="mini" padded={false}>
            <Container>
              <pre>
                {"Are you sure you want to activate two factor authentication?"}
                {/* Two factor auth refactor */}
                {/* {this.state.secretKey} */}
                {/* <input
                  hidden
                  readOnly
                  style={{ width: "100%" }}
                  id="key"
                  value={this.state.secretKey}
                />
                {" "}
                <Button
                  onClick={this.copyToClipboard}
                >
                  <Icon name="copy" />
                </Button>{" "} */}
              </pre>
              <Button
                primary
                disabled={this.state.active}
                onClick={() => {
                  this.activate2FA();
                }}
              >
                Yes
              </Button>
              {/* Two factor auth refactor */}
              {/* {this.state.active && (
                <Button primary onClick={this.downloadTxtFile}>
                  Download Key file
                </Button>
              )} */}
              <Button onClick={this.props.onModalClose}>No</Button>
            </Container>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ActivationCodeModal;
