import React from "react";
import Api from "@/api";
import { Segment, Grid, GridColumn, GridRow } from "semantic-ui-react";
import FileActions from "./components/actions_files";
import Browser from "./components/browser";
import ProductsContext from "./_context";
import { withRouter, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import ProductConstants from "../constants";

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.matchUrl();
    this.dispatchBaseurl();
    this.hasAdminAuthority();
    this.props.dispatch(this.props.actions.loadingProducts());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // this.props.dispatch({type: 'loading-products'})
      setTimeout(() => {
        this.matchUrl();
      });
    }
  }
  fecthIdfromSlug(slug) {
    let params = {
      params:{
        slug
      }
    }
    return Api.AxiosInstance.getInstance()
      .get("/products/fetchId", params )
      .then((resp) => {
         this.setState({ id: resp.data}, this.updateRoute(resp.data))
      })
      .catch((e) => {
        this.setState({msg: "error"});
      })
    }
    updateRoute(id) {  
      this.props.dispatch(
        this.props.actions.routeUpdate(
          id,
          this.loadProductById(id)
        )
      );
      window.location =
      window.location.href.replace("/downloads/browser", "/downloads/browser/" + id );
    }

  loadProductById(id) {
    if (this.props.data && this.props.data.rawProducts) {
      return this.props.data.rawProducts[id];     
    }
  }

  containsNumbers = (str) => {
    return str.match(/^[0-9]+$/);
  }
  matchUrl() {
    const match = matchPath(
      this.props.location.pathname,
      ProductConstants.URL_BROWSER + "/:id"
    );
    if (match && match.params && match.params.id) {
      let path = window.location.hash;
      let id ;
      path = path.replace("#/downloads/browser", "");
      path = path.replace(/^\/+|\/+$/g, ''); // The regular expression matches slashes at start and end
      if (!path){
        id = 1;
      }
      else{
        let checkId = path.split("/")
        let slug ;
        if(this.containsNumbers(checkId[0]) == null){
            // fetch id from backend
            this.fecthIdfromSlug(path);
            slug = path;
          } else {
          id = match.params.id;
          this.props.dispatch(
          this.props.actions.routeUpdate(
          parseInt(id),
          this.loadProductById(id)
        )
      );}
      }
     
    } else {
      this.props.dispatch(this.props.actions.routeUpdate(null, null));
    }
  }

  dispatchBaseurl() {
    this.props.dispatch(this.props.actions.baseurlUpdate(this.props.baseUrl));
  }

  hasAdminAuthority() {
    const roles =
      this.props.user.loggedin &&
      this.props.user.loggedin.user &&
      this.props.user.loggedin.user.authorities;
    const hasAdminRole =
      roles &&
      roles.reduce((a, r) => {
        if (
          r.authority === "websilo-sysadmin" ||
          r.authority === "websilo-admin"
        ) {
          return true;
        }
        return a;
      }, false);
    if (hasAdminRole) this.props.dispatch(this.props.actions.isAdmin());
  }

  render() {
    return (
      <div className="products-container">
        <Segment className="base-segment-wrapper">
          <Grid stackable>
            <GridRow className="products-header">
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="side-marker" />
                <div className="header inline">Downloads</div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <FileActions />
              </Grid.Column>
            </GridRow>
          </Grid>
          <Browser />
        </Segment>
      </div>
    );
  }
}

Products.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default ProductsContext.Consumer(withRouter(Products));
