import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Label,
  Message,
  Modal,
  Segment
} from "semantic-ui-react";
import { validate } from "validate.js";
import { isEmpty } from "lodash";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { removeEmptyKeys } from "../../common/utils/utils";
import urls from "../../config/constants";
import ErrorMessage from "@/common/Form/subcomponents/error-message";

class LoginAssistance extends React.Component {
  validationConfig = {
    user: {
      presence: true
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      user: null,
      showSuccess: false,
      validationMsg: {}
    };
  }

  onSubmit = event => {
    event.preventDefault();
    const { user } = this.state;
    let result = validate(removeEmptyKeys(this.state), this.validationConfig);
    if (isEmpty(result)) {
      this.setState(
        { apiError: null, disabled: true, validationMsg: {} },
        () => {
          this.doSumbit(user);
        }
      );
    } else {
      this.setState({ validationMsg: result });
    }
  };

  isUsernameInvalid = user => {
    const params = {
      params: {
        username: user
      }
    };
    Axios.get(urls.App.URL_CHECK_USERNAME, params)
      .then(resp => {
        this.isEmailInvalid(user);
      })
      .catch(() => {
        this.doSumbit(user);
      });
  };

  isEmailInvalid = user => {
    const params = {
      params: {
        email: user
      }
    };
    Axios.get(urls.App.URL_CHECK_EMAIL, params)
      .then(resp => {
        this.setState({
          disabled: false,
          validationMsg: { user: "Entered Username/Email doesn't exist." }
        });
      })
      .catch(() => {
        this.doSumbit(user);
      });
  };

  doSumbit = user => {
    Axios.post(urls.App.URL_FORGET_PASSWORD, { usernameOrEmail: user })
      .then(resp => {
        this.setState({ showSuccess: true });
      })
      .catch(e => {
        this.setState({
          apiError:
            "Something went wrong while submitting the password reset request."
        });
      });
  };

  render() {
    const { apiError, disabled, showSuccess, validationMsg } = this.state;
    return (
      <React.Fragment>
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 500 }}>
            <Header as="h2" color="black" textAlign="center">
              {/* <Image src="/logo-1.png" /> */}
              Account Login Assistance
            </Header>
            <Form
              size="large"
              onSubmit={this.onSubmit}
              loading={disabled}
              error={apiError}
            >
              <Message
                error
                header="Reset Password Failed"
                content={apiError}
              />
              <Segment stacked>
                <div style={{ textAlign: "left" }}>
                  Retrieve your account credentials using either your username
                  or your email address
                </div>
                <Form.Field required error={validationMsg.user}>
                  <br />
                  <Form.Input
                    fluid
                    placeholder="E-mail address or username"
                    onChange={e => {
                      this.setState({ user: e.target.value });
                    }}
                  />
                  <ErrorMessage
                    isError={validationMsg.user}
                    errorMsg={validationMsg.user}
                  />
                  <Button
                    className="m--no-margin-im"
                    primary
                    fluid
                    size="large"
                  >
                    Submit
                  </Button>
                </Form.Field>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
        <Modal
          open={showSuccess}
          onClose={() => {
            this.props.history.push("/login");
          }}
          centered={true}
          size="tiny"
          content="We have sent you an email for account reset. Please check your inbox."
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(LoginAssistance);
