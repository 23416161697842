import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder as folderSolid,
  faFolderOpen,
  faFolderPlus,
  faPencilAlt,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { Dimmer, Loader, Image, Segment, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Context from "../_context";

function Actions({ expandAll, closeAll }) {
  const context = useContext(Context.Context);
  const selectedProduct =
    context && context.data && context.data.selectedProduct !== null
      ? context.data.selectedProduct
      : false;

  const selectedProductData = 
		selectedProduct && context && context.data && context.data.rawProducts &&
  		context.data.rawProducts[selectedProduct] 
		? context.data.rawProducts[selectedProduct]  
		: false;


  const baseUrl =
    context && context.data && context.data.baseUrl ? context.data.baseUrl : "";

  const isAdmin = context && context.data && context.data.isAdmin;

  return (
    <div className="nav-actions">
      <Link to="#">
        <FontAwesomeIcon
          icon={faFolderOpen}
          onClick={() => expandAll()}
          className="item-icon"
          title="Expand All"
        />
      </Link>
      &nbsp; | &nbsp;
      <Link to="#">
        <FontAwesomeIcon
          icon={folderSolid}
          onClick={() => closeAll()}
          className="item-icon"
          title="Close All"
        />
      </Link>
      {isAdmin && (
       <React.Fragment>
        &nbsp; | &nbsp;
        <Link
          to={
            baseUrl +
            "/" +
            (selectedProductData ? selectedProduct : "") +
            "/add"
          }
          className={selectedProductData && isAdmin ? "" : "disabled-link"}
        >
          <FontAwesomeIcon
            icon={faFolderPlus}
            className={
              "item-icon " + (selectedProductData && isAdmin ? "" : "disabled")
            }
            title="Add a new folder"
          />
        </Link>
        </React.Fragment>
      )}

      {isAdmin && (
        <React.Fragment>
        &nbsp; | &nbsp;
        <Link
          to={
            baseUrl +
            "/" +
            (selectedProductData ? selectedProduct : "") +
            "/edit"
          }
          className={selectedProductData && isAdmin ? "" : "disabled-link"}
        >
          <FontAwesomeIcon
            icon={faPencilAlt}
            className={
              "item-icon " + (selectedProductData && isAdmin ? "" : "disabled")
            }
            title="Edit folder"
          />
        </Link>
        </React.Fragment>
      )}
      {isAdmin && (
        <React.Fragment>
        &nbsp; | &nbsp;
        <Link
          to={
            baseUrl +
            "/" +
            (selectedProductData ? selectedProduct : "") +
            "/delete"
          }
          className={selectedProductData && isAdmin ? "" : "disabled-link"}
        >
          <FontAwesomeIcon
            icon={faTrashAlt}
            className={
              "item-icon " + (selectedProductData && isAdmin ? "" : "disabled")
            }
            title="Delete folder"
          />
        </Link>
        </React.Fragment>
      )}
    </div>
  );
}

export default Actions;
