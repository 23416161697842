import React from"react"
import PropTypes from "prop-types"
import {Container, Checkbox, Pagination} from "semantic-ui-react";

class Gridview extends React.Component {
	static propTypes = {
		data: PropTypes.object.isRequired,
		// columns: PropTypes.array.isRequired,
		// title: PropTypes.string.isRequired,
		// description: PropTypes.string.isRequired
		update: PropTypes.func,

	}



	constructor(props) {
		super(props)
		/*let dataInit = {
			content: [],
			totalPages: 0,
			totalElements: 0,
			first: false,
			last: false,
			size: 0,
			number: 0,
			sort: {
					sorted: true,
					unsorted: false,
					empty: false
			},
			numberOfElements: 0,
			empty: false
		}*/
		// console.log('dashboard instantiated', props);
		this.state = {
			columns: props.columns,
			title: props.title,
			noDataMessage: props.noDataMessage ? props.noDataMessage : 'No Data!',
      checkboxes: [],
      checkboxAll: false
		}
		//this.data = props.data

	}

  toggleSelection = (key) => {
    console.log('checkbox key', key)
    let checkboxes = this.state.checkboxes;
    checkboxes[key] = !checkboxes[key]
    if(typeof(this.props.update) !== 'undefined') {
      this.props.update({
        selected: true,
        data: this.props.data.content.filter((v, k) => checkboxes[k])
      });
    }
    this.setState({
      checkboxes
    })
  }

  toggleAllSelection = () => {
    let checkboxes = this.props.data.content.map(() => !this.state.checkboxAll);
    if(typeof(this.props.update) !== 'undefined') {
      this.props.update({
        selected: true,
        data: this.props.data.content.filter((v, k) => checkboxes[k])
      });
    }
    this.setState({
      checkboxes,
      checkboxAll: !this.state.checkboxAll
    })
  }

  pageChange = (e, { activePage }) => {
    let checkboxes = this.props.data.content.map(() => false);
    this.setState({
      checkboxes,
      checkboxAll: false
    })
    if(typeof(this.props.update) !== 'undefined') {
      this.props.update({
        paginate: true,
        page: activePage
      });
    }
  }

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.data.content) {

		}
	}

	render() {
		return (
			<div className="grid-view-container">
				{ this.state.title &&
          <div className="grid-view-title">
  					<span>{this.state.title}</span>
  				</div>
        }
				<table className="grid-view table table-bordered">
					<thead>
            <tr>
            {
              this.props.checkboxes && <th><Checkbox onClick={this.toggleAllSelection}/></th>
            }
						{
							this.state.columns.map((d) => <th key={d.name}>{d.name}</th>)
						}
						</tr>
					</thead>
					<tbody>
						{
							this.props.data && this.props.data.content && this.props.data.content.length ?
								this.props.data.content.map( (data, key) => <tr key={key}>
                    {
                      this.props.checkboxes && <td><Checkbox checked={this.state.checkboxes[key]} onChange={() => {this.toggleSelection(key)}}/></td>
                    }
										{
											this.state.columns.map((column) =>
												<td key={column.key}>
                          {
                            typeof(column.render) !== 'undefined'
                              ? column.render(data, key) : [data]
                                .concat(column.key.split('.'))
                                .reduce(function(a, b) { return a[b] })


                          }</td>
											)
										}
									</tr>
								) : <tr>
											<td className="no-data-message" colSpan={this.props.checkboxes ? 1+this.state.columns.length  : this.state.columns.length }>{this.state.noDataMessage}</td>
										</tr>
						}
					</tbody>
				</table>
        {this.props.paginate && <Pagination
            activePage={this.props.data.number+1}
            onPageChange={this.pageChange}
            totalPages={this.props.data.totalPages ? this.props.data.totalPages : 0}
          />}
			</div>
		)
	}
}

export default Gridview;
