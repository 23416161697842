import Api from "@/api";
import Constants from "@/config/constants";
import ProductConstants from "@/modules/products/constants";
import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Dimmer,
  Grid,
  Loader,
  Modal,
  Responsive,
  Segment,
} from "semantic-ui-react";
import AddItems from "../pages/add-items";
import AddProducts from "../pages/add-products";
import DeleteProduct from "../pages/delete-product";
import EditItems from "../pages/edit-items";
import EditProducts from "../pages/edit-products";
import ListItems from "../pages/list-items";
import ProductsContext from "../_context";
import Actions from "./actions";
import ProductTree from "./products-tree";

function containsNumbers(str) {
  return str.match(/^[0-9]+$/);
}

class Browser extends React.Component {
  static FOLDER_HEIRARCHY =
    " <span className='breadcrumb-next'>&raquo;</span> ";
  static context = ProductsContext.Context;

  staticValues = {
    routes: [
      {
        path: ProductConstants.URL_BROWSER + "/:id/items/:itemId/edit",
        exact: true,
        render: (p) => <EditItems key={p.match.params.id} {...p} />,
      },
      {
        path: ProductConstants.URL_BROWSER + "/:id/items/add",
        exact: true,
        render: (p) => <AddItems key={p.match.params.id} {...p} />,
      },
      {
        path: ProductConstants.URL_BROWSER + "/:id/add",
        exact: true,
        render: (p) => <AddProducts key={p.match.params.id} {...p} />,
      },
      {
        path: ProductConstants.URL_BROWSER + "/:id/edit",
        exact: true,
        render: (p) => <EditProducts key={p.match.params.id} {...p} />,
      },
      {
        path: ProductConstants.URL_BROWSER,
        exact: true,
        render: (p) => <ListItems key={p.match.params.id} {...p} />,
      },
      {
        path: ProductConstants.URL_BROWSER + "/:id/delete",
        exact: true,
        render: (p) => <DeleteProduct key={p.match.params.id} {...p} successRedirect={ProductConstants.URL_BROWSER}/>,
      },
      {
        path: ProductConstants.URL_BROWSER + "/:id",
        exact: false,
        render: (p) => <ListItems key={p.match.params.id} {...p} />,
      },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      isProductsLoaded: false,
      products: [],
      showError: false,
      errorMsg: null,
      showTree: false,
    };
  }

  componentDidMount() {
    this.loadProducts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if (
      this.props.data.selectedProduct !== prevProps.data.selectedProduct
      /*** Commenting this because this condition is just false always
       * (prevProps.data.productParentMap === null &&
       * prevProps.data.productParentMap)
       */
    ) {
      // console.log('breadcrumbs update', this.props.data.selectedProduct)
      this.dropBreadcrumbs();
    } else if (
      /*(prevProps.data.selectedProduct !== null &&
        this.props.data.selectedProduct === null) ||*/
      prevProps.data.reloadProducts === false &&
      this.props.data.reloadProducts === true
    ) {
      this.loadProducts();
    }
  }

  dropBreadcrumbs() {
    let breadcrumbs = Browser.FOLDER_HEIRARCHY;
    if (
      this.props.data.selectedProduct !== null &&
      typeof this.props.data.selectedProduct !== "undefined"
    ) {
      breadcrumbs += this.breadcrumbs();
      //this.props.data.products.map((product) => this.breadcrumbs(product));
    }
    if (breadcrumbs) {
      this.props.dispatch({
        breadcrumbs: breadcrumbs,
        type: "breadcrumbs-update",
      });
    }
  }

  breadcrumbs() {
    if (
      this.props.data.selectedProduct === null ||
      typeof this.props.data.selectedProduct === "undefined"
    ) {
      return "";
    }
    const selectedProduct = this.props.data.selectedProduct;
    if (this.props.data.productParentMap) {
      const ancestor = this.props.data.productParentMap[selectedProduct];
      let topProducts = this.props.data.products;
      let crumb = [];
      for (var parentId in ancestor) {
        const ancestorProduct = topProducts.filter((p) => p.id == parentId);
        if (ancestorProduct && ancestorProduct.length > 0) {
          crumb.push(ancestorProduct[0].name);

          if (ancestorProduct[0].children) {
            topProducts = ancestorProduct[0].children;
          }
        }
      }
      return crumb.length > 0 ? crumb.join(Browser.FOLDER_HEIRARCHY) : false;
    }
    return "";
  }

  buildProductParentMap(product, map) {
    if (product && product.id !== null) {
      map[product.id] = { [product.id]: 1 };
      if (product.parent_id !== null && !!map[product.parent_id]) {
        map[product.id] = Object.assign({}, map[product.parent_id], {
          [product.id]: 1,
        });
      }
      if (product.children && product.children.length > 0) {
        for (var i in product.children) {
          this.buildProductParentMap(product.children[i], map);
        }
      }
    }
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  expandAll = () => {
    this.props.dispatch(this.props.actions.expandProducts(true));
  };

  closeAll = () => {
    this.props.dispatch(this.props.actions.expandProducts(false));
  };
  loadProducts() {
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_PRODUCTS_LIST)
      .then((resp) => {
        let products = resp.data;
        let productTree = [];
        let productParentMap = {};
        let productIdNameMap = [
          {
            text: "<Root>",
            key: -1,
            value: -1,
          },
        ];

        for (var i in products) {
          let product = products[i];
          if (product.parent_id !== null && !!products[product.parent_id]) {
            products[product.parent_id].children.push(product);
          } else {
            productTree.push(product);
          }

          productIdNameMap.push({
            text: product.name,
            key: product.id === null ? -1 : product.id,
            value: product.id === null ? -1 : product.id,
          });
        }
        // User Story #56612 (fixed)
        // 9a. Products Tab - Products navigation tree should be expanded
        if (productTree.length === 1) {
          productTree[0].expand = true;
        }

        productTree.map((p) => this.buildProductParentMap(p, productParentMap));

        this.props.dispatch(
          this.props.actions.updateProducts(
            productTree,
            products,
            productIdNameMap,
            productParentMap
          )
        );
        this.dropBreadcrumbs();
      })
      .catch((e) => {
        this.setState({
          showError: true,
          errorMsg:
            "You do not have access to any download items. Please reach out portal_admin@groq.com for more information.",
        });
        this.props.dispatch(this.props.actions.updateProducts({}, {}, {}, {}));
      });
  }
  setShowTree = () => {
    this.setState({
      showTree: false,
    });
  };
  render() {
    // const { showTree } = this.context;
    const { products, showError, errorMsg, showTree } = this.state;
    const { breadcrumbs } = this.props.data;
    return (
      <React.Fragment>
        <Responsive maxWidth={1023}>
          <Grid stackable className="products-browser">
            {showTree ? (
              <Grid.Row className="products-browser-row">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={4}
                  largeScreen={4}
                  className="products-browser-tree"
                >
                  <Actions
                    expandAll={this.expandAll}
                    closeAll={this.closeAll}
                  />
                  <ProductTree
                    match={this.props.match}
                    products={products}
                    selectFolder={this.selectFolder}
                    setShowTree={this.setShowTree}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={12}
                largeScreen={12}
                className="products-browser-list enable-vertical-scroll "
              >
                {" "}
                <div className="product-responsive-row">
                  <div className="control-action-wrapper">
                    {" "}
                    <span onClick={() => this.setState({ showTree: true })}>
                      <a class="button-basic clr-primary-link"> <span className="icon-login-arrow"></span> Back</a>
                    </span>{" "}
                  </div>
                  <div
                    className="breadcrumbs"
                    dangerouslySetInnerHTML={{ __html: breadcrumbs }}
                  ></div>
                </div>
                <div className="empty-height"></div>
                {this.props.data.isModuleReady && (
                  <Segment basic className="products-segment-div">
                    <Dimmer active={this.props.data.isListLoading} inverted>
                      <Loader>Loading</Loader>
                    </Dimmer>

                    <Switch>
                      {this.staticValues.routes.map((item, key) => (
                        <Route key={key} {...item} />
                      ))}
                    </Switch>
                  </Segment>
                )}
              </Grid.Column>
            )}
          </Grid>
        </Responsive>
        <Responsive minWidth={1024}>
          <Grid stackable className="products-browser">
            <Grid.Row className="products-browser-row">
              <Grid.Column
                mobile={16}
                tablet={5}
                computer={4}
                largeScreen={4}
                className="products-browser-tree"
              >
                <Actions expandAll={this.expandAll} closeAll={this.closeAll} />
                <ProductTree
                  match={this.props.match}
                  products={products}
                  selectFolder={this.selectFolder}
                  setShowTree={this.setShowTree}
                />
              </Grid.Column>
              <Grid.Column
                mobile={16}
                tablet={11}
                computer={12}
                largeScreen={12}
                className="products-browser-list enable-vertical-scroll"
              >
                <div
                  className="breadcrumbs"
                  dangerouslySetInnerHTML={{ __html: breadcrumbs }}
                ></div>
                <div className="empty-height"></div>
                {this.props.data.isModuleReady && (
                  <Segment basic className="products-segment-div">
                    <Dimmer active={this.props.data.isListLoading} inverted>
                      <Loader>Loading</Loader>
                    </Dimmer>

                    <Switch>
                      {this.staticValues.routes.map((item, key) => (
                        <Route key={key} {...item} />
                      ))}
                    </Switch>
                  </Segment>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default ProductsContext.Consumer(Browser);
