import Projects from "./projects";
import { connect } from "react-redux";
import Config from "@/config/constants";
import { authoritySelector } from "@/common/selectors";

const mapDispatchToProps = dispatch => {
  return {};
};

const baseUrl = "/projects";

const mapStateToProps = state => ({
  authority: authoritySelector(state),
  baseUrl: baseUrl
});

const mappedProjects = connect(mapStateToProps, mapDispatchToProps)(Projects);

export default {
  name: "Projects",
  configKey: "projects_menu_item_title",
  path: baseUrl,
  component: mappedProjects,
  isPrivateRoute: true,
  accessRoles: Config.AccessRole.PRIVILEGED
};
