import Api from "@/api";
import {
  FileField,
  InputField,
  TextareaField,
  ErrorMessage,
} from "@/common/Form";
import Uploader from "@/common/uploader";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import update from "immutability-helper";
import { get, isEmpty } from "lodash";
import moment from "moment";
import React from "react";
// import Items from "./../../components/_items";
import Moment from "react-moment";
import {dateToPacific} from "@/utils/dateTime";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  Confirm,
  Container,
  Form,
  Grid,
  Message,
  Progress,
} from "semantic-ui-react";
import validate from "validate.js";
import _ from "lodash";

const getOptions = (tagsArray) => {
  const tagsList = Object.keys(tagsArray).reduce((acc, el) => {
    acc.push({ key: el, value: tagsArray[el], text: tagsArray[el] });
    return acc;
  }, []);
  return tagsList;
};
class VideoInfo extends React.Component {
  VALIDATION_CONFIG = {
    status: {
      presence: false,
    },
    name: {
      presence: true,
    },
    description: {
      presence: true,
    },
    tags: {
      presence: true,
    },
  };
  staticValues = {
    listColumns: [
      { name: "Name", key: "name" },
      { name: "Type", key: "itemType" },
      { name: "Actions", key: "action", width: 2 },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: {},
      videos: {},
      file: {},
      loading: false,
      data: {},
      id: "",
      // tags: [],
      isUploading: false,
      uploadProgress: 0,
      redirectTo: false,
      options: [],

      // id: props.match.params.id,
      assignedItems: null,
      isSaving: false,
      validationMsg: {},
      showError: false,
      errorMsg: null,
      popupWarning: false,
      searchValue: ""
    };
  }

  transformResponse = (request) => ({
    action: "UPDATE_PACKAGE",
    name: request.name,
    description: request.description,
    status: request.status,
    createdBy: request.createdBy,
    creationDate: moment(request.creationDate).format(),
    modifiedBy: request.modifiedBy,
    modifiedDate: request.modifiedDate
      ? moment(request.modifiedDate).format()
      : null,
    itemsSimple: request.items,
    items: request.items,
  });

  componentDidMount() {
    const { data } = this.state;
    const id = get(this.props, ["match", "params", "id"]);
    this.fetchVideos();

    if (id > 0) {
      // Need to add this constants file
      const { URL_ADD_VIDEOS_DIR } = Constants.App;
      const params = { params: { id } };
      const instance = Api.AxiosInstance.getInstance();
      instance.get(`${URL_ADD_VIDEOS_DIR}/single`, params).then((resp) => {
        this.setState({
          data: update(data, { $set: this.transformResponse(resp.data) }),
          isLoaded: true,
        });
      });
    }
    this.getTagsFromServer();
  }
  
  getTagsFromServer = () => {
    // call some Api to get the data

    Api.AxiosInstance.getInstance()
      .get("tags/list",
      {
        params: {
          name: this.state.searchValue
        }
      })
      .then((resp) => {
        const mockTags = resp.data;
        const stateOptions = _.map(mockTags, (state, index) => ({
          key: mockTags[index].id,
          text: mockTags[index].name,
          value: mockTags[index],
        }));
        this.setState({ options: stateOptions });
      })
      .catch((error) => {
        this.setState({
          apiError: "Failed to get tags",
          isUploading: false,
        });
      });
  };
  
  onTagType = (event) => {
    if (event.target.value.length <= 0) {
      this.setState({ searchValue: "" }, () => this.getTagsFromServer());
    } else if (event.target.value.length <= 15) {
      this.setState({ searchValue: event.target.value}, () => this.getTagsFromServer());
    }
  };
  
  fetchVideos = () => {
    const id = get(this.props, ["match", "params", "id"]);

    const { videos } = this.state;
    const { URL_VIDEOS } = Constants.App;
    const params = { params: { parent_id: id } };
    const instance = Api.AxiosInstance.getInstance();
    instance.get(`${URL_VIDEOS}/`, params).then((resp) => {
      
      this.setState({
        videos: resp.data[0],
        id: resp.data[0].id,
        name: resp.data[0].name,
        description: resp.data[0].description,
        isLoaded: true,
        createdBy: resp.data[0].createdBy,
        creationDate: resp.data[0].creationDate,
        modifiedBy: resp.data[0].modifiedBy,
        modifiedDate: resp.data[0].modifiedDate,
        // filename: resp.data[0].filename,
        // filesize: resp.data[0].filesize,
        file: { name: resp.data[0].filename },
      });
      
      let tags = []
      
      // Fetching all tags
      if(resp.data[0].tagIds){
        resp.data[0].tagIds.forEach(element => {
          const p = { params: { id: element } };
          instance.get(`${Constants.App.URL_ADMIN_TAGS_INFO}/`, p).then((resp) => {
            tags.push(this.state.options.find((op) => resp.data.id == op.key).value);
            this.setState({tags: tags});
          });
        });
      }
      
    });
  };
  mapData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);

        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  showpopupWarning = () => {
    if (!this.validate()) {
      this.setState({ loading: false });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  }; 

  onChange = (event, eventData) => {
    const { videos } = this.state;
    const { name, value } = eventData;
    this.setState({
      [name]: value,
    });
    // this.setState({ videos: update(videos, { [name]: { $set: value } }) });
  };

  redirectToParent = () => {
    // Temporary hack to redirect to parent need to send baseUrl via props
    this.setState({
      redirectTo: "/videos",
    });
  };

  validate = () => {
    let result = validate(
      removeEmptyKeys(this.state.data),
      this.VALIDATION_CONFIG
    );
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };

  saveDetails = () => {
    const id = get(this.props, ["match", "params", "id"]);
    const { data } = this.state;
    this.setState({ loading: true });
    // validate() will return true incase all fields are valid.
    if (!this.validate()) return;
    const params = { params: { id } };
    let url = Constants.App.URL_ADD_VIDEOS_DIR;
    // Intentionally trunctating data ..
    let body = {
      // action: "UPDATE_PACKAGE",
      name: data.name,
      description: data.description,
      status: data.status,
    };
    this.setState({ isSaving: true });
    const instance = Api.AxiosInstance.getInstance();
    instance
      .post(url, body, params)
      .then((resp) => {
        this.setState({ isSaving: false }, () => {
          this.redirectToParent();
          // need to change this into packages
        });
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ isSaving: false, showError: true, errorMsg });
      });
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };
  updatePackages = () => {
    const id = get(this.props, ["match", "params", "id"]);
    const { action, msg, selectedItem } = this.state;
    const params = { params: { id } };
    let itemIds = [];
    itemIds.push({ id: selectedItem["id"] });
    //selectedItem.map(item => itemIds.push({ id: item.id }));
    let url = Constants.App.URL_PACKAGE_DETAILS;
    let payload = { action: action, items: itemIds };
    Api.AxiosInstance.getInstance()
      .post(url, payload, params)
      .then((resp) => {
        this.fetchAssignedItems();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: msg,
        });
      });
  };
  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;

    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  validate = () => {
    let result = validate(removeEmptyKeys(this.state), this.VALIDATION_CONFIG);
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };
  saveTemplate = () => {
    this.setState({ loading: true });
    const parentId = get(this.props, ["match", "params", "id"]);
    // validate() will return true incase all fields are valid.
    if (!this.validate()) {
      this.setState({ loading: false });
      return;
    }
    const { file, fileuuid, name, tags, description, id } = this.state;
    let tagIds = tags && tags.map((_tag) => {return _tag.id});
    const params = {
      params: {
        id,
      },
    };
    const data = {
      actiontype: "UPDATE_VIDEO",
      // parentId: parentId,
      description,
      name,
      tagIds,
      //options: [],

      status: "ACTIVE",
      fileuuid,
      filename: file.name,
      filetype: file.type,
      filesize: file.size,
    };

    const { URL_VIDEOS } = Constants.App;
    Api.AxiosInstance.getInstance()
      .post(URL_VIDEOS, data, params)
      .then((resp) => {
        this.setState({ file: resp.data, loading: false }, () =>
          this.redirectToParent()
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };
  onChange = (event, eventData) => {
    const { name, value } = eventData;
    this.setState({ [name]: value });
  };
  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleDropDownChange = (e, { value }) => {
    this.setState({ tags: value });
  };

  onFileChange = (eventData) => {
    // if (!eventData) return;

    if (eventData.isUploading) {
      const { file, isUploading } = eventData;
      let { data } = this.state;

      if (file.name && file.type && file.size) {
        //data = update(data, { file: { $set: file } });
        this.setState({ isUploading, file });
      }
    } else if (typeof eventData.uploadProgress !== "undefined") {
      const { uploadProgress } = eventData;
      const isUploading = uploadProgress !== 100;
      this.setState({ isUploading, uploadProgress });
    } else if (typeof eventData.fileuuid !== "undefined") {
      const { fileuuid } = eventData;
      this.setState({ fileuuid });
    } else if (typeof eventData.errorMsg !== "undefined") {
      const { errorMsg } = eventData;
      this.setState({ errorMsg, showError: true, isUploading: false });
    }
  };
  handleRemove = (event) => {
  };
  render() {
    // const {
    //   data,
    //   name,
    //   description,
    //   status,
    //   items,
    //   tags,
    //   createdBy,
    //   creationDate,
    //   modifiedBy,
    //   modifiedDate,
    //   assignedItems,
    // } = this.state.videos;
    const {
      validationMsg,
      isSaving,
      loading,
      file,
      isUploading,
      uploadProgress,
      isLoaded,
      showError,
      errorMsg,
      handleRemove,
      fileuuid,
      name,
      description,
      tags,
      createdBy,
      creationDate,
      modifiedBy,
      modifiedDate,
    } = this.state;
    const metaInfo = {
      createdBy,
      creationDate,
      modifiedBy,
      modifiedDate,
    };

    if (!isLoaded) return <React.Fragment></React.Fragment>;
    return (
      <React.Fragment>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={12} computer={12}>
              <Container className="m0-im">
                <Form loading={loading} className="width-100">
                  <InputField
                    fluid
                    isRequired
                    label="Name"
                    name="name"
                    value={name}
                    errorMsg={validationMsg.name}
                    isError={validationMsg.name}
                    onChange={this.onChange}
                    mobile={16}
                    tablet={8}
                    computer={8}
                  />

                  <TextareaField
                    label="Description (maximum 250 characters allowed)"
                    name="description"
                    isRequired
                    value={description}
                    onChange={this.onChange}
                    errorMsg={validationMsg.description}
                    isError={validationMsg.description}
                    mobile={16}
                    tablet={8}
                    computer={8}
                    maxLength={250}
                  />
                  <Form.Field required={true} error={validationMsg.tags}>
                    <label>Tags</label>

                    <Form.Dropdown
                      id="document-dropdown-tags"
                      required
                      name="tags"
                      options={this.state.options}
                      placeholder="Add Tags"
                      search
                      selection
                      multiple
                      value={tags}
                      //defaultValue={this.state.options}
                      onSearchChange={(e) => this.onTagType(e) }
                      onChange={this.handleDropDownChange}
                      mobile={16}
                      tablet={8}
                      computer={8}
                    />
                    <ErrorMessage
                      isError={validationMsg.tags}
                      errorMsg={validationMsg.tags}
                    />
                  </Form.Field>
                  <FileField
                    errorMsg="You have not selected any video."
                    isError={validationMsg && validationMsg.files}
                    label="Upload New Video (Previous video will be overwritten by the new one)"
                    name="files"
                    files={file ? [file] : []}
                    onChange={Uploader(this.onFileChange)}
                    accept=".MP4, .MOV, .MPEG, .WEBM, .FLV"
                    multipleSelection={false}

                    // isRequired
                  />

                  {isUploading && (
                    <Progress percent={uploadProgress} indicating />
                  )}
                  <Button
                    type="submit"
                    // disabled={!fileuuid && !showError}
                    onClick={this.showpopupWarning}
                    className="button-primary"
                  >
                    Save
                  </Button>
                  <Button
                    className="button-basic"
                    type="reset"
                    // disabled={}
                    onClick={(e) => {
                      e.preventDefault();
                      this.redirectToParent();
                    }}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Form>

                <Confirm
                  open={this.state.deleteWarning}
                  onCancel={this.hideDeleteWarning}
                  onConfirm={() => this.hideDeleteWarning(this.updatePackages)}
                  content={`Are you sure you want to delete this Item?`}
                />
                {this.state.redirectTo && (
                  <Redirect to={this.state.redirectTo} />
                )}
              </Container>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={4} computer={4}>
              {metaInfo && (
                <Card className="m-0">
                  <Card.Content
                    className="dark-blue-background-shade"
                    header={
                      <React.Fragment>
                        <Card.Header>Video Information</Card.Header>
                      </React.Fragment>
                    }
                  />
                  <Card.Content>
                    <Message style={{ fontSize: "11px" }}>
                      {metaInfo.createdBy && (
                        <Message.Content style={{ marginBottom: "10px" }}>
                          <Message.Header>Created By: </Message.Header>
                          <div style={{ marginLeft: "5px" }}>
                            {metaInfo.createdBy}
                          </div>
                        </Message.Content>
                      )}
                      {metaInfo.creationDate && (
                        <Message.Content style={{ marginBottom: "10px" }}>
                          <Message.Header>Created On: </Message.Header>
                          <div style={{ marginLeft: "5px" }}>
                            <>{dateToPacific(metaInfo.creationDate,"MM/DD/YYYY hh:mm a")}</>                        
                          </div>
                        </Message.Content>
                      )}
                      {metaInfo.modifiedBy && (
                        <Message.Content style={{ marginBottom: "10px" }}>
                          <Message.Header>Modified By: </Message.Header>
                          <div style={{ marginLeft: "5px" }}>
                            {metaInfo.modifiedBy}{" "}
                          </div>
                        </Message.Content>
                      )}
                      {metaInfo.modifiedDate && (
                        <Message.Content style={{ marginBottom: "10px" }}>
                          <Message.Header>Modification Date: </Message.Header>
                          <div style={{ marginLeft: "5px" }}>
                          <>{dateToPacific(metaInfo.modifiedDate,"MM/DD/YYYY hh:mm a")}</>    
                          </div>
                        </Message.Content>
                      )}
                    </Message>
                  </Card.Content>
                </Card>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.saveTemplate)}
          content="Are you sure you want to update video?"
        />
      </React.Fragment>
    );
  }
}
export default VideoInfo;
