import Config from "@/config/constants";
import {redirectToSubmitATicketURL} from "../../api/newticket";

const baseUrl = "#";

export default {
    name: "Submit a Request",
    configKey: "newticket_menu_item_title",
    path: baseUrl,
    component: () => {
        console.log("not a react component")
    },
    onRouteClick: () => {
        return redirectToSubmitATicketURL();
    },
    isPrivateRoute: true,
    accessRoles: Config.AccessRole.CUSTOMER,
    enableWithSupport: true
};
