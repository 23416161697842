const normalValidation = {
  name: {
    presence: true,
  },
  version: {
    presence: true,
  },
  filename: {
    presence: true,
    equality: {
      attribute: "fileType",
      message: "Only Plain Text or PDF files are supported.",
      comparator: function (name, type) {
        return (
          type.indexOf("text") !== -1 ||
          type.indexOf("doc") !== -1 ||
          type.indexOf("pdf") !== -1 ||
          type.indexOf("application/msword") !== -1
        );
      },
    },
  },
  userTypes: {
    presence: { allowEmpty: false }
  }
};

const editValidation = {
  ...normalValidation,
  filename: null,
}

export { normalValidation, editValidation };
