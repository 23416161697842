const normalValidation = {
  company_name: {
    presence: true,
  },
  email_from_name: {
    presence: true,
  },
  company_website_url: {
    presence: true,
  },
  websilo_site_name: {
    presence: true,
  },
  websilo_site_url: {
    presence: true,
  },
  inactivity_timeout: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 1,
      lessThanOrEqualTo:999
    }
  },
  registration_link_timeout: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 1,
      lessThanOrEqualTo:43200
    }
  },
  two_factor_renewal_cycle: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 1,
      lessThanOrEqualTo:999
    }

  },
  sms_expiry_time: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 1,
      lessThanOrEqualTo:999
    }
  },
  email_expiry_time:{
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 1,
      lessThanOrEqualTo:999
    }
  },
  approval_flow: {
    presence: false,
  },
  community_feature: {
    presence: false,
  },
  cross_group: {
    presence: false,
  },
  cross_group_friends: {
    presence: false,
  },
  account_renewal_cycle: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 1,
      lessThanOrEqualTo:999
    }
  },
  enforce_validation: {
    presence: false,
  },
  upload_location: {
    presence: false,
  },
  project_doc_upload_location: {
    presence: false,
  },
  upload_temp_location: {
    presence: false,
  },
  briefcase_upload_location: {
    presence: false,
  },
  license_upload_location: {
    presence: false,
  },
  license_expire_action: {
    presence: false,
  },
  nda_enforcement: {
    presence: false,
  },
  nda_upload_location: {
    presence: false,
  },
  nda_backup_upload_location: {
    presence: false,
  },
  nda_pending_reminder_interval: {
    presence: false,
  },
  nda_auto_reminder: {
    presence: false,
  },
  profile_http_path: {
    presence: false,
  },
  application_mode: {
    presence: false,
  },
  password_link_expr_time: {
    presence: false,
  },
  vp_sales_name: {
    presence: true,
  },
  restrictedDomains: {
    presence: true,
  },
};

export { normalValidation };
