import React from "react";
import RecentDownloads from "./components/recent-downloads";
import BriefcaseInbox from "./../briefcase/pages/briefcase-inbox";
import {Grid, Segment, Responsive, Modal, Confirm} from "semantic-ui-react";
import ReceivedFilesCard from "./components/received-files";
import WelcomePopup from "./components/welcome-popup";
import RecentUploads from "./components/recent-uploads";
import ZendeskTickets from "./components/zendesk-tickets";
import Articles from "./components/articles";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../store/actions";

const NdaCustomer = ({
  setGdprCheck,
  showGdpr,
  gdpr,
  ccpa,
  showCcpa,
  setCcpaCheck,
  userId,
}) => {
  const supportEnabled = useSelector(
    (state) => state.common.config.supportEnabled
  );
  const disabledHelpdeskNotif = useSelector(
      (state) => state.user.loggedin.disabledHelpdeskNotif
  );

  const dispatch = useDispatch();

  return (
    <Segment basic className="mt-2-im">
      <>
        <Grid centered>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={10} computer={12} largeScreen={12}>
              <RecentDownloads />
              <Responsive minWidth={1024}>
                <BriefcaseInbox
                  match={{ path: "", params: {}, paginate: false }}
                />
              </Responsive>
              <Responsive maxWidth={1023}>
                <ReceivedFilesCard />
              </Responsive>
 {/* <Responsive maxWidth={1023}> */}
              <RecentUploads />
              {/* </Responsive> */}
              {supportEnabled && (
                <>
                  <ZendeskTickets />
                  <Articles />
                </>
              )}
            </Grid.Column>
            {/* <Grid.Column mobile={16} tablet={6} computer={4} largeScreen={4}>
              <iframe
                width="100%"
                height="344"
                src="https://www.youtube.com/embed/nWJ-9n6w9eM"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Grid.Column> */}
          </Grid.Row>
        </Grid>
        <Modal
          header="GDPR policy"
          open={showGdpr}
          onClose={() => {
            // setGdprCheck();
          }}
          onActionClick={() => {
            setGdprCheck();
          }}
          closable={false}
          centered={true}
          size="large"
          content={
            <Segment>
              <div
                dangerouslySetInnerHTML={{
                  __html: gdpr?.gdpr_text,
                }}
              ></div>{" "}
            </Segment>
          }
          actions={[{ key: "warningDismiss", content: "Agree", primary: true }]}
        ></Modal>{" "}
        <Modal
          // basic
          header="CCPA policy"
          open={showCcpa}
          onClose={() => {
            // setCcpaCheck();
          }}
          onActionClick={() => {
            setCcpaCheck();
          }}
          closable={false}
          centered={true}
          size="large"
          content={
            <Segment>
              <div
                dangerouslySetInnerHTML={{
                  __html: ccpa?.ccpa_text,
                }}
              ></div>{" "}
            </Segment>
          }
          actions={[{ key: "warningDismiss", content: "Agree", primary: true }]}
        ></Modal>{" "}
      </>
      <WelcomePopup value="customer-popup" userId={userId} />
      <Confirm
          open={disabledHelpdeskNotif.state}
          size={"mini"}
          cancelButton={null}
          onConfirm={() => {
            dispatch({
              type: Actions.USER.DISABLED_HELPDESK_NOTIF,
              payload: '',
            });
          }}
          content={disabledHelpdeskNotif.message}
      />
    </Segment>
  );
};

export default NdaCustomer;
