import { CardviewMobile } from "@/common/cardview";
import Tableview from "@/common/tableview";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Confirm, Grid, Responsive, Segment } from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";
import { useDispatch, useSelector } from "react-redux";
import { getArticles } from "../../../../api/zendesk";
import {
  deleteArticles,
  setPageNumber,
} from "../../../../api/zendesk/articles";
import _ from "lodash";

export default (props) => {
  const store = useSelector((state) => state.zendeskReducer);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [sort, setSort] = useState("sequence,ASC");
  const [isDesc, setIsDesc] = useState(false);
  const [title, setTitle] = useState();
  const pagination = useSelector(
    (state) => state.zendeskReducer.pagination
  );
  const [messageModal, setMessageModal] = useState({
    message: "",
    open: false,
  });
  const dispatch = useDispatch();
  const stateRef = useRef();
  stateRef.current = isDesc;

  let [columns, setColumns] = useState({
    columns: [
      {
        name: "Sequence",
        key: "sequence",
        showTooltip: true,
        cb: () => {
          sortByHeader("sequence", "Sequence");
        },
      },
      {
        name: "Text To Display",
        key: "textToDisplay",
        showTooltip: true,
        cb: () => {
          sortByHeader("textToDisplay", "Text To Display");
        },
      },
      {
        name: "Last Updated By",
        key: "lastUpdatedBy",
        showTooltip: true,
        cb: () => {
          sortByHeader("lastUpdatedBy", "Last Updated By");
        },
      },
      {
        name: "Actions",
        key: "actions",
      },
    ],
  });

  let [responsive_columns, setResponsiveColumns] = useState({
    responsive_columns: [
      {
        name: "Sequence",
        key: "sequence",
        width: 2,
      },
      {
        name: "Text To Display",
        key: "textToDisplay",
        width: 2,
      },
      {
        name: "Last Updated By",
        key: "lastUpdatedBy",
      },
    ],
  });

  const openModal = (id) => {
    setDeleteWarning(true);
    setIdToDelete(id);
  };

  const sortByHeader = (key, header) => {
    setIsDesc((previousState) => !previousState);
    setTitle(header);
    let query = `${key},ASC`;
    if (!stateRef.current) {
      query = `${key},DESC`;
    }
    setSort(query);
  };

  useEffect(() => {
    let colProp = {
      columns: [],
    };
    let order = isDesc;
    columns.columns.map((obj) => {
      if (obj.name == title) {
        colProp.columns.push({
          ...obj,
          isDesc: !order,
          isActive: true,
        });
      } else
        colProp.columns.push({
          ...obj,
          isActive: false,
        });
    });
    setColumns(colProp);
  }, [isDesc, sort, title]);
  
  useEffect(() => {
    setIsDesc((previousState) => !previousState);
  }, []);

  const closeModal = () => {
    setDeleteWarning(false);
    setIdToDelete(null);
  };

  const closeMessageModal = () => {
    setMessageModal(false);
  };

  const deleteArticle = async () => {
    dispatch(deleteArticles(idToDelete, pagination.page));
    closeModal();
  };

  useEffect(() => {
    getArticles(pagination.page, sort)(dispatch);
    if(!_.isEmpty(store.articles))
      normalizedData = transformData(store.articles)
  }, [sort, pagination.page, isDesc, title]);

  const onPageChange = (e) => {
    dispatch(setPageNumber(e.page));
  };

  const transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        let adminActions = (
          <React.Fragment>
            <Link to={"/admin/zendesk/articles/edit/" + row.id}>
              <span
                className="icon-edit"
                style={{
                  margin: "3px",
                }}
                title="Edit"
                name="edit"
              />
            </Link>
            <span
              className="icon-delete"
              onClick={(e) => openModal(row.id)}
              style={{
                margin: "3px",
              }}
              title="Remove"
              name="remove"
            />
            <a href={el.url}>
              <span
                className="icon-external-link2"
                style={{
                  margin: "3px",
                }}
                title="Open link"
                name="open"
              />
            </a>
          </React.Fragment>
        );
        row.actions = adminActions;
        row.creationDate = (
          <>{dateToPacific(el.creationDate, "MM/DD/YYYY hh:mm a")}</>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  let normalizedData =
    !_.isEmpty(store.articles) && transformData(store.articles);
  return (
    <React.Fragment>
      <Segment className="base-segment-wrapper">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
              <div className="header">
                <div className="side-marker" />
                Articles
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
              <div className="control-action-wrapper">
                <>
                  <Link to={"/admin/zendesk/articles"}>
                    <button className="button-basic">Add New</button>
                  </Link>
                </>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div className="empty-height" />

        <Grid>
          <Grid.Row className="common-filter-wrapper">
            <Grid.Column
              mobile={16}
              tablet={4}
              computer={3}
              largeScreen={3}
            ></Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="empty-height" />
        <div className="row">
          <div className="col-md-12">
            <span
              id="msgspan"
              style={{
                color: "black",
              }}
            ></span>
          </div>
        </div>

        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination">
            <Tableview
              paginate
              data={normalizedData && normalizedData}
              columns={columns.columns}
              onPaginationChange={(e) => onPageChange(e)}
              noDataMessage="No Articles Found"
              loading={store.loading}
            />
          </div>
        </Responsive>

        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={normalizedData && normalizedData}
            columns={responsive_columns.responsive_columns}
            paginate
            onPaginationChange={(e) => onPageChange(e)}
          />
        </Responsive>
      </Segment>
      <Confirm
        open={deleteWarning}
        onCancel={() => closeModal()}
        onConfirm={() => deleteArticle()}
        content="Are you sure you want to delete this Article?"
      />
    </React.Fragment>
  );
};
