import React, { createRef } from "react";
import PropTypes from "prop-types";
import { Button, Container, Form, Grid } from "semantic-ui-react";
import update from "immutability-helper";
import GroupListing from "./group-listing";
import GroupCreation from "./group-creation"

class GroupSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIds: [],
      recipients: this.props.recipients,
      showGroupsListing: false,
      showCreateGroup: false,
    };
  }

  componentDidMount() {
    this.setState({
      selectRef: createRef()
    })
  }

  onSelectionChange = (event, t) => {
    const selections = this.state.selectRef.current.selectedOptions;
    const selectionCount = selections.length;
    const selectedIds = [];

    if (selectionCount) {
      for (let index = 0 ; index < selectionCount ; index ++){
        selectedIds.push(selections[index].value);
      }
    }
    this.setState({ selectedIds });
  };

  onRemoveGroup = (event) => {
    const { selectedIds } = this.state;
    const { recipients } = this.props;
    const newRecipients = recipients.filter((recipient) => selectedIds.indexOf(recipient.id.toString()) === -1);
    if (selectedIds.length === 0 || recipients.length === newRecipients.length) {
      this.setState({ errorMsg: "No data was selected.", showError: true });
      return;
    }
    this.setState(
      {
        recipients: update(recipients, { $set: newRecipients }),
      },
      this.notifyLatestContent
    );
  };

  onSelectGroup = () => {
    this.setState({ showGroupsListing: true });
  };

  onCreateGroup = () => {
    this.setState({showCreateGroup: true})
  }
  onDismissCreateGroup = () => {
    this.setState({showCreateGroup: false})
  }
  onDismissGroupListing = () => {
    this.setState({ showGroupsListing: false });
  };
  
  onSaveGroupListingOld = (groups) => {
    const { recipients } = this.state;
    this.setState(
      {
        showGroupsListing: false,
        recipients: update(recipients, { $push: groups }),
      },
      this.notifyLatestContent
    );
    
  };
  onSaveGroupListing = (groups) => {
    // const { recipients } = this.props;

    const { recipients } = this.state;
    this.setState({
      showGroupsListing: false,
    });
    groups.map((group) => {
      const found = recipients.some((el) => el.id === group.id);
      // if (!found) arr.push({ id, username: name });
      if (!found) {
        recipients.push(group);
        this.setState(
          {
            recipients: update(recipients, { $set: recipients }),
          },
          this.updateLatestContent
        );
      }
    });


  };

  updateLatestContent = () => {
    const { onChange } = this.props;
    const { recipients } = this.state;
    if (onChange instanceof Function) {
      onChange(recipients);
    }
  };
  notifyLatestContent = () => {
    console.log("recipients are,,,", this.state.recipients);
    console.log("props are reci]", this.props.recipients);
    const { onChange } = this.props;
    const { recipients } = this.state;
    if (onChange instanceof Function) {
      onChange(recipients);
    }
  };

  render() {
    const { showGroupsListing , showCreateGroup} = this.state;
    const {
      label,
      isRequired,
      selectionType,
      recipients,
      entity,
      entityId,
    } = this.props;

    return (
      <React.Fragment>
        <Form>
          <Form.Field required={isRequired}>
            {label && <label>{label}</label>}
            <select size={3} onChange={this.onSelectionChange} multiple={true} className="multi-select" ref={this.state.selectRef}>
              {recipients.map((rec) => (
                <option key={rec.id} value={rec.id}>
                  {rec.name}
                </option>
              ))}
            </select>
          </Form.Field>
          {/*<Grid columns="2">
              <Grid.Row>
                <Grid.Column>
                  <Button basic color="blue" onClick={ this.onSelectGroup }>Select Groups</Button>
                </Grid.Column>
                <Grid.Column>
                  <Button basic color="blue" onClick={ this.onRemoveGroup }>Remove Selected Groups</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>*/}
          <Grid columns="1">
            <Grid.Row>
              <Grid.Column>
                <div style={{ float: "right" }}>
                  <button className="button-basic" onClick={this.onCreateGroup}>
                    Create New Group
                  </button>
                  <button className="button-basic" onClick={this.onSelectGroup}>
                    Select Group
                  </button>
                  <button className="button-basic" onClick={this.onRemoveGroup}>
                    Remove Selected Group
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <GroupListing
          show={showGroupsListing}
          onDismiss={this.onDismissGroupListing}
          onSave={this.onSaveGroupListing}
          selectionType={selectionType}
          entity={entity}
          entityId={entityId}
        />
        <GroupCreation
          show={showCreateGroup}
          onDismiss={this.onDismissCreateGroup}         
          onCreateGroup={this.onSaveGroupListing}
        />
      </React.Fragment>
    );
  }
}

GroupSelection.propTypes = {
  /** Text label */
  label: PropTypes.string,
  /** Shows the red asteric in label text */
  isRequired: PropTypes.bool,
  /** A callback which is fired whenever the selection is changed. */
  onChange: PropTypes.func.isRequired,
  /** single-select = radio buttons, multi-select = checkboxes */
  selectionType: PropTypes.oneOf(["single-select", "multi-select"]),
};

export default GroupSelection;
