const GET_ALL_INDEXES_INITIATE = "GET_ALL_INDEXES_INITIATE";
const GET_ALL_INDEXES_SUCCESS = "GET_ALL_INDEXES_SUCCESS";
const GET_ALL_INDEXES_FAILURE = "GET_ALL_INDEXES_FAILUREE";
const SYNC_INDICES_INITIATE = "SYNC_INDICES_INITIATE";
const SYNC_INDICES_SUCCESS = "SYNC_INDICES_SUCCESS";
const SYNC_INDICES_FAILURE = "SYNC_INDICES_FAILURE";

export default {
  GET_ALL_INDEXES_INITIATE,
  GET_ALL_INDEXES_SUCCESS,
  GET_ALL_INDEXES_FAILURE,
  SYNC_INDICES_INITIATE,
  SYNC_INDICES_SUCCESS,
  SYNC_INDICES_FAILURE,
};
