import {
  CountryField,
  DropdownField,
  ErrorMessage,
  GroupTypeField,
  InputField,
  PhoneField,
  StateField,
  TextareaField,
  UserTypeField,
  CountryCode,
} from "@/common/Form";
import { default as React } from "react";
import ReactPasswordStrength from "react-password-strength";
import { Link } from "react-router-dom";
import { Form, Grid, Input, Message, Checkbox, Icon } from "semantic-ui-react";
import _ from "lodash";

const updateNotificationFrequencyOptions = [
  { key: "Never", value: "NONE", text: "Never" },
  { key: "As soon as possible", value: "0", text: "As soon as possible" },
  { key: "Daily", value: "DAILY", text: "Daily" },
  { key: "Weekly", value: "WEEKLY", text: "Weekly" },
  { key: "Monthly", value: "MONTHLY", text: "Monthly" },
];
const statusOptions = [
  { key: "Active", text: "Active", value: "ACTIVE" },
  { key: "Disabled", text: "Disabled", value: "DISABLED" },
];
const newUserStatusOptions = [
  { key: "Active", text: "Active", value: "ACTIVE" },
  { key: "Disabled", text: "Disabled", value: "DISABLED" },
  {
    key: "Verification_Pending",
    text: "Verification Pending",
    value: "VERIFICATION_PENDING",
  },
];
const techSupportOptions = [
  { key: "ENABLED", text: "ENABLED", value: 1 },
  { key: "DISABLED", text: "DISABLED", value: 0 },
];
const UserInfoForm = ({
  validateForm,
  apiError,
  formDisabled,
  onDataChange,
  onByteChange,
  onEmailChange,
  validateEmail,
  validatePassword,
  onBlur,
  onKeyup,
  form,
  userId,
  data,
  onPasswordChange,
  validationMsg,
  usernameError,
  emailError,
  config,
  backLink,
  msg,
  formType,
  errorTitle,
  msgSpanColor,
  showGdpr,
  showCcpa,
  onPhoneChange,
  formatphone,
  countryCodeChange,
  jwtDecoded,
  query,
  pageNum,
  isOAuth
}) => {
  const statusDropDownOptions =
    userId && form.status !== "VERIFICATION_PENDING"
      ? statusOptions
      : newUserStatusOptions;
    //form.firstName=!_.isEmpty(jwtDecoded) && jwtDecoded.firstName || "";
    //form.lastName=!_.isEmpty(jwtDecoded) && jwtDecoded.lastName || "";
  return (
    <Form
      error={apiError}
      onSubmit={validateForm}
      // loading={formDisabled}
      autocomplete="off"
    >
      <Message error header={errorTitle} content={apiError} />
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <InputField
              label="Username"
              errorMsg={validationMsg.username || usernameError}
              isError={!!validationMsg.username || usernameError}
              name="username"
              onBlur={onBlur}
              onKeyup={onKeyup}
              onChange={onDataChange}
              value={form.username}
              isRequired
              maxlength={32}
              //disabled={!_.isEmpty(jwtDecoded) && jwtDecoded.username || userId}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <InputField
              label={config.maps.company_name + ` Contact Name`}
              errorMsg={validationMsg.contactName}
              isError={!!validationMsg.contactName}
              name="contactName"
              onChange={onDataChange}
              onBlur={onBlur}
              maxlength={32}
              value={form.contactName}
            />
          </Grid.Column>
        </Grid.Row>
        {!userId && !isOAuth && (
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Form.Field
                required={true}
                width="16"
                error={
                  !!validationMsg.password || !!validationMsg.passwordScore
                }
              >
                <label>Password</label>
                <ReactPasswordStrength
                  minLength={5}
                  onBlur={onBlur}
                  minScore={2}
                  scoreWords={["weak", "okay", "good", "strong", "stronger"]}
                  changeCallback={onPasswordChange}
                  inputProps={{ autoComplete: "off", name: "password" }}
                  userInputs={Object.values(
                    Object.assign({}, form, {
                      password: null,
                      confirmPassword: null,
                    })
                  )}
                />
                <ErrorMessage
                  isError={validationMsg.password}
                  errorMsg={validationMsg.password}
                />
                <ErrorMessage
                  isError={validationMsg.passwordScore}
                  errorMsg={
                    validationMsg.passwordScore &&
                    validationMsg.passwordScore[0]
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Form.Field
                required={true}
                width="16"
                error={!!validationMsg.confirmPassword}
              >
                <label>Confirm Password</label>

                <Input
                  type="password"
                  name="confirmPassword"
                  onChange={onDataChange}
                  value={form.confirmPassword}
                  onBlur={onBlur}
                />
                <ErrorMessage
                  isError={validationMsg.confirmPassword}
                  errorMsg={
                    validationMsg.confirmPassword &&
                    validationMsg.confirmPassword[0]
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={6}>
            <InputField
              label="First Name"
              errorMsg={validationMsg.firstName}
              isError={!!validationMsg.firstName}
              name="firstName"
              onChange={onDataChange}
              onBlur={onBlur}
              value={form.firstName}
              maxlength={32}
              isRequired
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={4}>
            <InputField
              label="Middle Initial"
              errorMsg={validationMsg.middleInitial}
              isError={!!validationMsg.middleInitial}
              name="middleInitial"
              onChange={onDataChange}
              onBlur={onBlur}
              value={form.middleInitial}
              maxlength="1"
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={6}>
            <InputField
              label="Last Name"
              errorMsg={validationMsg.lastName}
              isError={!!validationMsg.lastName}
              name="lastName"
              onChange={onDataChange}
              onBlur={onBlur}
              value={form.lastName}
              maxlength={32}
              isRequired
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <InputField
              label="Company"
              errorMsg={validationMsg.company}
              isError={!!validationMsg.company}
              name="company"
              onChange={onDataChange}
              maxlength={32}
              onBlur={onBlur}
              value={form.company}
              isRequired
              //disabled={!_.isEmpty(jwtDecoded) && jwtDecoded.company || company}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <InputField
              label="Title"
              errorMsg={validationMsg.title}
              isError={!!validationMsg.title}
              name="title"
              onChange={onDataChange}
              onBlur={onBlur}
              maxlength={32}
              value={form.title}
              //isRequired
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <InputField
              label="City"
              errorMsg={validationMsg.city}
              isError={!!validationMsg.city}
              name="city"
              onChange={onDataChange}
              onBlur={onBlur}
              value={form.city}
              maxlength={32}
              isRequired
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <InputField
              label="Street Address"
              errorMsg={validationMsg.address}
              isError={!!validationMsg.address}
              name="address"
              onChange={onDataChange}
              onBlur={onBlur}
              value={form.address}
              isRequired
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <CountryField
              label="Country"
              errorMsg={validationMsg.countryCode}
              isError={!!validationMsg.countryCode}
              name="countryCode"
              onChange={onDataChange}
              value={form.countryCode}
              isRequired
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <StateField
              label="State"
              errorMsg={validationMsg.stateCode}
              isError={!!validationMsg.stateCode}
              name="stateCode"
              onChange={onDataChange}
              value={form.stateCode}
              country={form.countryCode}
              isRequired
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={5}
                  computer={5}
                  className="col-country-code"
                >
                  <CountryCode
                    label="Country Code"
                    errorMsg={validationMsg.code}
                    isError={!!validationMsg.code}
                    name="code"
                    onChange={onDataChange}
                    value={form.code}
                    width={16}
                    isRequired
                  />{" "}
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={11}
                  computer={11}
                  className="col-mobile-phone"
                >
                  <PhoneField
                    label="Mobile Phone"
                    errorMsg={validationMsg.phone}
                    isError={!!validationMsg.phone}
                    name="phone"
                    onChange={onPhoneChange}
                    onBlur={onBlur}
                    value={form.phone}
                    country={form.countryCode}
                    onKeyPress={(evt) =>
                      ["e", "E", "+", "-", "[a-z]"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    isRequired
                    width={16}
                    placeholder="Mobile Phone"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={8}>
            {/* <PhoneField
              label="Mobile Phone"
              errorMsg={validationMsg.cellPhone}
              isError={!!validationMsg.cellPhone}
              name="cellPhone"
              onChange={onDataChange}
              onBlur={onBlur}
              value={form.cellPhone}
              country={form.countryCode}
            /> */}
            <InputField
              label="Email"
              errorMsg={validationMsg.email || emailError}
              isError={!!validationMsg.email || emailError}
              name="email"
              onChange={onDataChange}
              onBlur={onBlur}
              onKeyup={onKeyup}
              value={form.email}
              // disabled={userId}
              disabled={!_.isEmpty(jwtDecoded) && jwtDecoded.username || userId}
              isRequired
              placeholder="You must register with a corporate email address"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            {/* <InputField
              label="Email"
              errorMsg={validationMsg.email || emailError}
              isError={!!validationMsg.email || emailError}
              name="email"
              onChange={onDataChange}
              onBlur={onBlur}
              onKeyup={onKeyup}
              value={form.email}
              disabled={userId}
              isRequired
              placeholder="You must register with a corporate email address"
            /> */}
            <InputField
              label="Postal Code / Zip Code"
              errorMsg={validationMsg.postalCode}
              isError={!!validationMsg.postalCode}
              name="postalCode"
              onChange={onDataChange}
              onBlur={onBlur}
              value={form.postalCode}
              maxlength="10"
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            {/* <InputField
              label="Postal Code / Zip Code"
              errorMsg={validationMsg.postalCode}
              isError={!!validationMsg.postalCode}
              name="postalCode"
              onChange={onDataChange}
              onBlur={onBlur}
              value={form.postalCode}
              maxlength="10"
            /> */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <TextareaField
              label="Project Details (maximum 250 characters allowed)"
              errorMsg={validationMsg.projectDetails}
              isError={!!validationMsg.projectDetails}
              name="projectDetails"
              maxLength={250}
              onBlur={onBlur}
              onChange={onDataChange}
              value={form.projectDetails}
              rows="5"
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            {formType == "AdminRegisteration" && (
              <TextareaField
                label="Remarks (maximum 250 characters allowed)"
                errorMsg={validationMsg.remarks}
                isError={!!validationMsg.remarks}
                name="remarks"
                maxLength={250}
                onBlur={onBlur}
                onkeyup={onBlur}
                onChange={onDataChange}
                value={form.remarks}
                rows="5"
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {/** TODO: enable this feature once we're done with cron job Implementation:
            *** disabling as per basecamp todo
            :https://3.basecamp.com/3953713/buckets/19656542/todos/35873208624****
           
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <DropdownField
              errorMsg={validationMsg.updateNotificationFrequency}
              isError={validationMsg.updateNotificationFrequency}
              label="Item(s) Update Notification Frequency"
              id="updateNotificationFrequency"
              name="updateNotificationFrequency"
              value={form.updateNotificationFrequency}
              onChange={onDataChange}
              options={updateNotificationFrequencyOptions}
              isRequired
            />
          </Grid.Column>*/}
          {formType == "AdminRegisteration" && (
            <>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                <DropdownField
                  errorMsg={validationMsg.status}
                  isError={validationMsg.status}
                  label="Status"
                  name="status"
                  value={form.status}
                  onChange={onDataChange}
                  options={statusDropDownOptions}
                  isRequired
                />
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                <InputField
                  label="Account Renewal Cycle"
                  errorMsg={validationMsg.accountRenewalCycle}
                  isError={validationMsg.accountRenewalCycle}
                  name="accountRenewalCycle"
                  value={form.accountRenewalCycle}
                  isRequired={false}
                  onKeyPress={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder="Only Integer Value eg: 2 (Months)"
                  onChange={onDataChange}
                  onBlur={onBlur}
                />
              </Grid.Column>
            </>
          )}
          {
            (form.userType ==332) &&
            <Grid.Column mobile={16} tablet={16} computer={4}>
                <DropdownField
                  label="Support"
                  name="techSupport"
                  value={form.techSupport}
                  onChange={onDataChange}
                  options={techSupportOptions}
                  isRequired
                />
              </Grid.Column>
        }
        </Grid.Row>
        {formType == "AdminRegisteration" && (
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <UserTypeField
                value={form.userType}
                label="User Type"
                name="userType"
                errorMsg={validationMsg.userType}
                isError={validationMsg.userType}
                onChange={onDataChange}
                isRequired
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <GroupTypeField
                value={form.userGroup}
                label="User Group"
                name="userGroup"
                errorMsg={validationMsg.userGroup}
                isError={validationMsg.userGroup}
                onChange={onDataChange}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {!userId && formType !== "AdminRegisteration" && (
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Form.Field error={validationMsg.agreed}>
                {/* <label>
                  I agree to the privacy policy and also understand the GDPR &
                  CCPA policy
                  <span onClick={showGdpr}>
                    Read our <a title="GDPR Policy"> GDPR Policy</a>{" "}
                    {form.gdprCheck ? (
                      <Icon
                        title="Agreed Policy"
                        name="check circle"
                        color="green"
                      />
                    ) : (
                      <Icon name="circle outline" color="red" />
                    )}
                  </span>{" "}
                  <span>
                    and{""}{" "}
                    <a title="CCPA Policy" onClick={showCcpa}>
                      {" "}
                      CCPA Policy
                    </a>{" "}
                    {form.ccpaCheck ? (
                      <Icon
                        title="Agreed Policy"
                        name="check circle"
                        color="green"
                      />
                    ) : (
                      <Icon name="circle outline" color="red" />
                    )}
                  </span>
                    </label> */}
                <div className="label-signup-policy">
                  <Checkbox
                    name="agreed"
                    checked={form.agreed}
                    // isRequired
                    onChange={(event, data) =>
                      onByteChange(event, {
                        name: data.name,
                        value: data.checked,
                      })
                    }
                  />

                  <span>
                    {" "}
                    I agree to Groq's{" "}
                    <a
                      href="https://groq.com/legal/privacy-policy/"
                      target="_blank"
                    >
                      privacy policy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://groq.com/legal/terms-and-conditions/"
                      target="_blank"
                    >
                      terms & conditions
                    </a>{" "}
                    <ErrorMessage
                      isError={validationMsg.agreed}
                      errorMsg={validationMsg.agreed}
                    />
                  </span>
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        )}
        <div className="ui divider"></div>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <button type="submit" className="button-primary" disabled={formDisabled}>
              Save
            </button>
            <Link to={{pathname:backLink,query:query,pageNum:pageNum}} >
              <button className="button-basic">Cancel</button>
            </Link>
            {msg && (
              <span
                style={{
                  color: msgSpanColor ? msgSpanColor : "red",
                  marginLeft: "5px",
                }}
              >
                {msg}
              </span>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default UserInfoForm;
