const normalValidation = {
  pdf_watermark_all: {
    presence: true,
  },
  pdf_watermark_line1: {
    presence: true,
    length: {
      maximum: 64,
    },
  },
  pdf_password_protect: {
    presence: true,
  },
  pdf_allow_printing: {
    presence: true,
  },
  pdf_allow_degraded_printing: {
    presence: true,
  },
  pdf_allow_modify_content: {
    presence: true,
  },
  pdf_allow_copy: {
    presence: true,
  },
  pdf_allow_modify_annotations: {
    presence: true,
  },
  pdf_allow_fill_in: {
    presence: true,
  },
  pdf_allow_screen_readers: {
    presence: true,
  },
  pdf_allow_assembly: {
    presence: true,
  },
  pdf_watermark_opacity: {
    presence: true,
    format: {
      pattern: "^(0(\.[0-9]{1,2})?|1(\.0)?)$",
      flags: "i",
      message: "can only be Between (0 - 1)"
    }
  },
  pdf_watermark_color_rgb: {
    presence: true,
    format: {
      pattern: "#[a-fA-F0-9]{6}",
      flags: "i",
      message: "can only be a hex value (with a #)"
    }
  },
};

export { normalValidation };
