export default {
  // Obsoleted placeholders

  // CompanyAddress2: "Company Address 2",
  // InfoBeingDisclosed: "Info Being Disclosed",
  // Date: "Sign Date",
  // Date_2: "Groq Sign Date",
  // Email: "Signer Email",

  CompanyName: "Company Name",
  By_2: "Groq Signer Signature",
  By: "Signer Signature",
  Title_2: "Groq Signer Title",
  Title: "Signer Title",
  CompanyAddress1: "Company Address",
  EffectiveDate: "Effective Date",
  Name: "Signer Name",
  Name_2: "Groq Signer Name",
};
