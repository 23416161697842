import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, Label, Popup, Icon } from 'semantic-ui-react';
import ErrorMessage from './error-message';

const InputField = ({
  errorMsg,
  isError,
  isRequired,
  label,
  name,
  onChange,
  value,
  width,
  id,
  type,
  disabled,
  placeholder,
  onKeyPress,
  maxlength,
  onInput,
  onBlur,
  onKeyup,
  popup,
  content,
  onFocus,
  defaultValue
}) => {
  return (
    <Form.Field required={isRequired} width={width} error={isError}>
      <label>
        {' '}
        {label}{' '}
        {popup && (
          <Popup
            trigger={<Icon name="question" color="orange" size="small" />}
            content={content}
            position="right center"
          />
        )}
      </label>

      <Input
        name={name}
        onChange={(e, data) => {
          onChange(e, { ...data, value: data.value.substring(0, maxlength) });
        }}
        value={value}
        id={id}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        maxLength={maxlength}
        // onInput={onInput}
        onBlur={onBlur}
        onInput={onBlur}
        onKeyUp={onKeyup}
        onFocus={onFocus}
        defaultValue={defaultValue && defaultValue}
      />

      <ErrorMessage isError={isError} errorMsg={errorMsg} />
    </Form.Field>
  );
};

InputField.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string,
  /** id of the input field */
  id: PropTypes.string,
  /** type of the input field */
  type: PropTypes.string,
  /** input field disabled*/
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  width: '16',
  disabled: false,
  type: 'text',
  maxlength: Infinity,
};

export default InputField;
