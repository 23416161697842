import React from 'react';
import PropTypes from 'prop-types';
import Moment from "react-moment";
import ListingWithSearch from "../../../common/listing-with-search";
import urls from "../../../config/constants";

const Constants = {
  SEARCH_ON_STATUS: Object.freeze([
    { key: 'all-search', text: 'All', value: '' },
    { key: 'active-search', text: 'Active', value: 'ACTIVE' },
    { key: 'pending-search', text: 'Pending', value: 'PENDING' },
    { key: 'rejected-search', text: 'Rejected', value: 'REJECTED' },
  ]),
  TABLE_COLUMNS: Object.freeze([
    {name:'Company Name', key:'company'},
    {name:'Status', key:'status'},
    {name:'Owner', key:'initiatorName'},
    {name:'Last Updated', key:'updatedOn' },
  ]),
};

const transformData = data => {
  let transformedData = Object.assign({}, data);
  let content = [];
  transformedData.content.map( el => {
    content.push({
      company: el.company,
      status: el.status,
      initiatorName: el.initiatorName,
      updatedOn: el.updatedOn? (<Moment format="MM/DD/YYYY hh:mm a">{ el.updatedOn }</Moment>) : '',
    });
  });
  transformedData.content = content;
  return transformedData;
};

const SLAPage = ({
  title,
  status,
}) => {
  const { SEARCH_ON_STATUS, TABLE_COLUMNS } = Constants;
  const { URL_SLA_LISTING } = urls.App;

  return(
    <ListingWithSearch
      title={ title }
      columns={ TABLE_COLUMNS }
      dropDownOptions={ SEARCH_ON_STATUS }
      extraAction={{ text: "Delete SLA(s)", url: null, action: "delete" }}
      fetchUrl={ URL_SLA_LISTING }
      searchTermKey="search"
      dropdown={{
        key: "status",
        value: status,
        placeholder: "Status",
      }}
      dataMapperFunction={ transformData }
    />
  )
};

SLAPage.propTypes = {
  /** Text to be show at the top of page */
  title: PropTypes.string.isRequired,
  /** Default filter. */
  status: PropTypes.string.isRequired,
}

export default SLAPage;
