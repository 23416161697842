import React from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import DropdownField from "./dropdown-field";
import { countries } from "../../lists/country-codes";

const getCountriesList = () => {
  let acc = [];
  countries.map((country, key) => (
  acc.push({

      key: country.isoCode,
      value: country.dialCode,
      text: country.dialCode
    
    })

  ));
  const sortedCountrieCode = sortBy(acc, [
    function (o) {
      return o.value;
    },
  ]);
  //   const countryList = Object.keys(countries).reduce((acc, el) => {
  //     acc.push({ key: el, value: el, text: countries[el] });
  //     return acc;
  //   }, []);
  //   const sortedCountries = sortBy(countryList, [
  //     function (o) {
  //       return o.text;
  //     },
  //]);
  //return sortedCountries;

  const uniqueSorted = [];
  
  for(let i = 0;i<sortedCountrieCode.length-1;i++)
    if(sortedCountrieCode[i].text != sortedCountrieCode[i+1].text)
      uniqueSorted.push(sortedCountrieCode[i]);

  return uniqueSorted;
};

const CountryCode = ({
  errorMsg,
  isError,
  isRequired,
  label,
  name,
  onChange,
  value,
  width,
}) => {
  return (
    <DropdownField
      errorMsg={errorMsg}
      isError={isError}
      isRequired={isRequired}
      label={label}
      name={name}
      onChange={onChange}
      options={getCountriesList()}
      // placeholder="Select your country code..."
      search
      selection
      value={value}
      width={width}
    />
  );
};

CountryCode.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string,
};
CountryCode.defaultProps = {
  value: "+1",
  width: "2",
};
export default CountryCode;
