import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { Icon, Button } from "semantic-ui-react";
import ListingWithSearch from "../../../../../common/listing-with-search";
import FileIcon from "../../../../../common/file-icon";
import { dateToPacific } from "@/utils/dateTime";


const FilesListing = ({
  columns,
  fetchUrl,
  title,
  downloadFunction,
  editUrl
}) => {
  const dataMapper = data => {
    let transformedData = Object.assign({}, data);
    let content = [];
    transformedData.content.map(el => {
      let newElement = Object.assign(
        { actions: (
          <React.Fragment>
          {/* deliberately commenting out for future scope */}
          {/*<Link>
            <span className="icon-edit"
            title="Edit"
            name="edit outline" />
          </Link>*/}
          <span
          className="icon-download"
          title="Download"
          onClick={() => downloadFunction(el)}
          />
          </React.Fragment>
        ),
        },
        el,
        {
          modification_date: el.modification_date && (
            <>{dateToPacific(el.modification_date,"MM/DD/YYYY hh:mm a")}</>
          ),
          modificationDate: el.modificationDate && (
            <>{dateToPacific(el.modificationDate,"MM/DD/YYYY hh:mm a")}</>
          ),
          description: <div style={{whiteSpace: 'pre-line'}}>{el.description}</div>,
          type: (<FileIcon
           filename =
            {el.filename ? el.filename : 'other'  }

            >
            </FileIcon>)
        }
      );
      content.push(newElement);
    });
    transformedData.content = content;
    return transformedData;
  };

  return (
    <React.Fragment>
      <ListingWithSearch
        columns={columns}
        fetchUrl={fetchUrl}
        title={title}
        dataMapperFunction={dataMapper}
      />
    </React.Fragment>
  );
};

FilesListing.propTypes = {
  columns: PropTypes.object.isRequired,
  fetchUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  downloadFunction: PropTypes.func.isRequired,
  editUrl: PropTypes.string
};

export default FilesListing;
