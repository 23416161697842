import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import equal from "fast-deep-equal";
import { List, Pagination, Segment } from "semantic-ui-react";
import Api from "@api";
import FileIcon from "@/common/file-icon";
import Constants from "@constants";
import LocalStore from "@/store/persistence/local-storage";
import { dateToPacific } from "@/utils/dateTime";
import {
  redirectToHelpdeskURL,
  trimString,
  trimStringWithWord,
} from "../../../common/utils/utils";
import { connect } from "react-redux";
const { URL_GLOBAL_SEARCH } = Constants.App;

class All extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      listings: {
        content: [],
        number: 0,
      },
      pageNo: 0,
      pageSize: 10,
      totalRecords: 0,
      data: {},
    };
  }
  componentDidMount() {
    // this.updateData();
    this.searchResults(this.props.keyword, this.props.sort);
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.data, prevProps.data)) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      // this.updateData();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      !equal(this.props.keyword, nextProps.keyword) ||
      nextProps.sort.sortBy !== this.props.sort.sortBy ||
      nextProps.sort.sortOrder !== this.props.sort.sortOrder
    ) {
      this.setState(
        {
          sort: nextProps.data,
        },
        () => {
          this.searchResults(nextProps.keyword, nextProps.sort);
        },
      );
    }
  }
  updateData = () => {
    const { data } = this.props;
    if (data) {
      this.setState({
        listings: this.transformData(data),
      });
    }
  };
  transformData = (data) => {
    let content = [];
    if (data) {
      data.map((el) => {
        let row = Object.assign({}, el.sourceAsMap);
        console.log(row.creationDate ? row.creationDate : el.index);
        row.score = el.score;
        if (el.index === "itemsearchindex") {
          row.header = row.name;
          row.description = trimStringWithWord(
            row.description,
            this.props.searchKeyword || this.props.keyword,
            120,
          );
          row.href = `/#/downloads/browser/${row.parentProductId}`;
          row.type = "DOWNLOAD ITEM";
        } else if (el.index == "projects") {
          row.header = row.name;
          row.href = `/#/projects/${row.id}`;
          row.type = "PROJECT ITEM";
          row.description = trimStringWithWord(
            row.description,
            this.props.searchKeyword || this.props.keyword,
            120,
          );
        } else if (el.index === "projectposts") {
          row.header = row.projectName;
          row.href = `/#/projects/${row.projectId}`;
          row.type = "PROJECT POST";
          row.description = row.post
            ? trimStringWithWord(
                row.post,
                this.props.searchKeyword || this.props.keyword,
                120,
              )
            : trimStringWithWord(
                row.description,
                this.props.searchKeyword || this.props.keyword,
                120,
              );
        } else if (el.index == "websilo") {
          row.header = row.name;
          row.href = `/#/downloads/browser/${row.parentProductId}`;
          row.description = trimStringWithWord(
            row.description,
            this.props.searchKeyword || this.props.keyword,
            120,
          );
          row.type = "DOWNLOAD ITEM";
        } else if (el.index == "trainingvideos") {
          row.header = row.name;
          row.href = `/#/videos/view/${row.id}`;
          row.type = "VIDEO";
          row.description = trimStringWithWord(
            row.description,
            this.props.searchKeyword || this.props.keyword,
            120,
          );
        } else if (el.index == "items") {
          row.header = row.name;
          row.description = row.content
            ? trimStringWithWord(
                row.content,
                this.props.searchKeyword || this.props.keyword,
                120,
              )
            : trimStringWithWord(
                row.description,
                this.props.searchKeyword || this.props.keyword,
                120,
              );
          row.href = `/#/downloads/browser/${row.parentProductId}`;
          row.type = "DOWNLOAD ITEM";
        } else if (el.index == "products") {
          row.header = row.name;
          row.href = `/#/downloads/browser/${row.id}`;
          row.type = "DOWNLOAD FOLDER";
          row.description = trimStringWithWord(
            row.description,
            this.props.searchKeyword || this.props.keyword,
            120,
          );
        } else if (el.index == "tickets") {
          row.header = row.subject;
          row.href = row.url;
          row.type = "SUPPORT REQUEST";
          row.creationDate = row.creationDate
            ? row.creationDate
            : row.created_at;
          row.description = trimStringWithWord(
            row.description,
            this.props.searchKeyword || this.props.keyword,
            120,
          );
        } else if (el.index == "projects" || el.index == "projectdocs") {
          row.header = row.name;
          row.href = `/#/projects/${row.projectId}`;
          row.description = trimStringWithWord(
            row.description,
            this.props.searchKeyword || this.props.keyword,
            120
          );
          row.type = "PROJECT ITEM";
        } else if (el.index == "articles") {
          row.href = row.html_url;
          row.type = "SUPPORT ARTICLE";
          row.header = row.name;
          row.description = row.body
            ? trimStringWithWord(
                row.body.replace(/<[^>]*>?/gm, ""),
                this.props.searchKeyword || this.props.keyword,
                120,
              )
            : "N/A";
        } else {
          row.href = row.permalink;
          row.type = "GROQ.COM";
          row.header = row.post_title;
          row.description = row.post_content_filtered
            ? trimStringWithWord(
                row.post_content_filtered.replace(/<[^>]*>?/gm, ""),
                this.props.searchKeyword || this.props.keyword,
                120,
              )
            : "N/A"; 
        }
        content.push(row);
      });
    }
    data.content = content;
    return data;
  };
  // projects,projectdocs,projectposts,trainingvideos,items,products,articles,tickets
  searchResults = (keyword, sort) => {
    let getKeyword = keyword;
    if (!keyword) {
      getKeyword = this.props.location.pathname.split("/")[2];
    }
    this.setState(
      {
        ...this.state,
        loader: true,
      },
      () => {
        let url = URL_GLOBAL_SEARCH;
        if (this.props.supportEnabled) {
          url = url + "_all";
        } else {
          url =
            url +
            "projects,projectdocs,projectposts,trainingvideos,items,products";
        }
        //projects,projectdocs,projectposts,trainingvideos,items,products
        Api.AxiosInstance.getInstance()
          .get(url, {
            params: {
              pageNo: this.state.pageNo,
              pageSize: this.state.pageSize,
              query: getKeyword,
              ...sort,
            },
          })
          .then((resp) => {
            this.setState({
              listings: this.transformData(resp.data.hits.hits),
              loader: false,
              totalRecords: resp.data.hits.totalHits,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              loader: false,
            });
          });
      },
    );
  };
  redirectToHelpdesk = (url) => {
    return () => {
      const showHideSupportErrorDialog = this.props.showHideSupportErrorDialog;
      const showHideMainLoader = this.props.showHideMainLoader;
      showHideMainLoader(true);
      redirectToHelpdeskURL(url, true)
        .then((returnToUrl) => {
          LocalStore.setValue("loggedInToHelpDesk", true);
          window.open(returnToUrl, "_blank");
          showHideMainLoader(false);
        })
        .catch((error) => {
          showHideSupportErrorDialog(true, error.response.data.error);
          showHideMainLoader(false);
        });
    };
  };
  onPageChange = (e, { activePage }) => {
    this.setState(
      {
        pageNo: activePage - 1,
      },
      () => {
        this.searchResults(this.props.keyword, this.props.sort);
      },
    );
  };
  render() {
    window.xkr = this.state.listings.content;
    return (
      <Segment className="m-0 p-0" loading={this.state.loader}>
        {this.state.listings.content.length > 0 ? (
          <>
            <List celled className="search-result-wrapper">
              {this.state.listings.content.map((data, index) => (
                <List.Item className="search-item-row">
                  <List.Content>
                    {data.type === "SUPPORT REQUEST" ||
                    data.type === "SUPPORT ARTICLE" ? (
                      <List.Header as="div">
                        <a
                          onClick={this.redirectToHelpdesk(data.href)}
                          className="search-heading"
                        >
                          {data.header}
                        </a>
                        <b> {data.type}</b>
                      </List.Header>
                    ) : (
                      <List.Header as="div">
                        <a href={data.href} className="search-heading"
                        dangerouslySetInnerHTML={{__html: data.header}}
                        >
                        </a>{" "}
                        <b>{data.type}</b>
                      </List.Header>
                    )}

                    <List.Description className="s-des-wrapper">
                      <p className="des-p1">
                        {data.creationDate ? (
                          <>{dateToPacific(data.creationDate, "MM/DD/YYYY")}</>
                        ) : data.post_date ? (
                          <>{dateToPacific(data.post_date, "MM/DD/YYYY")}</>
                        ) : (
                          "N/A"
                        )}{" "}
                        --{" "}
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[
                            this.props.keyword,
                            this.props.searchKeyword,
                          ]}
                          autoEscape={true}
                          textToHighlight={data.description}
                        />
                      </p>
                    </List.Description>

                    <span className="des-p2">
                      {data.type === "SUPPORT TICKET" ||
                      data.type === "SUPPORT ARTICLE" ||
                      data.type === "GROQ.COM"
                        ? data.href
                        : window.location.origin + data.href}
                    </span>
                  </List.Content>
                </List.Item>
              ))}
            </List>
            <Pagination
              activePage={this.state.pageNo + 1}
              onPageChange={this.onPageChange}
              totalPages={Math.ceil(this.state.totalRecords / 10)}
            />
            {this.state.listings.content.length > 0 ? "" : "No record found"}
          </>
        ) : (
          "No record found"
        )}
      </Segment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    supportEnabled: state.common.config.supportEnabled,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(All);
