const CUSTOMER = "websilo-customer";
const ACCESS_MANAGER = "websilo-account-approver";
const SYSTEM_ADMIN = "websilo-sysadmin";
const ACCOUNT_APPROVER = "websilo-account-approver";
const ADMINISTRATOR = "websilo-admin";
const EMPLOYEE = "websilo-employee";
const NON_NDA_CUSTOMER = "websilo-non-nda-customer";
const CUSTOMER_REPRESENTATIVE = "websilo-representative";
const PUBLIC_NON_NDA_CUSTOMER = "websilo-public-non-nda-customer";
const ADMINISTRATORS = [ADMINISTRATOR, SYSTEM_ADMIN];
const PRIVILEGED = [
  CUSTOMER,
  EMPLOYEE,
  ...ADMINISTRATORS,
  ACCOUNT_APPROVER,
  ACCESS_MANAGER,
  CUSTOMER_REPRESENTATIVE,
];
const SPECIAL_ROLES = [PUBLIC_NON_NDA_CUSTOMER,...PRIVILEGED ]
const ALL = [NON_NDA_CUSTOMER, PUBLIC_NON_NDA_CUSTOMER, ...PRIVILEGED];
const APPROVERS = [EMPLOYEE, ACCOUNT_APPROVER, ...ADMINISTRATORS];
const EDIT_APPROVERS = [ACCOUNT_APPROVER, ...ADMINISTRATORS];
const EMPLOYEES = [ACCOUNT_APPROVER, EMPLOYEE];

export default {
  EDIT_APPROVERS,
  CUSTOMER,
  NON_NDA_CUSTOMER,
  PUBLIC_NON_NDA_CUSTOMER,
  EMPLOYEE,
  SYSTEM_ADMIN,
  ACCOUNT_APPROVER,
  ADMINISTRATOR,
  ACCESS_MANAGER,
  CUSTOMER_REPRESENTATIVE,
  ADMINISTRATORS,
  PRIVILEGED,
  SPECIAL_ROLES,
  APPROVERS,
  EMPLOYEES,
  ALL,
};
