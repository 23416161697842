import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { parse } from "query-string";
import isEmpty from "lodash/isEmpty";
import { Dimmer, Loader } from "semantic-ui-react";

import { generateStateAndNonce, receiveAccessToken } from "../../api/onelogin";
import Api from "../../api";

const UserApi = Api.User;

const AuthPage = ({ location, profile, receiveTokens }) => {
  const hash = location.hash;

  const response = parse(hash);
  if (response.error) {
    alert(response.error_description);
    return <Redirect to="/login" />;
  } else {
    receiveTokens(response.access_token, response.id_token);
    // backend api
    return <Redirect to="/dashboard" />;
  }
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  state: state.token.state,
  nonce: state.token.nonce,
});

const mapDispatchToProps = (dispatch) => ({
  receiveTokens: (accessToken, idToken) => {
    dispatch(receiveAccessToken(accessToken));
    dispatch(UserApi.receiveIdToken(idToken));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
