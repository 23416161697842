import SLA from "./sla";
import { connect } from "react-redux";
import Config from "../../config/constants";

const mapDispatchToProps = dispatch => {
  return {};
};

const baseUrl = "/sla";

const mapStateToProps = state => ({
  baseUrl: baseUrl
});

const mappedSLA = connect(mapStateToProps, mapDispatchToProps)(SLA);

export default {
  name: "SLA",
  configKey: "sla_menu_item_title",
  path: baseUrl,
  component: mappedSLA,
  isPrivateRoute: true,
  accessRoles: [Config.AccessRole.SYSTEM_ADMIN]
};
