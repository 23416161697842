import {
  CategoryField,
  DateField,
  DropdownField,
  FileField,
  InputField,
  PlatformField,
  StatusField,
  TextareaField,
} from "@/common/Form";
import Uploader from "@/common/uploader";
import React from "react";
import { Card, Form, Grid, Message, Progress } from "semantic-ui-react";
import DatePickerWithTimezone from "../../../../utils/DatePickerWithTimeZone";
import { dateToPacific } from "../../../../utils/dateTime";
import ExpirationPeriod from "./expiration-period";

export default function ({
  onChange,
  onFileChange,
  name,
  friendlyName,
  description,
  file,
  parentId,
  categoryId,
  platformId,
  status,
  availableDate,
  parentDropDownDisabled,
  productsOptions,
  validationMsg,
  editMode,
  defaultExpiry,
  metaInfo,
  uploadProgress,
  minDate,
}) {
  const DATE_FORMAT = "YYYY-MM-DD";
  const modifiedDate =
    metaInfo && metaInfo.modifiedDate
      ? dateToPacific(metaInfo.modifiedDate, "MM/DD/YYYY hh:mm a")
      : null;
  const creationDate =
    metaInfo && metaInfo.creationDate
      ? dateToPacific(metaInfo.creationDate, "MM/DD/YYYY hh:mm a")
      : null;
  return (
    <React.Fragment>
      <Grid columns={2} stackable>
        <Grid.Row className="pb-0-im">
          <Grid.Column width={11}>
            <Form.Field>
              <DropdownField
                // disabled={parentDropDownDisabled}
                options={productsOptions}
                value={parentId}
                label="Parent Product"
                name="parentId"
                onChange={onChange}
              />
            </Form.Field>
            <Form.Field>
              <CategoryField
                value={categoryId}
                label="Category"
                name="categoryId"
                errorMsg={validationMsg.categoryId}
                isError={validationMsg.categoryId}
                onChange={onChange}
                isRequired
              />
            </Form.Field>
            <Form.Field>
              <PlatformField
                value={platformId}
                label="Platform"
                name="platformId"
                errorMsg={validationMsg.platformId}
                isError={validationMsg.platformId}
                onChange={onChange}
                isRequired
              />
            </Form.Field>
            <Form.Field>
              <StatusField
                value={status}
                label="Status"
                errorMsg={validationMsg.status}
                isError={validationMsg.status}
                name="status"
                onChange={onChange}
                isRequired
              />
            </Form.Field>
            <Form.Field>
              <InputField
                label="Item Name"
                errorMsg={validationMsg.name}
                isError={validationMsg.name}
                name="name"
                onChange={onChange}
                value={name}
                isRequired
                maxlength="32"
              />
            </Form.Field>
            <Form.Field>
              <InputField
                label="Friendly Name"
                errorMsg={validationMsg.friendlyName}
                isError={validationMsg.friendlyName}
                name="friendlyName"
                onChange={onChange}
                value={friendlyName}
                maxlength="32"
              />
            </Form.Field>
            <Form.Field>
              <TextareaField
                value={description}
                label="Description (maximum 750 characters allowed)"
                name="description"
                onChange={onChange}
                maxLength={750}
              />
            </Form.Field>

            <FileField
              errorMsg={validationMsg.file}
              isError={validationMsg.file}
              label="File"
              name="file"
              files={file ? [file] : []}
              onChange={Uploader(onFileChange)}
              multipleSelection={false}
              isRequired
            />
            {uploadProgress.isUploading && (
              <Progress percent={uploadProgress.uploadProgress} indicating />
            )}
            {/* <DateField
              name="availableDate"
              label="Available Date"
              errorMsg={validationMsg.availableDate}
              isError={validationMsg.availableDate}
              format={"YYYY-MM-DD"}
              onChange={(e, data) => {
                onChange(e, {
                  ...data,
                });
              }}
              value={availableDate}
              isRequired
              minDate={new Date()}
            /> */}
            <DatePickerWithTimezone
              name="availableDate"
              label="Available Date"
              errorMsg={validationMsg.availableDate}
              isError={validationMsg.availableDate}
              minDate={new Date()}
              selected={availableDate}
              timezone="America/Los_Angeles"
              isRequired
              onChange={(e, data) =>
                onChange(e, {
                  name: "availableDate",
                  value: e,
                })
              }
            />
            <ExpirationPeriod
              isError={validationMsg.expireType || validationMsg.expireValue}
              errorMsg={validationMsg.expireType || validationMsg.expireValue}
              onChange={onChange}
              editMode={editMode}
              availableDate={availableDate}
              defaultExpiration={defaultExpiry}
              format={DATE_FORMAT}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            {metaInfo && (
              <Card>
                <Card.Content
                  className="dark-blue-background-shade"
                  header={
                    <React.Fragment>
                      <Card.Header>Item Information</Card.Header>
                    </React.Fragment>
                  }
                />
                <Card.Content>
                  <Message style={{ fontSize: "11px" }}>
                    {metaInfo.createdBy && (
                      <Message.Content style={{ marginBottom: "10px" }}>
                        <Message.Header>Created By: </Message.Header>
                        <div style={{ marginLeft: "5px" }}>
                          {metaInfo.createdBy}
                        </div>
                      </Message.Content>
                    )}
                    {metaInfo.creationDate && (
                      <Message.Content style={{ marginBottom: "10px" }}>
                        <Message.Header>Created On: </Message.Header>
                        <div style={{ marginLeft: "5px" }}>
                          {/* <Moment format="MM/DD/YYYY hh:mm a"> */}
                          {creationDate}
                          {/* </Moment> */}
                        </div>
                      </Message.Content>
                    )}
                    {metaInfo.modifiedBy && (
                      <Message.Content style={{ marginBottom: "10px" }}>
                        <Message.Header>Modified By: </Message.Header>
                        <div style={{ marginLeft: "5px" }}>
                          {metaInfo.modifiedBy}{" "}
                        </div>
                      </Message.Content>
                    )}
                    {metaInfo.modifiedDate && (
                      <Message.Content style={{ marginBottom: "10px" }}>
                        <Message.Header>Modification Date: </Message.Header>
                        <div style={{ marginLeft: "5px" }}>
                          {/* <Moment format="MM/DD/YYYY hh:mm a"> */}
                          {modifiedDate}
                          {/* </Moment> */}
                        </div>
                      </Message.Content>
                    )}
                  </Message>
                </Card.Content>
              </Card>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
}
