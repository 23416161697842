import { DropdownField, InputField, TextareaField } from "@/common/Form";
import moment from "moment";
import React from "react";
import Moment from "react-moment";
import { Card, Form, Grid, Message } from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";
export default function ({
  name,
  status,
  description,
  validationMsg,
  statusOptions,
  productsDropDownMap,
  parentId,
  parentDropDownDisabled,
  onChange,
  metaInfo,
}) {
  // Formatting was necessary else  was getting invalid date issue: Temporary Fix::
  const DATE_FORMAT = "MM/DD/YYYY";
  const modifiedDate =
    metaInfo && metaInfo.modifiedDate
      ? new Date(metaInfo.modifiedDate).getTime()
      : null;
  const creationDate =
    metaInfo && metaInfo.creationDate
      ? new Date(metaInfo.creationDate).getTime()
      : null;

  return (
    <>
      <Grid columns={2} stackable>
        <Grid.Column width={11}>
          <Form.Field>
            <DropdownField
              disabled={parentDropDownDisabled}
              options={productsDropDownMap}
              value={parentId}
              label="Parent Product"
              name="parent_id"
              onChange={onChange}
            />
          </Form.Field>
          <Form.Field>
            <InputField
              label="Product Name"
              errorMsg={validationMsg.name}
              isError={validationMsg.name}
              name="name"
              onChange={onChange}
              value={name}
              maxlength="32"
              isRequired
            />
          </Form.Field>
          <Form.Field>
            <TextareaField
              value={description}
              label="Description (maximum 250 characters allowed)"
              name="description"
              onChange={onChange}
              maxLength={250}
            />
          </Form.Field>
          <Form.Field>
            <DropdownField
              errorMsg={validationMsg.status}
              isError={validationMsg.status}
              options={statusOptions}
              value={status}
              label="Status"
              name="status"
              isRequired
              onChange={onChange}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column width={5}>
          {metaInfo && (
            <Card>
              <Card.Content
                className="dark-blue-background-shade"
                header={
                  <React.Fragment>
                    <Card.Header>Product Information</Card.Header>
                  </React.Fragment>
                }
              />
              <Card.Content>
                <Message style={{ fontSize: "11px" }}>
                  {metaInfo.createdBy && (
                    <Message.Content style={{ marginBottom: "10px" }}>
                      <Message.Header>Created By: </Message.Header>
                      <div style={{ marginLeft: "5px" }}>
                        {" "}
                        {metaInfo.createdBy}
                      </div>
                    </Message.Content>
                  )}
                  {metaInfo.creationDate && (
                    <Message.Content style={{ marginBottom: "10px" }}>
                      <Message.Header>Created On: </Message.Header>
                      <div style={{ marginLeft: "5px" }}>
                        <>
                          {dateToPacific(creationDate,"MM/DD/YYYY hh:mm a")}
                        </>
                      </div>
                    </Message.Content>
                  )}
                  {metaInfo.modifiedBy && (
                    <Message.Content style={{ marginBottom: "10px" }}>
                      <Message.Header>Modified By: </Message.Header>
                      <div style={{ marginLeft: "5px" }}>
                        {metaInfo.modifiedBy}
                      </div>
                    </Message.Content>
                  )}
                  {metaInfo.modifiedDate && (
                    <Message.Content style={{ marginBottom: "10px" }}>
                      <Message.Header>Modification Date:</Message.Header>
                      <div style={{ marginLeft: "5px" }}>
                        <>{dateToPacific(modifiedDate,"MM/DD/YYYY hh:mm a")}</>
                      </div>
                    </Message.Content>
                  )}
                </Message>
              </Card.Content>
            </Card>
          )}
        </Grid.Column>
      </Grid>
    </>
  );
}
