import Tableview from "@/common/tableview";
import Api from "@api";
import urls from "@constants";
import update from "immutability-helper";
import { merge } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { CardviewMobile } from "@/common/cardview";
import {
  Button,
  Container,
  Grid,
  Modal,
  Segment,
  Dropdown,
  Responsive,
} from "semantic-ui-react";
import Moment from "moment";
import { dateToPacific } from "@/utils/dateTime";

class NDAListing extends React.Component {
  Constants = {
    COLUMNS: [
      { name: "Company Name", key: "company", cb: () => {
        this.sortByHeader("company", "Company Name");
      }, },
      { name: "Effective Date", key: "effectiveDate", cb: () => {
        this.sortByHeader("effectiveDate", "Effective Date");
      }, },
      { name: "Expire Date", key: "expiryDate", },
    ],

    RESPONSIVE_COLUMNS: [
      { name: "Company Name", key: "company" },
      { name: "Effective Date", key: "effectiveDate" },
      { name: "Expire Date", key: "expiryDate" },
    ],
    SEARCH_ON_TYPE: Object.freeze([
      // { key: "all-search", text: "All", value: "all" },
      {
        key: "non-standard-search",
        text: "Non-Standard",
        value: "non-standard",
      },
      { key: "standard-search", text: "Standard", value: "standard" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      PackageList: {},
      configOption: {},
      selectedPackages: [],
      type: "non-standard",
      isDescSearch: true,
      sortBy: "",
    };
  }

  componentDidMount() {
    this.fetchNDAs();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.COLUMNS = this.Constants.COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchNDAs();
    });
   
  };

  transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    transformedData.content.map((el) => {
      content.push({
        ...el,
        effectiveDate: dateToPacific(el.effectiveDate, "MM/DD/YYYY"),
        expiryDate:
          this.state.type == "non-standard"
            ? dateToPacific(el.expiryDate, "MM/DD/YYYY")
            : "--",
      });
    });
    transformedData.content = content;
    return transformedData;
  };

  fetchNDAs = () => {
    const { URL_NDA_LISTING, URL_NON_STANDARD_NDA_LISTING } = urls.App;
    const { type } = this.state;
    const { entity, entityId } = this.props;
    let { configOption } = this.state;
    configOption =  merge(
        configOption,
        {sort: this.state.sortBy},
      );
    

    const paramsForPerspective = {
      entity,
      entityId,
    };
    // entityId concat configOptions
    const params = {
      params: merge(
        configOption,
        entity && entityId ? paramsForPerspective : {}
      ),
    };
    // const url = type== "non-standard"?
    const parameterizedUrl =
      type === "non-standard"
        ? `${URL_NON_STANDARD_NDA_LISTING}`
        : `${URL_NDA_LISTING}`;
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(parameterizedUrl, params).then((resp) => {
      this.setState({
        PackageList: this.transformData(resp.data),
      });
    });
  };
  onItemSelection = (e) => {
    this.setState({ selectedPackages: e.data });
  };

  onPageChange = (e) => {
    const { configOption } = this.state;
    this.setState(
      { configOption: update(configOption, { page: { $set: e.page } }) },
      this.fetchNDAs
    );
  };
  onDropDownChange = (name, value) => {
    this.setState(
      {
        type: value,
      },
      this.fetchNDAs
    );
  };
  onSearchClicked = () => {
    // always fetch the first page while searching.
    // You never know if it has got more than one page data or not.
    this.onPageChange({ page: 0 });
  };

  onSearchTextChange = (event) => {
    const { configOption } = this.state;
    this.setState({
      configOption: update(configOption, {
        search: { $set: event.target.value },
      }),
    });
  };

  resetSearchConfig = () => {
    this.setState({ configOption: {} });
  };

  onModalClose = () => {
    this.resetSearchConfig();
    const { onDismiss } = this.props;
    if (onDismiss) onDismiss();
  };

  render() {
    const { selectedPackages, PackageList } = this.state;
    const { onSave, show, selectionType } = this.props;
    const { COLUMNS, SEARCH_ON_TYPE, RESPONSIVE_COLUMNS } = this.Constants;

    return (
      <Modal
        open={show}
        onMount={this.fetchNDAs}
        onClose={this.onModalClose}
        centered={true}
        size="large"
      >
        <Modal.Header>List of NDAs</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row className="common-filter-wrapper ui form">
              <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6}>
                <div class="field">
                  <input
                    placeholder="Search"
                    className="search-bar"
                    onChange={(e) => this.onSearchTextChange(e)}
                  ></input>
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6}>
                <div class="field">
                  <Dropdown
                    options={SEARCH_ON_TYPE}
                    selection
                    defaultValue="non-standard"
                    placeholder="Type"
                    onChange={(event, data) =>
                      this.onDropDownChange("type", data.value)
                    }
                  />
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <div class="field">
                  <button
                    className="button-primary"
                    onClick={this.onSearchClicked}
                  >
                    Search
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination mt-2">
                    <Tableview
                      paginate
                      selectionType={selectionType}
                      data={PackageList}
                      columns={COLUMNS}
                      onSelectionChange={this.onItemSelection}
                      onPaginationChange={this.onPageChange}
                      noDataMessage="No Nda found."
                    />
                  </div>
                </Responsive>

                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    paginate
                    selectionType={selectionType}
                    data={PackageList}
                    columns={RESPONSIVE_COLUMNS}
                    onSelectionChange={this.onItemSelection}
                    onPaginationChange={this.onPageChange}
                    noDataMessage="No Nda found"
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="mt-2"
              >
                <Button
                  className="button-primary m--no-margin-left-im"
                  onClick={() => {
                    onSave(selectedPackages);
                  }}
                >
                  Save
                </Button>
                <Button onClick={this.onModalClose} className="button-basic">
                  Close
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

NDAListing.propTypes = {
  /** Callback which is fired when Popup is closed. */
  onDismiss: PropTypes.func.isRequired,
  /** Callback which is fired when `resend` button is clicked. */
  onSave: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
  /** single-select = radio buttons, multi-select = checkboxes */
  selectionType: PropTypes.oneOf(["single-select", "multi-select"]),
  /** Perspective: */
  entity: PropTypes.oneOf(["PackageItem", "ItemPackage"]),
  /** */
  entityId: PropTypes.number,
};

NDAListing.defaultProps = {
  selectionType: "single-select",
};

export default NDAListing;
