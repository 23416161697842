import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Dimmer,
  Loader,
  Pagination,
  Segment,
  Grid,
} from "semantic-ui-react";
import get from "lodash/get";
import { activePageToPageIndex } from "@/common/utils/utils";
import update from "immutability-helper";

class Cardview extends Component {
  Constants = {
    EMPTY_MSG: "No Data!",
  };
  constructor(props) {
    super(props);
    this.state = {};
  }

  isDataProvided = () => {
    const { data } = this.props;
    return data && data.content && data.content.length > 0;
  };

  isColumnsProvided = () => {
    const { columns } = this.props;
    return columns && columns.length > 0;
  };

  handleEmptyPage = () => {
    const { totalPages, number } = this.props.data;
    // changing page index to page number.
    let curPage = number + 1;
    // sometimes server send 0 total pages.
    // but for our logic total page should at least 1.
    let minTotalPages = totalPages > 0 ? totalPages : 1;
    if (curPage > minTotalPages) {
      this.onPageChange(null, { activePage: minTotalPages });
    }
  };

  onPageChange = (e, { activePage }) => {
    this.setState(
      {
        currentPage: activePageToPageIndex(activePage),
      },
      () => this.notifyPageChange()
    );
  };
  toggleSelection = (rowIndex) => {
    const { checkboxes } = this.state;
    this.setState(
      {
        checkboxes: update(checkboxes, {
          [rowIndex]: { $set: !checkboxes[rowIndex] },
        }),
      },
      () => {
        this.notifySelectionChange();
      }
    );
  };
  notifySelectionChange = () => {
    const { data, onSelectionChange } = this.props;
    const { checkboxes } = this.state;
    if (onSelectionChange && onSelectionChange instanceof Function) {
      onSelectionChange({
        data: data.content.filter((v, k) => checkboxes[k]),
      });
    }
  };
  markAllSelection = (value, cb) => {
    const { content } = this.props.data;
    let contentLenght = (content && content.length) || 0;
    let checks = new Array(contentLenght).fill(value);

    this.setState(
      {
        checkboxes: checks,
        checkAllBox: value,
      },
      () => {
        if (cb) cb();
      }
    );
  };
  selectThisOne = (rowIndex) => {
    this.markAllSelection(false, () => {
      this.toggleSelection(rowIndex);
    });
  };
  notifyPageChange = (activePage) => {
    const { currentPage } = this.state;
    const { onPaginationChange } = this.props;
    // const onPaginationChange = this.props.onSelectionChange; // ToDo: temproray hack.
    if (onPaginationChange && onPaginationChange instanceof Function) {
      onPaginationChange({
        page: currentPage,
      });
    }
  };

  render() {
    const { columns, data, loading, noDataMessage, paginate } = this.props;
    const { EMPTY_MSG } = this.Constants;
    return (
      <React.Fragment>
        {!loading && (!this.isDataProvided() || !this.isColumnsProvided()) && (
          <p>{noDataMessage || EMPTY_MSG}</p>
        )}
        {loading && (
          <Segment>
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {this.isColumnsProvided() &&
          this.isDataProvided() &&
          data.content.map((row, rowIndex) => (
            <Card
              key={`card-${rowIndex}`}
              centered
              className="mobile-card-view-wrapper"
            >
              <Card.Content>
                <Card.Description>
                  <Grid>
                    {columns.map((col) => (
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <React.Fragment>
                          <div class="data-view-group-box">
                            <label key={`column-${col.name}`} color="blue">
                              {col.name}:
                            </label>
                            <p>{get(row, col.key.split("."))}</p>
                          </div>
                        </React.Fragment>
                      </Grid.Column>
                    ))}
                  </Grid>
                </Card.Description>
              </Card.Content>
            </Card>
          ))}
        {paginate && this.isDataProvided() && (
          <Segment>
            <Pagination
              activePage={data.number + 1}
              onPageChange={this.onPageChange}
              totalPages={data.totalPages ? data.totalPages : 1}
            />
          </Segment>
        )}
      </React.Fragment>
    );
  }
}

Cardview.propTypes = {
  /** Names of the columns */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      sort: PropTypes.bool,
      width: PropTypes.string,
      class: PropTypes.string,
    })
  ).isRequired,
  /** The data which will be rendered in the grid view. this `data` object must contain a `content` key. */
  data: PropTypes.shape({
    content: PropTypes.array,
    number: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  /** ToDo: not implemented yet. Its for future scope */
  loading: PropTypes.bool,
  /** Message will be displayed in case of no data to display. */
  noDataMessage: PropTypes.string,
  /** Determines if the pagination UI should be displayed. */
  paginate: PropTypes.bool,
  /** The callback which will be fired whenever page is changed. */
  onPaginationChange: PropTypes.func,
};

Cardview.defaultProps = {
  loading: false,
};

export default Cardview;
