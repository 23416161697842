import React from "react";
import Api from "@/api";
import Axios from "axios";
import Constants from "@/config/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder as folderSolid,
  faFolderOpen,
  faFolderPlus,
  faFolderMinus,
  faSearch,
  faDownload,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faFolder as folderRegular } from "@fortawesome/free-regular-svg-icons";
import Gridview from "@/common/gridview";
import moment from "moment";
import { dateToPacific } from "@/utils/dateTime";
import Edit from "./edit";
import Create from "./create";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { CardviewMobile } from "@/common/cardview";
import {
  Container,
  Header,
  Modal,
  Segment,
  Button,
  Dropdown,
  Divider,
  Grid,
  Confirm,
  Responsive,
} from "semantic-ui-react";
import Moment from "react-moment";
import Tableview from "../../../common/tableview";

class LicensesPage extends React.Component {
  Constants = {
    SEARCH_ON_LICENSE_TYPE: Object.freeze([
      { key: "all-search", text: "All", value: "" },
      { key: "item-license", text: "Item License", value: "ITEM_LICENSE" },
      { key: "site-license", text: "Site License", value: "SITE_LICENSE" },
    ]),
    SEARCH_ON_LICENSE_STATUS: Object.freeze([
      { key: "all-items", text: "All", value: "" },
      { key: "item-active", text: "Active", value: "ACTIVE" },
      { key: "item-disabled", text: "Disabled", value: "DISABLED" },
    ]),
  };
  constructor(props) {
    super(props);

    this.state = {
      licenseType: "",
      licenseStatus: "",
      selectedItem: [],
      deleteWarning: false,
      date_format: "YYYY-MM-DD hh:mm A",
      createMode: false,
      editMode: false,
      editId: null,
      columns: [
        { name: "License Name", key: "name", width: "2", showTooltip: true, cb: () => {
          this.sortByHeader("name", "License Name");
        }, },
        { name: "Version", key: "version", width: "2", showTooltip: true, cb: () => {
          this.sortByHeader("version", "Version");
        }, },
        { name: "Type", key: "type", width: "3", showTooltip: true, cb: () => {
          this.sortByHeader("type", "Type");
        }, },
        { name: "Status", key: "status", width: "4", showTooltip: true, cb: () => {
          this.sortByHeader("status", "Status");
        }, },
        { name: "Created On", key: "modificationDate", width: "2", showTooltip: true, cb: () => {
          this.sortByHeader("modificationDate", "Created On");
        }, },
        { name: "Actions", key: "actions", width: "3" },
      ],
      responsive_columns: [
        { name: "License Name", key: "name", width: "2" },
        { name: "Version", key: "version", width: "2" },
        { name: "Type", key: "type", width: "3" },
        { name: "Status", key: "status", width: "4" },
        { name: "Created On", key: "modificationDate", width: "2" },
      ],
      listings: { content: [], number: 0 },
      errorMsg: null,
      showError: false,
      itemLoadXHR: false,
      isDescSearch: true,
      sortBy: "creationDate,DESC",
    };
  }

  componentDidMount() {
    this.getList();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.state.columns = this.state.columns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getList();
    });
   
  };
  
  getList = () => {
    const { pageNum, licenseStatus, sortBy } = this.state;
    let status = licenseStatus ? licenseStatus : "all";
    let params = {
      params: {
        page: pageNum,
        sort: sortBy,
        status
      },
    };
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_LICENSE_LIST, params)
      .then((resp) => {
        this.setState({
          listings: this.transformData(resp.data),
        });
      });
  };
  showDeleteWarningAndSetItem = (item) => {
    this.setState({
      selectedItem: item,
      deleteWarning: true,
    });
  };
  hideDeleteWarning = (cb) => {
    this.setState({ deleteWarning: false }, () => {
      if (cb instanceof Function) cb();
    });
  };
  onDelete = () => {
    const { URL_LICENSE_REMOVE } = Constants.App;
    const { selectedItem } = this.state;

    Api.AxiosInstance.getInstance()
      .get(`${URL_LICENSE_REMOVE}?id=${selectedItem.id}`)
      .then((resp) => {
        this.setState({ selectedItems: [] }, this.getList);
      })
      .catch((err) => {
        this.setState(
          {
            showError: true,
            errorMsg: "An error occured while deleting the item(s)",
            selectedItems: [],
          },
          this.getList
        );
      });
  };
  transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.actions = (
          <React.Fragment>
            {
              <Link to={`/admin/licenses/edit/${row.id}`}>
                <span
                  style={{ margin: "5px" }}
                  className="icon-edit"
                  title="Edit"
                  name="edit"
                />
              </Link>
            }

            <a onClick={() => this.showDeleteWarningAndSetItem(row)}>
              {
                <span
                  style={{ margin: "5px" }}
                  className="icon-delete"
                  title="Remove"
                  name="remove"
                />
              }
            </a>
            <a href="#" onClick={(e) => this.download(e, row.id)}>
              {
                <span
                  className="icon-download"
                  style={{ margin: "5px" }}
                  title="Download"
                  name="download"
                />
              }
            </a>
          </React.Fragment>
        );
        row.modificationDate = (
          <> {dateToPacific(el.modificationDate, "MM/DD/YYYY hh:mm a")}</>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  onPageChange = (e) => {
    this.setState({ pageNum: e.page }, this.getList);
  };

  search = () => {
    this.setState({ pageNum: 0 }, this.getList);
  };

  create = () => {
    this.setState({
      createMode: true,
      listings: { content: [], number: 0 },
    });
  };

  edit = (e, id) => {
    e.preventDefault();
    this.setState({
      editMode: true,
      editId: id,
      listings: { content: [], number: 0 },
    });
  };

  remove = (e, id) => {
    e.preventDefault();
    let params = {
      params: {
        id,
      },
    };
    if (window.confirm("Are you sure you wish to delete this license?")) {
      return Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_LICENSE_REMOVE, params)
        .then((resp) => {
          this.getList();
        });
    }
  };

  download = (e, id) => {
    e.preventDefault();
    let params = {
      params: {
        id,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_LICENSE_DOWNLOAD, params)
      .then((resp) => {
        let win = window.open(resp.data, "_blank");
        win.focus();
      })
      .catch((err) =>
        this.setState({
          showError: true,
          errorMsg: "An error occured while downloading the license.",
        })
      );
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  onSearchTypeChange = (event, data) => {
    const { licenseType } = this.state;
    this.setState({ licenseType: data.value });
  };
  onSearchStatusChange = (event, data) => {
    const { licenseStatus } = this.state;
    this.setState({ licenseStatus: data.value });
  };
  createCancellationHandler = (e) => {
    if (e) e.preventDefault();
    this.setState({
      createMode: false,
    });
    this.getList();
  };
  editCancellationHandler = (e) => {
    e.preventDefault();
    this.setState({
      editMode: false,
      editId: null,
    });
    this.getList();
  };

  render() {
    const { errorMsg, showError } = this.state;
    const { SEARCH_ON_LICENSE_TYPE } = this.Constants;
    return (
      <React.Fragment>
        {this.state.listings.content &&
          !this.state.editMode &&
          !this.state.createMode && (
            <Segment className="base-segment-wrapper">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    largeScreen={8}
                  >
                    <div className="header">
                      <div className="side-marker" />
                      Licenses
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    largeScreen={8}
                  >
                    <div className="control-action-wrapper">
                      <Link to="/admin/licenses/create">
                        <button type="button" className="button-basic">
                          Add New
                        </button>
                      </Link>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Grid>
                <Grid.Row className="common-filter-wrapper ui form">
                  <Grid.Column
                    mobile={16}
                    tablet={4}
                    computer={4}
                    largeScreen={4}
                  >
                    <div class="field">
                      <label>
                        <b>Status:</b>
                      </label>
                      <div class="ui input">
                        <Dropdown
                          options={this.Constants.SEARCH_ON_LICENSE_STATUS}
                          selection
                          defaultValue={this.state.licenseStatus}
                          onChange={this.onSearchStatusChange}
                          placeholder="All"
                        />
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={4}
                    computer={4}
                    largeScreen={4}
                  >
                    <div class="field">
                      <label>&nbsp;</label>
                      <input
                        type="button"
                        className="button-primary"
                        value="Filter"
                        onClick={this.search}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Grid>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Responsive minWidth={1024}>
                      <div className="table-wrapper-with-pagination">
                        <Tableview
                          paginate
                          data={this.state.listings}
                          columns={this.state.columns}
                          onPaginationChange={this.onPageChange}
                          noDataMessage="No items"
                        />
                      </div>
                    </Responsive>
                    <Responsive maxWidth={1023}>
                      <CardviewMobile
                        data={this.state.listings}
                        columns={this.state.responsive_columns}
                        paginate
                        onPaginationChange={(e) => this.onPageChange(e)}
                      />
                    </Responsive>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          )}
        {this.state.createMode && (
          <Create createCancellationHandler={this.createCancellationHandler} />
        )}
        {this.state.editMode && (
          <Edit
            id={this.state.editId}
            editCancellationHandler={this.editCancellationHandler}
          />
        )}
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.onDelete)}
          content="Are you sure you want to delete this license?"
        />
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default LicensesPage;
