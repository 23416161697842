import React from "react";
import ProductsContext from "../_context";
import { Grid, Segment, GridRow, Icon, Message } from "semantic-ui-react";
import DownloadItem from "../components/download-items";
import ProductConstants from "../../constants";
import Api from "@api";
import Constants from "@constants";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { configSelector } from "../../../../common/selectors";

class Download extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      itemLoaded: false,
      itemIsLoading: true,
      redirect: false,
      invalidItem: false,
    };
  }

  componentDidMount() {
    this.loadItem();
  }

  loadItem() {
    // due to url change not using params
    const itemTokenOld =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
        ? this.props.match.params.id
        : false;

    let path = window.location.href;
    const itemToken = path.substring(path.lastIndexOf('/') + 1);
    if (!itemToken) {
      this.setState({
        invalidItem: true,
      });
    }
    const { URL_ITEMS_DETAILS } = Constants.App;
    const instance = Api.AxiosInstance.getInstance();
    instance
      .get(URL_ITEMS_DETAILS + "/" + itemToken)
      .then((resp) => {
        this.setState({
          item: resp.data,
          itemLoaded: true,
          itemIsLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          itemIsLoading: false,
          invalidItem: true,
        });
      });
  }

  goToProducts = () => {
    this.setState({ redirect: true });
  };

  render() {
    const { item, itemLoaded, itemIsLoading, redirect, invalidItem, } =
      this.state;
    const { config } = this.props;

    if (redirect) {
      return <Redirect to={ProductConstants.URL_BROWSER} />;
    }
    return (
      <>
        <div className="products-container">
          <Segment className="base-segment-wrapper">
            <Grid>
              <GridRow className="products-header">
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="side-marker" />
                  <div className="header inline">Item Info</div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                ></Grid.Column>
              </GridRow>

              <GridRow>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                >
                  {invalidItem && (
                    <Message negative>
                      You do not have permission to access this item!
                    </Message>
                  )}
                  {itemIsLoading && (
                    <>
                      <Icon loading name="spinner" /> Loading details!
                    </>
                  )}
                  {itemLoaded && item && item.id && (
                    <DownloadItem
                      item={item}
                      cancel={this.goToProducts}
                      configParam={config}
                    />
                  )}
                </Grid.Column>
              </GridRow>
            </Grid>
          </Segment>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    config: configSelector(state, props),
  };
};

const mappedDownload = connect(mapStateToProps)(Download);

export default mappedDownload;

//export default ProductsContext.Consumer(mappedDownload);
