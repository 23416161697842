import Api from "@/api";
import { InputField } from "@/common/Form";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Form, Grid, Segment, Confirm, Divider } from "semantic-ui-react";
import { validate } from "validate.js";
import { normalValidation } from "./../../../../validation";

class AddUserTypes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: null,
      description: null,
      accessRole: "websilo-customer",
      createdBy: null,
      modifiedBy: null,
      creationDate: null,
      modificationDate: null,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: "",
      msgSpanText: "",
      msgSpanColor: "black",
      itemLoadXHR: false,
      cancelButton: "Cancel",
      popupWarning: false,
    };
  }

  componentDidMount() { }

  save = () => {
    const {
      name,
      description,
      accessRole,
      createdBy,
      modifiedBy,
      creationDate,
      modificationDate
    } = this.state;
    let data = {
      name,
      description,
      accessRole,
      createdBy,
      modifiedBy,
      creationDate,
      modificationDate
    };

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_USER_TYPE_SAVE, data)
      .then(resp => {
        let msg = "";
        let color = "black";
        if (resp.data.id > 0) {
          msg = "New User Type Added";
          color = "green";
        } else {
          msg = "Request failed, Please try later.";
          color = "red";
        }

        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
          cancelButton: "Back to Listing"
        });
      });
  };

  handleChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value
    });
  };
  showpopupWarning = () => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (result) {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red"
      });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }
  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  validateForm = event => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red"
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">
            User Type Management
            <Link to="/admin/user-types-listing" className="btn-back-main">
              <FontAwesomeIcon icon={faArrowLeft} title="Back" name="back" />
              &nbsp;Back
            </Link>
          </div>
          <div className="empty-height-small" />

          <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Name"
                    errorMsg={this.state.validationMsg.name}
                    isError={this.state.validationMsg.name}
                    name="name"
                    id="name"
                    maxlength="32"
                    value={this.state.name}
                    onChange={this.handleChange}
                    isRequired
                    placeholder="Enter User Type"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Description"
                    errorMsg={this.state.validationMsg.description}
                    isError={this.state.validationMsg.description}
                    name="description"
                    id="description"
                    maxlength="50"
                    value={this.state.description}
                    onChange={this.handleChange}
                    placeholder="Enter User Type Description"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label style={{ fontWeight: "bold" }}>
                    Access Role <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id="accessRole"
                    name="accessRole"
                    value={this.state.accessRole}
                    onChange={this.handleChange}
                  >
                    <option value="websilo-sysadmin">websilo-sysadmin</option>
                    <option value="websilo-customer">websilo-customer</option>
                    <option value="websilo-employee">websilo-employee</option>
                    <option value="websilo-account-approver">
                      websilo-account-approver
                    </option>
                    <option value="websilo-admin">websilo-admin</option>
                    <option value="websilo-representative">
                      websilo-representative
                    </option>
                    <option value="websilo-non-nda-customer">
                      websilo-non-nda-customer
                    </option>
                  </select>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="submit"
                    value="Save"
                    className="button-primary"
                    onClick={this.showpopupWarning}
                  />
                  <Link to="/admin/user-types-listing">
                    <button className="button-basic">
                      {this.state.cancelButton}
                    </button>
                  </Link>
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content={"Are you sure you want to add new user type?"}
        />
      </React.Fragment>
    );
  }
}

export default AddUserTypes;
