import { CardviewMobile } from "@/common/cardview";
import Tableview from "@/common/tableview";
import Api from "@api";
import urls from "@constants";
import update from "immutability-helper";
import { merge } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Button, Grid, Modal, Responsive } from "semantic-ui-react";

class licenseListing extends React.Component {
  Constants = {
    COLUMNS: [
      { name: "License Name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "License Name");
      }, },
      { name: "Status", key: "status", showTooltip: true, cb: () => {
        this.sortByHeader("status", "Status");
      }, },
      { name: "Item Type", key: "type", showTooltip: true, cb: () => {
        this.sortByHeader("type", "Item Type");
      }, },
    ],
    RESPONSIVE_COLUMNS: [
      { name: "License Name", key: "name" },
      { name: "Status", key: "status" },
      { name: "Item Type", key: "type" },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      licenseList: {},
      configOption: {},
      selectedPackages: [],
      isDescSearch: true,
      sortBy: "",
    };
  }

  componentDidMount() {
    this.fetchLicenses();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.COLUMNS = this.Constants.COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchLicenses();
    });
   
  };

  fetchLicenses = () => {
    const { URL_LICENSE_LIST } = urls.App;
    let { configOption } = this.state;
    configOption = merge(configOption,{sort:this.state.sortBy}) 
    const { entity, entityId } = this.props;

    const paramsForPerspective = {
      entity,
      entityId,
    };
    configOption.type = "ITEM_LICENSE";
    configOption.status = "ACTIVE";

    // entityId concat configOptions
    const params = {
      params: merge(
        configOption,
        entity && entityId ? paramsForPerspective : {}
      ),
    };
    const parameterizedUrl = `${URL_LICENSE_LIST}`;
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(parameterizedUrl, params).then((resp) => {
      resp.data.content = resp.data.content.filter((_d) => !this.state.selectedPackages.find((_p) => _p.id == _d.id));
      resp.data.content = resp.data.content.filter((_d) => !this.props.assignedLicense.content.find((_p) => _p.id == _d.id));
      this.setState({
        licenseList: resp.data,
      });
    });
  };
  onItemSelection = (e) => {
    this.setState({ selectedPackages: e.data });
  };

  onPageChange = (e) => {
    const { configOption } = this.state;
    this.setState(
      { configOption: update(configOption, { page: { $set: e.page } }) },
      this.fetchLicenses
    );
  };

  onSearchClicked = () => {
    // always fetch the first page while searching.
    // You never know if it has got more than one page data or not.
    this.onPageChange({ page: 0 });
  };

  onSearchTextChange = (event) => {
    const { configOption } = this.state;
    this.setState({
      configOption: update(configOption, {
        search: { $set: event.target.value },
      }),
    });
  };

  resetSearchConfig = () => {
    this.setState({ configOption: {} });
  };

  onModalClose = () => {
    this.resetSearchConfig();
    const { onDismiss } = this.props;
    if (onDismiss) onDismiss();
  };

  render() {
    const { selectedPackages, licenseList } = this.state;
    const { onSave, show, selectionType } = this.props;
    const { COLUMNS, RESPONSIVE_COLUMNS } = this.Constants;

    return (
      <Modal
        open={show}
        onMount={this.fetchLicenses}
        onClose={this.onModalClose}
        centered={true}
        size="large"
      >
        <Modal.Header>List of Licenses</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row className="common-filter-wrapper ui form">
              <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6}>
                {" "}
                <div class="field">
                  <input
                    placeholder="Search"
                    className="search-bar"
                    onChange={(e) => this.onSearchTextChange(e)}
                  ></input>
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6}>
                {" "}
                <div class="field">
                  <button
                    className="button-primary"
                    onClick={this.onSearchClicked}
                  >
                    Search
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination mt-2">
                    <Tableview
                      paginate
                      selectionType={selectionType}
                      data={licenseList}
                      columns={COLUMNS}
                      onSelectionChange={this.onItemSelection}
                      onPaginationChange={this.onPageChange}
                      noDataMessage="No License found."
                    />
                  </div>
                </Responsive>

                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    paginate
                    selectionType={selectionType}
                    data={licenseList}
                    columns={RESPONSIVE_COLUMNS}
                    onSelectionChange={this.onItemSelection}
                    onPaginationChange={this.onPageChange}
                    noDataMessage="No License found."
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="mt-2"
              >
                <Button
                  className="button-primary m--no-margin-left-im"
                  onClick={() => {
                    this.resetSearchConfig();
                    onSave(selectedPackages);
                  }}
                >
                  Save
                </Button>
                <Button className="button-basic" onClick={this.onModalClose}>
                  Close
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

licenseListing.propTypes = {
  /** Callback which is fired when Popup is closed. */
  onDismiss: PropTypes.func.isRequired,
  /** Callback which is fired when `resend` button is clicked. */
  onSave: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
  /** single-select = radio buttons, multi-select = checkboxes */
  selectionType: PropTypes.oneOf(["single-select", "multi-select"]),
  /** Perspective: */
  entity: PropTypes.oneOf(["PackageItem", "ItemPackage"]),
  /** */
  entityId: PropTypes.number,
};

licenseListing.defaultProps = {
  selectionType: "single-select",
};

export default licenseListing;
