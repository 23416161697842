import React, { useState, useEffect } from 'react';
import {
  Segment,
  Sidebar,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import { notificationMarkAsRead } from '../../../api/notifications';
import { NotificationsConnect } from '../_context';
import NotificationsContent from './notifications-content';

const NotificationsSidebar = (props) => {
  const {
    visible,
    state,
    actions,
    updateNotificationCountToMain,
    showHideMainOverlay,
    hideSidebarNotifications,
    user
  } = props;
  const { notifications } = state;
  const { content } = notifications;

  const [recent, earlier] = content ? content.reduce((agg, row) => {
    const indexToPush = agg[2] || row.isRead ? 1 : 0;
    agg[indexToPush].push({ ...row });

    // once you get a read notification, move the rest to earlier.
    if (indexToPush === 1) agg[2] = true;

    return agg;
  }, [[], [], false]) : [[], []];

  const [isLoggedIn, setIsLoggedIn] = useState(user.isLoggedIn);

  const refreshNotifications = () => {
    if (isLoggedIn) {
      actions.listNotifications();
      actions.countNotifications();
      updateNotificationCountToMain();
    }
  }

  const markAllAsSeen = () => {
    if (window.confirm('Mark all as seen?')) {
      const notificationIds = content && content.length > 0 ? content.map((notification) => notification.id) : false;

      if (notificationIds) {
        notificationMarkAsRead(notificationIds).then(() => {
          refreshNotifications();
        });
      }
    }
  }
  
  const markAsSeen = (notificationIds = []) => {
    if (notificationIds) {
      notificationMarkAsRead(notificationIds).then(() => {
        refreshNotifications();
      });
    }
  }

  const onClickAllNotifications = () => {
    showHideMainOverlay(false);
    hideSidebarNotifications();
  }

  useEffect(() => {
    refreshNotifications();
    const intervalID = setInterval(() => {
      refreshNotifications();
    }, 10000);

    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    setIsLoggedIn(user.isLoggedIn);
  }, [user]);

  return (<React.Fragment>
    <Sidebar
      as={Segment}
      // animation='overlay'
      direction='right'
      visible={visible}
      className="notifications-sidebar base-segment-wrapper"
    >
      <NotificationsContent recent={recent} earlier={earlier} markAsSeen={markAsSeen} markAllAsSeen={markAllAsSeen}
        state={state} showAllNotificationsLink={true} onClickAllNotifications={onClickAllNotifications} />
      {state && state.isLoading && <Dimmer active inverted className={'notifications-loader'}>
        <Loader />
      </Dimmer>}
    </Sidebar>
  </React.Fragment>);
};

export default (props) => <NotificationsConnect {...props} Component={NotificationsSidebar} />;