import React from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ component, ...rest }) {
  const { isLoggedIn, fallback } = rest;

  // return <Route {...rest} render={() => React.createElement(component)} />;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          React.createElement(component)
        ) : (
          <Redirect
            to={{
              // pathname: "/login",
              pathname: fallback, search: (fallback === "/login" ? location.search : null),
              state: { from: location }
            }}
          />

        )
      }
    />
  );
}

export default PrivateRoute;
