import Notifications from "./notifications";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const baseUrl = "/notifications";

const mapStateToProps = (state) => ({
  user: state.user,
  config: state.common.config,
  baseUrl: baseUrl,
});

const mappedNotifications = connect(mapStateToProps, mapDispatchToProps)(Notifications);

export default {
  name: "Notifications",
  path: baseUrl,
  component: mappedNotifications,
  navbarVisibility: false,
};
