import Api from "@/api";
import { FileField, InputField } from "@/common/Form";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, Grid, Modal, Segment, Confirm } from "semantic-ui-react";
import { validate } from "validate.js";
import { normalValidation } from "./../validations/platform";

class PlatformsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: null,
      description: null,
      image: null,
      createdBy: null,
      modifiedBy: null,
      creationDate: null,
      modificationDate: null,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: "",
      msgSpanText: "",
      msgSpanColor: "black",
      itemLoadXHR: false,
      deleteWarning: false,
      popupWarning: false,
    };
  }

  componentDidMount() {
    if (this.props.id != null) {
      Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_ADMIN_PLATFORM_SINGLE + "?id=" + this.props.id)
        .then((resp) => {
          let data = resp.data;
          this.setState({
            id: data.id,
            name: data.name,
            description: data.description,
            image: data.image,
            createdBy: data.createdBy,
            modifiedBy: data.modifiedBy,
            creationDate: data.creationDate,
            modificationDate: data.modificationDate,
          });
        });
    }
  }

  save = () => {
    let data = {
      id: this.props.id ? this.props.id : null,
      name: document.getElementById("name").value,
      description: document.getElementById("description").value,
      image: this.state.image,
      createdBy: this.state.createdBy,
      modifiedBy: this.state.modifiedBy,
      creationDate: this.state.creationDate,
      modificationDate: this.state.modificationDate,
    };

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_PLATFORM_SAVE, data)
      .then((resp) => {
        let msg = "";
        let color = "black";
        if (resp.data.id > 0) {
          msg = this.props.id ? "Platform updated" : "New Platform Added";
          color = "green";
        } else {
          msg = "Request failed, Please try later.";
          color = "red";
        }
        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
        });
        document.getElementById("cancel_button").textContent =
          "Back to Listing";
        document.getElementById("cancel_button").classList.add("positive");
      })
      .catch((err) => {
        this.setState({
          errorMsg: "Something went wrong while saving the platform.",
          showError: true,
          loading: false,
        });
      });
  };

  onFileChange = (event, eventData) => {
    if(eventData.value[0]){
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        image: reader.result,
      });
    };
    reader.readAsDataURL(eventData.value[0]);}
  };

  showpopupWarning = () => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (result) {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red",
      });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  handleChange = (e) => {
    let value = e.target.value;
    if (value.length < 1) {
      value = null;
    }
    this.setState({
      [e.target.name]: value,
    });
  };

  validateForm = (event) => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red",
      });
    }
  };
  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  render() {
    const { showError, errorMsg } = this.state;
    let iconLabel = "Upload Icon Image";
    if (this.props.id) {
      iconLabel = "Change Icon Image";
    }
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">
            Platform Management
            <a
              href="#"
              className="btn-back-main"
              onClick={this.props.createCancellationHandler}
            >
              <FontAwesomeIcon icon={faArrowLeft} title="Back" name="back" />
              &nbsp;Back
            </a>
          </div>
          <div className="empty-height-small" />

          <Form loading={this.state.loading} autocomplete="off">
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Name"
                    errorMsg={this.state.validationMsg.name}
                    isError={this.state.validationMsg.name}
                    name="name"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    maxlength="32"
                    isRequired
                    placeholder="Enter Platform Name"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Description"
                    errorMsg={this.state.validationMsg.description}
                    isError={this.state.validationMsg.description}
                    name="description"
                    id="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    maxlength="50"
                    placeholder="Enter Platform Description"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.props.id && (
                    <div>
                      <span style={{ fontWeight: "bold" }}>Icon</span>
                      <div className="ico-img-wrapper">
                        <img src={this.state.image} />
                        <br />
                      </div>
                    </div>
                  )}
                  <FileField
                    errorMsg="You have not selected any icon image."
                    isError={this.state.validationMsg.image}
                    label={<span>{iconLabel}</span>}
                    name="image"
                    id="image"
                    onChange={this.onFileChange}
                    isRequired
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="submit"
                    value="Save"
                    className="button-primary"
                    onClick={this.showpopupWarning}
                  />
                  &nbsp;&nbsp;
                  <input
                    type="button"
                    value="Cancel"
                    className="button-basic"
                    id="cancel_button"
                    onClick={this.props.createCancellationHandler}
                  />
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content={"Are you sure you want to "+(this.state.id?"update":"add new")+" platform?"}
        />
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default PlatformsForm;