import { CardviewMobile } from "@/common/cardview";
import FileIcon from "@/common/file-icon";
import PackageListing from "@/common/package-selection";
import Tableview from "@/common/tableview";
import Api from "@api";
import PropTypes from "prop-types";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Divider, Grid, Loader, Responsive, Segment } from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";

class PackageList extends React.Component {
  Constants = {
    TABLE_COLUMNS: Object.freeze([
      //      { name: "Package Name", key: "name" },
      { name: "Item Name", key: "itemNameLink", showTooltip: true, cb: () => {
        this.sortByHeader("item.name", "Item Name");
      }, },
      { name: "Added By", key: "createdBy", showTooltip: true, cb: () => {
        this.sortByHeader("createdBy", "Added By");
      }, },
      { name: "Assigned Date", key: "creationDate", showTooltip: true, cb: () => {
        this.sortByHeader("creationDate", "Date");
      }, },
      { name: "Filename", key: "filename", showTooltip: true, cb: () => {
        this.sortByHeader("item.filename", "Filename");
      }, },
      { name: "", key: "icon" },
    ]),
    RESPONSIVE_COLUMNS: Object.freeze([
      //      { name: "Package Name", key: "name" },
      { name: "Item Name", key: "itemNameLink" },
      { name: "Added By", key: "createdBy" },
      { name: "Date", key: "creationDate" },
      { name: "Filename", key: "filename" },
      { name: "", key: "icon" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      data: { content: [] },
      errorMsg: "",
      selectedItems: [],
      showError: false,
      // need to check with pagination:
      page: 0,
      deleteWarning: false,
      showPackageListing: false,
      userId: "",
      field: "sender",
      packageName: "",
      packageId: null,
      status: null,
      prepareDownload: false,
      readyDownload: false,
      errorDownload: false,
      isDescSearch: true,
      sortBy: "item.name,DESC",
    };
  }

  componentDidMount() {
    //    this.getPackageListing();
  }
  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.TABLE_COLUMNS = this.Constants.TABLE_COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getPackageListing();
    });
   
  };

  getPackageListing = () => {
    const { page, packageId, status } = this.state;

    if (!packageId) {
      return;
    }

    let body = { id: packageId };

    let params = {
      params: {
        page,
        sort: this.state.sortBy,
      },
    };

    this.getData(
      body,
      params,
      (resp) => {
        this.setState({
          data: this.dataMapper(resp.data),
          errorDownload: false,
          prepareDownload: false,
          readyDownload: false,
        });
      },
      (err) => {
        this.setState({
          data: { content: [] },
          errorDownload: false,
          prepareDownload: false,
          readyDownload: false,
        });
      }
    );
  };

  getData = (body, params, success, failure) => {
    const { fetchUrl } = this.props;
    Api.AxiosInstance.getInstance()
      .post(fetchUrl, body, params)
      .then(success)
      .catch(failure);
  };

  dataMapper = (data) => {
    const { field } = this.state;
    let transformedData = Object.assign({}, data);
    let content = [];
    // add changes if needed, like item or file types etc
    transformedData.content.map((el) => {
      let row = Object.assign({}, el);
      // TODO: need to add condition for multiple files as well
      row.icon = <FileIcon filename={row.filename ? row.filename : "Other"} />;
      row.creationDate = (
        <>{dateToPacific(row.creationDate, "MM/DD/YYYY hh:mm a")}</>
      );
      row.itemNameLink = (
        <Link to={"/products/browser/1/items/" + row.itemId + "/edit"}>
          {row.itemName}
        </Link>
      );
      content.push(row);
    });
    transformedData.content = content;
    return transformedData;
  };
  onSelectPackage = (e) => {
    e.preventDefault();
    this.setState({ showPackageListing: true });
  };
  onSavePackageListing = (user) => {
    user.map((data) =>
      this.setState({
        showPackageListing: false,
        packageId: data.id,
        packageName: data.name,
      })
    );
  };
  onDismissPackageListing = () => {
    this.setState({ showPackageListing: false });
  };

  download = (e, id) => {
    e.preventDefault();
    //TODO : add whatever we need to do for downloading csv files...
    const { packageId, page, prepareDownload, readyDownload, errorDownload } =
      this.state;

    if (!prepareDownload && !errorDownload && !!readyDownload) {
      window.open(readyDownload);
      return;
    }

    let body = { id: packageId };

    let params = {
      timeout: 0,
      params: {
        download: 1,
        page,
        sort: "item.name,DESC",
      },
    };

    this.setState({
      prepareDownload: true,
    });
    this.getData(
      body,
      params,
      //success
      (resp) => {
        this.setState({
          prepareDownload: false,
          readyDownload: resp.data,
        });
      },
      //error
      (err) => {
        //Temporary Hack
        this.setState({
          prepareDownload: false,
          errorDownload: "Unable to prepare file for download.",
        });
      }
    );
  };

  onSearchTyping = (event, data) => {
    this.setState({
      packageName: event.target.value,
    });
  };

  onPageChange = (e) => {
    const { urlParams } = this.state;
    this.setState(
      {
        page: e.page,
      },
      this.getPackageListing
    );
  };

  onItemsSelection = (event) => {
    this.setState({ selectedItems: event.data });
  };

  onDropDownChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  clearState = (e) => {
    //Temporary hack: need to do something else
    this.setState(
      {
        data: { content: [] },
        errorMsg: "",
        selectedItems: [],
        showError: false,
        // need to check with pagination:
        page: 0,
        deleteWarning: false,
        showPackageListing: false,
        userId: "",
        field: "sender",
        packageName: "",
        packageId: null,
        status: null,
        prepareDownload: false,
        readyDownload: false,
        errorDownload: false,
        prepareDownload: false,
        readyDownload: false,
        errorDownload: false,
        // data: { content: [] },
      },
      this.getPackageListing
    );
  };

  render() {
    const { title } = this.props;
    const {
      packageName,
      data,
      errorMsg,
      showError,
      prepareDownload,
      readyDownload,
      errorDownload,
    } = this.state;

    const disableDownloadButton = prepareDownload || errorDownload;

    const { TABLE_COLUMNS, RESPONSIVE_COLUMNS } = this.Constants;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column mobile={10} tablet={10} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" />
                  {title}
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={6} computer={8} largeScreen={8}>
                <div className="control-action-wrapper">
                  <button
                    className="button-basic "
                    title="Click to Download"
                    disabled={disableDownloadButton}
                    onClick={this.download}
                  >
                    {!prepareDownload && !readyDownload && "Download"}
                    {!!prepareDownload && !readyDownload && (
                      <Loader>Loading</Loader>
                    )}
                    {!!prepareDownload && !readyDownload && "Preparing File"}
                    {!prepareDownload &&
                      !!readyDownload &&
                      "File Ready! Download Now!"}
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row className="common-filter-wrapper ui form">
              <Grid.Column tablet={6}
                computer={6}
                largeScreen={6}
                wideScreen={4}>
                <div class="field">
                  <div class="ui input">
                    {/* reason of making this field readonly is that we need packageId to be send for the call*/}
                    <input
                      readonly="readonly"
                      placeholder="Select Package"
                      className="search-bar"
                      onChange={this.onSearchTyping}
                      value={packageName}
                    ></input>
                  </div>
                  <a
                    href="#"
                    target="_blank"
                    onClick={(e) => this.onSelectPackage(e)}
                  >
                    Select Package
                  </a>
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={16}
                tablet={6}
                computer={6}
                largeScreen={6}
                wideScreen={4}
                className="button-inline-display"
              >
                <div class="field">
                  <div class="ui input">
                    <button
                      onClick={this.getPackageListing}
                      className="button-primary m--no-margin-left-im"
                    >
                      Search
                    </button>{" "}
                  </div>
                </div>{" "}
                <div class="field">
                  <div class="ui input">
                    <button
                      onClick={(e) => this.clearState(e)}
                      className="button-basic"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Divider />
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination">
                    <Tableview
                      columns={TABLE_COLUMNS}
                      data={data}
                      paginate
                      onPaginationChange={this.onPageChange}
                    />
                  </div>
                </Responsive>
                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    data={data}
                    columns={RESPONSIVE_COLUMNS}
                    paginate
                    onPaginationChange={(e) => this.onPageChange(e)}
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <PackageListing
          show={this.state.showPackageListing}
          onDismiss={this.onDismissPackageListing}
          onSave={this.onSavePackageListing}
          selectionType="single-select"
          title="List of Package"
        />
      </React.Fragment>
    );
  }
}

PackageList.propTypes = {
  /** URL this component will use to fetch latest data */
  fetchUrl: PropTypes.string.isRequired,
  /** Text which will be displayed at the top of the page. */
  title: PropTypes.string.isRequired,
};

export default withRouter(PackageList);
