export default {
  'icon-logo-2': 5,
  'icon-group-5': 0,
  'icon-g1': 3,
  'icon-file-link': 4,
  'icon-g1-state1': 3,
  'icon-g4-female': 2,
  'icon-g4-female-state3': 2,
  'icon-g4-female-state2': 2,
  'icon-g3-state1': 2,
  'icon-g3': 3,
  'icon-g3-state2': 3,
  'icon-g2-state1': 2,
  'icon-g1-state2': 2,
  'icon-g2': 2,
  'icon-other': 0,
  'icon-others-files': 2,
  'icon-svg': 6,
  'icon-png': 6,
  'icon-winzip': 12,
  'icon-zip-compressed-file-format-interface-symbol': 0,
  'icon-solaris': 2,
  'icon-microsoft': 4,
  'icon-linux': 3,
  'icon-iconfinder-137-freebsd-logo-logos-4373155': 0,
  'icon-powerpoint': 5,
  'icon-excel': 4,
  'icon-word': 4,
  'icon-pdf': 3,
  'icon-group-12': 2,
  'icon-file-copy': 3,
  'icon-cpio-copy-2': 26,
  'icon-rdf-logo': 7,
  'icon-rpm-file-format-symbol': 0,
  'icon-group-11': 2,
  'icon-java': 5,
  'icon-html-5': 0,
  'icon-cpio-1': 26,
  'icon-cpio': 26,
  'icon-vx-works': 0,
  'icon-group-2-copy': 4,
  'icon-group-2': 2,
  'icon-microsoft-executeable': 4,
  'icon-box-view': 0,
  'icon-list-view': 0,
  'icon-login-arrow': 0,
  'icon-menu': 0,
  'icon-edit': 0,
  'icon-delete': 0,
  'icon-download': 0,
  'icon-search': 0,
  'icon-folder-normal': 0,
  'icon-tree-close-details': 0,
  'icon-tree-open': 0,
  'icon-tree-close': 2,
  'icon-multiple-files': 7,
  'icon-winrar': 21,
  'icon-audio': 2,
  'icon-video': 2,
  'icon-jpg': 6,
  'icon-file-jupyter': 12,
  'icon-file-python': 4,
  'icon-file-iso': 8,
  'icon-debain': 1,
};
