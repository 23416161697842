import Api from "@/api";
import { ErrorMessage } from "@/common/Form";
import { ccpaSelector, gdprSelector } from "@/common/selectors";
import { removeEmptyKeys } from "@/common/utils/utils";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button, Form, Message, Segment } from "semantic-ui-react";
import validate from "validate.js";
import LocalStore from "@/store/persistence/local-storage";

class Policies extends Component {
  VALIDATION_CONFIG = {
    data: {
      presence: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: {},
      redirectTo: false,
      gdpr_text: "",

      data: this.props.gdpr?.gdpr_text,
      // id: props.match.params.id,
      assignedItems: null,
      isSaving: false,
      validationMsg: {},
      showError: false,
      errorMsg: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }
  componentDidMount() {
    console.log("config", this.props.ccpa, this.props.gdpr);
    // this.props.countries

    this.setState({
      data: this.props.gdpr?.gdpr_text,
      gdpr_text: this.props.gdpr?.gdpr_text,
    });
  }
  // updating values
  onChange = (event, eventData) => {
    const { data } = this.state;
    const { name, value } = eventData;
    this.setState({ [name]: value });
  };
  onEditorStateChange = (data) => {
    console.log("data", data);
    this.setState({
      gdpr_text: data,
    });
  };
  onEditorChange(evt) {
    this.setState({
      data: evt.editor.getData(),
    });
  }

  handleChange(changeEvent) {
    this.setState({
      data: changeEvent.target.value,
    });
  }
  validate = () => {
    let result = validate(removeEmptyKeys(this.state), this.VALIDATION_CONFIG);
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };
  saveDetails = () => {
    // const id = get(this.props, ["match", "params", "id"]);
    const { gdpr_text, data } = this.state;
    // validate() will return true incase all fields are valid.
    if (!this.validate()) return;
    // const params = { params: { id } };
    // let url = Constants.App.URL_ADD_VIDEOS_DIR;
    // Intentionally trunctating data ..
    let body = {
      text: data,
    };
    this.setState({ isSaving: true });
    const instance = Api.AxiosInstance.getInstance();
    instance
      .post("/admin/settings/update-gdpr", body, null)
      .then((resp) => {
        this.setState({ isSaving: false }, () => {
          this.loadConfigOptions();
          this.redirectToParent();
          // need to change this into packages
        });
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ isSaving: false, showError: true, errorMsg });
      });
  };

  redirectToParent = () => {
    // Temporary hack to redirect to parent need to send baseUrl via props
    this.setState({
      redirectTo: "/admin",
    });
  };
  loadConfigOptions = () => {
    const token = LocalStore.getValue("token");
    this.props.loadApplicationConfig(token);
  };
  render() {
    const {
      validationMsg,
      isError,
      showError,
      errorMsg,
      isSaving,
    } = this.state;
    const { gdpr, ccpa } = this.props;

    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="header">
            <div className="blue-marker" />
            GDPR Text
          </div>{" "}
          <div>
            <div className="editor-instance">
              <CKEditor
                editor={null}
                data={this.state?.data}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log({ event, editor, data });
                  this.setState({
                    data: data,
                  });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
          </div>
          <Form>
            {/* <Policy
              onChange={this.onChange}
              gdpr_text={this.state.gdpr_text}
              // modifiedBy={ }

              // metaInfo={metaInfo}
              // editMode={true}
              validationMsg={validationMsg}
           /> */}
            <ErrorMessage
              isError={validationMsg.data}
              errorMsg={validationMsg.data}
            />
            {showError && <Message error content={errorMsg} />}
            <div className="row">
              <Button
                type="submit"
                disabled={isSaving}
                onClick={this.saveDetails}
                primary
              >
                {" "}
                Save
              </Button>
              <Button
                type="reset"
                disabled={isSaving}
                onClick={(e) => {
                  e.preventDefault();
                  this.redirectToParent();
                }}
              >
                {" "}
                Cancel
              </Button>{" "}
            </div>
          </Form>
          {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
        </Segment>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadApplicationConfig: (token) => {
      dispatch(Api.Config.loadApplicationConfig(token));
    },
  };
};
const mapStateToProps = (state) => ({
  // user: state.user,
  config: state.common.config,
  ccpa: ccpaSelector(state),
  gdpr: gdprSelector(state),
  // countries: statesSelector(state),

  // baseUrl: baseUrl,
});

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
