import React, { useState } from "react";
import Api from "@api";
import PageTemplate from "../../common/page";
import BriefcaseOutbox from "./pages/briefcase-outbox";
import BriefcaseInbox from "./pages/briefcase-inbox";
import BriefcaseSend from "./pages/briefcase-send";
import DownloadBreifcase from "./pages/download";
import Config from "../../config/constants";

const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;

const Briefcase = ({ authority, baseUrl, userId }) => {

  const [inviteCount, setInviteCount] = useState(0);

  const checkProjectInviteCount = () => {
    Api.AxiosInstance.getInstance().get(Config.App.URL_BRIEFCASE_FILE_RECEIVED_COUNT).then(resp => {
      console.log('invite count', resp)
      setInviteCount(resp.data.count)
    })
  }

  checkProjectInviteCount();

  const ADMIN_MENU_ITEMS = {
    briefcase: [
      {
        name: "Send New Item",
        relativePath: "/",
        exact: true,
        subPageComponent: BriefcaseSend,
      },
      {
        name: "Received Files" + (inviteCount ? ` (${inviteCount})` : ""),
        relativePath: "/inbox/:id",
        clickPath: `/inbox/${userId}`,
        subPageComponent: BriefcaseInbox,
      },
      {
        name: "Sent Files",
        relativePath: `/outbox/:id`,
        clickPath: `/outbox/${userId}`,
        subPageComponent: BriefcaseOutbox,
      },
      {
        name: "Download Breifcase",
        relativePath: `/download/:id`,
        subPageComponent: DownloadBreifcase,
        hide: true,
      },
    ],
  };

  const CUSTOMER_MENU_ITEMS = {
    briefcase: [
      {
        name: "Send New Item",
        relativePath: "/",
        exact: true,
        subPageComponent: BriefcaseSend,
      },
      {
        name: "Received Files" + (inviteCount ? ` (${inviteCount})` : ""),
        relativePath: "/inbox",
        subPageComponent: BriefcaseInbox,
      },
      {
        name: "Sent Files",
        relativePath: "/outbox/",
        subPageComponent: BriefcaseOutbox,
      },
      {
        name: "Download Breifcase",
        relativePath: `/download/:id`,
        subPageComponent: DownloadBreifcase,
        hide: true,
      },
    ],
  };

  if (ADMIN_ROLES.indexOf(authority) >= 0) {
    return <PageTemplate baseUrl={baseUrl} menuItems={ADMIN_MENU_ITEMS} />;
  }
  return <PageTemplate baseUrl={baseUrl} menuItems={CUSTOMER_MENU_ITEMS} />;
};

export default Briefcase;
