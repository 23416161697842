import React from "react";
import { Redirect } from 'react-router';

const DashboardRedirect = () => {
  return(
    <Redirect to="/dashboard" />
  );
};

const LoginRedirect = () => {
  return(
    <Redirect to="/login" />
  );
};

export default {
  DashboardRedirect,
  LoginRedirect,
};