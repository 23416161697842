import React from "react";
import {
  Divider,
  Segment,
  Responsive,
  Accordion,
  Icon
} from "semantic-ui-react";
import {dateToPacific} from "@/utils/dateTime";
import Api from "@/api";
import Constants from "@/config/constants";
import Tableview from "@/common/tableview/tableview";
import {CardviewMobile} from "@/common/cardview";
import { Link } from "react-router-dom";

class ApproveUserDialog extends React.Component {
  COLUMNS = [
    { name: "Username", key: "username", width: "4", showTooltip: true, },
    { name: "Company", key: "company", width: "4", showTooltip: true, },
    { name: "Group", key: "group", showTooltip: true, },
    { name: "Status", key: "status", showTooltip: true, },
    { name: "Date of Joined", key: "joined", width: 6, showTooltip: true, },
    { name: "Action", key: "actions", class: "center" }
  ];

  RESPONSIVE_COLUMNS = [
    { name: "Username", key: "username", width: "4" },
    { name: "Company", key: "company", width: "4" },
    { name: "Group", key: "group" },
    { name: "Status", key: "status" },
    { name: "Date of Joined", key: "joined", width: 6 }
  ]

  constructor(props) {
    super(props);
    this.state = {
      usersList: { content: [] },
      loading: false,
      isCollapsed: true,
      page: 0
    };
  }

  componentDidMount() {
    this.loadUsers();
  }
  loadUsers = options => {
    const { page } = this.state;
    // extra params will be removed once backend req params are set to "not required":
    let params = {
      params: {
        page
        // sort: "creationDate,DESC"
      }
    };
    this.setState({ loading: true });
    return Api.AxiosInstance.paginate(
      Constants.App.URL_USER_APPROVAL_LIST,
      params
    ).then(resp => {
      this.setState({ usersList: resp.data, loading: false });
    });
  };

  onPageChange = e => {
    this.setState({ page: e.page }, this.loadUsers);
  };
  transformData = (data, validationMsg) => {
    const { isSaving } = this.state;
    let usersList = Object.assign({}, data);
    let content = [];

    data.content.map(el => {
      content.push({
        username: el.username,
        actions: (
          <>
            <Link to={`admin/user/edit/`+el.id}>
              <span className="icon-edit" title="Edit" />
            </Link>
          </>
        ),
        company: el.company,
        status: el.status,
        group: el.group,
        joined: <>{dateToPacific(el.joined,"MM/DD/YYYY hh:mm a")}</>
      });
    });
    usersList.content = content;
    return usersList;
  };

  render() {
    const { loading, isCollapsed, usersList, validationMsg } = this.state;
    let transformedList = this.transformData(usersList, validationMsg);
    return (
      <Segment className="dashboard-card">
        <Responsive minWidth={1024}>
          <div className="side-marker" />
          <div className="header">Account Activity</div>

          <div className="table-wrapper">
            <Tableview
              data={transformedList}
              columns={this.COLUMNS}
              paginate
              onPaginationChange={e => this.onPageChange(e)}
              loading={loading}
            />
          </div>
          {transformedList.content && (
            <React.Fragment>
              <Divider />
              <em>Showing recent {transformedList.content.length} records</em>
            </React.Fragment>
          )}
        </Responsive>
        <Responsive maxWidth={1023}>
          <Accordion>
            <Accordion.Title
              onClick={e => this.setState({ isCollapsed: !isCollapsed })}
            >
              <Icon
                name={isCollapsed ? "caret right" : "caret down"}
                color="blue"
              />
              <div className="side-marker" />
              <div className="header">Account Activity</div>
            </Accordion.Title>
            <Accordion.Content active={!isCollapsed}>
              <CardviewMobile
                data={transformedList}
                columns={this.RESPONSIVE_COLUMNS}
                paginate
                onPaginationChange={e => this.onPageChange(e)}
                loading={loading}
              />

              {transformedList.content && (
                <React.Fragment>
                  <Divider />
                  <em>
                    Showing recent {transformedList.content.length} records
                  </em>
                </React.Fragment>
              )}
            </Accordion.Content>
          </Accordion>
        </Responsive>
      </Segment>
    );
  }
}

export default ApproveUserDialog;
