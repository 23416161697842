import React from "react";
import PageTemplate from "../../common/page";
import LicensesPage from "./components/licenses-page";
import Edit from "./components/edit";
import Create from "./components/create";
const MENU_ITEMS = {
  Licenses: [
    {
      name: "Licenses",
      relativePath: "/",
      exact: true,
      subPageComponent: LicensesPage,
    },
    {
      name: "Licenses Edit",
      relativePath: "/edit/:id",
      subPageComponent: Edit,
      hide: true,
    },
    {
      name: "Licenses Create",
      relativePath: "/create",
      subPageComponent: Create,
      hide: true,
    },
  ],
};

const LICENCES = ({ baseUrl }) => {
  return <PageTemplate baseUrl={baseUrl} menuItems={MENU_ITEMS} />;
};

export default LICENCES;
