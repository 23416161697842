import React from "react";
import PageTemplate from "@/common/page";
import NDAList from "./pages/nda-list";
import PendingNDA from "./pages/pending-nda";
import NDAForm from "./pages/nda-form";
import NonStandardNDAList from "./pages/nda-non-standard-list";
import NDATemplate from "./pages/nda-template";
import NDACreateForm from "./pages/nda-create-form";
import NDANonStandardForm from "./pages/nda-non-standard-form";

const MENU_ITEMS = {
  NDAs: [
    {
      name: "Standard NDA List",
      relativePath: "/",
      exact: true,
      subPageComponent: NDAList,
    },
    {
      name: "Non-Standard NDA List",
      relativePath: "/non-standard",
      exact: true,
      subPageComponent: NonStandardNDAList,
    },
    {
      name: "Create Non Standard NDA",
      relativePath: "/non-standard/create",
      subPageComponent: NDANonStandardForm,
    },
    {
      name: "Pending NDAs",
      relativePath: "/pending",
      subPageComponent: PendingNDA,
    },

    {
      name: "Non Standard Edit Form",
      relativePath: "/non-standard/update/:id",
      hide: true,
      subPageComponent: NDANonStandardForm,
    },
    {
      name: "Update NDA",
      relativePath: "/update/:id",
      hide: true,
      subPageComponent: NDAForm,
    },
    {
      name: "Create NDA",
      relativePath: "/create/:token",
      hide: true,
      subPageComponent: NDACreateForm,
    },
    {
      name: "NDA Template",
      relativePath: "/template",
      subPageComponent: NDATemplate,
    },
  ],
};

const NDA = ({ baseUrl }) => {
  return <PageTemplate baseUrl={baseUrl} menuItems={MENU_ITEMS} />;
};

export default NDA;
