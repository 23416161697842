import Actions from "../../actions";

const Config = Actions.Config;

const initialState = {
	maps: {},
	allowedIdleTime: 1000 * 60 * 60,
	idleTimer: null,
	isLoading: false,
	success: false,
	message: '',
	supportEnabled: false,
	supportErrorDialog: false,
	showLoader: false,
	supportErrorMsg: 'Something went wrong, please contact administrator.'
};

export default function (state = initialState, action) {
	const { payload } = action;
	switch (action.type) {
		case Config.CONFIG_LOADING: {
			return {
				...state,
				isLoading: true,
				message: 'Config is loading!'
			}
		}
		case Config.CONFIG_LOADING_SUCCESS: {
			const timeOut = payload['inactivity_timeout']
				? payload['inactivity_timeout']
				: 60;
			const allowedIdleTime = 1000 * 60 * timeOut;
			
			return {
				...state,
				maps: payload,
				isLoading: false,
				message: '',
				success: true,
				allowedIdleTime
			}
		}
		case Config.CONFIG_LOADING_FAILURE: {
			return {
				...state,
				maps: {},
				isLoading: false,
				message: '',
				success: false,
			}
		}
		case Config.CONFIG_SUPPORT_ENABLED: {
			return {
				...state,
				supportEnabled: payload,
			}
		}
		case Config.SHOW_HIDE_ERROR_DIALOG: {
			return {
				...state,
				supportErrorDialog: payload.state,
				supportErrorMsg: payload.errMsg
			}
		}
		case Config.SHOW_HIDE_LOADER: {
			return {
				...state,
				showLoader: payload
			}
		}
		case Config.SHOW_HIDE_OVERLAY: {
			return {
				...state,
				showOverlay: payload
			}
		}
		case Config.SET_IDLE_TIME: {
			const timeOut = payload
				? payload
				: 60;
			const allowedIdleTime = 1000 * 60 * timeOut;

			return {
				...state,
				allowedIdleTime
			}
		}
		case Config.RESTORE_IDLE_TIME: {
			const timeOut = state.maps['inactivity_timeout']
				? state.maps['inactivity_timeout']
				: 60;
			const allowedIdleTime = 1000 * 60 * timeOut;

			return {
				...state,
				allowedIdleTime
			}
		}
		case Config.SET_IDLE_TIMER_REF: {
			return {
				...state,
				idleTimer: payload
			}
		}
		case Config.PAUSE_IDLE_TIMER: {
			state.idleTimer && state.idleTimer.pause();

			return {
				...state
			}
		}
		case Config.RESUME_IDLE_TIMER: {
            state.idleTimer && state.idleTimer.resume()
			return {
				...state
			}
		}
		default:
			return state;
	}
}
