import { nanoid } from "nanoid";

import Token from "./../actions/token";

const defaultState = {
  accessToken: null,
  idToken: null,
  state: null,
  nonce: null,
  oktaLoader: false,
  oktaUserDisabled: false,
  unsubscribe: {
    message: "",
    response: false
  },
};

const tokenReducer = (reduxState = defaultState, { type, payload }) => {
  switch (type) {
    case Token.RECEIVE_ACCESS_TOKEN:
      return {
        ...reduxState,
        accessToken: payload.accessToken,
      };
    case Token.RECEIVE_ID_TOKEN:
      return {
        ...reduxState,
        idToken: payload.idToken,
      };
    case Token.GENERATE_STATE_AND_NONCE:
      return {
        ...reduxState,
        // no need to redefine if it exists in the state already
        state: reduxState.state || nanoid(32),
        nonce: reduxState.nonce || nanoid(32),
      };
    case Token.CLEAR_ALL:
      return defaultState;
    case Token.GETTING_OKTA_TOKEN:
      return {
        ...reduxState,
        oktaLoader: true,
      };
    case Token.RECIEVED_OKTA_TOKEN:
      return {
        ...reduxState,
        oktaLoader: false,
      };
    case Token.OKTA_USER_DISABLED:
      return {
        ...reduxState,
        showLoader: false,
        oktaLoader: false,
        oktaUserDisabled: true,
        oktaErrorOnVerification: payload
      };
    case Token.USER_UNSUBSCRIBE_INITIATE:
      return {
        ...reduxState,
        unsubscribe: {
          message: "",
          response: false, 
        }
      };
    case Token.USER_UNSUBSCRIBE_SUCCESS:
      return {
        ...reduxState,
        unsubscribe: {
          message: payload,
          response: true
        }
      };
    case Token.USER_UNSUBSCRIBE_FAILURE:
      debugger;
      return {
        ...reduxState,
        unsubscribe: {
          message: payload,
          response: false
        }
      };
    default:
      return reduxState;
  }
};

export default tokenReducer;
