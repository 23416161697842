import Api from "@/api";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import update from "immutability-helper";
import { isEmpty } from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Message } from "semantic-ui-react";
import validate from "validate.js";
import VideoForm from "./../components/video-form";

class AddVideoFolder extends React.Component {
  VALIDATION_CONFIG = {
    status: {
      presence: true,
    },
    name: {
      presence: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      options: [],
      data: {
        status: "ACTIVE",
        description: null,
        name: null,
      },
      redirectTo: false,
      isSaving: false,
      validationMsg: {},
      showError: false,
      errorMsg: null,
      name: null,
      description: null,
      status: "ACTIVE",
    };
  }

  componentDidMount() {}

  onChange = (event, eventData) => {
    const { data } = this.state;
    const { name, value } = eventData;
    this.setState({ data: update(data, { [name]: { $set: value } }) });
  };

  redirectToParent = () => {
    // Temporary hack to redirect to parent need to send baseUrl via props
    this.setState({
      redirectTo: "/videos",
    });
  };

  validate = () => {
    let result = validate(
      removeEmptyKeys(this.state.data),
      this.VALIDATION_CONFIG
    );
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };

  saveDetails = () => {
    if (!this.validate()) return;
    // let url = Constants.App.URL_PACKAGE_NEW;
    let url = Constants.App.URL_ADD_VIDEOS_DIR;
    const { data } = this.state;
    this.setState({ isSaving: true });
    const instance = Api.AxiosInstance.getInstance();
    instance
      .post(url, data)
      .then((resp) => {
        this.setState({ isSaving: false }, () => {
          this.redirectToParent();
        });
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ isSaving: false, showError: true, errorMsg });
      });
  };

  render() {
    const { data, validationMsg, isSaving, showError, errorMsg } = this.state;
    return (
      <>
        <Form>
          <VideoForm
            onChange={this.onChange}
            name={data.name}
            description={data.description}
            status={data.status}
            options={this.state.options}
            editMode={true}
            validationMsg={validationMsg}
          />
          <div className="empty-height"></div>
          {showError && <Message error content={errorMsg} />}
          <div className="row">
            <Button
              type="submit"
              disabled={isSaving}
              onClick={this.saveDetails}
              className="button-primary"
            >
              {" "}
              Save
            </Button>
            <Button
              className="button-basic"
              type="reset"
              disabled={isSaving}
              onClick={(e) => {
                e.preventDefault();
                this.redirectToParent();
              }}
            >
              {" "}
              Cancel
            </Button>
          </div>
        </Form>
        {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
      </>
    );
  }
}
export default AddVideoFolder;
