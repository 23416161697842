import Api from "@/api";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import ProductConstants from "@/modules/products/constants";
import update from "immutability-helper";
import { get, isEmpty } from "lodash";
import moment from "moment";
import {dateToPacific} from "@/utils/dateTime";
import React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Confirm,
  Container,
  Form,
  Message,
  Grid,
} from "semantic-ui-react";
import validate from "validate.js";
import PackageDetails from "./../../components/package-detail";
import Items from "./../../components/_items";

class PackageInfo extends React.Component {
  VALIDATION_CONFIG = {
    status: {
      presence: true,
    },
    name: {
      presence: true,
    },
  };
  staticValues = {
    listColumns: [
      { name: "Name", key: "name" },
      { name: "Type", key: "itemType" },
      { name: "Actions", key: "action", width: 2 },
    ],
  };

  constructor(props) {
    super(props);
    //console.log("props package info", props);
    this.state = {
      isLoaded: false,
      data: {},
      redirectTo: false,
      packageId: props.match.params.packageId,
      assignedItems: null,
      isSaving: false,
      validationMsg: {},
      showError: false,
      errorMsg: null,
      popupWarning: false,
    };
  }

  transformResponse = (request) => ({
    action: "UPDATE_PACKAGE",
    name: request.name,
    description: request.description,
    status: request.status,
    publicPackage: request.publicPackage,
    createdBy: request.createdBy,
    creationDate: request.creationDate,
    modifiedBy: request.modifiedBy,
    modifiedDate: request.modifiedDate
      ? dateToPacific(request.modifiedDate,"MM/DD/YYYY hh:mm a")
      : null,
    itemsSimple: request.items,
    items: request.items,
  });

  componentDidMount() {
    const { packageId, data } = this.state;
    if (packageId > 0) {
      // Need to add this constants file
      const { URL_PACKAGE_DETAILS } = Constants.App;
      const params = { params: { id: this.state.packageId,
        page: 0,
        target:"packages",sort:"",direction:"" } };
      const instance = Api.AxiosInstance.getInstance();
      instance.get(URL_PACKAGE_DETAILS, params).then((resp) => {
        this.setState({
          data: update(data, { $set: this.transformResponse(resp.data) }),
          isLoaded: true,
        });
      });
    }
  }
  mapData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);

        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  onChange = (event, eventData) => {
    const { data } = this.state;
    const { name, value } = eventData;
    this.setState({ data: update(data, { [name]: { $set: value } }) });
  };

  redirectToParent = () => {
    // Temporary hack to redirect to parent need to send baseUrl via props
    this.setState({
      redirectTo: ProductConstants.URL_PACKAGES,
    });
  };

  validate = () => {
    let result = validate(
      removeEmptyKeys(this.state.data),
      this.VALIDATION_CONFIG
    );
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };

  showpopupWarning = () => {
    if (!this.validate()) return;
    this.setState(
      {
        popupWarning: true,
      },);
  }
    
  hidepopupWarning = (cb) => {
    this.setState(
      {
         popupWarning: false,
     },
      () => {
          if (cb instanceof Function) cb();
        }
      );
  };

  saveDetails = () => {
    const packageId = get(this.props, ["match", "params", "packageId"]);
    const { data } = this.state;
    // validate() will return true incase all fields are valid.
    if (!this.validate()) return;
    const params = { params: { id: packageId } };
    let url = Constants.App.URL_PACKAGE_DETAILS;
    // Intentionally trunctating data ..
    let body = {
      action: "UPDATE_PACKAGE",
      name: data.name,
      description: data.description,
      status: data.status,
      publicPackage: data.publicPackage
    };
    this.setState({ isSaving: true });
    const instance = Api.AxiosInstance.getInstance();
    instance
      .post(url, body, params)
      .then((resp) => {
        this.setState({ isSaving: false }, () => {
          this.redirectToParent();
          // need to change this into packages
        });
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ isSaving: false, showError: true, errorMsg });
      });
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };
  updatePackages = () => {
    const packageId = get(this.props, ["match", "params", "packageId"]);
    const { action, msg, selectedItem } = this.state;
    const params = { params: { id: packageId } };
    let itemIds = [];
    itemIds.push({ id: selectedItem["id"] });
    //selectedItem.map(item => itemIds.push({ id: item.id }));
    let url = Constants.App.URL_PACKAGE_DETAILS;
    let payload = { action: action, items: itemIds };
    Api.AxiosInstance.getInstance()
      .post(url, payload, params)
      .then((resp) => {
        this.fetchAssignedItems();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: msg,
        });
      });
  };
  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;

    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  handleRemove = (event) => {
    console.log("event", event);
  };
  render() {
    const {
      data,
      name,
      description,
      status,
      publicPackage,
      items,
      createdBy,
      creationDate,
      modifiedBy,
      modifiedDate,
      assignedItems,
    } = this.state.data;
    const {
      validationMsg,
      isSaving,
      isUploading,
      uploadProgress,
      isLoaded,
      showError,
      errorMsg,
      handleRemove,
    } = this.state;
    const metaInfo = {
      createdBy,
      creationDate,
      modifiedBy,
      modifiedDate,
    };
    const packageId = get(this.props, ["match", "params", "packageId"]);
    if (!isLoaded) return <></>;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
            <Form>
              <PackageDetails
                onChange={this.onChange}
                name={name}
                description={description}
                status={status}
                publicPackage={publicPackage}
                metaInfo={metaInfo}
                editMode={true}
                validationMsg={validationMsg}
              />

              <Items packageId={packageId} />

              <div className="empty-height"></div>
              {showError && <Message error content={errorMsg} />}
              <div className="row">
                <Button
                  type="submit"
                  disabled={isSaving}
                  onClick={() =>
                    this.showpopupWarning()
                  }
                  className="button-primary m--no-margin-left-im"
                >
                  {" "}
                  Save
                </Button>
                <Button
                  type="reset"
                  className="button-basic"
                  disabled={isSaving}
                  onClick={(e) => {
                    e.preventDefault();
                    this.redirectToParent();
                  }}
                >
                  {" "}
                  Cancel
                </Button>
              </div>
            </Form>
            <Confirm
              open={this.state.deleteWarning}
              onCancel={this.hideDeleteWarning}
              onConfirm={() => this.hideDeleteWarning(this.updatePackages)}
              content={`Are you sure you want to delete this Item?`}
            />
            <Confirm
                    open={this.state.popupWarning}
                    onCancel={this.hidepopupWarning}
                    onConfirm={() => this.hidepopupWarning(this.saveDetails)}
                    content="Are you sure you want to update this package?"
            />
            {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default PackageInfo;
