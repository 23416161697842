import Api from '@/api';
import { InputField } from '@/common/Form';
import { removeEmptyKeys } from '@/common/utils/utils';
import Constants from '@/config/constants';
import React from 'react';
import { Form, Grid, Segment, Checkbox, Divider, Confirm } from 'semantic-ui-react';
import { validate } from 'validate.js';
import { normalValidation } from './../components/validations/pdf';
import { connect } from 'react-redux';
import LocalStore from "@/store/persistence/local-storage";

class PDFSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyValuePairs: [],
      pdf_allow_assembly: false,
      pdf_allow_copy: false,
      pdf_allow_degraded_printing: false,
      pdf_allow_fill_in: false,
      pdf_allow_modify_annotations: false,
      pdf_allow_modify_content: false,
      pdf_allow_printing: false,
      pdf_allow_screen_readers: false,
      pdf_password_protect: false,
      pdf_watermark_all: false,
      pdf_watermark_line1: false,
      pdf_watermark_opacity: 1,
      pdf_watermark_color_rgb: '#000',
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: '',
      msgSpanText: '',
      msgSpanColor: 'black',
      itemLoadXHR: false,
      watermark: 1,
      popupWarning: false,
    };
  }

  componentDidMount() {
    Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ADMIN_SETTINGS + '?type=PDF')
      .then((resp) => {
        let data = resp.data;
        let content = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].value.length > 0) content[data[i].name] = data[i].value;
        }
        this.setState({
          keyValuePairs: content,
          pdf_allow_assembly: content['pdf_allow_assembly'],
          pdf_allow_copy: content['pdf_allow_copy'],
          pdf_allow_degraded_printing: content['pdf_allow_degraded_printing'],
          pdf_allow_fill_in: content['pdf_allow_fill_in'],
          pdf_allow_modify_annotations: content['pdf_allow_modify_annotations'],
          pdf_allow_modify_content: content['pdf_allow_modify_content'],
          pdf_allow_printing: content['pdf_allow_printing'],
          pdf_allow_screen_readers: content['pdf_allow_screen_readers'],
          pdf_watermark_opacity: content['pdf_watermark_opacity'],
          pdf_watermark_color_rgb: content['pdf_watermark_color_rgb'],
        });
      });
  }

  save = () => {
    let options = {};
    let kpKeys = Object.keys(this.state.keyValuePairs);
    let kpVals = Object.values(this.state.keyValuePairs);
    for (var i = 0; i < kpKeys.length; i++) {
      options[kpKeys[i]] = kpVals[i];
    }

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_SETTINGS_SAVE, options)
      .then((resp) => {
        let msg = '';
        let color = 'black';
        if (resp.data && resp.data === 'OK') {
          msg = 'Settings updated';
          color = 'green';
        } else {
          msg = 'Request failed, Please try later.';
          color = 'red';
        }
        const token = LocalStore.getValue("token");
        this.setState(
          {
            msgSpanText: msg,
            msgSpanColor: color,
            loading: false,
          },
          this.props.loadApplicationConfig(token)
        );
      });
  };

  showpopupWarning = () => {
    let result;
    result = validate(
      removeEmptyKeys(this.state.keyValuePairs),
      normalValidation
    );
    if (result) {
      this.setState({
        validationMsg: result,
        msgSpanText:
          'There are some errors with the submission. Please fix and retry.',
        msgSpanColor: 'red',
      });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      });
  }

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  handleChange = (e, name) => {
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
  };
  onByteChange = (event, eventData) => {
    let value = eventData.value ? 1 : 0;

    let kp = this.state.keyValuePairs;
    kp[eventData.name] = value;
    if (value.length < 1) delete kp[eventData.name];

    this.setState({
      keyValuePairs: kp,
    });
    this.setState({
      [eventData.name]: value,
    });
  };

  handleSelectChange = (e) => {
    let value = e.target.value;
    let kp = this.state.keyValuePairs;
    kp[e.target.name] = value;
    this.setState({
      keyValuePairs: kp,
    });
  };

  handleTextChange = (e) => {
    let value = e.target.value;
    let kp = this.state.keyValuePairs;
    kp[e.target.name] = value;
    //  if (value.length < 1) delete kp[e.target.name];

    this.setState({
      keyValuePairs: kp,
    });
  };

  validateForm = (event) => {
    let result;
    result = validate(
      removeEmptyKeys(this.state.keyValuePairs),
      normalValidation
    );
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: result,
        msgSpanText:
          'There are some errors with the submission. Please fix and retry.',
        msgSpanColor: 'red',
      });
    }
  };

  getFormTextField = (
    label,
    name,
    errorMsg,
    defaultVal,
    mandatory,
    maxlength
  ) => {
    if (maxlength == 32) {
      return (
        <InputField
          label={label}
          errorMsg={errorMsg}
          isError={errorMsg}
          name={name}
          id={name}
          value={defaultVal}
          onChange={this.handleTextChange}
          maxlength="32"
          isRequired={mandatory}
        />
      );
    } else {
      return (
        <InputField
          label={label}
          errorMsg={errorMsg}
          isError={errorMsg}
          name={name}
          id={name}
          maxlength={maxlength}
          value={defaultVal}
          onChange={this.handleTextChange}
          isRequired={mandatory}
        />
      );
    }
  };

  getTrioRadioField = (
    label,
    name,
    errorMsg,
    defaultVal,
    mandatory,
    option1,
    option2,
    option3
  ) => {
    let rad1 = false;
    let rad2 = false;
    let rad3 = false;
    if (defaultVal == 'ALL') {
      rad1 = true;
    } else if (defaultVal == 'DISABLED') {
      rad2 = true;
    } else {
      rad3 = true;
    }
    return (
      <React.Fragment>
        <Grid.Column mobile={16} tablet={16} computer={3}>
          <label style={{ fontWeight: 'bold' }}>
            {label}{' '}
            {mandatory == true ? <span style={{ color: 'red' }}>*</span> : ''}
          </label>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={5}>
          <input
            id={name + option1}
            name={name}
            type="radio"
            value={option1.toUpperCase()}
            checked={rad1}
            onChange={(e) => this.handleChange(e, name)}
          />
          &nbsp; &nbsp;
          <span>{option1}</span>
          &nbsp; &nbsp;
          <input
            id={name + option2}
            name={name}
            type="radio"
            value={option2.toUpperCase()}
            checked={rad2}
            onChange={(e) => this.handleChange(e, name)}
          />
          &nbsp; &nbsp;
          <span>{option2}</span>
          &nbsp; &nbsp;
          <input
            id={name + option3}
            name={name}
            type="radio"
            value={option3.toUpperCase()}
            checked={rad3}
            onChange={(e) => this.handleChange(e, name)}
          />
          &nbsp; &nbsp;
          <span>{option3}</span>
        </Grid.Column>
      </React.Fragment>
    );
  };

  render() {
    const { validationMsg } = this.state;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">PDF Settings</div>
          <div className="empty-height-small" />
          <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label style={{ fontWeight: 'bold' }}>
                    PDF Watermark <span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <select
                    id="pdf_watermark_all"
                    name="pdf_watermark_all"
                    value={this.state.keyValuePairs['pdf_watermark_all']}
                    onChange={this.handleSelectChange}
                  >
                    <option value="ALL" selected="">
                      Watermark all PDF product items
                    </option>
                    <option value="DISABLED">
                      Disable PDF product item watermark
                    </option>
                    {/* Commenting because not working */}
                    {/* <option value="USER-DEFINED">
                      User defined for each PDF product item
                    </option> */}
                  </select>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    'PDF Watermark Line 1',
                    'pdf_watermark_line1',
                    this.state.validationMsg.pdf_watermark_line1,
                    this.state.keyValuePairs['pdf_watermark_line1'],
                    true,
                    64
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label style={{ fontWeight: 'bold' }}>
                    Password Protect PDF <span style={{ color: 'red' }}>*</span>
                  </label>
                  <br />
                  <select
                    id="pdf_password_protect"
                    name="pdf_password_protect"
                    value={this.state.keyValuePairs['pdf_password_protect']}
                    onChange={this.handleSelectChange}
                  >
                    <option value="ALL">
                      Password protect all PDF product items
                    </option>
                    <option value="DISABLED">
                      Disable PDF product item password protection
                    </option>
                  </select>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <span style={{ color: 'orange' }}>Please Note:</span>
                  <br />
                  <span>
                    <strong>All:</strong>&nbsp;Apply this setting to all PDF
                    documents
                  </span>
                  <br />
                  <span>
                    <strong>Disabled:</strong>&nbsp;This setting is disabled for
                    all PDF documents
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {' '}
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <span className="text-primary ">
                    <b> PDF Watermark for Non Customer Users</b>
                  </span>
                  <Divider />
                  <div className="label-signup-policy pb-2">
                    <Checkbox
                      name="pdf_admin_watermark"
                      label="Customize watermark for Non customer users"
                      checked={
                        this.state.keyValuePairs['pdf_admin_watermark'] == '1'
                          ? '1'
                          : null
                      }
                      // isRequired
                      onChange={(event, data) =>
                        this.onByteChange(event, {
                          name: data.name,
                          value: data.checked,
                        })
                      }
                    />
                  </div>
                  {this.state.keyValuePairs['pdf_admin_watermark'] == '1' ? (
                    <React.Fragment>
                      <InputField
                        // errorMsg={validationMsg.line1}
                        //isError={validationMsg.line1}
                        label="Watermark Line 1"
                        maxlength={'64'}
                        onChange={this.handleTextChange}
                        popup={true}
                        id="pdf_admin_line1"
                        name="pdf_admin_line1"
                        value={this.state.keyValuePairs['pdf_admin_line1']}
                        content={`Default value configured by System Admin`}
                      // isRequired
                      />
                      <InputField
                        label="Watermark Line 2"
                        id="pdf_admin_line2"
                        name="pdf_admin_line2"
                        value={this.state.keyValuePairs['pdf_admin_line2']}
                        maxlength={64}
                        onChange={this.handleTextChange}
                        popup={true}
                        content={'Default : {Firstname} {Lastname}'}
                      />
                      <InputField
                        label="Watermark Line 3"
                        id="pdf_admin_line3"
                        name="pdf_admin_line3"
                        value={this.state.keyValuePairs['pdf_admin_line3']}
                        onChange={this.handleTextChange}
                        maxlength={64}
                        popup={true}
                        content={'Default : {Company Name}'}
                      />
                      <Form.Field>
                        <Checkbox
                          name="pdf_admin_date_stamp"
                          label="Date Stamp"
                          checked={
                            this.state.keyValuePairs['pdf_admin_date_stamp'] ==
                              '1'
                              ? 1
                              : 0
                          }
                          onChange={(event, data) =>
                            this.onByteChange(event, {
                              name: data.name,
                              value: data.checked,
                            })
                          }
                        />
                      </Form.Field>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <span className="text-primary">
                    <b> PDF Watermark Settings</b>
                  </span>
                  <Divider />
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={16} computer={8}>
                        <InputField
                          label="Watermark Opacity (Between 0 - 1)"
                          id="pdf_watermark_opacity"
                          name="pdf_watermark_opacity"
                          value={this.state.keyValuePairs['pdf_watermark_opacity']}
                          onChange={this.handleTextChange}
                          maxlength={4}
                          errorMsg={validationMsg.pdf_watermark_opacity}
                          isError={validationMsg.pdf_watermark_opacity}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={16} computer={8}>
                        <InputField
                          label="Watermark Color (#RGB)"
                          id="pdf_watermark_color_rgb"
                          name="pdf_watermark_color_rgb"
                          value={this.state.keyValuePairs['pdf_watermark_color_rgb']}
                          onChange={this.handleTextChange}
                          maxlength={7}
                          errorMsg={validationMsg.pdf_watermark_color_rgb}
                          isError={validationMsg.pdf_watermark_color_rgb}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='m--no-pad-top-im'>
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <label className='form-label'> PDF Watermark Preview </label>
                        <div className='pdf-preview-setting' >

                          <span style={{
                            color: `${this.state.keyValuePairs['pdf_watermark_color_rgb']}`,
                            opacity: `${this.state.keyValuePairs['pdf_watermark_opacity']}`,
                          }}>WATERMARK</span>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>


                </Grid.Column>
              </Grid.Row>


              <Grid.Row >
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="button"
                    value="Save"
                    className="button-primary"
                    onClick={this.showpopupWarning}
                  />
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content="Are you sure you want to update pdf settings?"
        />
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadApplicationConfig: (token) => {
      dispatch(Api.Config.loadApplicationConfig(token));
    },
  };
};
const mapStateToProps = (state) => ({
  config: state.common.config,
});
export default connect(mapStateToProps, mapDispatchToProps)(PDFSettingsForm);
