import React from "react";
import PageTemplate from "../../common/page";
import AccessReport from "./pages/access-report";
import BriefcaseLog from "./pages/briefcase-log";
import DownloadLog from "./pages/download-log";
import PackageList from "./pages/package-list";

const MENU_ITEMS = {
  Reports: [
    {
      name: "Access Report",
      relativePath: "/",
      exact: true,
      subPageComponent: AccessReport
    },
    /** Temporary fix, for relativePath: same name highlighted the briefcase component as well..*/
    {
      name: "Briefcase Log",
      relativePath: "/brief-case",
      subPageComponent: BriefcaseLog
    },
    {
      name: "Download Log",
      relativePath: "/download",
      subPageComponent: DownloadLog,
      hide: false
    },
    {
      name: "Package Report",
      relativePath: "/packages",
      subPageComponent: PackageList,
      hide: false
    }
  ]
};

const Reports = ({ baseUrl }) => {
  return <PageTemplate baseUrl={baseUrl} menuItems={MENU_ITEMS} />;
};

export default Reports;
