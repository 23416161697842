import Layout from "./layout";
import { connect } from "react-redux";
import Api from "../api";
import Actions from "../store/actions";
import {authoritySelector, configSelector, loggedInUserSelector} from "../common/selectors";
import {checkIfSupportEnabledForTheUser} from "../api/support";

const mapDispatchToProps = dispatch => {
  return {
    logout: (payload) => {
      dispatch(Api.User.logout(payload));
    },
    loadApplicationConfig: (token) => {
      dispatch(Api.Config.loadApplicationConfig(token));
    },
    saveUser: user =>
      dispatch({
        type: Actions.USER.USER_LOGIN,
        payload: user
      }),
    checkIfSupportEnabledForTheUser: () => {
      dispatch(checkIfSupportEnabledForTheUser())
    },
    showHideSupportErrorDialog: (state, errMsg) => {
      dispatch({
        type: Actions.Config.SHOW_HIDE_ERROR_DIALOG,
        payload: { state, errMsg }
      });
    },
    hideSidebarNotifications: () => {
      dispatch({
        type: Actions.USER.NOTIFICATIONS_SIDEBAR_HIDE
      });
    },
    hideMainOverlay: () => {
      dispatch({
        type: Actions.Config.SHOW_HIDE_OVERLAY,
        payload: false
      });
    },
  };
};

const mapStateToProps = (state, props)  => ({
  user: loggedInUserSelector(state),
  config: configSelector(state, props),
  accessRole: authoritySelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
