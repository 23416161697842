import Unsubscribe from "./unsubscribe";

export default {
  name: "Unsubscribe",
  configKey: "unsubscribe",
  path: "/unsubscribe/:id",
  component: Unsubscribe,
  navbarVisibility: false,
  isPrivateRoute: false,
};
