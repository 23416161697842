import Reports from "./reports";
import { connect } from "react-redux";
import { configSelector } from "@/common/selectors";
import Config from "@/config/constants";

const baseUrl = "/reports";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  // incase i'll need config values
  config: configSelector(state),
  baseUrl: baseUrl,
});

const mappedReports = connect(mapStateToProps, mapDispatchToProps)(Reports);

export default {
  name: "Reports",
  configKey: "reports_menu_item_title",
  path: baseUrl,
  component: mappedReports,
  isPrivateRoute: true,
  navbarVisibility: false,
  accessRoles: Config.AccessRole.ADMINISTRATORS,
};
