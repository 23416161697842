import React from "react";
import { Route, Redirect } from "react-router-dom";

function AccessRoute({ component, ...rest }) {
  const { isLoggedIn, fallback } = rest;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoggedIn ? (
          React.createElement(component)
        ) : (
          <Redirect
            to={{
              pathname: fallback,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default AccessRoute;
