import Briefcase from "./briefcase";
import { connect } from "react-redux";
import { userIdSelector, authoritySelector } from "../../common/selectors";
import Config from "../../config/constants";

const mapDispatchToProps = dispatch => {
  return {};
};

const baseUrl = "/briefcase";

const mapStateToProps = state => ({
  baseUrl: baseUrl,
  userId: userIdSelector(state),
  authority: authoritySelector(state)
});

const mappedBriefcase = connect(mapStateToProps, mapDispatchToProps)(Briefcase);

export default {
  name: "Briefcase",
  configKey: "briefcase_menu_item_title",
  path: baseUrl,
  component: mappedBriefcase,
  isPrivateRoute: true,
  accessRoles: Config.AccessRole.PRIVILEGED
};
