const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN";
const RECEIVE_ID_TOKEN = "RECEIVE_ID_TOKEN";
const GENERATE_STATE_AND_NONCE = "GENERATE_STATE_AND_NONCE";
const CLEAR_ALL = "CLEAR_ALL";
const GETTING_OKTA_TOKEN = "GETTING_OKTA_TOKEN";
const RECIEVED_OKTA_TOKEN = "RECIEVED_OKTA_TOKEN";
const OKTA_USER_DISABLED = "OKTA_USER_DISABLED";
const USER_UNSUBSCRIBE_INITIATE = "USER_UNSUBSCRIBE_INITIATE";
const USER_UNSUBSCRIBE_SUCCESS = "USER_UNSUBSCRIBE_SUCCESS";
const USER_UNSUBSCRIBE_FAILURE = "USER_UNSUBSCRIBE_FAILURE";

export default {
  RECEIVE_ACCESS_TOKEN,
  RECEIVE_ID_TOKEN,
  GENERATE_STATE_AND_NONCE,
  CLEAR_ALL,
  GETTING_OKTA_TOKEN,
  RECIEVED_OKTA_TOKEN,
  OKTA_USER_DISABLED,
  USER_UNSUBSCRIBE_INITIATE,
  USER_UNSUBSCRIBE_SUCCESS,
  USER_UNSUBSCRIBE_FAILURE
};
