import USER from "./user-actions";
import Config from "./config";
import Token from "./token";
import Admin from "./admin";
import Zendesk from "./zendesk";
import searchIndices from "./searchIndices";
export default {
  USER,
  Config,
  Token,
  Zendesk,
  Admin,
  searchIndices,
};
