import React from "react";
import Api from "@api";
import Constants from "@constants";
import "react-datepicker/dist/react-datepicker.css";
import update from "immutability-helper";
import { validate } from "validate.js";
import {
  InputField,
  FileField,
  UserTypeField,
  DropdownField
} from "@/common/Form";
import Uploader from "@/common/uploader";
import {
  Form,
  Modal,
  Progress,
  Segment,
  Grid,
  Tab,
  Confirm
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";
import LicensesPage from "./licenses-page.js";
import { editValidation } from "./validation";

class Licenses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: null,
      version: null,
      filename: null,
      fileSize: null,
      fileuuid: null,
      publishStartDate: "",
      status: null,
      type: null,
      createdBy: null,
      creationDate: null,
      itemType: null,
      modificationDate: null,
      modifiedBy: null,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: "",
      msgSpanText: "",
      msgSpanColor: "black",
      newFileAdded: 0,
      popupWarning: false,
      userTypes: [],
      statusOptions: [
        { text: 'Active', value: 'ACTIVE', key: 'ACTIVE'},
        { text: 'Disabled', value: 'DISABLED', key: 'DISABLED'}
      ]
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  transformUserTypeIdToUserObj(userTypeIds) {
    return userTypeIds && userTypeIds.length ? userTypeIds.map((id) => ({ id })) : [];
  }

  fetchData = () => {
    const { id } = this.props.match.params;
    let params = {
      params: {
        id,
      },
    };
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_LICENSE_SINGLE, params)
      .then((resp) => {
        let data = resp.data;
        this.setState({
          id: data.id,
          name: data.name,
          version: data.version,
          filename: data.filename,
          fileSize: data.fileSize,
          fileuuid: data.fileuuid,
          fileType: data.fileType,
          publishStartDate: data.publishStartDate,
          status: data.status,
          type: data.type,
          createdBy: data.createdBy,
          creationDate: data.creationDate,
          itemType: data.itemType,
          modificationDate: data.modificationDate,
          modifiedBy: data.modifiedBy,
          userTypes: data.userTypes ? data.userTypes.filter((type) => type.accessRole === 'websilo-customer' || type.accessRole === 'websilo-non-nda-customer').map((type) => type.id) : [],
        });
      });
  };
  save = () => {
    const {
      id,
      name,
      version,
      file,
      addver,
      status,
      type,
      createdBy,
      creationDate,
      itemType,
      modificationDate,
      modifiedBy,
      publishStartDate,
      userTypes
    } = this.state;
    let data = {
      id,
      name,
      version,
      file: file,
      newFileAdded: this.state.newFileAdded,
      fileuuid: this.state.fileuuid,
      filename: this.state.filename ? this.state.filename : 0,
      fileSize: this.state.fileSize ? this.state.fileSize : 0,
      fileType: this.state.fileType ? this.state.fileType : "",
      addver,
      publishStartDate,
      status,
      type,
      createdBy,
      creationDate,
      itemType,
      modificationDate,
      modifiedBy,
      userTypes: this.transformUserTypeIdToUserObj(userTypes),
    };
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_LICENSE_SAVE, data)
      .then((resp) => {
        this.setSuccessMsg(resp);
      });
  };

  setSuccessMsg = (resp) => {
    let msg = "";
    let color = "black";
    if (resp.data.id > 0 || resp.data.uniqueId !== undefined) {
      msg = "Updated Successfully!";
      color = "green";
    } else {
      msg = "Request failed, Please try later.";
      color = "red";
    }

    this.setState({
      msgSpanText: msg,
      msgSpanColor: color,
      loading: false,
    });
    // document.getElementById("cancel_button").textContent = "Back to Listing";
    // document.getElementById("cancel_button").classList.add("positive");
  };

  showpopupWarning = (showWarning = false) => {
    let result = true;
    result = validate(this.state, editValidation);
    if (result) {
      const updatedState = {
        validationMsg: result
      };

      if (showWarning) {
        updatedState.msgSpanText = "There are some errors with the submission. Please fix and retry.";
        updatedState.msgSpanColor = "red";
      }

      this.setState({ ...updatedState });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      });
  }

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const { validationMsg } = this.state;
    this.setState({
      [name]: value ? value : null,
      validationMsg: update(validationMsg, { [name]: {
        $set: null
      } }),
    });
  }

  handleUserTypeChange = (event, data) => {
    const { name, value } = data;
    const { validationMsg } = this.state;
    if (name === 'userTypes') {
      this.setState({
        userTypes: value,
        validationMsg: update(validationMsg, { userTypes: {
          $set: null
        } }),
      });
    }
  }

  handleStatusChange = (event, { name, value }) => {
    this.setState({
      status: value,
    });
  };

  onFileChange = (eventData) => {
    if (!eventData) return;

    if (eventData.isUploading) {
      const { file, isUploading } = eventData;
      if (file.name && file.type && file.size) {
        console.log("onFileChange", file);
        this.setState({
          isUploading,
          file: file,
          filename: file.name,
          fileSize: file.size,
          fileType: file.type,
        });
      }
    } else if (typeof eventData.uploadProgress !== "undefined") {
      const { uploadProgress } = eventData;
      const isUploading = uploadProgress !== 100;
      this.setState({ isUploading, uploadProgress });
    } else if (typeof eventData.fileuuid !== "undefined") {
      const { fileuuid } = eventData;
      this.setState({ fileuuid });
    } else if (typeof eventData.errorMsg !== "undefined") {
      const { errorMsg } = eventData;
      this.setState({
        apiError: errorMsg,
        showError: true,
        isUploading: false,
      });
    }
  };

  download = (e) => {
    e.preventDefault();
    const { id } = this.props.match.params;
    let params = {
      params: {
        id,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_LICENSE_DOWNLOAD, params)
      .then((resp) => {
        let win = window.open(resp.data, "_blank");
        win.focus();
      })
      .catch((err) =>
        this.setState({
          showError: true,
          errorMsg: "An error occured while downloading the license.",
        })
      );
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  validateForm = (event) => {
    let result = true;
    result = validate(this.state, editValidation);
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: result,
        msgSpanText:
          " There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red",
      });
    }
  };
  getForm() {
    const { validationMsg, isUploading, uploadProgress, version, name } =
      this.state;
    return (
      <Segment className="base-segment-wrapper">
        <div className="header">
          <div className="side-marker" />
          Edit License
          {/*<a
              href="#"
              className="btn-back-main"
              onClick={this.props.editCancellationHandler}
            >
              <FontAwesomeIcon icon={faArrowLeft} title="Back" name="back" />
              &nbsp;Back to listing
            </a>*/}
        </div>
        <div className="empty-height-small"></div>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} table={16} computer={16}>
              <Form loading={this.state.loading}>
                <InputField
                  label="Name"
                  errorMsg={validationMsg.name}
                  isError={validationMsg.name}
                  name="name"
                  id="name"
                  value={name}
                  onChange={this.handleChange}
                  isRequired={true}
                />
                <InputField
                  label="Version"
                  errorMsg={validationMsg.version}
                  isError={validationMsg.version}
                  name="version"
                  id="version"
                  value={version}
                  onChange={this.handleChange}
                  isRequired={true}
                />
                <br />
                <div className="row">
                  <div className="col-md-2">
                    <label style={{ fontWeight: "bold" }}>License File</label>
                  </div>
                  <div className="col-md-10">
                    <a href="#" onClick={(e) => this.download(e)}>
                      {this.state.filename}
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <label style={{ fontWeight: "bold" }}>File Size</label>
                  </div>
                  <div className="col-md-10">
                    <span type="text">{this.state.fileSize} &nbsp; bytes</span>
                  </div>
                </div>
                <br />
                <FileField
                  errorMsg="Please choose a valid file (Plain Text or PDF only)"
                  isError={validationMsg.filename}
                  label={
                    <span>
                      <span>Replace License File</span>&nbsp;
                      <i>(Plain Text or PDF only)</i>
                    </span>
                  }
                  name="filename"
                  id="filename"
                  onChange={Uploader(this.onFileChange)}
                  width="7"
                  isRequired
                  multipleSelection={false}
                />
                {isUploading && (
                  <Progress percent={uploadProgress} indicating />
                )}

                <br />

                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={4} computer={5}>
                      <label className="form-label">Publish Date</label>
                      <span id="publishStartDate" name="publishStartDate">
                        {moment(this.state.publishStartDate).format("MM/DD/YYYY")}
                      </span>
                      <br />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={4} computer={5}>
                      <DropdownField
                        options={this.state.statusOptions}
                        value={this.state.status}
                        label="Status"
                        name="status"
                        onChange={this.handleStatusChange}
                        isRequired={true}
                      />
                      <br />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={4} computer={5}>
                      <UserTypeField
                        value={this.state.userTypes}
                        label="User Type"
                        name="userTypes"
                        errorMsg={validationMsg.userTypes}
                        isError={validationMsg.userTypes}
                        onChange={this.handleUserTypeChange}
                        multiple
                        licenseUsers={true}
                        isRequired={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <button className="button-primary" onClick={() => this.showpopupWarning(true)}>
                {" "}
                Save{" "}
              </button>
              <Link to="/admin/licenses">
                <button
                  className="button-basic"
                // onClick={this.props.createCancellationHandler}
                >
                  {" "}
                  Cancel{" "}
                </button>
              </Link>
              &nbsp; &nbsp;
              <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                {this.state.msgSpanText}
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content="Are you sure you want to update license?"
        />
      </Segment>
    );
  }
  render() {
    const {
      errorMsg,
      showError,
      isUploading,
      uploadProgress,
      validationMsg,
      name,
      version,
    } = this.state;

    let tab1 = {
      menuItem: "Edit",
      render: () => <Tab.Pane>{this.getForm()}</Tab.Pane>,
    };

    let tab2 = {
      menuItem: "Licenses",
      render: () => (
        <Tab.Pane>
          <LicensesPage {...this.props} />
        </Tab.Pane>
      ),
    };
    let panes = [tab1];
    //can show if needed
    // panes = [tab1, tab2];

    return (
      <React.Fragment>
        <Tab panes={panes} />

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default Licenses;
