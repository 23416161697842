import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import urls from "../../../../config/constants";
import DocumentListing from "../../components/documents-listing";

const ProjectDocuments = (props) => (
  <DocumentListing
    projectId={props.match.params.id}
    fetchUrl={urls.App.URL_PROJECT_DOCUMENT}
    defaultParams={{ id: props.match.params.id }}
    label="Documents"
    createButton={
      <Link to="documents/new">
        <button className="button-basic">Create</button>
      </Link>
    }
  />
);

export default ProjectDocuments;
