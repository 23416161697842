import constants from "../constants";


/**
 *  This module has multiple reducers for data encapsulation. Only the count is part of the main redux state. 
 * Whereas everything else is stored locally in the react reducer.
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const Reducer = (state, action) => {
  switch (action.type) {
    case constants.ACTIONS.NOTIFICATIONS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constants.ACTIONS.NOTIFICATIONS_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notifications: action.payload,
        isSuccess: true,
      };
    case constants.ACTIONS.NOTIFICATIONS_LOAD_FAILED:
      return {
        ...state,
        isLoading: false,
        notifications: {},
        isSuccess: false,
        errorMessage: action.errorMessage
      };
    case constants.ACTIONS.NOTIFICATIONS_COUNT_UPDATE:
      return {
        ...state,
        count: action.payload
      };
    default:
      throw new Error("Unexpected action");
  }
};

export default Reducer;
