import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sortBy, reverse } from "lodash";
import DropdownField from "@/common/Form/subcomponents/dropdown-field";
import { countriesSelector } from "@/common/selectors";

const getCountriesList = countries => {
  if( countries) {
  const countryList = Object.keys(countries).reduce((acc, el) => {
    acc.push({ key: el, value: el, text: countries[el] });
    return acc;
  }, []);

  const sortedCountries = sortBy(countryList, [
    function(o) {
      return o.text;
    }
  ]);
  sortedCountries.push({
    key: "Outside",
    value: "outsideUSA",
    text: "Outside USA"
  });
  sortedCountries.push({ key: "ANY", value: "ANY", text: "ANY" });
  // To display last added items first
  return reverse(sortedCountries);
  }
};

const CountryField = ({
  countries,
  errorMsg,
  isError,
  isRequired,
  label,
  name,
  onChange,
  value,
  width
}) => {
  return (
    <DropdownField
      errorMsg={errorMsg}
      isError={isError}
      isRequired={isRequired}
      label={label}
      name={name}
      onChange={onChange}
      options={getCountriesList(countries)}
      placeholder="Select your country..."
      search
      selection
      value={value}
      width={width}
    />
  );
};

CountryField.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string
};

const mapStateToProps = (state, props) => {
  return {
    countries: countriesSelector(state, props)
  };
};

const mappedCountryField = connect(mapStateToProps)(CountryField);

export default mappedCountryField;
