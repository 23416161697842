import React from 'react';
import { Link } from 'react-router-dom';

const OktaSignInWidget = () => {
  const { myOkta } = window;

  const login = () => {
    myOkta.signInWithRedirect();
  };

  return (
    <>
      <Link to="#"
        onClick={(e) => {
          e.preventDefault();
          login();
        }}
      >
        {' '}
        I work for Groq{' '}
      </Link>
    </>
  );
};
export default OktaSignInWidget;
