import React from "react";
import Api from "../../../api";
import { dateToPacific } from "@/utils/dateTime";
import { Link, Redirect } from "react-router-dom";
import {
  Grid,
  Label,
  Segment,
  Confirm,
  Item,
  Modal,
  Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import RecentPosts from "../components/recentposts";
import Panel from "../components/panel";
import UserListing from "../../../common/user-selection/user-listing";
import GroupListing from "../../../common/group-selection/group-listing";
import Tableview from "../../../common/tableview";
import Notifications from "../components/notifications";
import projectIdDispatcher from "../dispatch";
import FileIcon from "../../../common/file-icon.js";
import { authoritySelector, userIdSelector } from "@/common/selectors";
import { default as Config, default as Constants } from "@/config/constants";
import MultiDownloadOpts from "../../../common/multiple-download-opts";
import ChangeOwner from "../components/change-owner";
import { isVideo } from "../../../common/utils/utils";
import VideoPlayer from "react-video-js-player";
import urls from "../../../config/constants";
import { MembersListing } from "../../../common/user-selection";
const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;

class ProjectView extends React.Component {
  constructor(props) {
    super(props);
    let id = props.match.params.id;
    this.state = {
      projectId: id,
      project: {},
      recentDocuments: [],
      activeMembers: [],
      notifications: {},
      memberCount: 0,
      loading: false,
      isModalOpen: false,
      selectedFile: {},
      showUsersListingForAdmin: false,

      selectedVideoLink: "",
      selectedVideoName: "",
      isLoaded: false,
      playVideo: false,
      isModalOpen: false,
      selectedFile: {},
      showUsersListingForAdmin: false,
    };
  }

  downloadDocument = (el) => {
    Api.AxiosInstance.getInstance()
      .get(`/projects/document/download?documentId=${el.docId}`)
      .then((resp) => {
        let basePath = `${Constants.App.API_URL}projects/`;

        const mock = {
          wgetCommand: `wget ${basePath}${resp.data.command} -O ${el.filename}`,
          curlCommand: `curl ${basePath}${resp.data.command} -o ${el.filename} -D-`,
          downloadLink: resp.data.downloadLink,
          directLink: resp.data.directLink,

          fileName: el.filename,
          fileDesc: el.description,
        };

        this.setState({ selectedFile: mock });
        this.setState({ isModalOpen: true });
      })
      .catch((err) =>
        this.setState({
          showError: true,
          errorMsg: "An error occured while downloading the document.",
        })
      );
  };

  loadProject = () => {
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_PROJECT_DETAIL + "?id=" + this.state.projectId)
      .then((resp) => {
        let data = resp.data;
        let recentDocuments = data.documents.map((el) =>
          Object.assign(
            {},
            el,
            {
              actions: (
                <>
                  {isVideo(el.filename ? el.filename : el.name) && (
                    <a
                      href="#"
                      onClick={(e) =>
                        this.getVideoStreamLink(e, el.docId, el.name)
                      }
                    >
                      {<span className="icon-play" title="Play" />}
                    </a>
                  )}
                  {el.directLink && (
                    <a
                      href={
                        el.directLink.includes("https", "http")
                          ? el.directLink
                          : `https://${el.directLink}`
                      }
                      target="_blank"
                    >
                      {<span className="icon-link-4"></span>}
                    </a>
                  )}
                  {!isVideo(el.filename ? el.filename : el.name) &&
                    !el.directLink && (
                      <span
                        className="icon-download"
                        title="Download"
                        onClick={() => this.downloadDocument(el)}
                      />
                    )}
                </>
              ),
              filename: el.filename ? el.filename : "-",
            },
            {
              creationDate: (
                <>{dateToPacific(el.creationDate, "MM/DD/YYYY hh:mm a")}</>
              ),
            },
            {
              type: (
                <FileIcon
                  filename={el.filename ? el.filename : "other"}
                  mimetype={el.type ? el.type : el.directLink ? "link" : ""}
                ></FileIcon>
              ),
            },
            {
              name: (
                <span
                  title="Download"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.downloadDocument(el)}
                >
                  {el.name}
                </span>
              ),
            }
          )
        );

        this.setState({
          project: {
            id: data.id,
            name: data.name,
            description: data.description,
            creationDate: (
              <>{dateToPacific(data.creationDate, "MM/DD/YYYY hh:mm a")}</>
            ),
          },
          recentDocuments: recentDocuments,
          activeMembers: data.users,
          memberCount: data.memberCount,
          notifications: {
            members: data.recentMembers,
            documents: data.recentDocuments,
            posts: data.recentPosts,
          },
          open: false,
          loading: false,
          redirectToProjects: false,
          adminId: data.adminId,
          adminName: data.adminName,
        });
      });
  };

  componentDidMount() {
    const { projectId } = this.state;
    this.setState({ loading: true });
    this.loadProject();
    projectIdDispatcher.dispatch({
      type: "PROJECT_ID_CHANGE",
      value: projectId,
    });
  }

  closeModal = () => this.setState({ isModalOpen: false });
  show = () => this.setState({ open: true });

  inviteUsers = () => {
    this.setState({ showUsersListing: true });
  };

  onDismissUserListing = () => {
    this.setState({ showUsersListing: false });
  };

  getVideoStreamLink = (e, id, name) => {
    // get link
    e.preventDefault();
    const { URL_PROJECT_DOCUMENT_DOWNLOAD } = urls.App;

    const instance = Api.AxiosInstance.getInstance();

    instance
      .get(`${URL_PROJECT_DOCUMENT_DOWNLOAD}?documentId=${id}`)
      .then((resp) => {
        this.setState({
          selectedVideoLink: resp.data.downloadLink,
          selectedVideoName: name,
          isLoaded: true,
          playVideo: true,
        });
      });
  };

  onSaveUserListing = (users) => {
    const userids = [];
    users.map((user) => userids.unshift({ id: user.id }));
    const options = { id: this.state.projectId, users: userids };
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_ADD_MEMBERS, options)
      .then((resp) => {
        this.setState({
          showUsersListing: false,
          redirectToViewMembers: true,
        });
      })
      .catch((err) => {
        this.setState({
          errorMsg: "Something went wrong while adding memeber",
          showError: true,
        });
      });
  };

  inviteGroups = () => {
    this.setState({ showGroupsPopup: true });
  };

  onDismissGroupPopup = () => {
    this.setState({ showGroupsPopup: false });
  };

  groupSelectionOnSave = (groups) => {
    const groupIds = [];
    groups.map((group) => groupIds.unshift({ id: group.id }));
    const options = { id: this.state.projectId, groups: groupIds };
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_ADD_MEMBERS, options)
      .then((resp) => {
        this.setState({
          showGroupsPopup: false,
        });
        this.loadProject();
      })
      .catch((err) => {
        this.setState({
          errorMsg: "Something went wrong while adding groups",
          showError: true,
        });
      });
  };

  changeOwnerModalOpen = () => {
    this.setState({ showUsersListingForAdmin: true });
  };

  onDismissUserAdminListing = () => {
    this.setState({ showUsersListingForAdmin: false });
  };

  onSaveUserAdminListing = (users) => {
    console.log("users", users);
    if (!users[0]["id"]) return;

    let id = this.state.projectId ? this.state.projectId : "new";
    let options = {
      adminId: users[0]["id"],
      name: this.state.project.name,
      description: this.state.project.description,
    };
    const params = { params: { id: id } };
    Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_DETAIL, options, params)
      .then((resp) => {
        this.onDismissUserAdminListing();
        this.loadProject();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: error.response.data.message,
        });
      });
  };

  Constants = {
    RECENT_MEMBER_BUTTONS_ADMINS: [
      { title: "Invite Users", callback: this.inviteUsers },
      { title: "Invite Groups", callback: this.inviteGroups },
    ],
    RECENT_MEMBER_BUTTONS_USER: [
      { title: "Invite Users", callback: this.inviteUsers },
    ],
    COLUMNS: [
      { name: "Name", key: "name", showTooltip: true },
      { name: "Description", key: "description", showTooltip: true },
      { name: "File Name", key: "filename", showTooltip: true },
      {
        name: "Creation Date",
        key: "creationDate",
        isDate: true,
        showTooltip: true,
      },
      { name: "Created By", key: "createdBy" },
      { name: "Type", key: "type" },
      { name: "Actions", key: "actions", class: "center" },
    ],
  };

  handleConfirm = (event) => {
    event.preventDefault();
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_DELETE + "?id=" + this.state.projectId)
      .then((resp) => {
        this.setState({
          result: "confirmed",
          open: false,
          redirectToProjects: true,
        });
        this.props.history.push("/projects");
      })
      .catch((err) => {
        this.setState({
          errorMsg: "Something went wrong while deleting the project",
          showError: true,
        });
      });
  };

  handleCancel = () => this.setState({ result: "cancelled", open: false });

  componentDidUpdate() {}

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  render() {
    const {
      RECENT_MEMBER_BUTTONS_ADMINS,
      RECENT_MEMBER_BUTTONS_USER,
      COLUMNS,
    } = this.Constants;
    const { errorMsg, showError, playVideo } = this.state;
    let isAdmin =
      ADMIN_ROLES.indexOf(this.props.authority) >= 0
        ? true
        : this.state.adminId == this.props.userId
        ? true
        : false;
    let RECENT_MEMBER_BUTTONS =
      this.props.authority === "websilo-customer"
        ? RECENT_MEMBER_BUTTONS_USER
        : RECENT_MEMBER_BUTTONS_ADMINS;

    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" />
                  {this.state.project.name}
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                {isAdmin && (
                  <div className="control-action-wrapper">
                    <Link to={"edit/" + this.state.project.id} className="pr-3">
                      <span className="icon-edit" title="Edit" />
                    </Link>
                    <Link to="#" onClick={this.show}>
                      <span className="icon-delete" title="Delete" />
                    </Link>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid stackable loading={this.state.loading}>
            <Grid.Column mobile={16} tablet={12} computer={12} largeScreen={12}>
              <Item.Group>
                <Item>
                  <Item.Content>
                    <Item.Description>
                      {this.state.project.description}
                    </Item.Description>
                    <Item.Extra>
                      <Label
                        content={
                          <React.Fragment>
                            <span>Started Since: </span>
                            <span className="black-font">
                              {this.state.project.creationDate}
                            </span>
                          </React.Fragment>
                        }
                      />
                      <Label
                        content={
                          <React.Fragment>
                            <span>Documents: </span>
                            <span className="black-font">
                              {" "}
                              {this.state.recentDocuments.length}
                            </span>
                          </React.Fragment>
                        }
                      />
                      <Label
                        content={
                          <React.Fragment>
                            <span>Active Members:</span>
                            <span className="black-font">
                              {" "}
                              {this.state.memberCount}{" "}
                            </span>
                          </React.Fragment>
                        }
                      />
                    </Item.Extra>
                  </Item.Content>
                </Item>
              </Item.Group>

              <Grid>
                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    largeScreen={8}
                  >
                    <div className="header">
                      <div className="side-marker" /> Recent Documents{" "}
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={8}
                    computer={8}
                    largeScreen={8}
                  >
                    <a
                      disabled
                      style={{ float: "right" }}
                      className="button-basic"
                      href={
                        "#/projects/" + this.state.project.id + "/documents/new"
                      }
                    >
                      {" "}
                      Add Documents{" "}
                    </a>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <div
                className="table-wrapper-with-pagination"
                style={{ margin: "24px 0px" }}
              >
                <Tableview
                  columns={COLUMNS}
                  data={{ content: this.state.recentDocuments }}
                />
              </div>

              <hr style={{ margin: "24px 0 16px 0" }} />

              <Grid>
                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={12}
                    computer={12}
                    largeScreen={12}
                  >
                    <div className="header mt-2">
                      <div className="side-marker" />
                      Recent Wall Posts
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={12}
                    largeScreen={12}
                  >
                    {this.state.projectId && (
                      <RecentPosts projectid={this.state.projectId} />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
              <Confirm
                open={this.state.open}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
                content="Are you sure you want to delete this project?"
              />
              <Notifications
                title="Notifications"
                data={this.state.notifications}
              />
              <Panel
                title="Recent Members"
                data={this.state.activeMembers}
                panelbuttons={RECENT_MEMBER_BUTTONS}
                projectId={this.state.projectId}
              />
              <ChangeOwner
                title="Owner"
                adminId={this.state.adminId}
                adminName={this.state.adminName}
                isAdmin={isAdmin}
                openModal={this.changeOwnerModalOpen}
              />
            </Grid.Column>
          </Grid>
          {this.state.redirectToViewMembers ? (
            <Redirect
              to={{
                pathname: "/projects/" + this.state.projectId + "/members",
              }}
            />
          ) : (
            ""
          )}
        </Segment>
        <UserListing
          show={this.state.showUsersListing}
          onDismiss={this.onDismissUserListing}
          onSave={this.onSaveUserListing}
          entity="ProjectMembers"
          entityId={this.state.project.id}
        />
        <GroupListing
          show={this.state.showGroupsPopup}
          onDismiss={this.onDismissGroupPopup}
          onSave={this.groupSelectionOnSave}
          entity="ProjectMembers"
          entityId={this.state.project.id}
        />
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>

        <Modal
          onClose={() => this.setState({ playVideo: false })}
          open={playVideo}
        >
          <Modal.Header>{this.state.selectedVideoName}</Modal.Header>
          <Modal.Content>
            {" "}
            <VideoPlayer
              controls={true}
              src={this.state.selectedVideoLink}
              width="860"
              height="420"
            />
            <Grid>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="m--align-right"
              >
                <Button
                  className="button-primary mr-0-im mt-2-im "
                  onClick={() => this.setState({ playVideo: false })}
                >
                  Close
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>

        {this.state.isModalOpen && (
          <MultiDownloadOpts
            isModalOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            selectedFile={this.state.selectedFile}
          ></MultiDownloadOpts>
        )}

        {this.state.showUsersListingForAdmin && (
          <MembersListing
            {...this.props}
            selectionType="single-select"
            show={this.state.showUsersListingForAdmin}
            onDismiss={this.onDismissUserAdminListing}
            onSave={this.onSaveUserAdminListing}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  authority: authoritySelector(state),
  userId: userIdSelector(state),

  // baseUrl: baseUrl,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mappedProjects = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectView);

export default mappedProjects;
