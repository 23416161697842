import { parsePhoneNumberFromString } from "libphonenumber-js";
import { isEmpty } from "lodash";

const normalValidationConfig = {
  username: {
    presence: true,
    length: {
      maximum: 50,
    },
  },
  firstName: {
    presence: true,
    format: /^[a-zA-Z ]*$/,
  },
  middleInitial: {
    length: { maximum: 1 },
    format: {
      pattern: /^[a-zA-Z.]{1}$/,
      message:
        " is invalid. It should only contain one letter alphabet or a period sign",
    },
  },
  middleName: {
    length: { maximum: 1 },
    format: {
      pattern: /^[a-zA-Z.]{1}$/,
      message:
        " is invalid. It should only contain one letter alphabet or a period sign",
    },
  },
  lastName: {
    presence: true,
    format: /^[a-zA-Z ]*$/,
  },
  title: {
    presence: false,
  },
  company: {
    presence: true,
  },
  address: {
    presence: true,
  },
  city: {
    presence: true,
  },
  phone: function (value, attributes, attributeName, options, constraints) {
    // if (value) {
    //   const phoneNum = parsePhoneNumberFromString(
    //     value,
    //     attributes.countryCode
    //   );
    //   if (!phoneNum) {
    //     return { format: { pattern: /\$/, message: "number is not valid" } };
    //   } else if (
    //     phoneNum.country === attributes.countryCode &&
    //     phoneNum.isValid()
    //   ) {
    //     return;
    //   } else {
    //     return {
    //       format: {
    //         pattern: /\$/,
    //         message: "number is not valid for the selected country",
    //       },
    //     };
    //   }
    // }
    return {
      presence: { allowEmpty: false },
      length: {
        minimum: 14,
        message: "number should not be less than 10 digits",
      },
    };
  },
  cellPhone: function (value, attributes, attributeName, options, constraints) {
    if (value) {
      const phoneNum = parsePhoneNumberFromString(
        value,
        attributes.countryCode
      );
      if (!phoneNum) {
        return { format: { pattern: /\$/, message: "number is not valid" } };
      } else if (
        phoneNum.country === attributes.countryCode &&
        phoneNum.isValid()
      ) {
        return;
      } else {
        return {
          format: {
            pattern: /\$/,
            message: "number is not valid for the selected country",
          },
        };
      }
    }
    // if its value is empty, let it go, its not a mandatory field.
    return;
  },
  postalCode: function (value, attributes){
    if(attributes.localresult==true){
      return ;}
  
    else{
    return {
      format: {
        pattern: "",
        message:
          " format is invalid",
      }
    };

  }
  },
  postCode: function (value, attributes){
    if(attributes.localresult==true){
      return ;}
  
    else{
    return {
      format: {
        pattern: "",
        message:
          " format is invalid",
      }
    };

  }
  },
  email: {
    presence: { allowEmpty: false },
    email: true,
    equality: {
      attribute: "restrictedDomains",
      message:
        "^Account requests using free email providers are rejected by the system. Please enter in your corporate email when registering.",
      comparator: function (emailString, comparisionString) {
        let splittedEmail = emailString && emailString.split("@");
        let restrictedDomainsArray =
          comparisionString && comparisionString.split(",");
        return restrictedDomainsArray &&
          splittedEmail &&
          restrictedDomainsArray.indexOf(splittedEmail[1]) !== -1
          ? false
          : true;
      },
    },
  },

  countryCode: {
    presence: { allowEmpty: false },
  },
  stateCode: {
    presence: { allowEmpty: false },
  },

  // remarks and details project
};
const extendedValidationConfig = Object.assign({}, normalValidationConfig, {
  password: {
    presence: { allowEmpty: false },
    length: { minimum: 5 },
  },
  confirmPassword: {
    presence: true,
    equality: "password",
  },
});
const adminExtendedValidationConfig = Object.assign(
  {},
  extendedValidationConfig,
  {
    // Only for admin
    userType: {
      presence: { allowEmpty: false },
    },
    // only for admin
    accountRenewalCycle: {
      numericality: {
        onlyInteger: true,
        greaterThan: 0,
        lessThanOrEqualTo: 9999,
      },
    },
  }
);
const adminNormalValidationConfig = Object.assign({}, normalValidationConfig, {
  // Only for admin
  userType: {
    presence: { allowEmpty: false },
  },
  // only for admin
  accountRenewalCycle: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 9999,
    },
  },
});
const normalValidationConfigForSelfRegister = Object.assign(
  {},
  extendedValidationConfig,
  {
    agreed: {
      // presence: true
      presence: {
        message: "^Kindly agree to privacy policy before proceeding",
      },
    },
  }
);

export {
  normalValidationConfig,
  normalValidationConfigForSelfRegister,
  extendedValidationConfig,
  adminNormalValidationConfig,
  adminExtendedValidationConfig,
};
