import Constants from "../../config/constants";
import actions from "../../store/actions";
import LocalStore from "@/store/persistence/local-storage";
import Axios from "axios";
import { redirectToHelpdeskURL } from '@/common/utils/utils';

export const checkIfSupportEnabledForTheUser = () =>  async (dispatch) => {
  const token = LocalStore.getValue("token");
  const status = await Axios.get(Constants.App.URL_SUPPORT_ENABLED, {
    headers: { Authorization: token },
  }).then((resp) =>{
    LocalStore.setValue('supportEnabled', resp.data.enabled)
    return resp.data && resp.data.enabled;
  })

  dispatch({
    type: actions.Config.CONFIG_SUPPORT_ENABLED,
    payload: status
  });
}

export const generateSupportURLAndRedirect = () => {
  return redirectToHelpdeskURL(Constants.App.URL_HELPCENTER)
}
