import LoginAssistance from "./login-assitance";
import ResetPassword from "./reset-password";
import Auth from "./auth";


export default {
  loginAssistance: {
    name: "Login Assistance",
    path: "/login-help",
    component: LoginAssistance,
    isAccessRoute: true,
    navbarVisibility: false,
  },
  resetPassword: {
    name: "Reset Password",
    path: "/reset-password/:token",
    component: ResetPassword,
    isAccessRoute: true,
    navbarVisibility: false,
  },
  authRoute: {
    name: "Auth",
    //configKey: "nda_menu_item_title",
    path: "/auth",
    component: Auth,
    isAccessRoute: true,
    navbarVisibility: false,
  },
};
