import React from "react";
import PropTypes from "prop-types";
import { Form, Icon, Label, Radio, Segment } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import ErrorMessage from "@/common/Form/subcomponents/error-message";
import { dateToPacific } from "../../../../../utils/dateTime";
import DatePickerWithTimezone from "../../../../../utils/DatePickerWithTimeZone";

class ExpirationPeriod extends React.Component {
  Constants = {
    EXPIRY_TYPE: Object.freeze({ NEVER: "NEVER", DAYS: "DAYS", DATE: "DATE" }),
  };

  constructor(props) {
    super(props);
    const {expireDate, expireDays, expireType, minDate} = this.props
    this.state = {
      expireDays: expireDays!==undefined?expireDays:7,
      expireDate: expireDate!==undefined?expireDate:minDate,
      expireType: expireType!==undefined && expireType!==''?expireType:"DAYS",
    };
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.minDate > this.state.expireDate) {
      this.setState({ expireDate: nextProps.minDate });
    }
  }

  onInputChange = (event) => {
    if(event.target.value <= 0)
      throw "Days can not be less than 1";
    else
      this.setState({ expireDays: event.target.value }, this.notifyChange);
  };

  onDateChange = (date) => {
    this.setState({ expireDate: date }, this.notifyChange);
  };

  onClick = (val) => {
    this.setState({ expireType: val }, this.notifyChange);
  };

  notifyChange = () => {
    const { expireType, expireDays, expireDate } = this.state;
    const { format, onChange } = this.props;
    const { EXPIRY_TYPE } = this.Constants;

    if (expireType === EXPIRY_TYPE.DAYS) {
      onChange(expireType, expireDays);
    } else if (expireType === EXPIRY_TYPE.DATE) {
      onChange(expireType, moment(expireDate).format(format));
    } else {
      onChange(expireType, "NULL");
    }
  };

  render() {
    const { expireDays, expireDate, expireType } = this.state;
    const { EXPIRY_TYPE } = this.Constants;
    const { isError, errorMsg, minDate } = this.props;

    return (
      <Segment className="m-0 p-0">
        <Form.Field required error={isError}>
          <label>Expiration Period</label>
        </Form.Field>
        <Form.Field error={isError} className="inline-items">
          <Radio
            label=""
            checked={expireType === EXPIRY_TYPE.DAYS}
            onClick={() => {
              this.onClick(EXPIRY_TYPE.DAYS);
            }}
          />
          <span>
            <label>
              Expires in
              <input
                type="number"
                style={{ width: "75px" }}
                value={expireDays}
                onChange={this.onInputChange}
                onClick={() => {
                  this.onClick(EXPIRY_TYPE.DAYS);
                }}
                min="1"
              />
              day(s) from Available Date
            </label>
          </span>
        </Form.Field>

        <Form.Field error={isError}>
          <Radio
            label={
              <label>
                <span>Expires on </span>
                <DatePickerWithTimezone
                  selected={expireDate}
                  onChange={this.onDateChange}
                  minDate={this.props.minDate}
                  timezone="America/Los_Angeles"
                />
              </label>
            }
            checked={expireType === EXPIRY_TYPE.DATE}
            onClick={() => {
              this.onClick(EXPIRY_TYPE.DATE);
            }}
          />
          <ErrorMessage isError={isError} errorMsg={errorMsg} />
        </Form.Field>

        <Form.Field error={isError}>
          <Radio
            label="Never Expires"
            checked={expireType === EXPIRY_TYPE.NEVER}
            onClick={() => {
              this.onClick(EXPIRY_TYPE.NEVER);
            }}
          />
        </Form.Field>
        <br />
      </Segment>
    );
  }
}

ExpirationPeriod.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** Format of the data/time. */
  format: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
};

export default ExpirationPeriod;
