import React, {useState} from 'react'
import ProductsContext from "../_context"
import { Segment, Button, Message } from 'semantic-ui-react'
import {Redirect} from 'react-router-dom'
import Api from "../../../../api"
import Constants from "../../../../config/constants"

function DeleteItem({item, cancel}) {

  const [isDeleting, setIsDeleting] = useState(false);


  const deleteItem = () => {
    const {URL_ITEM_DELETE} = Constants.App;
    const params = { params: {itemId: item.id}}
    const instance = Api.AxiosInstance.getInstance();
    setIsDeleting(true)
    instance.delete(URL_ITEM_DELETE, params).then((resp) => {
      cancel()
    });
  }

  return (
    <>
      <Segment raised>
        Are you sure you want to delete "{item.name}"?
        <hr />
        <Button color="red" onClick={deleteItem} disabled={isDeleting}>
          Delete
        </Button>{" "}
        <Button disabled={isDeleting} onClick={cancel}>
          Cancel
        </Button>
        {item.products && (item.products.length > 0) && <Message negative>This item is also associated with other folders. Deleting it will result in removal from those folders as well.</Message>}
      </Segment>
    </>
  );
}

export default ProductsContext.Consumer(DeleteItem);
