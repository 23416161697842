import React, { useReducer } from "react";
import { connect } from 'react-redux';
import { default as CoreActions } from "@/store/actions";
import {
  loggedInUserSelector
} from "@/common/selectors";
import Actions from "./actions";
import Reducer from "./reducer";


const InitialState = {
  notifications: {},
  isLoading: false,
  isSuccess: true,
  errorMessage: null,
  baseUrl: "/notifications",
  count: 0,
};

function MappedNotifications(props) {
  const { Component, ...propExploded } = props;
  const [state, dispatch] = useReducer(Reducer, InitialState);

  const actions = ((dispatch) => ({
    listNotifications: (page, limit, offset) => Actions.listNotifications(page, limit, offset)(dispatch),
    countNotifications: () => Actions.countNotifications()(dispatch),
  }))(dispatch);
  
  const value = { state, dispatch, actions, ...propExploded };
  return <Component {...value}></Component>;
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateNotificationCountToMain: () => Actions.countNotifications(true)(dispatch),
    showHideMainOverlay: (state) => {
      dispatch({
        type: CoreActions.Config.SHOW_HIDE_OVERLAY,
        payload: state
      });
    },
    hideSidebarNotifications: () => {
      dispatch({
        type: CoreActions.USER.NOTIFICATIONS_SIDEBAR_HIDE
      });
    }
  };
};

const mapStateToProps = (state) => ({
  user: loggedInUserSelector(state)
});

const NotificationsConnect = connect(mapStateToProps, mapDispatchToProps)(MappedNotifications);

export { NotificationsConnect };
