/**
 * Users actions
 */

const USER_LOGIN = "USER_LOGIN";
const USER_LOGOUT = "USER_LOGOUT";
const USER_LOGGING_IN = "USER_LOGGING_IN";

const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
const USER_DATA_FAILURE = "USER_DATA_FAILURE";
const TWO_FACTOR_AUTH_REQUIRED = "TWO_FACTOR_AUTH_REQUIRED";
const INVALID_2FA_CODE = "INVALID_2FA_CODE";

const USER_CLOSE_POPUP = "USER_CLOSE_POPUP";
const DISABLED_HELPDESK_NOTIF = "DISABLED_HELPDESK_NOTIF";

const NOTIFICATIONS_COUNT_UPDATE = 'NOTIFICATIONS_COUNT_UPDATE';
const NOTIFICATIONS_SIDEBAR_SHOW = 'NOTIFICATIONS_SIDEBAR_SHOW';
const NOTIFICATIONS_SIDEBAR_HIDE = 'NOTIFICATIONS_SIDEBAR_HIDE';

export default {
  USER_LOGIN,
  USER_LOGOUT,
  USER_LOGGING_IN,
  USER_DATA_SUCCESS,
  USER_DATA_FAILURE,
  TWO_FACTOR_AUTH_REQUIRED,
  INVALID_2FA_CODE,
  USER_CLOSE_POPUP,
  DISABLED_HELPDESK_NOTIF,
  NOTIFICATIONS_COUNT_UPDATE,
  NOTIFICATIONS_SIDEBAR_SHOW,
  NOTIFICATIONS_SIDEBAR_HIDE
};
