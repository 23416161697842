import { CardviewMobile } from "@/common/cardview";
import update from "immutability-helper";
import { merge } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {dateToPacific} from "@/utils/dateTime.js";
import { Dropdown, Grid, Modal, Responsive, Segment } from "semantic-ui-react";
import Api from "../../api";
import urls from "../../config/constants";
import Tableview from "../tableview";

class GroupListing extends React.Component {
  Constants = {
    COLUMNS: [
      { name: "Users", key: "users_count", cb: () => {
        this.sortByHeader("users_count", "Users");
      }, },
      { name: "Group Name", key: "name", cb: () => {
        this.sortByHeader("name", "Group Name");
      }, },
      { name: "Description", key: "description", cb: () => {
        this.sortByHeader("description", "Description");
      }, },
      // { name: "Type", key: "group_type" },
      { name: "Created By", key: "created_by", cb: () => {
        this.sortByHeader("createdBy", "Created By");
      }, },
      { name: "Creation Date", key: "creation_date", cb: () => {
        this.sortByHeader("creationDate", "Creation Date");
      }, },
    ],
    RESPONSIVE_COLUMNS: [
      { name: "Users", key: "users_count" },
      { name: "Group Name", key: "name" },
      { name: "Description", key: "description" },
      // { name: "Type", key: "group_type" },
      { name: "Created By", key: "created_by" },
      { name: "Creation Date", key: "creation_date" },
    ],
    SearchOptions: [
      { key: "groupname-search", text: "Group Name", value: "name" },
      { key: "description-search", text: "Description", value: "description" },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      groupsList: {},
      configOption: {},

      configOptionOld: {
        search_in: "",
        keyword: "",
        page: 0,
      },
      selectedStatedGroups: [],
      selectedGroupsIndexes: [],
      checkedGroupsIds: [],
      isDescSearch: true,
      sortBy: "",
    };
    
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    this.fetchGroups();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.COLUMNS = this.Constants.COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchGroups();
    });
   
  };

  fetchGroups = () => {
    const { URL_LISTINGS_GROUPS } = urls.App;
    let { configOption } = this.state;
    const { entity, entityId } = this.props;
    configOption = merge(configOption,{sort:this.state.sortBy})
    const paramsForPerspective = {
      entity,
      entityId,
    };
    const params = {
      params: merge(
        configOption,
        entity && entityId ? paramsForPerspective : {}
      ),
    };
    const parameterizedUrl = `${URL_LISTINGS_GROUPS}`;
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(parameterizedUrl, params).then((resp) => {
      this.setState({
        groupsList: this.transformData(resp.data),
      });
    });
  };

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.onSearchClicked();
    }
  }

  // transformData
  transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.creation_date = (       
            <>{dateToPacific(row.creation_date,"MM/DD/YYYY")}</>   
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };
  onItemSelection = (e) => {
    let selectedStatedGroups = this.state.selectedStatedGroups;
    let ids = this.state.checkedGroupsIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedGroups.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedGroups.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedGroups = selectedStatedGroups.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedGroups.map((s) => s.id);
    }
    this.setState({ checkedGroupsIds: ids });

    this.setState({
      selectedStatedGroups: selectedStatedGroups,
    });
  };
  onAllItemSelection = (selectedGroups, flag) => {
    let {selectedStatedGroups} = this.state;
    let ids=[];
    if(flag){
      selectedGroups.map((group)=>{
        selectedStatedGroups = selectedStatedGroups.filter((entity) => {
          return entity.id !== group.id;
        })
      });
    }else{
      selectedGroups.map((group)=>{
          if (group) {
            const alreadyPushed = selectedStatedGroups.find(
              (element) => element.id == group.id
            );
            if (!alreadyPushed) {
              selectedStatedGroups.push(group);
            } }
          } );
    }
    ids = selectedStatedGroups.map((s) => s.id);
    this.setState({
      selectedStatedGroups: selectedStatedGroups,
      checkedGroupsIds: ids,
       });
  }

  onPageChange = (e) => {
    const { configOption } = this.state;
    this.setState(
      { configOption: update(configOption, { page: { $set: e.page } }) },
      this.fetchGroups
    );
  };

  onSearchClicked = () => {
    // always fetch the first page while searching.
    // You never know if it has got more than one page data or not.
    this.onPageChange({ page: 0 });
  };

  onSearchTextChange = (data) => {
    const { configOption } = this.state;
    this.setState({
      configOption: update(configOption, {
        keyword: { $set: data.target.value },
      }),
    });
  };

  onSearchTypeChange = (e, data) => {
    const { configOption } = this.state;
    this.setState({
      configOption: update(configOption, { search_in: { $set: data.value } }),
    });
  };

  resetSearchConfig = () => {
    this.setState({ configOption: {} });
  };

  onModalClose = () => {
    this.resetSearchConfig();
    const { onDismiss } = this.props;
    if (onDismiss) onDismiss();
    this.setState({
      selectedStatedGroups: [],
      checkedGroupsIds: [],
    });
  };

  render() {
    const { 
      groupsList,
      selectedStatedGroups,
      selectedGroupsIndexes,
      checkedGroupsIds, } = this.state;
    const { onSave, show, selectionType } = this.props;
    const { COLUMNS, SearchOptions, RESPONSIVE_COLUMNS } = this.Constants;

    return (
      <Modal
        open={show}
        onMount={this.fetchGroups}
        onClose={this.onModalClose}
        centered={true}
        size="large"
      >
        <Modal.Header>
          <div className="side-marker" />
          <div className="header"> List of Groups</div>
        </Modal.Header>

        <Modal.Content>
          <Segment basic padded={false} className="p-0">
            <Grid>
              <Grid.Row className="common-filter-wrapper ui form">
                <Grid.Column
                  mobile={16}
                  tablet={6}
                  computer={6}
                  largeScreen={6}
                >
                  {" "}
                  <div class="field">
                    <input
                      placeholder="Search"
                      className="search-bar"
                      onChange={this.onSearchTextChange}
                      onKeyPress={this.onKeyUp}
                    ></input>
                  </div>
                </Grid.Column>

                <Grid.Column
                  mobile={16}
                  tablet={6}
                  computer={6}
                  largeScreen={6}
                >
                  {" "}
                  <div class="field">
                    <Dropdown
                      options={SearchOptions}
                      selection
                      onChange={this.onSearchTypeChange}                    
                    />
                  </div>
                </Grid.Column>

                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={4}
                  largeScreen={4}
                >
                  {" "}
                  <div class="field">
                    <button
                      onClick={this.onSearchClicked}
                      className="button-primary"
                    >
                      Search
                    </button>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination mt-2">
                    <Tableview
                      paginate
                      selectionType={selectionType}
                      data={groupsList}
                      columns={COLUMNS}
                      onSelectionChange={this.onItemSelection}
                      onAllSelectionChange={this.onAllItemSelection}
                      onPaginationChange={this.onPageChange}
                      checkedIndexes={selectedGroupsIndexes}
                      checkedIds={checkedGroupsIds}
                      noDataMessage="No Group found."
                    />
                  </div>
                </Responsive>

                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    data={groupsList}
                    columns={RESPONSIVE_COLUMNS}
                    onSelectionChange={this.onItemSelection}
                    onAllSelectionChange={this.onAllItemSelection}
                    selectionType={selectionType}
                    paginate
                    onPaginationChange={(e) => this.onPageChange(e)}
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="mt-2"
              >
                <button
                  className="button-primary m--no-margin-left-im"
                  onClick={() => {
                    onSave(selectedStatedGroups);
                    this.setState({
                      selectedStatedGroups: [],
                      checkedGroupsIds: [],
                    });
                  }}
                >
                  Save
                </button>
                <button className="button-basic" onClick={this.onModalClose}>
                  Close
                </button>
              </Grid.Column>
            </Grid.Row>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}

GroupListing.propTypes = {
  /** Callback which is fired when Popup is closed. */
  onDismiss: PropTypes.func.isRequired,
  /** Callback which is fired when `resend` button is clicked. */
  onSave: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
  /** single-select = radio buttons, multi-select = checkboxes */
  selectionType: PropTypes.oneOf(["single-select", "multi-select"]),
  /** Perspective: */
  entity: PropTypes.oneOf([
    "PackageGroups",
    "ProductGroups",
    "UserGroups",
    "NdaGroups",
    "LicenseGroups",
  ]),
  /** */
  entityId: PropTypes.number,
};

GroupListing.defaultProps = {
  selectionType: "multi-select",
};

export default GroupListing;
