import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Button, Comment, Confirm, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  userNameSelector,
  authoritySelector,
  userIdSelector,
} from "@/common/selectors";

class Post extends React.Component {
  DISPLAY_MODES = Object.freeze({ read_mode: 0, reply_mode: 1 });

  constructor(props) {
    super(props);
    this.state = {
      displayMode: this.DISPLAY_MODES.read_mode,
      showConfirmDialog: false,
      replyText: null,
      replySuccess: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.onReplyCancel();
    }
  }

  onTypingReply = (event) => {
    this.setState({ replyText: event.target.value });
  };

  onReplyButtonClicked = () => {
    this.setState({ displayMode: this.DISPLAY_MODES.reply_mode });
  };

  onReplyCancel = () => {
    this.setState({ displayMode: this.DISPLAY_MODES.read_mode });
  };

  toggleConfirmation = (val) => {
    this.setState({ showConfirmDialog: val });
  };

  deletePost = () => {
    const { data, deleteFunction } = this.props;
    this.toggleConfirmation(false);
    if (deleteFunction instanceof Function) deleteFunction(data.postId);
  };

  postComment = () => {
    const { data, postFunction } = this.props;
    const { replyText } = this.state;
    if (postFunction instanceof Function) {
      postFunction({ profileId: data.profileId, postText: replyText });
    }
    this.setState({ replySuccess: true });
  };

  onAcceptPost = () => {
    const { data, acceptFunction } = this.props;
    if (acceptFunction instanceof Function) acceptFunction(data.postId);
  };

  render() {
    const { acceptFunction, deleteFunction, postFunction, data } = this.props;
    const { firstname, lastname, postingTime, post, status, posterId, posterPicture } = data;
    const { displayMode, replySuccess, showConfirmDialog } = this.state;
    const formattedPostTime = moment(postingTime, "YYYY-MM-DD HH:mm A");
    return (
      <React.Fragment>
        <Comment>
          <Comment.Avatar
            src={ posterPicture ? posterPicture : "https://react.semantic-ui.com/images/avatar/small/elliot.jpg" }
            />
          <Comment.Content>
            <Comment.Author as="a">{`${firstname} ${lastname}`}</Comment.Author>
            <Comment.Metadata>
              <div>{formattedPostTime.format("LLLL")}</div>
            </Comment.Metadata>
            <Comment.Text>{post}</Comment.Text>
            {(!status || status === "CONFIRMED") && (
              <Comment.Actions>
                {postFunction && (
                  <Comment.Action onClick={this.onReplyButtonClicked}>
                    Reply
                  </Comment.Action>
                )}
                {deleteFunction && (
                  <>
                    {this.props.authority !== "websilo-customer" &&
                    this.props.authority !== "websilo-employee" &&
                    this.props.authority !== "websilo-account-approver" ? (
                      <Comment.Action
                        onClick={() => this.toggleConfirmation(true)}
                      >
                        Delete
                      </Comment.Action>
                    ) : (
                      posterId == this.props.userId && (
                        <Comment.Action
                          onClick={() => this.toggleConfirmation(true)}
                        >
                          Delete
                        </Comment.Action>
                      )
                    )}
                    {}
                  </>
                )}
              </Comment.Actions>
            )}
            {status === "PENDING" && (
              <Comment.Actions>
                {acceptFunction && (
                  <Comment.Action onClick={this.onAcceptPost}>
                    Accept
                  </Comment.Action>
                )}
                {deleteFunction && (
                  <Comment.Action onClick={() => this.toggleConfirmation(true)}>
                    Delete
                  </Comment.Action>
                )}
              </Comment.Actions>
            )}
          </Comment.Content>
          {displayMode === this.DISPLAY_MODES.reply_mode && (
            <Form>
              <br />
              <Form.Field
                control="textarea"
                rows="2"
                onChange={this.onTypingReply}
                disabled={replySuccess}
              />
              {!replySuccess && (
                <React.Fragment>
                  <button className="button-primary" onClick={this.postComment}>
                    {" "}
                    Reply{" "}
                  </button>
                  <button className="button-basic" onClick={this.onReplyCancel}>
                    {" "}
                    Cancel{" "}
                  </button>
                </React.Fragment>
              )}
            </Form>
          )}
        </Comment>
        <Confirm
          open={showConfirmDialog}
          content="Do you want to delete this post?"
          confirmButton="Delete"
          cancelButton="Cancel"
          onCancel={() => this.toggleConfirmation(false)}
          onConfirm={this.deletePost}
        />
      </React.Fragment>
    );
  }
}

Post.propTypes = {
  /** Data about a single post element. */
  data: PropTypes.shape({
    /** First name of the `commentor` */
    firstname: PropTypes.string.isRequired,
    /** Last name of the commentor */
    lastname: PropTypes.string.isRequired,
    /** The text content of the `Post` */
    post: PropTypes.string.isRequired,
    /** id of the post. */
    postId: PropTypes.number.isRequired,
    /** Commentor Id */
    posterId: PropTypes.number.isRequired,
    /** Time when the post was made */
    postingTime: PropTypes.string,
  }),
  /** The function which will change the status of a post from `PENDING` to `CONFIRMED` */
  acceptFunction: PropTypes.func,
  /** The function which will actually contain the Delete logic */
  deleteFunction: PropTypes.func.isRequired,
  /** The function which will post to the reply API */
  postFunction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  authority: authoritySelector(state),
  userName: userNameSelector(state),
  userId: userIdSelector(state),
  // baseUrl: baseUrl,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mappedDocumentListing = connect(
  mapStateToProps,
  mapDispatchToProps
)(Post);

export default mappedDocumentListing;
