import Api from "@/api";
import FileIcon from "@/common/file-icon";
import PackageListing from "@/common/package-selection";
import Constants from "@/config/constants";
import React, { Component } from "react";
import {
  Confirm,
  Container,
  Divider,
  Grid,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import validate from "validate.js";

class Package extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: { content: [] },
      selectedItems: [],
      showError: false,
      items: [],
      action: null,
      msg: null,
      selectedItem: [],
      // need to check with pagination:
      page: 0,
      deleteWarning: false,
      showPackageListing: false,
      userId: "",
      packageName: "",
      packageId: null,
      status: null,
      showMessage: false,
    };
  }

  componentDidMount() {}
  onSelectPackage = (e) => {
    e.preventDefault();
    this.setState({ showPackageListing: true });
  };

  onSavePackageListing = (list) => {
    list.map((data) =>
      this.setState({
        showPackageListing: false,
        packageId: data.id,
        packageName: data.name,
        items: data.items,
      })
    );
    console.log("prodasd inside", this.props);
  };
  onDismissPackageListing = () => {
    this.setState({ showPackageListing: false });
  };
  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;

    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  showDeleteWarningAndSetItem = (row) => {
    this.setState({
      deleteWarning: true,
      selectedItem: row,
      msg: "Something went wrong while unassigning the item(s) to package.",
      action: "DELETE_ITEM",
    });
  };
  loadItems = () => {
    const { URL_PACKAGE_DETAILS } = Constants.App;
    const params = {
      params: {
        id: this.state.packageId,
        target: 0,
        sort: "item.name,DESC",
        page: 0,
      },
    };
    const instance = Api.AxiosInstance.getInstance();
    instance.get(URL_PACKAGE_DETAILS, params).then((resp) => {
      this.setState({
        isLoaded: true,
        items: resp.data.items,
      });
    });
  };
  updatePackages = () => {
    const { action, msg, selectedItem } = this.state;
    const params = { params: { id: this.state.packageId } };
    let itemIds = [];
    itemIds.push({ id: selectedItem["id"] });
    //selectedItem.map(item => itemIds.push({ id: item.id }));
    let url = Constants.App.URL_PACKAGE_DETAILS;
    let payload = { action: action, items: itemIds };
    Api.AxiosInstance.getInstance()
      .post(url, payload, params)
      .then((resp) => {
        this.loadItems();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: msg,
        });
      });
  };

  handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // debugger;
    let data = JSON.parse(e.dataTransfer.getData("item"));
    this.setState(
      {
        action: "ADD_ITEM",
        selectedItem: { id: data.id },
      },
      this.updatePackages
    );
  };
  render() {
    const { items, packageName, packageId } = this.state;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="header">
                <div className="side-marker" />

                <b> Edit Packages</b>
                <button
                  type="button"
                  className="button-basic btn-s-t3-im m-0"
                  title="Load Package"
                  onClick={this.onSelectPackage}
                >
                  SELECT PACKAGE
                </button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <PackageListing
          show={this.state.showPackageListing}
          onDismiss={this.onDismissPackageListing}
          onSave={this.onSavePackageListing}
          selectionType="single-select"
          title="List of Package"
        />
        {packageId && (
          <>
            <div
              className={"drag-drop-zone"}
              onDrop={(e) => this.handleDrop(e)}
              onDragOver={(e) => this.handleDragOver(e)}
              onDragEnter={(e) => this.handleDragEnter(e)}
              onDragLeave={(e) => this.handleDragLeave(e)}
            >
              <p>
                Drop <strong>Item(s)</strong> here to Add into {packageName}{" "}
                Package
              </p>
            </div>

            <Divider />

            <div color="black">
              <strong>
                {" "}
                {validate.capitalize(validate.prettify(packageName))}{" "}
              </strong>
            </div>
            <Divider />
          </>
        )}

        {packageId &&
          (items.length == 0 ? (
            <Segment basic>
              <Container>
                {" "}
                <em>Currently No Item(s) In this Package </em>
              </Container>
            </Segment>
          ) : (
            ""
          ))}

        {items && (
          <Segment basic className="items-wrapper">
            <List animated selection divided verticalAlign="middle">
              {items.map((item) => (
                <List.Item>
                  <List.Content floated="right">
                    <Icon
                      color="red"
                      link
                      name="close"
                      title="Remove"
                      onClick={() => this.showDeleteWarningAndSetItem(item)}
                    />
                  </List.Content>
                  <Image>
                    <FileIcon
                      filename={item.filename ? item.filename : "other"}
                      mimetype={item.itemType}
                    ></FileIcon>
                  </Image>
                  <List.Content>
                    <List.Header as="a"> </List.Header>
                    {/*{item.description}*/}
                    <span className="black">{item.name}</span>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        )}
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updatePackages)}
          content={`Are you sure you want to delete this Item?`}
        />
      </React.Fragment>
    );
  }
}

export default Package;
