import React from "react";
import { get, isEmpty } from "lodash";
import {
  Button,
  Icon,
  Modal,
  Segment,
  Confirm,
  Responsive,
} from "semantic-ui-react";
import update from "immutability-helper";
import PackageListing from "./package-listing.js";
import Tableview from "@/common/tableview";
import { CardviewMobile } from "@/common/cardview";
import urls from "@/config/constants";
import Api from "@/api";
import { dateToPacific } from "@/utils/dateTime";
import moment from 'moment';

class PackageVidoes extends React.Component {
  Constants = {
    TABLE_COLUMNS: Object.freeze([
      { name: "Package Name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Package Name");
      }, },
      { name: "Assigned By", key: "createdBy", showTooltip: true, cb: () => {
        this.sortByHeader("createdBy", "Assigned By");
      }, },
      { name: "Assigned On", key: "creationDateModified", showTooltip: true, cb: () => {
        this.sortByHeader("creationDateModified", "Assigned On");
      }, },
    ]),
    RESPONSIVE_TABLE_COLUMNS: Object.freeze([
      { name: "Package Name", key: "name" },
      { name: "Assigned By", key: "createdBy" },
      { name: "Assigned On", key: "creationDateModified" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedPackages: { content: [] },
      errorMsg: null,
      showError: false,
      selectedPackages: [],
      showPackageListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
      isDescSearch: true,
      sortBy: "creationDateModified,DESC",
      sortingKey: "name",
      selectedStatedPackages: [],
      selectedPackagesIndexes: [],
      checkedPackagesIds: [],
      videoId : parseInt(window.location.href.split("/").pop())
    };
    
  }

  componentDidMount() {
    this.fetchAssignedPackages();
  }

  transformData = (data) => {
    return data.map((d) => {
      return {
        ...d,
        creationDateModified: dateToPacific(
          d.creationDate,
          "MM/DD/YYYY hh:mm a"
        ),
      };
    });
  };

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({
      sortingKey: key
    },()=>{
      if (!isDesc) {
        query = `${key},ASC`;
      }
      this.setState({ sortBy: query }, () => {
        this.Constants.TABLE_COLUMNS = this.Constants.TABLE_COLUMNS.map((obj) => {
          if (obj.name == title) {
            return {
              ...obj,
              isDesc: isDesc,
              isActive: true,
            };
          } else
            return {
              ...obj,
              isActive: false,
            };
        });
        this.setState({ isDescSearch: !isDesc });
        this.fetchAssignedPackagesSorted();
      });
    });
  };

  fetchAssignedPackagesSorted = () => {
    const { assignedPackages } = this.state;
    const { sortingKey } = this.state;
    const { isDescSearch } = this.state;
    let data = this.state.assignedPackages.content.sort(function(a, b){
        var objA=(String(a[sortingKey])).toLowerCase(), objB=(String(b[sortingKey])).toLowerCase();
        sortingKey==="creationDateModified"?objA=moment(a[sortingKey]):objA=objA
        sortingKey==="creationDateModified"?objB=moment(b[sortingKey]):objB=objB
        if(!isDescSearch){
        if (objA < objB)
         return -1;
        if (objA > objB)
         return 1;
        return 0;}
        else{
         if (objA > objB)
          return -1;
         if (objA < objB)
          return 1;
         return 0;
        }
       });
    this.setState({
      assignedPackages: update(assignedPackages, {
        content: { $set:data,}})
    });
  }

  fetchAssignedPackages = () => {
    const { assignedPackages ,videoId} = this.state;
    const { URL_VIDEO_PACKAGE } = urls.App;
    let url = `${URL_VIDEO_PACKAGE}/${videoId}`;
    Api.AxiosInstance.getInstance()
      .get(url)
      .then((resp) => {
        this.setState({
          assignedPackages: update(assignedPackages, {
            content: { $set: this.transformData(resp.data.content) },
          }),
        });
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: "Something went wrong while loading the packages list.",
        });
      });
  };

  onAssign = () => {
    this.setState({ showPackageListing: true });
  };

  doAssign = (data) => {
    this.setState({ showPackageListing: false, selectedPackages: data, selectedStatedPackages: [], selectedPackagesIndexes: [], checkedPackagesIds: [], }, () => {
      this.updatePackages(
        "ADD_PACKAGE",
        "Something went wrong while assigning new package(s)."
      );
    });
  };

  onUnassign = () => {
    const { selectedPackages } = this.state;
    if (isEmpty(selectedPackages)) {
      this.setState({
        showError: true,
        errorMsg:
          "No package selected for deletion. Please select one or more packages.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "REMOVE_PACKAGE",
        "Something went wrong while unassigning the package(s)."
      );
    }
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb(action, msg);
      }
    );
  };
  updatePackages = (actionType, errorMsg) => {
    const { selectedPackages ,videoId} = this.state;
    const { URL_VIDEOS } = urls.App;
    let url = `${URL_VIDEOS}?id=${videoId}`;
    let payload = { actiontype: actionType, packages: selectedPackages };
    Api.AxiosInstance.getInstance()
      .post(url, payload)
      .then((resp) => {
        this.fetchAssignedPackages();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };

  onPackageSelection = (e) => {
    let selectedStatedPackages = this.state.selectedStatedPackages;
    let ids = this.state.checkedPackagesIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedPackages.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedPackages.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedPackages = selectedStatedPackages.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedPackages.map((s) => s.id);
    }
    this.setState({ checkedPackagesIds: ids });

    this.setState({
      selectedStatedPackages: selectedStatedPackages,
      selectedPackages: selectedStatedPackages,
    });
  };

  onAllItemSelection = (selectedPackages, flag) => {
    let {selectedStatedPackages} = this.state;
    let ids=[];
    if(flag){
      selectedPackages.map((group)=>{
        selectedStatedPackages = selectedStatedPackages.filter((entity) => {
          return entity.id !== group.id;
        })
      });
    }else{
      selectedPackages.map((group)=>{
          if (group) {
            const alreadyPushed = selectedStatedPackages.find(
              (element) => element.id == group.id
            );
            if (!alreadyPushed) {
              selectedStatedPackages.push(group);
            } }
          } );
    }
    ids = selectedStatedPackages.map((s) => s.id);
    this.setState({
      selectedStatedPackages: selectedStatedPackages,
      selectedPackages: selectedStatedPackages,
      checkedPackagesIds: ids,
       });
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closePackageListing = () => {
    this.setState({ showPackageListing: false });
  };

  render() {
    const { TABLE_COLUMNS, RESPONSIVE_TABLE_COLUMNS } = this.Constants;
    const { assignedPackages, errorMsg, showError, showPackageListing, selectedStatedPackages, selectedPackagesIndexes, checkedPackagesIds } =
      this.state;

    return (
      <React.Fragment>
        <Segment basic className="p-0">
          <div className="control-action-wrapper mb-1">
            <Button className="button-basic mini" onClick={this.onAssign}>
              <Icon name="plus" />
              Assign New Packages
            </Button>
            <Button
              color="red"
              className="button-basic mini mr-0-im"
              onClick={this.onUnassign}
            >
              <Icon name="remove circle" />
              Unassign
            </Button>
          </div>
        </Segment>

        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination">
            <Tableview
              columns={TABLE_COLUMNS}
              data={assignedPackages}
              onSelectionChange={this.onPackageSelection}
              noDataMessage="No packages found."
              selectionType="multi-select"
              onAllSelectionChange={this.onAllItemSelection}
              checkedIndexes={selectedPackagesIndexes}
              checkedIds={checkedPackagesIds}
            />
          </div>
        </Responsive>
        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={assignedPackages}
            columns={RESPONSIVE_TABLE_COLUMNS}
            onSelectionChange={this.onPackageSelection}
            noDataMessage="No packages found."
            selectionType="multi-select"
            onAllSelectionChange={this.onAllItemSelection}
            checkedIndexes={selectedPackagesIndexes}
            checkedIds={checkedPackagesIds}
          />
        </Responsive>

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <PackageListing
          show={showPackageListing}
          onDismiss={this.closePackageListing}
          onSave={this.doAssign}
          entity="VideoPackages"
          entityId={get(this.props, ["match", "params", "videoId"])}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updatePackages)}
          content={`Are you sure you want to delete this package(s)?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageVidoes;
