import React, { useEffect } from "react";
import { useState } from "react";
import Api from "@/api";
import Constants from "@/config/constants";
import { Link } from "react-router-dom";
import { Confirm, Segment, Responsive, Grid  } from "semantic-ui-react";
import Tableview from "@/common/tableview";
import {CardviewMobile} from "@/common/cardview";
import { dateToPacific } from "@/utils/dateTime";

let TagsListing = () => {
    
    let [pageNum, setPageNum] = useState(0);
    let [tagsListing, setTagsListing] = useState([]);
    let [sortBy, setSortBy] = useState("creationDate,DESC");
    let [isDescSearch, setIsDescSearch] = useState(true);
    let [sortTitle, setSortTitle] = useState(null);
    let [deleteWarning, setDeleteWarning] = useState(false);
    let [tagToRemoveId, setTagToRemoveId] = useState(null);
    let [columns, setColumns] = useState([
        { name: "Name", key: "name", width: "4", showTooltip: true, cb: () => {
          sortByHeader("name", "Name");
        }},
        { name: "Creation Date", key: "creationDate", width: "10", showTooltip: true, cb: () => {
            sortByHeader("creationDate", "Creation Date");
        },},
        { name: "Actions", key: "actions" }
    ]);
    let [responsiveColumns, setResponsiveColumns] = useState([
        { name: "Name", key: "name", width: "4" }
    ]);
    
    const showDeleteWarningAndSetTagId = (e, id) => {
        setDeleteWarning(true);
        setTagToRemoveId(id);
    };
    
    const transformData = data => {
        let transformedData = Object.assign({}, data);
        let content = [];
        if (data) {
          transformedData.content.map(el => {
            let row = Object.assign({}, el);
            row.creationDate = dateToPacific(row.creationDate, "MM/DD/YYYY hh:mm:ss A");
            row.actions = (
              <React.Fragment>
                <span
                  className="icon-delete"
                  onClick={e => showDeleteWarningAndSetTagId(e, row.id)}
                  style={{ margin: "3px" }}
                  title="Remove"
                  name="remove"
                />
              </React.Fragment>
            );
            content.push(row);
          });
        }
        transformedData.content = content;
        return transformedData;
    };
    
    const getList = () => {
        let params = {
          params: {
            page: pageNum,
            sort: sortBy
          }
        };
        return Api.AxiosInstance.paginate(
          Constants.App.URL_ADMIN_TAGS_LISTING,
          params
        ).then(resp => {
          setTagsListing(transformData(resp.data));
        });
    };
    
    const sortByHeader = (key, title) => {
        let query = `${key},DESC`;
        const isDesc = isDescSearch;
        if (!isDesc) {
            query = `${key},ASC`;
        }
        setSortBy(query);
        setSortTitle(title);
        
        setIsDescSearch(!isDesc);
        getList();
    };
    
    const onPageChange = e => {
        setPageNum(e.page);
    };
    
    const hideDeleteWarning = cb => {
        setDeleteWarning(false);
        if (cb instanceof Function) {
            cb();
        }
    };
    
    const remove = () => {
        let params = {
          params: {
            id: tagToRemoveId
          }
        };
        let payload = {
            actionType: "DELETE_TAG"
        }
        return Api.AxiosInstance.getInstance()
          .post(Constants.App.URL_ADMIN_TAGS_REMOVE, payload, params)
          .then(resp => {
            getList();
          });
    };
    
    useEffect(() => {
        getList();
    }, [pageNum]);
    
    useEffect(() => {
        setColumns((prev) => {
            return prev.map((obj) => {
                if (obj.name == sortTitle) {
                    return {
                    ...obj,
                    isDesc: isDescSearch,
                    isActive: true,
                    };
                } else {
                    return {
                    ...obj,
                    isActive: false,
                    };
                }
            });
        });
    }, [sortBy, sortTitle, isDescSearch]);
    
    return (
        
        <React.Fragment>

            <Segment className="base-segment-wrapper">
                <Grid>
                    <Grid.Row>
                        <Grid.Column
                        mobile={10}
                        tablet={8}
                        computer={8}
                        largeScreen={8}
                        >
                        <div className="header">
                            <div className="side-marker" />
                            Tags
                        </div>
                        </Grid.Column>
                        <Grid.Column
                        mobile={6}
                        tablet={8}
                        computer={8}
                        largeScreen={8}
                        >
                        <div className="control-action-wrapper">
                            <Link to={"/admin/tags/new"}>
                                <button className="button-basic">Add New</button>
                            </Link>
                        </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Responsive minWidth={1024}>
                        <div className="table-wrapper-with-pagination">
                            <Tableview
                                paginate
                                data={tagsListing}
                                columns={columns}
                                onPaginationChange={onPageChange}
                                noDataMessage="No items"
                            />
                        </div>
                    </Responsive>

                    <Responsive maxWidth={1023}>
                        <CardviewMobile
                            data={tagsListing}
                            columns={responsiveColumns}
                            paginate
                            onPaginationChange={onPageChange}
                        
                        />
                    </Responsive>
                </Grid>
            </Segment>
                
            <Confirm
            open={deleteWarning}
            onCancel={hideDeleteWarning}
            onConfirm={() => hideDeleteWarning(() => remove())}
            content="The tag may have been used within the portal, continue deleting?"
            />
        </React.Fragment>
    );
}

export default TagsListing;