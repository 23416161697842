import React from "react";
import { Grid, Segment, GridRow, Icon, Message } from "semantic-ui-react";
import DownloadBriefcaseItem from "../components/download-items";
import Api from "@api";
import Constants from "@constants";
import { Redirect } from "react-router-dom";
import DownloadProjectItem from "../components/download-items";

class DownloadProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      itemLoaded: false,
      itemIsLoading: true,
      redirect: false,
      invalidItem: false,
    };
  }

  componentDidMount() {
    this.loadItem(); // load data from token
  }

  loadItem() {
    const itemToken =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
        ? this.props.match.params.id
        : false;

    if (!itemToken) {
      this.setState({
        invalidItem: true,
      });
    }
    // const { URL_BRIEFCASE_DOWNLOAD } = Constants.App;
    const instance = Api.AxiosInstance.getInstance();
    instance
      .get(`projects/document/download/${itemToken}`)
      .then((resp) => {
        this.setState({
          item: resp.data,
          itemLoaded: true,
          itemIsLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          itemIsLoading: false,
          invalidItem: true,
        });
      });
  }

  goBack = () => {
    this.setState({ redirect: true });
  };

  render() {
    const { item, itemLoaded, itemIsLoading, redirect, invalidItem } =
      this.state;

    if (redirect) {
      return <Redirect to={"/"} />;
    }

    return (
      <>
        <div className="products-container">
          <Segment className="base-segment-wrapper">
            <Grid>
              <GridRow className="products-header">
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="side-marker" />
                  <div className="header inline">Document Info</div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                ></Grid.Column>
              </GridRow>
              <GridRow>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                >
                  {invalidItem && (
                    <Message negative>
                      You do not have permission to access this item!
                    </Message>
                  )}
                  {itemIsLoading && (
                    <>
                      <Icon loading name="spinner" /> Loading details!
                    </>
                  )}
                  {itemLoaded && item && (
                    <DownloadProjectItem item={item} cancel={this.goBack} />
                  )}
                </Grid.Column>
              </GridRow>
            </Grid>
          </Segment>
        </div>
      </>
    );
  }
}

export default DownloadProject;
