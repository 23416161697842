import React from "react";
import PageTemplate from "../../common/page";
import ProjectList from "./pages/list";
import ProjectView from "./pages/view";
import ProjectEdit from "./pages/edit";
import ProjectCreate from "./pages/create";
import ProjectDocuments from "./pages/projectdocuments";
import DocumentForm from "./pages/projectdocuments/new";
import ProjectMembers from "./pages/projectmembers";
import ProjectPosts from "./pages/projectposts";
import Documents from "./documents/";
import ProjectInvites from "./pages/invites";
import projectIdDispatcher from "./dispatch";
import Config from "@/config/constants";
import DownloadProject from "./pages/download";

const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: null,
      loading: false,
    };

    projectIdDispatcher.register((dispatch) => {
      if (dispatch.type === "PROJECT_ID_CHANGE") {
        this.setState({ projectId: dispatch.value });
      }
    });
  }

  render() {
    const { projectId } = this.state;

    const PROJECT_VIEW_MENU_ITEMS = {
      Projects: [
        {
          name: "All Projects",
          relativePath: "",
          exact: true,
          subPageComponent: ProjectList,
        },
        {
          name: "Project Invites",
          relativePath: "/invites/all",
          exact: true,
          subPageComponent: ProjectInvites,
        },
      ],
      Project: [
        {
          name: "Edit Project",
          relativePath: "/edit/:id",
          hide: true,
          subPageComponent: ProjectEdit,
        },
        {
          name: "Create",
          relativePath: "/create/new",
          hide: true,
          subPageComponent: ProjectCreate,
        },
        {
          name: "Create document",
          relativePath: "/:id/documents/new",
          exact: true,
          hide: true,
          subPageComponent: DocumentForm,
        },
        {
          name: "View Project",
          relativePath: "/:id",
          exact: true,
          clickPath: `/${projectId}`,
          hide: !projectId,
          subPageComponent: ProjectView,
        },
        {
          name: "Documents",
          relativePath: "/:id/documents",
          exact: true,
          clickPath: `/${projectId}/documents`,
          hide: !projectId,
          subPageComponent: ProjectDocuments,
        },
        {
          name: "Members",
          relativePath: "/:id/members",
          clickPath: `/${projectId}/members`,
          hide: !projectId,
          subPageComponent: ProjectMembers,
        },
        {
          name: "Wall",
          relativePath: "/:id/wall",
          clickPath: `/${projectId}/wall`,
          hide: !projectId,
          subPageComponent: ProjectPosts,
        },

        {
          name: "Download Project",
          relativePath: `/download/:id`,
          subPageComponent: DownloadProject,
          hide: true,
        },
      ],
      Documents: [
        {
          name: "All Documents",
          relativePath: "/documents/all",
          subPageComponent: Documents,
        },
      ],
    };
    const PROJECT_VIEW_MENU_ITEMS_CUSTOMER = {
      Projects: [
        {
          name: "All Projects",
          relativePath: "",
          exact: true,
          subPageComponent: ProjectList,
        },
        {
          name: "Project Invites",
          relativePath: "/invites/all",
          exact: true,
          subPageComponent: ProjectInvites,
        },
      ],
      Project: [
        {
          name: "Edit Project",
          relativePath: "/edit/:id",
          hide: true,
          subPageComponent: ProjectEdit,
        },
        {
          name: "Create",
          relativePath: "/create/new",
          hide: true,
          subPageComponent: ProjectCreate,
        },
        {
          name: "Create document",
          relativePath: "/:id/documents/new",
          exact: true,
          hide: true,
          subPageComponent: DocumentForm,
        },
        {
          name: "View Project",
          relativePath: "/:id",
          exact: true,
          clickPath: `/${projectId}`,
          hide: !projectId,
          subPageComponent: ProjectView,
        },
        {
          name: "Documents",
          relativePath: "/:id/documents",
          exact: true,
          clickPath: `/${projectId}/documents`,
          hide: !projectId,
          subPageComponent: ProjectDocuments,
        },
        {
          name: "Members",
          relativePath: "/:id/members",
          clickPath: `/${projectId}/members`,
          hide: !projectId,
          subPageComponent: ProjectMembers,
        },
        {
          name: "Wall",
          relativePath: "/:id/wall",
          clickPath: `/${projectId}/wall`,
          hide: !projectId,
          subPageComponent: ProjectPosts,
        },
        {
          name: "Download Project",
          relativePath: `/download/:id`,
          subPageComponent: DownloadProject,
          hide: true,
        },
      ],
    };

    const template =
      ADMIN_ROLES.indexOf(this.props.authority) >= 0 ? (
        <PageTemplate
          baseUrl={this.props.baseUrl}
          menuItems={PROJECT_VIEW_MENU_ITEMS}
        />
      ) : (
        <PageTemplate
          baseUrl={this.props.baseUrl}
          menuItems={PROJECT_VIEW_MENU_ITEMS_CUSTOMER}
        />
      );
    return template;
  }
}

export default Projects;
