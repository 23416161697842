import Api from "@api";
import Constants from "@constants";
import Axios from "axios";

async function postFile(fileProcessingObject, sendUpdateEvent) {
  const { file } = fileProcessingObject;
  const instance = Api.AxiosInstance.getInstance();

  const fileRequest = {
    filename: file.name,
    filetype: file.type,
    filesize: file.size
  };

  const promiseRequest = new Promise((resolve, reject) => {
    instance
      .post(Constants.App.URL_FILE_UPLOAD, fileRequest)
      .then(response => {
        const { pathToUpload, id } = response.data;

        fileProcessingObject.fileuuid = id;
        sendUpdateEvent("fileuid", fileProcessingObject);

        Axios.put(pathToUpload, file, {
          onUploadProgress: progressEvent => {
            fileProcessingObject.uploadProgress =
              (progressEvent.loaded / file.size) * 100;

            sendUpdateEvent("progress", fileProcessingObject);
          },
          headers: {
            "Content-Type": file.type
          }
        })
          .then(data => {
            fileProcessingObject.uploadProgress = 100;
            fileProcessingObject.isUploading = false;
            sendUpdateEvent("complete", fileProcessingObject);

            resolve();
          })
          .catch(err => {
            fileProcessingObject.errorMsg =
              "Some of the file(s) could not be uploaded.";
              fileProcessingObject.isUploading = false;
            sendUpdateEvent("error", fileProcessingObject);
            reject();
          });
      })
      .catch(error => {
        fileProcessingObject.errorMsg =
          "Something went wrong while uploading the file. Please remove and attach again.";
        fileProcessingObject.isUploading = false;
        sendUpdateEvent("error", fileProcessingObject);
        reject();
      });
  });

  await promiseRequest;
}

class MultiUploader {

  constructor(callback) {
    this.callback = () => {};
    if ( callback ) {
      this.callback = callback;
    }
    this.filesProcessed = [];
  }

  isFileNotSame = (file1, file2) => {
    return file1.name !== file2.name ||
      file1.type !== file2.type ||
      file1.lastModified !== file2.lastModified;
  }

  isFileAlreadyProcessed = file => {
    const {filesProcessed, isFileNotSame} = this;
    return filesProcessed.filter(file1 => !isFileNotSame(file, file1.file)).length > 0;
  }

  filterFilesProcessed = filter => {
    this.filesProcessed = this.filesProcessed.filter(file => filter(file.file));
  }

  onChange = (event, eventData) => {
    const { value } = eventData;

    let filesPresentInThisIteration = [];

    if (value && value.length > 0) {
      value.map(file => {
        if (!file) return;
        filesPresentInThisIteration.push(file);
        if (!this.isFileAlreadyProcessed(file)) {
          let fileProcessingObject = {
            file,
            isUploading: false,
            uploadProgress: 0,
            fileuuid: false,
            errorMsg: false
          };
          //block further processing
          this.filesProcessed.push(fileProcessingObject);

          postFile(fileProcessingObject, this.callback);
        }
      });
    }

    const filesProcessedFiltered = this.filesProcessed.map((uploadedFile) => {

      const filePresentInThisIteration = filesPresentInThisIteration
        .filter((file) => !this.isFileNotSame(uploadedFile.file, file));

      if (filePresentInThisIteration.length === 1) {
        return uploadedFile;
      }

      this.callback("removed", uploadedFile);

      return null;
    });

    this.filesProcessed = filesProcessedFiltered.filter((file) => !!file);
  }
}

export default function(callback) {
  return new MultiUploader(callback);
}