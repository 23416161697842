import { combineReducers } from "redux";
import User from "./user";
import Common from "./common";
import tokenReducer from "./tokenReducer";
import userAdminReducer from "./admin/user";
import zendeskReducer from "./zendeskReducer";
import searchIndces from "./searchIndices/searchIndces";

export default combineReducers({
  user: User,
  common: Common,
  token: tokenReducer,
  Admin: userAdminReducer,
  zendeskReducer: zendeskReducer,
  searchIndcesReducer: searchIndces,
});
