import FileIcon from "@/common/file-icon";
import Tableview from "@/common/tableview";
import Api from "@api";
import Constants from "@constants";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import update from "immutability-helper";
import React from "react";
import Moment from "react-moment";
import { dateToPacific } from "@/utils/dateTime";
import { Link } from "react-router-dom";
import {
  Container,
  Popup,
  Button,
  Responsive,
  Grid,
  Icon,
  Modal,
  Header,
  Dropdown,
  GridRow,
  GridColumn,
} from "semantic-ui-react";
import { CardviewMobile } from "@/common/cardview";
import DownloadItem from "../components/download-items";
import ProductsContext from "../_context";
import DeleteItem from "./delete-items";
import LicensePreview from "../components/license-modal";
import moment from "moment";
import PDFviwer from "../../../../common/pdf-viewer";
class ListItems extends React.Component {
  staticValues = {
    itemColumns: [
      {
        name: "Name",
        key: "transformName",
        width: 3,
        showTooltip: true,
        cb: () => {
          this.sortByHeader("name", "Name");
        },
      },
      {
        name: "Category",
        key: "category",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("itemCategory.name", "Category");
        },
      },
      {
        name: "File Updated On",
        key: "fileModificationDate",
        isDate: true,
        showTooltip: true,
        cb: () => {
          this.sortByHeader("fileModificationDate", "File Updated On");
        },
      },
      {
        name: "Platform/OS",
        key: "platform",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("platform", "Platform/OS");
        },
      },
      { name: "Location", key: "location", showTooltip: true },

      {
        name: "Type",
        key: "icon",
        width: 2,
        class: "center",
      },
      { name: "Actions", key: "edit" },
    ],
    adminItemColumns: [
      {
        name: "Name",
        key: "transformName",
        width: 3,
        showTooltip: true,
        cb: () => {
          this.sortByHeader("name", "Name");
        },
      },
      {
        name: "Category",
        key: "category",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("itemCategory.name", "Category");
        },
      },
      {
        name: "file Updated On",
        key: "fileModificationDate",
        isDate: true,
        showTooltip: true,
        cb: () => {
          this.sortByHeader("fileModificationDate", "File Updated On");
        },
      },
      {
        name: "Platform/OS",
        key: "platform",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("platform.name", "Platform/OS");
        },
      },
      { name: "Location", key: "location", showTooltip: true },

      {
        name: "Type",
        key: "icon",
        width: 2,
        class: "center",
      },
      { name: "Actions", key: "edit" },
    ],
    responsive_itemColumns: [
      { name: "Name", key: "transformName", width: 3 },
      { name: "Category", key: "category" },
      { name: "Updated On", key: "modifiedDate", isDate: true },
      { name: "Platform/OS", key: "platform" },
      { name: "Location", key: "location" },
      { name: "Type", key: "icon", width: 2, class: "center" },
      { name: "Actions", key: "edit" },
    ],
    responsive_adminItemColumns: [
      { name: "Name", key: "transformName", width: 3 },
      { name: "Category", key: "category" },
      { name: "Updated On", key: "modifiedDate", isDate: true },
      { name: "Platform/OS", key: "platform" },
      { name: "Location", key: "location" },
      { name: "Type", key: "icon", width: 2, class: "center" },
      { name: "Actions", key: "edit" },
    ],

    searchType: [
      {
        text: "Search in current folder",
        key: "current_folder",
        value: "current_folder",
        showTooltip: true,
      },
      { text: "Search in all", key: "all", value: "ALL", showTooltip: true },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      items: {},
      page: 0,
      search: null,
      deleteItem: null,
      downloadItem: null,
      licenseLink: null,
      isModalOpen: false,
      isOpenlicenseModal: false,
      curlLink: null,
      wgetLink: null,
      setIsDownloading: false,
      downloadLink: null,
      directLink: null,
      friendlyLink: null,
      setProceedToDownload: false,
      disableCurl: false,
      disableDownload: false,
      disableWget: false,
      isCopied: false,
      searchType: "current_folder",
      isDescSearch: true,
      sortBy: "fileModificationDate,DESC",
      invalidFolderError: false,
    };
  }

  setLoading = (bool) => {
    if (this.props.loading) {
      this.props.loading(bool);
    }
  };

  deleteItem = (item) => {
    this.setState({ deleteItem: item });
  };

  cancelDelete = () => {
    this.setState({ deleteItem: null }, () =>
    this.loadProductItems(this.state.id)
    );
    window.location.reload()

  };

  downloadItem = (item) => {
    this.setState({ downloadItem: item });
  };

  cancelDownload = () => {
    this.setState({ downloadItem: null });
  };

  /********************************************************
   default case when no Id is provided in url.
   hack: using <id> of the first tree element from product:
   *******************************************************/
  setDefaultSelectedProduct() {
    this.props.dispatch(
      this.props.actions.defaultBrowser(
        parseInt(this.props.data.products[0].id),
        this.loadProductById(parseInt(this.props.data.products[0].id))
      )
    );
  }

  loadProductById(id) {
    if (this.props.data && this.props.data.rawProducts) {
      return this.props.data.rawProducts[id];
    }
  }

  getLicense = (item) => {
    this.setState({ item: item });
    if (item.licenses.length) {
      const { URL_ITEMS_ASSOCIATED_LICENSE_LINK } = Constants.App;
      const instance = Api.AxiosInstance.getInstance();
      const params = { params: { itemId: item.id }, timeout: 0 };
      instance
        .get(URL_ITEMS_ASSOCIATED_LICENSE_LINK, params)
        .then((response) => {
          if (response.data) {
            this.setState({ licenseLink: response.data });
            if (this.state.licenseLink) {
              this.setState({ isOpenlicenseModal: true });
            }
          }
          else{
            this.fetchToken(item);
            this.proceedToDownloadEvent(item);
            this.setState({ isModalOpen: true });
          }
        });
    } else {
      this.fetchToken(item);
      this.proceedToDownloadEvent(item);
      this.setState({ isModalOpen: true });
    }
  };

  proceedToDownloadEvent = (item, isAgreed) => {
    const instance = Api.AxiosInstance.getInstance();
    const { URL_ITEM_DOWNLOAD, URL_ITEMS_DETAILS } = Constants.App;
    if (item.friendlyName) {
      const {
        location: { host, protocol },
      } = window;
       // bug/GP3-138
      this.setState({
        friendlyLink: `${protocol}//${host}/#/downloads/view/${item.friendlyUrl}`,
      });
    } else {
      const paramsforLink = { params: { id: item.id } };
      instance.get(URL_ITEMS_DETAILS, paramsforLink).then((resp) => {
        this.setState({
          directLink: resp.data.directLink,
          friendlyLink: null,
        });
      });
    }

    const params = { params: { itemId: item.id, isAgreed }, timeout: 0 };
    instance.get(URL_ITEM_DOWNLOAD, params).then((resp) => {
      this.setState({
        setIsDownloading: false,
        downloadLink: resp.data,
      });
    });
    this.setState({ setIsDownloading: true, setProceedToDownload: true });
  };

  fetchToken = (item) => {
    const instance = Api.AxiosInstance.getInstance();
    instance.get("/items/userToken").then((resp) => {
      let command = `${Constants.App.API_URL}file/${encodeURI(item.filename)}/${
        item.id
      }/?token=${resp.data}`;
      let curl = `curl ${command} -o "${item.filename}" -D-`;
      let wget = `wget ${command} -O "${item.filename}"`;
      this.setState({ curlLink: curl, wgetLink: wget });
    });
  };

  copyToClipboard(url, type) {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    type === "link"
      ? this.setState({ disableDownload: true })
      : type === "curl"
      ? this.setState({ disableCurl: true })
      : this.setState({ disableWget: true });
    this.setState({ isCopied: true });
    setTimeout(() => {
      this.setState({
        isCopied: false,
        disableCurl: false,
        disableWget: false,
        disableDownload: false,
      });
    }, 500);
  }

  cancel = (type) => {
    this.setState({ isOpenlicenseModal: false });
    if (type === "agree") {
      this.fetchToken(this.state.item);
      this.proceedToDownloadEvent(this.state.item, 1);
      this.setState({ isModalOpen: true });
    }
  };

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;

    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query });
    this.loadProductItems(this.state.id);
    const isAdmin = this.props.data.isAdmin;
    let col = "";
    if (isAdmin) {
      col = "adminItemColumns";
    } else {
      col = "itemColumns";
    }

    this.staticValues[col] = this.staticValues[col].map((obj) => {
      if (obj.name == title) {
        return {
          ...obj,
          isDesc: isDesc,
          isActive: true,
        };
      } else
        return {
          ...obj,
          isActive: false,
        };
    });
    this.setState({ isDescSearch: !isDesc });
  };

  containsNumbers = (str) => {
    return str.match(/^[0-9]+$/);
  }

  /**
   * This method finds id of the product using slug and sets it in state.
   * @param {*} slug The slug reprsenting product path
   */
  fetchIdUsingSlug = (slug) => {

    const instance = Api.AxiosInstance.getInstance();
    const params = { params: { slug: slug }, timeout: 0 };
    const { URL_PRODUCTS_SEARCH } = Constants.App;
    instance
      .get(URL_PRODUCTS_SEARCH, params)
      .then((response) => {
        if (response.data) {
          this.setState({id: response.data.id});
        }
        else{
          this.setLoading(false);
          this.setState({invalidFolderError: true});
        }
      }).catch((err) => {
        this.setLoading(false);
        this.setState({invalidFolderError: true});
      });
    
  }
  
  loadProductItems = (id) => {
    /** case: when id is null */
    if (!id) {
      /**************************************
       hack: let's assign id of the first product of the tree
       ***************************************/
      id =
        this.props.data &&
        this.props.data.products &&
        this.props.data.products[0] &&
        this.props.data.products[0].id;
      /*******************************
      if id is still NULL eg: no product is assigned to user.
      *******************************************/
      if (!id) {
        // no need to fetch
        return;
      }
      // now set our context state values:
      this.setDefaultSelectedProduct(id);
    }
    this.props.dispatch({ type: "loading-list" });
    

    const isAdmin = this.props.data.isAdmin;
    let path = window.location.hash;
  
    path = path.replace("#/downloads/browser", "");
    path = path.replace(/^\/+|\/+$/g, ''); // The regular expression matches slashes at start and end
    let checkId = path.split("/");
    let slug ;
    if(!path){
      id = 1;
    }
    else if(this.containsNumbers(checkId[0]) == null){
      id = null;
      slug = path;
    }
    if(!slug) {
      this.setState({ id: id });
    }
    else{
      this.fetchIdUsingSlug(slug);
    }
    
    if (!this.state.invalidFolderError){
    
      setTimeout(() => {
        const instance = Api.AxiosInstance.getInstance();
        const options = {
          params: {
            parent_id: id,
            page: this.state.page,
            search: this.state.search,
            searchType: this.state.searchType,
            sort: this.state.sortBy,
            slug,
          },
        };
        
        instance
          .get(Constants.App.URL_PRODUCTS_ITEMS_LIST, options)
          .then((resp) => {
            this.setLoading(false);
            let data = resp.data;
            const values =
              data &&
              data.content &&
              resp.data.content.map((obj) => {
                obj.category = this.props.config.item_categories[obj.category];
                obj.platform = this.props.config.platforms[obj.platform];
                obj.icon = (
                  <React.Fragment>
                    <div
                      draggable="true"
                      onDragStart={(e) => this.drag(e, obj)}
                      style={{ cursor: isAdmin ? "grab" : "" }}
                    >
                      <FileIcon
                        filename={obj.filename ? obj.filename : "other"}
                        mimetype={obj.itemType}
                        size="large"
                      ></FileIcon>
                    </div>
                  </React.Fragment>
                );
                // dropping description column and concatenating with name field. temporary fix.
                // we need to increase the width of the table.
                obj.transformName = (
                  <div
                    style={{
                      position: "relative",

                      cursor: "pointer",
                    }}
                    onClick={() => this.getLicense(obj)}
                  >
                    <span style={{ position: "absolute", left: "-10px" }}>
                      {" "}
                      {obj.licenses.length > 0 ? "*" : ""}
                    </span>{" "}
                    {obj.name}
                    {obj.description ? (
                      <React.Fragment>
                        <br />
                        <small>({obj.description})</small>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                );
                obj.modifiedDate = (
                  <>{dateToPacific(obj.modifiedDate, "MM/DD/YYYY")}</>
                );
                obj.fileModificationDate = (
                                <>{dateToPacific(obj.fileModificationDate, "MM/DD/YYYY")}</>
                              );
                obj.location = obj.location ? <>{obj.location}</> : "-";
                if (isAdmin) {
                  const isDeletable = parseInt(obj.parent_id) === parseInt(id);

                  const deleteButton = (
                    <Link
                      to={this.props.data.baseUrl + "/" + id}
                      className={isDeletable ? "" : "disabled-link"}
                      onClick={() => (isDeletable ? this.deleteItem(obj) : null)}
                    >
                      <span className="icon-delete" title="Delete" alt="" />
                    </Link>
                  );
                  obj.edit = (
                    <React.Fragment>
                      <Link
                        to={
                          this.props.data.baseUrl +
                          "/" +
                          obj.parent_id +
                          "/items/" +
                          obj.id +
                          "/edit"
                        }
                      >
                        <span className="icon-edit" title="Edit" alt="" />
                      </Link>
                      <div
                        style={{ position: "relative;", display: "inline-block" }}
                      >
                        <Popup
                          disabled={isDeletable}
                          content="This item is associated with this folder. Please delete this item from its parent folder."
                          trigger={deleteButton}
                          position="left center"
                          pinned={false}
                        ></Popup>
                      </div>
                      <Link to="#" onClick={() => this.getLicense(obj)}>
                        <span className="icon-download" title="Download" />
                      </Link>
                      {/* <Link
                        to={this.props.data.baseUrl + "/" + id}
                        onClick={() => this.downloadItem(obj)}>
                        <span title="Download" className="icon-download"></span>
                      </Link> */}
                      {!isDeletable && (
                        <Link
                          to={this.props.data.baseUrl + "/" + obj.parent_id}
                          className="link-to-parent"
                        >
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            title="Go to Parent Folder!"
                          />
                        </Link>
                      )}
                    </React.Fragment>
                  );
                } else {
                  const todayDate = dateToPacific(new Date(), "MM/DD/YYYY");
                  const isAvailable =
                    obj.publishEndDate === null
                      ? true
                      : moment(todayDate).isSameOrBefore(obj.publishEndDate);
                  obj.edit = (
                    <React.Fragment>
                      {/*  <Link
                        to={this.props.data.baseUrl + "/" + id}
                        onClick={() => this.downloadItem(obj)}>
                        <span className="icon-download" title="Download" />
                    </Link>  */}
                      {obj.status == "DISABLED" ? (
                        <span>Disabled</span>
                      ) : isAvailable ? (
                        <Link to="#" onClick={() => this.getLicense(obj)}>
                          <span className="icon-download" title="Download" />
                        </Link>
                      ) : (
                        <span>Expired</span>
                      )}
                    </React.Fragment>
                  );
                }
                return obj;
              });
            data.content = values;
            this.props.dispatch({ type: "update-list", details: data });
          });
      });
    }

  };

  drag = (event, data) => {
    event.dataTransfer.setData("item", JSON.stringify(data));
  };
  onSearchClicked = (event) => {
    event.preventDefault();
    // always fetch the first page while searching.
    // You never know if it has got more than one page data or not.
    this.onPageChange({ page: 0 });
  };

  onSearchTextChange = (data) => {
    const { search } = this.state;
    this.setState({
      search: update(search, { $set: data.target.value }),
    });
  };

  sortChange = (rr) => {
  };

  componentDidMount() {
    this.loadProductItemsData();
  }

  loadProductItemsData = () => {
    const { id } = this.props.match.params;
    this.loadProductItems(id);
  };

  onPageChange = (event) => {
    this.setState({ page: event.page }, () =>
      this.loadProductItems(this.state.id)
    );
  };
  onDropDownChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { deleteItem, downloadItem, searchType, friendlyLink, directLink } =
      this.state;

    if (deleteItem && deleteItem.id && deleteItem.name) {

      return <DeleteItem item={deleteItem} cancel={this.cancelDelete}/>;
    }

    if (downloadItem && downloadItem.id && downloadItem.name) {
      return (
        <DownloadItem
          item={downloadItem}
          cancel={this.cancelDownload}
          directDownloadLink={downloadItem.directDownloadLink}
          isLicenseAttached={downloadItem.licenses.length > 0 ? true : false}
        />
      );
    }

    const isAdmin = this.props.data.isAdmin;
    const { breadcrumbs } = this.props.data;
    return (
      <div>
        <form className="ui form product-search-bar common-filter-wrapper">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8} widescreen={8}>
                <div class="field">
                  <div class="ui input">
                    <input
                      placeholder="Search"
                      className="product-search-bar"
                      onChange={this.onSearchTextChange}
                    ></input>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4} widescreen={4}>
                <div class="field">
                  <div class="ui input">
                    <Dropdown
                      options={this.staticValues.searchType}
                      selection
                      value={searchType}
                      placeholder="Search Type"
                      onChange={(event, data) =>
                        this.onDropDownChange("searchType", data.value)
                      }
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4} widescreen={4}>
                <div class="field">
                  <div class="ui input">
                    <button
                      className="button-primary tiny"
                      onClick={this.onSearchClicked}
                    >
                      {" "}
                      Search
                    </button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </form>
        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination">
            <Tableview
              data={this.props.data.details}
              paginate
              columns={
                isAdmin
                  ? this.staticValues.adminItemColumns
                  : this.staticValues.itemColumns
              }
              onPaginationChange={this.onPageChange}
              onSortingChange={this.sortChange}
              title=""
              noDataMessage="No items in this product."
            />
          </div>
        </Responsive>
        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={this.props.data.details}
            columns={
              isAdmin
                ? this.staticValues.responsive_adminItemColumns
                : this.staticValues.responsive_itemColumns
            }
            paginate
            onPaginationChange={this.onPageChange}
          />
        </Responsive>
        <p style={{ float: "right" }}>
          {" "}
          <em>
            <small>* Item is License Protected</small>{" "}
          </em>
        </p>
        <Modal
          open={this.state.isModalOpen}
          onClose={() =>
            this.setState({
              isModalOpen: false,
              disableWget: false,
              disableDownload: false,
              disableCurl: false,
              isCopied: false,
            })
          }
          closeIcon
          size="small"
        >
          <Modal.Header>
            <div
              className="breadcrumbs"
              dangerouslySetInnerHTML={{ __html: breadcrumbs }}
            ></div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description className="word-wrap">
              <Grid>
                <GridRow className="briefcase-download-popup-list">
                  <GridColumn
                    mobile={16}
                    tablet={16}
                    computer={16}
                    largeScreen={16}
                    widescreen={16}
                    verticalAlign="middle"
                  >
                    <Header className="mb-05-im">
                      <small>{this.state.item?.name}</small>{" "}
                    </Header>
                    <p>
                      {this.state.item?.filename}
                      <br></br>
                      {this.state.item?.description}
                    </p>
                  </GridColumn>
                  <GridColumn
                    mobile={16}
                    tablet={16}
                    computer={16}
                    largeScreen={16}
                    widescreen={16}
                  >
                    <div className="download-action">
                      <PDFviwer
                        filename={this.state.item?.filename}
                        itemType={this.state.item?.itemType}
                        url={this.state.downloadLink}
                      ></PDFviwer>
                      <a
                        href={this.state.downloadLink}
                        target="_blank"
                        title="Download now"
                      >
                        <Button className="button-basic mini">
                          <span class="icon-download" title="Download">
                            {" "}
                          </span>
                        </Button>
                      </a>
                      <a
                        title="Copy download link to clipboard"
                        onClick={() =>
                          this.copyToClipboard(
                            friendlyLink ? friendlyLink : directLink,
                            "link"
                          )
                        }
                      >
                        <Button
                          className="button-basic mini"
                          disabled={this.state.disableDownload}
                        >
                          <span
                            class="icon-external-link2"
                            title="Download Link"
                          >
                            {" "}
                          </span>
                        </Button>
                      </a>
                      <a
                        title="Copy cURL command for CLI to clipboard"
                        onClick={() =>
                          this.copyToClipboard(this.state.curlLink, "curl")
                        }
                      >
                        <Button
                          className="button-basic mini"
                          disabled={this.state.disableCurl}
                        >
                          cURL
                        </Button>
                      </a>
                      <a
                        title="Copy Wget command for CLI to clipboard"
                        onClick={() =>
                          this.copyToClipboard(this.state.wgetLink, "wget")
                        }
                      >
                        <Button
                          className="button-basic mini"
                          disabled={this.state.disableWget}
                        >
                          Wget
                        </Button>
                      </a>{" "}
                      {this.state.isCopied && (
                        <span className="copy-msg">Copied to clipboard</span>
                      )}
                    </div>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Modal.Description>
          </Modal.Content>
        </Modal>
        <React.Fragment>
          {this.state.isOpenlicenseModal && (
            <LicensePreview
              cancel={this.cancel}
              isLicenseAttached={this.state.isOpenlicenseModal}
              licenseLink={this.state.licenseLink}
            />
          )}
        </React.Fragment>
        <Modal
          open={this.state.invalidFolderError}
          onClose={() => {
            this.setState({invalidFolderError: false})
          }}
          centered={true}
          size="tiny"
          content={"Error! The folder name or id is either invalid or you donot have access to it."}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </div>
    );
  }
}

export default ProductsContext.Consumer(ListItems);
