import React from 'react';
import SLAPage from "../components/sla-page";


const CompletedSLA = () => {
  return(
    <SLAPage title="Completed SLAs" status="ACTIVE" />
  )
};

export default CompletedSLA;