import React from "react";
import axios from "axios";

import { Button, Icon } from "semantic-ui-react";

class PDFviwer extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  async viewDoc(url) {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open(fileURL);
      if (
        !pdfWindow ||
        pdfWindow.closed ||
        typeof pdfWindow.closed == "undefined"
      ) {
        console.log("Please allow browser-popup to preview pdf");
        alert("Please allow browser-popup to preview pdf");
      }
    } catch (error) {
      return { error };
    }
  }

  render() {
    const { url, filename, itemType, style } = this.props;
    let showOption = false;
    if (itemType) {
      if (itemType == "pdf") showOption = true;
    } else {
      if (filename) {
        const extension = filename.split(".").pop();
        if (extension == "pdf") showOption = true;
      }
    }
    return (
      showOption && (
        <a title="View" onClick={() => this.viewDoc(url)}>
          <Button className="button-basic mini">
            {" "}
            <span class="icon-search" title="View"></span>
          </Button>
        </a>
      )
    );
  }
}

export default PDFviwer;
