import React from "react";
import { Modal, Segment, Form, Grid, Divider, Button, Checkbox } from "semantic-ui-react";
import Api from "@/api";
import Constants from "@/config/constants";
import {
  InputField,
  TextareaField,
  CountryField,
  StateField,
  CountryCode,
  PhoneField,
} from "@/common/Form";
import validate from "validate.js";
import { isEmpty } from "lodash";
import { stringify } from "query-string";
import postalCodes from "postal-codes-js";
import update from "immutability-helper";
import { normalValidationConfigForSelfRegister } from "@/common/validations/userValidation";

class InitiateNDA extends React.Component {
  VALIDATION_CONFIG = {
    company: {
      presence: true,
    },
    address1: {
      presence: true,
    },
    country: {
      presence: true,
      exclusion: {
        within: ["null"],
        message: "Country can't be blank",
      },
    },
    city: {
      presence: true,
    },
    principleBusiness: {
      presence: true,
    },
    infoBeingDisclosed: {
      presence: false,
    },
    specificUsage: {
      presence: false,
    },
    ndaDuration: {
      presence: false,
      numericality: {
        onlyInteger: true,
        greaterThan: 0,
        lessThanOrEqualTo: 9999,
      },
    },
    initiatorName: {
      presence: true,
    },
    initiatorEmail: {
      presence: true,
      email: true,
    },
    initiatorPhone: {
      presence: true,
    },
    contactName: {
      presence: true,
    },
    contactEmail: {
      presence: true,
      email: true,
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isInitiated: false,
      isPublicAccessRequestInitiated: false,
      ndaLink: null,
      isSigned: false,
      showPopup: false,
      country: null,
      msg: "",
      showMsg: false,
      validationMsg: {},
      isError: false,
      local: false,
      initiatorCountryCode: "+1",
      localcountry: "US",
      localpostal: "",
      localresult: "",
      country: "US",
      differentSigner: false,
    };
  }

  componentDidMount() {
    this.load();
    console.log(this.props)
  }

  load = () => {
    this.setState({ loading: true });
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_IS_INITIATED_NDA)
      .then((resp) => {
        this.setState({
          isInitiated: resp.data.isInitiated,
          ndaLink: resp.data.file,
          isSigned: resp.data.isSigned,
          loading: false,
          status: resp.data.status,
          isPublicAccessRequestInitiated: resp.data.publicAccessRequestInitiated
        });
      })
      .catch((err) => this.setState({ loading: false }));
  };
  countryCodeChange = (event, data) => {
    this.setState({
      initiatorCountryCode: data.value,
    });
  };
  isNumeric(value) {
    return /^\d+$/.test(value);
  }
  onPhoneChange = (event, { name, value }) => {
    let cond = value;
    var phone = "";
    for (var i = 0; i < cond.length; i++) {
      if (this.isNumeric(cond.charAt(i))) {
        phone = phone + cond.charAt(i);
      }
    }
    if (phone.length < 3) {
      this.setState({
        [name]: phone,
      });
    } else if (
      phone.length == 3 &&
      cond.charAt(cond.length - 1) == ")" &&
      cond.length == 5
    ) {
      this.setState({
        [name]: phone.substring(0, 2),
      });
    } else if (phone.length == 3) {
      this.setState({
        [name]: "(" + phone.substring(0, 3) + ") ",
      });
    } else if (phone.length > 3 && phone.length < 6) {
      this.setState({
        [name]: "(" + phone.substring(0, 3) + ") " + phone.substring(3),
      });
    } else if (phone.length == 6 && this.state.local == false) {
      this.setState({
        [name]:
          "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " ",
      });
      this.setState({ local: true });
    } else if (phone.length == 6 && this.state.local == true) {
      this.setState({
        [name]: "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6),
      });
      this.setState({ local: false });
    } else if (phone.length >= 6 && phone.length < 12) {
      this.setState({
        [name]:
          "(" +
          phone.substring(0, 3) +
          ") " +
          phone.substring(3, 6) +
          " " +
          phone.substring(6),
      });
    } else if (phone.length >= 12) {
      this.setState({
        [name]:
          "(" +
          phone.substring(0, 3) +
          ") " +
          phone.substring(3, 6) +
          " " +
          phone.substring(6, 11),
      });
    }
  };
  checkPostalCode = (e) => {
    var res = postalCodes.validate(
      this.state.localcountry,
      this.state.localpostal
    );
    if (this.state.localpostal == "") {
      res = true;
    }
    this.state.localresult = res;
    const { validationMsg } = this.state;
    let value = res ? { postCode: res } : [];
    value.localresult = this.state.localresult;
    let obj = {
      postCode: normalValidationConfigForSelfRegister["postCode"],
    };
    const singleFieldValidation = validate(value, obj);
    if (isEmpty(singleFieldValidation)) {
      this.setState({
        validationMsg: update(validationMsg, {
          postCode: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          postCode: { $set: singleFieldValidation["postCode"] },
        }),
      });
    }
  };
  handleChange = (event, { name, value }) => {
    if (name == "country") {
      this.state.localcountry = value;
      this.setState({ [name]: value });
      this.checkPostalCode(event);
    } else if (name == "postCode") {
      this.state.localpostal = value;
      this.setState({ [name]: value });
      this.checkPostalCode(event);
    } else {
      this.setState({ [name]: value });
    }
  };

  handleCountryChange = (e) => {
    this.setState({
      country: e.target.value,
    });
  };

  openPopup = (e) => {
    e.preventDefault();
    this.setState({ showPopup: true ,msg:""});
  };
  openPublicMaterialPopup = (e) =>{
    e.preventDefault();
    this.setState({showPublicMaterialPopup: true});
  }

  closePopup = () => {
    this.setState({ showPopup: false, showMsg: false, msg: "" });
  };
  closePublicAccessPopup = () => {
    this.setState({ showPublicMaterialPopup: false});
  };
  closeErrorPopup = () => {
    this.setState({ showMsg: false, showPopup: false , showPublicMaterialPopup: false});
  };
  validateForm = (jsonObject) => {
    let result = validate(jsonObject, this.VALIDATION_CONFIG);
    // this.setState({ validationMsg: result || {} });
    console.log(result);
    // return isEmpty(result);
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => {
        // this.save();
      });
    } else {
      this.setState({
        validationMsg: result,
        loading: false,
        isError: true,
        msg: "Validation Issue. Please Fix before submission",
      });
    }
    return isEmpty(result);
  };
  handleRequestSubmit = (e) => {
    //api call to be integrated here
    Api.AxiosInstance.getInstance()
    .get("/admin/user/request-public-customer-access")
    .then((resp) => {
      this.load();
      this.setState({
        loading: false,
        showMsg: true,
        showError: true,
        msg: "Your request has been sent to Groq. Once an admin has approved your request, you will be able to access Groq public information",
        isError: false,
      });
    })
    .catch((error) => {
      this.setState({ msg: error, isError: true });
    });

  }
  handleSubmit = (event) => {
    event.preventDefault();
    var formData = new FormData(document.getElementById("nda-initiate-form"));
    formData.append("country", this.state.country);
    formData.append("state", this.state.state);
    let jsonObject = {};

    for (const [key, value] of formData.entries()) {
      if (value.length > 0) {
        if (key == "initiatorPhone") {
          var value2 = this.state.initiatorCountryCode + String(value);
          jsonObject[key] = value2;
        } else {
          jsonObject[key] = value;
        }
        console.log(key);
        console.log(jsonObject[key]);
      } else {
        jsonObject[key] = null;
      }
    }

    if(!this.state.differentSigner){
      // A different signer was not provided, so the initiator is the signer.
      jsonObject.contactName = this.state.initiatorName;
      jsonObject.contactEmail = this.state.initiatorEmail;
    }
    
    if (!this.validateForm(jsonObject)) return;

    Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_INITIATE_NDA, jsonObject)
      .then((resp) => {
        this.load();
        this.setState({
          loading: false,
          showMsg: true,
          showError: true,
          msg: "You or the designated signing authority at your company will be notified via email to sign an NDA.",
          isError: false,
        });
      })
      .catch((error) => {
        this.setState({ msg: error, isError: true });
      });
  };

  getNDAInitiateForm = () => {
    const { validationMsg, loading, showMsg, msg } = this.state;
    return (
      <Segment className="base-segment-wrapper">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12}>
              <Form id="nda-initiate-form" loading={loading}>
                <Divider horizontal className="font-weight-light">
                  Initiator Information
                </Divider>
                <InputField
                  fluid
                  isRequired
                  label="Initiator Name"
                  name="initiatorName"
                  errorMsg={validationMsg.initiatorName}
                  isError={validationMsg.initiatorName}
                  onChange={this.handleChange}
                  width="16"
                />
                <InputField
                  fluid
                  isRequired
                  label="Initiator Email"
                  name="initiatorEmail"
                  type="email"
                  errorMsg={validationMsg.initiatorEmail}
                  isError={validationMsg.initiatorEmail}
                  onChange={this.handleChange}
                  width="16"
                />
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          mobile={16}
                          tablet={5}
                          computer={5}
                          className="col-country-code"
                        >
                          <CountryCode
                            label="Country Code"
                            errorMsg={validationMsg.code}
                            isError={!!validationMsg.code}
                            name="code"
                            onChange={this.countryCodeChange}
                            value={this.state.initiatorCountryCode}
                            width={16}
                            isRequired
                          />{" "}
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={11}
                          computer={11}
                          className="col-mobile-phone"
                        >
                          <PhoneField
                            label="Initiator Phone"
                            errorMsg={validationMsg.initiatorPhone}
                            isError={validationMsg.initiatorPhone}
                            name="initiatorPhone"
                            onChange={this.onPhoneChange}
                            value={this.state.initiatorPhone}
                            width={16}
                            onBlur={this.onBlur}
                            country={this.state.countryCode}
                            isRequired
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>

                <Divider horizontal className="font-weight-light">
                  Company Information
                </Divider>
                <InputField
                  fluid
                  isRequired
                  label="Company"
                  name="company"
                  errorMsg={validationMsg.company}
                  isError={validationMsg.company}
                  onChange={this.handleChange}
                  width="16"
                />
                <InputField
                  fluid
                  isRequired
                  label="Address"
                  name="address1"
                  errorMsg={validationMsg.address1}
                  isError={validationMsg.address1}
                  onChange={this.handleChange}
                  width="16"
                />
                <CountryField
                  label="Country"
                  errorMsg={validationMsg.country}
                  isError={validationMsg.country}
                  onChange={this.handleChange}
                  name="country"
                  width="16"
                  isRequired
                  value={this.state.country}
                />
                <StateField
                  label="State"
                  country={this.state.country}
                  errorMsg={validationMsg.state}
                  isError={validationMsg.state}
                  onChange={this.handleChange}
                  name="state"
                  isRequired
                  value={this.state.state}
                  width="16"
                />
                <InputField
                  fluid
                  isRequired
                  label="City"
                  name="city"
                  errorMsg={validationMsg.city}
                  isError={validationMsg.city}
                  onChange={this.handleChange}
                  width="16"
                />
                <InputField
                  label="Post/Zip Code"
                  width="16"
                  errorMsg={validationMsg.postCode}
                  isError={validationMsg.postCode}
                  name="postCode"
                  onChange={this.handleChange}
                  maxlength="10"
                />
                <InputField
                  fluid
                  isRequired
                  label="Principle Business"
                  name="principleBusiness"
                  errorMsg={validationMsg.principleBusiness}
                  isError={validationMsg.principleBusiness}
                  onChange={this.handleChange}
                  width="16"
                />
                {/* <Divider horizontal className="font-weight-light">
                  NDA Information
                </Divider>
                <InputField
                  fluid
                  isRequired
                  label="Info Being Disclosed"
                  name="infoBeingDisclosed"
                  errorMsg={validationMsg.infoBeingDisclosed}
                  isError={validationMsg.infoBeingDisclosed}
                  onChange={this.handleChange}
                  width="12"
                />
                <InputField
                  fluid
                  isRequired
                  label="Specific Usage"
                  name="specificUsage"
                  errorMsg={validationMsg.specificUsage}
                  isError={validationMsg.specificUsage}
                  onChange={this.handleChange}
                  width="12"
                />
                <InputField
                  fluid
                  isRequired
                  label="NDA Duration"
                  name="ndaDuration"
                  placeholder="In years (eg: 1)"
                  errorMsg={validationMsg.ndaDuration}
                  isError={validationMsg.ndaDuration}
                  onChange={this.handleChange}
                  width="12"
                  onKeyPress={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                /> */}
                <Checkbox checked={this.state.differentSigner} 
                  label="I am not a signing authority for my company"
                  onChange={
                    () => {
                      this.setState({differentSigner: !this.state.differentSigner})
                      }}
                />
                { this.state.differentSigner &&
                  <>
                    <Divider horizontal className="font-weight-light">
                    YOUR COMPANY'S SIGNING AUTHORITY
                    </Divider>
                    <InputField
                      fluid
                      isRequired
                      label="Contact Name"
                      name="contactName"
                      errorMsg={this.state.differentSigner && validationMsg.contactName}
                      isError={this.state.differentSigner && validationMsg.contactName}
                      onChange={this.handleChange}
                      width="16"
                    />
                    <InputField
                      fluid
                      isRequired
                      label="Contact Email"
                      name="contactEmail"
                      errorMsg={this.state.differentSigner && validationMsg.contactEmail}
                      isError={this.state.differentSigner && validationMsg.contactEmail}
                      onChange={this.handleChange}
                      width="16"
                    />
                  </>
                }
                <br />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  render() {
    const {
      isInitiated,
      isSigned,
      ndaLink,
      showPopup,
      isError,
      showMsg,
      msg,
      status,
      showPublicMaterialPopup,
      isPublicAccessRequestInitiated
    } = this.state;
    const { authority } = this.props;
    console.log(this.state);
    return (
      <>
      <Segment className="dashboard-card">
        <div className="side-marker" />
        <div className="header">Start Standard NDA</div> 
        <br />
        {!isInitiated && (
          <div>
            <a href="#" onClick={this.openPopup}>
              Initiate NDA
            </a>
          </div>
        )}
        {isInitiated && !isSigned && (
          <span>
            <i>
              An NDA is in processing. Once the NDA is complete, your account
              will be active and you can retrieve Groq secure information
            </i>
          </span>
        )}
        {isInitiated && isSigned && (
          <div>
            <i>
              NDA has been signed by your company and is currently PENDING countersignature from Groq’s signing authority. For more information, please contact us <a href='mailto:portal_admin@groq.com'>here</a>
            </i>
          </div>
        )}
        <Modal size="samll" open={showPublicMaterialPopup} className="initiate-form-popup">
        <Modal.Header>Request for Access</Modal.Header>
        <Modal.Content>
        <p>By clicking the submit button, your request to access secure public materials from the Groq customer portal will be sent to the site administrator. This approval and setup is typically completed within one business day. </p>
              
        </Modal.Content>
          <Modal.Actions>
          <div className="mb-1-im">
              <button
                onClick={(e) => {
                  this.handleRequestSubmit(e);
                }}
                className="button-primary"
              >
                Submit
              </button>
              <button className="button-basic" onClick={this.closePublicAccessPopup}>
                Discard
              </button>
            </div>
          </Modal.Actions>

        </Modal>
        <Modal size="small" open={showPopup} className="initiate-form-popup">
          <Modal.Header>NDA Form</Modal.Header>
          <Modal.Content scrolling>{this.getNDAInitiateForm()}</Modal.Content>
          <Modal.Actions>
            <div className="mb-1-im m--align-center ">
              {msg && (
                <span style={{ color: isError ? "red" : "green" }}>{msg}</span>
              )}
            </div>
            <div className="mb-1-im">
              <button
                onClick={(e) => {
                  this.handleSubmit(e);
                }}
                className="button-primary"
              >
                Submit
              </button>
              <button className="button-basic" onClick={this.closePopup}>
                Discard
              </button>
            </div>

          </Modal.Actions>
        </Modal>
        <Modal
          open={showMsg}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={msg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </Segment>
      { authority == "websilo-non-nda-customer" &&
      <Segment className="dashboard-card">
        <div className="side-marker" />
        <div className="header">Request Public Material Access</div> 
        <br />
        {!isPublicAccessRequestInitiated && (
          <div>
            <a href="#" onClick={this.openPublicMaterialPopup}>
              Grant me public material access
            </a>
          </div>
        )}

        {!!isPublicAccessRequestInitiated && authority=="websilo-non-nda-customer" && (
          <div>
            <i>
              Request already sent, your access request is pending {" "}
            </i>
          </div>
        )}
      </Segment>}
      </>
    );
  }
}

export default InitiateNDA;
