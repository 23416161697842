import React from "react";
import { get, isEmpty } from "lodash";
import {
  Button,
  Icon,
  Modal,
  Segment,
  Confirm,
  Grid,
  Responsive,
} from "semantic-ui-react";
import update from "immutability-helper";
import Constants from "@/config/constants";
import Moment from "react-moment";
import Tableview from "@/common/tableview";
import urls from "@/config/constants";
import Api from "@/api";
import UserListing from "@/common/user-selection/user-listing";
import { CardviewMobile } from "@/common/cardview";
import { dateToPacific } from "@/utils/dateTime";
class PackageUsers extends React.Component {
  Constants = {
    USER_COLUMNS: Object.freeze([
      { name: "Username", key: "username", showTooltip: true, cb: () => {
        this.sortByHeader("username", "Username");
      }, },
      { name: "Title", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Title");
      }, },
      { name: "Company", key: "company", showTooltip: true, cb: () => {
        this.sortByHeader("company", "Company");
      }, },
      { name: "Assigned By", key: "createdBy", showTooltip: true, cb: () => {
        this.sortByHeader("createdBy", "Assigned By");
      }, },
      { name: "Assigned On", key: "creationDate", showTooltip: true, cb: () => {
        this.sortByHeader("creationDate", "Assigned On");
      }, },
    ]),
    RESPONSIVE_USER_COLUMNS: Object.freeze([
      { name: "Username", key: "username" },
      { name: "Title", key: "name" },
      { name: "Company", key: "company" },
      { name: "Assigned By", key: "createdBy" },
      { name: "Assigned On", key: "creationDate" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedPackages: { content: [] },
      assignedUsers: { content: [] },
      errorMsg: null,
      showError: false,
      selectedUsers: [],
      showUserListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
      data: {},
      redirectTo: false,
      packageId: props.match.params.packageId,
      userId: null,
      pageNum: 0,
      isDescSearch: true,
      sortBy: "",
      direction:"DESC",
      selectedStatedUsers: [],
      selectedUsersIndexes: [],
      checkedUserIds: [],
    };
  }

  componentDidMount() {
    this.fetchAssignedUsers();
  }

  transformData = (data) => {
    let transformedData = Object.assign({}, data.content);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.creationDate = (
          <React.Fragment>
            {
              dateToPacific(
                row.creationDate,
                "MM/DD/YYYY hh:mm a"
              )
            }
          </React.Fragment>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };
  showDeleteWarningAndSetId = (e, id) => {
    this.setState({
      deleteWarning: true,
      userId: id,
    });
  };

  sortByHeader = (key, title) => {
    let sort = `${key}`;
    let dir = `DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      sort = `${key}`;
      dir = `ASC`;
    }
    this.setState({ sortBy: sort, direction:dir }, () => {
      this.Constants.USER_COLUMNS = this.Constants.USER_COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchAssignedUsers();
    });
   
  };

  fetchAssignedUsers = () => {
    const { packageId, assignedUsers } = this.state;
    if (packageId > 0) {
      // Need to add this constants file
      const { URL_PACKAGE_DETAILS } = Constants.App;
      const params = {
        params: { id: this.state.packageId, page: this.state.pageNum,
        target:"users",sort:this.state.sortBy,direction:this.state.direction },
      };
      const instance = Api.AxiosInstance.getInstance();
      instance.get(URL_PACKAGE_DETAILS, params).then((resp) => {
        const transformedData = this.transformData({
          content: resp.data.pkgUsers,
        });

        this.setState({
          assignedUsers: transformedData,
          isLoaded: true,
        });
      });
    }
  };
  onAssign = () => {
    this.setState({ showUserListing: true });
  };

  doAssign = (data) => {
    this.setState(
      {
        showUserListing: false,
        selectedUsers: data,
        msg: "Something went wrong while assigning new package(s).",
        action: "ADD_USER",
        selectedStatedUsers: [],
        selectedUsersIndexes: [],
        checkedUserIds: [],
      },
      () => {
        this.updatePackages();
      }
    );
  };

  onUnassign = () => {
    const { selectedUsers } = this.state;

    if (isEmpty(selectedUsers)) {
      this.setState({
        showError: true,
        errorMsg:
          "No package selected for deletion. Please select one or more packages.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "DELETE_USER",
        "Something went wrong while unassigning the package(s)."
      );
    }
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  updatePackages = () => {
    const packageId = get(this.props, ["match", "params", "packageId"]);
    const { selectedUsers, action, msg } = this.state;

    const params = { params: { id: packageId } };
    let userIds = [];
    selectedUsers.map((user) => userIds.push({ id: user.id }));
    let url = Constants.App.URL_PACKAGE_DETAILS;
    let payload = { action: action, users: userIds };
    Api.AxiosInstance.getInstance()
      .post(url, payload, params)
      .then((resp) => {
        this.fetchAssignedUsers();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: msg,
        });
      });
  };

  onUserSelection = (e) => {
    // this.setState({ selectedUsers: e.data });
    let selectedStatedUsers = this.state.selectedStatedUsers;
    let ids = this.state.checkedUserIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedUsers.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedUsers.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedUsers = selectedStatedUsers.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedUsers.map((s) => s.id);
    }
    this.setState({ checkedUserIds: ids });

    this.setState({
      selectedStatedUsers: selectedStatedUsers,
      selectedUsers: selectedStatedUsers,
    });
  };

  onAllItemSelection = (selectedUsers, flag) => {
  let {selectedStatedUsers} = this.state;
  let ids=[];
  if(flag){
    selectedUsers.map((user)=>{
    selectedStatedUsers = selectedStatedUsers.filter((entity) => {
        return entity.id !== user.id;
      })
    });
  }else{
    selectedUsers.map((user)=>{
        if (user) {
          const alreadyPushed = selectedStatedUsers.find(
            (element) => element.id == user.id
          );
          if (!alreadyPushed) {
            selectedStatedUsers.push(user);
          } }
        } );
  }
  ids = selectedStatedUsers.map((s) => s.id);
  this.setState({
         selectedStatedUsers: selectedStatedUsers,
         selectedUsers: selectedStatedUsers,
         checkedUserIds: ids,
     });
}

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closeUserListing = () => {
    this.setState({ showUserListing: false });
  };

  onPageChange = (e) => {
    this.setState({ pageNum: e.page }, () => this.fetchAssignedUsers());
  };

  render() {
    const { USER_COLUMNS, RESPONSIVE_USER_COLUMNS } = this.Constants;
    const {
      assignedPackages,
      assignedUsers,
      errorMsg,
      showError,
      showUserListing,
      selectedStatedUsers,
      selectedUsersIndexes,
      checkedUserIds,
    } = this.state;

    return (
      <React.Fragment>
        <Segment basic>
          <div className="control-action-wrapper mb-1">
            <Button className="button-basic mini" onClick={this.onAssign}>
              <Icon name="plus" />
              Assign New Users
            </Button>
            <Button
              color="red"
              className="button-basic mini mr-0-im"
              onClick={this.onUnassign}
            >
              <Icon name="remove" />
              Unassign
            </Button>
          </div>
        </Segment>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Responsive minWidth={1024}>
              <div className="table-wrapper-with-pagination">
                <Tableview
                  //paginate
                  columns={USER_COLUMNS}
                  data={assignedUsers}
                  onSelectionChange={this.onUserSelection}
                  noDataMessage="No Users found."
                  selectionType="multi-select"
                  checkedIndexes={selectedUsersIndexes}
                  checkedIds={checkedUserIds}
                  onAllSelectionChange={this.onAllItemSelection}
                  higePagination
                  //onPaginationChange={this.onPageChange}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={1023}>
              <CardviewMobile
                data={assignedUsers}
                columns={RESPONSIVE_USER_COLUMNS}
                onSelectionChange={this.onUserSelection}
                noDataMessage="No Users found."
                selectionType="multi-select"
                checkedIndexes={selectedUsersIndexes}
                checkedIds={checkedUserIds}
                onAllSelectionChange={this.onAllItemSelection}
                higePagination
              />
            </Responsive>
          </Grid.Column>
        </Grid.Row>
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <UserListing
          show={showUserListing}
          onDismiss={this.closeUserListing}
          onSave={this.doAssign}
          entity="PackageUsers"
          entityId={this.state.packageId}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updatePackages())}
          content={`Are you sure you want to delete this user package(s)?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageUsers;
