import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab } from "semantic-ui-react";
import CorporateSiteData from "./corporate-site";
import Projects from "./projects";
import Videos from "./videos";
import Tickets from "./tickets";
import Articles from "./articles";
import All from "./responses";
import Downloads from "./downloads";
import Actions from "../../../store/actions";
import { isEmpty } from "lodash";

const Tabs = (props) => {
  const dispatch = useDispatch();
  const supportEnabled = useSelector(
    (state) => state.common.config.supportEnabled
  );
  const userRoles = useSelector(
    (state) => state.user.loggedin.user.authorities
  );

  const showHideMainLoader = (state) => {
    dispatch({
      type: Actions.Config.SHOW_HIDE_LOADER,
      payload: state
    });
  }

  const showHideSupportErrorDialog = (state, errMsg) => {
    dispatch({
      type: Actions.Config.SHOW_HIDE_ERROR_DIALOG,
      payload: { state, errMsg }
    });
  }

  const all = [
    {
      menuItem: "ALL",
      key: "all",
      render: () => (
        <Tab.Pane>
          <All {...props}  showHideMainLoader={showHideMainLoader} showHideSupportErrorDialog={showHideSupportErrorDialog}/>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Downloads",
      key: "downloads",
      render: () => (
        <Tab.Pane>
          <Downloads {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Projects",
      key: "projects",
      render: () => (
        <Tab.Pane>
          <Projects {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Videos",
      key: "videos",
      render: () => (
        <Tab.Pane>
          <Videos {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Corporate Site",
      key: "corporate",
      render: () => (
        <Tab.Pane>
          <CorporateSiteData {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Support Cases",
      key: "tickets",
      render: () => (
        <Tab.Pane>
          <Tickets {...props}  showHideMainLoader={showHideMainLoader} showHideSupportErrorDialog={showHideSupportErrorDialog}/>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Support Articles",
      key: "articles",
      render: () => (
        <Tab.Pane>
          <Articles {...props} showHideMainLoader={showHideMainLoader} showHideSupportErrorDialog={showHideSupportErrorDialog}/>
        </Tab.Pane>
      ),
    },
  ];
  const supportEnabledTabs = [
    ...all.filter((data) => ["articles", "tickets"].indexOf(data.key) == -1),
  ];
  const nonNDATabs = [...all.filter((data) => data.key == "corporate")];
  if (!isEmpty(userRoles) && userRoles.find((d) => d.authority == "websilo-non-nda-customer")) {
    return <Tab panes={nonNDATabs} />;
  } else if (supportEnabled) {
    return <Tab panes={all} />;
  } else {
    return <Tab panes={supportEnabledTabs} />;
  }
};

export default Tabs;
