import Api from "@/api";
import { CardviewMobile } from "@/common/cardview";
import { authoritySelector } from "@/common/selectors";
import Tableview from "@/common/tableview";
import { default as Config, default as Constants } from "@/config/constants";
import React from "react";
// import Edit from "./edit";
// import Create from "./create";
import "react-datepicker/dist/react-datepicker.css";
import { dateToPacific } from "@/utils/dateTime";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import VideoPlayer from "react-video-js-player";
import {
  Button,
  Confirm,
  Divider,
  Grid,
  Header,
  Icon,
  Modal,
  Responsive,
  Segment,
  TransitionablePortal,
} from "semantic-ui-react";

const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;
class VideosList extends React.Component {
  Constants = {
    SEARCH_ON_LICENSE_TYPE: Object.freeze([
      { key: "all-search", text: "All", value: "" },
      { key: "item-license", text: "Item License", value: "ITEM_LICENSE" },
      { key: "site-license", text: "Site License", value: "SITE_LICENSE" },
    ]),
    SEARCH_ON_LICENSE_STATUS: Object.freeze([
      { key: "all-items", text: "All", value: "" },
      { key: "item-active", text: "Active", value: "ACTIVE" },
      { key: "item-disabled", text: "Disabled", value: "DISABLED" },
    ]),
  };
  constructor(props) {
    super(props);

    this.state = {
      licenseType: "",
      licenseStatus: "",
      selectedVideoName: "",
      selectedVideoLink: "",
      selectedItem: [],
      deleteWarning: false,
      date_format: "YYYY-MM-DD hh:mm A",
      createMode: false,
      editMode: false,
      editId: null,
      columns: [
        { name: "Video Name", key: "name", width: "2", showTooltip: true,
        cb: () => {
          this.sortByHeader("name", "Video Name");
        },
       },
        {
          name: "Description",
          key: "description",
          width: "2",
          showTooltip: true,
          // cb: () => {
          //   this.sortByHeader("description", "Description");
          // },
        },
        { name: "Tags", key: "tags", width: "3", showTooltip: true, 
        cb: () => {
          this.sortByHeader("tags", "Tags");
        },  },
        // { name: "Status", key: "status", width: "1" },
        {
          name: "Updated On",
          key: "modifiedDate",
          width: "2",
          showTooltip: true,
          cb: () => {
            this.sortByHeader("modificationDate", "Updated On");
          },
        },
        {
          name: "Updated By",
          key: "modifiedBy",
          width: "2",
          showTooltip: true,
          cb: () => {
            this.sortByHeader("modifiedBy", "Updated By");
          },
        },
        { name: "Actions", key: "actions", width: "4" },
      ],
      responsive_columns: [
        { name: "Video Name", key: "name", width: "2", },
        { name: "Description", key: "description", width: "2", },
        { name: "Tags", key: "tags", width: "3", },
        // { name: "Status", key: "status", width: "1" },
        { name: "Updated On", key: "modifiedDate", width: "2", },
        { name: "Updated By", key: "modifiedBy", width: "2", },
      ],
      listings: { content: [], number: 0 },
      errorMsg: null,
      showError: false,
      itemLoadXHR: false,
      isDescSearch: true,
      sortBy: "modificationDate,DESC",
      isCopied: false,
    };

    this.onKeyUp = this.onKeyUp.bind(this);
  }
  getVideoStreamLink = (e, id, name) => {
    // get link
    e.preventDefault();
    const { URL_STREAM_VIDEO } = Constants.App;
    const params = { params: { id } };
    const instance = Api.AxiosInstance.getInstance();
    instance.get(URL_STREAM_VIDEO, params).then((resp) => {
      this.setState({
        selectedVideoLink: resp.data,
        selectedVideoName: name,
        isLoaded: true,
        playVideo: true,
      });
    });
  };
  componentDidMount() {
    this.getList();
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.search();
    }
  }

  getList = () => {
    const { pageNum } = this.state;
    let params = {
      params: {
        page: pageNum,
        sort: this.state.sortBy,
      },
    };
    return Api.AxiosInstance.getInstance()
      .get(`/video/all`, params)
      .then((resp) => {
        this.setState({
          listings: this.transformData(resp.data),
        });
      });
  };
  showDeleteWarningAndSetItem = (id) => {
    this.setState({
      selectedId: id,
      deleteWarning: true,
    });
  };
  hideDeleteWarning = (cb) => {
    this.setState({ deleteWarning: false }, () => {
      if (cb instanceof Function) cb();
    });
  };
  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.state.columns = this.state.columns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getList();
    });
  };
  remove = () => {
    const { URL_LICENSE_REMOVE } = Constants.App;
    const { selectedItem } = this.state;

    Api.AxiosInstance.getInstance()
      .get(`${URL_LICENSE_REMOVE}?id=${selectedItem.id}`)
      .then((resp) => {
        this.setState({ selectedItems: [] }, this.getList);
      })
      .catch((err) => {
        this.setState(
          {
            showError: true,
            errorMsg: "An error occured while deleting the item(s)",
            selectedItems: [],
          },
          this.getList
        );
      });
  };
  onDelete = () => {
    const { selectedId } = this.state;
    const { URL_VIDEOS } = Constants.App;
    const body = { params: { id: selectedId } };
    const instance = Api.AxiosInstance.getInstance();
    instance
      .delete(`${URL_VIDEOS}/dir`, body)
      .then((resp) => {
        this.setState(
          {
            // videos: update(videos, { $set: resp.data }),
            isLoaded: true,
            selectedItem: [],
          },
          this.getList
        );
      })
      .catch((err) => {
        this.setState(
          {
            showError: true,
            errorMsg: "An error occured while deleting the item(s)",
            selectedItems: [],
          },
          this.getList
        );
      });
  };
  transformData = (data) => {
    // let transformedData = Object.assign({}, data);
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);

        row.actions = (
          <React.Fragment>
            <a
              href="#"
              onClick={(e) => this.getVideoStreamLink(e, row.id, row.name)}
            >
              {<span className="icon-play" title="Play" />}
            </a>
            {
              <a
                title="Copy link to video"
                onClick={() => this.copyLink(row.id)}
              >
                {
                  <span
                    name="paperclip"
                    className="icon-link-4"
                    title="Copy Link"
                  />
                }
              </a>
            }
            {ADMIN_ROLES.indexOf(this.props.authority) >= 0 && (
              <Link to={`/videos/list/edit/${row.parentId}/${row.id}`}>
                <span
                  style={{ margin: "5px" }}
                  className="icon-edit"
                  title="Edit"
                  name="edit"
                />
              </Link>
            )}

            {/* {
              <a onClick={() => this.copy(row.parentId)}>
                {
                  <span
                    style={{ margin: "5px" }}
                    className="icon-delete"
                    title="Remove"
                    name="remove"
                  />
                }
              </a>
            } */}
            {/* {<b>{row.isCopied}</b>}{" "} */}
            {ADMIN_ROLES.indexOf(this.props.authority) >= 0 && (
              <a onClick={() => this.showDeleteWarningAndSetItem(row.parentId)}>
                {
                  <span
                    style={{ margin: "5px" }}
                    className="icon-delete"
                    title="Remove"
                    name="remove"
                  />
                }
              </a>
            )}
            {row.isCopied && (
              <span style={{ color: "#f55036", marginRight: "5px" }}>
                Copied to clipboard
              </span>
            )}
          </React.Fragment>
        );
        row.modifiedDate = <>{dateToPacific(el.modifiedDate, "MM/DD/YYYY")}</>;
        row.name = (
          <span
            title="Play"
            style={{ color: "#F55036", cursor: "pointer" }}
            onClick={(e) => this.getVideoStreamLink(e, row.id, row.name)}
          >
            {row.name}
          </span>
        );
        //   <>
        //     {/* <span
        //       title="Play"
        //       style={{ color: "#F55036", cursor: "pointer" }}
        //       onClick={(e) => this.getVideoStreamLink(e, row.id, row.name)}
        //     > */}
        //     {el.name} 123
        //     {/* </span> */}
        //   </>

        // (row.name = (
        //   <>
        //     {/* <span
        //       title="Play"
        //       style={{ color: "#F55036", cursor: "pointer" }}
        //       onClick={(e) => this.getVideoStreamLink(e, row.id, row.name)}
        //     > */}
        //     {el.name} 123
        //     {/* </span> */}
        //   </>
        // )),
        // console.log(row);
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  copyLink = (id) => {
    const el = document.createElement('textarea');
    el.value = `${window.origin}/#/videos/view/${id}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.setState({ isCopied: true });

    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 1000);
  };

  onPageChange = (e) => {
    this.setState({ pageNum: e.page }, this.getList);
  };

  search = () => {
    const { search } = this.state;
    // let type = this.state.licenseType ? this.state.licenseType : "all";
    // let status = this.state.licenseStatus ? this.state.licenseStatus : "all";
    let params = {
      params: {
        // type,
        search,
      },
    };
    return Api.AxiosInstance.getInstance()
      .get("/video/all", params)
      .then((resp) => {
        this.setState({
          listings: this.transformData(resp.data),
        });
      });
  };

  create = () => {
    this.setState({
      createMode: true,
      listings: { content: [], number: 0 },
    });
  };

  edit = (e, id) => {
    e.preventDefault();
    this.setState({
      editMode: true,
      editId: id,
      listings: { content: [], number: 0 },
    });
  };

  remove = (e, id) => {
    e.preventDefault();
    let params = {
      params: {
        id,
      },
    };
    if (window.confirm("Are you sure you wish to delete this license?")) {
      return Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_LICENSE_REMOVE, params)
        .then((resp) => {
          this.getList();
        });
    }
  };

  download = (e, id) => {
    e.preventDefault();
    let params = {
      params: {
        id,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_LICENSE_DOWNLOAD, params)
      .then((resp) => {
        let win = window.open(resp.data, "_blank");
        win.focus();
      })
      .catch((err) =>
        this.setState({
          showError: true,
          errorMsg: "An error occured while downloading the license.",
        })
      );
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  onSearchTypeChange = (event, data) => {
    const { licenseType } = this.state;
    this.setState({ licenseType: data.value });
  };
  onSearchStatusChange = (event, data) => {
    const { licenseStatus } = this.state;
    this.setState({ licenseStatus: data.value });
  };
  createCancellationHandler = (e) => {
    if (e) e.preventDefault();
    this.setState({
      createMode: false,
    });
    this.getList();
  };
  editCancellationHandler = (e) => {
    e.preventDefault();
    this.setState({
      editMode: false,
      editId: null,
    });
    this.getList();
  };

  onSearchTyping = (event, data) => {
    this.setState({
      search: event.target.value,
    });
  };
  render() {
    const { errorMsg, showError, playVideo } = this.state;
    const { SEARCH_ON_LICENSE_TYPE } = this.Constants;
    return (
      <React.Fragment>
        {this.state.listings.content && (
          <Segment className="base-segment-wrapper">
            <Grid stackable>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="header">
                    <div className="side-marker" />
                    Videos
                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="control-action-wrapper">
                    {ADMIN_ROLES.indexOf(this.props.authority) >= 0 && (
                      <Link to="/videos/upload/296">
                        <button type="button" className="button-basic">
                          Add New
                        </button>
                      </Link>
                    )}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row className="common-filter-wrapper ui form">
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={8}
                  largeScreen={8}
                >
                  <div class="field">
                    <div class="ui input">
                      <input
                        placeholder="Search"
                        className="search-bar"
                        onChange={this.onSearchTyping}
                        onKeyPress={this.onKeyUp}
                      ></input>
                    </div>
                  </div>
                </Grid.Column>

                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={4}
                  largeScreen={4}
                >
                  <div class="field">
                    <div class="ui input">
                      <button
                        onClick={this.search}
                        className="button-primary m--no-margin-left-im"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </Grid.Column>

                {/* <div class="field">
                      <label>
                        <b>Status:</b>
                      </label>
                      <div class="ui input">
                        <Dropdown
                          options={this.Constants.SEARCH_ON_LICENSE_STATUS}
                          selection
                          defaultValue={this.state.licenseStatus}
                          onChange={this.onSearchStatusChange}
                          placeholder="All"
                        />
                      </div>
                </div> */}
              </Grid.Row>
              <Divider />

              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Responsive minWidth={1024}>
                    <div className="table-wrapper-with-pagination">
                      <Tableview
                        paginate
                        data={this.state.listings}
                        columns={this.state.columns}
                        onPaginationChange={this.onPageChange}
                        noDataMessage="No Videos"
                      />
                    </div>
                  </Responsive>

                  <Responsive maxWidth={1023}>
                    <CardviewMobile
                      data={this.state.listings}
                      columns={this.state.responsive_columns}
                      paginate
                      onPaginationChange={(e) => this.onPageChange(e)}
                    />
                  </Responsive>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        )}

        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.onDelete)}
          content="Are you sure you want to delete this Video?"
        />
        <Modal
          onClose={() => this.setState({ playVideo: false })}
          open={playVideo}
        >
          <Modal.Header>{this.state.selectedVideoName}</Modal.Header>
          <Modal.Content>
            {" "}
            <VideoPlayer
              controls={true}
              src={this.state.selectedVideoLink}
              // src="https://storage.googleapis.com/websilo/temp/a6d31bf8-0490-4581-ab5d-2774e0d7dfff/Countdown_-_2637.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=converse-smartly%40conversations-that-matter.iam.gserviceaccount.com%2F20201201%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20201201T105634Z&X-Goog-Expires=3600&X-Goog-SignedHeaders=host&X-Goog-Signature=2576d24dddc149226fa790fbf9ca245f4a5c07c4e4558d6558ba163c7dbb326398acfbb5171c8bce0fba65e88c117a74aac6a7d56d4ac1e5853c92362a94e9579148e4034be6635e738c9bc84d1f1554f0868040e404595604594021d55554305098807078731fc88b63163b64c1e5088a899e4d633fd8ecbc2566f55c7a25f112444f5b6d84935e5ec63dbceb60bd26bb4b406623161d1600d974e12002d1f7151abe1959e8d8c6d33dc1825f957144ad47bba5b86324f6295a85acfcc4028dea54d9ad958918afa76d8c5bfaed3b6da8dbe2c84cdb21cb374f4020aad22fd586dc682240cd8d90409e561ac74a1d672c9baf297c51f1fd22f778aff034188c"
              // poster={this.state.poster}
              width="860"
              height="420"
            />
            <Grid>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="m--align-right mt-1"
              >
                <Button
                  className="button-primary mr-0-im mt-2-im "
                  onClick={() => this.setState({ playVideo: false })}
                >
                  Close
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <TransitionablePortal
          open={this.state.isCopied}
          transition={{ animation: "fly down" }}
        >
          <Segment
            style={{
              left: "40%",
              position: "fixed",
              top: "10%",
              zIndex: 1000,
            }}
          >
            <Header color="orange">Link Copied to Clipboard</Header>
          </Segment>
        </TransitionablePortal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  // incase i'll need config values
  // config: configSelector(state),
  // baseUrl: baseUrl,
  authority: authoritySelector(state),
});

const mappedVideos = connect(mapStateToProps, mapDispatchToProps)(VideosList);
export default mappedVideos;
