import Api from "@api";
import Constants from "@constants";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Label,
  Icon,
  Form,
  Divider,
  Header,
  Image,
  Modal,
} from "semantic-ui-react";
import ProductsContext from "../_context";
import DocumentViewer from "./document-viewer";
import LicensePreview from "./license-modal";

function DownloadItem({
  item,
  cancel,
  configParam,
  isLicenseAttached,
  curl,
  wget,
}) {
  const [proceedToDownload, setProceedToDownload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);
  const [acceptLicense, setAcceptLicense] = useState(true);
  const [licenseLink, setLicenseLink] = useState(null);
  const [curlCommand, setcurlLink] = useState(curl);
  const [wgetCommand, setwgetLink] = useState(wget);
  // const [downlinkClipboard, setdownlinkClipboard] = useState(0);
  //const [curlCopiedToClipboard, setcurlCopiedToClipboard] = useState(0);
  //const [wgetCopiedToClipboard, setwgetCopiedToClipboard] = useState(0);
  //const [open, setOpen] = React.useState(false);
  // const [token, setToken] = useState(null);

  useEffect(() => {
    // Update the document title using the browser API
    fetchLicenseLink();
  },[]);

  const instance = Api.AxiosInstance.getInstance();
  const {
    URL_ITEM_DOWNLOAD,
    URL_ITEMS_ASSOCIATED_LICENSE_LINK,
    URL_ITEMS_LICENSE_AGREED,
  } = Constants.App;

  const params = { params: { itemId: item.id }, timeout: 0 };
  //removing timeout here, because files are watermarked and can take some time.

  const fetchLicenseLink = () => {
    instance.get(URL_ITEMS_ASSOCIATED_LICENSE_LINK, params).then((resp) => {
      if (resp.data) {
        setLicenseLink(resp.data);
        setAcceptLicense(false);
      }
    });
  };

  const proceedToDownloadEvent = () => {
    if (licenseLink) {
      params.params.isAgreed = acceptLicense ? 1 : 0;
    }
    instance.get(URL_ITEM_DOWNLOAD, params).then((resp) => {
      setIsDownloading(false);
      setDownloadLink(resp.data);
    });
    setIsDownloading(true);
    setProceedToDownload(true);
  };

  const handleAcceptLicense = (type) => {
    if (type === "agree") {
      setAcceptLicense(true);
      // if needed @TODO handle in same call
      //params.params.isAgreed = 1;
      //instance.get(URL_ITEMS_LICENSE_AGREED, params).then((resp) => {});
    }
  };

  return (
    <React.Fragment>
      {/*}  <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        size={'tiny'}
        trigger={<Button onClick={proceedToDownloadEvent}>Show Modal</Button>}
      >
        <Modal.Header>Download {'>'} {item.createdBy}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header><small>{item.name}</small> </Header>
            <p>{item.filename}
              <br></br>
              {item.description}
            </p>
            <br></br>
          </Modal.Description>
          <a href={downloadLink} target="_blank">
            <Button color="grey">
              <Icon name="download" style={{ marginRight: 0 }} />
            </Button>
          </a>
          <a onClick={() => copyToClipboard(downloadLink, 'link')}>
            <Button color="grey" disabled={downlinkClipboard}>
              <Icon name="paperclip" style={{ marginRight: 0 }} />
            </Button>
          </a>
          <a onClick={() => copyToClipboard(curlCommand, 'curl')}>
            <Button color="grey" disabled={curlCopiedToClipboard}>
              cURL
            </Button>
          </a>
          <a onClick={() => copyToClipboard(wgetCommand, 'wget')}>
            <Button color="grey" disabled={wgetCopiedToClipboard}>
              wGET
            </Button>
          </a>
        </Modal.Content>
  </Modal> */}
      <div className="base-segment-wrapper product-file-downlaod">
        <div className="ui form mb-2-im">
          <Form.Input label="Name">
            <Label>
              <b>{item.name}</b>
            </Label>
          </Form.Input>

          <Form.Input label="Category">
            <Label>
              <b>{configParam.maps.item_categories[item.category]}</b>
            </Label>
          </Form.Input>

          <Form.Input label="Platform">
            <Label>
              <b>{configParam.maps.platforms[item.platform]}</b>
            </Label>
          </Form.Input>

          <Form.Input label="File Name">
            <Label>
              <b>{item.filename}</b>
            </Label>
          </Form.Input>
        </div>
      </div>
      <div className="ui divider mt-1-im"></div>
      {!proceedToDownload && (
        <Button
          onClick={proceedToDownloadEvent}
          disabled={isDownloading || !acceptLicense}
          className="button-primary m--no-margin-left-im"
        >
          Proceed to download
        </Button>
      )}
      {proceedToDownload && isDownloading && (
        <React.Fragment>
          <Icon loading name="spinner" /> Readying the file for download
        </React.Fragment>
      )}
      {proceedToDownload && !isDownloading && (
        <a href={downloadLink} target="_blank">
          <Button
            className="button-primary m--no-margin-left-im"
            disabled={isDownloading}
          >
            Download
          </Button>
        </a>
      )}{" "}
      <button onClick={cancel} className="button-basic">
        Cancel
      </button>
      {/*
      <strong>OR</strong> <em>Download using below commands</em>
      <br /> <br />
      <div>
        <label>
          <strong>Direct Download Commands:</strong>
        </label>
        <br></br>
        <pre>
          <a target="_blank">{curlCommand} </a>
          <Button
            onClick={copyCurlCommandToClipboard}
            disabled={curlCopiedToClipboard}
          >
            <Icon name="copy" />
          </Button>{" "}
        </pre>

        <pre>
          <a target="_blank">{wgetCommand} </a>
          <Button
            onClick={copyWgetCommandToClipboard}
            disabled={wgetCopiedToClipboard}
          >
            <Icon name="copy" />
          </Button>{" "}
        </pre>
      </div> */}
      {licenseLink && (
        <React.Fragment>
          <LicensePreview
            // accept={handleAcceptLicense}
            cancel={handleAcceptLicense}
            isLicenseAttached={true}
            licenseLink={licenseLink}
            name={item.filename}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ProductsContext.Consumer(DownloadItem);
