import { UserInfoForm } from "@/common/user";
import { removeEmptyKeys } from "@/common/utils/utils";
import {
  extendedValidationConfig,
  normalValidationConfigForSelfRegister,
} from "@/common/validations/userValidation";
import Constants from "@/config/constants";
import Axios from "axios";
import update from "immutability-helper";
import { get, isEmpty } from "lodash";
import React from "react";
import { Redirect, withRouter } from "react-router";
import { Dimmer, Loader, Modal, Segment } from "semantic-ui-react";
import { validate } from "validate.js";
import postalCodes from "postal-codes-js";
import _ from "lodash";
import { decodeJWT } from "../../utils/jwt";

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      formDisabled: false,
      isUsernameAvailable: false,
      isEmailAvailable: false,
      usernameError: null,
      emailError: null,
      validationMsg: {},
      form: {
        updateNotificationFrequency: "0",
        countryCode: "US",
        code: "+1",
        company: "",
      },
      showEmailMsg: false,
      msg: "",
      value: "",
      local: false,
      localcountry: "US",
      localpostal: "",
      localresult: "",
      linkExpired: false,
      isOAuth: false,
    };
  }
  componentDidMount() {
    if (this.props.location.pathname.split("/")[2]) {
      const decoded = decodeJWT(this.props.location.pathname.split("/")[2]);
      if (Date.now() >= decoded.exp * 1000) {
        this.setState({ linkExpired: true });
        return;
      } else {
        let params = {
          params: {
            username: decoded.username,
          },
        };
        Axios.get(`${Constants.App.LINK_EXPIRED}`, params).then((res) => {
          if (res.data) {
            this.setState({ linkExpired: true });
          }
        });
        this.setState({
          form: {
            ...this.state.form,
            username: decoded.username,
            email: decoded.email,
            company: decoded.company,
            firstName: decoded.firstName,
            lastName: decoded.lastName
          },
          isOAuth: decoded.oauth
        });
      }
    }

    const { config } = this.props;

    if (config && (config?.maps?.is_signup_allowed === "false" || !config?.maps?.is_signup_allowed)) {
      this.setState({
        formDisabled: true,
        msg: "Registration is disabled",
        msgSpanColor: "red",
      });

      return;
    }
  }
  checkPostalCode = (e) => {
    var res = postalCodes.validate(
      this.state.localcountry,
      this.state.localpostal
    );
    if (this.state.localpostal == "") {
      res = true;
    }
    this.state.localresult = res;
    const { validationMsg } = this.state;
    let value = res ? { postalCode: res } : [];
    value.localresult = this.state.localresult;
    let obj = {
      postalCode: normalValidationConfigForSelfRegister["postalCode"],
    };
    const singleFieldValidation = validate(value, obj);
    if (isEmpty(singleFieldValidation)) {
      this.setState({
        validationMsg: update(validationMsg, {
          postalCode: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          postalCode: { $set: singleFieldValidation["postalCode"] },
        }),
      });
    }
  };

  checkTokenData = () => {
    console.log('location', this.props.location.hash)
  }

  onDataChange = (event, data) => {
    const { form } = this.state;
    if (data.name == "countryCode") {
      this.state.localcountry = data.value;
      this.setState({
        form: update(form, { [data.name]: { $set: data.value } }),
      });
      this.checkPostalCode(event);
    } else if (data.name == "postalCode") {
      this.state.localpostal = data.value;
      this.setState({
        form: update(form, { [data.name]: { $set: data.value } }),
      });
      this.checkPostalCode(event);
    } else {
      this.setState({
        form: update(form, { [data.name]: { $set: data.value } }),
      });
    }
  };

  isNumeric = (value) => {
    return /^\d+$/.test(value);
  };

  onPhoneChange = (event, data) => {
    let cond = event.target.value;
    const { form } = this.state;
    var tempphone = "";
    for (var i = 0; i < cond.length; i++) {
      if (this.isNumeric(cond.charAt(i))) {
        tempphone = tempphone + cond.charAt(i);
      }
    }
    if (tempphone.length < 3) {
      this.setState({ form: update(form, { phone: { $set: tempphone } }) });
    } else if (
      tempphone.length == 3 &&
      cond.charAt(cond.length - 1) == ")" &&
      cond.length == 5
    ) {
      this.setState({
        form: update(form, { phone: { $set: tempphone.substring(0, 2) } }),
      });
    } else if (tempphone.length == 3) {
      this.setState({
        form: update(form, {
          phone: { $set: "(" + tempphone.substring(0, 3) + ") " },
        }),
      });
    } else if (tempphone.length > 3 && tempphone.length < 6) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              "(" + tempphone.substring(0, 3) + ") " + tempphone.substring(3),
          },
        }),
      });
    } else if (tempphone.length == 6 && this.state.local == false) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              "(" +
              tempphone.substring(0, 3) +
              ") " +
              tempphone.substring(3, 6) +
              " ",
          },
        }),
      });
      this.setState({ local: true });
    } else if (tempphone.length == 6 && this.state.local == true) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              "(" +
              tempphone.substring(0, 3) +
              ") " +
              tempphone.substring(3, 6),
          },
        }),
      });
      this.setState({ local: false });
    } else if (tempphone.length >= 6 && tempphone.length < 12) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              "(" +
              tempphone.substring(0, 3) +
              ") " +
              tempphone.substring(3, 6) +
              " " +
              tempphone.substring(6),
          },
        }),
      });
    } else if (tempphone.length >= 12) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              "(" +
              tempphone.substring(0, 3) +
              ") " +
              tempphone.substring(3, 6) +
              " " +
              tempphone.substring(6, 11),
          },
        }),
      });
    }
  };
  onByteChange = (event, eventData) => {
    const { form } = this.state;
    let value = eventData.value ? 1 : null;

    this.setState({
      form: update(form, { [eventData.name]: { $set: value } }),
    });
  };
  onKeyup = (e) => {
    let decoded;
    if (this.props.location.pathname.split("/")[2]) {
      decoded = decodeJWT(this.props.location.pathname.split("/")[2]);
    }
    if (e.target.name === "username") {
      if (decoded) {
        if (decoded.username !== e.target.value) {
          this.checkUsernameAvailability();
        }
      } else {
        this.checkUsernameAvailability();
      }
    }
    if (e.target.name === "email") {
      this.checkEmailAvailability();
    }
  };

  onBlur = (e) => {
    const { validationMsg } = this.state;
    const { form } = this.state;
    const { restrictedEmailDomains } = this.props;
    form.localresult = this.state.localresult;
    let value = e.target.value ? { [e.target.name]: e.target.value } : [];
    if (e.target.name === "email") {
      value.restrictedDomains =
        restrictedEmailDomains && restrictedEmailDomains
          ? restrictedEmailDomains
          : "";
    }
    if (e.target.name === "confirmPassword") {
      value.password = form.password && form.password ? form.password : "";
    }
    if (e.target.name === "phone" || e.target.name === "cellPhone") {
      value.countryCode = form.countryCode;
    }
    if (e.target.name === "postalCode") {
      return;
    }
    let obj = {
      [e.target.name]: normalValidationConfigForSelfRegister[e.target.name],
    };
    const singleFieldValidation = validate(value, obj);
    if (isEmpty(singleFieldValidation)) {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: singleFieldValidation[e.target.name] },
        }),
      });
    }
  };

  onPasswordChange = (passwordState, feedback) => {
    const { form, validationMsg } = this.state;
    if (passwordState.password) {
      this.setState({
        form: update(form, {
          password: { $set: passwordState.password },
          passwordScore: { $set: get(feedback, ["score"]) },
        }),
        validationMsg: update(validationMsg, {
          password: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          password: { $set: "Password can't be blank" },
        }),
        form: update(form, {
          password: { $set: null },
          // passwordScore: { $set: get(feedback, ["score"])}
        }),
      });
    }
  };

  checkUsernameAvailability = (cb) => {
    const { isUsernameAvailable } = this.state;
    const { username } = this.state.form;
    let params = {
      params: {
        username,
      },
    };
    Axios.get(Constants.App.URL_CHECK_USERNAME, params)
      .then((resp) => {
        this.setState(
          {
            isUsernameAvailable: true,
            formDisabled: false,
            validationMsg: { username: null },
            msg: null,
            apiError: null,
            usernameError: null,
          },
          () => {
            if (cb instanceof Function) cb();
          }
        );
      })
      .catch(() => {
        this.setState({
          formDisabled: false,
          validationMsg: {
            username: "User name already exists. Try other username",
          },
          // msg: "User name already exists",
          msgSpanColor: "red",
          usernameError: "User name already exists. Try other username",
        });
      });
  };
  formSubmission = () => {
    this.submitRegistration();
  };
  checkEmailAvailability = (cb) => {
    const { email } = this.state.form;
    const { isEmailAvailable } = this.state;
    let params = {
      params: {
        email,
      },
    };
    Axios.get(Constants.App.URL_CHECK_EMAIL, params)
      .then((resp) => {
        if (email.includes("@groq.com")) {
          this.setState({
            formDisabled: false,
            validationMsg: {
              email: "email cannot be with the domain @groq.com",
            },
            msg: "email cannot be with the domain @groq.com",
            msgSpanColor: "red",
            emailError: "email cannot be with the domain @groq.com",
          });
          return false;
        }
        this.setState(
          {
            isEmailAvailable: true,
            formDisabled: false,
            emailError: null,
            msg: null,
            apiError: null,
          },
          () => {
            if (cb instanceof Function) cb();
          }
        );
      })
      .catch(() => {
        this.setState({
          formDisabled: false,

          msgSpanColor: "red",
          emailError: "Email address already exists.",
          // msg: "Email address already exists, Please use Forget your Password"
        });
      });
  };

  submitRegistration = () => {
    let decoded = null;
    if (this.props.location.pathname.split("/")[2]) {
      decoded = decodeJWT(this.props.location.pathname.split("/")[2]);
    }
    let { form, validationMsg } = this.state;
    form.phone = `${form.code}${form.phone}`;
    if (!isEmpty(validationMsg)) return;
    if (!isEmpty(this.state.emailError)) {
      this.setState({
        formDisabled: false,
      });
      return;
    }
    const url = _.isEmpty(decoded)
      ? Constants.App.URL_REGISTER
      : `${Constants.App.URL_REGISTER}/update`;
    return Axios.post(url, form)
      .then((resp) => {
        this.setState({ showEmailMsg: true });
      })
      .catch((e) => {
        this.setState({
          formDisabled: false,
          apiError: "Registration failed. Cannot add user.",
          msg: "Registration failed. Cannot add user",
          msgSpanColor: "red",
        });
      });
  };

  validateForm = (event) => {

    const { config } = this.props;

    if (config && (config?.maps?.is_signup_allowed === "false" || !config?.maps?.is_signup_allowed)) {
      this.setState({
        formDisabled: true,
        msg: "Registration is disabled",
        msgSpanColor: "red",
      });

      return;
    }

    const { form, localresult, isOAuth } = this.state;
    form.localresult = localresult;
    const { restrictedEmailDomains } = this.props;
    form.agreed = form.agreed;
    // form.agreed = form.gdprCheck && form.ccpaCheck;
    let cleanedForm = removeEmptyKeys(form);
    cleanedForm.restrictedDomains = restrictedEmailDomains;

    let validationConfig = {...normalValidationConfigForSelfRegister};
    if (isOAuth) {
      validationConfig = {
        ...normalValidationConfigForSelfRegister,
        password: {presence: false},
        confirmPassword: {presence: false}
      }
    }

    const staticValidation = validate(
      cleanedForm,
      validationConfig
    );
    if (isEmpty(staticValidation)) {
      this.setState(
        { formDisabled: true, validationMsg: {} },
        this.formSubmission
      );
    } else {
      this.setState({
        validationMsg: staticValidation,
        msg: "Validation failed, please fix the errors before submitting",
        msgSpanColor: "red",
      });
    }
  };
  showGdprWarning = () => {
    this.setState({
      showGdpr: true,
    });
  };
  showCcpaWarning = () => {
    this.setState({
      showCcpa: true,
    });
  };
  setGdprCheck = () => {
    const { form } = this.state;

    this.setState({
      form: update(form, { gdprCheck: { $set: 1 } }),
      showGdpr: false,
    });
  };
  setCcpaCheck = () => {
    const { form } = this.state;

    this.setState({
      form: update(form, { ccpaCheck: { $set: 1 } }),
      showCcpa: false,
    });
  };
  render() {
    const {
      apiError,
      form,
      formDisabled,
      showEmailMsg,
      validationMsg,
      usernameError,
      emailError,
      msg,
      msgSpanColor,
    } = this.state;
    const { config } = this.props;
    let decoded;
    if (this.props.location.pathname.split("/")[2]) {
      decoded = decodeJWT(this.props.location.pathname.split("/")[2]);
    }
    if (config.success)
      return (
        <Segment basic>
          <Segment className="mt-2-im mb-2-im base-segment-wrapper">
            <div className="side-marker" />
            <div className="header">Request New Account</div>
            <div className="empty-height-small" />

            <div>
              <i>
                To request a new account, please fill in all of the mandatory
                fields below. You will need to validate your email account prior
                to logging in.
              </i>
            </div>
            <br />
            {/*UserInfo Form Reusable Component*/}

            <UserInfoForm
              validateForm={this.validateForm}
              apiError={apiError}
              formDisabled={formDisabled}
              form={form}
              validationMsg={validationMsg}
              onDataChange={this.onDataChange}
              onBlur={this.onBlur}
              onKeyup={this.onKeyup}
              usernameError={usernameError}
              onPhoneChange={this.onPhoneChange}
              emailError={emailError}
              config={config}
              backLink={"/Login"}
              msg={msg}
              onPasswordChange={this.onPasswordChange}
              formType="Registeration"
              errorTitle="User Registration Failed"
              msgSpanColor={msgSpanColor}
              onByteChange={this.onByteChange}
              showGdpr={this.showGdprWarning}
              showCcpa={this.showCcpaWarning}
              jwtDecoded={!_.isEmpty(decoded) && decoded}
              isOAuth={this.state.isOAuth}
            />

            {this.state.redirectToLogin ? <Redirect to="/login" /> : ""}
          </Segment>
          <Modal
            header="GDPR Policy"
            open={this.state.showGdpr}
            onClose={() => {
              this.setState({ showGdpr: false });
            }}
            onActionClick={() => {
              // this.setGdprCheck();
            }}
            closable={true}
            centered={true}
            size="large"
            closeIcon
            content={
              <Segment>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.gdpr?.gdpr_text,
                  }}
                ></div>
              </Segment>
            }
            actions={[
              {
                key: "cancel",
                content: "Cancel",
                primary: false,
                onActionClick: () => this.setState({ showGdpr: false }),
              },
              {
                key: "warningDismiss",
                content: "Agree",
                primary: true,
                onClick: this.setGdprCheck,
              },
            ]}
          ></Modal>
          <Modal
            header="CCPA Compliance Policy"
            closeIcon
            open={this.state.showCcpa}
            onClose={() => {
              this.setState({ showCcpa: false });
            }}
            onActionClick={() => {
              // this.setCcpaCheck();
            }}
            closable={true}
            centered={true}
            size="large"
            content={
              <Segment>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.ccpa?.ccpa_text,
                  }}
                ></div>{" "}
              </Segment>
            }
            // actions={['Cancel', { key: 'close', content: 'Agree', primary: true }]}
            actions={[
              {
                key: "cancel",
                content: "Cancel",
                primary: false,
                onActionClick: () => this.setState({ showCcpa: false }),
              },
              {
                key: "warningDismiss",
                content: "Agree",
                primary: true,
                onClick: this.setCcpaCheck,
              },
            ]}
          ></Modal>
          <Modal
            open={showEmailMsg}
            onClose={() => {
              this.setState({ redirectToLogin: true, formDisabled: false });
            }}
            centered={true}
            size="tiny"
            content={
              decoded
                ? "Account registration has been completed, now you can login to your account."
                : "Account registration request received. Please check your email to validate your account prior to logging in."
            }
            actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
          ></Modal>
          <Modal
            open={this.state.linkExpired}
            onClose={() => {
              this.setState({ linkExpired: false });
              this.props.history.push("/login");
            }}
            centered={true}
            size="tiny"
            content="Contact Groq Support to refresh the link."
            actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
          ></Modal>
        </Segment>
      );
    else
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
  }
}

export default withRouter(Registration);
