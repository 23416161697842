import { useDropzone } from 'react-dropzone';
import React, { useState, useEffect } from 'react';
import { Form, Icon, Label, Segment, List } from "semantic-ui-react";
import ErrorMessage from "./error-message";
import { configSelector } from '../../selectors';

function DropFileField(props) {
  const { errorMsg, isError, isRequired, label, multipleSelection,
    name, onChange, value } = props;
  const [files, setFiles] = useState(value ? value : []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept ? props.accept : "/*",
    onDrop: acceptedFiles => {

      let cleanFiles = [];

      // Retain only the files that match our criteria
      for (const file of acceptedFiles) {
        if (file.type !== "text/javascript") {
          if (props.type) {
            if (props.type === file.type) {
              cleanFiles.push(file);
            }
          }
          else {
            cleanFiles.push(file);
          }
        }
      }
      let newFiles = multipleSelection
        ? files.concat(cleanFiles)
        : cleanFiles ? [cleanFiles[0]] : [];
      setFiles(newFiles);
    }
  });
  let onFileRemove = (fileIndex) => {
    setFiles(prev => prev.filter((_, index) => index !== fileIndex));
  };
  useEffect(() => {
    if (files !== value || files.length ==0){
      onChange(null, { name, value: files });
    }
  }, [files]);
  
  return (
    <Form.Field
      required={isRequired}
      error={isError}
      className="common-file-attachment"
    >
      <label>{label}</label>
      <Segment>
        <List>
          {files.map((file, index) => (
            <List.Item key={`file-item--${index}`}>
              <Label size="large" key={`file-item-label--${index}`}>
                <Icon
                  size="large"
                  name="file outline"
                  key={`file-item-icon--${index}`}
                />
                {file.name}
                <Icon
                  name="delete"
                  key={`file-item-cross--${index}`}
                  onClick={() => onFileRemove(index)}
                />
              </Label>
            </List.Item>
          ))}
        </List>
        {files.length === 0 && "No file attached."}
      </Segment>
      <Segment basic className='m-0' >
        <div
          {...getRootProps({ className: 'dropzone drag-drop-zone' })}
        >
          <input className="input-zone" {...getInputProps()} />
          {isDragActive ? (
            <p>Release to drop the files here</p>
          ) : (
            <p>Drop <strong>File{multipleSelection && "(s)"}</strong> here or click to browse</p>
          )
          }
        </div>
      </Segment>
      <ErrorMessage isError={isError} errorMsg={errorMsg} />
    </Form.Field>
  );
}
export default DropFileField;
