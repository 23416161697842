import React from "react";
import PropTypes from "prop-types";
import { Form, TextArea } from "semantic-ui-react";
import ErrorMessage from "./error-message";

const TextareaField = ({
  errorMsg,
  isError,
  isRequired,
  label,
  maxLength,
  name,
  onChange,
  rows,
  value,
  width,
  id,
  className,
  placeholder,
}) => {
  return (
    <Form.Field required={isRequired} width={width} error={isError}>
      <label>{label}</label>
      <TextArea
        name={name}
        onChange={(e, data) =>
          onChange(e, {
            ...data,
            value: data.value.substring(0, maxLength)
          })
        }
        placeholder={placeholder}
        value={value}
        rows={rows}
        maxLength={maxLength}
        id={id}
        className={className}
      />
      <ErrorMessage isError={isError} errorMsg={errorMsg} />
    </Form.Field>
  );
};

TextareaField.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** max character allowed in the text area */
  maxLength: PropTypes.number,
  /** Sets the length of Textarea */
  rows: PropTypes.string,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string,
  /** Id of Textarea */
  id: PropTypes.string
};

TextareaField.defaultProps = {
  rows: "3",
  width: "16",
  maxLength: Infinity
};

export default TextareaField;
