import React from "react";
import NDAListing from "../components/nda-listing";
import urls from "@/config/constants";

const NonStandardNDAList = () => {
  const {
    URL_NON_STANDARD_NDA_LISTING,
    URL_NDA_NON_STANDARD_DOWNLOAD,
    URL_NDA_NON_STANDARD_DELETE,
  } = urls.App;
  return (
    <NDAListing
      title="Non-Standard NDA List"
      fetchUrl={URL_NON_STANDARD_NDA_LISTING}
      editUrl="/admin/nda/non-standard/update/"
      deleteUrl={URL_NDA_NON_STANDARD_DELETE}
      downloadUrl={URL_NDA_NON_STANDARD_DOWNLOAD}
      isNonStandard={true}
    />
  );
};

export default NonStandardNDAList;
