import React from "react";
import PageTemplate from "../../common/page";
import NotificationsMain from "./components/notifications-main";

const MENU_ITEMS = {
  Notifications: [
    {
      name: "Notifications",
      relativePath: "/",
      exact: true,
      subPageComponent: NotificationsMain,
    }
  ],
};

const NOTIFICATIONS = ({ baseUrl }) => {
  return <PageTemplate baseUrl={baseUrl} menuItems={MENU_ITEMS} />;
};

export default NOTIFICATIONS;
