import { connect } from "react-redux";
import Api from "../../api";
import Config from "../../config/constants";
import Admin from "./admin";
import NDACreateForm from "@/modules/NDA/pages/nda-create-form";
import { authoritySelector } from "@/common/selectors";

const UserApi = Api.User;

const mapDispatchToProps = (dispatch) => {
  return {
    // login: (username, password, code, isLoginScreen) =>
    //  dispatch(UserApi.login(username, password, code, isLoginScreen)),
    // logout: () => dispatch(UserApi.logout()),
    // oAuthLogin: (type, code) => dispatch(UserApi.oAuthLogin(type, code)),
  };
};

const baseUrl = "/admin";
const mapStateToProps = (state) => ({
  user: state.user,
  config: state.common.config,
  baseUrl: baseUrl,
  authority: authoritySelector(state),
});

const mappedAdmin = connect(mapStateToProps, mapDispatchToProps)(Admin);

export default {
  name: "Admin",
  configKey: "admin_menu_item_title",
  path: "/admin",
  component: mappedAdmin,
  isPrivateRoute: true,
  accessRoles: Config.AccessRole.APPROVERS,
};
