import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Icon, Label, Segment, List } from "semantic-ui-react";
import update from "immutability-helper";
import ErrorMessage from "./error-message";

class FileField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: props.files || [],
    };
  }

  onFileSelect = (event) => {
    const { files } = this.state;
  
    const file = event.target.files[0];

    if (file.type == "text/javascript") {
      return;
    }
    if (this.props.type) {
      if (this.props.type !== file.type) {
        return;
      }
    }
    const filesList = Object.values(event.target.files);

    const { name, onChange, multipleSelection } = this.props;
    let newFiles = multipleSelection
      ? update(files, { $push: filesList })
      : filesList;

    this.setState({ files: newFiles, val: null }, () => {
      onChange(event, { name, value: this.state.files });
    });
    event.target.value = null;
  };

  onFileRemove = (fileIndex) => {
    const { files } = this.state;
    const { name, onChange } = this.props;

    this.setState(
      { files: update(files, { $splice: [[fileIndex, 1]] }) },
      () => {
        onChange(null, { name, value: this.state.files });
      }
    );
  };

  fileInputRef = React.createRef();

  render() {
    const { errorMsg, isError, isRequired, label, multipleSelection, width, disabled } =
      this.props;
    const { files } = this.state;
    return (
      <Form.Field
        required={isRequired}
        error={isError}
        accept="application/pdf"
        className="common-file-attachment"
        disabled={disabled ? true : false}
      >
        <label>{label}</label>
        <Segment>
          <List>
            {files.map((file, index) => (
              <List.Item key={`file-item--${index}`}>
                <Label size="large" key={`file-item-label--${index}`}>
                  <Icon
                    size="large"
                    name="file outline"
                    key={`file-item-icon--${index}`}
                  />
                  {file.name}
                  <Icon
                    name="delete"
                    key={`file-item-cross--${index}`}
                    onClick={() => this.onFileRemove(index)}
                  />
                </Label>
              </List.Item>
            ))}
          </List>
          {files.length === 0 && "No file attached."}
        </Segment>
        <Button
          content="Choose File"
          labelPosition="left"
          className="btn-choose-file"
          icon="cloud upload"
          onClick={() => this.fileInputRef.current.click()}
          type="button"
        />
        <input
          ref={this.fileInputRef}
          type="file"
          hidden
          onChange={this.onFileSelect}
          accept={this.props.accept ? this.props.accept : "/*"}
          multiple={multipleSelection}
        />
        <ErrorMessage isError={isError} errorMsg={errorMsg} />
      </Form.Field>
    );
  }
}

FileField.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will allow to select multiple files */
  multipleSelection: PropTypes.bool,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Width of the input field */
  width: PropTypes.string,
};

FileField.defaultProps = {
  width: "16",
  multipleSelection: true,
};

export default FileField;
