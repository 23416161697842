import NDA from "./nda";
import NDACreateForm from "./pages/nda-create-form";
import Auth from "./auth";
import { connect } from "react-redux";
import Config from "../../config/constants";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const baseUrl = "/nda";

const mapStateToProps = (state) => ({
  baseUrl: baseUrl,
  config: state.common.config,
});

const mappedNDA = connect(mapStateToProps, mapDispatchToProps)(NDA);

export default {
  navigationalRoutes: {
    name: "NDA",
    configKey: "nda_menu_item_title",
    path: baseUrl,
    component: mappedNDA,
    isPrivateRoute: true,
    accessRoles: Config.AccessRole.EDIT_APPROVERS,
    navbarVisibility: false,
  },
  createRoute: {
    name: "NDA",
    configKey: "nda_menu_item_title",
    path: "/nda/create/:token",
    component: NDACreateForm,
    isPublicRoute: true,
    navbarVisibility: false,
  },
  publicRoute: {
    name: "NDA",
    configKey: "nda_menu_item_title",
    path: "/auth",
    component: Auth,
    isPublicRoute: true,
    navbarVisibility: false,
  },
};
