import equal from "fast-deep-equal";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import Moment from "react-moment";
import { List, Pagination, Segment } from "semantic-ui-react";
import Api from "@api";
import Constants from "@/config/constants";
import { trimStringWithWord } from "../../../common/utils/utils";

const { URL_GLOBAL_SEARCH } = Constants.App;
class CorporateSiteData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listings: {
        content: [],
        number: 0,
      },
      data: {},
      pageNo: 0,
      pageSize: 10,
      totalRecords: 0,
    };
  }
  componentDidMount() {
    this.searchResults(
      this.props.keyword ? this.props.keyword : this.props.searchKeyword,
      this.props.sort,
    );
  }
  componentWillReceiveProps(nextProps) {
    if (
      !equal(this.props.keyword, nextProps.keyword) ||
      nextProps.sort.sortBy !== this.props.sort.sortBy ||
      nextProps.sort.sortOrder !== this.props.sort.sortOrder
    ) {
      this.setState(
        {
          sort: nextProps.data,
        },
        () => {
          this.searchResults(
            nextProps.keyword ? nextProps.keyword : nextProps.searchKeyword,
            nextProps.sort,
          );
        },
      );
    }
  }

  transformData = (data) => {
    let content = [];
    // let isAdmin = this.state.isAdmin;
    if (data) {
      data.map((el) => {
        let row = Object.assign({}, el.sourceAsMap);
        console.log(row);
        row.header = row.post_title;
        // row.meta = row.id;
        row.description = row.post_content_filtered
          ? trimStringWithWord(
              row.post_content_filtered.replace(/<[^>]*>?/gm, ""),
              this.props.searchKeyword || this.props.keyword,
              120,
            )
          : "N/A";
        row.href = row.permalink;
        content.push(row);
      });
    }
    data.content = content;
    return data;
  };
  //onSitePageChange
  searchResults = (keyword, sort) => {
    this.setState({ ...this.state, loading: true }, () => {
      Api.AxiosInstance.getInstance()
        .get(`${URL_GLOBAL_SEARCH}groqcom-post-1`, {
          params: {
            query: keyword,
            ...sort,
            pageNo: this.state.pageNo,
            pageSize: this.state.pageSize,
          },
        })
        .then((resp) => {
          this.setState({
            listings: this.transformData(resp.data.hits.hits),
            totalRecords: resp.data.hits.totalHits,
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
          });
        });
    });
  };
  onPageChange = (e, { activePage }) => {
    this.setState(
      {
        pageNo: activePage - 1,
      },
      () => {
        this.searchResults(
          this.props.keyword || this.props.searchKeyword,
          this.props.sort,
        );
      },
    );
  };
  render() {
    return (
      <Segment className="m-0 p-0" loading={this.state.loading}>
        {this.state.listings.content.length > 0 ? (
          <>
            <List celled className="search-result-wrapper">
              {this.state.listings.content.map((data, index) => (
                <List.Item className="search-item-row">
                  <List.Content>
                    <List.Header
                      as="a"
                      href={data.href}
                      className="search-heading"
                      dangerouslySetInnerHTML={{__html: data.header}}
                    >
                    </List.Header>
                    <List.Description className="s-des-wrapper">
                      <p className="des-p1">
                        <Moment format="MM/DD/YYYY">{data.post_date}</Moment>
                        &nbsp; -- &nbsp;
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[
                            this.props.keyword
                              ? this.props.keyword
                              : this.props.searchKeyword,
                          ]}
                          autoEscape={true}
                          textToHighlight={data.description}
                        />
                      </p>
                      {/* <p className="des-p1">{data.description}</p> */}
                      <p class="des-p2">{data.href}</p>
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>

            {this.state.listings.content.length > 0 ? "" : "No record found"}
            <Pagination
              activePage={this.state.pageNo + 1}
              onPageChange={this.onPageChange}
              totalPages={Math.ceil(this.state.totalRecords / 10)}
            />
          </>
        ) : (
          "No record found"
        )}
      </Segment>
    );
  }
}

export default CorporateSiteData;
