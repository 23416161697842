import actions from "../../actions";

const initialState = {
  indices: [],
  loading: false,
  buttonLoading: {
    index: -1,
    loading: false,
  },
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.searchIndices.GET_ALL_INDEXES_INITIATE:
      return {
        ...state,
        loading: true,
      };
    case actions.searchIndices.GET_ALL_INDEXES_SUCCESS:
      return {
        ...state,
        indices: payload,
        loading: false,
      };
    case actions.searchIndices.GET_ALL_INDEXES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case actions.searchIndices.SYNC_INDICES_INITIATE:
      return {
        ...state,
        loading: true,
        buttonLoading: {
          index: payload,
          loading: true,
        },
      };
    case actions.searchIndices.SYNC_INDICES_SUCCESS:
      return {
        ...state,
        loading: false,
        buttonLoading: { index: -1, loading: false },
      };
    case actions.searchIndices.SYNC_INDICES_FAILURE:
      return {
        ...state,
        loading: false,
        buttonLoading: { index: -1, loading: false },
      };
    default:
      return {
        ...state,
      };
  }
};
