const CONFIG_LOADING = 'CONFIG_LOADING';
const CONFIG_LOADING_SUCCESS = 'CONFIG_LOADING_SUCCESS';
const CONFIG_LOADING_FAILURE = 'CONFIG_LOADING_FAILURE';
const CONFIG_SUPPORT_ENABLED = 'CONFIG_SUPPORT_ENABLED';

const SHOW_HIDE_ERROR_DIALOG = 'SHOW_HIDE_ERROR_DIALOG';
const SHOW_HIDE_LOADER = 'SHOW_HIDE_LOADER';
const SHOW_HIDE_OVERLAY = 'SHOW_HIDE_OVERLAY';

const SET_IDLE_TIME = 'SET_IDLE_TIME';
const RESTORE_IDLE_TIME = 'RESTORE_IDLE_TIME';

const SET_IDLE_TIMER_REF = 'SET_IDLE_TIMER_REF';
const PAUSE_IDLE_TIMER = 'PAUSE_IDLE_TIMER';
const RESUME_IDLE_TIMER = 'RESUME_IDLE_TIMER';

export default {
    CONFIG_LOADING,
    CONFIG_LOADING_FAILURE,
    CONFIG_LOADING_SUCCESS,
    CONFIG_SUPPORT_ENABLED,
    SHOW_HIDE_ERROR_DIALOG,
    SHOW_HIDE_LOADER,
    SHOW_HIDE_OVERLAY,
    SET_IDLE_TIME,
    RESTORE_IDLE_TIME,
    SET_IDLE_TIMER_REF,
    PAUSE_IDLE_TIMER,
    RESUME_IDLE_TIMER
};
