import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty, sortBy } from "lodash";
import DropdownField from "./dropdown-field";
import { statesSelector } from "../../selectors";

const NON_US_STATE_CODE = "XX";
const US_CODE = "US";

const getUSStatesList = (states) => {
  const countryList = Object.keys(states).reduce((acc, el) => {
    if (el !== "XX") acc.push({ key: el, value: el, text: states[el] });
    return acc;
  }, []);
  const sortedstates = sortBy(countryList, [
    function (o) {
      return o.text;
    },
  ]);
  return sortedstates;
};

const getNonUSStates = (states) => {
  return [
    { key: "", value: "", text: "" },
    {
      key: NON_US_STATE_CODE,
      value: NON_US_STATE_CODE,
      text: states[NON_US_STATE_CODE],
    },
  ];
};

class StateField extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { country, name, onChange, value } = this.props;
    if (prevProps.country !== country) {
      if (this.props.country !== US_CODE) onChange({}, { name, value: "XX" });
      else if (this.props.country === US_CODE && (!value || value === "XX"))
        onChange({}, { name, value: "" });
    }
  }

  render() {
    const {
      country,
      errorMsg,
      isError,
      isRequired,
      label,
      name,
      onChange,
      states,
      value,
      width,
    } = this.props;
    if (!isEmpty(states)) {
      if (country === US_CODE) {
        return (
          <DropdownField
            errorMsg={errorMsg}
            isError={isError}
            isRequired={isRequired}
            label={label}
            name={name}
            onChange={onChange}
            options={getUSStatesList(states)}
            placeholder="Select your state..."
            search
            selection
            value={value}
            width={width}
          />
        );
      } else {
        return (
          <DropdownField
            disabled
            errorMsg={errorMsg}
            isError={isError}
            isRequired={isRequired}
            label={label}
            name={name}
            onChange={onChange}
            options={getNonUSStates(states)}
            placeholder="Select your state..."
            value={value}
            width={width}
          />
        );
      }
    } else {
      return  <></>;
    }
  }
}

StateField.propTypes = {
  country: PropTypes.string,
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string,
};

StateField.defaultProps = {
  country: US_CODE,
};

const mapStateToProps = (state, props) => {
  return {
    states: statesSelector(state, props),
  };
};

const mappedStateField = connect(mapStateToProps)(StateField);

export default mappedStateField;
