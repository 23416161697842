import Config from "@/config/constants";
import {generateSupportURLAndRedirect} from "../../api/support";

const baseUrl = "#";

export default {
    name: "Support",
    configKey: "support_menu_item_title",
    path: baseUrl,
    component: () => {
        console.log("not a react component")
    },
    onRouteClick: () => {
        return generateSupportURLAndRedirect();
    },
    isPrivateRoute: true,
    accessRoles: Config.AccessRole.SPECIAL_ROLES,
    enableWithSupport: true
};
