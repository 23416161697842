import PageTemplate from "@/common/page";
import React from "react";
import { Redirect } from "react-router-dom";
import FriendlyDownload from "../../common/friendly-download";

const basePage = function () {
  return <Redirect exact from={"/"} to={"/"} />;
};

const FriendlyDownloads = ({ baseUrl, authority }) => {
  const FRIENDLY_VIEW_MENU_ITEMS = {
    Downloads: [
      {
        name: "Home",
        relativePath: "/",
        subPageComponent: basePage,
        hide: true,
        exact: true,
        includePackagePane: true,
      },

      {
        name: "Friendly Download",
        relativePath: `/:id`,
        subPageComponent: FriendlyDownload,
        hide: true,
        includePackagePane: false,
        navbarVisibility: false,
      },
    ],
  };
  return (
    <PageTemplate baseUrl={baseUrl} menuItems={FRIENDLY_VIEW_MENU_ITEMS} />
  );
};

export default FriendlyDownloads;
