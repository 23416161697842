import { InputField, StatusField, TextareaField } from "@/common/Form";
import React from "react";
import Moment from "react-moment";
import { Card, Form, Grid, Message, Checkbox } from "semantic-ui-react";
import moment from "moment";
import { dateToPacific } from "@/utils/dateTime";

export default function ({
  onChange,
  name,
  description,
  parentId,
  status,
  validationMsg,
  editMode,
  metaInfo,
  publicPackage
}) {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={11} computer={11} largeScreen={11}>
          <InputField
            fluid
            isRequired
            label="Package Name"
            name="name"
            value={name}
            errorMsg={validationMsg.name}
            isError={validationMsg.name}
            onChange={onChange}
            maxlength="32"
          />
          <TextareaField
            label="Description (maximum 100 characters allowed)"
            name="description"
            value={description}
            onChange={onChange}
            errorMsg={validationMsg.description}
            isError={validationMsg.description}
            maxLength={100}
          />
          <Form.Field>
            <StatusField
              value={status}
              label="Status"
              errorMsg={validationMsg.status}
              isError={validationMsg.status}
              name="status"
              onChange={onChange}
              isRequired
            />
          </Form.Field>
          <Checkbox
              name="publicPackage"
              label="Mark Package as Public"
              value={!publicPackage}
              checked={publicPackage}
              onChange={onChange}
            />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={5} computer={5} largeScreen={5}>
          {metaInfo && (
            <Card className="mb-1">
              <Card.Content
                className="dark-blue-background-shade"
                header={
                  <React.Fragment>
                    <Card.Header>Item Information</Card.Header>
                  </React.Fragment>
                }
              />
              <Card.Content>
                <Message style={{ fontSize: "11px" }}>
                  {metaInfo.createdBy && (
                    <Message.Content style={{ marginBottom: "10px" }}>
                      <Message.Header>Created By: </Message.Header>
                      <div style={{ marginLeft: "5px" }}>
                        {metaInfo.createdBy}
                      </div>
                    </Message.Content>
                  )}
                  {metaInfo.creationDate && (
                    <Message.Content style={{ marginBottom: "10px" }}>
                      <Message.Header>Created On: </Message.Header>
                      <div style={{ marginLeft: "5px" }}>
                        {dateToPacific(
                          metaInfo.creationDate,
                          "MM/DD/YYYY hh:mm a"
                        )}
                      </div>
                    </Message.Content>
                  )}
                  {metaInfo.modifiedBy && (
                    <Message.Content style={{ marginBottom: "10px" }}>
                      <Message.Header>Modified By: </Message.Header>
                      <div style={{ marginLeft: "5px" }}>
                        {metaInfo.modifiedBy}{" "}
                      </div>
                    </Message.Content>
                  )}
                  {metaInfo.modifiedDate && (
                    <Message.Content style={{ marginBottom: "10px" }}>
                      <Message.Header>Modification Date..: </Message.Header>
                      <div style={{ marginLeft: "5px" }}>
                        {dateToPacific(
                          metaInfo.modifiedDate,
                          "MM/DD/YYYY hh:mm a"
                        )}
                      </div>
                    </Message.Content>
                  )}
                </Message>
              </Card.Content>
            </Card>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
