import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { merge } from "lodash";
import { CardviewMobile } from "@/common/cardview";
import {
  Container,
  Divider,
  Dropdown,
  Modal,
  Segment,
  Confirm,
  Grid,
  Responsive,
} from "semantic-ui-react";
import { parameterStringify } from "../utils/utils";
import Tableview from "../tableview";
import Api from "../../api";

class ListingWithSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteWarning: false,
      data: { content: [], number: 0 },
      errorMsg: "",
      showError: false,
      loading: false,
      selectedItems: [],
      urlParams: Object.assign(
        {
          [props.searchTermKey]: "",
          [props.dropdown.key]: props.dropdown.value,
        },
        props.defaultParams
      ),
      columns:[],
      isDescSearch: true,
      sortBy: "",
    };

    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    const { columns } = this.props;
    this.state.columns = columns.map(obj=> (
      obj.key!=="type" && obj.key!=="actions"?
      { ...obj,  cb: () => {
       this.sortByHeader(obj.key, obj.name);
    }, }: obj
    ));
    this.refreshList();
  }

  sortByHeader = (key, title) => {
    key = key === "modification_date"?"modificationDate":key;
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.state.columns = this.state.columns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.refreshList();
    });
   
  };

  refreshList = () => {
    this.setState({ loading: true });

    let { urlParams } = this.state;
    const { fetchUrl } = this.props;
    urlParams = merge(urlParams,{sort:this.state.sortBy}) 
    let parameterizedUrl = fetchUrl + "?" + parameterStringify(urlParams);

    Api.AxiosInstance.getInstance()
      .get(parameterizedUrl)
      .then((resp) => {
        const { dataMapperFunction } = this.props;
        let transformedData = resp.data;
        if (dataMapperFunction instanceof Function)
          transformedData = dataMapperFunction(resp.data);
        this.setState({ data: transformedData, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false }, console.log(err));
      });
  };

  validateSelection = (cb) => {
    const { selectedItems } = this.state;
    if (selectedItems.length === 0) {
      this.setState({ errorMsg: "No data was selected.", showError: true });
    } else {
      if (cb instanceof Function) cb();
    }
  };

  showDeleteWarning = () => {
    this.setState({ deleteWarning: true });
  };

  hideDeleteWarning = (cb) => {
    const { extraAction } = this.props;
    if (extraAction.action === "delete") {
      this.setState({ deleteWarning: false }, () => {
        if (cb instanceof Function) cb();
      });
    }
  };

  doExtraAction = () => {
    const { extraAction } = this.props;
    const { selectedItems } = this.state;

    selectedItems.map((item) => {
      Api.AxiosInstance.getInstance()
        [extraAction.action](extraAction.url + "?id=" + item.id)
        .then((resp) => {
          this.refreshList();
        })
        .catch((err) => {
          this.setState({
            errorMsg: "Something went wrong while deleting the data.",
            showError: true,
          });
        });
    });
  };

  onSearchTyping = (data) => {
    const { searchTermKey } = this.props;
    const { urlParams } = this.state;
    this.setState({
      urlParams: update(urlParams, {
        [searchTermKey]: { $set: data.target.value },
      }),
    });
  };

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.refreshList();
    }
  }

  onItemsSelection = (event) => {
    this.setState({ selectedItems: event.data });
  };

  onSearchStatusChange = (event, data) => {
    const { dropdown } = this.props;
    const { urlParams } = this.state;
    this.setState({
      urlParams: update(urlParams, { [dropdown.key]: { $set: data.value } }),
    });
  };

  onPageChange = (curPage) => {
    const { urlParams } = this.state;
    this.setState(
      { urlParams: update(urlParams, { page: { $set: curPage.page } }) },
      this.refreshList
    );
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  render() {
    const {
      columns,
      dropDownOptions,
      dropdown,
      extraAction,
      title,
      menuButtons,
      responsive_columns,
    } = this.props;
    const { data, errorMsg, showError, loading } = this.state;

    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" />
                  {title}
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="control-action-wrapper">
                  {menuButtons}
                  {extraAction && extraAction.action === "delete" && (
                    <React.Fragment>
                      <button
                        className="button-danger"
                        onClick={() =>
                          this.validateSelection(this.showDeleteWarning)
                        }
                      >
                        {" "}
                        {extraAction.text}
                      </button>

                      <Confirm
                        open={this.state.deleteWarning}
                        onCancel={this.hideDeleteWarning}
                        onConfirm={() =>
                          this.hideDeleteWarning(this.doExtraAction)
                        }
                        content="Are you sure you want to delete this project?"
                      />
                    </React.Fragment>
                  )}
                  {extraAction && extraAction.action !== "delete" && (
                    <button
                      className="button-primary"
                      onClick={() => this.validateSelection(this.doExtraAction)}
                    >
                      {" "}
                      {extraAction.text}
                    </button>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row className="common-filter-wrapper ui form">
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div class="field">
                  <div class="ui input">
                    <input
                      placeholder="Search"
                      className="search-bar"
                      onChange={this.onSearchTyping}
                      onKeyPress={this.onKeyUp}
                    ></input>
                  </div>
                </div>
              </Grid.Column>
              {dropDownOptions && (
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div class="field">
                    <div class="ui input">
                      {/* {dropDownOptions && ( */}
                      <Dropdown
                        options={dropDownOptions}
                        selection
                        defaultValue={dropdown.value}
                        onChange={this.onSearchStatusChange}
                        placeholder={dropdown.placeholder}
                      />
                    </div>
                  </div>
                </Grid.Column>
              )}

              <Grid.Column mobile={16} tablet={6} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <button
                      onClick={this.refreshList}
                      className="button-primary m--no-margin-left-im"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                <div className="table-wrapper-with-pagination">
                  <Tableview
                    columns={this.state.columns}
                    data={data}
                    loading={loading}
                    paginate
                    // selectionType="multi-select"
                    onPaginationChange={this.onPageChange}
                    onSelectionChange={this.onItemsSelection}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

ListingWithSearch.propTypes = {
  /** Names of the columns */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      sort: PropTypes.bool,
      isDate: PropTypes.bool,
    })
  ).isRequired,
  responsive_columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      sort: PropTypes.bool,
      isDate: PropTypes.bool,
    })
  ).isRequired,
  /** Options which will be displayed in search dropdown */
  dropDownOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /** One extra action can be proformed on the table items. Like `delete` */
  extraAction: PropTypes.shape({
    /** HTTP verb */
    action: PropTypes.string,
    /** Text to be displayed on that button */
    text: PropTypes.string,
    /** URL this component will use to perform that action */
    url: PropTypes.string,
  }),
  /** URL this component will use to fetch latest data */
  fetchUrl: PropTypes.string.isRequired,
  /** Text which will be displayed at the top of the page. */
  title: PropTypes.string.isRequired,
  /** This key wil be used in urlParams as a `fitler` key */
  searchTermKey: PropTypes.string,
  /** This key wil be used in urlParams as a `status` key */
  dropdownConfig: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  /** Transforms the API response data before rendering it. */
  dataMapperFunction: PropTypes.func,
  /** Passes some default params for url building. */
  defaultParams: PropTypes.object,
  /** optional menu buttons which will displayed next to `ExtraAction` button. */
  menuButtons: PropTypes.arrayOf(PropTypes.node),
};

ListingWithSearch.defaultProps = {
  searchTermKey: "filter",
  dropdown: {
    key: "type",
    value: "",
    placeholder: "Type",
  },
  defaultParams: {},
};

export default ListingWithSearch;
