import Login from "@/modules/login";
import ForgetPassword from "@/modules/forgetpassword";
import Dashboard from "@/modules/dashboard";
import Registration from "@/modules/registration";
import Products from "@/modules/products";
import FriendlyDownloads from "@/modules/friendly-download";
import Projects from "@/modules/projects";
import Support from "@/modules/support";
// import Productsearch from "@/modules/productsearch";
import Profile from "@/modules/profile";
import Admin from "@/modules/admin";
import Briefcase from "@/modules/briefcase";
import Licenses from "@/modules/licenses";
import SLA from "@/modules/SLA";
import SWEL from "@/modules/SWEL";
import NDA from "@/modules/NDA";
import Reports from "@/modules/reports";
import Videos from "@/modules/videos";
import Search from "@/modules/globalsearch";
import NewTicket from "@/modules/newticket";
import Logout from "@/modules/logout";
import Notifications from "@/modules/notifications";
import Unsubscribe from "@/modules/unsubscribe";

// import Packages from "@/modules/products";

const routes = [
  ForgetPassword.loginAssistance,
  ForgetPassword.resetPassword,
  //ForgetPassword.authRoute,
  Registration,
  Dashboard,
  Products,
  Support,
  FriendlyDownloads,
  Projects,  
  Videos,
  Licenses,
  NDA.createRoute,
  NDA.publicRoute,
  NDA.navigationalRoutes,
  Briefcase,
  Profile,
  Reports,
  Admin,
  Login,
  Search,
  Notifications,
  //NewTicket,
  Logout,
  Unsubscribe,
];

export default routes;
