const normalValidation = {
  name: {
    presence: true,
    length: {
      maximum: 50
    }
  },
  image: {
    presence: true
  }
};

export { normalValidation };
