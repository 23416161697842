import { CardviewMobile } from "@/common/cardview";
import Tableview from "@/common/tableview";
import UserListing from "@/common/user-selection/user-listing";
import { removeEmptyKeys } from "@/common/utils/utils";
import Api from "@api";
import PropTypes from "prop-types";
import React from "react";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import { dateToPacific } from "@/utils/dateTime";
import {
  Divider,
  Dropdown,
  Grid,
  Loader,
  Responsive,
  Segment,
} from "semantic-ui-react";

class BriefcaseReportsListing extends React.Component {
  Constants = {
    TABLE_COLUMNS: Object.freeze([
      { name: "Sender Name", key: "senderName",showTooltip: true, cb: () => {
        this.sortByHeader("user2.username", "Sender Name");
      }, },
      { name: "Recipient Name", key: "recipientName",showTooltip: true, cb: () => {
        this.sortByHeader("user1.username", "Recipient Name");
      }, },
      { name: "Subject", key: "subject",showTooltip: true, cb: () => {
        this.sortByHeader("briefcaseItem.subject", "Subject");
      }, },
      { name: "Status", key: "status",showTooltip: true, cb: () => {
        this.sortByHeader("status", "Status");
      }, },
      { name: "Sent Date", key: "sentDate", isDate: true ,showTooltip: true, cb: () => {
        this.sortByHeader("briefcaseItem.sendDate", "Sent Date");
      },},
      { name: "File Names", key: "filenames",showTooltip: true, },
      // { name: "File Type", key: "fileType", class: "center" }
    ]),
    RESPONSIVE_COLUMNS: Object.freeze([
      { name: "Sender Name", key: "senderName" },
      { name: "Recipient Name", key: "recipientName" },
      { name: "Subject", key: "subject" },
      { name: "Status", key: "status" },
      { name: "Sent Date", key: "sentDate", isDate: true },
      { name: "File Names", key: "filenames" },
      // { name: "File Type", key: "fileType", class: "center" }
    ]),
    FIELD_TYPE: Object.freeze([
      { text: "Sender", key: "sender", value: "sender" },
      { text: "Recipient", key: "recipient", value: "recipient" },
    ]),
    FILE_STATUS_TYPE: Object.freeze([
      { text: "Viewed", key: "viewed", value: "VIEWED" },
      { text: "Not-Viewed", key: "un-viewed", value: "NOT_VIEWED" },
      { text: "Downloaded", key: "downlaoded", value: "DOWNLOADED" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      data: { content: [] },
      errorMsg: "",
      selectedItems: [],
      showError: false,
      // need to check with pagination:
      page: 0,
      deleteWarning: false,
      showUsersListing: false,
      userId: "",
      field: "sender",
      username: "",
      status: null,
      prepareDownload: false,
      readyDownload: false,
      errorDownload: false,
      isDescSearch: true,
      sortBy: "briefcaseItem.sendDate,DESC",
    };
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    this.getBriefcaseListing();
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.getBriefcaseListing();
    }
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.TABLE_COLUMNS = this.Constants.TABLE_COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getBriefcaseListing();
    });
   
  };

  getBriefcaseListing = () => {
    const { field, page, username, status } = this.state;

    // Username should be sufficient i guess (no need to add userId)
    let fieldOptions = {};
    if (field === "sender") {
      fieldOptions = {
        senderName: username,
        status,
      };
    } else if (field === "recipient") {
      fieldOptions = {
        recipientName: username,
        status,
      };
    } else {
      fieldOptions = {
        senderName: username,
        recipientName: username,
        status,
      };
    }

    let body = removeEmptyKeys(fieldOptions);

    let params = {
      params: {
        page,
        sort: this.state.sortBy,
      },
    };

    this.getData(
      body,
      params,
      (resp) => {
        this.setState({
          data: this.dataMapper(resp.data),
          errorDownload: false,
          prepareDownload: false,
          readyDownload: false,
        });
      },
      (err) => {
        this.setState({
          data: { content: [] },
          errorDownload: false,
          prepareDownload: false,
          readyDownload: false,
        });
      }
    );
  };

  getData = (body, params, success, failure) => {
    const { fetchUrl } = this.props;
    Api.AxiosInstance.getInstance()
      .post(fetchUrl, body, params)
      .then(success)
      .catch(failure);
  };

  dataMapper = (data) => {
    const { field } = this.state;
    let transformedData = Object.assign({}, data);
    let content = [];
    // add changes if needed, like item or file types etc
    transformedData.content.map((el) => {
      let row = Object.assign({}, el);
      // TODO: need to add condition for multiple files as well
      row.filenames =
        el.filenames.length === 0 ? (
          <i>File not available</i>
        ) : el.filenames.length > 1 ? (
          <i>Multiple files</i>
        ) : (
          el.filenames[0]
        );
      row.sentDate = <>{dateToPacific(row.sentDate, "MM/DD/YYYY hh:mm a")} </>;
      content.push(row);
    });
    transformedData.content = content;
    return transformedData;
  };
  onSelectUser = (e) => {
    e.preventDefault();
    this.setState({ showUsersListing: true });
  };
  onSaveUserListing = (user) => {
    user.map((data) =>
      this.setState({
        showUsersListing: false,
        userId: data.id,
        username: data.username,
      })
    );
  };
  onDismissUserListing = () => {
    this.setState({ showUsersListing: false });
  };

  download = (e, id) => {
    e.preventDefault();
    //TODO : add whatever we need to do for downloading csv files...
    const {
      data,
      field,
      page,
      username,
      status,
      prepareDownload,
      readyDownload,
      errorDownload,
    } = this.state;

    if (!prepareDownload && !errorDownload && !!readyDownload) {
      window.open(readyDownload);
      return;
    }

    if (!data || !data.content || !data.content.length) {
      alert("No data present!");
      return;
    }

    // Username should be sufficient i guess (no need to add userId)
    let fieldOptions = {};
    if (field === "sender") {
      fieldOptions = {
        senderName: username,
        status,
      };
    } else if (field === "recipient") {
      fieldOptions = {
        recipientName: username,
        status,
      };
    } else {
      fieldOptions = {
        senderName: username,
        recipientName: username,
        status,
      };
    }

    let body = removeEmptyKeys(fieldOptions);

    let params = {
      timeout: 0,
      params: {
        download: 1,
        page,
        sort: "briefcaseItem.sendDate,DESC",
      },
    };

    this.setState({
      prepareDownload: true,
    });
    this.getData(
      body,
      params,
      //success
      (resp) => {
        this.setState({
          prepareDownload: false,
          readyDownload: resp.data,
        });
      },
      //error
      (err) => {
        //Temporary Hack
        this.setState({
          prepareDownload: false,
          errorDownload: "Unable to prepare file for download.",
        });
      }
    );
  };

  onSearchTyping = (event, data) => {
    this.setState({
      username: event.target.value,
    });
  };

  onPageChange = (e) => {
    const { urlParams } = this.state;
    this.setState(
      {
        page: e.page,
      },
      this.getBriefcaseListing
    );
  };

  onItemsSelection = (event) => {
    this.setState({ selectedItems: event.data });
  };

  onDropDownChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  clearState = (e) => {
    //Temporary hack: need to do something else
    this.setState(
      {
        userId: "",
        username: "",
        field: "sender",
        status: null,
        // data: { content: [] },
      },
      this.getBriefcaseListing
    );
  };

  render() {
    const { title } = this.props;
    const {
      username,
      field,
      status,
      data,
      errorMsg,
      showError,
      prepareDownload,
      readyDownload,
      errorDownload,
    } = this.state;

    const disableDownloadButton = prepareDownload || errorDownload;

    const { FIELD_TYPE, TABLE_COLUMNS, FILE_STATUS_TYPE, RESPONSIVE_COLUMNS } =
      this.Constants;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={10} tablet={10} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" />
                  {title}
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={6}
                tablet={6}
                computer={8}
                largeScreen={8}
                className="m--align-right"
              >
                <button
                  className="button-basic "
                  title="Click to Download"
                  disabled={disableDownloadButton}
                  onClick={this.download}
                >
                  {!prepareDownload && !readyDownload && "Download"}
                  {!!prepareDownload && !readyDownload && (
                    <Loader>Loading</Loader>
                  )}
                  {!!prepareDownload && !readyDownload && "Preparing File"}
                  {!prepareDownload &&
                    !!readyDownload &&
                    "File Ready! Download Now!"}
                </button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="ui form">
              <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <input
                      placeholder="User Name"
                      className="search-bar"
                      onChange={this.onSearchTyping}
                      value={username}
                      onKeyPress={this.onKeyUp}
                    ></input>
                  </div>
                  <a
                    href="#"
                    target="_blank"
                    onClick={(e) => this.onSelectUser(e)}
                  >
                    Select User
                  </a>
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <Dropdown
                      options={FIELD_TYPE}
                      selection
                      defaultValue={field}
                      placeholder="In"
                      onChange={(event, data) =>
                        this.onDropDownChange("field", data.value)
                      }
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <Dropdown
                      options={FILE_STATUS_TYPE}
                      selection
                      defaultValue={status}
                      placeholder="Status"
                      onChange={(event, data) =>
                        this.onDropDownChange("status", data.value)
                      }
                    />
                  </div>
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <button
                      onClick={this.getBriefcaseListing}
                      className="button-primary m--no-margin-left-im"
                    >
                      Search
                    </button>
                    <button
                      onClick={(e) => this.clearState(e)}
                      className="button-basic"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Divider />
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination">
                    <Tableview
                      columns={TABLE_COLUMNS}
                      data={data}
                      paginate
                      onPaginationChange={this.onPageChange}
                    />
                  </div>
                </Responsive>
                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    data={data}
                    columns={RESPONSIVE_COLUMNS}
                    paginate
                    onPaginationChange={(e) => this.onPageChange(e)}
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <UserListing
          show={this.state.showUsersListing}
          onDismiss={this.onDismissUserListing}
          onSave={this.onSaveUserListing}
          selectionType="single-select"
          title="List of Customers"
        />
      </React.Fragment>
    );
  }
}

BriefcaseReportsListing.propTypes = {
  /** URL this component will use to fetch latest data */
  fetchUrl: PropTypes.string.isRequired,
  /** Text which will be displayed at the top of the page. */
  title: PropTypes.string.isRequired,
};

export default withRouter(BriefcaseReportsListing);
