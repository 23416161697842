import React from 'react';
import { size as _size } from 'lodash';
import { Link } from "react-router-dom";
import {
  Grid,
} from 'semantic-ui-react';
import NotificationsRow from './notifications-row';

const NotificationsContent = (props) => {
  const {
    recent,
    earlier,
    state,
    markAsSeen,
    markAsUnseen,
    markAllAsSeen,
    showAllNotificationsLink,
    onClickAllNotifications
  } = props;

  const hasRecent = _size(recent) > 0 ?? false;
  const hasEarlier = _size(earlier) > 0 ?? false;

  const markSeen = (row) => {
    markAsSeen([row.id]);
  }

  const markUnseen = (row) => {
    markAsUnseen([row.id]);
  }

  return (
    <>
      <Grid >
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <div className="header">
              <div className="side-marker" />
              Notifications
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={16} className='notification-wrapper'>
            <Grid className='mt-2-im'>
              <Grid.Row className='sort-heading p-0'>
                <Grid.Column mobile={4} className='sort-by-type'>
                  <span>Recent</span>
                </Grid.Column>
                <Grid.Column mobile={12} className="mark-action">
                  <Link to="#"
                    onClick={markAllAsSeen}
                  >Mark all as seen </Link>
                  {showAllNotificationsLink
                    && <> |
                      <Link
                        to={state.baseUrl + '/'}
                        onClick={onClickAllNotifications}> All Notification</Link>
                    </>}
                </Grid.Column>
              </Grid.Row>
              {hasRecent && <Grid.Row className='p-0'>
                <Grid.Column computer={16}>
                  {hasRecent && recent.map((row) => <NotificationsRow {...row}
                    markSeen={markSeen} markUnseen={markUnseen} />)}
                </Grid.Column>
              </Grid.Row>}
              {!hasRecent && <strong>No Notifications to display!</strong>}
            </Grid>

            {hasEarlier && <Grid className='mt-2-im'>
              <Grid.Row className='sort-heading p-0'>
                <Grid.Column mobile={8} className='sort-by-type'>
                  <span>Earlier</span>
                </Grid.Column>
                <Grid.Column mobile={8} className="mark-action">

                </Grid.Column>
              </Grid.Row>
              {hasEarlier && <Grid.Row className='p-0'>
                <Grid.Column computer={16}>
                  {hasEarlier && earlier.map((row) => <NotificationsRow {...row}
                    markSeen={markSeen} markUnseen={!!markAsUnseen ? markUnseen : null} />)}
                </Grid.Column>
              </Grid.Row>}
            </Grid>} 
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default NotificationsContent;