import Api from "@/api";
import { CardviewMobile } from "@/common/cardview";
import Tableview from "@/common/tableview";
import { parameterStringify } from "@/common/utils/utils";
import { default as Config, default as urls } from "@/config/constants";
import Constants from "@constants";
import common from "common-prefix";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { dateToPacific } from "@/utils/dateTime";
import { withRouter } from "react-router-dom";
import {
  Accordion,
  Confirm,
  Divider,
  Grid,
  Icon,
  Modal,
  Responsive,
  Segment,
} from "semantic-ui-react";
import FileIcon from "../../../../common/file-icon";
import BriefcaseDownload from "./download-briefcase";
import ItemInfo from "./info-item";
import BriefcaseResend from "./resend-briefcase";
import BriefcaseSwitch from "./switch-briefcase";
import { connect } from "react-redux";
import { authoritySelector, userIdSelector } from "@/common/selectors";
import { get } from "lodash";

const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;

class BriefcaseListing extends React.Component {
  Constants = {
    MODAL_TYPE: Object.freeze({
      NONE: -1,
      DOWNLOAD: 0,
      RESEND: 1,
      SWITCH: 2,
      INFO: 3,
    }),
    DEFAULT_STATE: {
      documents: { content: [], number: 0 },
      downloadLinks: [],
      errorMsg: "",
      selectedItems: [],
      showError: false,
      modalType: "NONE",
      recipients: null,
      page: 0,
      deleteWarning: false,
      password: "",
      onError: false,
      isDescSearch: true,
      sortBy: "",
      columns: [],
      selectedStatedBriefcase: [],
      selectedBriefcaseIndexes: [],
      checkedBriefcaseIds: [],
      isCollapsed: false,
      modelTypeMap: {},
    },
  };

  constructor(props) {
    super(props);
    this.state = this.Constants.DEFAULT_STATE;
  }

  componentDidMount() {
    const { columns } = this.props;
    this.state.columns = columns.map((obj) =>
      obj.key !== "fileType" && obj.key !== "actionButtons"
        ? // obj.key !== "filename"
          {
            ...obj,
            cb: () => {
              this.sortByHeader(obj.sortKey, obj.name);
            },
          }
        : obj
    );
    this.refreshListing();
  }

  refreshListing = () => {
    const { fetchUrl, urlParam } = this.props;
    const { page } = this.state;
    const instance = Api.AxiosInstance.getInstance();
    instance
      .get(
        fetchUrl +
          "?" +
          parameterStringify({
            userId: urlParam.id,
            page: page,
            sort: this.state.sortBy,
          })
      )
      .then((resp) => {
        this.setState({ documents: this.transformData(resp.data) });
      });
  };
  showDeleteWarning = () => {
    const { selectedItems, checkedBriefcaseIds } = this.state;
    if (!checkedBriefcaseIds.length) {
      this.setState({
        showError: true,
        errorMsg:
          "No item selected for deletion. Please select one or more items.",
      });
    } else {
      this.setState({ deleteWarning: true });
    }
  };
  hideDeleteWarning = (cb) => {
    this.setState({ deleteWarning: false }, () => {
      if (cb instanceof Function) cb();
    });
  };
  transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    transformedData.content.map((el) => {
      let row = Object.assign({}, el);

      if (ADMIN_ROLES.indexOf(this.props.authority) >= 0) {
        row.actionButtons = (
          <React.Fragment>
            <span
              className="icon-download"
              title="Download"
              onClick={() =>
                this.doDownload(
                  row.files,
                  row.id,
                  row.watermarkEnabled ? row.watermarkEnabled : false,
                  row.modelTypeMap,
                )
              }
            >
              {" "}
            </span>
            <ItemInfo file={el} viewCallBack={() => this.markViewed(el.id)} modelTypeMap={row.modelTypeMap} />
          </React.Fragment>
        );
      } else {
        const todayDate = dateToPacific(new Date(), "YYYY-MM-DD");

        const isAvailable =
          row.expireDate === null
            ? true
            : moment(todayDate).isSameOrBefore(row.expireDate);

        // eslint-disable-next-line no-lone-blocks
        {
          isAvailable
            ? (row.actionButtons = (
                <React.Fragment>
                  <span
                    className="icon-download"
                    title="Download"
                    onClick={() =>
                      this.doDownload(
                        row.files,
                        row.id,
                        row.watermarkEnabled ? row.watermarkEnabled : false,
                        row.modelTypeMap,
                      )
                    }
                  >
                    {" "}
                  </span>
                  <ItemInfo
                    file={el}
                    viewCallBack={() => this.markViewed(el.id)}
                    modelTypeMap={row.modelTypeMap}
                  />
                </React.Fragment>
              ))
            : (row.actionButtons = (
                <React.Fragment>
                  <span> Expired.</span>
                  <ItemInfo
                    file={el}
                    viewCallBack={() => this.markViewed(el.id)}
                    modelTypeMap={row.modelTypeMap}
                  />
                </React.Fragment>
              ));
        }
      }
      // row.actionButtons = (
      //   <React.Fragment>
      //     <span
      //       className="icon-download"
      //       title="Download"
      //       onClick={() => this.doDownload(row.files, row.id)}
      //     >
      //       {" "}
      //     </span>
      //     <ItemInfo file={el} viewCallBack={() => this.markViewed(el.id)} />
      //   </React.Fragment>
      // );

      // row.sendDate = <Moment format="MMMM Do YYYY hh:mm">{el.sendDate}</Moment>;
      row.sendDate = dateToPacific(el.availableDate, "MM/DD/YYYY");
      row.expireDate = row.expireDate
        ? dateToPacific(get(row, ["expireDate"]), "MM/DD/YYYY")
        : "NEVER EXPIRE";
      //using dateToPacific func causes error
      // row.sendDate = <Moment format="MM/DD/YYYY">{el.availableDate}</Moment>;
      row.fileType =
        el.files.length === 0 ? (
          <i>-</i>
        ) : (
          <FileIcon
            filename={el.files.length > 1 ? "other" : el.files[0].filename}
            mimetype={el.files.length > 1 ? "multiple" : el.files[0].filetype}
          ></FileIcon>
        );

      row.filename = (
        <span
          title=""
          style={{ cursor: "pointer" }}
          onClick={() =>
            this.doDownload(
              row.files,
              row.id,
              row.watermarkEnabled ? row.watermarkEnabled : false,
              row.modelTypeMap,
            )
          }
        >
          {el.files.length === 0 ? (
            <i>File not available</i>
          ) : el.files.length > 1 ? (
            <i>Multiple files</i>
          ) : (
            el.files[0].filename
          )}
        </span>
      );

      row.subject = (
        <ItemInfo
          file={el}
          tag={
            <span
              style={{ cursor: "pointer" }}
              onClick={() => this.markViewed(el.id)}
            >
              {row.subject}
            </span>
          }
          modelTypeMap={row.modelTypeMap}
        />
      );
      content.push(row);
    });
    transformedData.content = content;
    return transformedData;
  };

  markViewed = (id) => {
    const { URL_BRIEFCASE_VIEWED } = urls.App;
    Api.AxiosInstance.getInstance()
      .get(`${URL_BRIEFCASE_VIEWED}?id=${id}`)
      .then((resp) => {
        this.refreshListing();
      })
      .catch((err) => {});
  };
  isPasswordProtected = (id) => {
    let params = {
      params: {
        id,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(`briefcase/isProtected`, params)
      .then((resp) => {
        this.setState({
          isProtected: resp.data,
          verified: false,
          onError: false,
        });
      })
      .catch();
  };
  onPasswordChange = (e, data) => {
    this.setState({
      password: data.value,
    });
  };
  verifyPassword = () => {
    const { password } = this.state;
    let params = {
      params: {
        password,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(`briefcase/verifyPassword`, params)
      .then((resp) => {
        // if password matches then change isProtected to false

        if (resp.data === true) {
          this.setState({
            verified: resp.data,
            onError: false,
          });
        } else {
          this.setState({
            onError: true,
            verified: resp.data,
          });
        }
      })
      .catch();
  };
  doDownload = (files, id, watermarkEnabled, modelTypeMap) => {
    const { URL_BRIEFCASE_DOWNLOAD } = urls.App;
    const { MODAL_TYPE } = this.Constants;
    const { referer } = this.props;
    let links = [];
    // this.isPasswordProtected(id);
    Promise.all(
      files.map(
        (file) =>
          new Promise((resolve, reject) => {
            Api.AxiosInstance.getInstance()
              .get(
                `${URL_BRIEFCASE_DOWNLOAD}?entityId=${id}&id=${file.id}&watermarkEnabled=${watermarkEnabled}&fileType=${file.filetype}&for=${referer}`
              )
              .then((resp) => {
                console.log("file", file);
                let basePath = `${Constants.App.API_URL}briefcasefile/`;

                links.push({
                  id: file.id,
                  filename: file.filename,
                  link: resp.data.downloadLink,
                  wgetCommand: `wget ${basePath}${resp.data.command} -O ${file.filename}`,
                  curlCommand: `curl ${basePath}${resp.data.command} -o ${file.filename} -D-`,
                  directLink: resp.data.directLink,
                });
                resolve();
              })
              .catch((err) => resolve());
          })
      )
    ).then(() => {
      if (links.length > 0) {
        this.setState({ modalType: MODAL_TYPE.DOWNLOAD, downloadLinks: links, modelTypeMap: modelTypeMap });
      } else {
        this.setState({
          showError: true,
          errorMsg: "An error occured while downloading the file(s).",
        });
      }
    });
  };

  onItemSelection = (e) => {
    //this.setState({ selectedItems: e.data });
    let { checkedBriefcaseIds } = this.state;
    if (!e.changedContent) return;
    if (e.changedValue) {
      const alreadyPushed = checkedBriefcaseIds.find(
        (element) =>
          element ==
          e.changedContent.id + "_" + e.changedContent.recipients[0].userId
      );
      if (!alreadyPushed) {
        checkedBriefcaseIds.push(
          e.changedContent.id + "_" + e.changedContent.recipients[0].userId
        );
      }
    } else {
      checkedBriefcaseIds = checkedBriefcaseIds.filter((entity) => {
        return (
          entity !==
          e.changedContent.id + "_" + e.changedContent.recipients[0].userId
        );
      });
    }
    this.setState({
      checkedBriefcaseIds: checkedBriefcaseIds,
    });
  };

  onAllItemSelection = (selectedBriefcase, flag) => {
    let { checkedBriefcaseIds } = this.state;
    if (flag) {
      selectedBriefcase.map((briefcase) => {
        checkedBriefcaseIds = checkedBriefcaseIds.filter((entity) => {
          return entity !== briefcase.id + "_" + briefcase.recipients[0].userId;
        });
      });
    } else {
      selectedBriefcase.map((briefcase) => {
        if (briefcase) {
          const alreadyPushed = checkedBriefcaseIds.find(
            (element) =>
              element == briefcase.id + "_" + briefcase.recipients[0].userId
          );
          if (!alreadyPushed) {
            checkedBriefcaseIds.push(
              briefcase.id + "_" + briefcase.recipients[0].userId
            );
          }
        }
      });
    }
    this.setState({
      checkedBriefcaseIds: checkedBriefcaseIds,
    });
  };

  onPageChange = (e) => {
    this.setState({ page: e.page }, this.refreshListing);
  };

  onDelete = () => {
    const { selectedItems, checkedBriefcaseIds } = this.state;
    const { URL_BRIEFCASE_DELETE } = urls.App;

    Promise.all(
      checkedBriefcaseIds.map(
        (item) =>
          new Promise((resolve, reject) => {
            Api.AxiosInstance.getInstance()
              .delete(
                `${URL_BRIEFCASE_DELETE}?itemId=${item.split("_")[0]}&userId=${
                  item.split("_")[1]
                }`
              )
              .then((resp) => resolve())
              .catch((err) => reject());
          })
      )
    )
      .then(() => {
        this.setState({ checkedBriefcaseIds: [] }, this.refreshListing);
      })
      .catch((err) => {
        this.setState(
          {
            showError: true,
            errorMsg: "An error occured while deleting the item(s)",
            checkedBriefcaseIds: [],
          },
          this.refreshListing
        );
      });
  };

  onResend = () => {
    const { selectedItems, checkedBriefcaseIds } = this.state;
    const { MODAL_TYPE } = this.Constants;
    if (!selectedItems.length && !checkedBriefcaseIds.length) {
      this.setState({
        showError: true,
        errorMsg: "Please select one or more items from briefcase to reassign",
      });
    } else {
      this.setState({ modalType: MODAL_TYPE.RESEND });
    }
  };

  doResend = (users, sendEmail) => {
    const { URL_BRIEFCASE_RESEND } = urls.App;
    const { selectedItems, checkedBriefcaseIds } = this.state;
    const { MODAL_TYPE } = this.Constants;

    let userIds = [];
    users.map((user) => userIds.push({ userId: user.id }));

    Promise.all(
      checkedBriefcaseIds.map(
        (item) =>
          new Promise((resolve, reject) => {
            Api.AxiosInstance.getInstance()
              .post(
                `${URL_BRIEFCASE_RESEND}?itemId=${
                  item.split("_")[0]
                }&sendEmail=${sendEmail}`,
                {
                  recipients: userIds,
                }
              )
              .then((resp) => resolve())
              .catch((err) => reject(err));
          })
      )
    )
      .then(() => {
        this.setState(
          { modalType: MODAL_TYPE.NONE, checkedBriefcaseIds: [] },
          this.refreshListing
        );
      })
      .catch((error) => {
        console.log("error", error);
        this.setState(
          {
            modalType: MODAL_TYPE.NONE,
            showError: true,
            errorMsg: "Item which you want to send is Expired!",
            checkedBriefcaseIds: [],
          }
          // this.refreshListing
        );
      });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  doSwitch = (users) => {
    const { path, url } = this.props.match;
    const { MODAL_TYPE } = this.Constants;
    const baseUrl = common([path, url]);
    this.props.history.push(baseUrl + users[0].id);
    this.setState({ modalType: MODAL_TYPE.NONE }, this.refreshListing);
  };

  setModalType = (type) => {
    this.setState({ modalType: type });
  };

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.state.columns = this.state.columns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.refreshListing();
    });
  };

  render() {
    const {
      documents,
      downloadLinks,
      errorMsg,
      showError,
      modalType,
      selectedBriefcaseIndexes,
      checkedBriefcaseIds,
      isCollapsed,
      modelTypeMap,
    } = this.state;
    const { columns, isAdmin, title, userId, urlParam, paginate } = this.props;
    const { MODAL_TYPE } = this.Constants;

    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <Responsive minWidth={1024}>
                  <div className="side-marker" />
                  <div className="header">{title} </div>
                </Responsive>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <Responsive minWidth={1024}>
                  <div className="control-action-wrapper">
                    <button
                      onClick={this.showDeleteWarning}
                      className="button-primary mb-1"
                    >
                      Delete
                    </button>
                    {isAdmin && ADMIN_ROLES.indexOf(this.props.authority) >= 0 && (
                      <React.Fragment>
                        <button
                          onClick={this.onResend}
                          className="button-basic "
                        >
                          Resend to...
                        </button>
                        <button
                          className="button-basic "
                          onClick={() => this.setModalType(MODAL_TYPE.SWITCH)}
                        >
                          Switch...
                        </button>
                      </React.Fragment>
                    )}
                    {isAdmin && userId != urlParam.id && (
                      <button
                        className="button-basic"
                        onClick={() => {
                          this.doSwitch([{ id: userId }]);
                        }}
                      >
                        My Briefcase
                      </button>
                    )}

                    <Confirm
                      open={this.state.deleteWarning}
                      onCancel={this.hideDeleteWarning}
                      onConfirm={() => this.hideDeleteWarning(this.onDelete)}
                      content="Are you sure you want to delete this Item?"
                    />
                  </div>
                </Responsive>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="sp-case-row">
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination">
                    <Tableview
                      paginate={paginate}
                      selectionType="multi-select"
                      data={documents}
                      columns={this.state.columns}
                      onSelectionChange={this.onItemSelection}
                      onPaginationChange={this.onPageChange}
                      noDataMessage="No items in briefcase."
                      checkedIndexes={selectedBriefcaseIndexes}
                      checkedIds={checkedBriefcaseIds}
                      onAllSelectionChange={this.onAllItemSelection}
                      briefcaseSelection={true}
                    />
                  </div>
                </Responsive>

                <Responsive maxWidth={1023}>
                  <Accordion>
                    <Accordion.Title
                      onClick={(e) =>
                        this.setState({ isCollapsed: !isCollapsed })
                      }
                    >
                      <Icon
                        name={isCollapsed ? "caret right" : "caret down"}
                        color="blue"
                      />
                      <div className="side-marker" />
                      <div className="header">Received Files</div>
                    </Accordion.Title>

                    <Accordion.Content active={!isCollapsed}>
                      <div className="control-action-wrapper">
                        <button
                          onClick={this.showDeleteWarning}
                          className="button-primary mb-1"
                        >
                          Delete
                        </button>
                        {isAdmin &&
                          ADMIN_ROLES.indexOf(this.props.authority) >= 0 && (
                            <React.Fragment>
                              <button
                                onClick={this.onResend}
                                className="button-basic "
                              >
                                Resend to...
                              </button>
                              <button
                                className="button-basic "
                                onClick={() =>
                                  this.setModalType(MODAL_TYPE.SWITCH)
                                }
                              >
                                Switch...
                              </button>
                            </React.Fragment>
                          )}
                        {isAdmin && userId != urlParam.id && (
                          <button
                            className="button-basic"
                            onClick={() => {
                              this.doSwitch([{ id: userId }]);
                            }}
                          >
                            My Briefcase
                          </button>
                        )}

                        <Confirm
                          open={this.state.deleteWarning}
                          onCancel={this.hideDeleteWarning}
                          onConfirm={() =>
                            this.hideDeleteWarning(this.onDelete)
                          }
                          content="Are you sure you want to delete this Item?"
                        />
                      </div>
                      
                      <CardviewMobile
                        paginate
                        selectionType="multi-select"
                        data={documents}
                        columns={this.state.columns}
                        onSelectionChange={this.onItemSelection}
                        onPaginationChange={(e) => this.onPageChange(e)}
                        noDataMessage="No items in briefcase."
                        checkedIndexes={selectedBriefcaseIndexes}
                        checkedIds={checkedBriefcaseIds}
                        onAllSelectionChange={this.onAllItemSelection}
                        briefcaseSelection={true}
                      />
                    </Accordion.Content>
                  </Accordion>
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Responsive maxWidth={1024}></Responsive>
          {!paginate && (
            <React.Fragment>
              <Divider />
              <em>Showing recent {documents.content.length} records</em>
            </React.Fragment>
          )}
        </Segment>

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <BriefcaseResend
          show={modalType === MODAL_TYPE.RESEND}
          onClose={() => this.setModalType(MODAL_TYPE.NONE)}
          onResend={this.doResend}
        />
        <BriefcaseSwitch
          show={modalType === MODAL_TYPE.SWITCH}
          onClose={() => this.setModalType(MODAL_TYPE.NONE)}
          onSwitch={this.doSwitch}
        />
        <BriefcaseDownload
          show={modalType === MODAL_TYPE.DOWNLOAD}
          onClose={() => this.setModalType(MODAL_TYPE.NONE)}
          onDownload={this.refreshListing}
          files={downloadLinks}
          isProtected={this.state.isProtected}
          verifyPassword={this.verifyPassword}
          verified={this.state.verified}
          onPasswordChange={this.onPasswordChange}
          showError={this.state.onError}
          modelTypeMap={modelTypeMap}
        />
      </React.Fragment>
    );
  }
}

BriefcaseListing.propTypes = {
  /** columns name and their key. */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      sort: PropTypes.bool,
    })
  ).isRequired,
  /** Determins whether to show `Resend` and `Switch` buttons */
  isAdmin: PropTypes.bool,
  /** A URL which will be used for fetching data from the backend */
  fetchUrl: PropTypes.string.isRequired,
  /** title to be displayed above this component.  */
  title: PropTypes.string.isRequired,
  /** Contains browser specific parameters. */
  urlParam: PropTypes.object,
  /** Show Pagination? special case: hide pagination for dashbord */
  paginate: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
  authority: authoritySelector(state),
  // baseUrl: baseUrl,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
const MappedBriefcaseListing = connect(
  mapStateToProps,
  mapDispatchToProps
)(BriefcaseListing);

export default withRouter(MappedBriefcaseListing);
