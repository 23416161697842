import React from "react";
import { Segment, Grid, Responsive } from "semantic-ui-react";
import ApproveUserDialog from "./components/approve-users";
import TopDownloads from "./components/top-downloads";
import RecentDownloads from "./components/recent-downloads";
import RecentUploads from "./components/recent-uploads";
import BriefcaseInbox from "../briefcase/pages/briefcase-inbox";
import SearchLogs from "./components/search-logs";
import ZendeskTickets from "./components/zendesk-tickets";
import Articles from "./components/articles";

const AdminView = () => {
  return (
    <Segment basic className="mt-2-im app-dashboard-wrapper">
      <Grid >
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
            <TopDownloads />
            <RecentDownloads />
            <SearchLogs />
            <ZendeskTickets />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
            <ApproveUserDialog />
            <RecentUploads />
            <div className="dashboard-card">
            <Responsive minWidth={1024}>
              <BriefcaseInbox
                match={{ path: "", params: {}, paginate: false }}
              />
            </Responsive>
            <Responsive maxWidth={1023}>
               <BriefcaseInbox
                match={{ path: "", params: {}, paginate: false }}
              />
            </Responsive>
            </div>
            <Articles />
          </Grid.Column>
    
        </Grid.Row>
        
      </Grid>
    </Segment>
  );
};

export default AdminView;
