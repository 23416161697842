const normalValidations = {
  initiatorName: {
    presence: true,
    length: {
      maximum: 50,
    },
  },
  initiatorEmail: {
    presence: true,
    email: true,
  },
  initiatorPhone: {
    presence: { allowEmpty: false },
      length: {
        minimum: 14,
        message: "^number should not be less than 10 digits",
      },
  },
  infoBeingDisclosed: {
    presence: false,
    length: {
      maximum: 150,
    },
  },
  specificUsage: {
    presence: false,
  },
  effectiveDate: {
    presence: true,
  },
  expiryDate: {
    presence: true,
  },
  ndaDuration: {
    presence: true,
    numericality: true,
  },
  ndaFile: {
    presence: false,
  },
  contactEmail: {
    presence: true,
    email: true,
  },
  contactName: {
    presence: true,
  },
  signerName: {
    presence: false,
    length: {
      maximum: 50,
    },
  },
  signerTitle: {
    presence: false,
  },
  signerEmail: {
    presence: false,
    email: true,
  },
  signerPhone: {
    presence: { allowEmpty: false },
    length: {
      minimum: 14,
      message: "number should not be less than 10 digits",
    },
  },

  postCode: function (value, attributes){
  if(attributes.localpostalresult==true)
  {
      return;
  }
  else{
    return {
      format: {
        pattern: "",
        message:
          " format is invalid",
      }
    };

  }
  },
  address1: {
    presence: true,
  },
  city: { presence: true },
  country: { presence: true },
  state: { presence: false },
  principleBusiness: { presence: true },
  statusNotificationEmail: {
    presence: false,
    email: true,
  },
  status: {
    presence: true,
  },
};
const rejectionValidation = {
  rejectionReason: {
    presence: true,
  },
};

const acceptanceValidation = {
  companySignature: {
    presence: true,
  },
};

export { normalValidations, rejectionValidation, acceptanceValidation };
