import Api from "@/api";
import { InputField } from "@/common/Form";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import React from "react";
import { Form, Grid, Segment, Confirm } from "semantic-ui-react";
import { validate } from "validate.js";
import { 
        newuser_notification_email_to_validation,
        nda_notification_email_validation,
        normalValidation,
        account_assistance_notify_email_validation,
        additional_status_notification_email_validation
      } from "./../components/validations/email";

class EmailSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyValuePairs: [],
      mail_server_auth: false,
      newuser_notification_email: false,
      newuser_approval_email: false,
      newuser_rejection_email: false,
      newuser_request_received_email: false,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: "",
      msgSpanText: "",
      msgSpanColor: "black",
      itemLoadXHR: false,
      popupWarning: false,
    };
  }

  componentDidMount() {
    Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ADMIN_SETTINGS + "?type=MAIL")
      .then((resp) => {
        let data = resp.data;
        let content = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].value.length > 0) content[data[i].name] = data[i].value;
        }

        this.setState({
          keyValuePairs: content,
          mail_server_auth:
            content["mail_server_auth"] == "ENABLED" ? true : false,
          newuser_notification_email:
            content["newuser_notification_email"] == "ENABLED" ? true : false,
          newuser_approval_email:
            content["newuser_approval_email"] == "ENABLED" ? true : false,
          newuser_rejection_email:
            content["newuser_rejection_email"] == "ENABLED" ? true : false,
          newuser_request_received_email:
            content["newuser_request_received_email"] == "ENABLED"
              ? true
              : false,
        });
      });
  }

  save = () => {
    let options = {};
    let kpKeys = Object.keys(this.state.keyValuePairs);
    let kpVals = Object.values(this.state.keyValuePairs);
    for (var i = 0; i < kpKeys.length; i++) {
      options[kpKeys[i]] = kpVals[i];
    }
    // options["newuser_notification_email"] = document.getElementById(
    //   "newuser_notification_email"
    // ).checked
    //   ? "ENABLED"
    //   : "DISABLED";
    // options["mail_server_auth"] = document.getElementById("mail_server_auth")
    //   .checked
    //   ? "ENABLED"
    //   : "DISABLED";

    // options["newuser_approval_email"] = document.getElementById(
    //   "newuser_approval_email"
    // ).checked
    //   ? "ENABLED"
    //   : "DISABLED";
    // options["newuser_rejection_email"] = document.getElementById(
    //   "newuser_rejection_email"
    // ).checked
    //   ? "ENABLED"
    //   : "DISABLED";
    // options["newuser_request_received_email"] = document.getElementById(
    //   "newuser_request_received_email"
    // ).checked
    //   ? "ON"
    //   : "OFF";

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_SETTINGS_SAVE, options)
      .then((resp) => {
        let msg = "";
        let color = "black";
        if (resp.data && resp.data == "OK") {
          msg = "Settings updated";
          color = "green";
        } else {
          msg = "Request failed, Please try later.";
          color = "red";
        }

        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
        });
      });
  };

  showpopupWarning = () => {
    let result_nda_notification_email_validation, result_newuser_notification_email_to_validation, result_account_assistance_notify_email_validation, result_additional_status_notification_email_validation;
    const keyValuePairs = {...this.state.keyValuePairs};
    const nda_notification_email = keyValuePairs.nda_notification_email
    const newuser_notification_email_to = keyValuePairs.newuser_notification_email_to
    const account_assistance_notify_email = keyValuePairs.account_assistance_notify_email
    const additional_status_notification_email = keyValuePairs.additional_status_notification_email
    if(nda_notification_email!= null){
      nda_notification_email.split(",").every(element => {    
        keyValuePairs.nda_notification_email = element
        result_nda_notification_email_validation = validate(
            removeEmptyKeys(keyValuePairs),
            nda_notification_email_validation
          );
        return !result_nda_notification_email_validation;
      });

      if (result_nda_notification_email_validation) {
        this.setState({
          validationMsg: result_nda_notification_email_validation,
          msgSpanText:
            "There are some errors with the submission. Please fix and retry.",
          msgSpanColor: "red",
        });
        return;
      }

    }

    if(newuser_notification_email_to!= null){
      newuser_notification_email_to.split(",").every(element => {    
        keyValuePairs.newuser_notification_email_to = element
        result_newuser_notification_email_to_validation = validate(
            removeEmptyKeys(keyValuePairs),
            newuser_notification_email_to_validation
          );
        return !result_newuser_notification_email_to_validation;
      });

      if (result_newuser_notification_email_to_validation) {
        this.setState({
          validationMsg: result_newuser_notification_email_to_validation,
          msgSpanText:
            "There are some errors with the submission. Please fix and retry.",
          msgSpanColor: "red",
        });
        return;
      }
    }
    
    if(account_assistance_notify_email!= undefined && account_assistance_notify_email!= null){
      account_assistance_notify_email.split(",").every(element => {    
        keyValuePairs.account_assistance_notify_email = element
        result_account_assistance_notify_email_validation = validate(
            removeEmptyKeys(keyValuePairs),
            account_assistance_notify_email_validation
          );
        return !result_account_assistance_notify_email_validation;
      });

      if (result_account_assistance_notify_email_validation) {
        this.setState({
          validationMsg: result_account_assistance_notify_email_validation,
          msgSpanText:
            "There are some errors with the submission. Please fix and retry.",
          msgSpanColor: "red",
        });
        return;
      }
    }

    if(additional_status_notification_email!= undefined && additional_status_notification_email!= null){
      additional_status_notification_email.split(",").every(element => {    
        keyValuePairs.additional_status_notification_email = element
        result_additional_status_notification_email_validation = validate(
            removeEmptyKeys(keyValuePairs),
            additional_status_notification_email_validation
          );
        return !result_additional_status_notification_email_validation;
      });

      if (result_additional_status_notification_email_validation) {
        this.setState({
          validationMsg: result_additional_status_notification_email_validation,
          msgSpanText:
            "There are some errors with the submission. Please fix and retry.",
          msgSpanColor: "red",
        });
        return;
      }
    }

    this.setState(
      {
        popupWarning: true,
      },);
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  handleChange = (e) => {
    let checked = e.target.checked;
    if (e.target.value == "OFF" || e.target.value == "DISABLED") {
      checked = false;
    }
    this.setState({
      [e.target.name]: checked,
    });
  };

  handleSelectChange = (e) => {
    let value = e.target.value;
    let kp = this.state.keyValuePairs;
    kp[e.target.name] = value;
    this.setState({
      keyValuePairs: kp,
    });
  };

  handleTextChange = (e) => {
    let value = e.target.value;
    let kp = this.state.keyValuePairs;
    kp[e.target.name] = value;
    if (value.length < 1) delete kp[e.target.name];

    this.setState({
      keyValuePairs: kp,
    });
  };

  validateForm = (event) => {
    let result_nda_notification_email_validation, result_newuser_notification_email_to_validation, result_account_assistance_notify_email_validation, result_additional_status_notification_email_validation;
    const keyValuePairs = {...this.state.keyValuePairs};
    const nda_notification_email = keyValuePairs.nda_notification_email
    const newuser_notification_email_to = keyValuePairs.newuser_notification_email_to
    const account_assistance_notify_email = keyValuePairs.account_assistance_notify_email
    const additional_status_notification_email = keyValuePairs.additional_status_notification_email

    if(nda_notification_email!= null){
      nda_notification_email.split(",").every(element => {
        keyValuePairs.nda_notification_email = element
        result_nda_notification_email_validation = validate(
            removeEmptyKeys(keyValuePairs),
            nda_notification_email_validation
          );
      return !result_nda_notification_email_validation
      });
    }
    
    if(newuser_notification_email_to!= null && account_assistance_notify_email != null && additional_status_notification_email!= null){
      newuser_notification_email_to.split(",").every(element => {
        keyValuePairs.newuser_notification_email = element
        result_newuser_notification_email_to_validation = validate(
            removeEmptyKeys(keyValuePairs),
            nda_notification_email_validation
          );
      return !result_newuser_notification_email_to_validation
     });

     account_assistance_notify_email.split(",").every(element => {
      keyValuePairs.account_assistance_notify_email = element
      result_account_assistance_notify_email_validation = validate(
          removeEmptyKeys(keyValuePairs),
          account_assistance_notify_email_validation
        );
    return !result_account_assistance_notify_email_validation
   });

   additional_status_notification_email.split(",").every(element => {
    keyValuePairs.additional_status_notification_email = element
    result_additional_status_notification_email_validation = validate(
        removeEmptyKeys(keyValuePairs),
        additional_status_notification_email_validation
      );
  return !result_additional_status_notification_email_validation
 });

    if (!result_newuser_notification_email_to_validation && !result_nda_notification_email_validation && !result_account_assistance_notify_email_validation && !result_additional_status_notification_email_validation) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
        this.setState({
          validationMsg: result_newuser_notification_email_to_validation,
          msgSpanText:
            "There are some errors with the submission. Please fix and retry.",
          msgSpanColor: "red",
        });
      }
    }
  };

  getFormTextField = (label, name, errorMsg, defaultVal, mandatory) => {
    return (
      <InputField
        label={label}
        errorMsg={errorMsg}
        isError={errorMsg}
        name={name}
        id={name}
        value={defaultVal}
        onChange={this.handleTextChange}
        isRequired={mandatory}
      />
    );
  };

  getCheckboxField = (label, name, errorMsg, defaultCheck, mandatory, span) => {
    return (
      <React.Fragment>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <label style={{ fontWeight: "bold" }}>
            {label}{" "}
            {mandatory == true ? <span style={{ color: "red" }}>*</span> : ""}
          </label>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <input
            id={name}
            name={name}
            type="checkbox"
            checked={defaultCheck}
            onChange={this.handleChange}
            style={{ marginTop: "0.5%" }}
          />
          &nbsp;&nbsp;<span>{span}</span>
        </Grid.Column>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          {/* Commenting because not working */}
          <div className="header">
            <div className="side-marker" />
            Email Settings
          </div>
          <div className="empty-height-small" />
          <Form loading={this.state.loading}>
            {/* Commenting because not working */}
            {/* <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  {this.getFormTextField(
                    "SMTP Mail Server",
                    "mail_server",
                    this.state.validationMsg.mail_server,
                    this.state.keyValuePairs["mail_server"],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={4}>
                  {this.getFormTextField(
                    "Mail Server Port",
                    "mail_server_port",
                    this.state.validationMsg.mail_server_port,
                    this.state.keyValuePairs["mail_server_port"],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  <label style={{ fontWeight: "bold" }}>
                    Mail Server Security <span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    id="mail_server_secure"
                    name="mail_server_secure"
                    value={this.state.keyValuePairs["mail_server_secure"]}
                    onChange={this.handleSelectChange}
                  >
                    <option value="NONE">None</option>
                    <option value="SSL">SSL</option>
                    <option value="TLS">TLS</option>
                  </select>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {this.getCheckboxField(
                  "Secure Authentication",
                  "mail_server_auth",
                  this.state.validationMsg.mail_server_auth,
                  this.state.mail_server_auth,
                  true,
                  ""
                )}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Username",
                    "mail_server_auth_username",
                    this.state.validationMsg.mail_server_auth_username,
                    this.state.keyValuePairs["mail_server_auth_username"],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Password",
                    "mail_server_auth_password",
                    this.state.validationMsg.mail_server_auth_password,
                    this.state.keyValuePairs["mail_server_auth_password"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Item(s) Notification Email",
                    "mail_server_auth_username_for_items",
                    this.state.validationMsg
                      .mail_server_auth_username_for_items,
                    this.state.keyValuePairs[
                      "mail_server_auth_username_for_items"
                    ],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Password (Item(s) Email)",
                    "mail_server_auth_password_for_items",
                    this.state.validationMsg
                      .mail_server_auth_password_for_items,
                    this.state.keyValuePairs[
                      "mail_server_auth_password_for_items"
                    ],
                    true
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid> */}
            {/* <div className="empty-height-small" />
            <div className="empty-height-small" /> */}
            {/* <div className="side-marker">Email Notification Settings</div>
            <div className="empty-height-small" /> */}
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Support Email",
                    "support_email",
                    this.state.validationMsg.support_email,
                    this.state.keyValuePairs["support_email"],
                    true
                  )}
                </Grid.Column>
                {/* <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "NDA Emails Sent From",
                    "nda_sent_from_email",
                    this.state.validationMsg.nda_sent_from_email,
                    this.state.keyValuePairs["nda_sent_from_email"],
                    true
                  )}
                </Grid.Column> */}
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  {this.getFormTextField(
                    "NDA Notify Email",
                    "nda_notification_email",
                    this.state.validationMsg.nda_notification_email,
                    this.state.keyValuePairs["nda_notification_email"],
                    true
                  )}
                </Grid.Column>
                {/* Commenting because not working */}
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "New User Notify Email",
                    "newuser_notification_email_to",
                    this.state.validationMsg.newuser_notification_email_to,
                    this.state.keyValuePairs["newuser_notification_email_to"],
                    true
                  )}
                  </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Account Assistance Notify Email",
                    "account_assistance_notify_email",
                    this.state.validationMsg.account_assistance_notify_email,
                    this.state.keyValuePairs["account_assistance_notify_email"],
                    true
                  )}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Additional Status Notification Email",
                    "additional_status_notification_email",
                    this.state.validationMsg.additional_status_notification_email,
                    this.state.keyValuePairs["additional_status_notification_email"],
                    true
                  )}
                  </Grid.Column>
              </Grid.Row>
              {/* Commenting because not working */}
              {/* <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={4}>
                  {this.getFormTextField(
                    "NDA Emails Sent From",
                    "nda_sent_from_email",
                    this.state.validationMsg.nda_sent_from_email,
                    this.state.keyValuePairs["nda_sent_from_email"],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  {this.getFormTextField(
                    "NDA Notify Email",
                    "nda_notification_email",
                    this.state.validationMsg.nda_notification_email,
                    this.state.keyValuePairs["nda_notification_email"],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  {this.getFormTextField(
                    "NDA Signer Email",
                    "nda_signer_email",
                    this.state.validationMsg.nda_signer_email,
                    this.state.keyValuePairs["nda_signer_email"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row> */}
              {/* <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Rule Notify Email",
                    "rule_notification_email",
                    this.state.validationMsg.rule_notification_email,
                    this.state.keyValuePairs["rule_notification_email"],
                    true
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  {this.getFormTextField(
                    "Trade Compliance Email",
                    "trade_compliance_email",
                    this.state.validationMsg.trade_compliance_email,
                    this.state.keyValuePairs["trade_compliance_email"],
                    true
                  )}
                </Grid.Column>
              </Grid.Row> */}
              {/* <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <span style={{ fontWeight: "bold" }}>Send emails for</span>
                </Grid.Column>
              </Grid.Row> */}
              {/* <Grid.Row>
                {this.getCheckboxField(
                  "",
                  "newuser_notification_email",
                  this.state.validationMsg.newuser_notification_email,
                  this.state.newuser_notification_email,
                  false,
                  "New User Notification"
                )} /*}
                <br />
                {/* {this.getCheckboxField(
                  "",
                  "newuser_approval_email",
                  this.state.validationMsg.newuser_approval_email,
                  this.state.newuser_approval_email,
                  false,
                  "New User Approval"
                )}
               <br /> 
              </Grid.Row>*/}
              {/* <Grid.Row>
                {this.getCheckboxField(
                  "",
                  "newuser_rejection_email",
                  this.state.validationMsg.newuser_rejection_email,
                  this.state.newuser_rejection_email,
                  false,
                  "New User Rejection"
                )}
                <br />
                {this.getCheckboxField(
                  "",
                  "newuser_request_received_email",
                  this.state.validationMsg.newuser_request_received_email,
                  this.state.newuser_request_received_email,
                  false,
                  "New Account Request"
                )}
                </Grid.Row> */}
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="button"
                    value="Save"
                    className="button-primary"
                    onClick={this.showpopupWarning}
                  />
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content="Are you sure you want to update email settings?"
        />
      </React.Fragment>
    );
  }
}

export default EmailSettingsForm;
