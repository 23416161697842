import React from "react"
import PropTypes from "prop-types";

function ErrorMessage({ isError, errorMsg, color }) {
  return (
    <>
      <label className="error-message" style={{ color: color }}>{isError ? errorMsg : ""}&nbsp;</label>
    </>
  );
}


ErrorMessage.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  width: "16",
  disabled: false,
  type: "text"
};

export default ErrorMessage;