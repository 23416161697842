import React, { useEffect, useState } from "react";
import Api from "@/api";
import Constants from "@/config/constants";

import {
  Button,
  Modal,
  Header,
  GridRow,
  Grid,
  GridColumn,
} from "semantic-ui-react";
import PDFviwer from "../pdf-viewer";

const MultiDownloadOpts = ({ isModalOpen, closeModal, selectedFile }) => {
  const [disableCurl, setDisableCurl] = useState(false);
  const [disableDownload, setDisableDownload] = useState(false);
  const [disableWget, setDisableWget] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (url, type) => {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    type === "link"
      ? setDisableDownload(true)
      : type === "curl"
      ? setDisableCurl(true)
      : setDisableWget(true);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
      setDisableCurl(false);
      setDisableWget(false);
      setDisableDownload(false);
    }, 500);
  };
  const close = () => {
    closeModal();
    setDisableDownload(false);
    setDisableCurl(false);
    setIsCopied(false);
    setDisableWget(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => {
          close();
        }}
        closeIcon
        size={"small"}
      >
        <Modal.Header>Download</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description className="word-wrap">
            <Grid>
              <GridRow className="briefcase-download-popup-list">
                <GridColumn
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                  widescreen={16}
                  verticalAlign="middle"
                >
                  {" "}
                  <Header className="mb-05-im">
                    <small> {selectedFile?.fileName}</small>{" "}
                  </Header>
                  <p>{selectedFile?.fileDesc}</p>
                </GridColumn>

                <GridColumn
                 mobile={16}
                 tablet={16}
                 computer={16}
                 largeScreen={16}
                 widescreen={16}
                >
                  <div className="download-action">
                    <PDFviwer
                      filename={selectedFile?.fileName}
                      itemType={selectedFile?.itemType}
                      url={selectedFile.downloadLink}
                    ></PDFviwer>
                    <a
                      href={selectedFile.downloadLink}
                      target="_blank"
                      title="Download now"
                      onClick={()=>{
                        const instance = Api.AxiosInstance.getInstance();
                        const { URL_ITEM_DOWNLOAD } = Constants.App;
                        const params = { params: { 
                          itemId: selectedFile.itemId ,
                          hasDownloaded: 1
                        },timeout: 0 };
                        instance.get(URL_ITEM_DOWNLOAD, params);
                      }}
                    >
                      <Button className="button-basic mini">
                        <span class="icon-download" title="Download">
                          {" "}
                        </span>
                      </Button>
                    </a>
                    <a
                      title="Copy download link to clipboard"
                      onClick={() =>
                        copyToClipboard(
                          selectedFile.friendlyName
                            ? window.location.host +
                                "/#/downloads/" +
                                selectedFile.friendlyName
                            : selectedFile.directLink,
                          "link"
                        )
                      }
                    >
                      <Button
                        className="button-basic mini"
                        disabled={disableDownload}
                      >
                        <span class="icon-external-link2" title="Download Link">
                          {" "}
                        </span>
                      </Button>
                    </a>
                    <a
                      title="Copy cURL command for CLI to clipboard"
                      onClick={() =>
                        copyToClipboard(selectedFile.curlCommand, "curl")
                      }
                    >
                      <Button
                        className="button-basic mini"
                        disabled={disableCurl}
                      >
                        cURL
                      </Button>
                    </a>
                    <a
                      title="Copy Wget command for CLI to clipboard"
                      onClick={() =>
                        copyToClipboard(selectedFile.wgetCommand, "wget")
                      }
                    >
                      <Button
                        className="button-basic mini"
                        disabled={disableWget}
                      >
                        Wget
                      </Button>
                    </a>

                    {isCopied && (
                      <span className="copy-msg">Copied to clipboard</span>
                    )}
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
};
export default MultiDownloadOpts;
