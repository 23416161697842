import React from "react";
import ProductConstants from "../constants";
import Product from "./products";
import ProductsContext from "./_context";

const baseUrl = ProductConstants.URL_BASE;

function MappedProducts() {
  return (
    <>
      <ProductsContext.Provider>
        <Product baseUrl={ProductConstants.URL_BROWSER} />
      </ProductsContext.Provider>
    </>
  );
}

export default MappedProducts;
