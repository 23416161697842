const NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING';
const NOTIFICATIONS_LOAD_SUCCESS = 'NOTIFICATIONS_LOAD_SUCCESS';
const NOTIFICATIONS_LOAD_FAILED = 'NOTIFICATIONS_LOAD_FAILED';
const NOTIFICATIONS_COUNT_UPDATE = 'NOTIFICATIONS_COUNT_UPDATE';

export default {
  ACTIONS: {
    NOTIFICATIONS_LOADING,
    NOTIFICATIONS_LOAD_SUCCESS,
    NOTIFICATIONS_LOAD_FAILED,
    NOTIFICATIONS_COUNT_UPDATE
  }
};