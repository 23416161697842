import PageTemplate from "@/common/page";
import Config from "@/config/constants";
import { indexOf } from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";
import ProductConstants from "./constants";
import Package from "./packages/index";
import Product from "./products/index";
import DownloadPage from "./products/pages/download";
import ProductSearch from "./productsearch/productsearch";
import EditProducts from "./packages/pages/edit/index";
import AddPackage from "./packages/pages/add/index";

const basePage = function () {
  return (
    <Redirect
      exact
      from={ProductConstants.URL_BASE}
      to={ProductConstants.URL_BROWSER}
    />
  );
};

const Products = ({ baseUrl, authority }) => {
  const PRODUCT_VIEW_MENU_ITEMS = {
    Downloads: [
      {
        name: "Downloads Home",
        relativePath: "/",
        subPageComponent: basePage,
        hide: true,
        exact: true,
        includePackagePane: true,
      },
      // not removing old for backward compatibility
      {
        name: "Download Link",
        relativePath: "/download/:id",
        subPageComponent: DownloadPage,
        hide: true,
        exact: true,
        includePackagePane: true,
      },
      {
        name: "Download View",
        relativePath: "/view/:id",
        subPageComponent: DownloadPage,
        hide: true,
        // exact: true,
        includePackagePane: true,
      },
      {
        name: "Downloads",
        relativePath: ProductConstants.URL_BROWSER.replace(
          ProductConstants.URL_BASE,
          ""
        ),
        subPageComponent: Product,
        includePackagePane: true,
      },
      {
        name: "Packages",
        relativePath: ProductConstants.URL_PACKAGES.replace(
          ProductConstants.URL_BASE,
          ""
        ),
        hide: indexOf(Config.AccessRole.ADMINISTRATORS, authority) === -1,
        subPageComponent: Package,
        includePackagePane: false,
        exact: true,
      },
      {
        name: "Packages Edit",
        relativePath: ProductConstants.URL_PACKAGES_EDIT.replace(
          ProductConstants.URL_BASE,
          ""
        ),
        hide: true,
        subPageComponent: EditProducts,
        includePackagePane: false,
        exact: true,
      },
      {
        name: "Packages New",
        relativePath: "/packages/new",
        hide: true,
        subPageComponent: AddPackage,
        includePackagePane: false,
        exact: true,
      },
      // temporarily hiding it , till we fixed the weird issue
      // {
      //   name: "Search",
      //   relativePath: ProductConstants.URL_SEARCH.replace(
      //     ProductConstants.URL_BASE,
      //     ""
      //   ),
      //   subPageComponent: ProductSearch,
      //   includePackagePane: false
      // },
      
  
    ],
  };
  return <PageTemplate baseUrl={baseUrl} menuItems={PRODUCT_VIEW_MENU_ITEMS} />;
};

export default Products;
