import PropTypes from "prop-types";
import React from "react";
import DropdownField from "./dropdown-field";

const statusOptions = [
  { key: "ACTIVE", value: "ACTIVE", text: "Active" },
  { key: "DISABLED", value: "DISABLED", text: "Disabled" }
];

const StatusField = ({
  errorMsg,
  isError,
  isRequired,
  label,
  name,
  onChange,
  value,
  width
}) => {
  return (
    <DropdownField
      errorMsg={errorMsg}
      isError={isError}
      isRequired={isRequired}
      label={label}
      name={name}
      onChange={onChange}
      options={statusOptions}
      placeholder="Select Status..."
      value={value}
      width={width}
    />
  );
};

StatusField.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string
};

export default StatusField;
