import Tableview from "@/common/tableview";
import Api from "@api";
import Constants from "@constants";
import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Confirm, Grid, Responsive } from "semantic-ui-react";
import {CardviewMobile} from "@/common/cardview";
import {dateToPacific} from "@/utils/dateTime";

class PackageList extends React.Component {
  _isMounted = false;
  staticValues = {
    listColumns: [
      { name: "Name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Name");
      }, },
      { name: "Groups", key: "groups_count", class: "center", showTooltip: true, cb: () => {
        this.sortByHeader("groups_count", "Groups");
      }, },
      { name: "Users", key: "users_count", class: "center", showTooltip: true, cb: () => {
        this.sortByHeader("users_count", "Users");
      }, },
      { name: "Items", key: "items_count", class: "center", showTooltip: true, cb: () => {
        this.sortByHeader("items_count", "Items");
      }, },

      { name: "Videos", key: "videos_count", class: "center", showTooltip: true, cb: () => {
        this.sortByHeader("videos_count", "Videos");
      }, },
      { name: "Status", key: "status", showTooltip: true, cb: () => {
        this.sortByHeader("status", "Status");
      }, },
      { name: "Created On", key: "creationDate", showTooltip: true, cb: () => {
        this.sortByHeader("creationDate", "Created On");
      }, },
      { name: "Created By", key: "createdBy", showTooltip: true, cb: () => {
        this.sortByHeader("createdBy", "Created By");
      }, },
      { name: "Public", key: "publicPackage", showTooltip: true, cb: () => {
        this.sortByHeader("isPublicPackage", "Public");
      }, },
      { name: "Actions", key: "actions", width: 2 }
    ],
    responsive_listColumns: [
      { name: "Name", key: "name" },
      { name: "Groups", key: "groups_count", class: "center" },
      { name: "Users", key: "users_count", class: "center" },
      { name: "Items", key: "items_count", class: "center" },
      { name: "Videos", key: "videos_count", class: "center" },
      { name: "Status", key: "status" },
      { name: "Created On", key: "creationDate" },
      {name: "Public", key:"publicPackage"},
      { name: "Created By", key: "createdBy" }
    ]
  };

  constructor(props) {
    super(props);
    this.state = {
      packages: {},
      isLoading: false,
      search: null,
      page: 0,
      packageId: null,
      deleteWarning: false,
      isDescSearch: true,
      sortBy: "modificationDate,DESC",
    };
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    {
      this.loadPackages();
    }
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.loadPackages();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  showDeleteWarningAndSetValues = (e, id) => {
    this.setState({
      deleteWarning: true,
      packageId: id
    });
  };

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.staticValues.listColumns = this.staticValues.listColumns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.loadPackages();
    });
   
  };

  loadPackages = params => {
    const defaultParams = {
      page: this.state.page,
      search: this.state.search,
      sort: this.state.sortBy,
    };
    const requestParams = Object.assign(defaultParams, params);
    const instance = Api.AxiosInstance.getInstance();

    instance
      .get(Constants.App.URL_PACKAGE_LIST, { params: requestParams })
      .then(resp => {
        if (this._isMounted) {
          const data = resp.data;
          this.setState({ packages: this.transformData(data) });
        }
      });
  };

  hideDeleteWarning = cb => {
    this.setState(
      {
        deleteWarning: false
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  deletePackage = () => {
    const { packageId } = this.state;
    const { URL_PACKAGE_DELETE } = Constants.App;
    const defaultParams = {
      id: packageId
    };
    // todo: need to add delete api call
    const instance = Api.AxiosInstance.getInstance();
    instance
      .delete(URL_PACKAGE_DELETE, { params: defaultParams })
      .then(resp => {
        this.loadPackages();
      });
  };

  transformData = data => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map(el => {
        let row = Object.assign({}, el);
        row.groups_count = row.groups.length;
        row.users_count = row.users.length;
        row.items_count = row.items.length;
        row.publicPackage = row.publicPackage?"YES":"NO"
        row.actions = (
          <React.Fragment>
            <Link to={this.props.match.url + "/" + row.id + "/edit"}>
              <span className="icon-edit" title="Edit" alt="" />
            </Link>
            <a>
              {
                <span
                  className="icon-delete"
                  onClick={e => this.showDeleteWarningAndSetValues(e, row.id)}
                  title="Delete"
                  name="delete"
                />
              }
            </a>
          </React.Fragment>
        );
        row.creationDate = < >{ dateToPacific(row.creationDate,"MM/DD/YYYY hh:mm a")}</>;

        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };
  onSearchTextChange = data => {
    this.setState({
      search: data.target.value
    });
  };

  onSearchClicked = event => {
    event.preventDefault();
    this.setState({page: 0}, this.loadPackages);
  };

  onPageChange = event => {
    this.setState({ page: event.page }, this.loadPackages);
  };

  render() {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
              <div className="header">
                <div className="side-marker" />
                Packages
                <Link to={this.props.match.url + "/new"}>
                  <button className="button-basic">Add New</button>
                </Link>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="ui form common-filter-wrapper">
            <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6}>
              <div class="field">
                    <div class="ui input">
              <input
                type="text"
                placeholder="Search"
                className="search-bar"
                onChange={this.onSearchTextChange}
                onKeyPress={this.onKeyUp}
              />
              </div></div>

            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6} >
              <div class="field">
                    <div class="ui input">
              <button className="button-primary" onClick={this.onSearchClicked}>
                Search
              </button>

              </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>



             <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination">
             <div className="table-wrapper-with-pagination">
                <Tableview
                  data={this.state.packages}
                  paginate
                  columns={this.staticValues.listColumns}
                  onPaginationChange={this.onPageChange}
                  title=""
                  noDataMessage="No Packages."
                />
              </div>
          </div>
        </Responsive>
         <Responsive maxWidth={1023}>
           <CardviewMobile
              data={this.state.packages}
              columns={this.staticValues.responsive_listColumns}
              paginate
              onPaginationChange={e => this.onPageChange(e)}          
            />
        </Responsive>   


           


            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.deletePackage)}
          content={`Are you sure you want to delete this package?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageList;
