import React from 'react';
import PageTemplate from '../../common/page';
import MainPage from './pages/main.js';

const MENU_ITEMS = {
  Search: [
    {
      name: 'Search',
      relativePath: '/:keyword',
      exact: true,
      subPageComponent: MainPage,
    },
  ],
};

const Search = ({ baseUrl }) => {
  return <PageTemplate baseUrl={baseUrl} menuItems={MENU_ITEMS} />;
};

export default Search;
