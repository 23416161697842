import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Segment, Grid, Form, Confirm } from "semantic-ui-react";
import { InputField } from "@/common/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIfSequenceAlreadyExists,
  getArticleById,
  getMaximumSequence,
  updateArticle,
} from "../../../../../api/zendesk";
import _ from "lodash";

const validationObj = {
  urlError: false,
  textToDisplayError: false,
  msg: "",
};
export default ({ match }) => {
  const store = useSelector((state) => state.zendeskReducer);
  const [formValidations, setFormValidations] = useState({ ...validationObj });
  const [sequence, setSequence] = useState("");
  const [url, setUrl] = useState("");
  const [textToDisplay, setTextToDisplay] = useState("");
  const [updateWarning, setUpdateWarning] = useState(false);
  const [DTO, setDTO] = useState({});
  const [sequenceError, setSequenceError] = useState({
    isError: false,
    msg: "",
  });
  const [urlError, setUrlError] = useState({
    isError: false,
    msg: "",
  });
  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    const { sequence, url, textToDisplay } = e.target;
    if (sequenceError.isError || urlError.isError) {
      return;
    }
    if (textToDisplay.value == "") {
      setFormValidations({
        ...validationObj,
        textToDisplayError: true,
        msg: "Text To Display is required",
      });
      return;
    } else if (url.value == "") {
      setFormValidations({
        ...validationObj,
        urlError: true,
        msg: "Url is required",
      });
      return;
    } else {
      const dto = {
        sequence: sequence.value,
        url: url.value,
        textToDisplay: textToDisplay.value,
        id: match.params.id,
      };
      if (!_.isEmpty(store.existingSequence)) {
        setDTO(dto);
        setUpdateWarning(true);
      } else {
        dispatch(updateArticle(dto, match.params.id));
      }
    }
  };

  const onSequenceChange = (val) => {
    setSequence(val);
    if (val > store.maxSequence) {
      setSequenceError({
        isError: true,
        msg: `Sequence should be less than ${store.maxSequence + 1}`,
      });
    } else if (val < 1) {
      setSequenceError({
        isError: true,
        msg: `Sequence should be greater than 0`,
      });
    }
    else {
      setSequenceError({
        isError: false,
        msg: "",
      });
    }
    dispatch(checkIfSequenceAlreadyExists(val));
  };

  const onConfirm = () => {
    dispatch(updateArticle(DTO, match.params.id));
  };
  const setUrlAndValidate = (val) => {
    setUrl(val);
    if (!validURL(val)) {
      setUrlError({
        isError: true,
        msg: "Please enter the valid url.",
      });
    } else {
      setUrlError({
        isError: false,
        msg: "",
      });
    }
  }
  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }
  useEffect(() => {
    dispatch(getArticleById(match.params.id));
    dispatch(getMaximumSequence());
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (!_.isEmpty(store.article)) {
      setSequence(store.article.sequence);
      setUrl(store.article.url);
      setTextToDisplay(store.article.textToDisplay);
    }
  }, [store.article]);
  return (
    <Segment className="base-segment-wrapper">
      <Form
        loading={store ? store.loading : false}
        onSubmit={(e) => onSubmit(e)}
      >
        <Grid stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
              <div className="header">
                <div className="side-marker" />
                Edit Article
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
              <div className="control-action-wrapper"></div>
            </Grid.Column>
            <div className="empty-height" />
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <InputField
                label="Sequence"
                name="sequence"
                type="number"
                value={sequence}
                isError={sequenceError.isError}
                errorMsg={sequenceError.msg}
                onChange={(e) => onSequenceChange(e.target.value)}
                isRequired
                disabled={store.maxSequence < 3}
                maxlength={32}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <InputField
                label="Text To Display"
                name="textToDisplay"
                isError={formValidations.textToDisplayError}
                value={textToDisplay}
                onChange={(e) => setTextToDisplay(e.target.value)}
                isRequired
                maxlength={64}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <InputField
                label="URL"
                name="url"
                onChange={(e) => setUrlAndValidate(e.target.value)}
                isError={urlError.isError}
                errorMsg={urlError.msg}
                value={url}
                isRequired
              />
            </Grid.Column>
          </Grid.Row>
          <div className="ui divider"></div>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <button type="submit" className="button-primary">
                Save
              </button>
              <Link to={"/admin/zendesk"}>
                <button className="button-basic">Cancel</button>
              </Link>
              {store && store.errors.isError && (
                <span
                  style={{
                    color: "red",
                    marginLeft: "5px",
                  }}
                >
                  {store.errors.message}
                </span>
              )}
              {store && store.errors.isError && (
                <span
                  style={{
                    color: "red",
                    marginLeft: "5px",
                  }}
                >
                  {store.errors.message}
                </span>
              )}
              {formValidations.textToDisplayError ||
              formValidations.urlError ? (
                <span
                  style={{
                    color: "red",
                    marginLeft: "5px",
                  }}
                >
                  {formValidations.msg}
                </span>
              ) : (
                <></>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <Confirm
        open={updateWarning}
        onCancel={() => setUpdateWarning(false)}
        onConfirm={() => onConfirm()}
        content="The sequence number entered is currently in use, do you wish to swap the sequence numbers?"
      />
    </Segment>
  );
};
