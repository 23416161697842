import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "semantic-ui-react";
import Context from "../_context";

function ProductTreeElement({ product, baseUrl, setShowTree }) {
  const context = useContext(Context.Context);

  const [showChildren, setShowChildren] = useState(product.expand);

  const hasChildren = product.children.length > 0;
  if (context.data.expand !== null && showChildren !== context.data.expand) {
    setShowChildren(context.data.expand);
  }

  const selectedProduct =
    context && context.data && context.data.selectedProduct !== null
      ? context.data.selectedProduct
      : false;

  let isParentOfSelectedProductBool = false;
  if (
    selectedProduct !== null &&
    context.data.productParentMap &&
    !showChildren &&
    product.id !== selectedProduct
  ) {
    const parentMap = context.data.productParentMap[selectedProduct];
    if (parentMap && parentMap[product.id] === 1) {
      // setShowChildren(true);
      isParentOfSelectedProductBool = true;
    }
  }

  const toggleFolder = (e) => {
    setShowChildren(!showChildren);
  };

  const toggleLink = (e) => {
    setShowChildren(!showChildren);
    setShowTree();
  };
  const setShowTreeForSmallDevices = (e) => {
    setShowTree();
  };

  const iconName =
    showChildren || isParentOfSelectedProductBool ? (
      <span class="icon-tree-close">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
    ) : hasChildren ? (
      <span class="icon-tree-open"></span>
    ) : (
      <span class="icon-folder-normal"></span>
    );

  return (
    <>
      <Accordion.Title
        className={`${
          selectedProduct !== false && selectedProduct === product.id
            ? "selected "
            : ""
        } ${product.status == "DISABLED" ? "disabled" : ""}   `}
        active={showChildren || isParentOfSelectedProductBool}
        key={product.id}
        onClick={hasChildren ? toggleFolder : null}
      >
        {iconName}
        &nbsp;&nbsp;
        <Link
          onClick={hasChildren ? toggleLink : setShowTreeForSmallDevices}
          to={baseUrl + product.id  + product.friendlyUrl}
        >
          {product.name}
        </Link>
      </Accordion.Title>
      {hasChildren && (
        <Accordion.Content
          active={showChildren || isParentOfSelectedProductBool}
          className={`${product.children.length > 1 ? "" : "single-child"} ${
            product.status == "DISABLED" ? "disabled" : ""
          }   `}
        >
          {product.children.map((child) => {
            return (
              <ProductTreeElement
                key={child.id}
                product={child}
                baseUrl={baseUrl}
                setShowTree={setShowTree}
              />
            );
          })}
        </Accordion.Content>
      )}
    </>
  );
}

export default ProductTreeElement;
