import React from "react";
import {
  Message,
  Button,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  List,
  Input,
  Confirm,
  Item,
  Modal,
} from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";
import { connect } from "react-redux";
import { authoritySelector } from "@/common/selectors";
import { default as Config, default as Constants } from "@/config/constants";

import { Container, Pagination } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import Api from "../../../../api";
import UserListing from "../../../../common/user-selection/user-listing";
import GroupListing from "../../../../common/group-selection/group-listing";
import ImageIcon from "../../../../assets/icon-image.png";
import _ from "lodash";
import { merge } from "lodash";
import projectIdDispatcher from "../../dispatch";
import { userIdSelector } from "../../../../common/selectors";
import { MembersListing } from "../../../../common/user-selection";

const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;

class ProjectMembers extends React.Component {
  constructor(props) {
    super(props);
    let id = props.match.params.id;
    this.state = {
      projectId: id,
      membersList: {},
      loading: false,
      paginate: true,
      configOption: {},
      showConfirmDialog: false,
      open: false,
      selected: null,
      popupWarning: false,
      itemId: "",
      showUsersListingForAdmin: false,
      redirectToViewProject: false,
      name: "",
      description: "",
      adminId: null,
    };
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    this.loadMembers();
    this.loadProject();
    const { projectId } = this.state;
    projectIdDispatcher.dispatch({
      type: "PROJECT_ID_CHANGE",
      value: projectId,
    });
  }
  onKeyUp = (event) => {
    if (event.charCode === 13) {
      console.log(event.target.value);
      var options = {
        params: {
          page: 0,
          search: event.target.value,
        },
      };
      this.loadMembers(options);
    }
  };
  componentDidUpdate() {}

  toggleConfirmation = (val) => {
    this.setState({ showConfirmDialog: val });
  };

  loadMembers = (options) => {
    if (options && options.paginate) {
      if (!options.params) options.params = {};
      options.params.page = options.page - 1;
    }
    options = _.merge(
      {
        params: {
          size: 10,
        },
      },
      options ? options : {}
    );
    this.setState({ loading: true });
    return Api.AxiosInstance.paginate(
      Constants.App.URL_PROJECT_MEMBERS + "?id=" + this.state.projectId,
      options
    )
      .then((resp) => {
        if (resp.data.content.length > 0) {
          this.setState({ membersList: resp.data, loading: false });
          let element = document.getElementById("item");
          element.style.backgroundColor = "#ffe7e4";
          setTimeout(() => {
            element.style.backgroundColor = "#e9edf059";
          }, 2000);
        } else {
          this.setState({ membersList: {}, loading: false });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({
          errorMsg: "Something went wrong while adding memeber",
          showError: true,
          loading: false,
        });
      });
  };

  loadProject = () => {
    // this.setState({ loading: true });
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_PROJECT_DETAIL + "?id=" + this.state.projectId)
      .then((resp) => {
        let data = resp.data;
        this.setState({
          name: data.name,
          description: data.description,
          adminId: data.adminId,
        });
      });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  pageChange = (e, { activePage }) => {
    this.loadMembers({ paginate: true, page: activePage });
  };

  deleteMember = (memberId) => {
    const {
      history: { push },
      userId,
    } = this.props;
    let options = {
      id: this.state.projectId,
      users: [
        {
          id: memberId,
        },
      ],
    };
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_REMOVE_MEMBER, options)
      .then((resp) => {
        this.setState({ result: "confirmed", open: false }, () => {
          if (memberId === userId) {
            push(`/projects`);
          } else {
            this.loadMembers();
          }
        });
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({
          errorMsg,
          showError: true,
          open: false,
        });
      });
  };

  handleSearchChange = (data) => {
    var options = {
      params: {
        page: 0,
        search: data.target.value,
      },
    };
    //this.loadMembers(options);
  };

  show = (itemid, username) => {
    // event.preventDefault();
    // console.log("id and name", itemid, username);
    this.setState({
      open: true,
      selected: {
        id: itemid,
        name: username,
      },
    });
  };

  showpopupWarning = (itemId, username) => {
    this.setState({
      popupWarning: true,
      itemId: itemId,
      selected: {
        id: itemId,
        name: username,
      },
    });
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  handleConfirm = () => {
    // event.preventDefault();
    const { selected } = this.state;
    this.deleteMember(selected.id);
  };

  handleCancel = () => this.setState({ result: "cancelled", open: false });

  inviteUsers = () => {
    this.setState({ showUsersListing: true });
  };

  changeOwner = () => {
    this.setState({ showUsersListingForAdmin: true });
  };

  onDismissUserListing = () => {
    this.setState({ showUsersListing: false });
  };

  onDismissUserAdminListing = () => {
    this.setState({ showUsersListingForAdmin: false });
  };

  onSaveUserListing = (users) => {
    const userids = [];
    users.map((user) => userids.unshift({ id: user.id }));
    const options = {
      id: this.state.projectId,
      users: userids,
    };
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_ADD_MEMBERS, options)
      .then((resp) => {
        this.setState({ showUsersListing: false });
        this.loadMembers();
      })
      .catch((err) => {
        this.setState({
          errorMsg: "Something went wrong while adding memeber",
          showError: true,
        });
      });
  };

  onSaveUserAdminListing = (users) => {
    console.log("users", users);
    if (!users[0]["id"]) return;

    let id = this.state.projectId ? this.state.projectId : "new";
    let options = {
      adminId: users[0]["id"],
      name: this.state.name,
      description: this.state.description,
    };
    const params = { params: { id: id } };
    Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_DETAIL, options, params)
      .then((resp) => {
        this.setState({ redirectToViewProject: true, id: resp.data.id });
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: error.response.data.message,
        });
      });
  };

  onDismissUserListing = () => {
    this.setState({ showUsersListing: false });
  };

  inviteGroups = () => {
    this.setState({ showGroupsPopup: true });
  };

  onDismissGroupPopup = () => {
    this.setState({ showGroupsPopup: false });
  };

  groupSelectionOnSave = (groups) => {
    const groupIds = [];
    groups.map((group) => groupIds.unshift({ id: group.id }));
    const options = {
      id: this.state.projectId,
      groups: groupIds,
    };
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_ADD_MEMBERS, options)
      .then((resp) => {
        this.setState({ showGroupsPopup: false });
        this.loadMembers();
      })
      .catch((err) => {
        this.setState({
          errorMsg: "Something went wrong while adding groups",
          showError: true,
        });
      });
  };

  resendInvitation = (id) => {
    const { itemId } = this.state;
    const options = {
      id: this.state.projectId,
      users: [{ id }],
    };
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_PROJECT_RESEND_INVITE, options)
      .then((resp) => {
        this.setState({
          invitMessage: {
            success: true,
            message: "Member invitation successfully sent!",
          },
        });
        this.loadMembers();
      })
      .catch((err) => {
        this.setState({
          invitMessage: {
            success: false,
            message: "Failed to send member invitation!",
          },
        });
      });
  };

  render() {
    let isAdmin =
      ADMIN_ROLES.indexOf(this.props.authority) >= 0
        ? true
        : this.state.adminId == this.props.userId
        ? true
        : false;

    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" />
                  Members
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                <Segment
                  loading={this.state.loading}
                  className="m--no-pad-left-right-im"
                >
                  {this.state.invitMessage &&
                    (this.state.invitMessage.success ? (
                      <Message color="green">
                        {this.state.invitMessage.message}
                      </Message>
                    ) : (
                      <Message color="error">
                        {this.state.invitMessage.message}
                      </Message>
                    ))}
                  <Grid>
                    <Grid.Row className="ui form">
                      <Grid.Column
                        mobile={16}
                        tablet={8}
                        computer={5}
                        largeScreen={5}
                      >
                        <div class="field">
                          <input
                            placeholder="Search"
                            className="search-bar"
                            onChange={this.handleSearchChange}
                            onKeyPress={this.onKeyUp}
                          ></input>
                        </div>
                      </Grid.Column>

                      <Grid.Column
                        mobile={16}
                        tablet={8}
                        computer={8}
                        largeScreen={8}
                      >
                        <div className="control-action-wrapper m--float-left-im">
                          {this.props.authority !== "websilo-customer" ? (
                            <button
                              className="button-basic"
                              onClick={this.inviteGroups}
                            >
                              Invite Groups
                            </button>
                          ) : (
                            ""
                          )}
                          <button
                            className="button-basic"
                            onClick={this.inviteUsers}
                          >
                            Invite Users
                          </button>
                          {isAdmin && (
                            <button
                              className="button-basic"
                              onClick={this.changeOwner}
                            >
                              Change Owner
                            </button>
                          )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Item.Group relaxed unstackable>
                    {this.state.membersList &&
                      this.state.membersList.content &&
                      this.state.membersList.content.map((item, i) => {
                        return (
                          <Item id="item">
                            <img
                              className="image-icon"
                              src={item.picture ? item.picture : ImageIcon}
                              alt="Friend-Icon"
                            />{" "}
                            {/*<Image
                            className="image-icon"
                            avatar
                            src="https://react.semantic-ui.com/images/avatar/small/lena.png"
                          />*/}
                            {/*<img className="image-icon" src={ImageIcon} alt="Project-Icon"/>*/}
                            <Item.Content className="member-card-wrapper">
                              <Item.Header>
                                <Link to="#">
                                  <Grid>
                                    <Grid.Row>
                                      <Grid.Column
                                        mobile={16}
                                        tablet={8}
                                        computer={8}
                                        largeScreen={8}
                                      >
                                        <strong className="items-heading">
                                          {item.firstName + " " + item.lastName}{" "}
                                        </strong>
                                        <React.Fragment>
                                          <span className="member-name-status">
                                            ({item.username})
                                          </span>
                                          {item.status == "ACTIVE" ? (
                                            <Icon
                                              name="user plus"
                                              color="teal"
                                            />
                                          ) : (
                                            <Icon
                                              name="user times"
                                              color="grey"
                                            />
                                          )}
                                        </React.Fragment>
                                      </Grid.Column>

                                      <Grid.Column
                                        mobile={16}
                                        tablet={8}
                                        computer={8}
                                        largeScreen={8}
                                      >
                                        <div className="control-action-wrapper">
                                          {/*<Icon
                                                                                    type="button"
                                                                                    name="delete"
                                                                                    className="ml-1-im"
                                                                                    itemid={item.id}
                                                                                    username={item.username}
                                                                                    onClick={this.show}/>*/}
                                          {item.id == this.props.userId ? (
                                            <button
                                              className="button-basic ui mini "
                                              itemid={item.id}
                                              username={item.username}
                                              onClick={() =>
                                                this.show(
                                                  item.id,
                                                  item.username
                                                )
                                              }
                                              title="Delete User"
                                            >
                                              {" "}
                                              Leave Project
                                            </button>
                                          ) : ADMIN_ROLES.indexOf(
                                              this.props.authority
                                            ) >= 0 ? (
                                            <button
                                              className="button-basic ui mini button"
                                              itemid={item.id}
                                              username={item.username}
                                              onClick={() =>
                                                this.show(
                                                  item.id,
                                                  item.username
                                                )
                                              }
                                              title="Delete User"
                                            >
                                              {" "}
                                              Delete User{" "}
                                            </button>
                                          ) : (
                                            ""
                                          )}{" "}
                                          {item.status == "PENDING" &&
                                            ADMIN_ROLES.indexOf(
                                              this.props.authority
                                            ) >= 0 &&
                                            item.id !== this.props.userId && (
                                              <button
                                                className="button-basic ui mini button"
                                                itemid={item.id}
                                                onClick={() =>
                                                  this.resendInvitation(item.id)
                                                }
                                              >
                                                Resend Invitation
                                              </button>
                                            )}
                                        </div>
                                      </Grid.Column>
                                    </Grid.Row>
                                  </Grid>
                                </Link>
                              </Item.Header>

                              <Item.Extra>Company: {item.company}</Item.Extra>
                              <Item.Extra>
                                Group:{" "}
                                {item.userGroup > 0
                                  ? item.userGroup
                                  : "No Group"}
                              </Item.Extra>
                              {item.joined && (
                                <Item.Extra>
                                  Member since:{" "}
                                  <>
                                    {dateToPacific(item.joined, "MM/DD/YYYY")}
                                  </>
                                </Item.Extra>
                              )}
                            </Item.Content>
                          </Item>
                        );
                      })}
                    {_.isEmpty(this.state.membersList.content) ? (
                      <div style={{ textAlign: "center" }}>
                        <span> No Members Found.</span>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.selected && this.state.selected.id && (
                      <Confirm
                        open={this.state.open}
                        onCancel={this.handleCancel}
                        onConfirm={this.handleConfirm}
                        itemid={this.state.selected.id}
                        content={
                          "Are you sure you want to delete user '" +
                          this.state.selected.name +
                          "' from this project?"
                        }
                      />
                    )}

                    {this.state.selected && this.state.selected.id && (
                      <Confirm
                        open={this.state.popupWarning}
                        onCancel={this.hidepopupWarning}
                        onConfirm={() =>
                          this.hidepopupWarning(this.resendInvitation)
                        }
                        content={
                          "Are you sure you want to resend invite to user '" +
                          this.state.selected.name +
                          "' ?"
                        }
                      />
                    )}
                  </Item.Group>
                  {this.state.paginate && (
                    <Pagination
                      activePage={
                        this.state.membersList.number
                          ? this.state.membersList.number + 1
                          : 1
                      }
                      onPageChange={this.pageChange}
                      totalPages={
                        this.state.membersList.totalPages
                          ? this.state.membersList.totalPages
                          : 0
                      }
                    />
                  )}
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <UserListing
          show={this.state.showUsersListing}
          onDismiss={this.onDismissUserListing}
          onSave={this.onSaveUserListing}
          entity="ProjectMembers"
          entityId={this.state.projectId}
        />
        <GroupListing
          show={this.state.showGroupsPopup}
          onDismiss={this.onDismissGroupPopup}
          onSave={this.groupSelectionOnSave}
          entity="ProjectMembers"
          entityId={this.state.projectId}
        />
        <Modal
          open={this.state.showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={this.state.errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>

        {this.state.showUsersListingForAdmin && (
          <MembersListing
            {...this.props}
            selectionType="single-select"
            show={this.state.showUsersListingForAdmin}
            onDismiss={this.onDismissUserAdminListing}
            onSave={this.onSaveUserAdminListing}
          />
        )}
        {this.state.redirectToViewProject ? (
          <Redirect to={{ pathname: "/projects/" + this.state.id }} />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  authority: authoritySelector(state),
  userId: userIdSelector(state),
  // baseUrl: baseUrl,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mappedProjects = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectMembers);

export default mappedProjects;
