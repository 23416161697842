const normalValidation = {
  name: {
    presence: true,
    length: {
      maximum: 32,
    },
  },
  extension: {
    presence: true,
    length: {
      maximum: 6,
    },
  },
  mimeType: {
    presence: true,
    length: {
      maximum: 50,
    },
  },
};

export { normalValidation };
