import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Sidebar } from "semantic-ui-react";
import PropTypes from "prop-types";
import Config from "@/config/constants";

const ADMINISTRATOR = Config.AccessRole.ADMINISTRATORS;
const EMPLOYEES = Config.AccessRole.EMPLOYEES;

class SideBar extends React.Component {

  onRouteClick(route) {
    const {dispatch} = this.props;

    if (!route.onRouteClick) {
      return;
    }
    const showHideSupportErrorDialog = this.props.showHideSupportErrorDialog;
    const showHideMainLoader = this.props.showHideMainLoader;
    return function () {
      showHideMainLoader(true);
      route.onRouteClick().catch((error) => {
        showHideMainLoader(false);
        showHideSupportErrorDialog(true, error.response.data.error);
      })
    }
  }

  render() {
    const { location, routes, user, config } = this.props;

    const helpFile =
      ADMINISTRATOR.indexOf(this.props.authority) >= 0
        ? 'help.html'
        : this.props.authority === 'websilo-employee'
        ? 'help_employee.html'
        : this.props.authority === 'websilo-account-approver'
        ? 'help_approver.html'
        : this.props.authority === 'websilo-customer'
        ? 'HelpGuide_Customer.html'
        : 'HelpGuide_Public.html';

    return (
      <React.Fragment>
        {user.isLoggedIn && (
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            direction="left"
            vertical
            visible={this.props.visible}
            width="wide"
          >
            <React.Fragment>
              {user.isLoggedIn &&
                routes
                  .filter(
                    (route) =>
                      (typeof route.navbarVisibility === "undefined" ||
                        route.navbarVisibility !== false) &&
                      (typeof route.accessRoles === "undefined" ||
                        route.accessRoles.indexOf(this.props.userAccessRole) >=
                          0)
                        && (!route.enableWithSupport || route.enableWithSupport && config.supportEnabled)
                  )
                  .map((route) => (
                    <Link to={route.path} key={`link--${route.name}`}>
                      <Menu.Item
                        link
                        active={location.pathname.indexOf(route.path) >= 0}
                        // onClick={this.props.hide}
                        onClick={this.onRouteClick(route) || (() => {
                        })}
                        key={`menu-item--${routes.name}`}
                      >
                        {" "}
                        {config.maps[route.configKey]
                          ? config.maps[route.configKey]
                          : route.name}
                      </Menu.Item>
                    </Link>
                  ))}
            </React.Fragment>
          </Sidebar>
        )}
      </React.Fragment>
    );
  }
}

SideBar.propTypes = {
  // ToDo: to be defined.
  // The reason I am leaving it for now is, I am not sure about their propTypes.
  // These objects (inside props) are very complex and needs to be simplified.
  // One possible reason can be that props data is not being retrieved properly from Redux store, and its containg too much extra irrelavant data.
  hide: PropTypes.func.isRequired,
};

export default withRouter(SideBar);
