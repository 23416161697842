// Standard NDA List /Edit
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Message,
  Segment,
  Grid,
  Image,
  Label,
  Confirm,
} from "semantic-ui-react";

import {
  CountryField,
  InputField,
  DropdownField,
  TextareaField,
  PlainField,
  StateField,
  DateField,
  FileField,
  CountryCode,
  PhoneField,
} from "@/common/Form";
import update from "immutability-helper";
import { normalValidationConfigForSelfRegister } from "@/common/validations/userValidation";
import validate from "validate.js";
import { isEmpty } from "lodash";
import { removeEmptyKeys } from "@/common/utils/utils";
import {
  normalValidations,
  rejectionValidation,
  acceptanceValidation,
} from "./../validations/update-nda";
import moment from "moment";
import Uploader from "@/common/uploader";
import { GroupSelection } from "@/common/group-selection";
import { configSelector } from "@/common/selectors";
import { connect } from "react-redux";
import Api from "@/api";
import urls from "@/config/constants";
import { Link, Redirect } from "react-router-dom";
import { merge } from "lodash";
import postalCodes from "postal-codes-js";
import { dateToPacific } from "../../../utils/dateTime";
import DatePickerWithTimezone from "../../../utils/DatePickerWithTimeZone";

const statusOptions = [
  { key: "PENDING", value: "PENDING", text: "PENDING" },
  { key: "APPROVED", value: "ACTIVE", text: "ACTIVE" },
  { key: "REJECTED", value: "REJECTED", text: "REJECTED" },
];
const signerNameOptions = [
  { key: "tomLegatta", value: "Tom Legata", text: "Tom Legata" },
];
const signerTitleOptions = [
  {
    key: "vicePresidentWorldWideSales",
    value: "Vice President World Wide Sales",
    text: "Vice President World Wide Sales",
  },
];
class NDAForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DATE_FORMAT: "YYYY-MM-DD",
      phoneform: true,
      codeform: true,
      phoneformSigner: true,
      codeformSigner: true,
      code: "",
      codeSigner: "+1",
      local: false,
      localSigner: false,
      localcountry: "US",
      localpostal: "",
      localresult: true,
      data: {
        id: null,
        company: "My Company",
        initiatorName: "",
        initiatorEmail: "",
        initiatorPhone: "",
        address1: "",
        address2: "",
        infoBeingDisclosed: "",
        specificUsage: "",
        ndaDuration: "",
        contactName: "",
        contactEmail: "",
        signerName: "",
        signerTitle: "",
        signerEmail: "",
        signerCountryCode: "",
        signerPhone: "",
        city: "",
        country: "",
        state: "",
        postCode: "",
        principleBusiness: "",
        expiryDate: moment(new Date()).format("YYYY-MM-DD"),
        effectiveDate: moment(new Date()).format("YYYY-MM-DD"),
        statusNotificationEmail: "",
        status: "",
        companySignerName: "",
        companySignerTitle: "",
        companySignature: "",
        companySignDate: moment(new Date()).format("YYYY-MM-DD"),
        isSigned: true,
        fileuuid: "",
        recipients: [],
        groups: [],
        rejectionReason: "",
        selectedId: "",
        selectedItems: [],
        localpostalresult: false,
      },
      validationMsg: {},
      isError: false,
      msg: null,
      recipients: [],
      selectedItems: [],
      popupWarning: false,
    };
  }

  componentDidMount() {
    //TODO: fetch data and assign this to data:
    this.getData();
    this.getEmployeesList();
    this.fetchSignatureImage();
  }
  getData = () => {
    const { URL_NDA_EDIT } = urls.App;
    const { id } = this.props.match.params;
    const params = {
      params: {
        id,
      },
    };
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(URL_NDA_EDIT, params).then((resp) => {

      this.setState({ data: resp.data });
      this.state.localcountry = resp.data.country;
      this.state.localpostal = resp.data.postCode;
    });
  };

  fetchSignatureImage = () => {
    const { API_URL } = urls.App;
    const fetchUrl = API_URL + "admin/settings/parameter";

    const params = {
      params: {
        key: "company_signature_image_url",
      },
    };
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(fetchUrl, params).then((resp) => {
      this.setState({ imageUrl: resp.data });
    });
  };

  getEmployeesList = () => {
    const { URL_LISTINGS_USERS } = urls.App;
    const params = {
      params: {
        roles: "websilo-sysadmin,websilo-account-approver,websilo-admin",
        size: 1000
      },
    };
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(URL_LISTINGS_USERS, params).then((resp) => {
      this.setState({ recipients: resp.data.content });
    });
  };

  checkPostalCode = (e) => {
    var res = postalCodes.validate(
      this.state.localcountry,
      this.state.localpostal
    );
    if (this.state.localpostal == "") {
      res = true;
    }
    this.state.localresult = res;
    this.state.data.localpostalresult = res;
  };

  showpopupWarning = () => {
    let { data } = this.state;
    data.localpostalresult = this.state.localresult;
    let normalResult, extendedResult, result;
    if (data.status == "ACTIVE") {
      extendedResult = validate(removeEmptyKeys(data), acceptanceValidation);
    } else if (data.status == "REJECTED") {
      extendedResult = validate(removeEmptyKeys(data), rejectionValidation);
    }
    normalResult = validate(removeEmptyKeys(data), normalValidations);
    result = merge(
      normalResult ? normalResult : "",
      extendedResult ? extendedResult : ""
    );
    if (normalResult || extendedResult) {
      this.setState({
        validationMsg: result,
        loading: false,
        isError: true,
        msg: "Validation Issue Occurred. Please Fix before submission",
      });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  handleChange = (event, eventData) => {
    const { data } = this.state;
    if (eventData.name == "country") {
      this.state.localcountry = eventData.value;
      this.setState({
        data: update(data, { [eventData.name]: { $set: eventData.value } }),
      });
      this.checkPostalCode(event);
    } else if (eventData.name == "postCode") {
      this.state.localpostal = eventData.value;
      this.setState({
        data: update(data, { [eventData.name]: { $set: eventData.value } }),
      });
      this.checkPostalCode(event);
    } else {
      this.setState({
        data: update(data, { [eventData.name]: { $set: eventData.value } }),
      });
    }
  };

  handleFileChange = (eventData) => {
    const { data } = this.state;
    const { fileuuid } = eventData;
    if (fileuuid) {
      this.setState({
        data: update(data, { fileuuid: { $set: fileuuid } }),
      });
    }
  };
  onGroupChange = (groups) => {
    const { data } = this.state;
    this.setState({
      data: update(data, { groups: { $set: groups } }),
    });
  };
  validateForm = (event) => {
    let { data } = this.state;
    data.localpostalresult = this.state.localresult;
    let normalResult, extendedResult, result;
    if (data.status == "ACTIVE") {
      extendedResult = validate(removeEmptyKeys(data), acceptanceValidation);
    } else if (data.status == "REJECTED") {
      extendedResult = validate(removeEmptyKeys(data), rejectionValidation);
    }
    normalResult = validate(removeEmptyKeys(data), normalValidations);
    result = merge(
      normalResult ? normalResult : "",
      extendedResult ? extendedResult : ""
    );
    if (!normalResult && !extendedResult) {
      this.setState({ validationMsg: {}, loading: true }, () => {
        this.save();
      });
    } else {
      this.setState({
        validationMsg: result,
        loading: false,
        isError: true,
        msg: "Validation Issue Occurred. Please Fix before submission",
      });
    }
  };

  save = () => {
    // TODO: submit call
    var val2 =
      this.state.code +
      this.state.data.initiatorPhone.substring(
        this.state.data.initiatorPhone.indexOf("(")
      );
    if (this.state.data.signerPhone) {
      var val1 =
        this.state.codeSigner +
        this.state.data.signerPhone.substring(
          this.state.data.signerPhone.indexOf("(")
        );
    } else {
      val1 = "";
    }
    let { data } = this.state;
    data.signerPhone = val1;
    data.initiatorPhone = val2;

    const { URL_NDA_EDIT } = urls.App;
    const { id } = this.props.match.params;
    const params = {
      params: {
        id,
      },
    };
    let body = {
      ...data,
    };
    if (data.status === 'REJECTED' && data.recipients) {
      const recipients = data.recipients.map(x => x.email).join(",")
      body.recipients = recipients
    }
    // cross browser issue:
    body.effectiveDate = data.effectiveDate;
    body.expiryDate = data.expiryDate;

    const instance = Api.AxiosInstance.getInstance();
    return instance
      .post(URL_NDA_EDIT, body, params)
      .then((resp) => {
        console.log("response after post", resp);
        this.setState(
          {
            loading: false,
            msg: "NDA updated Successfully!",
            isError: false,
            redirect: true,
          },
          this.getData
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          msg: "Error Occurred While Updating NDA!",
          isError: true,
        });
      });
  };

  onSelectionChange = (e) => {
    const { data, selectedItems } = this.state;
    const idList = e.map((l) => {
      const i = {
        email: l.value
      };
      return i;
    })
    this.setState({
      data: update(data, { recipients: { $set: idList } }),
    });
  };

  download = (e, fileuuid) => {
    const { URL_DOWNLOAD_PENDING_NDA_DOC } = urls.App;
    const { id } = this.props.match.params;
    // add fileuuid into the call as well...
    const params = {
      params: {
        id,
        fileuuid,
      },
    };
    // e.preventDefault();
    Api.AxiosInstance.getInstance()
      .get(URL_DOWNLOAD_PENDING_NDA_DOC, params)
      .then((resp) => {
        let win = window.open(resp.data, "_blank");
        win.focus();
      })
      .catch((err) =>
        this.setState({
          isError: true,
          msg: "An error occured while downloading the NDA document.",
        })
      );
  };
  formatCountryCode(value) {
    this.state.code = value;
    if (this.state.codeform) {
      var phone = String(this.state.data.initiatorPhone);
      if (phone[0] == "+") {
        this.state.code = phone.substring(0, phone.indexOf("("));
      }
      return this.state.code;
    } else {
      this.state.code = value;
      return this.state.code;
    }
  };
  countryCodeChange = (event, data) => {
    this.setState({ phoneform: false });
    this.setState({ codeform: false });
    this.state.code = data.value;
  };
  isNumeric(value) {
    return /^\d+$/.test(value);
  }
  formatphone(value) {
    var phone = String(value);
    if (this.state.phoneform) {
      var phone = String(value);
      var plusvalue = "";
      if (phone[0] == "+") {
        this.state.code = phone.substring(0, phone.indexOf("("));
        this.state.data.initiatorPhone = phone.substring(phone.indexOf("("));
        for (var i = phone.indexOf("("); i < phone.length; i++) {
          if (this.isNumeric(phone.charAt(i))) {
            plusvalue = plusvalue + phone.charAt(i);
          }
        }
        phone = plusvalue;
        phone =
          "(" +
          phone.substring(0, 3) +
          ") " +
          phone.substring(3, 6) +
          " " +
          phone.substring(6, phone.length);
        value = phone;
        return phone;
      } else if (phone[0] == "(" && phone[4] == ")" && phone[5] == " ") {
        return value;
      } else {
        phone =
          "(" +
          phone.substring(0, 3) +
          ") " +
          phone.substring(3, 6) +
          " " +
          phone.substring(6, phone.length);
        value = phone;
        return phone;
      }
    } else if (phone[0] == "+") {
      this.state.code = phone.substring(0, phone.indexOf("("));
      return phone.substring(phone.indexOf("("));
    } else {
      return phone;
    }
  };

  onPhoneChange = (event, eventData) => {
    this.setState({ phoneform: false });
    this.setState({ codeform: false });
    let cond = event.target.value;
    const { data } = this.state;
    var phone = "";
    for (var i = 0; i < cond.length; i++) {
      if (this.isNumeric(cond.charAt(i))) {
        phone = phone + cond.charAt(i);
      }
    }
    if (phone.length < 3) {
      this.setState({
        data: update(data, { [eventData.name]: { $set: phone } }),
      });
    } else if (
      phone.length == 3 &&
      cond.charAt(cond.length - 1) == ")" &&
      cond.length == 5
    ) {
      this.setState({
        data: update(data, {
          [eventData.name]: { $set: phone.substring(0, 2) },
        }),
      });
    } else if (phone.length == 3) {
      this.setState({
        data: update(data, {
          [eventData.name]: { $set: "(" + phone.substring(0, 3) + ") " },
        }),
      });
    } else if (phone.length > 3 && phone.length < 6) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set: "(" + phone.substring(0, 3) + ") " + phone.substring(3),
          },
        }),
      });
    } else if (phone.length == 6 && this.state.local == false) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " ",
          },
        }),
      });
      this.setState({ local: true });
    } else if (phone.length == 6 && this.state.local == true) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set: "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6),
          },
        }),
      });
      this.setState({ local: false });
    } else if (phone.length >= 6 && phone.length < 12) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              "(" +
              phone.substring(0, 3) +
              ") " +
              phone.substring(3, 6) +
              " " +
              phone.substring(6),
          },
        }),
      });
    } else if (phone.length >= 12) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              "(" +
              phone.substring(0, 3) +
              ") " +
              phone.substring(3, 6) +
              " " +
              phone.substring(6, 11),
          },
        }),
      });
    }
  };

  formatCountryCodeSigner(value) {
    this.state.codeSigner = value;
    if (this.state.codeformSigner) {
      var phone = String(this.state.data.signerPhone);
      if (phone[0] == "+") {
        this.state.codeSigner = phone.substring(0, phone.indexOf("("));
      }
      return this.state.codeSigner;
    } else {
      this.state.codeSigner = value;
      return this.state.codeSigner;
    }
  }
  countryCodeChangeSigner = (event, data) => {
    this.setState({ phoneformSigner: false });
    this.setState({ codeformSigner: false });
    this.state.codeSigner = data.value;
  };
  formatphoneSigner(value) {
    var phone = String(value);
    if (phone == "" || phone == "undefined") {
      return phone;
    }
    if (this.state.phoneformSigner) {
      var phone = String(value);
      var plusvalue = "";
      if (phone[0] == "+") {
        this.state.codeSigner = phone.substring(0, phone.indexOf("("));
        this.state.data.signerPhone = phone.substring(phone.indexOf("("));
        for (var i = phone.indexOf("("); i < phone.length; i++) {
          if (this.isNumeric(phone.charAt(i))) {
            plusvalue = plusvalue + phone.charAt(i);
          }
        }
        phone = plusvalue;
        phone =
          "(" +
          phone.substring(0, 3) +
          ") " +
          phone.substring(3, 6) +
          " " +
          phone.substring(6, phone.length);
        value = phone;
        return phone;
      } else if (phone[0] == "(" && phone[4] == ")" && phone[5] == " ") {
        return value;
      } else {
        phone =
          "(" +
          phone.substring(0, 3) +
          ") " +
          phone.substring(3, 6) +
          " " +
          phone.substring(6, phone.length);
        value = phone;
        return phone;
      }
    } else if (phone[0] == "+") {
      return phone.substring(phone.indexOf("("));
    } else {
      return phone;
    }
  }

  onPhoneChangeSigner = (event, eventData) => {
    this.setState({ phoneformSigner: false });
    this.setState({ codeformSigner: false });
    let cond = event.target.value;
    const { data } = this.state;
    var phone = "";
    for (var i = 0; i < cond.length; i++) {
      if (this.isNumeric(cond.charAt(i))) {
        phone = phone + cond.charAt(i);
      }
    }
    if (phone.length < 3) {
      this.setState({
        data: update(data, { [eventData.name]: { $set: phone } }),
      });
    } else if (
      phone.length == 3 &&
      cond.charAt(cond.length - 1) == ")" &&
      cond.length == 5
    ) {
      this.setState({
        data: update(data, {
          [eventData.name]: { $set: phone.substring(0, 2) },
        }),
      });
    } else if (phone.length == 3) {
      this.setState({
        data: update(data, {
          [eventData.name]: { $set: "(" + phone.substring(0, 3) + ") " },
        }),
      });
    } else if (phone.length > 3 && phone.length < 6) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set: "(" + phone.substring(0, 3) + ") " + phone.substring(3),
          },
        }),
      });
    } else if (phone.length == 6 && this.state.localSigner == false) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6) + " ",
          },
        }),
      });
      this.setState({ localSigner: true });
    } else if (phone.length == 6 && this.state.localSigner == true) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set: "(" + phone.substring(0, 3) + ") " + phone.substring(3, 6),
          },
        }),
      });
      this.setState({ localSigner: false });
    } else if (phone.length >= 6 && phone.length < 12) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              "(" +
              phone.substring(0, 3) +
              ") " +
              phone.substring(3, 6) +
              " " +
              phone.substring(6),
          },
        }),
      });
    } else if (phone.length >= 12) {
      this.setState({
        data: update(data, {
          [eventData.name]: {
            $set:
              "(" +
              phone.substring(0, 3) +
              ") " +
              phone.substring(3, 6) +
              " " +
              phone.substring(6, 11),
          },
        }),
      });
    }
  };
  render() {
    const {
      validationMsg,
      data,
      DATE_FORMAT,
      loading,
      msg,
      isError,
      recipients,
    } = this.state;
    const color = isError ? "red" : "green";
    const cancelUrl =
      data.status == "PENDING"
        ? "/admin/nda/pending"
        : data.status == "UNSIGNED"
          ? "/admin/nda/pending"
          : "/admin/nda";
    return (
      <Segment className="base-segment-wrapper mb-3-im">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
              <div className="header">
                <div className="side-marker" />
                Update NDA
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
              <Form error={true} loading={loading}>
                <span className="text-primary">
                  <b>Initiator Information</b>
                </span>
                <Divider />
                <InputField
                  label="Initiator Name"
                  errorMsg={validationMsg.initiatorName}
                  isError={validationMsg.initiatorName}
                  name="initiatorName"
                  onChange={this.handleChange}
                  value={data.initiatorName}
                  // width="5"
                  isRequired
                />
                <InputField
                  label="Initiator Email"
                  errorMsg={validationMsg.initiatorEmail}
                  isError={validationMsg.initiatorEmail}
                  name="initiatorEmail"
                  onChange={this.handleChange}
                  value={data.initiatorEmail}
                  // width="5"
                  isRequired
                />

                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          mobile={16}
                          tablet={5}
                          computer={5}
                          className="col-country-code"
                        >
                          <CountryCode
                            label="Initiator Phone"
                            errorMsg={validationMsg.code}
                            isError={!!validationMsg.code}
                            name="code"
                            onChange={this.countryCodeChange}
                            value={this.formatCountryCode(this.state.code)}
                            width={16}
                            isRequired
                          />{" "}
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={11}
                          computer={11}
                          className="col-mobile-phone"
                        >
                          <PhoneField
                            // label="initiator Phone"
                            errorMsg={validationMsg.initiatorPhone}
                            isError={!!validationMsg.initiatorPhone}
                            name="initiatorPhone"
                            onChange={this.onPhoneChange}
                            value={this.formatphone(
                              this.state.data.initiatorPhone
                            )}
                            width={16}
                            placeholder="Mobile Phone"
                            onBlur={this.onBlur}
                            isRequired
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>


                <span className="text-primary">
                  <b>Company Information</b>
                </span>
                <Divider />
                <PlainField label="Company Name" value={data.company} />
                <InputField
                  label="Address1"
                  // width="7"
                  errorMsg={validationMsg.address1}
                  isError={validationMsg.address1}
                  name="address1"
                  onChange={this.handleChange}
                  value={data.address1}
                  isRequired
                />
                <InputField
                  label="Address2"
                  // width="7"
                  errorMsg={validationMsg.address2}
                  isError={validationMsg.address2}
                  name="address2"
                  onChange={this.handleChange}
                  value={data.address2}
                />
                <InputField
                  label="City"
                  errorMsg={validationMsg.city}
                  isError={validationMsg.city}
                  name="city"
                  onChange={this.handleChange}
                  value={data.city}
                  // width="5"
                  isRequired
                />
                <CountryField
                  label="Country"
                  errorMsg={validationMsg.country}
                  isError={validationMsg.country}
                  onChange={this.handleChange}
                  value={data.country}
                  name="country"
                  // width="5"
                  isRequired
                />
                {/*//TODO: state should be disabled...*/}
                <StateField
                  label="State"
                  country={data.country}
                  errorMsg={validationMsg.state}
                  isError={validationMsg.state}
                  onChange={this.handleChange}
                  value={data.state}
                  name="state"
                // width="5"
                />
                <InputField
                  label="Post/Zip Code"
                  // width="3"
                  errorMsg={validationMsg.postCode}
                  isError={validationMsg.postCode}
                  name="postCode"
                  onChange={this.handleChange}
                  value={data.postCode}
                  maxlength="10"
                />
                <InputField
                  label="Principle Business"
                  errorMsg={validationMsg.principleBusiness}
                  isError={validationMsg.principleBusiness}
                  name="principleBusiness"
                  // width="5"
                  onChange={this.handleChange}
                  value={data.principleBusiness}
                  isRequired
                />
                <span className="text-primary">
                  <b>NDA Information</b>
                </span>
                <Divider />
                {/*} <TextareaField
              label="Information Being Disclosed (Max 150)"
              // width="8"
              errorMsg={validationMsg.infoBeingDisclosed}
              isError={validationMsg.infoBeingDisclosed}
              name="infoBeingDisclosed"
              value={data.infoBeingDisclosed}
              onChange={this.handleChange}
              isRequired
              maxLength={150}
            />
            <InputField
              label="Specific Usage "
              errorMsg={validationMsg.specificUsage}
              isError={validationMsg.specificUsage}
              name="specificUsage"
              // width="5"
              onChange={this.handleChange}
              value={data.specificUsage}
              isRequired
    /> */}
                <DatePickerWithTimezone
                  name="effectiveDate"
                  label="Effective Date"
                  timezone="America/Los_Angeles"
                  errorMsg={validationMsg.effectiveDate}
                  isError={validationMsg.effectiveDate}
                  format={"YYYY-MM-DD"}
                  onChange={(e) => {
                    this.handleChange(null, {
                      name: "effectiveDate",
                      value: e,
                    });
                  }}
                  selected={data.effectiveDate}
                  isRequired
                />
                {/* <DateField
              name="expiryDate"
              label="Expire Date"
              // width="5"
              errorMsg={validationMsg.expiryDate}
              isError={validationMsg.expiryDate}
              format={DATE_FORMAT}
              onChange={this.handleChange}
              value={data.expiryDate}
              isRequired
              readOnly={true}
            />
            <InputField
              label="Duration of NDA (years)"
              errorMsg={validationMsg.ndaDuration}
              isError={validationMsg.ndaDuration}
              name="ndaDuration"
              // width="3"
              onChange={this.handleChange}
              value={data.ndaDuration}
              isRequired
            />
            */}
                <PlainField
                  label="NDA"
                  name="NDA"
                  errorMsg={validationMsg.companySignerName}
                  isError={validationMsg.companySignerName}
                  value={
                    <a onClick={(e) => this.download(e)}>
                      {data.filename}
                    </a>
                  }
                />
                {/* History Table...*/}

                {data.history && (
                  <>
                    <Form.Field>
                      <label> History </label>
                      {data.history.map((history) => (
                        <div key={history.id}>
                          <a
                            onClick={(e) => this.download(e, history.id)}
                          >
                            {history.filename}
                          </a>
                        </div>
                      ))}
                    </Form.Field>
                  </>
                )}
                <FileField
                  label="New Nda File"
                  isError={validationMsg.newNdaFile}
                  name="fileuuid"
                  onChange={Uploader(this.handleFileChange)}
                  value={data.fileuuid}
                  multipleSelection={false}
                />
                <span className="text-primary">
                  <b>Contact / Signer Information</b>
                </span>
                <Divider />
                <InputField
                  label="Contact Name "
                  errorMsg={validationMsg.contactName}
                  isError={validationMsg.contactName}
                  name="contactName"
                  // width="5"
                  onChange={this.handleChange}
                  value={data.contactName}
                  isRequired
                />
                <InputField
                  label="Contact Email "
                  errorMsg={validationMsg.contactEmail}
                  isError={validationMsg.contactEmail}
                  name="contactEmail"
                  // width="5"
                  onChange={this.handleChange}
                  value={data.contactEmail}
                  isRequired
                />
                {data.status == "UNSIGNED" && (
                  <PlainField
                    label="Sign NDA Url"
                    value={
                      <a href={data.ndaLink} target="_blank">
                        {data.ndaLink}
                      </a>
                    }
                  />
                )}
                <InputField
                  label="Signer Name"
                  errorMsg={validationMsg.signerName}
                  isError={validationMsg.signerName}
                  name="signerName"
                  // width="5"
                  onChange={this.handleChange}
                  value={data.signerName}
                />
                <InputField
                  label="Signer Title"
                  errorMsg={validationMsg.signerTitle}
                  isError={validationMsg.signerTitle}
                  name="signerTitle"
                  // width="5"
                  onChange={this.handleChange}
                  value={data.signerTitle}
                />
                <InputField
                  label="Signer Email"
                  errorMsg={validationMsg.signerEmail}
                  isError={validationMsg.signerEmail}
                  name="signerEmail"
                  // width="5"
                  onChange={this.handleChange}
                  value={data.signerEmail}
                />
                {/*
                <InputField
                  label="Signer Phone"
                  errorMsg={validationMsg.signerPhone}
                  isError={validationMsg.signerPhone}
                  name="signerPhone"
                  // width="5"
                  onChange={this.handleChange}
                  value={data.signerPhone}
                /> */}

                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={8}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column
                          mobile={16}
                          tablet={5}
                          computer={5}
                          className="col-country-code"
                        >
                          <CountryCode
                            label="Country Code"
                            errorMsg={validationMsg.code}
                            isError={!!validationMsg.code}
                            name="signerCode"
                            onChange={this.countryCodeChangeSigner}
                            value={this.formatCountryCodeSigner(
                              this.state.codeSigner
                            )}
                            width={16}
                            isRequired
                          />{" "}
                        </Grid.Column>
                        <Grid.Column
                          mobile={16}
                          tablet={11}
                          computer={11}
                          className="col-mobile-phone"
                        >
                          <PhoneField
                            label="Signer Phone"
                            errorMsg={validationMsg.signerPhone}
                            isError={!!validationMsg.signerPhone}
                            name="signerPhone"
                            onChange={this.onPhoneChangeSigner}
                            value={
                              this.state.data.signerPhone !== undefined ?
                               this.formatphoneSigner( this.state.data.signerPhone)
                               :  null
                              }
                            width={16}
                            placeholder="Mobile Phone"
                            onBlur={this.onBlur}
                            isRequired
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>

                <span className="text-primary">
                  <b>Status Information</b>
                </span>
                <Divider />
                {/*<InputField
                  label="Status Notification Email"
                  errorMsg={validationMsg.statusNotificationEmail}
                  isError={validationMsg.statusNotificationEmail}
                  name="statusNotificationEmail"
                  // width="5"
                  onChange={this.handleChange}
                  value={data.statusNotificationEmail}
                /> */}

                {data.status == "UNSIGNED" ? (
                  <PlainField label="Status" value="Un-Signed" />
                ) : (
                  <DropdownField
                    errorMsg={validationMsg.status}
                    isError={validationMsg.status}
                    isRequired
                    label="Status"
                    name="status"
                    // width="5"
                    value={data.status}
                    onChange={this.handleChange}
                    options={statusOptions}
                    placeholder="Select Status Type..."
                  />
                )}
                {data.status == "ACTIVE" && (
                  <>
                    <span className="text-primary">
                      <b> Groups </b>
                    </span>
                    <Divider />
                    <label> Groups </label>
                    <GroupSelection
                      onChange={this.onGroupChange}
                      isError={validationMsg.groups}
                      errorMsg="You have not selected any group"
                      recipients={data.groups}
                      entity="NdaGroups"
                      entityId={this.props.match.params.id}
                    />
                    <span className="text-primary">
                      <b>Company Signer Information</b>
                    </span>
                    <Divider />
                    {/*<PlainField
                      label="Company Signer Name"
                      name="companySignerName"
                      errorMsg={validationMsg.companySignerName}
                      isError={validationMsg.companySignerName}
                      value={this.props.config.maps.vp_sales_name}
                    />
                    <DropdownField
                      errorMsg={validationMsg.companySignerTitle}
                      isError={validationMsg.companySignerTitle}
                      isRequired
                      label="Company Signer Title"
                      name="companySignerTitle"
                      // width="5"
                      value={data.companySignerTitle}
                      onChange={this.handleChange}
                      options={signerTitleOptions}
                      placeholder="Select Signer Title..."
                    /> */}
                    <InputField
                      label="Company Signer Name"
                      errorMsg={validationMsg.companySignerName}
                      isError={validationMsg.companySignerName}
                      name="companySignerName"
                      // width="3"
                      onChange={this.handleChange}
                      value={data.companySignerName}
                      isRequired
                    />
                    <InputField
                      label="Company Signer Title"
                      errorMsg={validationMsg.companySignerTitle}
                      isError={validationMsg.companySignerTitle}
                      name="companySignerTitle"
                      // width="3"
                      onChange={this.handleChange}
                      value={data.companySignerTitle}
                      isRequired
                    />{" "}
                    <InputField
                      label="Company Signature"
                      errorMsg={validationMsg.companySignature}
                      isError={validationMsg.companySignature}
                      name="companySignature"
                      // width="3"
                      onChange={this.handleChange}
                      value={data.companySignature}
                      isRequired
                    />
                    {/* <div className="field">
                      <label>Company Signature </label>
                      {this.state.imageUrl && (
                        <Image size="tiny" src={this.state.imageUrl} wrapped />
                      )}
                      </div> */}
                  </>
                )}

                {data.status == "REJECTED" && (
                  <>
                    <span className="text-primary">
                      <b>Rejection Reason</b>
                    </span>
                    <Divider />
                    <TextareaField
                      label="Reason"
                      // width="8"
                      errorMsg={validationMsg.rejectionReason}
                      isError={validationMsg.rejectionReason}
                      name="rejectionReason"
                      maxLength={"500"}
                      value={data.rejectionReason}
                      onChange={this.handleChange}
                      isRequired
                    />
                    <Divider />
                    <Form>
                      <Form.Field>
                        {<label> CC To </label>}
                        <Select maxMenuHeight="120px"
                          classNamePrefix="react-select" className="select-search" options={recipients.map((rec) => {

                            const r = {
                              value: rec.email,
                              label: rec.email,
                            };
                            return r;
                          }
                          )}
                          onChange={this.onSelectionChange}
                          isMulti />
                      </Form.Field>
                    </Form>
                  </>
                )}
                <Divider />
                <Grid.Row>
                  <button
                    onClick={this.showpopupWarning}
                    disabled={loading}
                    className="button-primary"
                  >
                    Submit
                  </button>

                  <Link to={cancelUrl}>
                    <button className="button-basic">Discard</button>
                  </Link>

                  {msg && (
                    <span style={{ color: color, marginLeft: "5px" }}>
                      {msg}
                    </span>
                  )}
                </Grid.Row>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.state.redirect && <Redirect to={{ pathname: cancelUrl }} />}
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content="Are you sure you want to update this non-disclosure agreement?"
        />
      </Segment>
    );
  }
}

NDAForm.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  config: configSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NDAForm);
