import React from "react";
import Api from "@api";
import { connect } from "react-redux";
import Constants from "@constants";
import {dateToPacific} from "@/utils/dateTime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tableview from "@/common/tableview";
import FileIcon from "@/common/file-icon";
import { Link } from "react-router-dom";
import { Modal, Segment, Grid, Dimmer, Loader, Responsive } from "semantic-ui-react";

class Productsearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      startDate: "",
      endDate: "",
      highlight: false,
      items: {
        content: []
      },
      columns: [
        {
          name: "Name",
          key: "name",
          width: "3",
          showTooltip: true,
        },
        {
          name: "Description",
          key: "description",
          width: "3",
          showTooltip: true,
        },
        {
          name: "Category",
          key: "category",
          width: "2",
          showTooltip: true,
        },
        {
          name: "Updated On",
          key: "modification_date",
          width: "2",
          showTooltip: true,
        },
        {
          name: "Platform/OS",
          key: "platform",
          width: "2",
          showTooltip: true,
        },
        {
          name: "Status",
          key: "status",
          width: "1",
          showTooltip: true,
        },
        {
          name: "Type",
          key: "item_type",
          width: "1",
          class: "center",
          showTooltip: true,
        },
        { name: "Actions", key: "actions", width: "3" }
      ],
      listings: {
        content: [],
        number: 0
      },
      showAdvancedOptions: false,
      advancedSearchButtonText: "Advanced Search",
      pageNum: 0,
      isAdmin: false
    };
  }

  componentDidMount() {
    console.log("user", this.props);
    this.hasAdminAuthority();
  }

  search = event => {
    event.preventDefault();

    let strElement = document.getElementById("search_box");
    let str = strElement.value;
    if (str.length === 0) {
      return;
    }
    this.setState({ isLoading: true });
    var e = document.getElementById("itemfiletype");
    // should be revamped and organized in state
    let data = {
      keyword: str,
      itemname:
        document.getElementById("itemname") !== null
          ? document.getElementById("itemname").checked
          : true,
      itemdesc:
        document.getElementById("itemdesc") !== null
          ? document.getElementById("itemdesc").checked
          : true,
      filename:
        document.getElementById("filename") !== null
          ? document.getElementById("filename").checked
          : true,
      filedesc:
        document.getElementById("filedesc") !== null
          ? document.getElementById("filedesc").checked
          : true,
      itemfiletype:
        document.getElementById("itemfiletype") !== null
          ? document.getElementById("itemfiletype").options[
              document.getElementById("itemfiletype").selectedIndex
            ].value
          : null,
      itemplatform:
        document.getElementById("itemplatform") !== null
          ? document.getElementById("itemplatform").options[
              document.getElementById("itemplatform").selectedIndex
            ].value
          : null,
      itemcategory:
        document.getElementById("itemcategory") !== null
          ? document.getElementById("itemcategory").options[
              document.getElementById("itemcategory").selectedIndex
            ].value
          : null,
      itemstatus:
        document.getElementById("itemstatus") !== null
          ? document.getElementById("itemstatus").options[
              document.getElementById("itemstatus").selectedIndex
            ].value
          : null,
      startdate:
        document.getElementById("startdate") !== null
          ? document.getElementById("startdate").value
          : null,
      enddate:
        document.getElementById("enddate") !== null
          ? document.getElementById("enddate").value
          : null,
      page: this.state.pageNum,
      pageSize: 10
    };
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_SEARCH_ITEMS, data)
      .then(resp => {
        this.setState({
          listings: this.transformData(resp.data),
          isLoading: false
        });
      })
      .catch(e =>
        this.setState({
          isLoading: false
        })
      );
  };

  onPageChange = e => {
    this.setState(
      {
        pageNum: e.page
      },
      this.search
    );
  };

  transformData = data => {
    let transformedData = Object.assign({}, data);
    let content = [];
    let isAdmin = this.state.isAdmin;
    if (data) {
      transformedData.content.map(el => {
        let row = Object.assign({}, el);
        row.actions = (
          <>
            {
            isAdmin && <Link
              target="_blank"
              to={this.edit(null, row.id, row.parent_product_id)}
            >
              <span className="icon-edit" title="Edit" name="edit" />
            </Link> 
            }
            <a href="#" onClick={e => this.download(e, row.id)}>
              {
                <span
                  className="icon-download"
                  title="Download"
                  name="download"
                />
              }
            </a>
          </>
        );
        row.modification_date = 
          <>{dateToPacific(row.modification_date,"MM/DD/YYYY hh:mm a")}</>
        ;
        row.item_type = (
          <FileIcon
            filename={row.filename ? row.filename : "other"}
            size="large"
          ></FileIcon>
        );
        row.highlight = this.parseHighlighter(row.highlight);
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  parseHighlighter = (content) => {
    if(content === null){
      return (<i>None</i>);
    }
    
    let highlightOpener = '<mark>';
    let highlightCloser = '</mark>';
    let parts = content.split(highlightOpener);
    let newContent = [];
    newContent.push(parts.shift().replace(highlightCloser));
    parts.map(el => {
      let closeBreak = el.split(highlightCloser);
      newContent.push(<mark>{closeBreak.shift()}</mark>);
      newContent.push(closeBreak.pop());
    });

    this.setState({highlight: true});
    return newContent;
  }

  edit = (e, id, parent_product_id) => {
    let editUrl = "/products/browser/:pid/items/:iid/edit";
    editUrl = editUrl.replace(":pid", parent_product_id);
    editUrl = editUrl.replace(":iid", id);
    return editUrl;
  };

  download = (e, id) => {
    e.preventDefault();
    Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ITEM_DOWNLOAD + "?itemId=" + id, { timeout :0})
      .then(resp => {
        let win = window.open(resp.data, "_blank");
        win.focus();
      })
      .catch(err =>
        this.setState({
          showError: true,
          errorMsg: "An error occured while downloading the product."
        })
      );
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  handleEndDateChange = enddate => {
    this.setState({ endDate: enddate });
  };

  handleStartDateChange = startdate => {
    this.setState({ startDate: startdate });
  };

  hasAdminAuthority() {
    const roles =
      this.props.user.loggedin &&
      this.props.user.loggedin.user &&
      this.props.user.loggedin.user.authorities;
    const hasAdminRole =
      roles &&
      roles.reduce((a, r) => {
        if (
          r.authority === "websilo-sysadmin" ||
          r.authority === "websilo-employee" ||
          r.authority === "websilo-admin"
        ) {
          return true;
        }
        return a;
      }, false);
    //if (hasAdminRole) this.props.dispatch(this.props.actions.isAdmin());
    if (hasAdminRole) this.setState({ isAdmin: hasAdminRole });
  }

  advancedSearchControl = e => {
    e.preventDefault();
    let toggle = true;
    let advancedBtnVal = "Simple Search";
    if (this.state.showAdvancedOptions) {
      toggle = false;
      advancedBtnVal = "Advanced Search";
    }
    this.setState(
      {
        showAdvancedOptions: toggle,
        advancedSearchButtonText: advancedBtnVal
      },
      () => {
        if (this.state.showAdvancedOptions) {
          document.getElementById("itemname").checked = true;
          document.getElementById("itemdesc").checked = true;
          document.getElementById("filename").checked = true;
          document.getElementById("filedesc").checked = true;
        }
      }
    );
  };

  render() {
    const item_types = [];
    let item_keys = this.props.config.maps.item_types ? Object.keys(this.props.config.maps.item_types) : [];
    let item_vals = this.props.config.maps.item_types ? Object.values(this.props.config.maps.item_types): [];
    for (
      var i = 1;
      i < item_keys.length;
      i++
    ) {
      item_types.push(<option value={item_keys[i]}>{item_vals[i]}</option>);
    }
    const item_categories = [];
    let category_keys = this.props.config.maps.item_categories ? Object.keys(this.props.config.maps.item_categories) : [];
    let category_vals = this.props.config.maps.item_categories ? Object.values(this.props.config.maps.item_categories) : [];
    for (var i = 1; i < category_keys.length; i++) {
      item_categories.push(
        <option value={category_keys[i]}>{category_vals[i]}</option>
      );
    }

    return (
      <div className="products-container">
        <Segment className="base-segment-wrapper">
          <form>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="header">
                    <div className="side-marker" />
                    Search
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className="ui form common-filter-wrapper ">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={8}
                  largeScreen={8}
                >
                <div class="field">
                    <div class="ui input">

                  <input
                    type="text"
                    className="search-bar"
                    id="search_box"
                    placeholder="Search Items"
                  />

                  </div>
                  </div>

                </Grid.Column>

                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={8}
                  largeScreen={8}
                  className="button-inline-display"
                >
                 <div class="field">
                    <div class="ui input">

                  <button
                    className="button-primary"
                    id="search_button"
                    onClick={this.search}
                  >
                    Search
                  </button> </div>
                  </div>
<div class="field">
                    <div class="ui input">
                  <button
                    className="button-basic"
                    onClick={this.advancedSearchControl}
                  >

                    {this.state.advancedSearchButtonText}
                  </button>

                  </div>
                  </div>


                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                >
                  {this.state.showAdvancedOptions && (
                    <div
                      className="search-fields ui form filter-box-wrapper"
                      id="advanced-options"
                    >
                      <Grid>

                      <Grid.Row>
                          <Grid.Column
                            mobile={16}
                            tablet={4}
                            computer={4}
                            largeScreen={4}
                          >

                           <div className="field field-search-wrapper">
                              <label>Search in</label>
                              <div className="seach-item">
                                <input
                                  name="itemname"
                                  id="itemname"
                                  type="checkbox"
                                  value="true"
                                />
                                <span>Item Name</span>
                              </div>

                              <div className="seach-item">
                                <input
                                  name="itemdesc"
                                  id="itemdesc"
                                  type="checkbox"
                                  value="true"
                                />
                                <span>Item Description</span>
                              </div>
                              <div className="seach-item">
                                <input
                                  name="filename"
                                  id="filename"
                                  type="checkbox"
                                  value="true"
                                />
                                <span>File Name</span>
                              </div>
                              <div className="seach-item">
                                <input
                                  name="filedesc"
                                  id="filedesc"
                                  type="checkbox"
                                  value="true"
                                />
                                <span>File Contents</span>
                              </div>
                            </div>
                          </Grid.Column>


                          <Grid.Column
                            mobile={16}
                            tablet={12}
                            computer={12}
                            largeScreen={12}
                          >

<Grid>
                        <Grid.Row>
                         
                          <Grid.Column
                            mobile={16}
                            tablet={8}
                            computer={8}
                            largeScreen={8}
                          >

                            <div className="field">
                              <label>Item File Type</label>
                              <div class="ui input">
                                <select name="itemfiletype" id="itemfiletype">
                                  <option value="0" selected="">
                                    All
                                  </option>
                                  {item_types}
                                </select>
                              </div>
                            </div>

                             {/* <div className="field">
                              <label>From Date</label>
                              <div class="ui input">
                                <DatePicker
                                  name="startdate"
                                  id="startdate"
                                  style={{
                                    width: "100%"
                                  }}
                                  selected={this.state.startDate}
                                  onChange={this.handleStartDateChange}
                                />
                              </div> 
                            </div>*/}

                            <div className="field">
                              <label>Item Status</label>
                              <div class="ui input">
                                <select name="itemstatus" id="itemstatus">
                                  <option value="ALL">All</option>
                                  <option value="ACTIVE">Active</option>
                                  <option value="DISABLED">Disabled</option>
                                </select>
                              </div>
                            </div>


                          </Grid.Column>
                          <Grid.Column
                            mobile={16}
                            tablet={8}
                            computer={8}
                            largeScreen={8}
                          >

                            <div className="field">
                              <label>Item Platform</label>
                              <div class="ui input">
                                <select name="itemplatform" id="itemplatform">
                                  <option value="1" selected="">
                                    All
                                  </option>
                                  <option value="14">FreeBSD</option>
                                  <option value="3">Linux</option>
                                  <option value="15">MS Windows</option>
                                  <option value="2">Solaris</option>
                                  <option value="16">VxWorks</option>
                                </select>
                              </div>
                            </div>

                             <div className="field">
                              <label>Item Category</label>
                              <div class="ui input">
                                <select name="itemcategory" id="itemcategory">
                                  <option value="0" selected="">
                                    All
                                  </option>
                                  {item_categories}
                                </select>
                              </div>
                            </div>

                             <div className="field">
                              {/* <label>To Date</label>
                              <div class="ui input">
                                <DatePicker
                                  name="enddate"
                                  id="enddate"
                                  style={{
                                    width: "100%"
                                  }}
                                  selected={this.state.endDate}
                                  onChange={this.handleEndDateChange}
                                />
                              </div> */}
                            </div>


                          </Grid.Column>

                        </Grid.Row>

                      

                       
  </Grid>



                          </Grid.Column>


                         
                        </Grid.Row>






                      </Grid>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                >
                  <Segment>
                    <Dimmer active={this.state.isLoading} inverted>
                      <Loader>Loading</Loader>
                    </Dimmer>
                    <div className="search-gridview">
                      <div className="row">
                        <div className="col-md-12">
                          {this.state.listings.content && (
                            <React.Fragment>
                              {
                                this.state.highlight &&
                                <div className="table-wrapper-with-pagination">
                                  <Tableview
                                    paginate
                                    data={this.state.listings}
                                    columns={this.state.columns}
                                    onPaginationChange={this.onPageChange}
                                    noDataMessage="No items"
                                    row_extras_field="highlight"
                                    row_extras_prepend={(<strong>Matches in document content:</strong>)}
                                  />
                                </div>
                              }
                              {
                                !this.state.highlight &&
                                <div className="table-wrapper-with-pagination">
                                  <Tableview
                                    paginate
                                    data={this.state.listings}
                                    columns={this.state.columns}
                                    onPaginationChange={this.onPageChange}
                                    noDataMessage="No items"
                                  />
                                </div>
                              }
                              <Modal
                                open={this.state.showError}
                                onClose={() => {
                                  this.closeErrorPopup();
                                }}
                                centered={true}
                                size="tiny"
                                content={this.state.errorMsg}
                                actions={[
                                  {
                                    key: "warningDismiss",
                                    content: "OK",
                                    primary: true
                                  }
                                ]}
                              ></Modal>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </form>
        </Segment>
      </div>
    );
  }
}
const UserApi = Api.User;

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(UserApi.login(username, password)),
    logout: () => dispatch(UserApi.logout()),
    oAuthLogin: (type, code) => dispatch(UserApi.oAuthLogin(type, code))
  };
};

const mapStateToProps = state => ({
  user: state.user,
  config: state.common.config
});

const mappedProductsearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(Productsearch);

export default mappedProductsearch;
