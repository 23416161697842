import React from "react";
import { get, isEmpty } from "lodash";
import {
  Button,
  Icon,
  Modal,
  Segment,
  Confirm,
  Responsive,
  Grid,
} from "semantic-ui-react";
import update from "immutability-helper";
import Constants from "@/config/constants";
import { CardviewMobile } from "@/common/cardview";
import Tableview from "@/common/tableview";
import urls from "@/config/constants";
import Api from "@/api";
import UserListing from "@/common/user-selection/user-listing";
import { dateToPacific } from "@/utils/dateTime";
import moment from 'moment';

class PackageUsers extends React.Component {
  Constants = {
    USER_COLUMNS: Object.freeze([
      { name: "Username", key: "username", showTooltip: true, cb: () => {
        this.sortByHeader("username", "Username");
      }, },
      { name: "Name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Name");
      }, },
      { name: "Company", key: "company", showTooltip: true, cb: () => {
        this.sortByHeader("company", "Company");
      }, },
      { name: "Assigned By", key: "updatedBy", showTooltip: true, cb: () => {
        this.sortByHeader("updatedBy", "Assigned By");
      }, },
      { name: "Assigned On", key: "updatedOn", showTooltip: true, cb: () => {
        this.sortByHeader("updatedOn", "Assigned On");
      }, },
    ]),
    RESPONSIVE_USER_COLUMNS: Object.freeze([
      { name: "Username", key: "username" },
      { name: "Name", key: "name" },
      { name: "Company", key: "company" },
      { name: "Assigned By", key: "updatedBy" },
      { name: "Assigned On", key: "updatedOn" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedPackages: { content: [] },
      assignedUsers: { content: [] },
      errorMsg: null,
      showError: false,
      selectedUsers: [],
      showUserListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
      data: {},
      redirectTo: false,
      userId: null,
      pageNum: 0,
      isDescSearch: true,
      sortingKey: "",
      selectedStatedUsers: [],
      selectedUsersIndexes: [],
      checkedUserIds: [],
    };
  }

  componentDidMount() {
    this.fetchAssignedUsers();
  }

  // transformData = (data) => {
  //   let transformedData = Object.assign({}, data);
  //   let content = [];
  //   if (data) {
  //     transformedData.content.map((el) => {
  //       let row = Object.assign({}, el);
  //       row.updatedOn = (
  //         <> {dateToPacific(row.updatedOn, "MM/DD/YYYY hh:mm a")}</>
  //         // <>  {row.updatedOn}</>
  //       );
  //       content.push(row);
  //     });
  //   }
  //   transformedData.content = content;
  //   return transformedData;
  // };
  transformData = (data) => {
    return data.map((d) => {
      return {
        ...d,
        updatedOn: dateToPacific(
          d.updatedOn,
          "MM/DD/YYYY hh:mm a"
        ),
      };
    });
  };
  showDeleteWarningAndSetId = (e, id) => {
    this.setState({
      deleteWarning: true,
      userId: id,
    });
  };

  mapData = (data) => {
    // const arrayOfObj = Object.entries(data).map((e) => ({ [e[0]]: e[1] }));
    const arrayOfObj = Object.entries(data).map((e) => e[1]);
    return arrayOfObj;
  };

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({
      sortingKey: key
    },()=>{
      if (!isDesc) {
        query = `${key},ASC`;
      }
      this.setState({ sortBy: query }, () => {
        this.Constants.USER_COLUMNS = this.Constants.USER_COLUMNS.map((obj) => {
          if (obj.name == title) {
            return {
              ...obj,
              isDesc: isDesc,
              isActive: true,
            };
          } else
            return {
              ...obj,
              isActive: false,
            };
        });
        this.setState({ isDescSearch: !isDesc });
        this.fetchAssignedUsersSorted();
      });
    });
  };

  fetchAssignedUsersSorted = () => {
    const { assignedUsers } = this.state;
    const { sortingKey } = this.state;
    const { isDescSearch } = this.state;
    let data = this.state.assignedUsers.content.sort(function(a, b){
        var objA=(String(a[sortingKey])).toLowerCase(), objB=(String(b[sortingKey])).toLowerCase();
        sortingKey==="updatedOn"?objA=moment(a[sortingKey]):objA=objA
        sortingKey==="updatedOn"?objB=moment(b[sortingKey]):objB=objB
        if(!isDescSearch){
        if (objA < objB)
         return -1;
        if (objA > objB)
         return 1;
        return 0;}
        else{
         if (objA > objB)
          return -1;
         if (objA < objB)
          return 1;
         return 0;
        }
       });
    this.setState({
      assignedUsers: update(assignedUsers, {
        content: { $set:data,}})
    });
  }

  fetchAssignedUsers = () => {
    const { data, assignedUsers } = this.state;
    const id = get(this.props, ["match", "params", "id"]);

    if (id > 0) {
      // Need to add this constants file
      const { URL_ADD_VIDEOS_DIR } = Constants.App;
      const params = {
        params: { id, page: this.state.pageNum },
      };
      const instance = Api.AxiosInstance.getInstance();
      instance.get(`${URL_ADD_VIDEOS_DIR}/single`, params).then((resp) => {
        // const transformedData = this.transformData({
        //   content: resp.data.userids,
        // });
        this.setState({
          assignedUsers: update(assignedUsers, {
            content: { $set: this.transformData(resp.data.userids) },
          }),
        });

        this.setState({
          //assignedUsers: transformedData,
          isLoaded: true,
        });
      });
    }
  };
  onAssign = () => {
    this.setState({ showUserListing: true });
  };

  doAssign = (data) => {
    this.setState(
      {
        showUserListing: false,
        selectedUsers: data,
        msg: "Something went wrong while assigning new user(s).",
        action: "ADD_USER",
        selectedStatedUsers: [],
        selectedUsersIndexes: [],
        checkedUserIds: [],
      },
      () => {
        this.updatePackages();
      }
    );
  };

  onUnassign = () => {
    const { selectedUsers } = this.state;

    if (isEmpty(selectedUsers)) {
      this.setState({
        showError: true,
        errorMsg:
          "No Video selected for deletion. Please select one or more videos.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "DELETE_USER",
        "Something went wrong while unassigning the video(s)."
      );
    }
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  updatePackages = () => {
    const id = get(this.props, ["match", "params", "id"]);
    const { selectedUsers, action, msg } = this.state;

    const params = { params: { id } };
    let userIds = [];
    selectedUsers.map((user) => userIds.push({ id: user.id }));
    let url = Constants.App.URL_VIDEOS;
    let payload = { actiontype: action, userids: userIds };
    Api.AxiosInstance.getInstance()
      .post(url, payload, params)
      .then((resp) => {
        this.fetchAssignedUsers();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: msg,
        });
      });
  };

  onUserSelection = (e) => {
    //this.setState({ selectedUsers: e.data });
    let selectedStatedUsers = this.state.selectedStatedUsers;
    let ids = this.state.checkedUserIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedUsers.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedUsers.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedUsers = selectedStatedUsers.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedUsers.map((s) => s.id);
    }
    this.setState({ checkedUserIds: ids });

    this.setState({
      selectedStatedUsers: selectedStatedUsers,
      selectedUsers: selectedStatedUsers
    });
  };

  onAllItemSelection = (selectedUsers, flag) => {
    let {selectedStatedUsers} = this.state;
    let ids=[];
    if(flag){
      selectedUsers.map((user)=>{
      selectedStatedUsers = selectedStatedUsers.filter((entity) => {
          return entity.id !== user.id;
        })
      });
    }else{
      selectedUsers.map((user)=>{
          if (user) {
            const alreadyPushed = selectedStatedUsers.find(
              (element) => element.id == user.id
            );
            if (!alreadyPushed) {
              selectedStatedUsers.push(user);
            } }
          } );
    }
    ids = selectedStatedUsers.map((s) => s.id);
    this.setState({
           selectedStatedUsers: selectedStatedUsers,
           selectedUsers: selectedStatedUsers,
           checkedUserIds: ids,
       });
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closeUserListing = () => {
    this.setState({ showUserListing: false });
  };

  onPageChange = (e) => {
    this.setState({ pageNum: e.page }, () => this.fetchAssignedUsers());
  };

  render() {
    const { USER_COLUMNS, RESPONSIVE_USER_COLUMNS } = this.Constants;
    const {
      assignedPackages,
      assignedUsers,
      errorMsg,
      showError,
      showUserListing,
      selectedUsersIndexes,
      checkedUserIds
    } = this.state;

    return (
      <React.Fragment>
        <Segment basic>
          <div className="control-action-wrapper mb-1">
            <Button className="button-basic mini" onClick={this.onAssign}>
              <Icon name="plus" />
              Assign New Users
            </Button>
            <Button
              color="red"
              className="button-basic mini mr-0-im"
              onClick={this.onUnassign}
            >
              <Icon name="remove" />
              Unassign
            </Button>
          </div>
        </Segment>

        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination">
            <Tableview
              //paginate
              columns={USER_COLUMNS}
              data={assignedUsers}
              onSelectionChange={this.onUserSelection}
              noDataMessage="No Users found."
              selectionType="multi-select"
              higePagination
              checkedIndexes={selectedUsersIndexes}
              checkedIds={checkedUserIds}                
              onAllSelectionChange={this.onAllItemSelection}
              // onPaginationChange={this.onPageChange}
            />
          </div>
        </Responsive>
        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={assignedUsers}
            columns={RESPONSIVE_USER_COLUMNS}
            onSelectionChange={this.onUserSelection}
            selectionType="multi-select"
            noDataMessage="No Users found."
            higePagination
            checkedIndexes={selectedUsersIndexes}
            checkedIds={checkedUserIds}                
            onAllSelectionChange={this.onAllItemSelection}
            //paginate
            //onPaginationChange={(e) => this.onPageChange(e)}
          />
        </Responsive>
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <UserListing
          show={showUserListing}
          onDismiss={this.closeUserListing}
          onSave={this.doAssign}
          entity="TrainingVideosDirUsers"
          entityId={get(this.props, ["match", "params", "id"])}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updatePackages())}
          content={`Are you sure you want to delete this user(s)?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageUsers;
