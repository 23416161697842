import PageTemplate from "./page";

import { authoritySelector } from "../selectors";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  authority: authoritySelector(state),
});

const PageTemplateMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageTemplate);

export default PageTemplateMapped;
