import Api from "@/api";
import PackageListing from "@/common/package-selection";
import Tableview from "@/common/tableview";
import { CardviewMobile } from "@/common/cardview";
import urls from "@/config/constants";
import { get, isEmpty } from "lodash";
import React from "react";
import {
  Button,
  Confirm,
  Icon,
  Modal,
  Segment,
  Responsive,
} from "semantic-ui-react";
import Moment from "react-moment";

class PackageInfo extends React.Component {
  Constants = {
    TABLE_COLUMNS: Object.freeze([
      { name: "Package Name", key: "packageName", showTooltip: true, },
      { name: "Assigned By", key: "createdBy", showTooltip: true, },
      { name: "Assigned On", key: "creationDate", showTooltip: true, },
    ]),
    TABLE_RESPONSIVE: Object.freeze([
      { name: "Package Name", key: "packageName" },
      { name: "Assigned By", key: "createdBy" },
      { name: "Assigned On", key: "creationDate" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedPackages: { content: [] },
      errorMsg: null,
      showError: false,
      selectedPackages: [],
      showPackageListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
    };
  }

  componentDidMount() {
    this.fetchAssignedPackages();
  }

  fetchAssignedPackages = () => {
    const { assignedPackages } = this.state;
    const { id } = this.props.match.params;
    const { URL_ADMIN_USER_ASSIGNED_PACKAGES } = urls.App;
    let params = {
      params: {
        userId: id,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(URL_ADMIN_USER_ASSIGNED_PACKAGES, params)
      .then((resp) => {
        this.setState({
          assignedPackages: this.transformResponse(resp.data),
        });
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: "Something went wrong while loading the packages list.",
        });
      });
  };
  transformResponse = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.creationDate = (
          <Moment format="MM/DD/YYYY hh:mm a">{row.creationDate}</Moment>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };
  onAssign = () => {
    this.setState({ showPackageListing: true });
  };

  doAssign = (data) => {
    this.setState({ showPackageListing: false, selectedPackages: data }, () => {
      this.updatePackages(
        "ADD_PACKAGE",
        "Something went wrong while assigning new package(s)."
      );
    });
  };

  onUnassign = () => {
    const { selectedPackages } = this.state;
    if (isEmpty(selectedPackages)) {
      this.setState({
        showError: true,
        errorMsg:
          "No package selected for deletion. Please select one or more packages.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "REMOVE_PACKAGE",
        "Something went wrong while unassigning the package(s)."
      );
    }
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb(action, msg);
      }
    );
  };

  updatePackages = (actionType, errorMsg) => {
    const { selectedPackages } = this.state;
    const { URL_ADMIN_USER_ASSIGN_PACKAGE } = urls.App;

    let url = `${URL_ADMIN_USER_ASSIGN_PACKAGE}?userId=${get(this.props, [
      "match",
      "params",
      "id",
    ])}`;

    let userIds = [];
    selectedPackages.map((pkg) => {
      userIds.push(pkg.id);
    });

    Api.AxiosInstance.getInstance()
      .post(url, userIds)
      .then((resp) => {
        this.fetchAssignedPackages();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };
  removePackages = (actionType, errorMsg) => {
    const { selectedPackages } = this.state;
    const { URL_ADMIN_USER_REMOVE_PACKAGE } = urls.App;

    let url = `${URL_ADMIN_USER_REMOVE_PACKAGE}?userId=${get(this.props, [
      "match",
      "params",
      "id",
    ])}`;
    let userIds = [];
    selectedPackages.map((pkg) => {
      userIds.push(pkg.packageId);
    });

    Api.AxiosInstance.getInstance()
      .post(url, userIds)
      .then((resp) => {
        this.fetchAssignedPackages();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };

  onPackageSelection = (e) => {
    this.setState({ selectedPackages: e.data });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closePackageListing = () => {
    this.setState({ showPackageListing: false });
  };

  render() {
    const { TABLE_COLUMNS, TABLE_RESPONSIVE } = this.Constants;
    const { assignedPackages, errorMsg, showError, showPackageListing } =
      this.state;

    return (
      <React.Fragment>
        <Segment basic></Segment>

        <Segment basic>
          <div className="control-action-wrapper mb-1">
            <Button className="button-basic mini" onClick={this.onAssign}>
              <Icon name="plus" />
              Assign New Packages
            </Button>

            <Button
              className="button-basic mini mr-0-im"
              color="red"
              onClick={this.onUnassign}
            >
              <Icon name="remove circle" />
              Unassign
            </Button>
          </div>
        </Segment>

        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination">
            {
              <Tableview
                columns={TABLE_COLUMNS}
                data={assignedPackages}
                onSelectionChange={this.onPackageSelection}
                noDataMessage="No packages found."
                selectionType="multi-select"
              />
            }
          </div>
        </Responsive>

        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={assignedPackages}
            columns={TABLE_RESPONSIVE}
            onSelectionChange={this.onPackageSelection}
            noDataMessage="No packages found."
            selectionType="multi-select"
          />
        </Responsive>

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <PackageListing
          show={showPackageListing}
          onDismiss={this.closePackageListing}
          onSave={this.doAssign}
          entity="UserPackages"
          entityId={get(this.props, ["match", "params", "id"])}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.removePackages)}
          content={`Are you sure you want to delete this package(s)?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageInfo;
