import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form } from "semantic-ui-react";

const PlainField = ({
  label,
  value,
  width,
}) => {
  return (
    <Form.Field width={ width }>
      <label>{ label }</label>
      <div><b>{ value }</b></div>
    </Form.Field>
  );
}

PlainField.propTypes = {
  /** Label text */
  label: PropTypes.string,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string,
};

PlainField.defaultProps = {
  width: "16",
};

export default PlainField;