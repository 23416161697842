import React from 'react';
import Api from "../../../../api";
import { Container, Header, Pagination, Segment, Grid } from 'semantic-ui-react';
import { filter } from "lodash";
import update from 'immutability-helper';
import { activePageToPageIndex, parameterStringify, removeEmptyKeys } from "../../../../common/utils/utils";
import Wall from "../../../../common/wall";
import urls from '../../../../config/constants';
import projectIdDispatcher from "../../dispatch";

class ProjectPosts extends React.Component {
  constructor(props) {
    super(props);
    let id = props.match.params.id;
    this.state = {
      projectId: id,
      posts: [],
      urlParams: {
        page: 0,
        id: id,
      },
      loading: false
    };
  }

  componentDidMount() {
    this.loadWallPosts();
    const { projectId } = this.state;
    projectIdDispatcher.dispatch({
      type: 'PROJECT_ID_CHANGE',
      value: projectId,
    });
  }

  filterPosts = (apiResponse) => {
    const filteredPost = filter(apiResponse.content, el => el.status === "CONFIRMED");
    apiResponse.content = filteredPost;
    return apiResponse;
  };

  loadWallPosts = () => {
    const { urlParams } = this.state;
    const { URL_PROJECT_POSTS } = urls.App;
    let parameterizedUrl = URL_PROJECT_POSTS + '?' + parameterStringify(urlParams);
    this.setState({ loading: true});
    Api.AxiosInstance.getInstance()
    .get(parameterizedUrl)
    .then(resp => {
      this.setState({ posts: resp.data, loading: false});
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while loading your wall posts.", showError: true })
    });
  };

  addPost = (args) => {
    const { projectId, postText } = args;
    const { URL_PROJECT_POST_CREATE } = urls.App;
    const payload = removeEmptyKeys({ projectId: this.state.projectId, post: postText });

    Api.AxiosInstance.getInstance()
    .post(URL_PROJECT_POST_CREATE, payload)
    .then(resp => {
      this.loadWallPosts();
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while loading your wall posts.", showError: true })
    });
  };

  deletePost = (id) => {
    const { URL_PROJECT_POST_CREATE } = urls.App;
    let parameterizedUrl = URL_PROJECT_POST_CREATE + '?' + parameterStringify({ postId: id });

    Api.AxiosInstance.getInstance()
    .delete(parameterizedUrl)
    .then(resp => {
      this.loadWallPosts();
    }).catch(err => {
      this.setState({ errorMsg: "Something went wrong while approving the posts.", showError: true })
    });
  };

  onSearchFunction = (searchTerm) => {
    const { urlParams } = this.state;
    this.setState({ urlParams: update(urlParams, { search: { $set: searchTerm }}) }, this.loadWallPosts);
  };

  onPageChange = (e, { activePage }) => {
    const { urlParams } = this.state;
    const curPage = activePageToPageIndex(activePage);
    this.setState({ urlParams: update(urlParams, { page: { $set: curPage }}) }, this.loadWallPosts);
  };

  render() {
    const { posts } = this.state;
    const { content } = posts;
    return(

     <React.Fragment>
          <Segment className="base-segment-wrapper" loading={this.state.loading}>
          <Grid>
          <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16} >
  
              <div  className="header" > <div className="side-marker" /> Wall 
          
          </div>
                </Grid.Column>

                </Grid.Row>

  
              <Wall
                  projectId={ this.state.projectId }
                  posts={ content }
                  postFunction={ this.addPost }
                  deleteFunction={ this.deletePost }
                  searchFunction={ this.onSearchFunction }
                />



              

                
                <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16} >

              <Pagination
                  activePage={ posts.number + 1 }
                  onPageChange={ this.onPageChange }
                  totalPages={ posts.totalPages ? posts.totalPages : 1 }
                />

              </Grid.Column>

              </Grid.Row>

          </Grid>
         


        


          

         
        </Segment>
     </React.Fragment>
    );
  }
};

export default ProjectPosts;
