import React from "react";
import BriefcaseListing from "../components/briefcase-listing";
import urls from "../../../config/constants";

const COLUMNS = [
    {
      name: "Status",
      key: "recipients.0.status",
      sortKey: "status",
      showTooltip: true,
    },
    {
      name: "From",
      key: "senderName",
      sortKey: "user2.username",
      showTooltip: true,
    },
    {
      name: "Subject",
      key: "subject",
      width: "4",
      sortKey: "BriefcaseItem.subject",
      showTooltip: true,
    },
    {
      name: "File Name",
      key: "filename",
      width: "4",
      sortKey: "BriefcaseItem.filename",
      showTooltip: true,
    },
    {
      name: "Received Date",
      key: "sendDate",
      width: "2",
      sortKey: "BriefcaseItem.sendDate",
      showTooltip: true,
    },
    {
      name: "Expiry Date",
      key: "expireDate",
      sortKey: "BriefcaseItem.expireDate",
      width: "2",
      showTooltip: true,
    },
    { name: "Type", key: "fileType", class: "center", showTooltip: false },
    { name: "Actions", key: "actionButtons", width: "4" },
  ],
  RESPONSIVE_COLUMNS = [
    { name: "Status", key: "recipients.0.status" },
    { name: "From", key: "senderName" },
    { name: "Subject", key: "subject", width: "3" },
    { name: "File Name", key: "filename", width: "4" },
    { name: "Date", key: "sendDate", width: "4" },
    { name: "Expiry Date", key: "expireDate", width: "4" },
    { name: "Type", key: "fileType", class: "center" },
  ];
const { URL_BRIEFCASE_RECEIVED_FILES } = urls.App;

const BriefcaseInbox = ({ match }) => {
  let isAdmin = match.path.indexOf(":id") > 0;
  let paginate = match.isExact;

  return (
    <BriefcaseListing
      urlParam={match.params}
      columns={COLUMNS}
      isAdmin={isAdmin}
      fetchUrl={URL_BRIEFCASE_RECEIVED_FILES}
      title="Received Files"
      paginate={paginate}
      referer="briefcase_receive"
    />
  );
};

export default BriefcaseInbox;
