import Api from "@/api";
import { InputField } from "@/common/Form";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Form, Grid, Segment } from "semantic-ui-react";
import { validate } from "validate.js";
import { normalValidation } from "./../../../../validation";

class EditContactTypes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: null,
      description: null,
      createdBy: null,
      modifiedBy: null,
      creationDate: null,
      modificationDate: null,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: "",
      msgSpanText: "",
      msgSpanColor: "black",
      itemLoadXHR: false,
      cancelButton: "Cancel"
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      let params = {
        params: {
          id
        }
      };
      Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_ADMIN_CONTACT_TYPE_SINGLE, params)
        .then(resp => {
          let data = resp.data;
          this.setState({
            id: data.id,
            name: data.name,
            description: data.description,
            createdBy: data.createdBy,
            modifiedBy: data.modifiedBy,
            creationDate: data.creationDate,
            modificationDate: data.modificationDate
          });
        });
    }
  }

  save = () => {
    const {
      id,
      name,
      description,
      createdBy,
      modifiedBy,
      creationDate,
      modificationDate
    } = this.state;
    let data = {
      id,
      name,
      description,
      createdBy,
      modifiedBy,
      creationDate,
      modificationDate
    };

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_CONTACT_TYPE_SAVE, data)
      .then(resp => {
        let msg = "";
        let color = "black";
        if (resp.data.id > 0) {
          msg = "Contact Type updated";

          color = "green";
        } else {
          msg = "Request failed, Please try later.";
          color = "red";
        }

        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
          cancelButton: "Back to Listing"
        });
      });
  };

  handleChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value
    });
  };

  validateForm = event => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red"
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="header">
            <div className="side-marker" />
            Customer Contact Type Management
            <Link
              to="/admin/customer-contact-types-listing"
              className="btn-back-main"
            >
              <FontAwesomeIcon icon={faArrowLeft} title="Back" name="back" />
              &nbsp;Back
            </Link>
          </div>
          <div className="empty-height-small" />

          <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Name"
                    errorMsg={this.state.validationMsg.name}
                    isError={this.state.validationMsg.name}
                    name="name"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    isRequired
                    placeholder="Enter Customer Contact Type"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Description"
                    errorMsg={this.state.validationMsg.description}
                    isError={this.state.validationMsg.description}
                    name="description"
                    id="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    placeholder="Enter Contact Type Description"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="submit"
                    value="Update"
                    className="button-primary"
                    onClick={this.validateForm}
                  />
                  &nbsp;&nbsp;
                  <Link to="/admin/customer-contact-types-listing">
                    <button className="button-basic">
                      {this.state.cancelButton}
                    </button>
                  </Link>
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
      </React.Fragment>
    );
  }
}

export default EditContactTypes;
