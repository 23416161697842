import React from "react";
import { useParams } from "react-router-dom";
import {
  Divider,
  Segment,
  Responsive,
  Accordion,
  Icon,
} from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";
import Tableview from "@/common/tableview";
import { Cardview } from "@/common/cardview";
import Api from "@/api";
import Constants from "@/config/constants";
import merge from "lodash";
import MultiDownloadOpts from "../../../common/multiple-download-opts";
import LicensePreview from "../../products/products/components/license-modal";

class RecentUploads extends React.Component {
  COLUMNS = [
    { name: "Product Name", key: "parent_product_name", showTooltip: true },
    { name: "Item Name", key: "name", showTooltip: true },
    { name: "File Name", key: "filename", showTooltip: true },
    { name: "Uploaded On", key: "modification_date", showTooltip: true },
  ];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: { content: [] },
      isCollapsed: true,
      isModalOpen: false,
      selectedFile: {},
      licenseLink: null,
      isOpenlicenseModal: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  update = (options) => {
    if (options.paginate) {
      this.load({ params: { page: options.page - 1 } });
    }
  };

  load = (options) => {
    options = merge({}, options ? options : {});
    this.setState({ loading: true });
    return Api.AxiosInstance.paginate(Constants.App.URL_RECENT_UPLOADS, options)
      .then((resp) => {
        resp.data.content = resp.data.content.map((item) => {
          return item;
        });
        this.setState({
          data: this.transformData(resp.data),
          loading: false,
        });
      })
      .catch((err) => this.setState({ loading: false }));
  };

  transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    transformedData.content.map((el) => {
      content.push({
        parent_product_name: el.parent_product_name,
        filename: el.filename,
        modification_date: (
          <>{dateToPacific(el.modification_date, "MM/DD/YYYY hh:mm a")}</>
        ),
        name: (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => this.getLicense(el)}
          >
            {" "}
            {el.licenses && el.licenses.length > 0 ? "*" : ""}
            {" "}
            {el.name}
          </div>
        ),
      });
    });
    transformedData.content = content;
    return transformedData;
  };

  download = (item) => {
    this.fetchToken(item);
    this.proceedToDownloadEvent(item);
    this.setState({ isModalOpen: true });
  };
  
  getLicense = (item) => {
    this.setState({ item: item });
    if (item.licenses.length) {
      const { URL_ITEMS_ASSOCIATED_LICENSE_LINK } = Constants.App;
      const instance = Api.AxiosInstance.getInstance();
      const params = { params: { itemId: item.id }, timeout: 0 };
      instance
        .get(URL_ITEMS_ASSOCIATED_LICENSE_LINK, params)
        .then((response) => {
          if (response.data) {
            this.setState({ licenseLink: response.data });
            if (this.state.licenseLink) {
              this.setState({ isOpenlicenseModal: true });
            }
          }
          else{
            this.fetchToken(item);
            this.proceedToDownloadEvent(item);
            this.setState({ isModalOpen: true });
          }
        });
    } else {
      this.fetchToken(item);
      this.proceedToDownloadEvent(item);
      this.setState({ isModalOpen: true });
    }
  };
  
  fetchToken = (item) => {
    const instance = Api.AxiosInstance.getInstance();
    instance.get("/items/userToken").then((resp) => {
      let command = `${Constants.App.API_URL}file/${encodeURI(item.filename)}/${
        item.id
      }/?token=${resp.data}`;
      let curl = `curl ${command} -o "${item.filename}" -D-`;
      let wget = `wget ${command} -O "${item.filename}"`;
      const { selectedFile } = this.state;
      this.setState({
        selectedFile: {
          ...selectedFile,
          wgetCommand: wget,
          curlCommand: curl,
        },
      });
    });
  };

  proceedToDownloadEvent = (item, isAgreed) => {
    const instance = Api.AxiosInstance.getInstance();
    const { URL_ITEM_DOWNLOAD, URL_ITEMS_DETAILS } = Constants.App;
    const paramsforLink = { params: { id: item.id } };
    instance.get(URL_ITEMS_DETAILS, paramsforLink).then((resp) => {
      const { selectedFile } = this.state;

      this.setState({
        selectedFile: {
          ...selectedFile,
          directLink: resp.data.directLink,
          fileName: resp.data.filename,
          fileDesc: resp.data.description,
          itemId: resp.data.id
          // bug/GP3-138
          // friendlyName: resp.data.friendlyName ? resp.data.friendlyName : null,
        },
      });
    });

    const params = { params: { itemId: item.id, isAgreed: isAgreed ? 1 : 0 }, timeout: 0 };
    instance.get(URL_ITEM_DOWNLOAD, params).then((resp) => {
      const { selectedFile } = this.state;

      this.setState({
        selectedFile: {
          ...selectedFile,
          downloadLink: resp.data,
        },
      });
    });
    this.setState({ setIsDownloading: true, setProceedToDownload: true });
  };
  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const { loading, data, isCollapsed } = this.state;
    return (
      <Segment className="dashboard-card">
        <Responsive minWidth={1024}>
          <div className="side-marker" />
          <div className="header">Recent Uploads</div>

          <div className="table-wrapper">
            <Tableview data={data} columns={this.COLUMNS} loading={loading} />
          </div>
          {data.content && (
            <React.Fragment>
              <Divider />
              <em>Showing top {data.content.length} records</em>
            </React.Fragment>
          )}
        </Responsive>
        <Responsive maxWidth={1023}>
          <Accordion>
            <Accordion.Title
              onClick={(e) => this.setState({ isCollapsed: !isCollapsed })}
            >
              <Icon
                name={isCollapsed ? "caret right" : "caret down"}
                color="blue"
              />
              <div className="side-marker" />
              <div className="header">Recent Uploads</div>
            </Accordion.Title>
            <Accordion.Content active={!isCollapsed}>
              <Cardview data={data} columns={this.COLUMNS} loading={loading} />

              {data.content && (
                <React.Fragment>
                  <Divider />
                  <em>Showing top {data.content.length} records</em>
                </React.Fragment>
              )}
            </Accordion.Content>
          </Accordion>
        </Responsive>

        {this.state.isModalOpen && (
          <MultiDownloadOpts
            isModalOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            selectedFile={this.state.selectedFile}
          ></MultiDownloadOpts>
        )}
        <React.Fragment>
          {this.state.isOpenlicenseModal && (
            <LicensePreview
              cancel={(res) => {
                this.setState({isOpenlicenseModal: false})
                if(res == 'agree'){
                  this.download(this.state.item);
                }
              }}
              isLicenseAttached={this.state.isOpenlicenseModal}
              licenseLink={this.state.licenseLink}
            />
          )}
        </React.Fragment>
      </Segment>
    );
  }
}

export default RecentUploads;
