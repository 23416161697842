import React from "react";
import PropTypes from "prop-types";
import { Form, Select } from "semantic-ui-react";
import ErrorMessage from "./error-message";

const DropdownField = ({
  disabled,
  errorMsg,
  isError,
  isRequired,
  label,
  name,
  onChange,
  options,
  placeholder,
  search,
  selection,
  value,
  width,
  multiple,
  className,
}) => {
  const isMultiple = !!multiple;
  return (
    <Form.Field required={isRequired} width={width} error={isError}>
      {label &&
        <label>{label}</label>
      }
      <Select
        autocorrect="off"
        spellcheck="false"
        autocomplete="off"
        disabled={disabled}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={(event, data) =>
          onChange(event, { name: name, value: data.value })
        }
        search={search}
        selection={selection}
        multiple={isMultiple}
        className={className ? className : "dropdown-with-tags"}
      />
      <ErrorMessage isError={isError} errorMsg={errorMsg} />
    </Form.Field>
  );
};

DropdownField.propTypes = {
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  placeholder: PropTypes.string,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string,
  /** Is drop down multiple value or not */
  multiple: PropTypes.bool,
  
  className: PropTypes.string,
};

DropdownField.defaultProps = {
  width: "16"
};

export default DropdownField;
