const normalValidation = {
  mail_server: {
    presence: true,
  },
  mail_server_port: {
    presence: true,
  },
  mail_server_secure: {
    presence: true,
  },
  mail_server_auth: {
    presence: true,
  },
  mail_server_auth_username: {
    presence: true,
  },
  mail_server_auth_password: {
    presence: true,
  },
  mail_server_auth_username_for_items: {
    presence: true,
    email: true,
  },
  mail_server_auth_password_for_items: {
    presence: true,
  },
  support_email: {
    presence: true,
    email: true,
  },
  newuser_notification_email_to: {
    presence: true,
    email: true,
  },
  new_user_notification_email: {
    presence: false,
    email: true,
  },
  nda_sent_from_email: {
    presence: true,
    email: true,
  },
  nda_notification_email: {
    presence: true,
    email: true,
  },
  nda_signer_email: {
    presence: true,
    email: true,
  },
  rule_notification_email: {
    presence: true,
    email: true,
  },
  trade_compliance_email: {
    presence: true,
    email: true,
  },
};

const nda_notification_email_validation = {
  nda_notification_email: {
    presence: true,
    email: true,
  },
  support_email: {
    presence: true,
    email: true,
  },
}

const newuser_notification_email_to_validation = {
  newuser_notification_email_to: {
    presence: true,
    email: true,
  },
}

const account_assistance_notify_email_validation = {
  account_assistance_notify_email: {
    presence: true,
    email: true,
  },
}
const additional_status_notification_email_validation = {
  additional_status_notification_email: {
    presence: true,
    email: true,
  },
}

export { normalValidation , nda_notification_email_validation , newuser_notification_email_to_validation, account_assistance_notify_email_validation, additional_status_notification_email_validation};
