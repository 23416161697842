import React, { useReducer, createContext, useContext } from "react";
import Reducer from "./reducer";
import Actions from "./actions";
import { connect, useSelector } from "react-redux";
// import { isCustomerSelector } from "../selectors/selectors";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  user: state.user,
  config: state.common.config.maps,
  // isCustomer: isCustomerSelector(state)
});

// const mappedBrowser = connect(mapStateToProps, mapDispatchToProps)(Browser)/

const Context = React.createContext({
  state: null,
  dispatch: null,
  user: null,
  config: null,
});

const InitialState = {
  isModuleReady: false,
  isAdmin: false,
  reloadProducts: false,
  isProductsLoading: true,
  products: [],
  rawProducts: {},
  baseUrl: null,
  selectedProduct: null,
  breadcrumbs: "",
  details: {},
  expand: null,
  showTree: true,
};

function Provider({ children }) {
  const [data, dispatch] = useReducer(Reducer, InitialState);
  const { user, config } = useSelector(mapStateToProps);

  const value = { data, dispatch, user, config, actions: Actions };
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function Consumer(Component) {
  return (props) => {
    return (
      <Context.Consumer>
        {(context) => {
          return <Component {...props} {...context} />;
        }}
      </Context.Consumer>
    );
  };
}

export default { Context, Provider, Consumer };
