import React from "react";
import { get, isEmpty } from "lodash";
import { Button, Icon, Modal, Segment, Confirm, Responsive } from "semantic-ui-react";
import update from "immutability-helper";
import LicenseListing from "@/common/license-selection";
import Tableview from "@/common/tableview";
import urls from "@/config/constants";
import Api from "@/api";
import { CardviewMobile } from "@/common/cardview";
import Moment from "react-moment";
import { dateToPacific } from "@/utils/dateTime";

class LicenseInfo extends React.Component {
  Constants = {
    TABLE_COLUMNS: Object.freeze([
      { name: "License Name", key: "licenseName", showTooltip: true, },
      { name: "Created By", key: "createdBy", showTooltip: true, },
      { name: "Created On", key: "creationDate", showTooltip: true, },
      { name: "User Types", key: "userTypeField", showTooltip: true, },
    ]),
    RESPONSIVE_TABLE_COLUMNS: Object.freeze([
      { name: "License Name", key: "licenseName" },
      { name: "Created By", key: "createdBy" },
      { name: "Created On", key: "creationDate" },
      { name: "User Types", key: "userTypeField" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedLicense: { content: [] },
      errorMsg: null,
      showError: false,
      selectedItems: [],
      showLicenseListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
      selectedStatedLicences: [],
      selectedLicencesIndexes: [],
      checkedLicencesIds: [],
    };
  }

  componentDidMount() {
    this.fetchAssignedLicense();
  }

  fetchAssignedLicense = () => {
    const { assignedLicense } = this.state;
    const { URL_ITEMS_ASSOCIATED_LICENSES } = urls.App;
    let params = {
      itemId: get(this.props, ["match", "params", "itemId"]),
    };
    Api.AxiosInstance.getInstance()
      .get(URL_ITEMS_ASSOCIATED_LICENSES, { params: params })
      .then((resp) => {
        this.setState({
          //  assignedLicense: update(assignedLicense, {
          //  content: { $set: resp.data.licenses },
          // }),
          assignedLicense: this.mapData(resp.data),
        });
      })

      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: "Something went wrong while loading the License list.",
        });
      });
  };

  mapData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.id = row.licensId;
        row.creationDate = (
          <>{dateToPacific(row.creationDate, "MM/DD/YYYY hh:mm a")}</>
        );
        row.userTypeField = row.userTypes && row.userTypes.length
          ? row.userTypes.map((type) => type.name).join(', ')
          : ['-'];

        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  onAssign = () => {
    this.setState({ showLicenseListing: true });
  };

  doAssign = (data) => {
    this.setState({ showLicenseListing: false, selectedItems: data, selectedStatedLicences: [], selectedLicencesIndexes: [], checkedLicencesIds: [], }, () => {
      this.updateLicense(
        "Something went wrong while assigning new license(s)."
      );
    });
  };

  onUnassign = () => {
    const { selectedItems } = this.state;
    if (isEmpty(selectedItems)) {
      this.setState({
        showError: true,
        errorMsg:
          "No license selected for deletion. Please select one or more License.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "Something went wrong while unassigning the license(s)."
      );
    }
  };
  showDeleteWarningAndSetValues = (msg) => {
    this.setState({
      msg: msg,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { msg } = this.state;
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb(msg);
      }
    );
  };
  updateLicense = (errorMsg) => {
    const { selectedItems } = this.state;
    const { URL_ITEMS_ASSOCIATED_LICENSES } = urls.App;
    const { itemId } = this.props.match.params;

    let licenseIds = [];
    selectedItems.map((data) => {
      licenseIds.push(data.id);
    });
    let params = {
      itemId: itemId,
      licenseIds,
    };
    Api.AxiosInstance.getInstance()
      .post(URL_ITEMS_ASSOCIATED_LICENSES, params)
      .then((resp) => {
        this.fetchAssignedLicense();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };
  unAssign = (errorMsg) => {
    const { selectedItems } = this.state;
    const { URL_ITEMS_ASSOCIATED_LICENSES } = urls.App;
    const { itemId } = this.props.match.params;

    let licenseIds = [];
    selectedItems.map((data) => {
      licenseIds.push(data.licensId);
    });
    let config = {
      data: {
        itemId,
        licenseIds,
      },
    };
    // console.log("sdasd", body);

    Api.AxiosInstance.getInstance()
      .delete(URL_ITEMS_ASSOCIATED_LICENSES, config)
      .then((resp) => {
        this.fetchAssignedLicense();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };

  onLicenseSelection = (e) => {
    let selectedStatedLicences = this.state.selectedStatedLicences;
    let ids = this.state.checkedLicencesIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedLicences.find(
        (element) => element.id === e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedLicences.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
      console.log('alreadyPushed', alreadyPushed, e.changedContent);
    } else {
      selectedStatedLicences = selectedStatedLicences.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedLicences.map((s) => s.id);
    }
    this.setState({ checkedLicencesIds: ids });

    this.setState({
      selectedStatedLicences: selectedStatedLicences,
      selectedItems: selectedStatedLicences
    });
  };

  onAllItemSelection = (selectedLicences, flag) => {
    let { selectedStatedLicences } = this.state;
    let ids = [];
    if (flag) {
      selectedLicences.map((license) => {
        selectedStatedLicences = selectedStatedLicences.filter((entity) => {
          return entity.id !== license.id;
        })
      });
    } else {
      selectedLicences.map((license) => {
        if (license) {
          const alreadyPushed = selectedStatedLicences.find(
            (element) => element.id == license.id
          );
          if (!alreadyPushed) {
            selectedStatedLicences.push(license);
          }
        }
      });
    }
    ids = selectedStatedLicences.map((s) => s.id);
    this.setState({
      selectedStatedLicences: selectedStatedLicences,
      selectedItems: selectedStatedLicences,
      checkedLicencesIds: ids,
    });
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closeLicenseListing = () => {
    this.setState({ showLicenseListing: false });
  };

  render() {
    const { TABLE_COLUMNS, RESPONSIVE_TABLE_COLUMNS } = this.Constants;
    const {
      assignedLicense,
      errorMsg,
      showError,
      showLicenseListing,
      checkedLicencesIds,
      selectedLicencesIndexes,
      selectedItems,
    } = this.state;
    const isData =
      assignedLicense &&
        assignedLicense.content &&
        assignedLicense.content.length >= 2
        ? true
        : false;
    const isUnassignable = selectedItems && selectedItems.length;

    return (
      <React.Fragment>
        <Segment basic className="p-0">
          <div className="control-action-wrapper mb-1">

            <Button className="button-basic mini" disabled={isData} onClick={this.onAssign}>
              <Icon name="plus" />
              Assign New License
            </Button>
            <Button
              color="red"
              className="button-basic mini mr-0-im"
              disabled={!isUnassignable}
              onClick={this.onUnassign}
            >
              <Icon name="remove circle" />
              Unassign
            </Button>
          </div>
        </Segment>



        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination">

            <Tableview
              columns={TABLE_COLUMNS}
              data={assignedLicense}
              checkedIndexes={selectedLicencesIndexes}
              checkedIds={checkedLicencesIds}
              onSelectionChange={this.onLicenseSelection}
              onAllSelectionChange={this.onAllItemSelection}
              noDataMessage="No License found."
              selectionType="multi-select"
            />
          </div>
        </Responsive>
        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={assignedLicense}
            columns={RESPONSIVE_TABLE_COLUMNS}
            checkedIndexes={selectedLicencesIndexes}
            checkedIds={checkedLicencesIds}
            onSelectionChange={this.onLicenseSelection}
            onAllSelectionChange={this.onAllItemSelection}
            noDataMessage="No License found."
            selectionType="multi-select"
          />
        </Responsive>




        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <LicenseListing
          show={showLicenseListing}
          onDismiss={this.closeLicenseListing}
          onSave={this.doAssign}
          assignedLicense={this.state.assignedLicense}
        // selectionType="multi-select"

        // entity="ItemLicenses"
        // entityId={get(this.props, ["match", "params", "itemId"])}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.unAssign)}
          content={`Are you sure you want to un-associate this license?`}
        />
      </React.Fragment>
    );
  }
}

export default LicenseInfo;
