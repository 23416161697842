import React from "react";
import Api from "../../../api";
import Axios from "axios";
import Constants from "../../../config/constants";
import ProjectForm from "../components/projectform";
import {get} from "lodash";

class ProjectCreate extends React.Component {
  constructor(props) {
     super(props);
     var userId = get(props, ['user', 'loggedin', 'user', 'principal', 'id'])
     var userName = get(props, ['user', 'loggedin', 'user', 'principal', 'username'])
     this.state = {
       name: '', description: '', adminId: userId, adminName: userName
     };

     this.handleChange = this.handleChange.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e, { value }) => {
     this.setState({ [e.target.name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    var project = {"name": this.state.name, "description": this.state.description, "adminId": this.state.adminId}
    Api.AxiosInstance.getInstance().post(Constants.App.URL_PROJECT_DETAIL+"?id=new", project).then(resp => {
      this.setState({redirectToViewProject: true, id: resp.data.id})
    }).catch(error => {
      this.setState({
        errors: {
          submit: 'Failed to create Project'
        }
      })
    })
  }

  componentDidMount() {
  }

  onSelectUser = () => {
    this.setState({ showUsersListing: true });
  };

  onDismissUserListing = () => {
    this.setState({ showUsersListing: false });
  };

  onSaveUserListing = (users) => {
    this.setState({
      showUsersListing: false,
      adminId: users[0]['id'],
      adminName: users[0]['username'],
    }, this.notifyLatestContent)
  };

  componentDidUpdate() {

  }

  render() {
    const { adminId, adminName } = this.state;
    return(
       <ProjectForm data={{adminId: adminId, adminName: adminName}}/>
    )
  }
}

export default ProjectCreate;
