import React from "react";
import { get } from "lodash";
import PageTemplate from "@/common/page";
import ViewProfile from "./pages/view-profile";
import EditProfile from "./pages/edit-profile";
import ChangePassword from "./pages/change-password";
import ViewFriends from "./pages/view-friends";
import PendingFriends from "./pages/pending-friends";
import WallPosts from "./pages/wall-posts";
import PendingPosts from "./pages/pending-posts";
import ViewDocuments from "./pages/documents";
import Config from "@/config/constants";

const CUSTOMER_AUTHORITY = Config.AccessRole.CUSTOMER;
const NON_NDA_CUSTOMER = Config.AccessRole.NON_NDA_CUSTOMER;
const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;

const ADMIN_MENU_ITEMS = {
  profile: [
    {
      name: "View Profile",
      relativePath: "/",
      exact: true,
      subPageComponent: ViewProfile,
    },
    {
      name: "Update My Information",
      relativePath: "/edit",
      subPageComponent: EditProfile,
    },
    {
      name: "Passwords",
      relativePath: "/password",
      subPageComponent: ChangePassword,
    },
  ],
  Documents: [
    {
      name: "My Documents",
      relativePath: "/documents",
      subPageComponent: ViewDocuments,
    },
  ],
};

const USER_MENU_ITEMS = {
  profile: [
    {
      name: "Update My Information",
      relativePath: "/",
      exact: true,
      subPageComponent: EditProfile,
    },
    {
      name: "My Documents",
      relativePath: "/documents",
      subPageComponent: ViewDocuments,
    },
    {
      name: "Passwords",
      relativePath: "/password",
      subPageComponent: ChangePassword,
    },
  ],
};
const NON_NDA_USER_MENU_ITEMS = {
  profile: [
    {
      name: "Update My Information",
      relativePath: "/",
      exact: true,
      subPageComponent: EditProfile,
    },
    {
      name: "Passwords",
      relativePath: "/password",
      subPageComponent: ChangePassword,
    },
  ],
};

const Profile = ({ baseUrl, authority }) => {
  const template =
    ADMIN_ROLES.indexOf(authority) >= 0 ? (
      <PageTemplate baseUrl={baseUrl} menuItems={ADMIN_MENU_ITEMS} />
    ) : authority === CUSTOMER_AUTHORITY ? (
      <PageTemplate baseUrl={baseUrl} menuItems={USER_MENU_ITEMS} />
    ) : (
      <PageTemplate baseUrl={baseUrl} menuItems={NON_NDA_USER_MENU_ITEMS} />
    );
  return template;
};

export default Profile;
