import React from 'react';
import { dateToPacific } from '@/utils/dateTime';
import {
  Container,
  Item,
  Modal,
  Pagination,
  Segment,
  Grid,
  Image,
  GridRow,
  GridColumn,
} from 'semantic-ui-react';
import update from 'immutability-helper';
import {
  activePageToPageIndex,
  parameterStringify,
} from '../../../common/utils/utils';
import Api from '../../../api';
import { default as Config, default as Constants } from '@/config/constants';
import ItemDetail from '../components/listview';
import projectIdDispatcher from '../dispatch';
import { isEmpty } from 'lodash';
import { authoritySelector, userIdSelector } from '../../../common/selectors';
import { connect } from 'react-redux';

const ADMIN_ROLES = Config.AccessRole.ADMINISTRATORS;

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectsList: {},
      loading: false,
      projects: null,
      configOption: {},
    };
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidUpdate() {
    this.handleEmptyPage();
  }

  componentDidMount() {
    this.loadProjectList();
    projectIdDispatcher.dispatch({
      type: 'PROJECT_ID_CHANGE',
      value: null,
    });
  }

  handleEmptyPage = () => {
    const { projectsList, configOption } = this.state;
    // sometimes server send 0 total pages.
    // but for our logic total page should at least 1.
    let minTotalPages =
      projectsList.totalPages > 0 ? projectsList.totalPages : 1;
    if (configOption.page > activePageToPageIndex(minTotalPages))
      this.onPageChange(null, { activePage: minTotalPages });
  };

  parseProjectData = (data) => {
    // let current user id = 52928 userIdSelector
    // authority , userId
    if (!isEmpty(data.content)) {
      return data.content.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.description,
        companies: item.company,
        groups: item.groupNames,
        fileurl: item.fileurl,
        creationDate: (
          <>{dateToPacific(item.creationDate, 'MM/DD/YYYY hh:mm a')}</>
        ),
        itemDetail: {
          memberCount: item.memberCount,
          name: item.createdBy || '(None)',
        },
        imageUrl: 'https://react.semantic-ui.com/images/wireframe/image.png',
        viewUrl: 'projects/' + item.id,
        editUrl: 'projects/edit/' + item.id,
        deleteUrl: 'projects/' + item.id,
        isAdmin:
          ADMIN_ROLES.indexOf(this.props.authority) >= 0
            ? true
            : item.adminId == this.props.userId
            ? true
            : false,
      }));
    }
  };

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.loadProjectList();
    }
  }

  loadProjectList = () => {
    this.setState({ loading: true });
    const { configOption } = this.state;
    const { URL_PROJECTS_LIST } = Constants.App;
    let parameterizedUrl =
      URL_PROJECTS_LIST + '?' + parameterStringify(configOption);
    return Api.AxiosInstance.getInstance()
      .get(parameterizedUrl)
      .then((resp) => {
        this.setState({
          projectsList: resp.data,
          loading: false,
          projects: this.parseProjectData(resp.data),
        });
      });
  };

  onPageChange = (e, { activePage }) => {
    const { configOption } = this.state;
    const curPage = activePageToPageIndex(activePage);
    this.setState(
      { configOption: update(configOption, { page: { $set: curPage } }) },
      this.loadProjectList
    );
  };

  onSearchTyping = (data) => {
    const { configOption } = this.state;
    this.setState({
      configOption: update(configOption, {
        search: { $set: data.target.value },
      }),
    });
  };

  deleteProject = (projectId) => {
    const { URL_PROJECT_DELETE } = Constants.App;
    return Api.AxiosInstance.getInstance()
      .post(URL_PROJECT_DELETE + '?id=' + projectId)
      .then((resp) => {
        this.loadProjectList();
      })
      .catch((err) => {
        this.setState({
          errorMsg: 'Something went wrong while deleting the project',
          showError: true,
        });
      });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  render() {
    const { errorMsg, loading, projects, projectsList, showError } = this.state;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" /> All Projects
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="control-action-wrapper">
                  <a className="button-basic" href={'#/projects/create/new'}>
                    Add New
                  </a>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row className="common-filter-wrapper ui form">
              <GridColumn mobile={16} tablet={8} computer={8}>
                <div class="field">
                  <div class="ui input">
                    <input
                      placeholder="Search"
                      className="search-bar"
                      onChange={this.onSearchTyping}
                      onKeyPress={this.onKeyUp}
                    ></input>
                  </div>
                </div>
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={8}>
                <div class="field">
                  <div class="ui input">
                    <button
                      onClick={this.loadProjectList}
                      className="button-primary"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </GridColumn>
            </Grid.Row>
            <Grid.Row>
              <GridColumn mobile={16} tablet={16} computer={16}>
                <Segment
                  basic
                  loading={loading}
                  className="m--no-pad-left-right-im"
                >
                  <Item.Group relaxed unstackable>
                    {projects &&
                      projects.map((item, i) => {
                        return (
                          <ItemDetail
                            key={item.id}
                            item={item}
                            deleteCallback={this.deleteProject}
                            showEditButton={true}
                          />
                        );
                      })}
                    {!projects && (
                      <Container>No project(s) to display</Container>
                    )}
                  </Item.Group>
                </Segment>
              </GridColumn>
            </Grid.Row>
          </Grid>
        </Segment>
        {projects && (
          <Pagination
            activePage={projectsList.number + 1}
            onPageChange={this.onPageChange}
            totalPages={projectsList.totalPages || 1}
          />
        )}
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: 'warningDismiss', content: 'OK', primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
  authority: authoritySelector(state),
  // baseUrl: baseUrl,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mappedProjectList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectList);

export default mappedProjectList;
