import Api from "@/api";
import Tableview from "@/common/tableview";
import {CardviewMobile} from "@/common/cardview";
import Constants from "@/config/constants";
import React from "react";
import { Link } from "react-router-dom";
import { Confirm, Segment, Responsive, Grid  } from "semantic-ui-react";

// ToDo: shift listing component in userTypes
// more refactoring to be done later on:
class UserTypes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteWarning: false,
      userTypeId: null,
      columns: [
        { name: "Name", key: "name", width: "4", showTooltip: true, cb: () => {
          this.sortByHeader("name", "Name");
        }, },
        { name: "Description", key: "description", width: "5", showTooltip: true, cb: () => {
          this.sortByHeader("description", "Description");
        }, },
        { name: "Access Role", key: "accessRole", width: "5", showTooltip: true, cb: () => {
          this.sortByHeader("accessRole", "Access Role");
        }, },
        { name: "Actions", key: "actions" }
      ],
      responsive_columns: [
        { name: "Name", key: "name", width: "4" },
        { name: "Description", key: "description", width: "5" },
        { name: "Access Role", key: "accessRole", width: "5" }
      ],
      listings: { content: [], number: 0 },
      itemLoadXHR: false,
      isDescSearch: true,
      sortBy: "creationDate,DESC",
    };
  }

  componentDidMount() {
    this.getList();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.state.columns = this.state.columns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getList();
    });
   
  };

  getList = () => {
    const { pageNum } = this.state;
    let params = {
      params: {
        page: pageNum,
        sort: this.state.sortBy
      }
    };
    return Api.AxiosInstance.paginate(
      Constants.App.URL_ADMIN_USER_TYPES_LISTING,
      params
    ).then(resp => {
      this.setState({
        listings: this.transformData(resp.data)
      });
    });
  };
  showDeleteWarningAndSetUserId = (e, id) => {
    this.setState({
      userTypeId: id,
      deleteWarning: true
    });
  };
  hideDeleteWarning = cb => {
    this.setState(
      {
        deleteWarning: false
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  transformData = data => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map(el => {
        let row = Object.assign({}, el);
        row.actions = (
          <React.Fragment>
            <Link to={"/admin/user-types/edit/" + row.id}>
              <span
                className="icon-edit"
                style={{
                  margin: "3px"
                }}
                title="Edit"
                name="edit"
              />
            </Link>
          {/* <span
              className="icon-delete"
              onClick={e => this.showDeleteWarningAndSetUserId(e, row.id)}
              style={{ margin: "3px" }}
              title="Remove"
              name="remove"
              /> */}
          </React.Fragment>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  onPageChange = e => {
    this.setState({ pageNum: e.page }, this.getList);
  };

  create = () => {
    this.setState({
      createMode: true,
      listings: { content: [] }
    });
  };

  remove = () => {
    const { userTypeId } = this.state;
    // e.preventDefault();
    let params = {
      params: {
        id: userTypeId
      }
    };
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ADMIN_USER_TYPE_REMOVE, params)
      .then(resp => {
        this.getList();
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.listings.content && (


          <Segment className="base-segment-wrapper">
            <Grid>
              <Grid.Row>
                <Grid.Column
                  mobile={10}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="header">
                    <div className="side-marker" />
                    User Types

                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={6}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="control-action-wrapper">
                     <Link to={"/admin/user-types/new"}>
                {/*<button className="button-basic">Add New</button> */}
              </Link>
                  </div>
                </Grid.Column>
              </Grid.Row>
            <Responsive minWidth={1024}>
            <div className="table-wrapper-with-pagination">
              <Tableview
                paginate
                data={this.state.listings}
                columns={this.state.columns}
                onPaginationChange={this.onPageChange}
                noDataMessage="No items"
              />
            </div>
             </Responsive>


         <Responsive maxWidth={1023}>
           <CardviewMobile
                data={this.state.listings}
                columns={this.state.responsive_columns}
                paginate
                onPaginationChange={e => this.onPageChange(e)}
            
              />
        </Responsive>
            </Grid>
          </Segment>
        )}
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.remove)}
          content="Are you sure you want to delete this User Type?"
        />
      </React.Fragment>
    );
  }
}

export default UserTypes;
