import React from "react";

import dateMap from "./placeholder-mapping.js";
import { Table } from "semantic-ui-react";

function PlaceholderList({ data, config }) {
  let val = Object.values(data);
  let confiqCompany = ["By", "Title", "Date"];
  //  const { config } = this.props;
  let site_name = config.maps.websilo_site_name;
  return (
    <div>
      <hr />
      <h3>Template Placeholders:</h3>
      <Table celled striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Template Placeholders</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {val.map((val, key) => (
            <>
              {dateMap[val] && (
                <Table.Row key={key}>
                  <Table.Cell key={val}>{val}</Table.Cell>
                  <Table.Cell>
                    {" "}
                    {confiqCompany.indexOf(val) >= 0
                      ? `${site_name} ${dateMap[val]}`
                      : dateMap[val]}
                  </Table.Cell>
                </Table.Row>
              )}
            </>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default PlaceholderList;
