import React from "react";
import { Accordion, Dimmer, Grid, Loader, Segment } from "semantic-ui-react";
import ProductsContext from "../_context";
import ProductTreeElement from "./product-tree-element";

//@TODO: MOVE THE TREE TO THIS COMPONENT
class ProductTree extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.data.expand !== null && prevProps.data.expand === null) {
      console.log("stuck in loop parent");
      this.props.dispatch({
        type: "expand-products",
        expand: null,
      });
    }
  }
  expandAll = () => {
    this.props.dispatch(this.props.actions.expandProducts(true));
  };

  closeAll = () => {
    this.props.dispatch(this.props.actions.expandProducts(false));
  };

  render() {
    let productAccordion;

    const { products, baseUrl } = this.props.data;

    if (products && products.length) {
      let bool = true;
      productAccordion = products.map((i) => {
        return (
          <ProductTreeElement
            // setShowTree={this.props.setShowTree}
            key={i.id}
            product={i}
            baseUrl={baseUrl + "/"}
            setShowTree={this.props.setShowTree}
          />
        );
      });
    }
    let emptyMessage = false;
    if (!productAccordion || productAccordion.length === 0) {
      emptyMessage = "No Files!";
    }

    return (
      <Dimmer.Dimmable
        as={Segment}
        dimmed={this.props.data.isProductsLoading}
        className="enable-vertical-scroll product-tree-wrapper"
      >
        <Grid.Row>
          <Dimmer active={this.props.data.isProductsLoading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          {productAccordion && productAccordion.length > 0 && (
            <Accordion styled>{productAccordion}</Accordion>
          )}
          {emptyMessage && <em>No Files are shared with you!</em>}
        </Grid.Row>
      </Dimmer.Dimmable>
    );
  }
}

export default ProductsContext.Consumer(ProductTree);
