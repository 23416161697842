import Api from "@/api";
import FileIcon from "@/common/file-icon";
import Constants from "@/config/constants";
import Axios from "axios";
import React, { Component } from "react";
import {
  Confirm,
  Container,
  Divider,
  Grid,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import validate from "validate.js";
import ProductListing from "../../modules/products/products/pages/edit-items/product-listing";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { content: [] },
      selectedItems: [],
      showError: false,
      items: [],
      action: null,
      msg: null,
      selectedItem: [],
      page: 0,
      deleteWarning: false,
      showProductListing: false,
      userId: "",
      productName: "",
      productId: null,
      status: null,
      showMessage: false,
      selectedProducts: [],
      parentProductId: null
    };
  }

  componentDidMount() {}
  onSelectProduct = (e) => {
    e.preventDefault();
    this.setState({ showProductListing: true });
  };
  onSaveProductListing = (list) => {
    list.map((data) =>{
      const instance = Api.AxiosInstance.getInstance();
      this.state.parentProductId = data.id
      const options = {
        params: {
          parent_id: this.state.parentProductId,
          searchType: 'current_folder',
        },
      };

      instance
      .get(Constants.App.URL_PRODUCTS_ITEMS_LIST, options)
      .then((resp) => {
        this.setState({
          showProductListing: false,
          productId: data.id,
          productName: data.name,
          items: resp.data.content,
          selectedProducts: list
        })
      })
  }
    );
  };
  onDismissProductListing = () => {
    this.setState({ showProductListing: false });
  };
  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;

    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  showDeleteWarningAndSetItem = (row) => {
    this.setState({
      deleteWarning: true,
      selectedItem: row,
      msg: "Something went wrong while unassigning the item(s) to Products.",
      action: "REMOVE_PRODUCT",
    });
  };
  loadItems = () => {
    const instance = Api.AxiosInstance.getInstance();
    const options = {
      params: {
        parent_id: this.state.productId,
        searchType: 'current_folder',
      },
    };

    instance
    .get(Constants.App.URL_PRODUCTS_ITEMS_LIST, options)
    .then((resp) => {
      this.setState({
        items: resp.data.content,
      })
    })
    
    
  };

  updateProducts = () => {
    const { action, msg, selectedItem ,selectedProducts} = this.state;
      const { URL_PRODUCTS_ITEMS_DETAILS } = Constants.App;
      let url = `${URL_PRODUCTS_ITEMS_DETAILS}?id=${ selectedItem["id"] }`;
      let payload = { actiontype: action, products: selectedProducts};
      Api.AxiosInstance.getInstance()
        .post(url, payload)
        .then((resp) => {
          
          this.loadItems();
        })
        .catch((error) => {
          this.setState({
            showError: true,
            errorMsg: msg,
          });
        });
  };

  handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // debugger;
    let data = JSON.parse(e.dataTransfer.getData("item"));
    this.setState(
      {
        action: "ADD_PRODUCT",
        selectedItem: data,
      },
      this.updateProducts
    );
  };
  render() {
    const { items, productName, productId ,parentProductId} = this.state;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="header">
                <div className="side-marker" />

                <b> Edit Products</b>
                <button
                  type="button"
                  className="button-basic btn-s-t3-im m-0"
                  title="Load Product"
                  onClick={this.onSelectProduct}
                >
                  SELECT PRODUCT
                </button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <ProductListing
          show={this.state.showProductListing}
          onDismiss={this.onDismissProductListing}
          onSave={this.onSaveProductListing}
          selectionType="single-select"
          title="List of Products"
        />
        {productId && (
          <>
            <div
              className={"drag-drop-zone"}
              onDrop={(e) => this.handleDrop(e)}
              onDragOver={(e) => this.handleDragOver(e)}
              onDragEnter={(e) => this.handleDragEnter(e)}
              onDragLeave={(e) => this.handleDragLeave(e)}
            >
              <p>
                Drop <strong>Item(s)</strong> here to Add into {productName}{" "}
                Product
              </p>
            </div>

            <Divider />

            <div color="black">
              <strong>
                {" "}
                {validate.capitalize(validate.prettify(productName))}{" "}
              </strong>
            </div>
            <Divider />
          </>
        )}

        {productId &&
          (items.length == 0 ? (
            <Segment basic>
              <Container>
                {" "}
                <em>Currently No Item(s) In this Product </em>
              </Container>
            </Segment>
          ) : (
            ""
          ))}

        {items && (
          <Segment basic className="items-wrapper">
            <List animated selection divided verticalAlign="middle">
              {items.map((item) => (
                <List.Item>
                  <List.Content floated="right">
                  { item.parent_id!=parentProductId && (
                    <Icon
                      color="red"
                      link
                      name="close"
                      title="Remove"
                      onClick={() => this.showDeleteWarningAndSetItem(item)}
                    />
                     ) }
                  </List.Content>
                  <Image>
                    <FileIcon
                      filename={item.filename ? item.filename : "other"}
                      mimetype={item.itemType}
                    ></FileIcon>
                  </Image>
                  <List.Content>
                    <List.Header as="a"> </List.Header>
                    <span className="black">{item.name}</span>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        )}
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updateProducts)}
          content={`Are you sure you want to delete this Item?`}
        />
      </React.Fragment>
    );
  }
}

export default Product;
