import Api from "@/api";
import { getAllSearchIndices, syncIndices } from "../../../../api/admin";
import Constants from "@/config/constants";
import React from "react";
import { connect } from "react-redux";
import { Segment, Button, Responsive, Confirm } from "semantic-ui-react";
import { CardviewMobile } from "@/common/cardview";
import Tableview from "@/common/tableview";
import { dateToPacific } from "@/utils/dateTime";
import { isEmpty } from "lodash";

const Columns = {
  columns: [
    { name: "Indexes", key: "index_name" },
    {
      name: "Last Run",
      key: "last_run",
    },
    {
      name: "Run By",
      key: "run_by",
    },
    {
      name: "Status",
      key: "last_status",
    },
    {
      name: "Triggers",
      key: "actions",
    },
  ],
  responsive_columns: [
    {
      name: "Indexes",
      key: "index_name",
      width: 2,
    },
    {
      name: "Last Run",
      key: "last_run",
      width: 2,
    },
    {
      name: "Run By",
      key: "run_by",
    },
    {
      name: "Status",
      key: "last_status",
    },
  ],
};
class SearchSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: {},
      statusInterval: null,
      popupWarning: false,
      indices: [],
      reindexAll: false,
    };
  }

  componentDidMount() {
    this.loadData();
    this.props.getAllSearchIndices();
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.indices.length != this.state.indices.length) {
      this.setState({
        indices: nextprops.indices,
      });
    }
  }
  showpopupWarning = () => {
    this.setState({
      popupWarning: true,
    });
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
// unlisten component in the componentWillUnmount
  componentWillUnmount() {
    if (this.state.statusInterval) {
      clearInterval(this.state.statusInterval);
    }
  }

  _loadData = () => {
    Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_REINDEX_STATUS, {})
      .then((resp) => {
        let data = resp.data;
        this.setState(
          {
            status: data,
            reindexAll: data.isIndexing,
          },
          () => {
            if (!this.state.reindexAll) {
              this.props.getAllSearchIndices();
            }
          }
        );
      });
  };

  loadData = () => {
    this.setState({
      statusInterval: setInterval(this._loadData, 5000),
    });
  };

  onReindexClick = () => {
    this.setState({ reindexAll: true }, () => {
      Api.AxiosInstance.getInstance()
        .post(Constants.App.URL_ADMIN_REINDEX, {})
        .then((resp) => {
          let data = resp.data;
          this.setState({
            status: data,
            reindexAll: false,
          });
        })
        .catch((err) => {
          this.setState({
            reindexAll: false,
          });
        });
    });
  };

  transformData = (data) => {
    const obj = {
      content: data,
    };
    let transformedData = Object.assign({}, obj);
    let content = [];
    if (data) {
      transformedData.content.map((el, index) => {
        let row = Object.assign({}, el);
        let adminActions = (
          <React.Fragment>
            <div class="field">
              <div class="ui input">
                <Button
                  loading={this.props.buttonLoading.index == index}
                  onClick={() => this.onSyncNowClick(el.index_name, index)} // onClick={this.loadProjectList}
                  className="button-primary"
                >
                  Sync Now
                </Button>
              </div>
            </div>
          </React.Fragment>
        );
        if (el.index_name == "Articles" || el.index_name == "Tickets") {
          row.index_name = el.index_name + " (Helpdesk)";
        } else if (el.index_name == "Items") {
          row.index_name = el.index_name + " (Downloads)";
        } else if (el.index_name == "Products") {
          row.index_name = "Folders (Downloads)";
        }
        row.run_by = el.run_by === "JOB_SCHEDULER" ? "Scheduler" : el.run_by;
        row.last_run = dateToPacific(el.last_run, "MM/DD/YYYY hh:mm:ss A");
        row.actions = adminActions;
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };
  onSyncNowClick = (type, index) => {
    this.props.syncIndices(type, index);
  };

  render() {
    const { status, indices } = this.state;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">Search Settings</div>
          <div className="empty-height-small" />

          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Indexing Status</h2>
            <div class="field">
              <div class="ui input">
                <Button
                  onClick={this.showpopupWarning}
                  className="button-primary"
                  loading={this.state.reindexAll}
                >
                  Reindex All
                </Button>
              </div>
            </div>
          </div>
          {status && status.isIndexing && (
            <div>
              <h4>
                <label>ReIndexing In Progress</label>
              </h4>
              <div>
                <strong>Index Name: </strong> {status.indexName}
              </div>
              <div>
                <strong>Total Items: </strong> {status.totalItems}
              </div>
              <div>
                <strong>Current Indexed Items: </strong> {status.currentOffset}
              </div>
              <hr />
            </div>
            
          )}
          {/* {(!status || !status.isIndexing) && <h4>Normal</h4>} */}

          <Responsive minWidth={1024}>
            <div className="table-wrapper-with-pagination">
              <Tableview
                // paginate
                data={
                  !isEmpty(this.props.indices) &&
                  this.transformData(this.props.indices)
                }
                columns={Columns.columns}
                noDataMessage="No Record Found"
                loading={this.props.loading}
                loader={true}
              />
            </div>
          </Responsive>
          <Responsive maxWidth={1023}>
            <CardviewMobile
              data={
                !isEmpty(this.props.indices) &&
                this.transformData(this.props.indices)
              }
              columns={Columns.responsive_columns}
              // loading={this.props.loading}
            />
          </Responsive>
        </Segment>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.onReindexClick)}
          content="Are you sure you want to reindex the entire data?"
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllSearchIndices: () => {
      dispatch(getAllSearchIndices());
    },
    syncIndices: (indexName, index) => {
      dispatch(syncIndices(indexName, index));
    },
  };
};
const mapStateToProps = (state) => {
  return {
    indices: state.searchIndcesReducer.indices,
    loading: state.searchIndcesReducer.loading,
    buttonLoading: state.searchIndcesReducer.buttonLoading,
  };
};
// export default SearchSettingsForm;
export default connect(mapStateToProps, mapDispatchToProps)(SearchSettingsForm);