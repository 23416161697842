import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Icon, Modal, Confirm, Grid } from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";
import Api from "../../../api";
import ListingWithSearch from "../../../common/listing-with-search";
import FileIcon from "../../../common/file-icon";
import projectIdDispatcher from "../dispatch";
import urls from "../../../config/constants";
import { userNameSelector, authoritySelector } from "@/common/selectors";
import { connect } from "react-redux";
import CONSTANTS from "@/config/constants";
import VideoPlayer from "react-video-js-player";
import { isVideo } from "../../../common/utils/utils";
import MultiDownloadOpts from "../../../common/multiple-download-opts";
import { default as Config, default as Constants } from "@/config/constants";

class DocumentListing extends React.Component {
  Constants = {
    COLUMNS: [
      { name: "Name", key: "name", width: 3, showTooltip: true },
      { name: "Description", key: "description", width: 3, showTooltip: true },
      { name: "File Name", key: "filename", width: 3, showTooltip: true },
      {
        name: "Creation Date",
        key: "creationDate",
        isDate: true,
        showTooltip: true,
      },
      { name: "Created By", key: "createdBy", showTooltip: true },
      {
        name: "Type",
        key: "type",
        width: "1",
        class: "center",
        showTooltip: false,
      },
      { name: "Actions", key: "actions" },
    ],
    responsive_columns: [
      { name: "Name", key: "name", width: 3 },
      { name: "Description", key: "description", width: 3 },
      { name: "File Name", key: "filename", width: 3 },
      { name: "Creation Date", key: "creationDate", isDate: true },
      { name: "Created By", key: "createdBy" },
      { name: "Type", key: "type", width: "1", class: "center" },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMsg: null,
      showError: false,

      selectedVideoLink: "",
      selectedVideoName: "",
      isLoaded: false,
      playVideo: false,
      isModalOpen: false,
      selectedFile: {},
    };
  }

  componentDidMount() {
    this.loadDocments();
  }
  loadDocments = () => {
    const { projectId } = this.props;
    projectIdDispatcher.dispatch({
      type: "PROJECT_ID_CHANGE",
      value: projectId,
    });
  };
  closeModal = () => this.setState({ isModalOpen: false });

  downloadDocument = (el) => {
    const { URL_PROJECT_DOCUMENT_DOWNLOAD } = urls.App;
    Api.AxiosInstance.getInstance()
      .get(`${URL_PROJECT_DOCUMENT_DOWNLOAD}?documentId=${el.docId}`)
      .then((resp) => {
        console.log("resp", resp);

        // TODO: res.data as obj
        let basePath = `${Constants.App.API_URL}projects/`;

        const mock = {
          wgetCommand: `wget ${basePath}${resp.data.command} -O ${el.filename}`,
          curlCommand: `curl ${basePath}${resp.data.command} -o ${el.filename} -D-`,
          downloadLink: resp.data.downloadLink,
          directLink: resp.data.directLink,

          fileName: el.filename,
          fileDesc: el.description,
        };

        this.setState({ selectedFile: mock });
        this.setState({ isModalOpen: true });
      })
      .catch((err) =>
        this.setState({
          showError: true,
          errorMsg: "An error occured while downloading the document.",
        })
      );
  };
  showDeleteWarningAndSetItem = (item) => {
    this.setState({
      selectedItems: item,
      deleteWarning: true,
    });
  };
  hideDeleteWarning = (cb) => {
    this.setState({ deleteWarning: false }, () => {
      if (cb instanceof Function) cb();
    });
  };
  onDelete = () => {
    const { URL_PROJECT_DOCUMENT } = urls.App;
    const { selectedItems } = this.state;
    Api.AxiosInstance.getInstance()
      .delete(`${URL_PROJECT_DOCUMENT}?id=${selectedItems.docId}`)
      .then((resp) => {
        this.setState({ selectedItems: [] });
        window.location.reload();

        this.loadDocments();
      })
      .catch((err) => {
        this.setState({
          showError: true,
          errorMsg: "An error occured while deleting the item(s)",
          errorMsg: err.response.data.message,
          selectedItems: [],
        });
      });
  };

  dataMapper = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    transformedData.content.map((el) => {
      // console.log("el", el);
      content.push({
        id: el.docId,

        editLink: (
          <Link to="#">
            <span className="icon-edit" title="Edit" />
          </Link>
        ),
        actions: (
          <React.Fragment>
            {!isVideo(el.filename ? el.filename : el.name) &&
              !el.directLink && (
                <span
                  className="icon-download"
                  title="Download"
                  onClick={() => this.downloadDocument(el)}
                />
              )}
            {isVideo(el.filename ? el.filename : el.name) && (
              <a
                href="#"
                onClick={(e) => this.getVideoStreamLink(e, el.docId, el.name)}
              >
                {<span className="icon-play" title="Play" />}
              </a>
            )}
            {/* external link check */}

            {el.directLink && (
              <a
                href={
                  el.directLink.includes("https", "http")
                    ? el.directLink
                    : `https://${el.directLink}`
                }
                target="_blank"
              >
                {<span className="icon-link-4"></span>}
              </a>
            )}

            <a href="#" target="_blank">
              {<i className="icon-link-2"></i>}
            </a>
            {this.props.authority !== "websilo-customer" &&
            this.props.authority !== "websilo-employee" &&
            this.props.authority !== "websilo-account-approver" ? (
              <a onClick={() => this.showDeleteWarningAndSetItem(el)}>
                {
                  <span
                    style={{ margin: "5px" }}
                    className="icon-delete"
                    title="Remove"
                    name="remove"
                  />
                }
              </a>
            ) : el.createdBy == this.props.userName ? (
              <a onClick={() => this.showDeleteWarningAndSetItem(el)}>
                {
                  <span
                    style={{ margin: "5px" }}
                    className="icon-delete"
                    title="Remove"
                    name="remove"
                  />
                }
              </a>
            ) : (
              ""
            )}
          </React.Fragment>
        ),
        name: (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.downloadDocument(el)}
          >
            {el.name}
          </span>
        ),
        description: el.description,
        filename: el.filename ? el.filename : "-",
        creationDate: (
          <>{dateToPacific(el.creationDate, "MM/DD/YYYY hh:mm a")}</>
        ),
        createdBy: el.createdBy,
        type: el.type,
        type: (
          <FileIcon
            filename={el.filename ? el.filename : "other"}
            mimetype={el.type ? el.type : el.directLink ? "link" : ""}
          ></FileIcon>
        ),
      });
    });
    transformedData.content = content;
    return transformedData;
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  getVideoStreamLink = (e, id, name) => {
    // get link
    e.preventDefault();
    const { URL_STREAM_VIDEO } = CONSTANTS.App;
    const params = { params: { id } };
    const { URL_PROJECT_DOCUMENT_DOWNLOAD } = urls.App;

    const instance = Api.AxiosInstance.getInstance();

    instance
      .get(`${URL_PROJECT_DOCUMENT_DOWNLOAD}?documentId=${id}`)
      .then((resp) => {
        console.log("resp", resp.data);
        this.setState({
          selectedVideoLink: resp.data.downloadLink,
          selectedVideoName: name,
          isLoaded: true,
          playVideo: true,
        });
      });
  };

  render() {
    const { COLUMNS, responsive_columns } = this.Constants;
    const { errorMsg, showError, playVideo } = this.state;
    const { createButton, defaultParams, fetchUrl, label } = this.props;
    const { URL_PROJECT_DOCUMENT } = urls.App;
    return (
      <React.Fragment>
        <ListingWithSearch
          columns={COLUMNS}
          responsive_columns={responsive_columns}
          fetchUrl={fetchUrl}
          title={label}
          searchTermKey="search"
          dataMapperFunction={this.dataMapper}
          defaultParams={defaultParams}
          // extraAction={{
          //   text: "Delete",
          //   url: URL_PROJECT_DOCUMENT,
          //   action: "delete",
          // }}
          menuButtons={createButton}
        />
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>

        <Modal
          onClose={() => this.setState({ playVideo: false })}
          open={playVideo}
        >
          <Modal.Header>{this.state.selectedVideoName}</Modal.Header>
          <Modal.Content>
            {" "}
            <VideoPlayer
              controls={true}
              src={this.state.selectedVideoLink}
              width="860"
              height="420"
            />
            <Grid>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="m--align-right"
              >
                <Button
                  className="button-primary mr-0-im mt-2-im "
                  onClick={() => this.setState({ playVideo: false })}
                >
                  Close
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>

        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.onDelete)}
          content="Are you sure you want to delete this document?"
        />

        {this.state.isModalOpen && (
          <MultiDownloadOpts
            isModalOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            selectedFile={this.state.selectedFile}
          ></MultiDownloadOpts>
        )}
      </React.Fragment>
    );
  }
}

DocumentListing.propTypes = {
  /** A button which will direct to create document page */
  createButton: PropTypes.node,
  /** Url params which will be appended to other url params */
  defaultParams: PropTypes.object,
  /** /** URL this component will use to fetch latest data */
  fetchUrl: PropTypes.string.isRequired,
  /** text will be displayed as header. */
  label: PropTypes.string.isRequired,
  /** id of the project from where documents are being fetched */
  projectId: PropTypes.number,
};
const mapStateToProps = (state) => ({
  authority: authoritySelector(state),
  userName: userNameSelector(state),
  // baseUrl: baseUrl,
});
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mappedDocumentListing = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentListing);

export default mappedDocumentListing;
