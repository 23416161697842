import Api from '@/api';
import {
  configSelector,
  restrictedEmailDomainsSelector,
} from '@/common/selectors';
import { UserInfoForm } from '@/common/user';
import { removeEmptyKeys } from '@/common/utils/utils';
import {
  adminNormalValidationConfig,
  normalValidationConfigForSelfRegister,
} from '@/common/validations/userValidation';
import Constants from '@/config/constants';
import update from 'immutability-helper';
import { get, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dimmer,
  Loader,
  Segment,
  Button,
  Confirm,
  Grid,
  Modal,
  Header,
} from 'semantic-ui-react';
import { validate } from 'validate.js';
import postalCodes from 'postal-codes-js';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
class EditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formDisabled: false,
      validationMsg: {},
      form: {},
      msg: '',
      validationModal: false,
      authMsg: '',
      phonelocal: false,
      phoneform: true,
      codeform: true,
      code: '',
      completephone: '',
      localcountry: '',
      localpostal: '',
      localresult: true,
      popupWarning: false,
      local: false,
    };
  }
  setTwoFA = () => {
    const { isActive, id } = this.state;
    // copy button add krna hai
    let data = {
      isActive: !isActive,
      userId: id,
    };

    Api.AxiosInstance.getInstance()
      .post(`/twoFactorAuthKey/activeUser`, data)
      .then((resp) => {
        this.setState({ authMsg: resp.data, validationModal: true });
        this.loadData();
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            msg: error.response.data.message,
            msgSpanColor: 'red',
            loading: false,
            apiError: error.response.data.message,
            formDisabled: false,
          });
        }
      });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { id } = this.props.match.params;

    let userRequestOptions = {};
    if (id != null) {
      userRequestOptions.params = { id: id };
      Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_ADMIN_USER_SINGLE, userRequestOptions)
        .then((resp) => {
          this.setState({ form: resp.data });

          let data = resp.data;
          data.code = this.state.code;
          data.phone = this.formatphone(data.phone);
          this.setState({
            id: data.id,
            localcountry: data.country,
            localpostal: data.postalCode,
            username: data.username,
            firstName: data.firstName,
            middleInitial: data.middleInitial,
            lastName: data.lastName,
            title: data.title,
            company: data.company,
            address: data.address,
            address2: data.address2,
            city: data.city,
            countryCode: data.country,
            state: data.state,
            stateCode: data.state,
            postalCode: data.postalCode,
            phone: data.phone,
            email: data.email,
            userType: data.userType,
            userGroup: data.userGroup,
            contactName: data.contactName,
            projectDetails: data.projectDetails,
            remarks: data.remarks,
            status: data.status,
            updateNotificationFrequency: data.updateNotificationFrequency,
            accountRenewalCycle: data.accountRenewalCycle,
            createdBy: data.createdBy,
            modifiedBy: data.modifiedBy,
            creationDate: data.creationDate,
            modificationDate: data.modificationDate,
            isActive: data.key ? true : false,
            techSupport: data.techSupport
          });
        });
    }
  };
  formatCountryCode = (value) => {
    var code = String(value);
    if (this.state.codeform) {
      var code = String(value);
      var phone = String(this.state.phone);
      this.state.code = phone.substring(0, phone.indexOf('('));
      return this.state.code;
    } else {
      return value;
    }
  };
  countryCodeChange = (event, data) => {
    const { form } = this.state;
    this.setState({ codeform: false });
    //this.setState({ code: data.value});
    this.setState({
      form: update(form, { [data.name]: { $set: data.value } }),
    });
  };
  isNumeric(value) {
    return /^\d+$/.test(value);
  }
  formatphone = (value) => {
    const { form } = this.state;
    var phone = String(value);
    if (this.state.phoneform) {
      var phone = String(value);
      var plusvalue = '';
      if (phone[0] == '+') {
        this.state.code = phone.substring(0, phone.indexOf('('));
        form.code = this.state.code;
        for (var i = phone.indexOf('('); i < phone.length; i++) {
          if (this.isNumeric(phone.charAt(i))) {
            plusvalue = plusvalue + phone.charAt(i);
          }
        }
        phone = plusvalue;
        phone =
          '(' +
          phone.substring(0, 3) +
          ') ' +
          phone.substring(3, 6) +
          ' ' +
          phone.substring(6, phone.length);
        value = phone;
        return phone;
      } else if (phone[0] == '(' && phone[4] == ')' && phone[5] == ' ') {
        return value;
      } else {
        phone =
          '(' +
          phone.substring(0, 3) +
          ') ' +
          phone.substring(3, 6) +
          ' ' +
          phone.substring(6, phone.length);
        value = phone;
        return phone;
      }
    } else {
      if (phone[0] == '+') {
        this.state.code = phone.substring(0, phone.indexOf('('));
        form.code = this.state.code;
        return phone.substring(phone.indexOf('('));
      } else {
        return phone;
      }
    }
  };
  onPhoneChange = (event, data) => {
    const { form } = this.state;
    this.state.phoneform = false;
    let cond = event.target.value;
    var tempphone = '';
    for (var i = 0; i < cond.length; i++) {
      if (this.isNumeric(cond.charAt(i))) {
        tempphone = tempphone + cond.charAt(i);
      }
    }
    if (tempphone.length < 3) {
      this.setState({ form: update(form, { phone: { $set: tempphone } }) });
    } else if (
      tempphone.length == 3 &&
      cond.charAt(cond.length - 1) == ')' &&
      cond.length == 5
    ) {
      this.setState({
        form: update(form, { phone: { $set: tempphone.substring(0, 2) } }),
      });
    } else if (tempphone.length == 3) {
      this.setState({
        form: update(form, {
          phone: { $set: '(' + tempphone.substring(0, 3) + ') ' },
        }),
      });
    } else if (tempphone.length > 3 && tempphone.length < 6) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              '(' + tempphone.substring(0, 3) + ') ' + tempphone.substring(3),
          },
        }),
      });
    } else if (tempphone.length == 6 && this.state.local == false) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              '(' +
              tempphone.substring(0, 3) +
              ') ' +
              tempphone.substring(3, 6) +
              ' ',
          },
        }),
      });
      this.setState({ local: true });
    } else if (tempphone.length == 6 && this.state.local == true) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              '(' +
              tempphone.substring(0, 3) +
              ') ' +
              tempphone.substring(3, 6),
          },
        }),
      });
      this.setState({ local: false });
    } else if (tempphone.length >= 6 && tempphone.length < 12) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              '(' +
              tempphone.substring(0, 3) +
              ') ' +
              tempphone.substring(3, 6) +
              ' ' +
              tempphone.substring(6),
          },
        }),
      });
    } else if (tempphone.length >= 12) {
      this.setState({
        form: update(form, {
          phone: {
            $set:
              '(' +
              tempphone.substring(0, 3) +
              ') ' +
              tempphone.substring(3, 6) +
              ' ' +
              tempphone.substring(6, 11),
          },
        }),
      });
    }
  };

  checkPostalCode = (e) => {
    var res = postalCodes.validate(
      this.state.localcountry,
      this.state.localpostal
    );
    if (this.state.localpostal == '') {
      res = true;
    }
    this.state.localresult = res;
    const { validationMsg } = this.state;
    let value = res ? { postalCode: res } : [];
    value.localresult = this.state.localresult;
    let obj = {
      postalCode: normalValidationConfigForSelfRegister['postalCode'],
    };
    const singleFieldValidation = validate(value, obj);
    if (isEmpty(singleFieldValidation)) {
      this.setState({
        validationMsg: update(validationMsg, {
          postalCode: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          postalCode: { $set: singleFieldValidation['postalCode'] },
        }),
      });
    }
  };

  showpopupWarning = () => {
    const { form } = this.state;
    form.localresult = this.state.localresult;
    const { restrictedEmailDomains } = this.props;
    let cleanedForm = removeEmptyKeys(form);
    cleanedForm.phone = cleanedForm.phone;
    cleanedForm.restrictedDomains = restrictedEmailDomains;
    delete cleanedForm.cellPhone;
    const staticValidation = validate(cleanedForm, adminNormalValidationConfig);
    if (!isEmpty(staticValidation)) {
      this.setState({
        validationMsg: staticValidation,
        msg: 'Validation failed, please fix the errors before submitting',
        msgSpanColor: 'red',
      });
      return;
    }
    this.setState({
      popupWarning: true,
    });
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  onDataChange = (event, data) => {
    const { form } = this.state;
    if (data.name == 'countryCode') {
      this.state.localcountry = data.value;
      this.setState({
        form: update(form, { [data.name]: { $set: data.value } }),
      });
      this.checkPostalCode(event);
    } else if (data.name == 'postalCode') {
      this.state.localpostal = data.value;
      this.setState({
        form: update(form, { [data.name]: { $set: data.value } }),
      });
      this.checkPostalCode(event);
    } else {
      this.setState({
        form: update(form, { [data.name]: { $set: data.value } }),
      });
    }
  };
  onByteChange = (event, eventData) => {
    const { form } = this.state;
    let value = eventData.value ? 1 : null;

    this.setState({
      form: update(form, { [eventData.name]: { $set: value } }),
    });
  };
  onBlur = (e) => {
    const { validationMsg } = this.state;
    const { form } = this.state;
    const { restrictedEmailDomains } = this.props;
    let value = e.target.value ? { [e.target.name]: e.target.value } : [];
    if (e.target.name === 'email') {
      value.restrictedDomains =
        restrictedEmailDomains && restrictedEmailDomains
          ? restrictedEmailDomains
          : '';
    }
    if (e.target.name === 'phone' || e.target.name === 'cellPhone') {
      value.countryCode = form.countryCode;
    }
    if (e.target.name === 'postalCode') {
      return;
    }
    let obj = { [e.target.name]: adminNormalValidationConfig[e.target.name] };
    const singleFieldValidation = validate(value, obj);
    if (isEmpty(singleFieldValidation)) {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: singleFieldValidation[e.target.name] },
        }),
      });
    }
  };

  onPasswordChange = (passwordState, feedback) => {
    const { form } = this.state;
    this.setState({
      form: update(form, {
        password: { $set: passwordState.password },
        passwordScore: { $set: get(feedback, ['score']) },
      }),
    });
  };
  save = () => {
    const {
      username,
      password,
      retypePassword,
      firstName,
      middleInitial,
      lastName,
      title,
      company,
      address,
      address2,
      city,
      countryCode,
      state,
      stateCode,
      postalCode,
      phone,
      cellPhone,
      email,
      userType,
      userGroup,
      contactName,
      projectDetails,
      remarks,
      status,
      updateNotificationFrequency,
      accountRenewalCycle,
      createdBy,
      modifiedBy,
      creationDate,
      modificationDate,
      techSupport
    } = this.state;
    let data = {
      id: this.props.id ? this.props.id : null,
      username,
      password,
      retypePassword,
      firstName,
      middleInitial,
      lastName,
      title,
      company,
      address,
      address2,
      city,
      countryCode,
      state,
      stateCode,
      postalCode,
      phone,
      cellPhone,
      email,
      userType,
      userGroup,
      contactName,
      projectDetails,
      remarks,
      status,
      updateNotificationFrequency,
      accountRenewalCycle,
      createdBy,
      modifiedBy,
      creationDate,
      modificationDate,
      techSupport
    };

    let msg = '';
    let color = 'black';
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_USER_SAVE, data)
      .then((resp) => {
        if (resp.data.id > 0) {
          msg = this.props.id ? 'User updated' : 'New User Added';
          color = 'green';
        } else {
          msg = 'Request failed, Please try later.';
          color = 'red';
        }

        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
        });
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            msgSpanText: error.response.data.message,
            msgSpanColor: 'red',
            loading: false,
          });
        }
      });
  };
  // need to change this into save/update form
  updateUser = () => {
    let { form, validationMsg, msg, color } = this.state;
    //form.phone=this.state.code+form.phone;
    let onlynum = String(form.phone);
    onlynum = onlynum.substring(onlynum.indexOf('('));
    this.setState({
      completephone: form.code + onlynum
    });
    form.phone = form.code + onlynum;
    form.localresult = this.state.localresult;
    // if (!isEmpty(validationMsg)) return;
    // else
    // debugger;
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_USER_SAVE, form)
      .then((resp) => {
        if (resp.data.id > 0) {
          msg = 'User updated';
          color = 'green';
        } else {
          msg = 'Request failed, Please try later.';
          color = 'red';
        }
        this.setState(
          {
            msgSpanText: msg,
            form: { ...resp.data },
            msg: msg,
            msgSpanColor: color,
            loading: false,
            formDisabled: false,
          },
          this.loadData()
        );
      })
      .catch((error) => {
        this.setState({
          formDisabled: false,
          apiError: error.response.data.message,
          msg: 'Updation Failed. Cannot Update user.',
        });
      });
  };
  showModal = () => {
    this.setState({
      showModal: true,
    });
  };
  onCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };
  hideModal = (cb) => {
    this.setState(
      {
        showModal: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  validateForm = (event) => {
    const { form } = this.state;
    form.localresult = this.state.localresult;
    const { restrictedEmailDomains } = this.props;
    let cleanedForm = removeEmptyKeys(form);
    cleanedForm.restrictedDomains = restrictedEmailDomains;
    delete cleanedForm.cellPhone;
    const staticValidation = validate(cleanedForm, adminNormalValidationConfig);
    if (isEmpty(staticValidation)) {
      this.setState(
        { formDisabled: true, validationMsg: {} },
        // call save/update function
        this.updateUser
      );
    } else {
      this.setState({
        validationMsg: staticValidation,
        msg: 'Validation failed, please fix the errors before submitting',
        msgSpanColor: 'red',
      });
    }
  };
  render() {
    const {
      apiError,
      form,
      formDisabled,
      validationMsg,
      msg,
      msgSpanColor,
      isActive,
    } = this.state;
    const { config } = this.props;

    if (config.success)
      return (
        <Segment basic>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="side-marker" />
                <div className="header">General Settings</div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="control-action-wrapper has-modify">
                  <Link to={{pathname:"/admin/users-listing",query:this.props.location.query,pageNum:this.props.location.pageNum}} className="btn-back-main mr-1-im">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      title="Back"
                      name="back"
                    />
                    &nbsp;Back
                  </Link>
                  <Button
                    className="button-basic"
                    onClick={() => {
                      this.showModal();
                    }}
                  >
                    {isActive
                      ? 'Disable Two Factor Authentication'
                      : 'Enable Two Factor Authentication'}
                  </Button>

                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div className="empty-height-small" />

          {/*UserInfo Form, Reusable Component*/}

          <UserInfoForm
            userId={form.id}
            validateForm={this.showpopupWarning}
            apiError={apiError}
            formDisabled={formDisabled}
            form={form}
            validationMsg={validationMsg}
            onDataChange={this.onDataChange}
            onBlur={this.onBlur}
            config={config}
            backLink={'/admin/users-listing'}
            msg={msg}
            onPasswordChange={this.onPasswordChange}
            formType="AdminRegisteration"
            errorTitle="User Updation Failed"
            msgSpanColor={msgSpanColor}
            onByteChange={this.onByteChange}
            onPhoneChange={this.onPhoneChange}
            formatphone={this.formatphone}
            countryCodeChange={this.countryCodeChange}
            formatCountryCode={this.formatCountryCode}
            query={this.props.location.query}
            pageNum={this.props.location.pageNum}
          />

          <Confirm
            open={this.state.showModal}
            onCancel={this.hideModal}
            onConfirm={() => this.hideModal(this.setTwoFA)}
            content={`Are you sure you want to ${isActive ? 'Disable' : 'Enable'
              } Two Factor Authentication for this User?`}
          />
          <Confirm
            open={this.state.popupWarning}
            onCancel={this.hidepopupWarning}
            onConfirm={() => this.hidepopupWarning(this.validateForm)}
            content="Are you sure you want to update user?"
          />
          <Modal
            closeIcon
            open={this.state.validationModal}
            onClose={() => this.setState({ validationModal: false })}
            className="tiny"
          >
            <Header content="Two Factor Authentication" />
            <Modal.Content>
              <p>
                <b>{this.state.authMsg}</b>
              </p>
            </Modal.Content>
            <Modal.Actions>
              {/* onClick={() => setOpen(false)} */}
              <Button onClick={() => this.setState({ validationModal: false })}>
                Ok
              </Button>
            </Modal.Actions>
          </Modal>
        </Segment>
      );
    else
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
  }
}

const mapStateToProps = (state) => ({
  config: configSelector(state),
  restrictedEmailDomains: restrictedEmailDomainsSelector(state),
});

export default EditUser = connect(mapStateToProps)(EditUser);
