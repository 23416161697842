import Axios from "axios";
import Constants from "../../config/constants";
import actions from "../../store/actions";
import LocalStore from "@/store/persistence/local-storage";
import history from "../../utils/history";

export const addArticle = (data) => async (dispatch) => {
  dispatch({
    type: actions.Zendesk.ADD_ARTICLES_INITIATE,
  });
  try {
    const token = LocalStore.getValue("token");
    const response = await Axios.post(
      `${Constants.App.API_URL}articles`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    dispatch({
      type: actions.Zendesk.ADD_ARTICLES_SUCCESS,
      payload: response.data,
    });
    history.go(-1);
  } catch (ex) {
    dispatch({
      type: actions.Zendesk.ADD_ARTICLES_FAILURE,
      payload: ex.response.data.message,
    });
  }
};

export const getArticles = (page,sort="sequence,ASC", size=10) => async (dispatch) => {
  dispatch({
    type: actions.Zendesk.INITIATE_GET_ARTICLES,
  });
  try {
    const token = LocalStore.getValue("token");
    const response = await Axios.get(
      `${Constants.App.API_URL}articles?page=${page}&${size}&sort=${sort}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    let payload = {
      data: response.data,
      page, 
    }
    dispatch({
      type: actions.Zendesk.GET_ARTICLES_SUCCESS,
      payload: payload,
    });
  } catch (ex) {
    dispatch({
      type: actions.Zendesk.GET_ARTICLES_FAILURE,
    });
  }
};

export const getArticleById = (id) => async (dispatch) => {
  dispatch({
    type: actions.Zendesk.GET_ARTICLE_BY_ID_INITIATE,
  });
  try {
    const token = LocalStore.getValue("token");
    const response = await Axios.get(`${Constants.App.API_URL}articles/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    dispatch({
      type: actions.Zendesk.GET_ARTICLE_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (ex) {
    dispatch({
      type: actions.Zendesk.GET_ARTICLE_BY_ID_FAILURE,
    });
  }
};

export const updateArticle = (data, id) => async (dispatch) => {
  dispatch({
    type: actions.Zendesk.UPDATE_ARTICLE_INITIATE,
  });
  try {
    const token = LocalStore.getValue("token");
    const response = await Axios.put(
      `${Constants.App.API_URL}articles`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    dispatch({
      type: actions.Zendesk.UPDATE_ARTICLE_SUCCESS,
      payload: response.data,
    });
    history.go(-1);
  } catch (ex) {
    dispatch({
      type: actions.Zendesk.UPDATE_ARTICLE_FAILURE,
      payload: ex.response.data.message,
    });
  }
};
export const deleteArticles = (id, pageNum) => async (dispatch) => {
  dispatch({
    type: actions.Zendesk.DELETE_ARTICLES_INITIATE,
  });
  try {
    const token = LocalStore.getValue("token");
    const response = await Axios.delete(
      `${Constants.App.API_URL}articles/${id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    let payload = {
      data: response.data,
      page: pageNum,
    };
    dispatch({
      type: actions.Zendesk.DELETE_ARTICLES_SUCCESS,
      payload: payload,
    });
    dispatch(getArticles(pageNum));
  } catch (ex) {
    dispatch({
      type: actions.Zendesk.DELETE_ARTICLES_FAILURE,
    });
  }
};

export const getMaximumSequence = () => async (dispatch) => {
  dispatch({
    type: actions.Zendesk.GET_MAX_SEQUENCE_INITIATE,
  });
  try {
    const token = LocalStore.getValue("token");
    const response = await Axios.get(
      `${Constants.App.API_URL}articles/sequence`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    dispatch({
      type: actions.Zendesk.GET_MAX_SEQUENCE_SUCCESS,
      payload: response.data,
    });
  } catch (ex) {
    dispatch({
      type: actions.Zendesk.GET_MAX_SEQUENCE_FAILURE,
    });
  }
};

export const checkIfSequenceAlreadyExists = (sequence) => async (dispatch) => {
  try {
    const token = LocalStore.getValue("token");
    const response = await Axios.get(
      `${Constants.App.API_URL}articles/sequence/${sequence}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    dispatch({
      type: actions.Zendesk.GET_EXISTING_SEQUENCE_SUCCESS,
      payload: response.data,
    });
  } catch (ex) {
    dispatch({
      type: actions.Zendesk.GET_EXISTING_SEQUENCE_FAILURE,
    });
  }
};


export const setPageNumber = (page) => (dispatch) => {
  console.log("SETTINGPAGE NUM", page)
  dispatch({
    type: actions.Zendesk.SET_PAGE_NUMBER,
    payload: page,
  });
}