import React from "react";
import Api from "@api";
import Constants from "@constants";
import { validate } from "validate.js";
import update from "immutability-helper";
import { normalValidation } from "./validation";
import {
  InputField,
  FileField,
  UserTypeField,
  DropdownField
} from "@/common/Form";
import Uploader from "@/common/uploader";
import { Redirect } from "react-router-dom";
import {
  Form,
  Segment,
  Grid,
  Progress,
  Message,
  Confirm
} from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DatePickerWithTimezone from "../../../utils/DatePickerWithTimeZone";

const statusOptions = [
  { key: "Active", text: "Active", value: "ACTIVE" },
  { key: "Disabled", text: "Disabled", value: "DISABLED" },
];
const licenseType = [
  { key: "item", text: "ITEM LICENSE", value: "ITEM_LICENSE" },
  { key: "site", text: "SITE LICENSE", value: "SITE_LICENSE" },
];
class Licenses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      version: null,
      filename: null,
      fileType: null,
      publishStartDate: new Date(),
      status: "ACTIVE",
      itemType: null,
      createdBy: null,
      creationDate: null,
      type: "ITEM_LICENSE",
      modificationDate: null,
      modifiedBy: null,
      uploadProgress: 0,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: "",
      msgSpanText: "",
      msgSpanColor: "black",
      itemLoadXHR: false,
      redirectTo: false,
      apiError: false,
      popupWarning: false,
      userTypes: [],
      statusOptions: [
        { text: 'Active', value: 'ACTIVE', key: 'ACTIVE'},
        { text: 'Disabled', value: 'DISABLED', key: 'DISABLED'}
      ]
    };
  }

  componentDidMount() { }

  handleDateChange = (date) => {
    this.setState({
      publishStartDate: date,
    });
  };

  transformUserTypeIdToUserObj(userTypeIds) {
    return userTypeIds && userTypeIds.length ? userTypeIds.map((id) => ({ id })) : [];
  }

  save = () => {
    const {
      id,
      name,
      version,
      file,
      fileuuid,
      status,
      type,
      publishStartDate,
      userTypes
    } = this.state;
    let data = {
      id,
      name,
      version,
      file,
      fileuuid,
      filename: this.state.filename ? this.state.filename : 0,
      fileSize: this.state.fileSize ? this.state.fileSize : 0,
      fileType: this.state.fileType ? this.state.fileType : "",
      publishStartDate: new Date(publishStartDate),
      status,
      type,
      userTypes: this.transformUserTypeIdToUserObj(userTypes),
    };

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_LICENSE_CREATE, data)
      .then((resp) => {
        // const { file } = this.state;
        // const fileToSend = file[0];
        // Axios.put(resp.data.pathToUpload, fileToSend, {
        //   headers: {
        //     "Content-Type": fileToSend.type
        //   }
        // }).then(data => {
        this.setState({
          redirectTo: true,
        });

        // });
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ apiError: errorMsg, loading: false });
      });
  };

  handleStatusChange = (event, { name, value }) => {
    this.setState({
      status: value,
    });
  };

  handleUserTypeChange = (event, data) => {
    const { name, value } = data;
    const { validationMsg } = this.state;
    if (name === 'userTypes') {
      this.setState({
        userTypes: value,
        validationMsg: update(validationMsg, { userTypes: {
          $set: null
        } }),
      });
    }
  }

  handleTypeChange = (event) => {
    this.setState({
      type: event.target.value,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const { validationMsg } = this.state;
    this.setState({
      [name]: value,
      validationMsg: update(validationMsg, { [name]: {
        $set: null
      }}),
    });
  };

  onFileChange = (eventData) => {
    if (!eventData) return;

    if (eventData.isUploading) {
      const { file, isUploading } = eventData;

      if (file.name && file.size) {
        this.setState({
          isUploading,
          file: file,
          filename: file.name,
          fileSize: file.size,
          fileType: file.type,
        });
      }
    } else if (typeof eventData.uploadProgress !== "undefined") {
      const { uploadProgress } = eventData;
      const isUploading = uploadProgress !== 100;
      this.setState({ isUploading, uploadProgress });
    } else if (typeof eventData.fileuuid !== "undefined") {
      const { fileuuid } = eventData;
      this.setState({ fileuuid });
    } else if (typeof eventData.errorMsg !== "undefined") {
      const { errorMsg } = eventData;
      this.setState({
        apiError: errorMsg,
        showError: true,
        isUploading: false,
      });
    }
  };

  showpopupWarning = () => {
    let result = true;

    result = validate(this.state, normalValidation);
    console.log('validate', result)
    if (result) {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red",
      });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      });
  }
  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  validateForm = (event) => {
    let result = true;
    result = validate(this.state, normalValidation);
    console.log('validate', result)
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red",
      });
    }
  };

  render() {
    const {
      redirectTo,
      isUploading,
      uploadProgress,
      validationMsg,
      loading,
      apiError,
    } = this.state;
    if (redirectTo) {
      return <Redirect to={"/licenses"} />;
    }
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="header">
            <div className="side-marker" />
            Add New License
            {/*<a
              href="#"
              className="btn-back-main"
              onClick={this.props.createCancellationHandler}
            >
              <FontAwesomeIcon icon={faArrowLeft} title="Back" name="back" />
              &nbsp;Back to listing
            </a>*/}
          </div>
          <div className="empty-height" />
          {apiError && (
            <Message
              error
              header="Document was not created!"
              content={apiError}
            />
          )}
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Form loading={loading}>
                  <InputField
                    label="Name"
                    errorMsg={validationMsg.name}
                    isError={validationMsg.name}
                    name="name"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    isRequired={true}
                  />
                  <InputField
                    label="Version"
                    errorMsg={validationMsg.version}
                    isError={validationMsg.version}
                    name="version"
                    id="version"
                    value={this.state.version}
                    onChange={this.handleChange}
                    isRequired={true}
                  />
                  <br />
                  <div className="row">
                    <div className="col-md-2">
                      <label style={{ fontWeight: "bold" }}>License File</label>
                    </div>
                    <div className="col-md-10">
                      <a href="#" onClick={(e) => this.download(e)}>
                        {this.state.filename}
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <label style={{ fontWeight: "bold" }}>File Size</label>
                    </div>
                    <div className="col-md-10">
                      <span type="text">
                        {this.state.fileSize} &nbsp; bytes
                      </span>
                    </div>
                  </div>
                  <br />
                  <FileField
                    errorMsg="Please choose a valid file (Plain Text or PDF only)"
                    isError={validationMsg.filename}
                    label={
                      <span>
                        <span>Upload License File</span>&nbsp;
                        <i>(Plain Text or PDF only)</i>
                      </span>
                    }
                    name="filename"
                    id="filename"
                    onChange={Uploader(this.onFileChange)}
                    width="8"
                    isRequired
                    multipleSelection={false}
                  />
                  {isUploading && (
                    <Progress percent={uploadProgress} indicating />
                  )}
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={4} computer={4}>
                        <label style={{ fontWeight: "bold" }}>
                          Publish Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <DatePickerWithTimezone
                          id="publishStartDate"
                          name="publishStartDate"
                          timezone="America/Los_Angeles"
                          selected={this.state.publishStartDate}
                          onChange={this.handleDateChange}
                          minDate={new Date()}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={4} computer={6}>
                        <DropdownField
                          options={this.state.statusOptions}
                          value={this.state.status}
                          label="Status"
                          name="status"
                          onChange={this.handleStatusChange}
                          isRequired={true}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={4} computer={5}>
                        <UserTypeField
                          value={this.state.userTypes}
                          label="User Type"
                          name="userTypes"
                          errorMsg={validationMsg.userTypes}
                          isError={validationMsg.userTypes}
                          onChange={this.handleUserTypeChange}
                          multiple
                          licenseUsers={true}
                          isRequired={true}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <button className="button-primary" onClick={this.showpopupWarning}>
                  {" "}
                  Save{" "}
                </button>
                <Link to="/admin/licenses">
                  <button
                    className="button-basic"
                  // onClick={this.props.createCancellationHandler}
                  >
                    {" "}
                    Cancel{" "}
                  </button>
                </Link>
                &nbsp;&nbsp;
                <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                  {this.state.msgSpanText}
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Confirm
            open={this.state.popupWarning}
            onCancel={this.hidepopupWarning}
            onConfirm={() => this.hidepopupWarning(this.validateForm)}
            content={"Are you sure you want to add new license?"}
          />
        </Segment>
      </React.Fragment>
    );
  }
}

export default Licenses;
