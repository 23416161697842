import update from "immutability-helper";
import { get, isMatch, _ } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
  Checkbox,
  Dimmer,
  Icon,
  Loader,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import { Popup } from "semantic-ui-react";

class Tableview extends React.Component {
  Constants = {
    EMPTY_MSG: "No Data!",
    SINGLE_SELECT: "single-select",
    MULTI_SELECT: "multi-select",
    ASCENDING_ORDER: "ascending",
    DESCENDING_ORDER: "descending",
    CUSTOM_SELECT: "custom-select",
  };

  constructor(props) {
    super(props);
    this.state = {
      checkboxes: [],
      checkAllBox: false,
      columnsSort: {},
      currentPage: 0,
    };
  }

  componentDidMount() {
    this.initializeCheckboxes();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.shouldUnselectAll(prevProps);
    this.handleEmptyPage();
  }

  shouldUnselectAll = (prevProps) => {
    const { data } = this.props;
    let nothingChanged = isMatch(prevProps.data, data);
    if (!nothingChanged) {
      // if table data has updated, unselect everything.
      this.markAllSelection(false, this.notifySelectionChange);
    }
  };

  handleEmptyPage = () => {
    const { totalPages, number } = this.props.data;
    // changing page index to page number.
    let curPage = number + 1;
    // sometimes server send 0 total pages.
    // but for our logic total page should at least 1.
    let minTotalPages = totalPages > 0 ? totalPages : 1;
    if (curPage > minTotalPages) {
      this.onPageChange(null, { activePage: minTotalPages });
    }
  };

  toggleSelection = (rowIndex, isPresent) => {
    const { checkboxes } = this.state;
    this.setState(
      {
        checkboxes: update(checkboxes, {
          [rowIndex]: { $set: !checkboxes[rowIndex] },
        }),
      },
      () => {
        this.notifySelectionChange(rowIndex, isPresent);
        // console.log("checkboxes[rowIndex]", checkboxes[rowIndex]);
      }
    );
  };

  markAllSelection = (value, cb) => {
    const { content } = this.props.data;
    let contentLenght = (content && content.length) || 0;
    let checks = new Array(contentLenght).fill(value);

    this.setState(
      {
        checkboxes: checks,
        checkAllBox: value,
      },
      () => {
        if (cb) cb();
      }
    );
  };

  toggleAllSelection = () => {
    const { checkAllBox } = this.state;
    const { onAllSelectionChange, data } = this.props;
    this.setState({
      checkAllBox: !checkAllBox,
    }, () => {
      onAllSelectionChange(data.content, checkAllBox);
    });
  };

  selectThisOne = (rowIndex) => {
    this.markAllSelection(false, () => {
      this.toggleSelection(rowIndex);
    });
  };

  activePageToPageIndex = (activePage) => {
    return activePage - 1; // index always starts from 0 and goes to n-1.
  };

  onPageChange = (e, { activePage }) => {
    const { checkboxes } = this.state;
    const { checkedUserData, data } = this.props;

    this.setState({
      checkAllBox: false,
      currentPage: this.activePageToPageIndex(activePage),
    });
    this.markAllSelection(false, this.notifyPageChange);
  };

  notifySelectionChange = (rowIndex, isPresent) => {
    const { data, onSelectionChange } = this.props;
    const { checkboxes } = this.state;
    if (onSelectionChange && onSelectionChange instanceof Function) {
      onSelectionChange({
        data: data.content.filter((v, k) => checkboxes[k]),
        changedContent: data.content[rowIndex],
        changedValue: !isPresent,
      });
    }
  };

  notifyPageChange = (activePage) => {
    const { currentPage } = this.state;
    const { onPaginationChange } = this.props;
    // const onPaginationChange = this.props.onSelectionChange; // ToDo: temproray hack.
    if (onPaginationChange && onPaginationChange instanceof Function) {
      onPaginationChange({
        page: currentPage,
      });
    }
  };

  notifySortingChange = (sortConfig) => {
    const { onSortingChange } = this.props;
    if (onSortingChange && onSortingChange instanceof Function) {
      onSortingChange(sortConfig);
    }
  };

  initializeCheckboxes = () => {
    const { content } = this.props.data;
    const { checkboxes } = this.state;

    // console.log("this.isDataProvided()", this.isDataProvided(), content);
    if (this.isDataProvided() && content.length !== checkboxes.length) {
      this.setState({ checkboxes: new Array(content.length).fill(false) });
    }
  };

  initialColumnWidth = (colWidth) => {
    if (colWidth) return;

    const { columns, selectionType } = this.props;
    const { SINGLE_SELECT, MULTI_SELECT } = this.Constants;
    let totalCount = columns.length;
    let maxWidth = 100.0;
    if (selectionType === SINGLE_SELECT || selectionType === MULTI_SELECT)
      maxWidth = maxWidth - 6.25; // its the width of "Checkbox column".
    return `${maxWidth / totalCount}%`;
  };

  onColumnSort = (colName) => {
    const { columnsSort } = this.state;
    const { ASCENDING_ORDER, DESCENDING_ORDER } = this.Constants;
    if (columnsSort.name === colName && columnsSort.value === ASCENDING_ORDER)
      this.setState(
        {
          columnsSort: update(columnsSort, {
            value: { $set: DESCENDING_ORDER },
          }),
        },
        () => {
          this.notifySortingChange({ colName: columnsSort[colName] });
        }
      );
    else if (
      columnsSort.name === colName &&
      columnsSort.value === DESCENDING_ORDER
    )
      this.setState(
        {
          columnsSort: update(columnsSort, {
            value: { $set: ASCENDING_ORDER },
          }),
        },
        () => {
          this.notifySortingChange({ colName: columnsSort[colName] });
        }
      );
    else
      this.setState(
        {
          columnsSort: update(columnsSort, {
            value: { $set: ASCENDING_ORDER },
            name: { $set: colName },
          }),
        },
        () => {
          this.notifySortingChange({ colName: columnsSort[colName] });
        }
      );
  };

  isDataProvided = () => {
    const { data } = this.props;
    return data && data.content && data.content.length > 0;
  };

  isColumnsProvided = () => {
    const { columns } = this.props;
    return columns && columns.length > 0;
  };

  render() {
    const {
      columns,
      data,
      loading,
      noDataMessage,
      paginate,
      selectionType,
      row_extras_prepend,
      row_extras_field,
      checkedIds,
      packageSelection,
      briefcaseSelection,
    } = this.props;
    const { checkAllBox, columnsSort } = this.state;
    let { checkboxes } = this.state;
    const {
      EMPTY_MSG,
      SINGLE_SELECT,
      MULTI_SELECT,
      ASCENDING_ORDER,
      DESCENDING_ORDER,
      CUSTOM_SELECT,
    } = this.Constants;

    return (
      <React.Fragment>
        <Table
          size="small"
          striped
          fixed
          unstackable
          selectable
          verticalAlign="top"
        >
          {this.isColumnsProvided() && (
            <Table.Header className="top">
              <Table.Row>
              {selectionType === CUSTOM_SELECT &&
                  <Table.HeaderCell width="1">
                  </Table.HeaderCell>                   
                }
                {selectionType === MULTI_SELECT && (
                  <Table.HeaderCell width="1">
                    <Checkbox
                      checked={checkAllBox}
                      onClick={this.toggleAllSelection}
                    />
                  </Table.HeaderCell>
                )}
                {selectionType === SINGLE_SELECT && (
                  <Table.HeaderCell width="1"></Table.HeaderCell>
                )}
                {columns.map((col) => (
                  <Table.HeaderCell
                    key={col.name}
                    width={col.width}
                    style={{ width: this.initialColumnWidth(col.width) }}
                    className={col.cb ?`${col.class} pointer-table`:`${col.class}`}
                    onClick={() => {
                      col.cb && col.cb();
                    }}
                  >
                    {/* {col.sort && (
                      <button
                        key={`${col.name}--btn`}
                        className="btn btn-link p-0"
                        onClick={() => {
                          this.onColumnSort(col.name);
                        }}
                      >
                        {col.name}
                        {columnsSort.name === col.name &&
                          columnsSort.value === ASCENDING_ORDER && (
                            <Icon key={`${col.name}--up`} name="angle up" />
                          )}
                        {columnsSort.name === col.name &&
                          columnsSort.value === DESCENDING_ORDER && (
                            <Icon key={`${col.name}--down`} name="angle down" />
                          )}
                      </button>
                    )} */}
                    
                    {!col.sort && col.name}
                      {!col.isActive && col.cb && <Icon name="sort" disabled="true" />}

                    {col.isActive && (                      <>
                        {" "}                                              
                         {col.isDesc ? <Icon  name="sort ascending" /> : <Icon  name="sort descending" /> }
                      </>
                    )}

                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {this.isDataProvided() &&
              data.content.map((row, rowIndex) => (
                <React.Fragment>
                  <Table.Row key={`row-${rowIndex}`}>
                  {selectionType === CUSTOM_SELECT && (
                      <Table.Cell
                        verticalAlign="middle"
                        key={`row-${rowIndex}--checkbox-cell`}
                      >
                         {row.showCheckboxes===true &&
                        <Checkbox
                          key={`row-${rowIndex}--checkbox`}
                          checked={
                            (checkedIds && checkedIds.includes(row.id)) 
                            //||
                            //checkboxes[rowIndex]
                          }
                          onChange={() => {
                            this.toggleSelection(
                              rowIndex,
                              checkedIds.includes(row.id)
                            );
                          }}
                        />}
                      </Table.Cell>
                    )}
                    {selectionType === MULTI_SELECT && (
                      <Table.Cell
                        verticalAlign="middle"
                        key={`row-${rowIndex}--checkbox-cell`}
                      >
                        <Checkbox
                          key={`row-${rowIndex}--checkbox`}
                          checked={
                            packageSelection===true?(checkedIds && checkedIds.includes(row.packageId)):
                            briefcaseSelection===true?(checkedIds && checkedIds.includes(row.id+"_"+row.recipients[0].userId)):
                            (checkedIds && checkedIds.includes(row.id)) 
                            //||
                            //checkboxes[rowIndex]
                          }
                          onChange={() => {
                            this.toggleSelection(
                              rowIndex,
                              packageSelection===true?(checkedIds && checkedIds.includes(row.packageId)):
                              briefcaseSelection===true?checkedIds.includes(row.id+"_"+row.recipients[0].userId):
                              checkedIds.includes(row.id)
                            );
                          }}
                        />
                      </Table.Cell>
                    )}
                    {selectionType === SINGLE_SELECT && (
                      <Table.Cell
                        verticalAlign="middle"
                        key={`row-${rowIndex}--radio-cell`}
                      >
                        <Checkbox
                          key={`row-${rowIndex}--radio`}
                          radio
                          checked={checkboxes[rowIndex]}
                          onChange={() => {
                            this.selectThisOne(rowIndex);
                          }}
                        />
                      </Table.Cell>
                    )}
                    {columns.map((col, colIndex) => (
                      <Table.Cell
                        key={`row-${rowIndex}-col-${colIndex}`}
                        verticalAlign="middle"
                        textAlign={col.class}
                      >
                        {col.showTooltip ? (
                          <Popup 
                          // pinned="true"
                            className="grid-tooltip"
                            position="bottom left"
                            content={get(row, col.key.split("."))}
                            trigger={
                              <span>{get(row, col.key.split("."))}</span>
                            }
                          />
                        ) : (
                          get(row, col.key.split("."))
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                  {/* TODO: Temporary hack. Need to discuss with UX guy to find a proper solution*/}
                  {row_extras_field && (
                    <Table.Row>
                      <Table.Cell
                        colSpan="100%"
                        className="light-background-shade"
                      >
                        {row_extras_prepend && row_extras_prepend}{" "}
                        {get(row, row_extras_field)}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </React.Fragment>
              ))}
            {!loading && !this.isDataProvided() && (
              <Table.Row>
                <Table.Cell colSpan="100%" className="light-background-shade">
                  {noDataMessage || EMPTY_MSG}
                </Table.Cell>
              </Table.Row>
            )}
            {loading && !this.isDataProvided() && (
              <Table.Row>
                <Table.Cell colSpan="100%">
                  <Segment>
                    <Dimmer active inverted>
                      <Loader inverted>Loading</Loader>
                    </Dimmer>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          {paginate && this.isDataProvided() && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="100%">
                  <Pagination
                    activePage={data.number + 1}
                    onPageChange={this.onPageChange}
                    totalPages={data.totalPages ? data.totalPages : 1}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </React.Fragment>
    );
  }
}

Tableview.propTypes = {
  /** Names of the columns */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      sort: PropTypes.bool,
      width: PropTypes.string,
      class: PropTypes.string,
    })
  ).isRequired,
  /** The data which will be rendered in the grid view. this `data` object must contain a `content` key. */
  data: PropTypes.shape({
    content: PropTypes.array,
    number: PropTypes.number,
    totalPages: PropTypes.number,
  }).isRequired,
  /** ToDo: not implemented yet. Its for future scope */
  loading: PropTypes.bool,
  /** Message will be displayed in case of no data to display. */
  noDataMessage: PropTypes.string,
  /** Determines if the pagination UI should be displayed. */
  paginate: PropTypes.bool,
  /** Single-select = Radial selection, multi-select = checkboxes, null = no selection option */
  selectionType: PropTypes.oneOf(["single-select", "multi-select", null]),
  /** The callback which will be fired whenever page is changed. */
  onPaginationChange: PropTypes.func,
  /** The callback which will be fired whenever an item is selected/deselected. */
  onSelectionChange: PropTypes.func,
  /** The callback which will be fired whenever columns are re-sorted. */
  onSortingChange: PropTypes.func,
};

Tableview.defaultProps = {
  loading: false,
};

export default Tableview;