import Api from "@/api";
import {
  configSelector,
  restrictedEmailDomainsSelector,
} from "@/common/selectors";
import { UserInfoForm } from "@/common/user";
import { removeEmptyKeys } from "@/common/utils/utils";
import { adminExtendedValidationConfig,normalValidationConfigForSelfRegister } from "@/common/validations/userValidation";
import Constants from "@/config/constants";
import Axios from "axios";
import update from "immutability-helper";
import { get, isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dimmer, Loader, Segment, Confirm } from "semantic-ui-react";
import { validate } from "validate.js";
import  postalCodes  from "postal-codes-js";

class UsersAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formDisabled: false,
      validationMsg: {},
      // any default value for form field
      form: {
        updateNotificationFrequency: "0",
        countryCode: "US",
        status: "VERIFICATION_PENDING",
        userType: 1,
        userGroup: "",
        password: "",
        code:"+1",
        techSupport: 0
      },
      usernameError: null,
      emailError: null,
      msg: "",
      phonelocal: false,
      phoneform: true,
      codeform: true,
      code: "+1",
      localcountry:"US",
      localpostal:"",
      localresult:"",
      popupWarning:false,
      local:false,
    };
  }
  isNumeric(value) {
    return /^\d+$/.test(value);
  }
  onEmailChange = (event, data) => {
    const { form } = this.state;
    this.setState({
      form: update(form, { [data.name]: { $set: data.value } }),
    });
  };
  checkPostalCode=(e)=>{
    var res = postalCodes.validate(this.state.localcountry,this.state.localpostal );
    if(this.state.localpostal=="") {res=true;}
    this.state.localresult=res;
      const { validationMsg } = this.state;
      let value = res ? { postalCode: res } : [];
      value.localresult = this.state.localresult;
      let obj = {
        postalCode: normalValidationConfigForSelfRegister["postalCode"],
      };
      const singleFieldValidation = validate(value, obj);
      if (isEmpty(singleFieldValidation)) {
        this.setState({
          validationMsg: update(validationMsg, {
            postalCode: { $set: null },
          }),
        });
      } else {
        this.setState({
          validationMsg: update(validationMsg, {
            postalCode: { $set: singleFieldValidation["postalCode"] },
          }),
        });
    }
  }
  onDataChange = (event, data) => {
    const { form } = this.state;
    if(data.name=="countryCode")
    {
      this.state.localcountry=data.value;
      this.setState({
        form: update(form, { [data.name]: { $set: data.value } }),
      });
      this.checkPostalCode(event);
    }
    else if(data.name=="postalCode")
    {
      this.state.localpostal=data.value;
      this.setState({
        form: update(form, { [data.name]: { $set: data.value } }),
      });
     this.checkPostalCode(event);
    }
    else{
    this.setState({
      form: update(form, { [data.name]: { $set: data.value } }),
    });
  }
  };

  onPhoneChange = (event, data) => {
    let cond=event.target.value;
    const { form } = this.state;
    var tempphone="";
    for (var i = 0; i < cond.length; i++) {
      if(this.isNumeric(cond.charAt(i))) {  
        tempphone=tempphone+cond.charAt(i);
      }
    } if(tempphone.length<3){
      this.setState({ form: update(form, { phone: { $set: tempphone } }) });
    } else if(tempphone.length==3 && cond.charAt(cond.length-1)==")" && cond.length==5){
      this.setState({ form: update(form, { phone: { $set: tempphone.substring(0,2) } }) });
    } else if(tempphone.length==3){
      this.setState({ form: update(form, { phone: { $set:"("+tempphone.substring(0,3)+") " } }) });
    } else if(tempphone.length>3 && tempphone.length <6){
      this.setState({ form: update(form, { phone: { $set:"("+tempphone.substring(0,3)+") "+tempphone.substring(3) } }) });
    } else if(tempphone.length==6 && this.state.local==false){
      this.setState({ form: update(form, { phone: { $set:"("+tempphone.substring(0,3)+") "+tempphone.substring(3,6)+" " } }) });
      this.setState({local:true})
    } else if(tempphone.length==6 && this.state.local==true){
      this.setState({ form: update(form, { phone: { $set:"("+tempphone.substring(0,3)+") "+tempphone.substring(3,6) } }) });
      this.setState({local:false})
    } else if(tempphone.length>=6 && tempphone.length <12){
      this.setState({ form: update(form, { phone: { $set:"("+tempphone.substring(0,3)+") "+tempphone.substring(3,6)+" "+tempphone.substring(6) } }) });  
    } else if(tempphone.length>=12){
      this.setState({ form: update(form, { phone: { $set:"("+tempphone.substring(0,3)+") "+tempphone.substring(3,6)+" "+tempphone.substring(6,11) } }) });  
    }
  };
  onByteChange = (event, eventData) => {
    const { form } = this.state;
    let value = eventData.value ? 1 : null;

    this.setState({
      form: update(form, { [eventData.name]: { $set: value } }),
    });
  };
  onKeyup = (e) => {
    if (e.target.name === "username") {
      this.checkUsernameAvailability();
    }
    if (e.target.name === "email") {
      this.checkEmailAvailability();
    }
  };
  onBlur = (e) => {
    const { validationMsg } = this.state;
    const { form } = this.state;
    const { restrictedEmailDomains } = this.props;
    let value = e.target.value ? { [e.target.name]: e.target.value } : [];
    if (e.target.name === "email") {
      value.restrictedDomains =
        restrictedEmailDomains && restrictedEmailDomains
          ? restrictedEmailDomains
          : "";
    }
    if (e.target.name === "phone" || e.target.name === "cellPhone") {
      value.countryCode = form.countryCode;
    }

    if (e.target.name === "confirmPassword") {
      value.password = form.password && form.password ? form.password : null;
    }
    if (e.target.name === "postalCode" ) {
      return;
    }

    let obj = { [e.target.name]: adminExtendedValidationConfig[e.target.name] };
    const singleFieldValidation = validate(value, obj);
    if (isEmpty(singleFieldValidation)) {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: singleFieldValidation[e.target.name] },
        }),
      });
    }
  };

  onPasswordChange = (passwordState, feedback) => {
    const { form, validationMsg } = this.state;
    if (passwordState.password) {
      this.setState({
        form: update(form, {
          password: { $set: passwordState.password },
          passwordScore: { $set: get(feedback, ["score"]) },
        }),
        validationMsg: update(validationMsg, {
          password: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          password: { $set: "Password can't be blank" },
        }),
        form: update(form, {
          password: { $set: passwordState.password },
          passwordScore: { $set: get(feedback, ["score"]) },
        }),
      });
    }
  };

  checkUsernameAvailability = () => {
    const { username } = this.state.form;
    let params = {
      params: {
        username,
      },
    };
    Axios.get(Constants.App.URL_CHECK_USERNAME, params)
      .then((resp) => {
        this.setState({
          formDisabled: false,
          validationMsg: { username: null },
          msg: null,
          apiError: null,
          usernameError: null,
        });
      })
      .catch(() => {
        this.setState({
          formDisabled: false,
          validationMsg: {
            username: "User name already exists. Try other username",
          },
          // msg: "User name already exists",
          msgSpanColor: "red",
          usernameError: "User name already exists. Try other username",
        });
      });
  };

  checkEmailAvailability = () => {
    const { email } = this.state.form;
    let params = {
      params: {
        email,
      },
    };
    Axios.get(Constants.App.URL_CHECK_EMAIL, params)
      .then((resp) => {
        // this.submitRegistration();
        this.setState({
          formDisabled: false,
          emailError: null,
          msg: null,
          apiError: null,
        });
      })
      .catch(() => {
        this.setState({
          formDisabled: false,
          emailError: "Email address already exists",
          // msg: "Email address already exists",
          msgSpanColor: "red",
        });
      });
  };

  submitRegistration = () => {
    let { form, validationMsg, msg, color } = this.state;
    form.phone = form.code + form.phone;
    if (!isEmpty(validationMsg)) return;
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_USER_SAVE, form)
      .then((resp) => {
        if (resp.data.id > 0) {
          msg = "New User Added";
          color = "green";
        } else {
          msg = "Request failed, Please try later.";
          color = "red";
        }

        this.setState({
          msgSpanText: msg,
          msg: msg,
          msgSpanColor: color,
          loading: false,
          formDisabled: false,
          apiError: null,
        });
        window.location.reload(); 
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            msg: error.response.data.message,
            msgSpanColor: "red",
            loading: false,
            apiError: error.response.data.message,
            formDisabled: false,
          });
        }
      });
  };

  showpopupWarning = () => {
    this.setState(
      {
        popupWarning: true,
      },);
  }
  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
        formDisabled: true, validationMsg: {}
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  validateForm = (event) => {
    const { form } = this.state;
    form.localresult=this.state.localresult;
    const { restrictedEmailDomains } = this.props;

    let cleanedForm = removeEmptyKeys(form);
    cleanedForm.restrictedDomains = restrictedEmailDomains;
    const staticValidation = validate(
      cleanedForm,
      adminExtendedValidationConfig
    );
    if (isEmpty(staticValidation)) {
        this.showpopupWarning();
    } else {
      this.setState({
        validationMsg: staticValidation,
        msg: "Validation failed, please fix the errors before submitting",
        msgSpanColor: "red",
      });
    }
  };
  render() {
    const {
      apiError,
      form,
      formDisabled,
      validationMsg,
      usernameError,
      emailError,
      msg,
      msgSpanColor,
    } = this.state;
    const { config } = this.props;

    if (config.success)
      return (
        <Segment basic>
          <Segment className="base-segment-wrapper">
            <div className="side-marker" />
            <div className="header">Add New User</div>
            <div className="empty-height-small" />

            {/*UserInfo Form Reusable Component*/}

            <UserInfoForm
              validateForm={this.validateForm}
              apiError={apiError}
              formDisabled={formDisabled}
              form={form}
              validationMsg={validationMsg}
              onDataChange={this.onDataChange}
              onPhoneChange={this.onPhoneChange}
              onBlur={this.onBlur}
              onKeyup={this.onKeyup}
              usernameError={usernameError}
              emailError={emailError}
              config={config}
              backLink={"/admin/users-listing"}
              msg={msg}
              onPasswordChange={this.onPasswordChange}
              formType="AdminRegisteration"
              errorTitle="User Registration Failed"
              msgSpanColor={msgSpanColor}
              onByteChange={this.onByteChange}
            />
          </Segment>
          <Confirm
             open={this.state.popupWarning}
             onCancel={this.hidepopupWarning}
             onConfirm={() => this.hidepopupWarning(this.submitRegistration)}
             content="Are you sure you want to add new user?"
          />
        </Segment>
      );
    else
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
  }
}

const mapStateToProps = (state) => ({
  config: configSelector(state),
  restrictedEmailDomains: restrictedEmailDomainsSelector(state),
});

export default UsersAdd = connect(mapStateToProps)(UsersAdd);
