import Api from "@/api";
import { CardviewMobile } from "@/common/cardview";
import Tableview from "@/common/tableview";
import { UserListing } from "@/common/user-selection";
import urls, { default as Config } from "@/config/constants";
import React from "react";
import { UserTypeField } from "@/common/Form";
import { Link, withRouter } from "react-router-dom";
import {
  Form,
  Confirm,
  Dropdown,
  Grid,
  Responsive,
  Segment,
  Modal,
  Message,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { authoritySelector } from "@/common/selectors";
import { dateToPacific } from "@/utils/dateTime";
import { connect } from "react-redux";
import {
  configSelector,
  restrictedEmailDomainsSelector,
} from "@/common/selectors";
import { adminExtendedValidationConfig } from "@/common/validations/userValidation";
import Constants from "@/config/constants";
import Axios from "axios";
import update from "immutability-helper";
import { validate } from "validate.js";
import { InviteUser } from "../../../../../../common/user";
import { inviteUsersApi } from "../../../../../../api/admin";
import actions from "../../../../../../store/actions";
import { isEmpty } from "lodash";

const EDIT_APPROVERS = Config.AccessRole.EDIT_APPROVERS;

class Users extends React.Component {
  Constants = {
    columns: [
      {
        name: "Username",
        key: "username",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("username", "Username");
        },
      },
      {
        name: "First Name",
        key: "firstName",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("firstName", "First Name");
        },
      },
      {
        name: "Last Name",
        key: "lastName",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("lastName", "Last Name");
        },
      },
      {
        name: "Company",
        key: "company",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("company", "Company");
        },
      },
      {
        name: "Group",
        key: "group",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("groups.name", "Group");
        },
      },
      {
        name: "Status",
        key: "status",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("status", "Status");
        },
      },
      {
        name: "Created On",
        key: "creationDate",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("creationDate", "Created On");
        },
      },
      {
        name: "Registered By",
        key: "createdBy",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("createdBy", "Registered By");
        },
      },
      {
        name: "Updated By",
        key: "modifiedBy",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("modifiedBy", "Updated By");
        },
      },
      {
        name: "Last Updated",
        key: "modificationDate",
        width: "2",
        showTooltip: true,
        cb: () => {
          this.sortByHeader("modificationDate", "Last Updated");
        },
      },
      {
        name: "Actions",
        key: "actions",
        width: "2",
      },
    ],
    responsive_columns: [
      {
        name: "Username",
        key: "username",
        width: "2",
      },
      {
        name: "First Name",
        key: "firstName",
        width: "2",
      },
      {
        name: "Last Name",
        key: "lastName",
        width: "2",
      },
      {
        name: "Company",
        key: "company",
        width: "3",
      },
      {
        name: "Group",
        key: "group",
        width: "2",
      },
      {
        name: "Status",
        key: "status",
        width: "2",
      },
      {
        name: "Created On",
        key: "creationDate",
        width: "3",
      },
    ],
    fieldOptions: Object.freeze([
      {
        key: "username",
        text: "Username",
        value: "username",
      },
      {
        key: "first_name",
        text: "First Name",
        value: "firstName",
      },
      {
        key: "last_name",
        text: "Last Name",
        value: "lastName",
      },
      {
        key: "company",
        text: "Company",
        value: "company",
      },
      {
        key: "email",
        text: "Email Address",
        value: "email",
      },
      {
        key: "city",
        text: "City",
        value: "city",
      },
      {
        key: "country_name",
        text: "Country",
        value: "countryName",
      },
    ]),
    userTypeOptions: Object.freeze([
      {
        key: "all",
        text: "All",
        value: 0,
      },
      {
        key: "customer",
        text: "Customer",
        value: 1,
      },
      {
        key: "employee",
        text: "Employee",
        value: 2,
      },
      {
        key: "system_admin",
        text: "System Admin",
        value: 4,
      },
      {
        key: "account_approver",
        text: "Account Approver",
        value: 5,
      },
      {
        key: "administrator",
        text: "Administrator",
        value: 6,
      },
      {
        key: "access_manager",
        text: "Access Manager",
        value: 7,
      },
      {
        key: "customer_representative",
        text: "Customer Representative",
        value: 8,
      },
      {
        key: "non_nda_customer",
        text: "Non Nda Customer",
        value: 331,
      },
      {
        key: "public_non_nda_customer",
        text: "Public Non Nda Customer",
        value: 332,
      },
    ]),
    approvalStatusOptions: Object.freeze([
      {
        key: "all",
        text: "All",
        value: "All",
      },
      {
        key: "active",
        text: "Active",
        value: "ACTIVE",
      },
      {
        key: "InActive",
        text: "InActive",
        value: "INACTIVE",
      },
      {
        key: "Deleted",
        text: "Deleted",
        value: "DELETED",
      },
      {
        key: "Disabled",
        text: "Disabled",
        value: "DISABLED",
      },
      {
        key: "Pending",
        text: "Pending",
        value: "PENDING",
      },
      {
        key: "tc_pending",
        text: "TC Pending",
        value: "TC_PENDING",
      },
      {
        key: "verification_pending",
        text: "Verification Pending",
        value: "VERIFICATION_PENDING",
      },
      {
        key: "Rejected",
        text: "Rejected",
        value: "REJECTED",
      },
      {
        key: "tc_rejected",
        text: "TC Rejected",
        value: "TC_REJECTED",
      },
      {
        key: "Locked",
        text: "Locked",
        value: "LOCKED",
      },
    ]),
  };

  constructor(props) {
    super(props);

    this.state = {
      createMode: false,
      editMode: false,
      editId: null,
      showUsersPopup: false,
      showPendingUsersPopup: false,
      pageNum: 0,
      msgSpanText: "",
      msgSpanColor: "black",
      listings: {
        content: [],
        number: 0,
      },
      itemLoadXHR: false,
      keyword: "",
      field: "",
      usertype: 0,
      approvalstatus: "",
      userId: null,
      deleteWarning: false,
      inviteModal: false,
      username: "",
      email: "",
      messageModal: {
        open: false,
        message: "",
      },
      isDescSearch: true,
      sortBy: "creationDate,DESC",
      popupWarning: false,
      resendInviteWarning: false,
      users: {},
      formDisabled: false,
      validationMsg: {},
      // any default value for form field
      usernameError: null,
      emailError: null,
      msg: "",
      inviteUserList: [{ firstName0: "", lastName0: "", email0: "" }],
      activeIndex: 0,
      formError: false,
      duplicateEmail: false,
    };
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyup = this.onKeyUp.bind(this);
  }

  onDataChange = (event, data) => {
    this.setState({ [data.name]: event.target.value });
  };

  onKeyUpUserInvite = (e, i) => {
    if (e.target.name === `username${i}`) {
      this.checkUsernameAvailability(i, e.target.value);
    }
    if (e.target.name === `email${i}`) {
      this.checkEmailAvailability(i, e.target.value);
    }
  };

  onBlur = (e) => {
    const { validationMsg } = this.state;
    const { restrictedEmailDomains } = this.props;
    let value = e.target.value ? { [e.target.name]: e.target.value } : [];
    if (e.target.name === "email") {
      value.restrictedDomains =
        restrictedEmailDomains && restrictedEmailDomains
          ? restrictedEmailDomains
          : "";
    }
    let obj = { [e.target.name]: adminExtendedValidationConfig[e.target.name] };
    const singleFieldValidation = validate(value, obj);
    if (isEmpty(singleFieldValidation)) {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: null },
        }),
      });
    } else {
      this.setState({
        validationMsg: update(validationMsg, {
          [e.target.name]: { $set: singleFieldValidation[e.target.name] },
        }),
      });
    }
  };

  checkUsernameAvailability = (i, username) => {
    let params = {
      params: {
        username: username,
      },
    };
    Axios.get(Constants.App.URL_CHECK_USERNAME, params)
      .then((resp) => {
        this.setState({
          formDisabled: false,
          validationMsg: {
            ...this.state.validationMsg,
            [`username${i}`]: null,
          },
          msg: null,
          apiError: null,
          usernameError: null,
        });
      })
      .catch(() => {
        this.setState({
          formDisabled: false,
          validationMsg: {
            ...this.state.validationMsg,
            [`username${i}`]: "User name already exists. Try other username",
          },
          msgSpanColor: "red",
          usernameError: "User name already exists. Try other username",
        });
      });
  };

  checkEmailAvailability = (i, email) => {
    const {restrictedEmailDomains} = this.props
    let params = {
      params: {
        email,
      },
    };
    Axios.get(Constants.App.URL_CHECK_EMAIL, params)
      .then((resp) => {
        // this.submitRegistration();
        this.setState({
          formDisabled: false,
          emailError: null,
          msg: null,
          apiError: null,
          validationMsg: {
            ...this.state.validationMsg,
            [`email${i}`]: null,
          },
        });
       let restrictedDomains = restrictedEmailDomains.replace(/\s+/g, '').split(",")
       let providedEmailDomain = email.split("@").pop()
       if(restrictedDomains.includes(providedEmailDomain))
          throw "It is a restricted domain";
      })
      .catch((err) => {
        if(err !== "It is a restricted domain")
          err = "Email address already exists"
        this.setState({
          formDisabled: false,
          emailError: "Email address already exists",
          msgSpanColor: "red",
          validationMsg: {
            ...this.state.validationMsg,
            [`email${i}`]: err ,
          },
        });
      });
  };

  submitRegistration = () => {
    let { form, validationMsg, msg, color } = this.state;
    if (!isEmpty(validationMsg)) return;
    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_USER_SAVE, form)
      .then((resp) => {
        if (resp.data.id > 0) {
          msg = "New User Added";
          color = "green";
        } else {
          msg = "Request failed, Please try later.";
          color = "red";
        }

        this.setState({
          msgSpanText: msg,
          msg: msg,
          msgSpanColor: color,
          loading: false,
          formDisabled: false,
          apiError: null,
        });
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            msg: error.response.data.message,
            msgSpanColor: "red",
            loading: false,
            apiError: error.response.data.message,
            formDisabled: false,
          });
        }
      });
  };

  validateForm = (event) => {
    const { validationMsg } = this.state;
    let validationObj = { ...validationMsg };
    const dto = this.state.inviteUserList.map((d, index) => {
      if (event.target[`firstname${index}`].value == "") {
        validationObj = {
          ...validationObj,
          [`firstname${index}`]: "First name is required",
        };
        this.setState({
          validationMsg: {
            ...this.state.validationMsg,
            [`firstname${index}`]: "First name is required",
          },
        });
      } else if (event.target[`lastname${index}`].value == "") {
        validationObj = {
          ...validationObj,
          [`lastname${index}`]: "Last Name is required",
        };
        this.setState({
          validationMsg: {
            ...this.state.validationMsg,
            [`lastname${index}`]: "Last name is required",
          },
        });
      } else if (event.target[`email${index}`].value == "") {
        this.setState({
          validationMsg: {
            ...this.state.validationMsg,
            [`email${index}`]: "Email is required",
          },
        });
      } else {
        return {
          username: event.target[`email${index}`].value,
          firstName: event.target[`firstname${index}`].value,
          lastName: event.target[`lastname${index}`].value,
          email: event.target[`email${index}`].value,
          company: event.target[`email${index}`].value
            .split("@")[1]
            .split(".")[0],
        };
      }
    });
    let isValid = false;
    for (let prop in validationObj) {
      if (validationObj[prop] !== null) {
        isValid = false;
        break;
      } else {
        isValid = true;
      }
    }
    this.setState(
      {
        formError: !isValid,
      },
      () => {
        if (isValid) {
          // find the duplicate email in the dto array of objects
          let duplicateEmail = dto.filter(
            (item, index) =>
              dto.findIndex((obj) => obj.email === item.email) !== index
          );
          if (duplicateEmail.length > 0) {
            this.setState({
              duplicateEmail: true,
            });
          } else {
            this.props.inviteUserApi(dto);
            if (!this.props.inviteUserModalLoader) {
              this.onModalClose();
              this.getList();
            }
          }
        }
      }
    );
  };

  onSearchTextChange = (event, data) => {
    this.setState({ keyword: event.target.value });
  };
  onKeyUp(event) {
    if (event.charCode === 13) {
      this.setState({ inputValue: event.target.value });
      if (
        this.state.field == "" &&
        this.state.usertype == 0 &&
        this.state.approvalstatus == ""
      ) {
        this.setState({ field: "username" });
      }
      this.getList();
    }
  }
  componentDidMount() {
    this.getList();
  }
  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.columns = this.Constants.columns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getList();
    });
  };

  getList = (page) => {
    const {location} = this.props
    let { keyword, field, usertype, approvalstatus, pageNum } = this.state;
    if(!keyword && location.query)
     { keyword= location.query
      this.setState({keyword:location.query})
    }
    if(page != null){
      pageNum = page;
    }
    else if(location.pageNum)
    {
      pageNum = location.pageNum
    }
    let params = {
      params: {
        page: pageNum,
        keyword,
        field,
        usertype,
        approvalstatus,
        sort: this.state.sortBy,
      },
    };
     location.pageNum=0
    return Api.AxiosInstance.paginate(
      urls.App.URL_ADMIN_USER_LIST,
      params
    ).then((resp) => {
      this.setState({
        listings: this.transformData(resp.data),
      });
    });
  };

  transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        let adminActions = (
          <React.Fragment>
            {/* New Link for Edit User....*/}
            <Link to={{pathname:"/admin/user/edit/" + row.id,query:this.state.keyword,pageNum:this.state.pageNum}}>
              <span
                className="icon-edit"
                style={{
                  margin: "3px",
                }}
                title="Edit"
                name="edit"
              />
            </Link>
            <span
              className="icon-delete"
              onClick={(e) => this.showDeleteWarningAndSetUserId(e, row.id)}
              style={{
                margin: "3px",
              }}
              title="Remove"
              name="remove"
            />
          </React.Fragment>
        );

        row.actions =
          this.props.authority == "websilo-sysadmin" ? (
            adminActions
          ) : this.props.authority == "websilo-admin" ? (
            row.userType == "4" ? (
              <span>No Privileges</span>
            ) : (
              adminActions
            )
          ) : this.props.authority == "websilo-account-approver" ? (
            row.userType == "1" ||
            row.userType == "332" ||
            row.userType == "331" ? (
              adminActions
            ) : (
              <span>No Privileges</span>
            )
          ) : (
            <span>No Privileges</span>
          );

        row.creationDate = (
          <>{dateToPacific(el.creationDate, "MM/DD/YYYY hh:mm a")}</>
        );
        row.modificationDate = el.modificationDate ? (
          <>{dateToPacific(el.modificationDate, "MM/DD/YYYY hh:mm a")}</>
        ) : (
          "-"
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  onPageChange = (e) => {
    this.setState({ pageNum: e.page }, this.getList);
  };

  showpopupWarning = (users) => {
    this.setState({
      popupWarning: true,
      users: users,
    });
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  showResendInviteWarning = (users) => {
    this.setState({
      resendInviteWarning: true,
      users: users,
    });
  };

   hideResendInviteWarning = (cb) => {
    this.setState(
      {
        resendInviteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  showDeleteWarningAndSetUserId = (e, id) => {
    this.setState({
      deleteWarning: true,
      userId: id,
    });
  };
  hideDeleteWarning = (cb) => {
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  remove = () => {
    const { userId } = this.state;
    let params = {
      params: {
        id: userId,
      },
    };
    return Api.AxiosInstance.getInstance()
      .get(urls.App.URL_ADMIN_USER_REMOVE, params)
      .then((resp) => {
        this.getList();
      });
  };

  showUsersPopup = (users) => {
    this.setState({ showUsersPopup: true });
  };

  showInvitePopup = () => {
    this.props.openInviteModal();
  };
    showPendingUsersPopup = () => {
    this.setState({ showPendingUsersPopup: true});
  }

  onDismissUserPopup = (users) => {
    this.setState({ showUsersPopup: false });
  };
  onDismissPendingUsersPopup = (users) => {
    this.setState({ showPendingUsersPopup: false});
  }
  closeMessageModal = () => {
    this.setState({
      messageModal: {
        open: false,
        message: "",
      },
    });
  };

  closeInviteModal = () => {
    this.setState(
      {
        validationMsg: {},
        usernameError: null,
        emailError: null,
        inviteUserList: [{ firstName0: "", lastName0: "", email0: "" }],
        formError: false,
      },
      this.props.closeInviteModal()
    );
  };
  userSelectionOnSave = () => {
    const { users } = this.state;
    let userIds = "";
    // will check and refactor this later on..
    for (var i = 0; i < users.length; i++) {
      userIds += users[i].id + ",";
    }
    userIds = userIds.slice(0, -1);
    let params = {
      params: {
        userIds,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(urls.App.URL_ADMIN_USER_FORCE_RESET, params)
      .then((resp) => {
        this.setState({
          messageModal: {
            open: true,
            message: "Password reset requested",
          },
        });
      });

    this.setState({ showUsersPopup: false });
  };

  userResendInviteOnSave = () => {
    const { users } = this.state;
    let userIds = "";
    // will check and refactor this later on..
    for (var i = 0; i < users.length; i++) {
      userIds += users[i].id + ",";
    }
    userIds = userIds.slice(0, -1);
    let params = {
      params: {
        userIds,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(urls.App.URL_ADMIN_USER_RESEND_INVITE, params)
      .then((resp) => {
        this.setState({
          messageModal: {
            open: true,
            message: "Invite Resent",
          },
        });
      });

    this.setState({ showPendingUsersPopup: false });
  };

  onAddMorePeople = () => {
    let people = this.state.inviteUserList;
    people.push({
      [`firstName${people.length - 1 + 1}`]: "",
      [`lastName${people.length - 1 + 1}`]: "",
      [`email${people.length - 1 + 1}`]: "",
    });
    this.setState(people);
  };
  onDeletePeople = (index) => {
    if (this.state.inviteUserList.length) {
      let people = this.state.inviteUserList;
      people.splice(index, 1);
      this.setState({
        ...people,
        validationMsg: {
          ...this.state.validationMsg,
          [`firstName${index}`]: null,
          [`lastName${index}`]: null,
          [`email${index}`]: null,
        },
      });
    }
    return;
  };
  onModalClose = () => {
    this.setState({
      inviteModal: false,
      inviteUserList: [{}],
    });
  };
  handleClickAccordian = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  render() {
    const { apiError, formDisabled, validationMsg } = this.state;
    return (
      <React.Fragment>
        {this.state.listings.content && (
          <Segment className="base-segment-wrapper">
            <Grid stackable>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="header">
                    <div className="side-marker" />
                    Users
                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="control-action-wrapper">
                    {/* Link for Add New....*/}
                    {EDIT_APPROVERS.indexOf(this.props.authority) >= 0 && (
                      <>
                        <input
                          type="button"
                          className="button-basic"
                          value="Resend Invite"
                          onClick={this.showPendingUsersPopup}
                        />{" "}
                        <input
                          type="button"
                          className="button-basic"
                          value="Invite User"
                          onClick={this.showInvitePopup}
                        />{" "}
                        <Link to={"/admin/user/new"}>
                          <button className="button-basic">Add New</button>
                        </Link>
                        <input
                          type="button"
                          className="button-basic"
                          value="Force Password Reset"
                          onClick={this.showUsersPopup}
                        />{" "}
                      </>
                    )}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <div className="empty-height" />

            <Grid>
              <Grid.Row className="common-filter-wrapper">
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={3}
                  largeScreen={3}
                >
                  <div className="ui form">
                    <div className="field">
                      <input
                        type="text"
                        placeholder="Search"
                        className="search-bar search-bar-small"
                        onChange={this.onSearchTextChange}
                        onKeyPress={this.onKeyUp}
                        defaultValue={this.state.keyword}
                      />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={3}
                  largeScreen={3}
                >
                  <div className="ui form">
                    <div className="field">
                      <Dropdown
                        className="dropdown-small"
                        options={this.Constants.fieldOptions}
                        selection
                        placeholder="Field Options"
                        onChange={(data) => {
                          this.setState({ field: data.value });
                        }}
                      />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={3}
                  largeScreen={3}
                >
                  <div className="ui form">
                    <div className="field">
                      <UserTypeField
                        className="dropdown-small"
                        placeholder="User Types"
                        extraOptions={[{
                          key: "all",
                          text: "All",
                          value: 0,
                        },]}
                        value={this.state.usertype}
                        onChange={(event, data) => {
                          this.setState({ usertype: data.value }, this.onPageChange(0));
                        }}
                      />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={3}
                  largeScreen={3}
                >
                  <div className="ui form">
                    <div className="field">
                      <Dropdown
                        className="dropdown-small"
                        options={this.Constants.approvalStatusOptions}
                        selection
                        placeholder="Approval Status"
                        onChange={(event, data) => {
                          this.setState({ approvalstatus: data.value });
                        }}
                      />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={3}
                  largeScreen={3}
                >
                  <div className="ui form">
                    <div className="field">
                      <input
                        type="button"
                        className="button-primary"
                        value="Search"
                        onClick={(e) => this.getList(0)}
                      />
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className="empty-height" />
            <div className="row">
              <div className="col-md-12">
                <span
                  id="msgspan"
                  style={{
                    color: this.state.msgSpanColor,
                  }}
                >
                  {this.state.msgSpanText}
                </span>
                <UserListing
                  show={this.state.showUsersPopup}
                  onDismiss={this.onDismissUserPopup}
                  onSave={this.showpopupWarning}
                />
              </div>
            </div>

            <div className="empty-height" />
            <div className="row">
              <div className="col-md-12">
                <span
                  id="msgspan"
                  style={{
                    color: this.state.msgSpanColor,
                  }}
                >
                  {this.state.msgSpanText}
                </span>
                <UserListing
                  show={this.state.showPendingUsersPopup}
                  onDismiss={this.onDismissPendingUsersPopup}
                  onSave={this.showResendInviteWarning}
                  approvalstatus = {'VERIFICATION_PENDING'}
                />
              </div>
            </div>

            <Responsive minWidth={1024}>
              <div className="table-wrapper-with-pagination">
                <Tableview
                  paginate
                  data={this.state.listings}
                  columns={this.Constants.columns}
                  onPaginationChange={(e) => this.onPageChange(e)}
                  noDataMessage="No Users Found"
                />
              </div>
            </Responsive>

            <Responsive maxWidth={1023}>
              <CardviewMobile
                data={this.state.listings}
                s
                columns={this.Constants.responsive_columns}
                paginate
                onPaginationChange={(e) => this.onPageChange(e)}
              />
            </Responsive>
          </Segment>
        )}

        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.remove)}
          content="Are you sure you want to delete this User?"
        />
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.userSelectionOnSave)}
          content={
            "Are you sure you want to request password reset for selected" +
            (this.state.users.length > 1 ? " users?" : " user?")
          }
        />
        <Confirm
          open={this.state.resendInviteWarning}
          onCancel={this.hideResendInviteWarning}
          onConfirm={() => this.hideResendInviteWarning(this.userResendInviteOnSave)}
          content={
            "Are you sure you want to resend registration link for selected" +
            (this.state.users.length > 1 ? " users?" : " user?")
          }
        />

        <>
          <Modal
            open={this.state.messageModal.open}
            onClose={this.closeMessageModal}
            centered={true}
            size="tiny"
            content={this.state.messageModal.message}
            actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
          ></Modal>
          <Modal
            open={this.props.inviteModal}
            onClose={() => this.closeInviteModal()}
            centered={false}
            size="large"
            loading={true}
            closeIcon
          >
            <Modal.Header>
              <div className="header">
                <div className="side-marker" />
                Invite Users
              </div>
            </Modal.Header>
            <Modal.Content scrolling>
              {this.props.userInivteErrors.isError && (
                <Message
                  error
                  header="Error"
                  content={
                    this.props.userInivteErrors.message ||
                    "Something unexpected has happened."
                  }
                />
              )}
              <Dimmer active={this.props.inviteUserModalLoader}>
                <Loader active />
              </Dimmer>
              <Segment basic className="p-0">
                <Form
                  error={apiError}
                  onSubmit={this.validateForm}
                  loading={formDisabled}
                  autocomplete="off"
                  id="submit-form"
                >
                  {this.state.inviteUserList.map((d, index) => {
                    return (
                      <Segment className="invite-user-wrapper">
                        <Button
                          className="btn-user-delete icon-delete"
                          title="User Delete"
                          type="button"
                          onClick={() => {
                            this.onDeletePeople(index);
                          }}
                        ></Button>

                        <InviteUser
                          validationMsg={validationMsg}
                          onKeyUp={this.onKeyUpUserInvite}
                          onDataChange={this.onDataChange}
                          name={this.state.name}
                          userId={this.userId}
                          email={this.state.email}
                          onDeletePeople={this.onDeletePeople}
                          index={index}
                          currentIndexData={d}
                        />
                      </Segment>
                    );
                  })}
                </Form>
              </Segment>
            </Modal.Content>

            <Modal.Actions>
              {this.state.formError ? (
                <span
                  style={{
                    color: "red",
                    marginLeft: "5px",
                  }}
                >
                  Please fill all the required fields.
                </span>
              ) : (
                ""
              )}
              {this.state.duplicateEmail ? (
                <span style={{ color: "red", marginLeft: "5px" }}>
                  {" "}
                  Duplicate emails are not allowed.{" "}
                </span>
              ) : (
                ""
              )}
              <Button className="button-primary" onClick={this.onAddMorePeople}>
                Add More
              </Button>
              <Button color="black" type="submit" form="submit-form">
                Send
              </Button>
              <Button color="black" onClick={() => this.closeInviteModal()}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
        </>
        <Modal
          open={this.props.inviteSuccessModal}
          onClose={() => this.props.closeInviteSuccessModal()}
          centered={true}
          size="tiny"
          content="Invitation has been sent."
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

// export default withRouter(Users);

const mapDispatchToProps = (dispatch) => {
  return {
    inviteUserApi: (data) => {
      dispatch(inviteUsersApi(data));
    },
    openInviteModal: () => {
      dispatch({
        type: actions.Admin.OPEN_INVITE_MODAL,
      });
    },
    closeInviteModal: () => {
      dispatch({
        type: actions.Admin.CLOSE_INVITE_MODAL,
      });
    },
    closeInviteSuccessModal: () => {
      dispatch({
        type: actions.Admin.CLOSE_INVITE_SUCCESS_MODAL,
      });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    // incase i'll need config values
    // config: configSelector(state),
    // baseUrl: baseUrl,
    config: configSelector(state),
    restrictedEmailDomains: restrictedEmailDomainsSelector(state),
    authority: authoritySelector(state),
    inviteUserModalLoader: state.Admin.loading,
    inviteModal: state.Admin.inviteModal,
    userInivteErrors: state.Admin.errors,
    inviteSuccessModal: state.Admin.inviteSuccessModal,
  };
};

const mappedUsersListing = connect(mapStateToProps, mapDispatchToProps)(Users);
export default withRouter(mappedUsersListing);
