import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import update from "immutability-helper";
import Constants from "../../../config/constants";
import {
  Container,
  Divider,
  Dropdown,
  Modal,
  Segment,
  Grid,
  Confirm,
  Checkbox,
  Responsive,
} from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";
import { parameterStringify } from "@/common/utils/utils";
import Tableview from "@/common/tableview";
import { CardviewMobile } from "@/common/cardview";
import Api from "@api";
const NDA_EDIT_ROUTE = "/nda/update/";

class NDAListing extends React.Component {
  Constants = {
    SEARCH_ON_STATUS: Object.freeze([
      { key: "all-search", text: "All", value: "" },
      { key: "rejected-search", text: "Rejected", value: "REJECTED" },
      { key: "active-search", text: "Active", value: "ACTIVE" },
      { key: "pending-search", text: "Pending", value: "PENDING" },
      { key: "expired-search", text: "Expired", value: "EXPIRED" },
    ]),
    SEARCH_ON_STATUS_PENDING: Object.freeze([
      { key: "all-search", text: "All", value: "" },
      { key: "unsigned-search", text: "unsigned", value: "UNSIGNED" },
      { key: "pending-search", text: "Pending", value: "PENDING" },
    ]),
    SEARCH_ON_TYPE: Object.freeze([
      { key: "all-search", text: "All", value: "all" },
      {
        key: "non-standard-search",
        text: "Non-Standard",
        value: "non-standard",
      },
      { key: "standard-search", text: "Standard", value: "standard" },
    ]),
    SEARCH_ON_GROUP: Object.freeze([
      { key: "no-matter-search", text: "Doesn't Matter", value: "" },
      { key: "yes-search", text: "Yes", value: "YES" },
      { key: "no-search", text: "No", value: "NO" },
    ]),
    TABLE_COLUMNS: Object.freeze([
      { name: "", key: "checkboxes", width: "1" },
      { name: "Company Name", key: "company", width: "4", showTooltip: true, cb: () => {
        this.sortByHeader("company", "Company Name");
      }, },
      // {name:'Assigned Groups', key:'assigned'},
      { name: "Type", key: "ndaType", width: "3", showTooltip: true, },
      { name: "Status", key: "status", width: "2", showTooltip: true, cb: () => {
        this.sortByHeader("status", "Status");
      }, },
      { name: "Updated On", key: "updatedOn", isDate: true, width: "3", showTooltip: true, cb: () => {
        this.sortByHeader("modificationDate", "Updated On");
      }, },
      { name: "Actions", key: "actions", width: "3" },
    ]),
    TABLE_COLUMNS_PENDING: Object.freeze([
      { name: "", key: "checkboxes", width: "1" },
      { name: "Company Name", key: "company", width: "4", showTooltip: true, cb: () => {
        this.sortByHeader("company", "Company Name");
      }, },
      // {name:'Assigned Groups', key:'assigned'},
      { name: "Type", key: "ndaType", width: "2", showTooltip: true, },
      { name: "# of Reminders sent", key: "totalRemindersSent", width: "2", showTooltip: true, cb: () => {
        this.sortByHeader("totalRemindersSent", "# of Reminders sent");
      }, },
      { name: "Last Reminder date", key: "lastReminderSentDate", width: "3", showTooltip: true, cb: () => {
        this.sortByHeader("lastReminderSentDate", "Last Reminder date");
      }, },
      { name: "Status", key: "status", width: "2", showTooltip: true, cb: () => {
        this.sortByHeader("status", "Status");
      }, },
      { name: "Updated On", key: "updatedOn", isDate: true, width: "3", showTooltip: true, cb: () => {
        this.sortByHeader("modificationDate", "Updated On");
      }, },
      { name: "Actions", key: "actions", width: "3" },
    ]),
    RESPONSIVE_COLUMNS: Object.freeze([
      // { name: "", key: "checkboxes", width: "1" },
      { name: "Company Name", key: "company", width: "4" },
      // {name:'Assigned Groups', key:'assigned'},
      { name: "Type", key: "ndaType", width: "3" },
      { name: "Status", key: "status", width: "2" },
      { name: "Updated On", key: "updatedOn", isDate: true, width: "3" },
    ]),
    RESPONSIVE_COLUMNS_PENDING: Object.freeze([
      // { name: "", key: "checkboxes", width: "1" },
      { name: "Company Name", key: "company", width: "4" },
      // {name:'Assigned Groups', key:'assigned'},
      { name: "Type", key: "ndaType", width: "2" },
      { name: "Status", key: "status", width: "2" },
      { name: "# of Reminders sent", key: "totalRemindersSent", width: "2" },
      { name: "Last Reminder date", key: "lastReminderSentDate", width: "3" },
      { name: "Status", key: "status", width: "2" },
      { name: "Updated On", key: "updatedOn", isDate: true, width: "3" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      data: { content: [], number: 0 },
      errorMsg: "",
      selectedItems: [],
      selectedCheckboxes: [],
      selectedStatedItems: [],
      selectedItemsIndexes: [],
      checkedItemsIds: [],
      showError: false,
      urlParams: {
        category: props.category,
        filter: "",
        status: "",
        // group: '',
        type: "",
      },
      deleteWarning: false,
      isDescSearch: true,
      sortBy: "",
    };
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  componentDidMount() {
    this.refreshList();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      let tableColumns =
      this.props.category === "pending" ? "TABLE_COLUMNS_PENDING" : "TABLE_COLUMNS";
      this.Constants[tableColumns] = this.Constants[tableColumns].map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.refreshList();
    });
   
  };

  onItemSelection = (e) => {
    let selectedStatedItems = this.state.selectedStatedItems;
    let ids = this.state.checkedItemsIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedItems.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedItems.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedItems = selectedStatedItems.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedItems.map((s) => s.id);
    }
    this.setState({ checkedItemsIds: ids });

    this.setState({
      selectedStatedItems: selectedStatedItems,
    });
  };

  refreshList = (page) => {
    const { urlParams } = this.state;
    const { fetchUrl } = this.props;
    
    if(page != null){
      urlParams.page = page;
    }
    
    let parameterizedUrl = fetchUrl + "?"+"sort="+this.state.sortBy+"&" + parameterStringify(urlParams);

    Api.AxiosInstance.getInstance()
      .get(parameterizedUrl)
      .then((resp) => {
        this.setState({ data: this.dataMapper(resp.data) });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.refreshList();
    }
  }

  showDeleteWarningAndSetItem = (item) => {
    this.setState({
      selectedItems: item,
      deleteWarning: true,
    });
  };
  hideDeleteWarning = (cb) => {
    this.setState({ deleteWarning: false }, () => {
      if (cb instanceof Function) cb();
    });
  };
  onDelete = () => {
    const { URL_REMOVE_PENDING_NDA } = Constants.App;
    const { deleteUrl } = this.props;
    const { selectedItems } = this.state;

    Api.AxiosInstance.getInstance()
      .delete(`${deleteUrl}?id=${selectedItems.id}`)
      .then((resp) => {
        this.setState({ selectedItems: [] }, this.refreshList);
      })
      .catch((err) => {
        this.setState(
          {
            showError: true,
            errorMsg: "An error occured while deleting the item(s)",
            errorMsg: err.response.data.message,
            selectedItems: [],
          },
          this.refreshList
        );
      });
  };

  edit = (e, id) => {
    const { editUrl } = this.props;
    e.preventDefault();
    /// let redirectTo = NDA_EDIT_ROUTE + id;
    let redirectTo = editUrl + id;
    this.props.history.push(redirectTo);
  };

  selectCheckbox = (id) => {
    let { selectedCheckboxes } = this.state;
    if (id) {
      const alreadyPushed = selectedCheckboxes.find(
        (element) => element == id
      );
      if (!alreadyPushed) {
        selectedCheckboxes.push(id);
      }
      else{
        selectedCheckboxes = selectedCheckboxes.filter((entity) => {
          return entity !== id;
        });
      }
    } 
    this.setState({
      selectedCheckboxes: selectedCheckboxes,
    });
  };

  sendReminder = () => {
    var that = this;
    const { selectedStatedItems } = this.state;
    if (selectedStatedItems.length > 0) {
      selectedStatedItems.forEach(function (id) {
        let params = {
          params: {
            id:id.id,
          },
        };
        Api.AxiosInstance.getInstance()
          .get(Constants.App.URL_NDA_REMINDER, params)
          .then(function() { that.refreshList() })
          .catch((err) =>
          that.setState({
              showError: true,
              errorMsg: "An error occured while sending NDA reminder request.",
            })
          );
      });
      //window.location.reload();
    } else {
      that.setState({
        showError: true,
        errorMsg: "Please Select at least one Item to Send Reminder ",
      });
    }
  };

  download = (e, id) => {
    const { downloadUrl } = this.props;
    e.preventDefault();
    Api.AxiosInstance.getInstance()
      .get(downloadUrl + "?id=" + id)
      .then((resp) => {
        let win = window.open(resp.data, "_blank");
        win.focus();
      })
      .catch((err) =>
        this.setState({
          showError: true,
          errorMsg: "An error occured while downloading the NDA document.",
        })
      );
  };

  dataMapper = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    transformedData.content.map((el) => {
      let row = Object.assign({}, el);
      // row.checkboxes = row.status == "UNSIGNED" && (
      //   <React.Fragment>
      //     <Checkbox onClick={(e) => this.selectCheckbox(e, row.id)} />
      //   </React.Fragment>
      // );
      row.status !== "UNSIGNED"?row.showCheckboxes =false:row.showCheckboxes=true;
      row.actions = (
        <React.Fragment>
          <a href="#" onClick={(e) => this.edit(e, row.id)}>
            {
              <span
                style={{ margin: "5px" }}
                className="icon-edit"
                title="Edit"
                name="edit"
              />
            }
          </a>
          <a onClick={() => this.showDeleteWarningAndSetItem(row)}>
            {
              <span
                style={{ margin: "5px" }}
                className="icon-delete"
                title="Remove"
                name="remove"
              />
            }
          </a>
          <a href="#" onClick={(e) => this.download(e, row.id)}>
            {
              <span
                className="icon-download"
                style={{ margin: "5px" }}
                title="Download"
                name="download"
              />
            }
          </a>
        </React.Fragment>
      );
      row.updatedOn = <>{dateToPacific(el.updatedOn, "MM/DD/YYYY hh:mm a")}</>;
      if(row.totalRemindersSent>=1){
        row.lastReminderSentDate = (
          <>{dateToPacific(el.lastReminderSentDate,"MM/DD/YYYY hh:mm a")}</>  
        ); }
        else{
          row.lastReminderSentDate =el.lastReminderSentDate="";  
        }
        content.push(row);
      });
    transformedData.content = content;
    return transformedData;
  };

  doExtraAction = () => {
    const { extraAction } = this.props;
    const { selectedItems } = this.state;

    if (selectedItems.length === 0) {
      this.setState({ errorMsg: "No data was selected.", showError: true });
    }

    selectedItems.map((item) => {
      // Api.AxiosInstance.getInstance()
      // .delete(extraAction.url + '?id=' + item.id)
      // .then(resp => {
      //   this.refreshList();
      // }).catch(err => {
      //   this.setState({ errorMsg: "Something went wrong while deleting the data.", showError: true })
      // });
    });
  };

  onSearchTyping = (event, data) => {
    const { urlParams } = this.state;
    this.setState({
      urlParams: update(urlParams, { search: { $set: event.target.value } }),
    });
  };

  onPageChange = (e) => {
    const { urlParams } = this.state;
    this.setState(
      { urlParams: update(urlParams, { page: { $set: e.page } }) },
      this.refreshList
    );
  };

  onItemsSelection = (event) => {
    this.setState({ selectedItems: event.data });
  };

  onDropDownChange = (name, value) => {
    const { urlParams } = this.state;
    this.setState({
      urlParams: update(urlParams, { [name]: { $set: value } }),
    });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  render() {
    const { extraAction, title } = this.props;
    const { data, errorMsg, showError } = this.state;
    const {
      SEARCH_ON_STATUS,
      SEARCH_ON_TYPE,
      TABLE_COLUMNS,
      TABLE_COLUMNS_PENDING,
      SEARCH_ON_STATUS_PENDING,
      RESPONSIVE_COLUMNS,
      RESPONSIVE_COLUMNS_PENDING,
    } = this.Constants;
    const { 
      selectedStatedItems,
      selectedItemsIndexes,
      checkedItemsIds, } = this.state;

    let tableColumns =
      this.props.category === "pending" ? TABLE_COLUMNS_PENDING : TABLE_COLUMNS;
    let responsiveColumns =
      this.props.category === "pending"
        ? RESPONSIVE_COLUMNS_PENDING
        : RESPONSIVE_COLUMNS;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={10} tablet={10} computer={8} largeScreen={8}>
                <div className="header">
                  <div className="side-marker" />
                  {title}
                </div>
              </Grid.Column>

              <Grid.Column
                mobile={6}
                tablet={6}
                computer={8}
                largeScreen={8}
                className="m--align-right"
              >
                {extraAction && (
                  <button className="button-basic " onClick={this.sendReminder}>
                    {extraAction.text}
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row className="common-filter-wrapper ui form">
              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <input
                      placeholder="Search"
                      className="search-bar"
                      onChange={this.onSearchTyping}
                      onKeyPress={this.onKeyUp}
                    ></input>
                  </div>
                </div>
              </Grid.Column>
              {/* temporaty hidding it for demo 

              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <Dropdown
                      options={SEARCH_ON_TYPE}
                      selection
                      defaultValue=""
                      placeholder="Type"
                      onChange={(event, data) =>
                        this.onDropDownChange("type", data.value)
                      }
                    />
                  </div>
                </div>
              </Grid.Column>
               
              */}

              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <Dropdown
                      options={
                        extraAction
                          ? SEARCH_ON_STATUS_PENDING
                          : SEARCH_ON_STATUS
                      }
                      selection
                      defaultValue=""
                      placeholder="Status"
                      onChange={(event, data) =>
                        this.onDropDownChange("status", data.value)
                      }
                    />
                  </div>
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={4} computer={4} largeScreen={4}>
                <div class="field">
                  <div class="ui input">
                    <button
                      onClick={
                        () => this.refreshList(0)                       
                      }
                      className="button-primary m--no-margin-left-im"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Divider />
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination">
                    <Tableview
                      columns={tableColumns}
                      data={data}
                      paginate
                      onSelectionChange={this.onItemSelection}
                      onPaginationChange={this.onPageChange}
                      selectionType ={ this.props.category === "pending" ?"custom-select":"none"}
                      checkedIndexes={selectedItemsIndexes}
                      checkedIds={checkedItemsIds}
                    />
                  </div>
                </Responsive>
                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    data={data}
                    columns={responsiveColumns}
                    paginate
                    onPaginationChange={(e) => this.onPageChange(e)}
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.onDelete)}
          content="Are you sure you want to delete this non-disclosure agreement?"
        />
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

NDAListing.propTypes = {
  /** Determines whether to fetch pending or active NDAs. */
  category: PropTypes.string.isRequired,
  /** One extra action can be proformed on the table items. Like `delete` */
  extraAction: PropTypes.shape({
    /** Text to be displayed on that button */
    text: PropTypes.string,
    /** URL this component will use to perform that action */
    url: PropTypes.string,
  }),
  /** URL this component will use to fetch latest data */
  fetchUrl: PropTypes.string.isRequired,
  /** Text which will be displayed at the top of the page. */
  title: PropTypes.string.isRequired,
};

NDAListing.defaultProps = {
  category: "all",
};

export default withRouter(NDAListing);
