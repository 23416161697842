import FileIcon from "@/common/file-icon";
import equal from "fast-deep-equal";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { List, Pagination, Segment } from "semantic-ui-react";
import Highlighter from "react-highlight-words";
import { dateToPacific } from "@/utils/dateTime";
import Constants from "../../../config/constants";
import Api from "@api";
import LocalStore from "../../../store/persistence/local-storage";
import {
  trimString,
  redirectToHelpdeskURL,
  trimStringWithWord,
} from "../../../common/utils/utils";
const { URL_GLOBAL_SEARCH } = Constants.App;

class Tickets extends Component {
  columns = [
    {
      name: "Name",
      key: "name",
      width: "3",
    },
    {
      name: "Description",
      key: "description",
      width: "3",
    },

    {
      name: "File Name",
      key: "filename",
      width: "2",
      class: "center",
    },

    { name: "Action", key: "actions", width: "3" },
  ];
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listings: {
        content: [],
        number: 0,
      },
      data: {},
      pageNo: 0,
      pageSize: 10,
      totalRecords: 0,
    };
  }
  componentDidMount() {
    this.searchResults(
      this.props.keyword ? this.props.keyword : this.props.searchKeyword,
      this.props.sort
    );
  }
  componentWillReceiveProps(nextProps) {
    if (
      !equal(this.props.keyword, nextProps.keyword) ||
      nextProps.sort.sortBy !== this.props.sort.sortBy ||
      nextProps.sort.sortOrder !== this.props.sort.sortOrder
    ) {
      this.setState(
        {
          sort: nextProps.data,
        },
        () => {
          this.searchResults(
            nextProps.keyword ? nextProps.keyword : nextProps.searchKeyword,
            nextProps.sort
          );
        }
      );
    }
  }
  transformData = (data) => {
    let content = [];
    if (data) {
      data.map((el) => {
        let row = Object.assign({}, el);
        row.href = row.url;
        row.header = row.subject;
        row.description = trimStringWithWord(
          el.sourceAsMap.description,
          this.props.searchKeyword || this.props.keyword,
          120
        );
        row.actions = (
          <React.Fragment>
            <Link
              // target="_blank"
              to={`#`}
            >
              {" "}
              <span class="icon-search" title="View"></span>
            </Link>
          </React.Fragment>
        );
        row.creationDate = row.sourceAsMap.creationDate;
        row.item_type = (
          <FileIcon
            filename={row.filename ? row.filename : "other"}
            size="large"
          ></FileIcon>
        );
        content.push(row);
      });
    }
    data.content = content;

    return data;
  };

  redirectTotTicket = (url) => {
    return () => {
      const showHideSupportErrorDialog = this.props.showHideSupportErrorDialog;
      const showHideMainLoader = this.props.showHideMainLoader;
      showHideMainLoader(true);
      redirectToHelpdeskURL(url, true)
        .then((returnToUrl) => {
          LocalStore.setValue("loggedInToHelpDesk", true);
          window.open(returnToUrl, "_blank");
          showHideMainLoader(false);
        })
        .catch((error) => {
          showHideSupportErrorDialog(true, error.response.data.error);
          showHideMainLoader(false);
        });
    };
  };
  searchResults = (keyword, sort) => {
    this.setState(
      {
        ...this.state,
        loading: true,
      },
      () => {
        Api.AxiosInstance.getInstance()
          .get(`${URL_GLOBAL_SEARCH}tickets`, {
            params: {
              query: keyword,
              ...sort,
              pageNo: this.state.pageNo,
              pageSize: this.state.pageSize,
            },
          })
          .then((resp) => {
            this.setState({
              listings: this.transformData(resp.data.hits.hits),
              loading: false,
              totalRecords: resp.data.hits.totalHits,
            });
          });
      }
    );
  };
  onPageChange = (e, { activePage }) => {
    this.setState(
      {
        pageNo: activePage - 1,
      },
      () => {
        this.searchResults(
          this.props.keyword || this.props.searchKeyword,
          this.props.sort
        );
      }
    );
  };
  render() {
    return (
      <Segment className="m-0 p-0" loading={this.state.loading}>
        {this.state.listings.content.length > 0 ? (
          <>
            <List celled className="search-result-wrapper">
              {this.state.listings.content.map((data, index) => (
                <List.Item className="search-item-row">
                  <List.Content>
                    <List.Header
                      as="p"
                      // href={"javacript:void(0)"}
                      onClick={this.redirectTotTicket(data.sourceAsMap.url)}
                      className="ticket-external-link"
                    >
                      {data.sourceAsMap.subject}
                    </List.Header>
                  
                    <List.Description className="s-des-wrapper">
                      <p className="des-p1">
                        {data.creationDate
                          ? dateToPacific(data.creationDate, 'MM/DD/YYYY')
                          : 'N/A'}{' '}
                        --&nbsp;{' '}
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[
                            this.props.keyword
                              ? this.props.keyword
                              : this.props.searchKeyword,
                          ]}
                          autoEscape={true}
                          textToHighlight={data.description}
                        />
                      </p>
                    </List.Description>  <span className="des-p2">{data.sourceAsMap.url}</span>
                  </List.Content>
                </List.Item>
              ))}
            </List>
            {this.state.listings.content.length > 0 ? '' : 'No record found'}
            <Pagination
              activePage={this.state.pageNo + 1}
              onPageChange={this.onPageChange}
              totalPages={Math.ceil(this.state.totalRecords / 10)}
            />
          </>
        ) : (
          'No record found'
        )}
      </Segment>
    );
  }
}

export default Tickets;
