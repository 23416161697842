import Actions from "../../actions";
import LocalStore from "../../persistence/local-storage";
import Api from "@/api";
import { faPassport } from "@fortawesome/free-solid-svg-icons";

const USER = Actions.USER;

const initialState = {
  isLoggedIn: false,
  isLoggingIn: false,
  loginAction: { message: null, success: false },
  username: null,
  password: null,
  user: {},
  showTwoFactor: false,
  disabledHelpdeskNotif: { state: false, message: '' },
  notificationsCount: { count: 0 },
  notificationsSidebarVisible: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER.USER_LOGOUT: {
      let isLoggedInHelpdesk = LocalStore.getValue('loggedInToHelpDesk')
      if (action.payload && action.payload.hasOwnProperty("error")) {
        isLoggedInHelpdesk = false;
      }
      LocalStore.setValue("token", null);
      LocalStore.setValue("activity-time", null);
      Api.AxiosInstance.destroy();
      LocalStore.removeValue("uid");
      LocalStore.removeValue("loggedInToHelpDesk");
      LocalStore.removeValue('returnTo')
      LocalStore.removeValue('supportEnabled')
      LocalStore.removeValue('NDAExpired')
      
      if (isLoggedInHelpdesk) {
        window.location.href = 'https://helpdesk.groq.com/access/logout?return_to=' + encodeURIComponent(window.location.origin);
      }
      return {
        ...initialState,
        isLoggedIn: false,
      };
    }
    case USER.USER_LOGIN: {
      const user = action.payload;
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        user,
      };
    }
    case USER.USER_DATA_SUCCESS: {
      const payload = action.payload;
      const accessToken = payload.tokenType + " " + payload.accessToken;
      LocalStore.setValue("token", accessToken);
      return {
        ...state,
        loginAction: { message: null, success: true },
        isLoggingIn: false,
        showTwoFactor: false,
        isLoggedIn: payload.hasOwnProperty('showDashboard') ? payload.showDashboard : true,
      };
    }
    case USER.TWO_FACTOR_AUTH_REQUIRED: {
      const data = action.data;
      return {
        ...state,
        isLoggingIn: false,
        showTwoFactor: true,
        isLoggedIn: false,
        username: data.username,
        password: data.password,
        loginAction: { message: null, success: false },
      };
    }
    case USER.INVALID_2FA_CODE: {
      const data = action.data;
      return {
        ...state,
        isLoggingIn: false,
        showTwoFactor: false,
        isLoggedIn: false,
        username: data.username,
        password: data.password,
        loginAction: { message: "Invalid Code", success: false },
      };
    }
    case USER.USER_DATA_FAILURE: {
      const payload = action.payload;

      LocalStore.setValue("token", null);
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        loginAction: { message: payload.message, success: false },
      };
    }
    case USER.USER_LOGGING_IN: {
      return {
        ...state,
        isLoggingIn: true,
        isLoggedIn: false,
        loginAction: { message: null, success: false },
      };
    }
    case USER.USER_CLOSE_POPUP: {
      return {
        ...state,
         showTwoFactor: false,
        // loginAction: { message: null, success: false },
      };
    }
    case USER.DISABLED_HELPDESK_NOTIF: {
      return {
        ...state,
        disabledHelpdeskNotif: {state: action.payload !== '', message: action.payload},
      };
    }
    case USER.NOTIFICATIONS_COUNT_UPDATE: {
      return {
        ...state,
        notificationsCount: action.payload,
      };
    }
    case USER.NOTIFICATIONS_SIDEBAR_SHOW: {
      return {
        ...state,
        notificationsSidebarVisible: true,
      };
    }
    case USER.NOTIFICATIONS_SIDEBAR_HIDE: {
      return {
        ...state,
        notificationsSidebarVisible: false,
      };
    }

    default:
      return state;
  }
}
