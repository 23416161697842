import Profile from "./profile";
import { connect } from "react-redux";
import Config from "@/config/constants";
import { authoritySelector } from "@/common/selectors";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const baseUrl = "/profile";

const mapStateToProps = (state) => ({
  authority: authoritySelector(state),
  baseUrl: baseUrl,
});

const mappedProfile = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default {
  name: "Profile",
  configKey: "profile_menu_item_title",
  path: baseUrl,
  component: mappedProfile,
  isPrivateRoute: true,
  accessRoles: Config.AccessRole.ALL,
  navbarVisibility: false,
};
