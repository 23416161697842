import ProductConstants from "@/modules/products/constants";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import AddPackage from "./pages/add/index";
import EditProducts from "./pages/edit/index";
import PackageList from "./pages/list";

class Package extends React.Component {
  staticValues = {
    routes: [
      {
        path: ProductConstants.URL_PACKAGES,
        exact: true,
        render: (p) => <PackageList key={p.match.params.id} {...p} />,
      },
      // {
      //   path: ProductConstants.URL_PACKAGES + "/:packageId/edit",
      //   exact: true,
      //   render: (p) => <EditProducts key={p.match.params.id} {...p} />,
      // },
      // {
      //   path: ProductConstants.URL_PACKAGES + "/new",
      //   exact: true,
      //   render: (p) => <AddPackage key={p.match.params.id} {...p} />,
      // },
    ],
  };
  render() {
    return (
      <Segment className="base-segment-wrapper">
        <Switch>
          {this.staticValues.routes.map((item, key) => (
            <Route key={key} {...item} />
          ))}
        </Switch>
      </Segment>
    );
  }
}

export default Package;
