import Api from "@/api";
import { CardviewMobile } from "@/common/cardview";
import PackageListing from "@/common/package-selection";
import Tableview from "@/common/tableview";
import urls from "@/config/constants";
import { get, isEmpty } from "lodash";
import React from "react";
import Moment from "react-moment";
import {
  Button,
  Confirm,
  Icon,
  Modal,
  Responsive,
  Segment,
} from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";

class PackageInfo extends React.Component {
  Constants = {
    TABLE_COLUMNS: Object.freeze([
      { name: "Package Name", key: "packageName", cb: () => {
        this.sortByHeader("package0.name", "Package Name");
      }, },
      { name: "Assigned By", key: "createdBy", cb: () => {
        this.sortByHeader("createdBy", "Assigned By");
      }, },
      { name: "Assigned On", key: "creationDate", cb: () => {
        this.sortByHeader("creationDate", "Assigned On");
      }, },
    ]),
    RESPONSIVE_COLUMNS: Object.freeze([
      { name: "Package Name", key: "packageName" },
      { name: "Assigned By", key: "createdBy" },
      { name: "Assigned On", key: "creationDate" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedPackages: { content: [] },
      errorMsg: null,
      showError: false,
      selectedPackages: [],
      selectedStatedPackages: [],
      selectedPackagesIndexes: [],
      checkedPackagesIds: [],
      showPackageListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
      pageNum:0,
      isDescSearch: true,
      sortBy: "",
    };
  }

  componentDidMount() {
    this.fetchAssignedPackages();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.TABLE_COLUMNS = this.Constants.TABLE_COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchAssignedPackages();
    });
   
  };

  fetchAssignedPackages = () => {
    const { assignedPackages,pageNum } = this.state;
    const { id } = this.props.match.params;
    const { URL_ADMIN_GROUP_ASSIGNED_PACKAGES } = urls.App;
    let params = {
      params: {
        groupId: id,
        page: pageNum,
        sort: this.state.sortBy,
      },
    };
    Api.AxiosInstance.getInstance()
      .get(URL_ADMIN_GROUP_ASSIGNED_PACKAGES, params)
      .then((resp) => {
        this.setState({
          assignedPackages: this.transformResponse(resp.data),
        });
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: "Something went wrong while loading the packages list.",
        });
      });
  };

  transformResponse = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.creationDate = <>{dateToPacific(row.creationDate,"MM/DD/YYYY hh:mm a")}</>;
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };
  onAssign = () => {
    this.setState({ showPackageListing: true });
  };

  doAssign = (data) => {
    this.setState({ showPackageListing: false, selectedPackages: data }, () => {
      this.updatePackages(
        "ADD_PACKAGE",
        "Something went wrong while assigning new package(s)."
      );
    });
  };

  onUnassign = () => {
    const { selectedPackages } = this.state;
    if (isEmpty(selectedPackages)) {
      this.setState({
        showError: true,
        errorMsg:
          "No package selected for deletion. Please select one or more packages.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "REMOVE_PACKAGE",
        "Something went wrong while unassigning the package(s)."
      );
    }
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb(action, msg);
      }
    );
  };

  updatePackages = (actionType, errorMsg) => {
    const { selectedPackages } = this.state;
    const { URL_ADMIN_GROUP_ASSIGN_PACKAGE } = urls.App;

    let url = `${URL_ADMIN_GROUP_ASSIGN_PACKAGE}?groupId=${get(this.props, [
      "match",
      "params",
      "id",
    ])}`;

    let userIds = [];
    selectedPackages.map((pkg) => {
      userIds.push(pkg.id);
    });

    Api.AxiosInstance.getInstance()
      .post(url, userIds)
      .then((resp) => {
        this.fetchAssignedPackages();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };
  removePackages = (actionType, errorMsg) => {
    const { selectedPackages } = this.state;
    const { URL_ADMIN_GROUP_REMOVE_PACKAGE } = urls.App;

    let url = `${URL_ADMIN_GROUP_REMOVE_PACKAGE}?groupId=${get(this.props, [
      "match",
      "params",
      "id",
    ])}`;
    let groupIds = [];
    selectedPackages.map((pkg) => {
      groupIds.push(pkg.packageId);
    });

    Api.AxiosInstance.getInstance()
      .post(url, groupIds)
      .then((resp) => {
        this.setState({pageNum:0});
        this.fetchAssignedPackages();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: errorMsg,
        });
      });
  };

  onPageChange = (e) => {
    this.setState({ pageNum: e.page }, () => this.fetchAssignedPackages());
  };

  onPackageSelection = (e) => {
    //  this.setState({ selectedPackages: e.data });
    let selectedStatedPackages = this.state.selectedStatedPackages;
    let ids = this.state.checkedPackagesIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedPackages.find(
        (element) => element.packageId == e.changedContent.packageId
      );
      if (!alreadyPushed) {
        selectedStatedPackages.push(e.changedContent);
        ids.push(e.changedContent.packageId);
      }
    } else {
      selectedStatedPackages = selectedStatedPackages.filter((entity) => {
        return entity.packageId !== e.changedContent.packageId;
      });

      ids = selectedStatedPackages.map((s) => s.packageId);
    }
    this.setState({ checkedPackagesIds: ids });

    this.setState({
      selectedStatedPackages: selectedStatedPackages,
      selectedPackages: selectedStatedPackages,
    });
  };

  onAllItemSelection = (selectedPackages, flag) => {
    let {selectedStatedPackages} = this.state;
    let ids=[];
    if(flag){
      selectedPackages.map((group)=>{
          selectedStatedPackages = selectedStatedPackages.filter((entity) => {
          return entity.packageId !== group.packageId;
        })
      });
    }else{
      selectedPackages.map((group)=>{
          if (group) {
            const alreadyPushed = selectedStatedPackages.find(
              (element) => element.packageId == group.packageId
            );
            if (!alreadyPushed) {
              selectedStatedPackages.push(group);
            } }
          } );
    }
    ids = selectedStatedPackages.map((s) => s.packageId);
    this.setState({
      selectedStatedPackages: selectedStatedPackages,
      selectedPackages: selectedStatedPackages,
      checkedPackagesIds: ids,
       });
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closePackageListing = () => {
    this.setState({ showPackageListing: false });
  };

  render() {
    const { TABLE_COLUMNS, RESPONSIVE_COLUMNS } = this.Constants;
    const {
      assignedPackages,
      errorMsg,
      showError,
      showPackageListing,
      selectedStatedPackages,
      selectedPackagesIndexes,
      checkedPackagesIds,
    } = this.state;

    return (
      <React.Fragment>
        <Segment basic>
          <div className="control-action-wrapper mb-1">
            <Button className="button-basic mini" onClick={this.onAssign}>
              <Icon name="plus" />
              Assign New Packages
            </Button>

            <Button
              color="red"
              className="button-basic mini mr-0-im"
              onClick={this.onUnassign}
            >
              <Icon name="remove circle" />
              Unassign
            </Button>
          </div>
        </Segment>

        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination mt-2">
            <Tableview
              paginate
              columns={TABLE_COLUMNS}
              data={assignedPackages}
              onSelectionChange={this.onPackageSelection}
              onAllSelectionChange={this.onAllItemSelection}   
              packageSelection={true}          
              checkedIndexes={selectedPackagesIndexes}
              checkedIds={checkedPackagesIds}
              noDataMessage="No packages found."
              selectionType="multi-select"
              onPaginationChange={this.onPageChange}
            />
          </div>
        </Responsive>

        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={assignedPackages}
            columns={RESPONSIVE_COLUMNS}
            onSelectionChange={this.onPackageSelection}
            onAllSelectionChange={this.onAllItemSelection}   
            packageSelection={true}          
            checkedIndexes={selectedPackagesIndexes}
            checkedIds={checkedPackagesIds}
            noDataMessage="No packages found."
            selectionType="multi-select"
            paginate
            onPaginationChange={this.onPageChange}
          />
        </Responsive>

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <PackageListing
          show={showPackageListing}
          onDismiss={this.closePackageListing}
          onSave={this.doAssign}
          entity="GroupPackages"
          entityId={get(this.props, ["match", "params", "id"])}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.removePackages)}
          content={`Are you sure you want to delete this package(s)?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageInfo;
