import React from "react";
import { get, isEmpty } from "lodash";
import { Button, Icon, Modal, Segment, Confirm, Grid, Responsive  } from "semantic-ui-react";
import update from "immutability-helper";
import Tableview from "@/common/tableview";
import urls from "@/config/constants";
import Api from "@/api";
import Constants from "@/config/constants";
import Moment from "react-moment";
import {dateToPacific} from "@/utils/dateTime";
import VideosListing from "@/common/video-selection/videos-listing"
import {CardviewMobile} from "@/common/cardview";

class PackageVideos extends React.Component {
  Constants = {
    VIDEO_COLUMNS: Object.freeze([
      { name: "Video name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Video name");
      }, },
      { name: "Description", key: "description", showTooltip: true, cb: () => {
        this.sortByHeader("description", "Description");
      }, },
      { name: "Assigned By", key: "createdBy", showTooltip: true, cb: () => {
        this.sortByHeader("createdBy", "Assigned By");
      }, },
      { name: "Assigned On", key: "creationDate", showTooltip: true, cb: () => {
        this.sortByHeader("creationDate", "Assigned On");
      }, },
    ]),
     RESPONSIVE_VIDEO_COLUMNS: Object.freeze([
      { name: "Video name", key: "name" },
      { name: "Description", key: "description" },
      { name: "Assigned By", key: "createdBy" },
      { name: "Assigned On", key: "creationDate" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedVideos: { content: [] },
      videos: {},
      errorMsg: null,
      showError: false,
      selectedVideos: [],
      showVideoListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
      data: {},
      redirectTo: false,
      packageId: props.match.params.packageId,
      videoId: null,
      pageNum: 0,
      isDescSearch: true,
      sortBy: "",
      direction:"DESC",
      selectedStatedVideos: [],
      selectedVideosIndexes: [],
      checkedVideosIds: [],
    };
  }

  componentDidMount() {
    this.fetchAssignedVideos();
  }


  transformData = (data) => {
    return data.map((d) => {
      return {
        ...d,
        creationDate: dateToPacific(
          d.creationDate,
          "MM/DD/YYYY hh:mm a"
        ),
      };
    });
  };


  sortByHeader = (key, title) => {
    let sort = `${key}`;
    let dir = `DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      sort = `${key}`;
      dir = `ASC`;
    }
    this.setState({ sortBy: sort, direction:dir  }, () => {
      this.Constants.VIDEO_COLUMNS = this.Constants.VIDEO_COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchAssignedVideos();
    });
   
  };

  fetchAssignedVideos = () => {
    const { assignedVideos, videos } = this.state;
    const { packageId } = this.props.match.params;
    if (packageId > 0) {
      // Need to add this constants file
      const { URL_PACKAGE_VIDEOS } = Constants.App;
      const params = {
        params: { id: packageId, page: this.state.pageNum,
        sort:this.state.sortBy,direction:this.state.direction },
      };
      const instance = Api.AxiosInstance.getInstance();
      instance.get(URL_PACKAGE_VIDEOS, params).then((resp) => {
        console.log("resp",resp)
        let transformedData = [];
        if(resp.data.length > 0)
        { transformedData = {content : this.transformData(resp.data)}}
        this.setState({
          assignedVideos: transformedData,
          isLoaded: true,
        });
      });
    }
  };

  onAssign = () => {
    this.setState({ showVideoListing: true });
  };

  doAssign = (data) => {
    this.setState(
      {
        showVideoListing: false,
        selectedVideos: data,
        action: "ADD_VIDEO",
        msg: "Something went wrong while assigning new video(s) to package.",
        selectedStatedVideos: [],
        selectedVideosIndexes: [],
        checkedVideosIds: [],
      },
      () => {
        this.updatePackages();
      }
    );
  };

  onUnassign = () => {
    const { selectedVideos } = this.state;

    if (isEmpty(selectedVideos)) {
      this.setState({
        showError: true,
        errorMsg:
          "No Video selected for deletion. Please select one or more videos.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "REMOVE_VIDEO",
        "Something went wrong while unassigning the video(s) to package."
      );
    }
  };

  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;

    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  updatePackages = () => {
    const packageId = get(this.props, ["match", "params", "packageId"]);
    const { selectedVideos, action, msg } = this.state;
    const params = { params: { id: packageId } };
    let videoIds = [];
    selectedVideos.map((video) => videoIds.push(video.id));    
    let url = "/packages/videos";
    let payload = { action: action, videoIds: videoIds };

    Api.AxiosInstance.getInstance()
      .post(url, payload, params)
      .then((resp) => {
        this.fetchAssignedVideos();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: msg,
        });
      });
  };

  onPackageSelection = (e) => {
    let selectedStatedVideos = this.state.selectedStatedVideos;
    let ids = this.state.checkedVideosIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      console.log(e)
      const alreadyPushed = selectedStatedVideos.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedVideos.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      console.log(e)

      selectedStatedVideos = selectedStatedVideos.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedVideos.map((s) => s.id);
    }
    this.setState({ checkedVideosIds: ids });

    this.setState({
      selectedStatedVideos: selectedStatedVideos,
      selectedVideos: selectedStatedVideos,
    });
  };

  onAllItemSelection = (selectedVideos, flag) => {
    if(selectedVideos === undefined)
      return
    let {selectedStatedVideos} = this.state;
    console.log(selectedVideos, flag)
    let ids=[];
     if(flag ){
      selectedVideos.map((video)=>{
        selectedStatedVideos = selectedStatedVideos.filter((entity) => {
          return entity.id !== video.id;
        })
      });
    }else{
      selectedVideos.map((video)=>{
          if (video) {
            const alreadyPushed = selectedStatedVideos.find(
              (element) => element.id == video.id
            );
            if (!alreadyPushed) {
              selectedStatedVideos.push(video);
            } }
          } );
    }
    ids = selectedStatedVideos.map((s) => s.id);
    this.setState({
      selectedStatedVideos: selectedStatedVideos,
      selectedVideos: selectedStatedVideos,
      checkedVideosIds: ids,
       });
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closevideoListing = () => {
    this.setState({ showVideoListing: false });
  };

  onPageChange = (e) => {
    this.setState({ pageNum: e.page }, () => this.fetchAssignedVideos());
  };

  render() {
    const { VIDEO_COLUMNS, RESPONSIVE_VIDEO_COLUMNS } = this.Constants;
    const {
      assignedVideos,
      errorMsg,
      showError,
      showVideoListing,
      selectedVideosIndexes,
      checkedVideosIds, 
    } = this.state;
    return (
      <React.Fragment>
        <Segment basic>
          <div className="control-action-wrapper mb-1">
            <Button className="button-basic mini" onClick={this.onAssign}>
              <Icon name="plus" />
              Assign New Video
            </Button>
            <Button
              className="button-basic mini mr-0-im"
              color="red"
              onClick={this.onUnassign}
            >
              <Icon name="remove" />
              Unassign
            </Button>
          </div>
        </Segment>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Responsive minWidth={1024}>
              <div className="table-wrapper-with-pagination">
                <Tableview
                  //paginate
                  columns={VIDEO_COLUMNS}
                  data={assignedVideos}
                  onSelectionChange={this.onPackageSelection}
                  onAllSelectionChange={this.onAllItemSelection}
                  checkedIndexes={selectedVideosIndexes}
                  checkedIds={checkedVideosIds}
                  noDataMessage="No Videos found."
                  selectionType="multi-select"
                  //onPaginationChange={this.onPageChange}
                />
              </div>
            </Responsive>
            <Responsive maxWidth={1023}>
              <CardviewMobile
                data={assignedVideos}
                columns={RESPONSIVE_VIDEO_COLUMNS}
                onSelectionChange={this.onPackageSelection}
                onAllSelectionChange={this.onAllItemSelection}
                checkedIndexes={selectedVideosIndexes}
                checkedIds={checkedVideosIds}
                noDataMessage="No Videos found."
                selectionType="multi-select"
                hidePagination
              />
            </Responsive>
          </Grid.Column>
        </Grid.Row>

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <VideosListing
          show={showVideoListing}
          onDismiss={this.closevideoListing}
          onSave={this.doAssign}
          entity="packagesVideos"
          entityId={this.state.packageId}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updatePackages)}
          content={`Are you sure you want to delete this Video Packages(s)?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageVideos;
