import EditProfile from "./editprofile";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { restrictedEmailDomainsSelector } from "@/common/selectors";
import Api from "@/api";

const commonSelector = (state, props) => state.common;
const countriesSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.countries
);
const statesSelector = createSelector(
  [commonSelector],
  (commons) => commons.config.maps.states
);

const mapDispatchToProps = (dispatch) => {
  return {
    reloadUserObject: () => {
      dispatch(Api.User.reloadUserObject());
    },
    logout: () => {
      dispatch(Api.User.logout());
    },
  };
};

const mapStateToProps = (state, props) => {
  return {
    countries: countriesSelector(state, props),
    states: statesSelector(state, props),
    restrictedEmailDomains: restrictedEmailDomainsSelector(state),
  };
};

const mappedEditProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfile);

export default mappedEditProfile;
