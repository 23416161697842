import Api from "@/api";
import { CardviewMobile } from "@/common/cardview";
import GroupListing from "@/common/group-selection/group-listing";
import Tableview from "@/common/tableview";
import Constants from "@/config/constants";
import { get, isEmpty } from "lodash";
import React from "react";
import {dateToPacific} from "@/utils/dateTime";
import {
  Button,
  Confirm,
  Icon,
  Modal,
  Responsive,
  Segment,
} from "semantic-ui-react";
import update from "immutability-helper";
import moment from 'moment';

class PackageGroups extends React.Component {
  Constants = {
    GROUP_COLUMNS: Object.freeze([
      { name: "Group name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Group name");
      }, },
      { name: "Description", key: "description", showTooltip: true, cb: () => {
        this.sortByHeader("description", "Description");
      }, },
      { name: "Assigned By", key: "updatedBy", showTooltip: true, cb: () => {
        this.sortByHeader("updatedBy", "Assigned By");
      }, },
      { name: "Assigned On", key: "updatedOn", showTooltip: true, cb: () => {
        this.sortByHeader("updatedOn", "Assigned On");
      }, },
    ]),
    RESPONSIVE_GROUP_COLUMNS: Object.freeze([
      { name: "Group name", key: "name" },
      { name: "Description", key: "description" },
      { name: "Assigned By", key: "updatedBy" },
      { name: "Assigned On", key: "updatedOn" },
    ]),
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedProducts: { content: [] },
      assignedGroups: { content: [] },
      groups: {},
      errorMsg: null,
      showError: false,
      selectedGroups: [],
      showGroupListing: false,
      msg: null,
      action: null,
      deleteWarning: false,
      data: {},
      redirectTo: false,
      packageId: props.match.params.packageId,
      groupId: null,
      pageNum: 0,
      isDescSearch: true,
      sortingKey: "",
      selectedStatedGroups: [],
      selectedGroupsIndexes: [],
      checkedGroupsIds: [],
    };
  }

  componentDidMount() {
    this.fetchAssignedGroups();
  }

  // transformData
  // transformData = (data) => {
  //   let transformedData = Object.assign({}, data);
  //   let content = [];
  //   if (data) {
  //     transformedData.content.map((el) => {
  //       let row = Object.assign({}, el);
  //       row.updatedOn = (
  //           <>{dateToPacific(row.updatedOn,"MM/DD/YYYY hh:mm a")}</>
  //       );

  //       content.push(row);
  //     });
  //   }
  //   transformedData.content = content;
  //   return transformedData;
  // };

  transformData = (data) => {
    return data.map((d) => {
      return {
        ...d,
        updatedOn: dateToPacific(
          d.updatedOn,
          "MM/DD/YYYY hh:mm a"
        ),
      };
    });
  };

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({
      sortingKey: key
    },()=>{
      if (!isDesc) {
        query = `${key},ASC`;
      }
      this.setState({ sortBy: query }, () => {
        this.Constants.GROUP_COLUMNS = this.Constants.GROUP_COLUMNS.map((obj) => {
          if (obj.name == title) {
            return {
              ...obj,
              isDesc: isDesc,
              isActive: true,
            };
          } else
            return {
              ...obj,
              isActive: false,
            };
        });
        this.setState({ isDescSearch: !isDesc });
        this.fetchAssignedGroupsSorted();
      });
    });
  };

  fetchAssignedGroupsSorted = () => {
    const { assignedGroups } = this.state;
    const { sortingKey } = this.state;
    const { isDescSearch } = this.state;
    let data = this.state.assignedGroups.content.sort(function(a, b){
        var objA=(String(a[sortingKey])).toLowerCase(), objB=(String(b[sortingKey])).toLowerCase();
        sortingKey==="updatedOn"?objA=moment(a[sortingKey]):objA=objA
        sortingKey==="updatedOn"?objB=moment(b[sortingKey]):objB=objB
        if(!isDescSearch){
        if (objA < objB)
         return -1;
        if (objA > objB)
         return 1;
        return 0;}
        else{
         if (objA > objB)
          return -1;
         if (objA < objB)
          return 1;
         return 0;
        }
       });
    this.setState({
      assignedGroups: update(assignedGroups, {
        content: { $set:data,}})
    });
  }

  fetchAssignedGroups = () => {
    const { data,assignedGroups } = this.state;
    const id = get(this.props, ["match", "params", "id"]);

    if (id > 0) {
      // Need to add this constants file
      const { URL_ADD_VIDEOS_DIR } = Constants.App;
      const params = {
        params: { id, page: this.state.pageNum },
      };
      const instance = Api.AxiosInstance.getInstance();
      instance.get(`${URL_ADD_VIDEOS_DIR}/single`, params).then((resp) => {
        // const transformedData = this.transformData({
        //   content: resp.data.groupids,
        // });
        this.setState({
          assignedGroups: update(assignedGroups, {
            content: { $set: this.transformData(resp.data.groupids) },
          }),
        });

        this.setState({
          //assignedGroups: transformedData,
          isLoaded: true,
        });
      });
    }
  };

  onAssign = () => {
    this.setState({ showGroupListing: true });
  };

  doAssign = (data) => {
    this.setState(
      {
        showGroupListing: false,
        selectedGroups: data,
        action: "ADD_GROUP",
        msg: "Something went wrong while assigning new group(s) to video.",
        selectedStatedGroups: [],
        selectedGroupsIndexes: [],
        checkedGroupsIds: [],
      },
      () => {
        this.updatePackages();
      }
    );
  };

  onUnassign = () => {
    const { selectedGroups } = this.state;

    if (isEmpty(selectedGroups)) {
      this.setState({
        showError: true,
        errorMsg:
          "No Group selected for deletion. Please select one or more groups.",
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "DELETE_GROUP",
        "Something went wrong while unassigning the group(s) to package."
      );
    }
  };

  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true,
    });
  };

  hideDeleteWarning = (cb) => {
    const { action, msg } = this.state;

    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  updatePackages = () => {
    const id = get(this.props, ["match", "params", "id"]);
    const { selectedGroups, action, msg } = this.state;
    const params = { params: { id } };
    let groupIds = [];
    selectedGroups.map((group) => groupIds.push({ id: group.id }));
    let url = Constants.App.URL_VIDEOS;
    let payload = { actiontype: action, groupids: groupIds };
    Api.AxiosInstance.getInstance()
      .post(url, payload, params)
      .then((resp) => {
        this.fetchAssignedGroups();
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMsg: msg,
        });
      });
  };

  onPackageSelection = (e) => {
    //this.setState({ selectedGroups: e.data });
    let selectedStatedGroups = this.state.selectedStatedGroups;
    let ids = this.state.checkedGroupsIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedGroups.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedGroups.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedGroups = selectedStatedGroups.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedGroups.map((s) => s.id);
    }
    this.setState({ checkedGroupsIds: ids });

    this.setState({
      selectedStatedGroups: selectedStatedGroups,
      selectedGroups: selectedStatedGroups
    });
  };
  onAllItemSelection = (selectedGroups, flag) => {
    let {selectedStatedGroups} = this.state;
    let ids=[];
    if(flag){
      selectedGroups.map((group)=>{
        selectedStatedGroups = selectedStatedGroups.filter((entity) => {
          return entity.id !== group.id;
        })
      });
    }else{
      selectedGroups.map((group)=>{
          if (group) {
            const alreadyPushed = selectedStatedGroups.find(
              (element) => element.id == group.id
            );
            if (!alreadyPushed) {
              selectedStatedGroups.push(group);
            } }
          } );
    }
    ids = selectedStatedGroups.map((s) => s.id);
    this.setState({
      selectedStatedGroups: selectedStatedGroups,
      selectedGroups: selectedStatedGroups,
      checkedGroupsIds: ids,
       });
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  closeGroupListing = () => {
    this.setState({ showGroupListing: false });
  };

  onPageChange = (e) => {
    this.setState({ pageNum: e.page }, () => this.fetchAssignedGroups());
  };

  render() {
    const { GROUP_COLUMNS, RESPONSIVE_GROUP_COLUMNS } = this.Constants;
    const {
      groups,
      assignedGroups,
      errorMsg,
      showError,
      showGroupListing,
      selectedStatedGroups,
      selectedGroupsIndexes,
      checkedGroupsIds,
    } = this.state;
    return (
      <React.Fragment>
        <Segment basic>
          <div className="control-action-wrapper mb-1">
            <Button className="button-basic mini" onClick={this.onAssign}>
              <Icon name="plus" />
              Assign New Group
            </Button>
            <Button
              className="button-basic mini mr-0-im"
              color="red"
              onClick={this.onUnassign}
            >
              <Icon name="remove" />
              Unassign
            </Button>
          </div>
        </Segment>

        <Responsive minWidth={1024}>
          <div className="table-wrapper-with-pagination">
            <Tableview
              //paginate
              columns={GROUP_COLUMNS}
              data={assignedGroups}
              onSelectionChange={this.onPackageSelection}
              noDataMessage="No Groups found."
              selectionType="multi-select"
              hidePagination
              onAllSelectionChange={this.onAllItemSelection}               
              checkedIndexes={selectedGroupsIndexes}
              checkedIds={checkedGroupsIds}
              // onPaginationChange={this.onPageChange}
            />
          </div>
        </Responsive>
        <Responsive maxWidth={1023}>
          <CardviewMobile
            data={assignedGroups}
            columns={RESPONSIVE_GROUP_COLUMNS}
            onSelectionChange={this.onPackageSelection}
            //paginate
            noDataMessage="No Groups found."
            selectionType="multi-select"
            hidePagination
            onAllSelectionChange={this.onAllItemSelection}               
            checkedIndexes={selectedGroupsIndexes}
            checkedIds={checkedGroupsIds}
            //onPaginationChange={(e) => this.onPageChange(e)}
          />
        </Responsive>
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <GroupListing
          show={showGroupListing}
          onDismiss={this.closeGroupListing}
          onSave={this.doAssign}
          entity="TrainingVideosDirGroups"
          entityId={get(this.props, ["match", "params", "id"])}
        />
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updatePackages)}
          content={`Are you sure you want to delete this Group Packages(s)?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageGroups;
