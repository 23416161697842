import React from "react";
import PropTypes from "prop-types";
import { validate } from "validate.js";
import { TextareaField } from '@/common/Form'
import {
    Button,
    Comment,
    Container,
    Form,
    Input,
    Message,
    Segment,
    Grid
} from "semantic-ui-react";
import Post from "./subcomponents/post";

class Wall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPostText: "",
            searchText: "",
            validationMsg: {
            }
        };
    }

    onTypingNewPost = event => {
        this.setState({newPostText: event.target.value});
    };

    onTypingSearch = (data) => {
        this.setState({searchText: data.target.value});
    };

    clearTextareAndPost = () => {
        const {newPostText} = this.state;
        let result = validate({text: newPostText}, 
            {
                text: {
                    presence: true,
                    length: {
                        minimum: 1,
                    }
                },
            }
        );
        if(!result){
            this.setState({
                newPostText: "",
                validationMsg: {},
            }, () => this.postNewComment(newPostText));
        }
        else{
            this.setState({ validationMsg: result});
        }
    };

    postNewComment = newPostText => {
        const {postFunction, profileId} = this.props;
        if (postFunction instanceof Function) 
            postFunction({postText: newPostText, profileId: profileId});
        };
    
    renderCommentThread = ({posts, acceptFunction, deleteFunction, postFunction}) => {
        const {newPostText} = this.state;
        return (
            <Comment.Group>
                {posts.length > 0 && posts.map(post => (<Post
                    data={post}
                    acceptFunction={acceptFunction}
                    deleteFunction={deleteFunction}
                    postFunction={postFunction}/>))}
                {posts.length === 0 && (<Message content="You have no post to display."/>)}
                {postFunction && (
                    <Form reply>
                        <TextareaField 
                            isError={this.state.validationMsg.text}
                            errorMsg={this.state.validationMsg.text}
                            value={newPostText} onChange={this.onTypingNewPost}
                            maxLength={1500}
                        />
                        <button
                            className="button-primary m--no-mar-left"
                            onClick={this.clearTextareAndPost}
                            >
                            Add Post</button>
                    </Form>
                )}
            </Comment.Group>
        );
    };

    render() {
        const {
            posts,
            acceptFunction,
            deleteFunction,
            postFunction,
            searchFunction,
            profileId
        } = this.props;
        const {searchText} = this.state;

        return (
            <React.Fragment>
                {searchFunction && (
                    <React.Fragment>
                         <Grid.Row className="common-filter-wrapper ui form">
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                                      <div class="field">
                    <div class="ui input">
                                        <input
                                            placeholder="Search"
                                            className="search-bar"
                                            onChange={this.onTypingSearch}></input>
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={8} computer={8}>
                               
                                        <div class="field">
                    <div class="ui input">
                                            <button onClick={() => searchFunction(searchText)} className="button-primary">Search</button>
                                        </div>
                                    </div>
                             
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column mobile={16} table={16} computer={16}>
                                {this.renderCommentThread({posts, acceptFunction, deleteFunction, postFunction, profileId})}

                            </Grid.Column>
                        </Grid.Row>

                    </React.Fragment>
                )}
                {!searchFunction && this.renderCommentThread({posts, acceptFunction, deleteFunction, postFunction, profileId})}
            </React.Fragment>
        );
    }
}

Wall.propTypes = {
    /** The function which will change the status of a post from `PENDING` to `CONFIRMED` */
    acceptFunction: PropTypes.func,
    /** The function which will actually contain the Delete logic */
    deleteFunction: PropTypes.func.isRequired,
    /** A list of Post Objects. */
    posts: PropTypes.arrayOf(PropTypes.shape({
        /** First name of the `commentor` */
        firstname: PropTypes.string.isRequired,
        /** Last name of the commentor */
        lastname: PropTypes.string.isRequired,
        /** The text content of the `Post` */
        post: PropTypes.string.isRequired,
        /** id of the post. */
        postId: PropTypes.number.isRequired,
        /** Commentor Id */
        posterId: PropTypes.number.isRequired,
        /** Time when the post was made */
        postingTime: PropTypes.string,
        /** Shows if the post is published or is in pending */
        status: PropTypes.string.isRequired
    })),
    /** The function which will post to the reply API */
    postFunction: PropTypes.func,
    /** Profile ID on which user is standing and posting. */
    profileId: PropTypes.number,
    /** The function which performs search */
    searchFunction: PropTypes.func
};

Wall.defaultProps = {
    posts: []
};

export default Wall;
