import {
  ErrorMessage,
  FileField,
  InputField,
  TextareaField,
} from "@/common/Form";
import Uploader from "@/common/uploader";
import { removeEmptyKeys } from "@/common/utils/utils";
import Api from "@api";
import Constants from "@constants";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import { get, isEmpty } from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Loader,
  Progress,
  Segment,
  Confirm
} from "semantic-ui-react";
import validate from "validate.js";
import _ from "lodash";

class uploadVideos extends React.Component {
  VALIDATION_CONFIG = {
    description: {
      presence: true,
    },
    name: {
      presence: true,
    },
    tags: {
      presence: true,
    },
    filename: {
      presence: false,
      equality: {
        attribute: "filetype",
        message: "Only MP4, MOV, MPEG, WEBM, or FLV files are supported.",
        comparator: function (name, type) {
          return (
            type.indexOf("mp4") !== -1 ||
            type.indexOf("mov") !== -1 ||
            type.indexOf("mpeg") !== -1 ||
            type.indexOf("flv") !== -1 ||
            type.indexOf("webm") !== -1
          );
        },
      },
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      loading: false,
      data: {},
      isUploading: false,
      uploadProgress: 0,
      name: "",
      options: [],
      tags: [],
      description: "",
      validationMsg: {},
      fileuuid: false,
      isSaving: false,
      popupWarning: false,
      searchValue: "",
    };
  }

  getTagsFromServer = () => {
    // call some Api to get the data

    Api.AxiosInstance.getInstance()
      .get("tags/list",
      {
        params: {
          name: this.state.searchValue
        }
      }
      )
      .then((resp) => {
        const mockTags = resp.data;
        const stateOptions = _.map(mockTags, (state, index) => ({
          key: index,
          text: mockTags[index].name,
          value: mockTags[index],
        }));
        this.setState({ options: stateOptions });
      })
      .catch((error) => {
        this.setState({
          apiError: "Failed to get tags",
          isUploading: false,
        });
      });
  };
  
  componentDidMount() {
    const id = get(this.props, ["match", "params", "id"]);
    // this.loadData();
    // this.loadTemplateDate();
    this.getTagsFromServer();
  }

  loadData = () => {
    const { URL_NDA_SET_TEMPLATE } = Constants.App;
    Api.AxiosInstance.getInstance()
      .get(URL_NDA_SET_TEMPLATE)
      .then((resp) => {
        this.setState({ file: resp.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadTemplateDate = () => {
    const { URL_TEMPLATE_PLACEHOLDERS } = Constants.App;
    Api.AxiosInstance.getInstance()
      .get(URL_TEMPLATE_PLACEHOLDERS)
      .then((resp) => {
        this.setState({ data: resp.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  validate = () => {
    let result = validate(removeEmptyKeys(this.state), this.VALIDATION_CONFIG);
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };
  showpopupWarning = () => {
    this.setState({ loading: true });
    if (!this.validate()) {
      this.setState({ loading: false });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }
  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  saveTemplate = () => {
    this.setState({ loading: true });

    const id = get(this.props, ["match", "params", "id"]);
    // validate() will return true incase all fields are valid.
    if (!this.validate()) {
      this.setState({ loading: false });
      return;
    }
    const { file, fileuuid, name, tags, description } = this.state;
    let tagIds = tags && tags.map((_tag) => {return _tag.id});
    
    const data = {
      actiontype: "ADD_VIDEO",
      parentId: id,
      name,
      tagIds: tagIds,
      //options: [],
      description,
      status: "ACTIVE",
      fileuuid,
      filename: file.name,
      filetype: file.type,
      filesize: file.size,
    };

    const datas = {
      id: this.state.fileuuid,
    };
    const { URL_VIDEOS } = Constants.App;
    Api.AxiosInstance.getInstance()
      .post(URL_VIDEOS, data)
      .then((resp) => {
        this.setState(
          { file: resp.data, loading: false },
          this.redirectToParent()
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };
  onChange = (event, eventData) => {
    const { name, value } = eventData;
    this.setState({ [name]: value });
  };

  redirectToParent = () => {
    // Temporary hack to redirect to parent need to send baseUrl via props
    this.setState({
      redirectTo: "/videos",
    });
  };
  handleAddition = (e, { value }) => {
    if(value.length<50){
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  }
  };
  handleDropDownChange = (e, { value }) => {

    this.setState({ tags: value });
  };
  onFileChange = (eventData) => {
    // bug GP3-148
    if (eventData == "removed") {
      this.setState({ fileuuid: null });
    };
    

    if (eventData.isUploading) {
      const { file, isUploading } = eventData;
      let { data } = this.state;

      if (file.name && file.type && file.size) {
        //data = update(data, { file: { $set: file } });
        this.setState({ isUploading, file });
      }
    } else if (typeof eventData.uploadProgress !== "undefined") {
      const { uploadProgress } = eventData;
      const isUploading = uploadProgress !== 100;
      this.setState({ isUploading, uploadProgress });
    } else if (typeof eventData.fileuuid !== "undefined") {
      const { fileuuid } = eventData;
      this.setState({ fileuuid });
    } else if (typeof eventData.errorMsg !== "undefined") {
      const { errorMsg } = eventData;
      this.setState({ errorMsg, showError: true, isUploading: false });
    }
  };
  
  onTagType = (event) => {
    if (event.target.value.length <= 0) {
      this.setState({ searchValue: "" }, () => this.getTagsFromServer());
    } else if (event.target.value.length <= 15) {
      this.setState({ searchValue: event.target.value}, () => this.getTagsFromServer());
    }
  };

  render() {
    const {
      loading,
      file,
      validationMsg,
      showError,
      fileuuid,
      uploadProgress,
      isUploading,
      data,
      name,
      description,
      tags,
    } = this.state;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">Upload Video</div>
          <div className="empty-height-small" />

          <Form loading={loading} className="width-100">
            <InputField
              fluid
              isRequired
              label="Name"
              name="name"
              value={name}
              errorMsg={validationMsg.name}
              isError={validationMsg.name}
              onChange={this.onChange}
              maxlength="32"
            />
            <TextareaField
              label="Description (maximum 250 characters allowed)"
              name="description"
              isRequired
              value={description}
              onChange={this.onChange}
              errorMsg={validationMsg.description}
              isError={validationMsg.description}
              maxLength={250}
            />
            {file && file.filename && (
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                ></Grid.Column>
                <h3>Existing Template Link: </h3>
                <a href={file.downloadLink} target="_blank">
                  {file.filename}
                </a>
              </Grid.Row>
            )}
            <Form.Field required={true} error={validationMsg.tags}>
              <label>Tags</label>

              <Form.Dropdown
                id="document-dropdown-tags"
                required
                name="tags"
                options={this.state.options}
                placeholder="Add Tags"
                search
                selection
                multiple
                value={tags}
                onChange={this.handleDropDownChange}
                onSearchChange={(e) => this.onTagType(e) }
                className="field-add-tags"

              />
              <ErrorMessage
                isError={validationMsg.tags}
                errorMsg={validationMsg.tags}
              />
            </Form.Field>
            <FileField
              errorMsg="You have not selected any video(s)."
              isError={validationMsg && validationMsg.files}
              label="Upload Video"
              name="files"
              onChange={Uploader(this.onFileChange)}
              accept=".MP4, .MOV, .MPEG, .WEBM, .FLV"
              isRequired
              multipleSelection={false}
            />
            {isUploading && <Progress percent={uploadProgress} indicating />}
          </Form>
          <Grid.Row>
            <Button
              type="submit"
              disabled={isUploading || !fileuuid}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ loading: true });
                this.showpopupWarning();
              }}
              className="button-primary"
            >
              Save
            </Button>
            <Button
              className="button-basic"
              type="reset"
              // disabled={}
              onClick={(e) => {
                e.preventDefault();
                this.redirectToParent();
              }}
            >
              {" "}
              Cancel
            </Button>
          </Grid.Row>
          {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
        </Segment>
        <Confirm
            open={this.state.popupWarning}
            onCancel={this.hidepopupWarning}
            onConfirm={() => this.hidepopupWarning(this.saveTemplate)}
            content={"Are you sure you want to add new video?"}
        />
      </React.Fragment>
    );
  }
}

export default uploadVideos;
