import Api from "@/api";
import Tableview from "@/common/tableview";
import {CardviewMobile} from "@/common/cardview";
import Constants from "@/config/constants";
import React from "react";
import { Confirm, Modal, Segment, Responsive, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";


// ToDo: shift listing component in contact-types
// more refactoring to be done later on:
class ContactTypes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      createMode: false,
      editMode: false,
      editId: null,
      deleteWarning: false,
      contactId: null,
      showError: false,
      errorMsg: null,
      columns: [
        { name: "Name", key: "name", width: "6" },
        { name: "Description", key: "description", width: "8" },
        { name: "Actions", key: "actions" }
      ],
      responsive_columns:[
        { name: "Name", key: "name", width: "6" },
        { name: "Description", key: "description", width: "8" }
      ],
      listings: { content: [], number: 0 },
      itemLoadXHR: false
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const { pageNum } = this.state;
    let params ={
      params: {
        page: pageNum,
        sort: "creationDate,DESC"

      }
    }
    return Api.AxiosInstance.paginate(
      Constants.App.URL_ADMIN_CONTACT_TYPES_LISTING, params
    ).then(resp => {
      this.setState({
        listings: this.transformData(resp.data)
      });
    });
  };
  showDeleteWarningAndSetContactId = (e, id) => {
    this.setState({
      deleteWarning: true,
      contactId: id
    });
  };
  hideDeleteWarning = cb => {
    // console.log("cb is", cb);
    this.setState(
      {
        deleteWarning: false
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  transformData = data => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map(el => {
        let row = Object.assign({}, el);
        row.actions = (
          <React.Fragment>
            <Link to={"/admin/contact-types/edit/" + row.id}>
              <span
                className="icon-edit"
                style={{
                  margin: "3px"
                }}
                title="Edit"
                name="edit"
              />
            </Link>
            <span
              className="icon-delete"
              onClick={e => this.showDeleteWarningAndSetContactId(e, row.id)}
              style={{ margin: "3px" }}
              title="Remove"
              name="remove"
            />
          </React.Fragment>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  onPageChange = e => {
    this.setState({ pageNum: e.page }, this.getList);
  };
  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  create = () => {
    this.setState({
      createMode: true,
      listings: { content: [] }
    });
  };

  edit = (e, id) => {
    e.preventDefault();
    this.setState({
      editMode: true,
      editId: id,
      listings: { content: [] }
    });
  };

  remove = () => {
    const { contactId } = this.state;
    //e.preventDefault();
    let params = {
      params:{
        id: contactId
      }
    }

    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ADMIN_CONTACT_TYPE_REMOVE , params)
      .then(resp => {
        this.getList();
      })
      .catch(err => {
        this.setState({
          errorMsg: "Something went wrong while deleting the data.",
          showError: true
        });
      });
  };

  render() {
    const { errorMsg, showError } = this.state;
    return (
      <React.Fragment>
        {this.state.listings.content &&
         (

           <Segment className="base-segment-wrapper">
            <Grid >
              <Grid.Row>
                <Grid.Column
                  mobile={10}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="header">
                    <div className="side-marker" />
                    Customer Contact Types

                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={6}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="control-action-wrapper">
                 <Link to={"/admin/contact-types/new"}>
                <button className="button-basic">Add New</button>
              </Link>
                  </div>
                </Grid.Column>
              </Grid.Row>





<Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                >

                  <Responsive minWidth={1024}>
                   <div className="table-wrapper-with-pagination">
                                  <Tableview
                                    paginate
                                    data={this.state.listings}
                                    columns={this.state.columns}
                                    onPaginationChange={this.onPageChange}
                                    noDataMessage="No Contact Type(s)"
                                  />
                                </div>
                  </Responsive>
                   <Responsive maxWidth={1023}>
                     <CardviewMobile
                          data={this.state.listings}
                          columns={this.state.responsive_columns}
                          paginate
                          onPaginationChange={e => this.onPageChange(e)}
                      
                        />
                  </Responsive>
         

             

                  </Grid.Column>
              </Grid.Row>
              </Grid>
            </Segment>
          )}
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.remove)}
          content="Are you sure you want to delete this Contact Type?"
        />
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default ContactTypes;
