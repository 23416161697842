import React, { useEffect, useState } from "react";

import { Grid, Segment, GridRow, Icon, Message } from "semantic-ui-react";
import Api from "@api";
import Constants from "@constants";
import DownloadFriendlyItem from "./download-component";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const FriendlyDownload = () => {
  const [item, setItem] = useState(null);
  const [itemLoaded, setItemLoaded] = useState(false);
  const [itemIsLoading, setItemIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [invalidItem, setInvalidItem] = useState(false);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    loadItem();
  }, [loadItem]);

  useEffect(() => {
    if (redirect) history.push("/");
  }, [history, redirect]);

  const loadItem = () => {
    const itemToken = params && params.id ? params.id : false;
    if (!itemToken) {
      setInvalidItem(true);
    }
    const { URL_FRIENDLY_NAME_ITEM_DETAILS } = Constants.App;
    const instance = Api.AxiosInstance.getInstance();
    instance
      .get(`${URL_FRIENDLY_NAME_ITEM_DETAILS}${itemToken}`)
      .then((resp) => {
        setItem(resp.data);
        setItemLoaded(true);
        setItemIsLoading(false);
      })
      .catch((e) => {
        setItemIsLoading(false);
        setInvalidItem(true);
      });
  };

  const goToProducts = () => {
    setRedirect(true);
  };

  return (
    <>
      <div className="products-container">
        <Segment className="base-segment-wrapper">
          <Grid>
            <GridRow className="products-header">
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
                <div className="side-marker" />
                <div className="header inline">Downloads</div>
              </Grid.Column>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                largeScreen={8}
              ></Grid.Column>
            </GridRow>

            <GridRow>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
              >
                {invalidItem && (
                  <Message negative>
                    You do not have permission to access this item!
                  </Message>
                )}
                {itemLoaded && (!item.name || !item) && (
                  <Message negative>
                    You do not have permission to access this item or the file
                    doesn't exist!
                  </Message>
                )}
                {itemIsLoading && (
                  <>
                    <Icon loading name="spinner" /> Loading details!
                  </>
                )}
                {itemLoaded && item && item.name && (
                  <DownloadFriendlyItem item={item} cancel={goToProducts} />
                )}
              </Grid.Column>
            </GridRow>
          </Grid>
        </Segment>
      </div>
    </>
  );
};
export default FriendlyDownload;
