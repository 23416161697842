import { identity, pickBy, reduce as _reduce, trim, isEmpty } from "lodash";
import LocalStore from "../../store/persistence/local-storage";
import Constants from "../../config/constants";
import Api from "@api";

const activePageToPageIndex = (activePage) => {
  return activePage - 1; // index always starts from 0 and goes to n-1.
};

const removeEmptyKeys = (args) => {
  return pickBy(args, identity);
};

const validateDomain = (domains) => {
  if (!domains) {
    return true;
  }
  const regex = /^(?!-)[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*[.][A-Za-z]{2,6}$/;
  const domainsArr = domains.split(",");
  const is_valid = domainsArr.every((domain) => {
    return regex.test(domain.trim());
  });
  return is_valid;
};

const parameterStringify = (urlParams) => {
  // remove any empty key from the object. Any such key which doesn't have any value.
  urlParams = pickBy(urlParams, identity);
  // Browsers standard.
  const parameterSeparator = "&";
  const urlParameters = _reduce(
    urlParams,
    (result, val, key) => {
      result += `${key}=${val}${parameterSeparator}`;
      return result;
    },
    ""
  );
  // there should be no `&` in start or end of parameter string.
  // So trim it.
  return trim(urlParameters, parameterSeparator);
};

const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

const isVideo = (type) => {
  return (
    type &&
    (type.indexOf("mp4") !== -1 ||
      type.indexOf("mov") !== -1 ||
      type.indexOf("mpeg") !== -1 ||
      type.indexOf("flv") !== -1 ||
      type.indexOf("webm") !== -1)
  );
};
// check if string is more than given characters long then trim it and add ... at the end and return it.
const trimString = (str, length) => {
  if (str && str.length > length) {
    return str.substring(0, length) + "...";
  }
  return str;
};

const redirectToHelpdeskURL = (url, dontRedirect) => {
  const isLoggedInHelpdesk = LocalStore.getValue("loggedInToHelpDesk");
  const redirectUrl = isLoggedInHelpdesk
    ? Constants.App.ZENDESK_REDIRECT_URL
    : Constants.App.ZENDESK_REDIRECT_URL_SYNCUSER;
  return Api.AxiosInstance.getInstance()
    .get(`${redirectUrl}${url}`)
    .then((resp) => {
      const url = resp.data.url;
      if (dontRedirect) {
        return url;
      }
      if (url) {
        LocalStore.setValue("loggedInToHelpDesk", true);
        window.location.href = url;
      }
    });
};

// find a word in a string and check if string has more characters after or before the word.
// if string has given length of characters before the word then trim the string backword from the word to given length of characters and add ... in the end and return it.
// if string has given length of characters after the word then trim the string forward from the word to given length of characters and add ... in the end and return it.
const trimStringWithWord = (str, word, length) => {
  if (isEmpty(str)) { 
    return "N/A";
  }
  str = str.replace(/\s+/g, " ").trim();
  if (str && str.length > length) {
    const index = str.toLowerCase().indexOf(word.toLowerCase());
    if (index !== -1) {
      // if index is 0 then it means that word is at the start of the string.
      // if index is str.length - word.length then it means that word is at the end of the string.
      // if index is in between then it means that word is in the middle of the string.
      if (index === 0) {
        str = str.substring(0, length);
        return str.slice(0, Math.min(str.length, str.lastIndexOf(" "))) + "...";
      } else if (index === str.length - word.length) {
        str = str.substring(str.length - length);
        return "..." + str.substr(str.indexOf(" ") + 1);
      }
      // check if word is in the middle of the string.
      // if word is in the middle of the string then trim the string from the word to given length characters and add ... in the end and return it.
      else if (index > 0 && index < str.length - word.length) {
        str = str.substring(index - length / 2, index + length / 2);
        str = "..." + str.substr(str.indexOf(" ") + 1);
        return str.slice(0, Math.min(str.length, str.lastIndexOf(" "))) + "...";
      }
    } else {
      return str.substring(0, length) + "...";
    }
  } else if (str.length === length) {
    return str;
  } else {
    return str;
  }
};
// Check if url is valid or not using regex expression.
const validateUrl = (url) => {
  const regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regex.test(url);
};

export {
  redirectToHelpdeskURL,
  activePageToPageIndex,
  create_UUID,
  parameterStringify,
  removeEmptyKeys,
  isVideo,
  trimString,
  validateDomain,
  trimStringWithWord,
  validateUrl,
};
