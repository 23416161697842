import FileIcon from "@/common/file-icon";
import Api from "@api";
import Constants from "@constants";
import equal from "fast-deep-equal";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { List, Pagination, Segment } from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";
import { trimStringWithWord } from "../../../common/utils/utils";

class Downloads extends Component {
  columns = [
    {
      name: "Name",
      key: "name",
      width: "3",
    },
    {
      name: "Description",
      key: "description",
      width: "3",
    },

    {
      name: "Parent Name",
      key: "parentName",
      width: "2",
      class: "center",
    },
    { name: "Action", key: "actions", width: "3" },
  ];
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listings: {
        content: [],
        number: 0,
      },
      pageNo: 0,
      pageSize: 10,
      totalRecords: 0,
      data: {},
    };
  }
  componentDidMount() {
    this.searchResults(
      this.props.keyword ? this.props.keyword : this.props.searchKeyword,
      this.props.sort
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      !equal(this.props.keyword, nextProps.keyword) ||
      nextProps.sort.sortBy !== this.props.sort.sortBy ||
      nextProps.sort.sortOrder !== this.props.sort.sortOrder
    ) {
      this.setState(
        {
          sort: nextProps.data,
        },
        () => {
          this.searchResults(
            nextProps.keyword ? nextProps.keyword : nextProps.searchKeyword,
            nextProps.sort
          );
        }
      );
    }
  }

  searchResults = (keyword, sort) => {
    let getKeyword = keyword;
    if (!keyword) {
      getKeyword = this.props.location.pathname.split("/")[2];
    }
    const { URL_GLOBAL_SEARCH } = Constants.App;
    let params = {
      params: {
        query: keyword,
        ...sort,
        pageNo: this.state.pageNo,
        pageSize: this.state.pageSize,
      },
    };
    this.setState({ loading: true }, () => {
      Api.AxiosInstance.getInstance()
        .get(`${URL_GLOBAL_SEARCH}items,products`, params)
        .then((resp) => {
          this.setState(
            {
              data: resp.data.hits.hits,
              loading: false,
              totalRecords: resp.data.hits.totalHits,
            },
            this.updateData
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    });
  };
  updateData = () => {
    const { data } = this.state;
    if (data) {
      this.setState({
        // ...this.state,
        listings: this.transformData(data),
      });
    }
  };

  download = (e, id) => {
    Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ITEM_DOWNLOAD + "?itemId=" + id, { timeout: 0 })
      .then((resp) => {
        let win = window.open(resp.data, "_blank");
        win.focus();
      })
      .catch((err) =>
        this.setState({
          showError: true,
          errorMsg: "An error occured while downloading the product.",
        })
      );
  };
  transformData = (data) => {
    let content = [];
    if (data) {
      data.map((el) => {
        let row = Object.assign({}, el);
        row.header = row.name;
        row.description = row.description;
        row.href = `/#/downloads/browser/${
          row.sourceAsMap.parentProductId
            ? row.sourceAsMap.parentProductId
            : row.sourceAsMap.parentId
        }`;
        row.actions = (
          <React.Fragment>
            <Link to={`/downloads/browser/${row.id}`}>
              {" "}
              <span class="icon-search" title="View"></span>
            </Link>
          </React.Fragment>
        );
        row.modification_date = (
          <Moment format="MM/DD/YYYY hh:mm a">{row.modification_date}</Moment>
        );
        row.item_type = (
          <>
            {" "}
            <FileIcon
              filename={row.filename ? row.filename : "other"}
              size="large"
            ></FileIcon>{" "}
          </>
        );
        content.push(row);
      });
    }
    data.content = content;
    return data;
  };
  parseHighlighter = (content) => {
    if (content === null) {
      return <i>None</i>;
    }

    let highlightOpener = "<mark>";
    let highlightCloser = "</mark>";
    let parts = content.split(highlightOpener);
    let newContent = [];
    newContent.push(parts.shift().replace(highlightCloser));
    parts.map((el) => {
      let closeBreak = el.split(highlightCloser);
      newContent.push(<mark>{closeBreak.shift()}</mark>);
      newContent.push(closeBreak.pop());
    });

    this.setState({ highlight: true });
    return newContent;
  };
  onPageChange = (e, { activePage }) => {
    this.setState(
      {
        pageNo: activePage - 1,
      },
      () => {
        this.searchResults(
          this.props.keyword || this.props.searchKeyword,
          this.props.sort
        );
      }
    );
  };
  render() {
    console.log(
      this.state.pageNo,
      this.state.pageSize,
      this.state.totalRecords
    );
    return (
      <Segment className="m-0 p-0" loading={this.state.loading}>
        {this.state.listings.content.length > 0 ? (
          <>
            <List celled className="search-result-wrapper">
              {this.state.listings.content.map((data, index) => (
                <List.Item className="search-item-row">
                  <List.Content>
                    <List.Header
                      as="a"
                      href={data.href}
                      className="search-heading"
                    >
                      {data.sourceAsMap.name}
                      {'    '}
                    </List.Header>
                   
                    <List.Description className="s-des-wrapper">
                      <p className="des-p1">
                        {' '}
                        {data.sourceAsMap.creationDate ? (
                          <>{dateToPacific(data.creationDate, 'MM/DD/YYYY')}</>
                        ) : (
                          'N/A'
                        )}{' '}
                        --{' '}
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[
                            this.props.keyword
                              ? this.props.keyword
                              : this.props.searchKeyword,
                          ]}
                          autoEscape={true}
                          textToHighlight={
                            data.sourceAsMap.content
                              ? trimStringWithWord(
                                  data.sourceAsMap.content,
                                  this.props.searchKeyword ||
                                    this.props.keyword,
                                  120
                                )
                              : trimStringWithWord(
                                  data.sourceAsMap.description,
                                  this.props.searchKeyword ||
                                    this.props.keyword,
                                  120
                                )
                          }
                        />{' '}
                      </p>
                    </List.Description>
                    <span className="des-p2">
                      {' '}
                      {window.location.origin}
                      {data.href}
                    </span>
                  </List.Content>
                </List.Item>
              ))}
            </List>
            <Pagination
              activePage={this.state.pageNo + 1}
              onPageChange={this.onPageChange}
              totalPages={Math.ceil(this.state.totalRecords / 10)}
            />
            {this.state.listings.content.length > 0 ? '' : 'No record found'}
          </>
        ) : (
          'No record found'
        )}
      </Segment>
    );
  }
}

export default Downloads;
