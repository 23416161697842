import update from "immutability-helper";
import { merge } from "lodash";
import PropTypes from "prop-types";
import {dateToPacific} from "@/utils/dateTime.js";
import Api from "../../api";
import Constants from '@/config/constants';
import React from 'react';
import { connect } from "react-redux";
import { InputField, ErrorMessage } from '@/common/Form';
import { removeEmptyKeys, validateDomain } from '@/common/utils/utils';
import {
  Button,
  Checkbox,
  Confirm,
  Form,
  Grid,
  Segment,
  Modal,
  Tab,
  Divider,
  Popup,
  Icon, Input,
} from 'semantic-ui-react';
import { validate } from 'validate.js';
import { StatusField } from "@/common/Form";
import DropdownField from "../Form/subcomponents/dropdown-field";
import LocalStore from "@/store/persistence/local-storage";
import Config from "@api/common/config";

class GroupCreation extends React.Component {

normalValidation = {
    name: {
      presence: true,
      length: {
        maximum: 32
      }
    },
    description: {
      presence: false,
      length: {
        maximum: 50,
      },
    },
  };
  constructor(props) {
    super(props);
    this.state = {
      groupsList: {},
      configOption: {},

      configOptionOld: {
        search_in: "",
        keyword: "",
        page: 0,
      },
      selectedStatedGroups: [],
      selectedGroupsIndexes: [],
      checkedGroupsIds: [],
      isDescSearch: true,
      sortBy: "",
      name: null,
      description: null,
      domain: null,
      watermark: 1,
      dateStamp: true,
      community_feature_enabled: 1,
      tech_support: 'ENABLED',
      tier1: 1,
      group_type: 'CUSTOMER',
      status: 'ACTIVE',
      created_by: null,
      creation_date: null,
      modification_date: null,
      modified_by: null,
      msgSpanText: '',
      msgSpanColor: 'black',
      msgSpanTextAssign: '',
      msgSpanColorAssign: 'black',
      selectedUsers: null,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: '',
      usersPageNum: 0,
      userId: null,
      deleteWarning: false,
      cancelButton: 'Cancel',
      showDomainError: false,
      groupName: null,
      ndaInformation: false,
    };
    
  }

  componentDidMount() {
    //this.fetchGroups();
  }
  loadConfigOptions = () => {
    const token = LocalStore.getValue("token");
    this.props.loadApplicationConfig(token);
  };
  
  save = () => {
    debugger;
    const {
      name,
      description,
      community_feature_enabled,
      tier1,
      group_type,
      status,
      createdBy,
      tech_support,
      creationDate,
      modificationDate,
      modifiedBy,
      domain,
      watermark,
      dateStamp,
      line1,
      line2,
      line3,
    } = this.state;
    let data = {
      name,
      description,
      communityFeatureEnabled: community_feature_enabled,
      techSupport: tech_support === 'ENABLED' ? 1 : 0,
      tier1,
      groupType: group_type,
      status,
      createdBy,
      creationDate,
      modificationDate,
      modifiedBy,
      domain,
      watermark: watermark ? 1 : 0,
      dateStamp: dateStamp ? 1 : 0,
      line1,
      line2,
      line3,
    };

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_ADD_GROUP, data)
      .then((resp) => {
        let msg = '';
        let color = 'black';
        if (resp.data.id > 0) {
          this.props.onCreateGroup([resp.data])
          msg = 'Added new group';
          color = 'green';
        } else {
          msg = 'Request failed, Please try later.';
          color = 'red';
        }

        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
        });
        this.loadConfigOptions();
      }).catch((e, d) => {
          console.log(e);
          let msg = e.response.data.message;
          let color = 'red';
          this.setState({
            msgSpanText: msg,
            msgSpanColor: color,
            loading: false,
          });
        });
  };
  validateForm = (event) => {
    debugger;
    let result;
    result = validate(removeEmptyKeys(this.state), this.normalValidation);
    const invalid_domain = !validateDomain(this.state.domain);
    if (!result && !invalid_domain) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: {...result, domain: invalid_domain ? "Please enter a valid domain." : null},
        msgSpanText:
          'There are some errors with the submission. Please fix and retry.',
        msgSpanColor: 'red',
      });
    }
  };
  hideDomainError = (cb) => {
    this.setState(
      {
        showDomainError: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  removeDomain = () => {
    const { domain } = this.state;
    let params = {
      params: {
        domain,
      },
    };
    if (domain) {
      return Api.AxiosInstance.getInstance()
        .get('/admin/group/remove-domain', params)
        .then((resp) => {
          if (resp.data) {
            this.setState({
              showDomainError: false,
              groupName: null,
            });
          }
        })
        .catch((resp) => {
          console.log('error');
        });
    }
    return;
  };
  checkDomainAvailability = () => {
    const { domain } = this.state;
    let params = {
      params: {
        domain,
      },
    };
    if (domain) {
      return Api.AxiosInstance.getInstance()
        .get('/admin/group/check-domain', params)
        .then((resp) => {
          console.log('safs', resp);
          if (resp.data) {
            this.setState({
              showDomainError: true,
              groupName: resp.data.name,
            });
          } else
            this.setState({
              showDomainError: false,
              groupName: null,
            });
        })
        .catch((resp) => {
          console.log('error');
        });
    }
    return;
  };

  handleDomainChange = (e) => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    }, () => {
      const validated = validateDomain(value);
      this.setState({
        validationMsg: {domain: !validated ? "Please enter a valid domain." : null}
      })
    });
  };

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  };

  onChange = (event, { name, value }) => {
      this.setState({ [name]: value });
  };

  handleStatusChange = (e) => {
    this.setState({
      status: e.target.value,
    });
  };

  handleTechSupportChange = (e) => {
    this.setState({
      tech_support: e.target.value,
    });
  };


  onModalClose = () => {
    // this.resetSearchConfig();
    const { onDismiss } = this.props;
    if (onDismiss) onDismiss();
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  render() {
    const { 
      selectedStatedGroups,
      } = this.state;
    const { onSave, show } = this.props;

    return (
      <Modal
        open={show}
        //onMount={this.fetchGroups}
        onClose={this.onModalClose}
        centered={true}
        size="large"
      >
        <Modal.Header>
          <div className="side-marker" />
          <div className="header"> Create Group</div>
        </Modal.Header>

        <Modal.Content>
        <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Name"
                    errorMsg={this.state.validationMsg.name}
                    isError={this.state.validationMsg.name}
                    name="name"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    maxlength={32}
                    isRequired
                    placeholder="Enter Group Name"
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Description"
                    errorMsg={this.state.validationMsg.description}
                    isError={this.state.validationMsg.description}
                    name="description"
                    id="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    maxlength={32}
                    placeholder="Enter Group Description"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <InputField
                    label="Domains (comma separated)"
                    errorMsg={this.state.validationMsg.domain}
                    isError={this.state.validationMsg.domain}
                    name="domain"
                    id="domain"
                    value={this.state.domain}
                    onChange={this.handleDomainChange}
                    maxlength={250}
                    placeholder="Domains (comma separated)"
                  />
                  {this.state.showDomainError && (
                    <>
                      <label color="red">
                        <em>
                          This domain is already Asssociated with other group{' '}
                        </em>{' '}
                      </label>
                      <Button onClick={(e) => this.removeDomain()}>
                        {' '}
                        Delete Domain from other Group?{' '}
                      </Button>
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <StatusField
                    value={this.state.status}
                    label="Status"
                    name="status"
                    onChange={this.onChange}
                    isRequired
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <DropdownField
                    value={this.state.tech_support}
                    label="Support"
                    name="tech_support"
                    onChange={this.onChange}
                    options ={[
                      { key: "ENABLED", value: "ENABLED", text: "Enabled" },
                      { key: "DISABLED", value: "DISABLED", text: "Disabled" }
                    ]}
                    isRequired
                />
                </Grid.Column>
              </Grid.Row>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="mt-2"
              >
                <button
                  className="button-primary m--no-margin-left-im"
                  onClick={() => {
                    this.validateForm()
                  }}
                >
                  Save
                </button>
                <button className="button-basic" onClick={this.onModalClose}>
                  Close
                </button>
              </Grid.Column>
            </Grid.Row>
            </Grid>
            <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
            </span>
            <Confirm
             open={this.state.popupWarning}
             onCancel={this.hidepopupWarning}
             onConfirm={() => this.hidepopupWarning(this.save)}
             content="Are you sure you want to add new group?"
            />
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

GroupCreation.propTypes = {
  /** Callback which is fired when Popup is closed. */
  onDismiss: PropTypes.func.isRequired,
  /** Callback which is fired when `resend` button is clicked. */
  // onSave: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
  /** single-select = radio buttons, multi-select = checkboxes */
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadApplicationConfig: (token) => {
      dispatch(Api.Config.loadApplicationConfig(token));
    },
  };
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupCreation);
