import React, { useEffect, useState } from "react";
import {
  Grid,
  Segment,
  GridRow,
  Button,
  Label,
  Form,
  Modal,
} from "semantic-ui-react";
import VideoPlayer from "react-video-js-player";
import Api from "@/api";
import Constants from "@/config/constants";
import { dateToPacific } from "@/utils/dateTime";
const { URL_STREAM_VIDEO } = Constants.App;

export default (props) => {

  const {
    match: { params },
  } = props;
  const [modalState, setModalState] = useState(false);
  const [videoId, setVideoId] = useState(params.id);
  const [videoURL, setVideoURL] = useState(null);
  const [videoInformation, setVideoInformation] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setVideoId(params.id);
    getVideoInformations(params.id);
  }, [params.id]);

  useEffect(() => {
    if (videoURL) {
      setModalState(true);
    }
  }, [videoURL]);

  const getVideoStreamLink = () => {
    setLoading(true);
    const params = { params: { id: videoId } };
    const instance = Api.AxiosInstance.getInstance();
    instance.get(URL_STREAM_VIDEO, params).then((resp) => {
      setLoading(false);
      setVideoURL(resp.data);
    });
  };

  const getVideoInformations = (id) => {
    setLoading(true);
    const params = { params: { id } };
    const instance = Api.AxiosInstance.getInstance();
    instance.get(`video/info`, params).then((resp) => {
      setLoading(false);
      setVideoInformation(resp.data);
    });
  };

  return (
    <div className="products-container">
      <Segment className="base-segment-wrapper" loading={loading}>
        <Grid>
          <GridRow className="products-header">
            <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
              <div className="side-marker" />
              <div className="header inline">Video</div>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={8}
              computer={8}
              largeScreen={8}
            ></Grid.Column>
          </GridRow>
          <GridRow>
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>
              <React.Fragment>
                <div className="base-segment-wrapper product-file-downlaod">
                  <div className="ui form mb-2-im">
                    <Form.Input label="Name">
                      <Label>
                        <b>{videoInformation && videoInformation.name}</b>
                      </Label>
                    </Form.Input>
                    <Form.Input label="Description">
                      <Label className="info-text-area">
                        {videoInformation && videoInformation.description}
                      </Label>
                    </Form.Input>
                    <Form.Input label="Updated on">
                      <Label>
                        <b>
                          {videoInformation &&
                            dateToPacific(
                              videoInformation.modificationDate,
                              "MM/DD/YYYY"
                            )}
                        </b>
                      </Label>
                    </Form.Input>
                  </div>
                </div>
                <div className="ui divider mt-1-im"></div>
                <Button
                  className="button-primary m--no-margin-left-im"
                  onClick={() => getVideoStreamLink()}
                >
                  Play
                </Button>
                <button
                  className="button-basic"
                  onClick={() => props.history.goBack()}
                >
                  Back
                </button>
              </React.Fragment>
            </Grid.Column>
          </GridRow>
        </Grid>
        <Modal onClose={() => setModalState(false)} open={modalState}>
          <Modal.Header>Video Name</Modal.Header>
          <Modal.Content>
            {" "}
            <VideoPlayer
              controls={true}
              src={videoURL}
              width="860"
              height="420"
            />
            <Grid>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="m--align-right"
              >
                <Button
                  className="button-primary mr-0-im mt-2-im "
                  onClick={() => setModalState(false)}
                >
                  Close
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </Segment>
    </div>
  );
};
