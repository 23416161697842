import React from "react";
import AccessReportsListing from "../components/access-reports-listing";
import urls from "@/config/constants";

const AccessReport = () => {
  const { URL_ACCESS_REPORT_LISTING } = urls.App;
  return (
    <AccessReportsListing
      title="Access Report"
      fetchUrl={URL_ACCESS_REPORT_LISTING}
    />
  );
};

export default AccessReport;
