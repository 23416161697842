const URL_BASE = '/downloads';
const URL_OLD_BASE = '/products';
const URL_BROWSER = URL_BASE+'/browser';
const URL_PACKAGES = URL_BASE + '/packages';
const URL_SEARCH = URL_BASE + '/search';
const URL_PACKAGES_EDIT = URL_PACKAGES + "/:packageId/edit";

export default {
  URL_BASE,
  URL_OLD_BASE,
  URL_BROWSER,
  URL_PACKAGES,
  URL_SEARCH,
  URL_PACKAGES_EDIT
}
