import constants from "../constants";
import NotificationsApi from "../../../api/notifications";

export default {
  listNotifications: (page = 0, limit = 15, offset = 0) => async (dispatch) => {
    dispatch({ type: constants.ACTIONS.NOTIFICATIONS_LOADING });

    try {
      const response = await NotificationsApi.notificationsList(page, limit, offset);
      const { data } = response;

      dispatch({
        type: constants.ACTIONS.NOTIFICATIONS_LOAD_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: constants.ACTIONS.NOTIFICATIONS_LOAD_FAILED,
        errorMessage: error
      });
    }
  },
  countNotifications: (main = false) => async (dispatch) => {
    try {
      const response = await NotificationsApi.notificationsCount();
      const { data } = response;
      dispatch({
        type: constants.ACTIONS.NOTIFICATIONS_COUNT_UPDATE,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: constants.ACTIONS.NOTIFICATIONS_LOAD_FAILED,
        errorMessage: error
      });
    }
  }
};
