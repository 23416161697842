import Axios from "axios";
import Constants from "../../config/constants";
import actions from "../../store/actions";
import LocalStore from "../../store/persistence/local-storage";

export const getAllSearchIndices = () => async (dispatch) => {
  dispatch({
    type: actions.searchIndices.GET_ALL_INDEXES_INITIATE,
  });
  try {
    const token = LocalStore.getValue("token");
    const response = await Axios.get(
      `${Constants.App.API_URL}admin/settings/index/status`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    dispatch({
      type: actions.searchIndices.GET_ALL_INDEXES_SUCCESS,
      payload: response.data,
    });
  } catch (ex) {
    dispatch({
      type: actions.searchIndices.GET_ALL_INDEXES_FAILURE,
    });
  }
};

export const syncIndices = (type, index) => async (dispatch) => {
  let endPoint = `${Constants.App.API_URL}admin/settings/index/`;
  dispatch({
    type: actions.searchIndices.SYNC_INDICES_INITIATE,
    payload: index,
  });
  try {
    switch (type.toLowerCase()) {
      case "items":
        endPoint = `${endPoint}items`;
        break;
      case "articles":
        endPoint = `${endPoint}articles`;
        break;
      case "tickets":
        endPoint = `${endPoint}tickets`;
        break;
      case "projects":
        endPoint = `${endPoint}projects`;
        break;
      case "products":
        endPoint = `${endPoint}products`;
        break;
      case "videos":
        endPoint = `${endPoint}videos`;
        break;
      default:
        break;
    }
    const token = LocalStore.getValue("token");
    const response = await Axios.post(
      endPoint,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    dispatch({
      type: actions.searchIndices.SYNC_INDICES_SUCCESS,
      payload: response.data,
    });
    dispatch(getAllSearchIndices());
  } catch (ex) {
    dispatch({
      type: actions.searchIndices.SYNC_INDICES_FAILURE,
    });
  }
};
