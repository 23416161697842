const normalValidation = {
  recipients: {
    presence: true,
    length: {
      minimum: 1,
    }
  },
  subject: {
    presence: true,
  },
  description: {
    presence: true,
    length: {
      maximum: 1000,
    },
  },
  availableDate: {
    presence: true,
  },
  expireType: {
    presence: true,
  },
  expireValue: {
    presence: true,
  },
};

const extendedValidation = Object.assign({
  line1: {
    presence: true,
  }
}, normalValidation);

export {
  normalValidation,
  extendedValidation,
};