import React from "react";
import BriefcaseListing from "../components/briefcase-listing";
import urls from "../../../config/constants";

const COLUMNS = [
  {
    name: "Status",
    key: "recipients.0.status",
    sortKey: "status",
    showTooltip: true,
  },
  {
    name: "To",
    key: "recipients.0.sentTo",
    sortKey: "user1.username",
    showTooltip: true,
  },
  {
    name: "Subject",
    key: "subject",
    width: "3",
    sortKey: "briefcaseItem.subject",
    showTooltip: true,
  },
  {
    name: "File Name",
    key: "filename",
    width: "4",
    sortKey: "briefcaseItem.filename",
    showTooltip: true,
  },
  {
    name: "Date",
    key: "sendDate",
    width: "2",
    sortKey: "briefcaseItem.sendDate",
    showTooltip: true,
  },
  {
    name: "Expiry Date",
    key: "expireDate",
    sortKey: "BriefcaseItem.expireDate",
    width: "2",
    showTooltip: true,
  },
  { name: "Type", key: "fileType", class: "center", showTooltip: false },
  { name: "Actions", key: "actionButtons", class: "center" },
];
const { URL_BRIEFCASE_SENT_FILES } = urls.App;

const BriefcaseOutbox = ({ match }) => {
  let isAdmin = match.path.indexOf(":id") > 0;
  let paginate = match.isExact;

  return (
    <BriefcaseListing
      urlParam={match.params}
      columns={COLUMNS}
      isAdmin={isAdmin}
      fetchUrl={URL_BRIEFCASE_SENT_FILES}
      title="Sent Files"
      paginate={paginate}
      referer="briefcase_send"
    />
  );
};

export default BriefcaseOutbox;
