const text = `
<h5>
  Below is a list of the functionality and resources accessible to you on this website.
</h5>
<ul class="welcome-info">
<li>
  <b>HOME</b> - <p>View top articles, recent downloads, received files and your technical requests.</p>
</li>
<li>
  <b>DOWNLOADS</b> - <p>Browse, search, and download confidential documentation and software for Groq products.</p>
</li>
<li>
  <b>SUPPORT</b> - <p>Browse available knowledge base articles for getting started with Groq products and software.</p>
</li>
<li>
  <b>PROJECTS</b> - <p>Create your own project page to collaborate and share information with your colleagues and Groq personnel.</p>
</li>
<li>
  <b>VIDEOS</b> - <p>Watch on demand video trainings to jumpstart your project with Groq products.</p>
</li>
<li>
  <b>BRIEFCASE</b> - <p>Securely exchange files with Groq personnel.</p>
</li>
<li>
  <b>MY ACTIVITIES</b> - <p>Submit or update your technical requests. View the site help page. Access your profile where you can update your information, delete your user account, view your documents and passwords. You can also sign out here when you are done. </p>.
</li>

</ul>
<p class="label-bottom">We are excited to have you become a part of the Groq community and looking forward to working with you. </p>
`;

export default text;

