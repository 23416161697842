import React from "react";
import AdminView from "./admin-view";
import NdaCustomer from "./nda-customer-view";
import NonNdaCustomer from "./non-nda-customer-view";
import EmployeeView from "./employee-view";
import ApproverView from "./approver-view";
import Api from "@/api";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGdprAgreed: 0,
      isCcpaAgreed: 0,
      showGdpr: false,
      showCcpa: false,
    };
  }
  componentDidMount() {
    const { userId } = this.props;
    //   setTimeout(() => {
    //     this.checkGdprUpdate(userId);
    //   }, 500);
    //   this.checkCcpaUpdate();
    //
  }
  checkGdprUpdate = (userId) => {
    // this.setState({ loading: true });
    //  const { userId } = this.props;
    // let params = {
    //   params: {
    //     userid: userId,
    //   },
    // };
    let params = {
      params: {
        userid: userId,
      },
    };
    if (userId) {
      Api.AxiosInstance.getInstance()
        .get("/profile/latest_gdpr_agreed", params)
        .then((resp) => {
          this.setState({
            isGdprAgreed: resp.data,
            showGdpr: !resp.data,
          });
        })
        .catch((err) => this.setState({ loading: false }));
    }
  };
  checkCcpaUpdate = () => {
    const { userId } = this.props;
    const { ccpaCheck } = this.state;

    let params = {
      params: {
        userid: userId,
        agreement: ccpaCheck,
      },
    };

    Api.AxiosInstance.getInstance()
      .get("/profile/latest_ccpa_agreed", params)
      .then((resp) => {
        this.setState({
          isCcpaAgreed: resp.data,
          showCcpa: !resp.data,
          // loading: false,
        });
      })
      .catch((err) => this.setState({ loading: false }));
  };

  setGdprCheck = () => {
    this.setState(
      {
        gdprCheck: 1,
        showGdpr: false,
      },
      this.updateGdprCheck
    );
  };
  setCcpaCheck = () => {
    this.setState(
      {
        ccpaCheck: 1,
        showCcpa: false,
      },
      this.updateCcpaCheck
    );
  };
  updateGdprCheck = () => {
    const { userId } = this.props;
    const { gdprCheck } = this.state;

    let params = {
      params: {
        userid: userId,
        agreement: gdprCheck,
      },
    };
    Api.AxiosInstance.getInstance()
      .post("/profile/latest_gdpr_agreement_update", null, params)
      .then((resp) => {})
      .catch((err) => this.setState({ loading: false }));
  };
  updateCcpaCheck = () => {
    const { userId } = this.props;
    const { ccpaCheck } = this.state;

    let params = {
      params: {
        userid: userId,
        agreement: ccpaCheck,
      },
    };
    Api.AxiosInstance.getInstance()
      .post("/profile/latest_ccpa_agreement_update", null, params)
      .then((resp) => {})
      .catch((err) => this.setState({ loading: false }));
  };

  render() {
    const { authority } = this.props;
    switch (authority) {
      case "websilo-sysadmin":
        return <AdminView />;
      case "websilo-admin":
        return <AdminView />;
      case "websilo-customer":
        return <NdaCustomer {...this.state} {...this.props} />;
      case "websilo-employee":
        return <EmployeeView {...this.props} />;
      case "websilo-account-approver":
        return <ApproverView />;
      case "websilo-non-nda-customer":
        return <NonNdaCustomer authority={authority}/>;
      case "websilo-public-non-nda-customer":
        return <NonNdaCustomer authority={authority}/>;
      default:
        return <></>;
    }
  }
}

export default Dashboard;
