import SWEL from "./swel";
import { connect } from "react-redux";
import Config from "../../config/constants";

const mapDispatchToProps = dispatch => {
  return {};
};

const baseUrl = "/swel";

const mapStateToProps = state => ({
  baseUrl: baseUrl
});

const mappedSWEL = connect(mapStateToProps, mapDispatchToProps)(SWEL);

export default {
  name: "SWEL",
  configKey: "swel_menu_item_title",
  path: baseUrl,
  component: mappedSWEL,
  isPrivateRoute: true,
  accessRoles: [Config.AccessRole.SYSTEM_ADMIN]
};
