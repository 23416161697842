import React from "react";
import { withRouter } from "react-router-dom";
import { validate } from "validate.js";
import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Input,
  Label,
  Message,
  Segment,
  Grid,
  Confirm,
  Modal
} from "semantic-ui-react";
import Api from "../../../api";
import urls from "../../../config/constants";
import ErrorMessage from "@/common/Form/subcomponents/error-message";
import ReactPasswordStrength from "react-password-strength";
import { get } from "lodash";

class ChangePassword extends React.Component {
  VALIDATION_CONFIG = {
    oldPassword: {
      presence: true,
    },
    newPassword: {
      presence: true,
      length: {
        minimum: 5,
      },
    },
    confirmPassword: {
      presence: true,
      equality: "newPassword",
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      validationMsg: {},
      pdfPassword: "********",
      apiError: null,
      pdfPasswordLoaded: false,
      pdfPasswordSaved: false,
      responsePopup: false
    };
  }

  onFieldChange = (event, data) => {
    this.setState({ [data.name]: data.value });
  };

  validatePdfPassword = () => {
    const { pdfPassword } = this.state;
    const validationResult = validate(
      {
        pdfPassword: pdfPassword,
      },
      {
        pdfPassword: {
          presence: true,
          length: {
            minimum: 8,
          },
        },
      }
    );
    if (!validationResult) {
      this.setState({ pdfPasswordConfirm: true });
    } else {
      this.setState({ validationMsg: validationResult });
    }
  };

  getPdfPassword = (e) => {
    e.preventDefault();
    if (!this.state.pdfPasswordLoaded) {
      Api.AxiosInstance.getInstance()
        .get(urls.App.URL_PROFILE_CHANGE_PWD_PDF)
        .then((resp) => {
          this.setState({ pdfPasswordLoaded: true, pdfPassword: resp.data });
        })
        .catch((error) => {
          this.setState({ apiError: error.response.data.message });
        });
    } else {
      const passLength =
        this.state.pdfPassword && this.state.pdfPassword.lenth
          ? this.state.pdfPassword.lenth
          : 8;
      this.setState({
        pdfPasswordLoaded: false,
        // pdfPassword: "*".repeat(passLength),
      });
    }
  };

  updatePdfPassword = () => {
    const { pdfPassword } = this.state;
    Api.AxiosInstance.getInstance()
      .post(urls.App.URL_PROFILE_CHANGE_PWD_PDF, {
        pdfPassword: pdfPassword,
      })
      .then((resp) => {
        // this.props.history.push("/profile");
        this.setState({ pdfPasswordSaved: true });
      })
      .catch((error) => {
        this.setState({ apiError: error.response.data.message });
      });
  };

  validatePasswords = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const validationResult = validate(
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      },
      this.VALIDATION_CONFIG
    );
    if (!validationResult) {
      this.updatePassword();
    } else {
      this.setState({ validationMsg: validationResult });
    }
  };

  updatePassword = () => {
    const { oldPassword, newPassword } = this.state;
    Api.AxiosInstance.getInstance()
      .post(urls.App.URL_PROFILE_CHANGE_PWD, {
        oldPassword: oldPassword,
        newPassword: newPassword,
      })
      .then((resp) => {
        this.setState({ responsePopup: true });
        // this.props.history.push("/profile");
      })
      .catch((error) => {
        this.setState({ apiError: error.response.data.message });
      });
  };

  hidepdfPasswordConfirm = (cb) => {
    this.setState({ pdfPasswordConfirm: false }, () => {
      if (cb instanceof Function) cb();
    });
  };
  onPasswordChange = (passwordState, feedback) => {
    this.setState({
      passwordScore: { $set: get(feedback, ["score"]) },
      newPassword: passwordState.password,
    });
  };

  render() {
    const { pdfPassword, pdfPasswordLoaded, pdfPasswordSaved } = this.state;
    const {
      confirmPassword,
      newPassword,
      oldPassword,
    } = this.state.validationMsg;
    const { apiError } = this.state;

    return (
      <Segment className="base-segment-wrapper">
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="header">
                <div className="side-marker" />
                Passwords
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <span className="text-primary">
                <b>Account Password</b>
              </span>
              <hr />
              <br />

              <Form error={!!apiError}>
                <Message error header="Error" content={apiError} />



                <Grid >
                  <Grid.Row>


                 <Grid.Column mobile={16} tablet={5} computer={5} largeScreen={5}>
                <Form.Field required={true} error={!!oldPassword}>
                        <label>Old Password</label>
                        <Input
                          name="oldPassword"
                          type="password"
                          onChange={this.onFieldChange}
                        />
                        <ErrorMessage
                          isError={oldPassword}
                          errorMsg={oldPassword && oldPassword[0]}
                        />
                      </Form.Field>
 </Grid.Column>

    <Grid.Column mobile={16} tablet={5} computer={5} largeScreen={5}>
    <Form.Field
                        required={true}
                        width="16"
                        error={!!newPassword}
                      >
                        <label>New Password</label>
                        <ReactPasswordStrength
                          minLength={5}
                          minScore={2}
                          scoreWords={[
                            "weak",
                            "okay",
                            "good",
                            "strong",
                            "stronger",
                          ]}
                          changeCallback={this.onPasswordChange}
                          inputProps={{ autoComplete: "off" }}
                          userInputs={Object.values(
                            Object.assign({}, this.state, {
                              newPassword: null,
                              confirmPassword: null,
                            })
                          )}
                        />
                        <ErrorMessage
                          isError={newPassword}
                          errorMsg={newPassword && newPassword[0]}
                        />
                      </Form.Field>
 </Grid.Column>

     <Grid.Column mobile={16} tablet={5} computer={5} largeScreen={5}>
     <Form.Field required={true} error={confirmPassword}>
                        <label>Confirm New Password</label>
                        <Input
                          name="confirmPassword"
                          type="password"
                          onChange={this.onFieldChange}
                        />
                        <ErrorMessage
                          isError={confirmPassword}
                          errorMsg={confirmPassword && confirmPassword[0]}
                        />
                      </Form.Field>
 </Grid.Column>


                  </Grid.Row>
                </Grid>
              </Form>


              <Grid.Row className="common-filter-wrapper ui form">
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={8}
                  largeScreen={8}
                >
                 
                  <button
                    className="button-primary"
                    onClick={this.validatePasswords}
                  >
                    Change Password
                  </button>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <span className="text-primary">
                <b>Briefcase/PDF Password</b>
              </span>
              <hr />

              <Form>
                <Form.Field width="15">
                  <label>Briefcase/PDF Password</label>
                  <span className="small" style={{ fontSize: "12px" }}>
                    {/*<i>
                      Copy and paste this password into your downloaded PDF
                      file.
                    </i> */}
                    <i>
                      Copy and paste this password into your briefcase folder.
                    </i>
                  </span>
                  <Input
                    name="pdfPassword"
                    onChange={this.onFieldChange}
                    value={pdfPassword}
                    type={pdfPasswordLoaded ? "text" : "password"}
                  />
                  <ErrorMessage
                    isError={this.state.validationMsg.pdfPassword}
                    errorMsg={
                      this.state.validationMsg.pdfPassword &&
                      this.state.validationMsg.pdfPassword[0]
                    }
                  />

                  <span style={{ fontSize: "12px" }}>
                    <a href="#" onClick={this.getPdfPassword}>
                      {!pdfPasswordLoaded && "Show"}
                      {pdfPasswordLoaded && "Hide"} Briefcase/PDF Password
                    </a>
                  </span>

                  {/* <span style={{ fontSize: "12px" }}>
                    <i>
                      *Previously downloaded PDFs will be accessible with your
                      old password.
                    </i>
                  </span> */}
                </Form.Field>
                <br />
                <button
                  className="button-primary"
                  type="submit"
                  onClick={this.validatePdfPassword}
                >
                  Change Briefcase/PDF Password
                </button>
                {pdfPasswordSaved && (
                  <span style={{ fontSize: "12px", color: "green" }}>
                    &nbsp;&nbsp;Briefcase/PDF Password saved!
                  </span>
                )}
                <Confirm
                  open={this.state.pdfPasswordConfirm}
                  onCancel={this.hidepdfPasswordConfirm}
                  onConfirm={() =>
                    this.hidepdfPasswordConfirm(this.updatePdfPassword)
                  }
                  content="Are you sure you want to change your Briefcase/PDF password?"
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          onClose={() => this.setState({ responsePopup: false }, this.props.history.push("/profile"))}
          open={this.state.responsePopup}
          size='tiny'
          closeIcon >
          <Header>
            Update Password
          </Header>
          <Modal.Content>
            <p>
              Password has been changed successfully!
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ responsePopup: false }, this.props.history.push("/profile"))}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}

export default withRouter(ChangePassword);
