import React from "react";
import { Button, Icon, Image, Modal } from "semantic-ui-react";
import DocumentViewer from "./document-viewer";

const LicensePreview = ({
  cancel,
  licenseLink,
  isLicenseAttached
}) => {
  const [open, setOpen] = React.useState(isLicenseAttached);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      className="product-attactment-view-popup">

    <Modal.Header>Details</Modal.Header>
      <Modal.Content>
        <DocumentViewer licenseLink={licenseLink} /> :
      </Modal.Content>
      <Modal.Actions>
       
        <Button
          onClick={() => {
            setOpen(false);
            cancel('cancel')
          }}
        >
          Close
        </Button>
         <Button
          className="m-0"
          onClick={() => {
            setOpen(false);
            cancel('agree');
          }}
          primary
        >
        Agree
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LicensePreview;
