const CLIENT_ID = "0oa18hqjwpEjVlobc5d7";
const ISSUER = "https://groq.okta.com";
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = "http://localhost:3000/okta";

export const oktaobj = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: false,
    authParams: {
      issuer: ISSUER,
      responseType: ["id_token", "token"],
    },
  },
  resourceServer: {
    messagesUrl: "http://localhost:3000/api/messages",
  },
};