import React from "react";
import {
  Button,
  Confirm,
  Container,
  Dimmer,
  Header,
  Item,
  Loader,
  Modal,
  Segment,
} from "semantic-ui-react";
import { join } from "lodash";
import Api from "../../../api";
import urls from "../../../config/constants";

class ProjectInvites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmWarning: false,
      warningContent: "",
      warningAction: "",
      projectId: null,
    };
  }

  componentDidMount() {
    this.fetchInvites();
  }

  fetchInvites = () => {
    this.setState({ loading: true });
    const { URL_PROJECT_LIST_INVITATIONS } = urls.App;
    Api.AxiosInstance.getInstance()
      .get(URL_PROJECT_LIST_INVITATIONS)
      .then((resp) => {
        this.setState({
          projectsList: resp.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errorMsg: "Something went wrong while fetching invites.",
          showError: true,
        });
      });
  };

  askConfirmation = (id, action) => {
    let msg = "";
    if (action == "ACCEPT_INVITE") {
      msg = "Are you sure you want to accept this invite?";
    } else {
      msg = "Are you sure you want to reject this invite?";
    }
    this.setState({
      confirmWarning: true,
      warningContent: msg,
      warningAction: action,
      projectId: id,
    });
  };
  hideWarning = () => {
    this.setState({ confirmWarning: false, warningContent: "" });
  };

  proceed = () => {
    if (this.state.warningAction == "ACCEPT_INVITE") {
      this.acceptInvite(this.state.projectId);
    } else {
      this.rejectInvite(this.state.projectId);
    }

    this.setState({
      confirmWarning: false,
      warningContent: "",
      projectId: null,
      warningAction: "",
    });
  };

  acceptInvite = (id) => {
    this.setState({ loading: true });
    const { URL_PROJECT_ACCEPT_INVITATION } = urls.App;
    Api.AxiosInstance.getInstance()
      .post(`${URL_PROJECT_ACCEPT_INVITATION}?id=${id}`)
      .then(() => {
        this.setState({ loading: false }, () => {
          this.fetchInvites();
        });
      })
      .catch((err) =>
        this.setState({
          loading: false,
          errorMsg: "Something went wrong while accepting the invite.",
          showError: true,
        })
      );
  };

  rejectInvite = (id) => {
    this.setState({ loading: true });
    const { URL_PROJECT_LEAVE } = urls.App;
    Api.AxiosInstance.getInstance()
      .post(URL_PROJECT_LEAVE, { id })
      .then(() => {
        this.setState({ loading: false }, () => {
          this.fetchInvites();
        });
      })
      .catch((err) =>
        this.setState({
          loading: false,
          errorMsg: "Something went wrong while rejecting the invite.",
          showError: true,
        })
      );
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  render() {
    const {
      errorMsg,
      projectsList,
      showError,
      warningContent,
      confirmWarning,
    } = this.state;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header"> Project Invites </div>
          <Segment className="p-0 project-invites-block">
              {projectsList && (
                <Item.Group divided >
                  {projectsList.map((el, index) => (
                    <Item key={`item--${index}`} className="padding-1">
                      <Item.Content key={`item-content--${index}`}>
                        <Item.Header key={`item-header--${index}`} as="a">
                          {el.name}
                        </Item.Header>
                        <Item.Meta key={`item-meta--${index}`}>
                          <p key={`item-companies--${index}`}>
                            <b>Companies: </b>
                            {join(el.company, ", ") || "N/A"}
                          </p>
                          <p key={`item-groups--${index}`}>
                            <b>Groups: </b>
                            {join(el.groupNames, ", ") || "N/A"}
                          </p>
                        </Item.Meta>
                        <Item.Description
                          key={`item-description--${index}`}
                          className="word-break-all"
                        >
                          {el.description}
                        </Item.Description>
                        <Item.Extra key={`item-extra--${index}`}>
                          <Button
                            key={`item-accept--${index}`}
                            primary
                            size="mini"
                            onClick={() => {
                              this.askConfirmation(el.id, "ACCEPT_INVITE");
                            }}
                          >
                            Accept
                          </Button>
                          <Button
                            color="red"
                            size="mini"
                            key={`item-reject--${index}`}
                            onClick={() => {
                              this.askConfirmation(el.id, "REJECT_INVITE");
                            }}
                          >
                            Reject
                          </Button>
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                  ))}
                </Item.Group>
              )}
              {projectsList &&
                projectsList.length === 0 &&
                "No project invites to display."}
              {!projectsList && (
                <Dimmer active inverted>
                  <Loader />
                </Dimmer>
              )}
          
          </Segment>
        </Segment>
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
        <Confirm
          open={confirmWarning}
          onCancel={this.hideWarning}
          onConfirm={this.proceed}
          content={warningContent}
        />
      </React.Fragment>
    );
  }
}

ProjectInvites.propTypes = {};

export default ProjectInvites;
