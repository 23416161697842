import React from "react";
import { useSelector } from "react-redux";
import { Grid, Responsive, Segment } from "semantic-ui-react";
import BriefcaseInbox from "../briefcase/pages/briefcase-inbox";
import ReceivedFilesCard from "./components/received-files";
import RecentDownloads from "./components/recent-downloads";
import TopDownloads from "./components/top-downloads";
import WelcomePopup from "./components/welcome-popup";
import ZendeskTickets from "./components/zendesk-tickets";
import Articles from "./components/articles";
import RecentUploads from "./components/recent-uploads";
const EmployeeView = ({ userId }) => {
  const supportEnabled = useSelector(
    (state) => state.common.config.supportEnabled
  );
  return (
    <Segment basic className="mt-2-im app-dashboard-wrapper">
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={10} computer={8} largeScreen={8}>
            <TopDownloads />
            <RecentDownloads />
            {supportEnabled && (
              <>
                {" "}
                <ZendeskTickets />
              </>
            )}

            {/* <SearchLogs /> */}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
            {/* <ApproveUserDialog /> */}
            <RecentUploads />
            <Responsive minWidth={1024}>
              <BriefcaseInbox
                match={{ path: "", params: {}, paginate: false }}
              />
            </Responsive>
            <Responsive maxWidth={1023}>
              <ReceivedFilesCard />
            </Responsive>
            {supportEnabled && (
              <>
                <Articles />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <WelcomePopup value="employee-popup" userId={userId} />
    </Segment>
  );
};

export default EmployeeView;
