import React, { createRef } from "react";
import PropTypes from "prop-types";
import { Button, Container, Form, Grid, Modal } from "semantic-ui-react";
import update from "immutability-helper";
import UserListing from "./user-listing";
import { ErrorMessage } from "@/common/Form";

class UserSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIds: [],
      recipients: props.initialValue ? props.initialValue : [],
      showUsersListing: false,
      errorMsg: "",
      showError: false,
    };
  }

  componentDidMount() {
      this.setState({
          selectRef: createRef()
      })
  }

  onSelectionChange = (event, t) => {
    const selections = this.state.selectRef.current.selectedOptions;
    const selectionCount = selections.length;
    const selectedIds = [];

    if (selectionCount) {
        for (let index = 0 ; index < selectionCount ; index ++){
            selectedIds.push(selections[index].value);
        }
    }

    this.setState({ selectedIds });
  };

  onRemoveUser = (event) => {

    const { recipients, selectedIds } = this.state;
    const newRecipients = recipients.filter((recipient) => selectedIds.indexOf(recipient.id.toString()) === -1);

    if (selectedIds.length === 0 || recipients.length === newRecipients.length) {
      this.setState({ errorMsg: "No data was selected.", showError: true });
      return;
    }
    this.setState(
      {
          recipients: update(recipients, { $set: newRecipients }),
      },
      this.notifyLatestContent
    );
  };
  closeErrorPopup = () => {
    this.setState({ showError: false });
  };
  onSelectUser = () => {
    this.setState({ showUsersListing: true });
  };

  onDismissUserListing = () => {
    this.setState({ showUsersListing: false });
  };

  onSaveUserListing = (users) => {
    const { recipients } = this.state;
    this.setState(
      {
        showUsersListing: false,
        recipients: update(recipients, { $push: users }),
      },
      this.notifyLatestContent
    );
  };

  notifyLatestContent = () => {
    const { onChange } = this.props;
    const { recipients } = this.state;
    if (onChange instanceof Function) {
      onChange(recipients);
    }
  };

  render() {
    const { recipients, showUsersListing, showError, errorMsg } = this.state;
    const {
      label,
      isRequired,
      selectionType,
      entityId,
      entity,
      role,
    } = this.props;
    // console.log("proos", this.props);
    return (
      <React.Fragment>
        <row>
          <Form>
            <Form.Field required={isRequired}>
              {label && <label>{label}</label>}
              <select
                size={3}
                onChange={this.onSelectionChange}
                multiple={true}
                className="multi-select"
                ref={this.state.selectRef}
              >
                {recipients.map((rec) => (
                  <option key={rec.id} value={rec.id}>
                    {rec.username}
                  </option>
                ))}
              </select>
              <ErrorMessage
                isError={this.props.isError}
                errorMsg={this.props.errorMsg}
              />
            </Form.Field>

            <Grid>
              <Grid.Row className="">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                >
                  <div className="common-filter-wrapper ui form">
                    <button
                      className="button-basic mb-1"
                      onClick={this.onSelectUser}
                    >
                      Select Users
                    </button>
                    <button
                      className="button-basic mr-0-im mb-1"
                      onClick={this.onRemoveUser}
                    >
                      Remove Selected Users
                    </button>{" "}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <UserListing
            show={showUsersListing}
            onDismiss={this.onDismissUserListing}
            onSave={this.onSaveUserListing}
            selectionType={selectionType}
            entity={entity}
            entityId={entityId}
            role={role}
          />
        </row>
        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

UserSelection.propTypes = {
  /** Text label */
  label: PropTypes.string,
  /** Shows the red asteric in label text */
  isRequired: PropTypes.bool,
  /** A callback which is fired whenever the selection is changed. */
  onChange: PropTypes.func.isRequired,
  /** single-select = radio buttons, multi-select = checkboxes */
  selectionType: PropTypes.oneOf(["single-select", "multi-select"]),
};

export default UserSelection;
