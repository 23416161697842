import Api from "@/api";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import ProductConstants from "@/modules/products/constants";
import update from "immutability-helper";
import { isEmpty } from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, Message, Confirm } from "semantic-ui-react";
import validate from "validate.js";
import PackageForm from "./../../components/package-detail";

class PackageAdd extends React.Component {
  VALIDATION_CONFIG = {
    status: {
      presence: true,
    },
    name: {
      presence: true,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: {
        status: "ACTIVE",
        description: null,
        name: null,
      },
      redirectTo: false,
      isSaving: false,
      validationMsg: {},
      showError: false,
      errorMsg: null,
      name: null,
      description: null,
      status: "ACTIVE",
      popupWarning: false,
      publicPackage: false
    };
  }

  componentDidMount() {}

  onChange = (event, eventData) => {
    const { data } = this.state;
    const { name, value } = eventData;
    this.setState({ data: update(data, { [name]: { $set: value } }) });
  };

  redirectToParent = () => {
    // Temporary hack to redirect to parent need to send baseUrl via props
    this.setState({
      redirectTo: ProductConstants.URL_PACKAGES,
    });
  };

  validate = () => {
    let result = validate(
      removeEmptyKeys(this.state.data),
      this.VALIDATION_CONFIG
    );
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };

  showpopupWarning = () => {
    if (!this.validate()) return;
    this.setState(
      {
        popupWarning: true,
      },);
  }

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  saveDetails = () => {
    if (!this.validate()) return;
    let url = Constants.App.URL_PACKAGE_NEW;
    const { data } = this.state;
    this.setState({ isSaving: true });
    const instance = Api.AxiosInstance.getInstance();
    instance
      .post(url, data)
      .then((resp) => {
        // console.log('resp', resp);
        this.setState({ isSaving: false }, () => {
          this.redirectToParent();
        });
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ isSaving: false, showError: true, errorMsg });
      });
  };

  render() {
    const { data, validationMsg, isSaving, showError, errorMsg } = this.state;
    return (
      <>
        <Form>
          <PackageForm
            onChange={this.onChange}
            name={data.name}
            description={data.description}
            status={data.status}
            editMode={true}
            validationMsg={validationMsg}
            publicPackage={data.publicPackage}
          />
          <div className="empty-height"></div>
          {showError && <Message error content={errorMsg} />}
          <div className="row">
            <Button
              type="submit"
              disabled={isSaving}
              onClick={this.showpopupWarning}
              primary
            >
              {" "}
              Save
            </Button>
            <Button
              type="reset"
              disabled={isSaving}
              onClick={(e) => {
                e.preventDefault();
                this.redirectToParent();
              }}
            >
              {" "}
              Cancel
            </Button>
          </div>
          <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.saveDetails)}
          content="Are you sure you want to add new package?"
          />
        </Form>
        {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
      </>
    );
  }
}
export default PackageAdd;
