import React from "react";
import {
  Divider,
  Segment,
  Responsive,
  Accordion,
  Icon,
} from "semantic-ui-react";
import { dateToPacific } from "@/utils/dateTime";
import Tableview from "@/common/tableview";
import { Cardview } from "@/common/cardview";
import Api from "@/api";
import Constants from "@/config/constants";
import { merge } from "lodash";
import FileIcon from "@/common/file-icon";
import MultiDownloadOpts from "../../../common/multiple-download-opts";

class RecentDownloads extends React.Component {
  COLUMNS = [
    { name: "Username", key: "user.username", showTooltip: true },
    { name: "Product Name", key: "prd_name", showTooltip: true },
    { name: "Item Name", key: "item_name", showTooltip: true },
    {
      name: "File Type",
      key: "file_type",
      class: "center",
      showTooltip: false,
    },
    { name: "Download Time", key: "downloadTime", width: 6, showTooltip: true },
  ];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: { content: [] },
      isCollapsed: true,
      isModalOpen: false,
      selectedFile: {},
    };
  }

  componentDidMount() {
    this.load();
  }

  update = (options) => {
    if (options.paginate) {
      this.load({ params: { page: options.page - 1 } });
    }
  };

  load = (options) => {
    options = merge({}, options ? options : {});
    this.setState({ loading: true });
    return Api.AxiosInstance.paginate(
      Constants.App.URL_RECENT_DOWNLOADS,
      options
    )
      .then((resp) => {
        this.setState({
          data: resp.data,
          loading: false,
        });
      })
      .catch((err) => this.setState({ loading: false }));
  };

  download = (item) => {
    this.fetchToken(item);
    this.proceedToDownloadEvent(item);
    this.setState({ isModalOpen: true });
  };
  transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    transformedData.content.map((el) => {
      content.push({
        user: el.user,
        prd_name: el.prd_name,
        file_type: (
          <FileIcon
            filename={el.filename ? el.filename : "other"}
            mimetype={el.file_type}
          ></FileIcon>
        ),
        downloadTime: (
          <>{dateToPacific(el.downloadTime, "MM/DD/YYYY hh:mm a")}</>
        ),
        item_name: (
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => this.download(el)}
          >
            {el.item_name}
          </div>
        ),
      });
    });
    transformedData.content = content;
    return transformedData;
  };

  fetchToken = (item) => {
    const instance = Api.AxiosInstance.getInstance();
    instance.get("/items/userToken").then((resp) => {
      let command = `${Constants.App.API_URL}file/${encodeURI(item.filename)}/${
        item.item_id
      }/?token=${resp.data}`;
      let curl = `curl ${command} -o "${item.filename}" -D-`;
      let wget = `wget ${command} -O "${item.filename}"`;
      const { selectedFile } = this.state;
      this.setState({
        selectedFile: {
          ...selectedFile,
          wgetCommand: wget,
          curlCommand: curl,
        },
      });
    });
  };

  proceedToDownloadEvent = (item, isAgreed) => {
    const instance = Api.AxiosInstance.getInstance();
    const { URL_ITEM_DOWNLOAD, URL_ITEMS_DETAILS } = Constants.App;
    const paramsforLink = { params: { id: item.item_id } };
    instance.get(URL_ITEMS_DETAILS, paramsforLink).then((resp) => {
      const { selectedFile } = this.state;

      this.setState({
        selectedFile: {
          ...selectedFile,
          directLink: resp.data.directLink,
          fileName: resp.data.filename,
          fileDesc: resp.data.description,
          itemId: resp.data.id
          // bug/GP3-138
          // friendlyName: resp.data.friendlyName ? resp.data.friendlyName : null,
        },
      });
    });

    const params = { params: { itemId: item.item_id, isAgreed }, timeout: 0 };
    instance.get(URL_ITEM_DOWNLOAD, params).then((resp) => {
      const { selectedFile } = this.state;

      this.setState({
        selectedFile: {
          ...selectedFile,
          downloadLink: resp.data,
        },
      });
    });
    this.setState({ setIsDownloading: true, setProceedToDownload: true });
  };
  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const { loading, data, isCollapsed } = this.state;
    let transformedData = this.transformData(data);
    return (
      <Segment className="dashboard-card">
        <Responsive minWidth={1024}>
          <div className="side-marker" />
          <div className="header">Recent Downloads</div>

          <div className="table-wrapper">
            <Tableview
              data={transformedData}
              columns={this.COLUMNS}
              loading={loading}
            />
          </div>

          {transformedData.content && (
            <React.Fragment>
              <Divider />
              <em>Showing top {transformedData.content.length} records</em>
            </React.Fragment>
          )}
        </Responsive>
        <Responsive maxWidth={1023}>
          <Accordion>
            <Accordion.Title
              onClick={(e) => this.setState({ isCollapsed: !isCollapsed })}
            >
              <Icon
                name={isCollapsed ? "caret right" : "caret down"}
                color="blue"
              />
              <div className="side-marker" />
              <div className="header">Recent Downloads</div>
            </Accordion.Title>
            <Accordion.Content active={!isCollapsed}>
              <Cardview
                data={transformedData}
                columns={this.COLUMNS}
                loading={loading}
              />

              {transformedData.content && (
                <React.Fragment>
                  <Divider />
                  <em>Showing top {transformedData.content.length} records</em>
                </React.Fragment>
              )}
            </Accordion.Content>
          </Accordion>
        </Responsive>

        {this.state.isModalOpen && (
          <MultiDownloadOpts
            isModalOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            selectedFile={this.state.selectedFile}
          ></MultiDownloadOpts>
        )}
      </Segment>
    );
  }
}

export default RecentDownloads;
