import React from "react";
import {
  Divider,
  Segment,
  Responsive,
  Accordion,
  Icon
} from "semantic-ui-react";
import {dateToPacific} from "@/utils/dateTime";
import Tableview from "@/common/tableview";
import {Cardview} from "@/common/cardview";
import Api from "@/api";
import urls from "@/config/constants";

class SearchLogs extends React.Component {
  COLUMNS = [
    { name: "Search Term", key: "term", width: "3", showTooltip: true, },
    { name: "Username", key: "user.username", width: "2", showTooltip: true, },
    { name: "Group", key: "user.group", width: "2", showTooltip: true, },
    { name: "City", key: "user.city", width: "2", showTooltip: true, },
    { name: "Time of Search", key: "searchDate", width: "3", showTooltip: true, }
  ];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: { content: [] },
      isCollapsed: true
    };
  }

  componentDidMount() {
    this.load();
  }

  load = options => {
    this.setState({ loading: true });
    Api.AxiosInstance.getInstance()
      .get(urls.App.URL_SEARCH_LOG)
      .then(resp => {
        this.setState({
          data: resp.data,
          loading: false
        });
      })
      .catch(err => this.setState({ loading: false }));
  };

  transformData = data => {
    let transformedData = Object.assign({}, data);
    let content = [];
    transformedData.content.map(el => {
      if (!el.user.group) {
        el.user.group = "N/A";
      }
      content.push({
        term: el.term,
        user: el.user,
        searchDate: <>{dateToPacific(el.searchDate,"MM/DD/YYYY hh:mm a")}</>
      });
    });
    transformedData.content = content;
    return transformedData;
  };

  render() {
    const { loading, data, isCollapsed } = this.state;
    let transformedData = this.transformData(data);
    return (
      <Segment className="dashboard-card">
        <Responsive minWidth={1024}>
          <div className="side-marker" />
          <div className="header">Search Logs</div>

          <div className="table-wrapper">
            <Tableview
              data={transformedData}
              columns={this.COLUMNS}
              loading={loading}
            />
          </div>
          {transformedData.content && (
            <React.Fragment>
              <Divider />
              <em>Showing top {transformedData.content.length} records</em>
            </React.Fragment>
          )}
        </Responsive>
        <Responsive maxWidth={1023}>
          <Accordion>
            <Accordion.Title
              onClick={e => this.setState({ isCollapsed: !isCollapsed })}
            >
              <Icon
                name={isCollapsed ? "caret right" : "caret down"}
                color="blue"
              />
              <div className="side-marker" />
              <div className="header">Search Logs</div>
            </Accordion.Title>
            <Accordion.Content active={!isCollapsed}>
              <Cardview
                data={transformedData}
                columns={this.COLUMNS}
                loading={loading}
              />
              {transformedData.content && (
                <React.Fragment>
                  <Divider />
                  <em>Showing top {transformedData.content.length} records</em>
                </React.Fragment>
              )}
            </Accordion.Content>
          </Accordion>
        </Responsive>
      </Segment>
    );
  }
}

export default SearchLogs;
