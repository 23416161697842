import Api from "@api";
import Constants from "@constants";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Icon, Label } from "semantic-ui-react";
import { dateToPacific } from "../../../utils/dateTime";

function DownloadBriefcaseItem({ item, cancel }) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className="base-segment-wrapper product-file-downlaod">
        <div className="ui form mb-2-im">
          {item.name && (
            <Form.Input label="File Name">
              <Label>
                <b>{item.name}</b>
              </Label>
            </Form.Input>
          )}
          {item.date && (
            <Form.Input label="Date">
              <Label>
                <b>{dateToPacific(item.date, "MM/DD/YYYY")}</b>
              </Label>
            </Form.Input>
          )}
        </div>
      </div>
      <a href={item.downloadLink} target="_blank">
        <Button className="button-primary m--no-margin-left-im">
          Download
        </Button>
      </a>{" "}
      <button onClick={cancel} className="button-basic">
        Cancel
      </button>
    </React.Fragment>
  );
}

export default DownloadBriefcaseItem;
