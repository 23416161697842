import BriefcaseListing from "./briefcase-listing";
import { connect } from "react-redux";
import { userIdSelector } from "../../../../common/selectors";


const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
});

const mappedBriefcaseListing = connect(mapStateToProps)(BriefcaseListing);

export default mappedBriefcaseListing;