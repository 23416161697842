import React from "react";
import { Segment, Tab } from "semantic-ui-react";
import PackageGroups from "./groups";
import PackageUsers from "./users";
import VideoInfo from "./video-info";
import PackageVidoes from "./videos";

const EditVideos = (props) => {
  const panes = [
    {
      menuItem: "Video Info",
      render: () => (
        <Tab.Pane>
          <VideoInfo {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assigned Users",
      render: () => (
        <Tab.Pane>
          <PackageUsers {...props} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Assigned Groups",
      render: () => (
        <Tab.Pane>
          <PackageGroups {...props} />
        </Tab.Pane>
      ),
    },
     {
      menuItem: "Assigned Packages",
      render: () => (
        <Tab.Pane>
          <PackageVidoes {...props} />
        </Tab.Pane>
      ),
    }, 
  ];

  return (
    <Segment className="base-segment-wrapper">
      <div className="side-marker" />
      <div className="header">Edit Video</div>
      <div className="empty-height-small" />

      <Tab panes={panes} />
    </Segment>
  );
};

export default EditVideos;
