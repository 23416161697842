import Footer from "./template"
import { connect } from "react-redux";
import { configSelector } from "../../../common/selectors";


const mapDispatchToProps = {
}

const mapStateToProps = (state, props) => ({
	config: configSelector(state, props),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
