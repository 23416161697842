import Actions from "../../actions";

const initialState = {
  loading: false,
  inviteModal: false,
  inviteSuccessModal: false,
  errors: {
    isError: false,
    message: "",
  },
};

const userAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.Admin.INVITE_USER_INITIATE:
      return {
        ...state,
        loading: true,
      };
    case Actions.Admin.INVITE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        inviteModal: false,
        errors: {
          isError: false,
          message: "",
        },
        inviteSuccessModal: true,
      };
    case Actions.Admin.INVITE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: {
          isError: true,
          message: action.payload,
        },
      };
    case Actions.Admin.OPEN_INVITE_MODAL:
      return {
        ...state,
        inviteModal: true,
      };
    case Actions.Admin.CLOSE_INVITE_MODAL:
      return {
        ...state,
        inviteModal: false,
      };
    case Actions.Admin.CLOSE_INVITE_SUCCESS_MODAL:
      return {
        ...state,
        inviteSuccessModal: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default userAdminReducer;
