import React from "react";
import { Tab } from "semantic-ui-react";
import PackageGroups from "./groups";
import PackageInfo from "./info";
import PackageItems from "./items";
import PackageUsers from "./users";
import PackageVideos from "./videos";

const EditPackages = props => {
  const panes = [
    {
      menuItem: "Package Info",
      render: () => (
        <Tab.Pane>
          <PackageInfo {...props} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Assigned Users",
      render: () => (
        <Tab.Pane>
          <PackageUsers {...props} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Assigned Groups",
      render: () => (
        <Tab.Pane>
          <PackageGroups {...props} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Assigned Videos",
      render: () => (
        <Tab.Pane>
          <PackageVideos {...props} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Assigned Items",
      render: () => (
        <Tab.Pane>
          <PackageItems {...props} />
        </Tab.Pane>
      )
    }
  ];

  return <Tab panes={panes} />;
};

export default EditPackages;
