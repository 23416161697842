import Api from "@/api";
import React, { Component } from "react";
import { Segment, Grid, Table, Divider, Form, Message, Confirm, Modal, Button, Card } from "semantic-ui-react";
import { InputField, DropdownField } from "@/common/Form";
import { ErrorMessage } from "@/common/Form";
import { removeEmptyKeys } from "@/common/utils/utils";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import validate from "validate.js";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Constants from "@/config/constants";


class EmailTemplate extends Component {

  VALIDATION_CONFIG = {
    subject: {
      presence: true,
    },
    content: {
      presence: true,
    },
  };

  container = {
    width: 500,
    margin: '50px auto'
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      redirectTo: false,
      isSaving: false,
      validationMsg: {},
      showError: false,
      errorMsg: null,
      content: null,
      templateType: "",
      modalState: false,
      edited: false,
      unsavedChangesWarning: false,
      tempTemplateType: "",
      placeholderMismatch: null,
      previewUrl: null,
    };
  };
  getDropDownValues = types => {
    if (types) {
      const dropdownValues = types.map((val, el) => (
        { value: val, key: val, text: val.replaceAll('_', ' ') }))
      return dropdownValues;
    }
  }


  componentDidMount() {
    this.fetchEmailTemplateTypes();
  }

  fetchEmailTemplateTypes = () => {
    const instance = Api.AxiosInstance.getInstance();
    return instance
      .get("mailer/template-types")
      .then((resp) => {
        this.setState({ types: this.getDropDownValues(resp.data) }, () => {
          this.setState({
            templateType: this.state.types[0].value,
            tempTemplateType: this.state.types[0].value,
          }, this.fetchEmailTemplateByType);
        });
      })
      .catch((error) => {
        this.setState({});
      });
  }

  changeEmailTemplate = () => {
    this.setState({ templateType: this.state.tempTemplateType, loading: true, edited: false }, this.fetchEmailTemplateByType);
  }

  handleDropDownChange = (e, data) => {
    console.log(e, data)
    this.setState({ tempTemplateType: data.value }, () => {
      if (this.state.edited) {
        this.setState({ unsavedChangesWarning: true });
      }
      else {
        this.changeEmailTemplate();
      }
    });
  };
  
  handleCancelClicked = () => {
    if (this.state.edited) {
      this.setState({ unsavedChangesWarning: true });
    }
    else {
      this.changeEmailTemplate();
    }
  };
  
  /**
   * This method removes object name from string.
   * E.g. If the placeholder is ${object.attribute} it is turned into ${attribute}
   * @param {*} str 
   * @returns 
   */
  clearObjectsFromPlaceholders = (str) => {
    if(str){
      const matches = str.match(/\${[^}]*\.[^}]*}/g);  // Matching all placeholders that have object.attribute
      if(matches){
        const attributeArr = matches.map((_s) => {
          return _s.slice(2, -1).trim().split(".")[1];
        });
        for(let i = 0; i < attributeArr.length; i++){
          str = str.replace(matches[i], "${" + attributeArr[i] + "}");
        }
      }
    }
    return str;
  }
  
  /**
   * This method inserts object names to placeholders.
   * E.g. if placeholder is ${attribute} it is turned to ${object.attribute}
   * @param {*} str The content string that needs to be changed.
   * @returns A string in which object names are inserted.
   */
  addObjectsToPlaceholders = (str) => {
    if(str){
      const matches = str.match(/\${[^}]*}/g);
      
      if(matches){
        const attributeArr = matches.map((_s) => {
          return _s.slice(2, -1).trim();
        });
        for(let i = 0; i < attributeArr.length; i++){
          for(const p of this.state.placeholder) {
            if(p.attributes){
              if(p.attributes.find((_a) => _a.name == attributeArr[i])) {
                str = str.replace(matches[i], "${" + p.name + "." + attributeArr[i] + "}");
              } 
            }
          }
        }
      }
    }
    return str;
  }

  fetchEmailTemplateByType = () => {
    console.log(this.state)
    const { templateType } = this.state;
    const params = {
      params: {
        templateType
      },
    };
    const instance = Api.AxiosInstance.getInstance();
    return instance
      .get("mailer", params)
      .then((resp) => {
        let fixedContent = resp.data.content;
        if(fixedContent && !fixedContent.startsWith("<p>")){
          fixedContent = "<p>" + fixedContent + "</p>";
        }
        this.setState({
          loading: false,
          content : fixedContent,
          initialData: fixedContent,
          footer: resp.data.emailFooter.footerText,
          data: fixedContent,
          header: resp.data.emailHeader.headerText,
          placeholder : resp.data.placeholders,
          subject: resp.data.subject
        })
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }
  setData = (data) => {
    console.log("data", data);
  }
  onChange = (event, eventData) => {
    const { data } = this.state;
    const { name, value } = eventData;
    this.setState({ [name]: value, edited: true });
  };
  onEditorChange = (evt) => {
    this.setState({
      data: evt.editor.getData(),
      edited: true,
    });
  }

  handleChange = (event, { name, value }) => {
    this.setState({
      [name]: value, edited: true,
    })
  }

  validateForm = () => {
    let result = validate(removeEmptyKeys(this.state), this.VALIDATION_CONFIG);
    this.setState({ validationMsg: result || {} });
    return isEmpty(result)
  };
  saveDetails = () => {
    const { templateType, subject, content } = this.state;
    // validate() will return true incase all fields are valid.
    if (!this.validateForm()) return;
    let body = {
      /* Commenting this as placeholder implementation is changed to ${object.attribute} now
      subject: this.addObjectsToPlaceholders(subject),
      content: this.addObjectsToPlaceholders(content),
      */
      subject,
      content,
    };
    const params = {
      params: {
        templateType
      },
    };
    this.setState({ loading: true });
    const instance = Api.AxiosInstance.getInstance();
    instance
      .post("mailer/update", body, params)
      .then((resp) => {
        this.setState({ loading: false, edited: false }, () => {
          this.redirectToParent();
        });
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ loading: false, showError: true, errorMsg });
      });
  };
  
  handlePreviewClicked = () => {
    const { templateType, subject, content } = this.state;
    const instance = Api.AxiosInstance.getInstance();
    
    let body = {
      subject: this.addObjectsToPlaceholders(subject),
      content: this.addObjectsToPlaceholders(content),
      templateType: this.state.templateType,
    };
    
    instance
      .post(Constants.App.URL_EMAIL_TEMPLATE_PREVIEW, body, { })
      .then((resp) => {
        console.log(resp);
        this.fetchPreviewUrl();
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ loading: false, showError: true, errorMsg });
      });
  }
  
  fetchPreviewUrl = () => {
    const instance = Api.AxiosInstance.getInstance();
    
    let params = {
      templateType: this.state.templateType,
    };
    
    instance
      .get(Constants.App.URL_EMAIL_TEMPLATE_IFRAME, { params })
      .then((resp) => {
        console.log(resp);
        this.setState({ loading: false, edited: false, previewUrl: resp.data }, () => {
          this.setModalState(true)
        });
      })
      .catch((error) => {
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ loading: false, showError: true, errorMsg });
      });
  }

  setModalState = (val) => {
    if (!this.isPlaceholderMismatch(this.state.content) && !this.isPlaceholderMismatch(this.state.subject)) {
      this.setState({ modalState: val }); 
    }
  }
  
  /**
   * This method checks if there is a non matching placeholder in the passed string.
   * It returns true if there is a mismatch.
   */
  isPlaceholderMismatch = (str) => {
    let errors = [];
    let syntaxErrorMatches = [];
    if(str){
      const matches = str.match(/\${[^(\s|\n|<|}|$)]*(\s|\n|<|}|$)/g);  // Matching all placeholders, i.e. ${name.attribute}
      if(matches){
        // Checking syntax
        for (let i = 0; i < matches.length; i++) {
          let innerText = matches[i].slice(0, -1).trim();
          if (!matches[i].match(/\${[^}]*}/g)) {
            // The curly braces don't match
            errors.push(innerText);
            syntaxErrorMatches.push(matches[i]);
          }
          else if(matches[i].slice(0, -1).split(".").length != 2){
            // There is less than 1 or more than 1 . in the placeholder
            errors.push(matches[i]);
            syntaxErrorMatches.push(matches[i]);
          }
        }
        let placeholderArr = matches.map((_s) => {
          return _s.slice(2, -1).trim();
        });
        placeholderArr = placeholderArr.filter((_m) => !syntaxErrorMatches.find((_s) => _s.slice(2, -1).trim() == _m));
        
        // Checking for mismatch errors
        for(const p of placeholderArr){
          
          // Separating object and attribute name
          let splitted = p.split(".");
          
          if(!this.state.placeholder.find((_p) => {
              if(_p.attributes){
                // Finding inside attributes
                return _p.attributes.find((_a) => _a.name == splitted[1] && _p.name == splitted[0]);
              }
              else{
                return false;
              }
            })){
            // This element was not found in the accepted placeholders
            errors.push("${" + p + "}");
          }
        }
      }
    }
    
    if(errors.length != 0){
      this.setState({placeholderMismatch: errors.join(", ")})
    }
    
    return errors.length != 0;
  }
  
  showpopupWarning = () => {
    this.setState({
      popupWarning: true
    })
  }
  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  hideUnsavedChangesWarning = (cb) => {
    this.setState(
      {
        unsavedChangesWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  hidePlaceholderMismatchWarning = (cb) => {
    this.setState(
      {
        placeholderMismatch: null,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  redirectToParent = () => {
    // Temporary hack to redirect to parent need to send baseUrl via props
    this.setState({
      redirectTo: "/admin/email-templates",
    });
  };
  
  showAlert = (str) => {
    alert(str);
  }

  render() {
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                largeScreen={8}
              >
                <div className="header">
                  <div className="side-marker" />
                  Email Templates
                </div>
              </Grid.Column>

            </Grid.Row>
          </Grid>
          <div className="empty-height-small" />

          <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>

                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={6}
                  largeScreen={6}>
                  <DropdownField
                    isRequired
                    label="Email Triggers"
                    name="templateType"
                    value={this.state.templateType}
                    onChange={this.handleDropDownChange}
                    options={this.state.types}
                    search
                  />
                </Grid.Column>

                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={10}
                  largeScreen={10}>
                  <InputField
                    label="Email Subject"
                    errorMsg={this.state.validationMsg.subject}
                    isError={this.state.validationMsg.subject}
                    name="subject"
                    id="subject"
                    maxlength="128"
                    value={this.state.subject}
                    onChange={this.handleChange}
                    isRequired
                    placeholder="Enter Email Subject"
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>

                  <div className="ck-editor-wrapper">
                    <label className="form-label">Email Body</label>
                    <div className="" />

                    {<CKEditor
                      initData={"hello"}
                      editor={Editor}
                      data={this.state.data ? this.state.data : ""}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      config={{

                        toolbar: ['bold', '|', 'italic', '|', 'link', 
                        '|', 'colors', '|', 'fontfamily', '|', 'fontsize', '|', 
                        'fontColor', '|', 'fontBackgroundColor', '|', 
                        'numberedList', '|', 'bulletedList']
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ event, editor, data });
                        this.setState({
                          content: data,
                          data: data,
                        });
                        if(data != this.state.initialData){
                          this.setState({ edited: true });
                        }
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                    />}
                  </div>

                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="submit"
                    value="Save"
                    className="button-primary"
                    onClick={() => {
                      this.setState({placeholderMismatch: null}, () => {
                        if(!this.isPlaceholderMismatch(this.state.content) && !this.isPlaceholderMismatch(this.state.subject)){
                          // No errors found, we can continue
                          this.showpopupWarning();
                        }
                      });
                      
                    }}
                  />

                  <button
                    className="button-primary"
                    onClick={() => this.handlePreviewClicked()}
                  > Preview</button>

                  <Link to="/admin/email-templates">
                    <button className="button-basic"
                      onClick={() => this.handleCancelClicked()}
                    >
                      Cancel
                    </button>
                  </Link>

                  <ErrorMessage
                    isError={this.state.validationMsg.data}
                    errorMsg={this.state.validationMsg.data}
                  />
                  {this.state.showError && <Message error content={this.state.errorMsg} />} 
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className="template-placeholder-wrapper mt-2">

                    <Card >

                      <Card.Content
                        className="dark-blue-background-shade"
                        header="Template Placeholders Details"
                      />
                      <Card.Content>
                        <div className="table-wrapper-with-pagination ">
                          <Table striped >
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Template Placeholders/ Wildcards</Table.HeaderCell>
                                <Table.HeaderCell>Description</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {/*this data array should be replaced with actual placeholders */}
                              {this.state.placeholder && this.state.placeholder.map((val, key) => (
                                <>
                                  {val && val.attributes && (
                                    val.attributes.map((attr, k) => (
                                      <Table.Row key={key + k}>
                                        <Table.Cell key={key + k}>{"${" + val.name + "." + attr.name + "}"}</Table.Cell>
                                        <Table.Cell>
                                          {" "}
                                          {attr.description}
                                        </Table.Cell>
                                      </Table.Row>
                                    ))
                                  )}
                                </>
                              ))}
                            </Table.Body>
                          </Table>
                        </div>
                      </Card.Content>
                    </Card>


                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>

        </Segment>
        <Modal size={"fullscreen"} onClose={() => this.setModalState(false)} open={this.state.modalState}>
          <Modal.Header>Email Preview</Modal.Header>
          <Modal.Content>
            <div>
              <div>
                <iframe class="email-template-preview" src={this.state.previewUrl}/>
              </div>
            </div>
            <Grid>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="m--align-right"
              >
                <Button
                  className="button-primary mr-0-im mt-2-im "
                  onClick={() => this.setModalState(false)}
                >
                  Close
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.saveDetails)}
          content="Are you sure you want to update email template?"
        />
        <Confirm
          open={this.state.unsavedChangesWarning}
          onCancel={this.hideUnsavedChangesWarning}
          onConfirm={() => this.hideUnsavedChangesWarning(this.changeEmailTemplate)}
          content="You have unsaved changes. Proceed without saving?"
        />
        <Confirm
          open={this.state.placeholderMismatch}
          onCancel={this.hidePlaceholderMismatchWarning}
          onConfirm={this.hidePlaceholderMismatchWarning}
          content={"Error! Invalid placeholder provided: " + this.state.placeholderMismatch}
        />
        
      </React.Fragment>
    );
  }
};
export default EmailTemplate;
