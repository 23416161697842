import React from "react";
import { useSelector } from "react-redux";
import { Segment, Grid, Responsive } from "semantic-ui-react";
import ApproveUserDialog from "./components/approve-users";
import TopDownloads from "./components/top-downloads";
import RecentDownloads from "./components/recent-downloads";
import RecentUploads from "./components/recent-uploads";
import BriefcaseInbox from "../briefcase/pages/briefcase-inbox";
import SearchLogs from "./components/search-logs";
import ReceivedFilesCard from "./components/received-files";
import ZendeskTickets from "./components/zendesk-tickets";
import Articles from "./components/articles";

const ApproverView = () => {
  const supportEnabled = useSelector(
    (state) => state.common.config.supportEnabled
  );
  return (
    <Segment basic className="mt-2-im app-dashboard-wrapper">
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
            <TopDownloads />
            <RecentDownloads />
            <SearchLogs />
            {supportEnabled && (
              <>
                <ZendeskTickets />
              </>
            )}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8}>
            <RecentUploads />
            <ApproveUserDialog />
            <Responsive minWidth={1024}>
              <BriefcaseInbox
                match={{ path: "", params: {}, paginate: false }}
              />
            </Responsive>
            <Responsive maxWidth={1023}>
              <ReceivedFilesCard />
            </Responsive>
            {supportEnabled && (
              <>
                <Articles />
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default ApproverView;
