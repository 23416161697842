/* Tickets */
const INITIATE_GET_TICKETS = "INITIATE_GET_TICKETS";
const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
const GET_TICKETS_FAILURE = "GET_TICKETS_FAILURE";

/* Articles */
const INITIATE_GET_ARTICLES = "INITIATE_GET_ARTICLES";
const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";
const GET_ARTICLES_FAILURE = "GET_ARTICLES_FAILURE";
const DELETE_ARTICLES_INITIATE = "DELETE_ARTICLES_INITIATE";
const DELETE_ARTICLES_SUCCESS = "DELETE_ARTICLES_SUCESS";
const DELETE_ARTICLES_FAILURE = "DELETE_ARTICLES_FAILURE";
const ADD_ARTICLES_INITIATE = "ADD_ARTICLES_INITIATE";
const ADD_ARTICLES_SUCCESS = "ADD_ARTICLES_SUCESS";
const ADD_ARTICLES_FAILURE = "ADD_ARTICLES_FAILURE";
const GET_ARTICLE_BY_ID_INITIATE = "GET_ARTICLE_BY_ID_INITIATE";
const GET_ARTICLE_BY_ID_SUCCESS = "GET_ARTICLE_BY_ID_SUCCESS";
const GET_ARTICLE_BY_ID_FAILURE = "GET_ARTICLE_BY_ID_FAILURE";
const UPDATE_ARTICLE_INITIATE = "UPDATE_ARTICLE_INITIATE";
const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
const UPDATE_ARTICLE_FAILURE = "UPDATE_ARTICLE_FAILURE";
const GET_MAX_SEQUENCE_INITIATE = "GET_MAX_SEQUENCE_INITIATE";
const GET_MAX_SEQUENCE_SUCCESS = "GET_MAX_SEQUENCE_SUCCESS";
const GET_MAX_SEQUENCE_FAILURE = "GET_MAX_SEQUENCE_FAILURE";
const GET_EXISTING_SEQUENCE_INITIATE = "GET_EXISTING_SEQUENCE_INITIATE";
const GET_EXISTING_SEQUENCE_SUCCESS = "GET_EXISTING_SEQUENCE_SUCCESS";
const GET_EXISTING_SEQUENCE_FAILURE = "GET_EXISTING_SEQUENCE_FAILURE";
const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";

export default {
  INITIATE_GET_TICKETS,
  GET_TICKETS_SUCCESS,
  GET_TICKETS_FAILURE,
  INITIATE_GET_ARTICLES,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAILURE,
  DELETE_ARTICLES_INITIATE,
  DELETE_ARTICLES_SUCCESS,
  DELETE_ARTICLES_FAILURE,
  ADD_ARTICLES_INITIATE,
  ADD_ARTICLES_SUCCESS,
  ADD_ARTICLES_FAILURE,
  GET_ARTICLE_BY_ID_INITIATE,
  GET_ARTICLE_BY_ID_SUCCESS,
  GET_ARTICLE_BY_ID_FAILURE,
  UPDATE_ARTICLE_INITIATE,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE,
  GET_MAX_SEQUENCE_INITIATE,
  GET_MAX_SEQUENCE_SUCCESS,
  GET_MAX_SEQUENCE_FAILURE,
  GET_EXISTING_SEQUENCE_INITIATE,
  GET_EXISTING_SEQUENCE_SUCCESS,
  GET_EXISTING_SEQUENCE_FAILURE,
  SET_PAGE_NUMBER
};
