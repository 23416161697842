import Api from "@/api";
import { CustomerContactType } from "@/common/Form";
import Tableview from "@/common/tableview";
import { UserListing } from "@/common/user-selection";
import Constants from "@/config/constants";
import update from "immutability-helper";
import React, { Component } from "react";
import Moment from "react-moment";
import { Confirm, Grid, Segment } from "semantic-ui-react";

class contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      data: {},
      showUsersPopup: false,
      usersPageNum: 0,
      contacts: {
        content: {}
      },
      contactTypeId: null,
      selectedContactName: null,
      selectedContact: null,
      contactColumns: [
        { name: "Contact Type", key: "contactType", class: "left" ,showTooltip: true },
        { name: "Contact Person", key: "employeeName", class: "left" , showTooltip: true},
        { name: "Assigned By", key: "createdBy", class: "left", showTooltip: true },
        { name: "Assigned On", key: "creationDate", class: "left",showTooltip: true  },
        { name: "Actions", key: "actions", class: "left" }
      ]
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => {
    const { form } = this.props;
    const { usersPageNum } = this.state;
    let params = {
      params: {
        id: form.id,
        page: usersPageNum
      }
    };
    return Api.AxiosInstance.paginate(
      Constants.App.URL_ADMIN_USER_CONTACTS,
      params
    ).then(resp => {
      let cleanedData = this.transformData(resp.data);
      this.setState({
        contacts: { content: cleanedData }
      });
    });
  };
  transformData = (data, type) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map(el => {
        let row = Object.assign({}, el);
        row.actions = (
          <React.Fragment>
            <a
              onClick={e =>
                this.removeContact(
                  e,
                  el.contactTypeId,
                  el.customerId,
                  el.employeeId
                )
              }
            >
              <span className="icon-delete" title="Remove" name="remove" />
            </a>
          </React.Fragment>
        );

        row.creationDate = <Moment format="MM/DD/YYYY hh:mm a">{el.creationDate}</Moment>;
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };
  onDataChangeOld = (event, data) => {
    const { form } = this.state;
    this.setState({
      form: update(form, { [data.name]: { $set: data.value } })
    });
  };
  onDataChange = (e, data) => {
    // const { form } = this.state;
    // debugger;
    console.log("e, data", e, data.name, data.value);
    this.setState({
      contactTypeId: data.value
    });
  };
  showUsersPopup = e => {
    this.setState({
      showUsersPopup: true
    });
  };

  onDismissUserPopup = users => {
    this.setState({
      showUsersPopup: false
    });
  };
  setCustomerContactType = e => {
    this.setState({
      customerContactType: e.target.value
    });
  };
  contactSelectionOnSave = users => {
    const { customerContactType } = this.state;

    // document.getElementById("pickedupContact").textContent = users[0].username;
    this.setState({
      selectedContactId: users[0].id,
      selectedContactName: users[0].username,
      showUsersPopup: false
    });
  };
  onAssign = () => {
    // we need to get the selected Contact type
    const {
      customerContactType,
      contactTypeId,
      selectedContact,
      selectedContactId
    } = this.state;
    const { form } = this.props;
    // backend requirements : cid (customer id/userId),
    // eid (selected contact id), cctid (selected customer contact type id)
    let params = {
      params: {
        cid: form.id,
        eid: selectedContactId,
        cctid: contactTypeId
      }
    };
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ADMIN_USER_ASSIGN_CONTACT, params)
      .then(resp => {
        this.getUsers();
      });
  };
  hideDeleteWarning = cb => {
    this.setState({ deleteWarning: false }, () => {
      if (cb instanceof Function) cb();
    });
  };
  removeContact = (e, cctid, cid, eid) => {
    // there should be some alert for this?
    this.setState({
      customerContactTypeId: cctid,
      employeeId: eid,
      customerId: cid,
      deleteWarning: true
    });

    // e.preventDefault();
    // e.stopPropagation();
  };
  deleteContact = () => {
    const { employeeId, customerId } = this.state;
    let params = {
      params: {
        cid: customerId,
        eid: employeeId
      }
    };
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ADMIN_USER_REMOVE_CONTACT, params)
      .then(resp => {
        this.getUsers();
        this.setState({
          employeeId: null,
          customerId: null
        });
        // clear State
        // document.getElementById("pickedupContact").textContent = "";
      });
  };
  onUserPageChange = e => {
    this.setState({ usersPageNum: e.page }, this.getUsers);
  };

  render() {
    const { contactTypeId, selectedContactId } = this.state;
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="empty-height-small" />
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                <label>User</label>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={5}>
                <span>{this.props.form.username}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                <label>Full Name</label>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={5}>
                <span>
                  {this.props.form.firstName} {this.props.form.lastName}
                </span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                <label>Company</label>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={5}>
                <span>{this.props.form.company}</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={3}>
              <label>
                Contact Type <span style={{ color: "red" }}>*</span>
              </label>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <CustomerContactType
                value={this.state.contactTypeId}
                name="contactTypeId"
                // errorMsg={validationMsg.userGroup}
                // isError={validationMsg.userGroup}
                onChange={this.onDataChange}
                isRequired
              />
            </Grid.Column>
            <Grid.Row className="ui form">
              <Grid.Column mobile={16} tablet={16} computer={3}>
                <label>
                  Contact <span style={{ color: "red" }}>*</span>
                </label>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                <div class="field">
                  <div class="ui input">
                    <input
                      placeholder="Selected User"
                      className="search-bar"
                      // disabled
                      // onChange={this.onSearchTyping}
                      value={this.state.selectedContactName}
                    ></input>
                  </div>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={e => this.showUsersPopup(e)}
                  >
                    Select Contact
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                {contactTypeId && selectedContactId && (
                  <button
                    className="button-basic"
                    onClick={() => this.onAssign()}
                  >
                    Assign
                  </button>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <UserListing
                  selectionType="single-select"
                  show={this.state.showUsersPopup}
                  onDismiss={this.onDismissUserPopup}
                  onSave={this.contactSelectionOnSave}
                  entity="PackageUsers"
                  entityId={this.props.form.id}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="table-wrapper-with-pagination">
                  <Tableview
                    paginate
                    data={this.state.contacts.content}
                    columns={this.state.contactColumns}
                    onPaginationChange={this.onUserPageChange}
                    noDataMessage="No Customers"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.deleteContact)}
          content="Are you sure you want to delete this Contact Type?"
        />
      </React.Fragment>
    );
  }
}

export default contact;
