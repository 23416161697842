import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Modal, Button, Segment, Container } from "semantic-ui-react";
import { UserSelection } from "../../../../common/user-selection";

class BriefcaseResend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipients: [],
      sendEmail: false,
      errorMsg: "Please select one or more users to resend briefcase item",
      showError: false,
    };
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  onSelection = (users) => {
    this.setState({ recipients: users });
  };

  resendBriefcase = (users, sendEmail) => {
    const { onResend } = this.props;
    if(users.length==0){
      this.setState({ showError: true });
      return;
    }
    onResend(users, sendEmail);
  }

  render() {
    const { recipients, showError, errorMsg } = this.state;
    const { onClose, onResend, show } = this.props;
    return (
      <React.Fragment>
      <Modal open={show} onClose={onClose} centered={true} size="tiny">
        <Modal.Header>Resend Briefcase Items</Modal.Header>
        <Modal.Content>
          <UserSelection
            label="Resend To"
            isRequired={true}
            onChange={this.onSelection}
          />
          {/* <Checkbox label="Send Email notification to the recipient(s)" /> */}
          <Checkbox
            name="sendEmail"
            label="Send Email notification to the recipient(s)"
            checked={this.state.sendEmail}
            onChange={(event, data) => {
              this.setState({ sendEmail: data.checked });
            }}
          />
        </Modal.Content>
        <Modal.Actions className="m--align-right">
          <Button
            primary
            onClick={() => this.resendBriefcase(recipients, this.state.sendEmail)}
            className="button-primary mr-0-im"
          >
            Resend
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

BriefcaseResend.propTypes = {
  /** Callback which is fired when Popup is closed. */
  onClose: PropTypes.func.isRequired,
  /** Callback which is fired when `resend` button is clicked. */
  onResend: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
};

export default BriefcaseResend;
