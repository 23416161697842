import Api from "@api";
import FileIcon from "@/common/file-icon";
import equal from "fast-deep-equal";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { List, Pagination, Segment } from "semantic-ui-react";
import Highlighter from "react-highlight-words";
import { dateToPacific } from "@/utils/dateTime";
import Constants from "@constants";
import { trimStringWithWord } from "../../../common/utils/utils";
const { URL_GLOBAL_SEARCH } = Constants.App;

class Videos extends Component {
  columns = [
    {
      name: 'Name',
      key: 'name',
      width: '3',
    },
    {
      name: 'Description',
      key: 'description',
      width: '3',
    },

    {
      name: 'File Name',
      key: 'filename',
      width: '2',
      class: 'center',
    },

    { name: 'Action', key: 'actions', width: '3' },
  ];
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listings: {
        content: [],
        number: 0,
      },
      data: {
        content: [],
      },
      pageNo: 0,
      pageSize: 10,
      totalRecords: 0,
    };
  }
  componentDidMount() {
    this.searchResults(
      this.props.keyword ? this.props.keyword : this.props.searchKeyword,
      this.props.sort
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      !equal(this.props.keyword, nextProps.keyword) ||
      nextProps.sort.sortBy !== this.props.sort.sortBy ||
      nextProps.sort.sortOrder !== this.props.sort.sortOrder
    ) {
      this.setState(
        {
          sort: nextProps.sort,
        },
        () => {
          this.searchResults(
            nextProps.keyword ? nextProps.keyword : nextProps.searchKeyword,
            nextProps.sort
          );
        }
      );
    }
  }

  /*   transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];

    // let isAdmin = this.state.isAdmin;
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.href = `/#/videos/view/${row.id}`;
        row.header = row.name;
        row.actions = (
          <React.Fragment>
            <Link to={`/videos/view/${row.id}`}>
              {" "}
              <span class="icon-search" title="View"></span>
            </Link>
          </React.Fragment>
        );
        row.creationDate = <>{dateToPacific(row.creationDate, "MM/DD/YYYY")}</>;
        row.item_type = (
          <FileIcon
            filename={row.filename ? row.filename : "other"}
            size="large"
          ></FileIcon>
        );
        content.push(row);
      });
    }
    transformedData.content = content;

    return transformedData;
  };
 */
  searchResults = (keyword, sort) => {
    this.setState(
      {
        ...this.state,
        loading: true,
      },
      () => {
        Api.AxiosInstance.getInstance()
          .get(`${URL_GLOBAL_SEARCH}trainingvideos`, {
            params: {
              query: keyword,
              ...sort,
              pageNo: this.state.pageNo,
              pageSize: this.state.pageSize,
            },
          })
          .then((resp) => {
            this.setState({
              data: this.transformData(resp.data.hits.hits),
              loading: false,
              totalRecords: resp.data.hits.totalHits,
            });
          });
      }
    );
  };
  transformData = (data) => {
    let content = [];
    if (data) {
      data.map((el) => {
        let row = Object.assign({}, el.sourceAsMap);
        row.score = el.score;
        row.header = row.name;
        row.description = trimStringWithWord(
          row.description,
          this.props.searchKeyword || this.props.keyword,
          120
        );
        row.href = `/#/videos/view/${row.id}`;
        row.type = 'videos';
        content.push(row);
      });
    }
    data.content = content;
    return data;
  };
  onPageChange = (e, { activePage }) => {
    this.setState(
      {
        pageNo: activePage - 1,
      },
      () => {
        this.searchResults(
          this.props.keyword || this.props.searchKeyword,
          this.props.sort
        );
      }
    );
  };
  render() {
    return (
      <Segment className="m-0 p-0" loading={this.state.loading}>
        {this.state.data.content.length > 0 ? (
          <>
            <List celled className="search-result-wrapper">
              {this.state.data.content.map((data, index) => (
                <List.Item className="search-item-row">
                  <List.Content>
                    <List.Header
                      as="a"
                      href={data.href}
                      className="search-heading"
                    >
                      {data.header}
                    </List.Header>
                   
                    <List.Description className="s-des-wrapper">
                      <p className="des-p1">
                        {data.creationDate
                          ? dateToPacific(data.creationDate, 'MM/DD/YYYY')
                          : 'N/A'}{' '}
                        -- {' '}
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[
                            this.props.keyword
                              ? this.props.keyword
                              : this.props.searchKeyword,
                          ]}
                          autoEscape={true}
                          textToHighlight={data.description}
                        />
                      </p>
                    </List.Description> <span className="des-p2">
                      {' '}
                      {window.location.origin}
                      {data.href}
                    </span>
                  </List.Content>
                </List.Item>
              ))}
            </List>
            {this.state.data.content.length > 0 ? '' : 'No record found'}
            <Pagination
              activePage={this.state.pageNo + 1}
              onPageChange={this.onPageChange}
              totalPages={Math.ceil(this.state.totalRecords / 10)}
            />
          </>
        ) : (
          'No record found'
        )}
      </Segment>
    );
  }
}

export default Videos;
