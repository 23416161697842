import { removeEmptyKeys } from "@/common/utils/utils";
import Api from "@api";
import Constants from "@constants";
import update from "immutability-helper";
import { debounce, isEmpty, merge } from "lodash";
import moment from "moment";
import React from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Grid,
  Icon,
  Message,
  Modal,
  Checkbox,
  Confirm,
} from "semantic-ui-react";
import validate from "validate.js";
import { dateToPacific } from "../../../../../utils/dateTime";
import ItemDetails from "../../components/_item-details";
import ProductsContext from "../../_context";

class ItemInfo extends React.Component {
  VALIDATION_CONFIG = {
    categoryId: {
      presence: true,
    },
    platformId: {
      presence: true,
    },
    status: {
      presence: true,
    },
    name: {
      presence: true,
    },
    file: {
      presence: true,
    },
    availableDate: {
      presence: true,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      redirectTo: false,
      itemId: props.match.params.itemId,
      data: {
        availableDate: dateToPacific(new Date().getTime(), "YYYY-MM-DD"),
        expireDays: 7,
        expireDate: dateToPacific(new Date().getTime(), "YYYY-MM-DD"),
        isFriendlyNameOccupied: 0,
        isFriendlyNameWarningAccepted: 0,
      },
      isSaving: false,
      isUploading: false,
      validationMsg: {},
      uploadProgress: 0,
      fileCancelToken: null,
      showError: false,
      errorMsg: null,
      copiedToClipboard: false,
      DATE_FORMAT: "YYYY-MM-DD",
      isFileChanged: false,
      showFriendlyNameWarningPopup: false,
      friendlyNameWarningMsg: "",
      popupWarning: false,
    };
  }

  transformResponse = (request) => {
    return {
      actiontype: "UPDATE_ITEM",
      name: request.name,
      friendlyName: request.friendlyName,
      description: request.description,
      parentId: request.parent_id,
      parent_id: request.parent_id,
      categoryId: request.category.toString(),
      platformId: request.platform.toString(),
      availableDate: request.publishStartDate,
      id: request.id,
      file: { name: request.filename },
      status: request.status,
      expireDays: 0,
      expireDate: request.publishEndDate,
      expireType: !request.publishEndDate ? "NEVER" : "DATE",
      createdBy: request.createdBy,
      creationDate: request.creationDate,
      modifiedBy: request.modifiedBy,
      modifiedDate: request.modifiedDate,
      fileuuid: request.fileuuid,
      directLink: request.directLink,
      commandLink: request.commandLink,
      curlCommand: `curl ${Constants.App.API_URL}file/${encodeURI(
        request.filename
      )}/${request.commandLink} -o "${request.filename}" -D-`,
      wgetCommand: `wget ${Constants.App.API_URL}file/${encodeURI(
        request.filename
      )}/${request.commandLink} -O "${request.filename}"`,

      isFriendlyNameOccupied: 0,
      isFriendlyNameWarningAccepted: 0,
    };
  };

  componentDidMount() {
    const { itemId, data } = this.state;
    if (itemId > 0) {
      const { URL_ITEMS_DETAILS } = Constants.App;
      this.props.dispatch({ type: "loading-list" });
      const params = { params: { id: this.state.itemId } };
      const instance = Api.AxiosInstance.getInstance();
      instance.get(URL_ITEMS_DETAILS, params).then((resp) => {
        this.setState({
          data: update(data, { $set: this.transformResponse(resp.data) }),
          isLoaded: true,
        });
        this.props.dispatch({ type: "update-list", details: "" });
      });
    }
  }

  showpopupWarning = () => {
    if (!this.validate()) return;
    this.setState({
      popupWarning: true,
    });
  };

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  onChange = (event, eventData) => {
    const { data } = this.state;
    const { name, value } = eventData;
    if (event === "NEVER" || event === "DAYS" || event === "DATE") {
      const expireDate = eventData,
        expireType = event;
      this.setState({
        data: update(data, {
          expireDate: { $set: expireDate },
          expireType: { $set: expireType },
        }),
      });
    } else {
      this.setState({ data: update(data, { [name]: { $set: value } }) });
    }

    if (eventData.name == "friendlyName") {
      this.isDisplayNameAvailable();
    }
  };

  isDisplayNameAvailable = debounce(() => {
    const instance = Api.AxiosInstance.getInstance();
    const url = Constants.App.URL_PRODUCTS_FRIENDLY_NAME_AVAILABLE;
    let { friendlyName, id } = this.state.data;
    instance
      .get(url, { params: { friendlyName, id } })
      .then((resp) => {
        let isAvailable = resp.data.isAvailable;
        let itemName = resp.data.itemName;
        if (!isAvailable) {
          this.setState({ showFriendlyNameWarningPopup: true });
          this.setState({
            friendlyNameWarningMsg: `The friendly name provided is already in use for another file "${itemName}". Do you want to transfer the friendly name to this file?`,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, 1000);

  hideFNWarning = (cb) => {
    this.setState(
      {
        showFriendlyNameWarningPopup: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  updateFriendlyName = () => {
    const { data } = this.state;
    data.isFriendlyNameOccupied = 1;
    data.isFriendlyNameWarningAccepted = 1;
    this.setState({ data });
  };

  clearFriendlyName = () => {
    const { data } = this.state;
    data.friendlyName = "";
    this.setState({ data });
  };

  onFileChange = (eventData) => {
    if (!eventData) return;
    if ((typeof eventData === "string") && eventData === "removed") {
      if (!!this.state.fileCancelToken) {
        this.state.fileCancelToken.cancel("File removed manually.");
        this.setState({ isUploading: false, fileCancelToken: null });
      }
    } else if (eventData.isUploading) {
      const { file, isUploading } = eventData;
      let { data } = this.state;

      if (!!this.state.fileCancelToken) {
        this.state.fileCancelToken.cancel("File replaced manually.");
      }

      if (file.name && file.size) {
        data = update(data, { file: { $set: file } });
        this.setState({ isUploading, data, isFileChanged: true, fileCancelToken: eventData.cancelToken });
      }
    } else if (typeof eventData.uploadProgress !== "undefined") {
      const { uploadProgress } = eventData;
      const isUploading = uploadProgress !== 100;
      this.setState({ isUploading, uploadProgress });
    } else if (typeof eventData.fileuuid !== "undefined") {
      const { fileuuid } = eventData;
      this.setState({ fileuuid });
    } else if (typeof eventData.errorMsg !== "undefined") {
      const { errorMsg } = eventData;
      this.setState({ errorMsg, showError: true, isUploading: false });
    }
  };

  redirectToParent = () => {
    this.setState({
      redirectTo:
        this.props.data.baseUrl + "/" + this.props.data.selectedProduct,
    });
  };

  validate = () => {
    let result = validate(
      removeEmptyKeys(this.state.data),
      this.VALIDATION_CONFIG
    );
    this.setState({ validationMsg: result || {} });
    return isEmpty(result);
  };

  copyDirectLinkToClipboard = () => {
    const { directLink } = this.state.data;
    const el = document.createElement("textarea");
    el.value = directLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ copiedToClipboard: true });
  };

  copyCurlCommandToClipboard = () => {
    const { curlCommand } = this.state.data;
    const el = document.createElement("textarea");
    el.value = curlCommand;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ curlCopiedToClipboard: true });
  };
  copyWgetCommandToClipboard = () => {
    const { wgetCommand } = this.state.data;
    const el = document.createElement("textarea");
    el.value = wgetCommand;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ wgetCopiedToClipboard: true });
  };
  saveDetails = () => {
    // validate() will return true incase all fields are valid.
    if (!this.validate()) return;

    const { fileuuid, sendEmail, DATE_FORMAT } = this.state;
    const { expireDate, expireType, file, availableDate } = this.state.data;
    let postObj = Object.assign({}, this.state.data, {
      file: null,
      creationDate: null,
      modifiedDate: null,
    });
    // fix for cross browser issues.
    // const formattedExpireDate = moment(expireDate).format();

    // postObj.expireValue = expireType === "NEVER" ? null : formattedExpireDate;

    if (postObj.expireType && postObj.expireType == "DATE") {
      postObj.expireValue = dateToPacific(expireDate);
    }
    if (postObj.expireType && postObj.expireType == "DAYS") {
      postObj.expireValue = expireDate;
    }

    postObj.platform = postObj.platformId;
    postObj.category = postObj.categoryId;
    postObj.parent_id = postObj.parentId;
    // setting both values to it's default date time format . fix for cross browser issues
    postObj.availableDate = moment(availableDate).format("YYYY-MM-DD");
    postObj.publishStartDate = moment(availableDate).format("YYYY-MM-DD");

    const fileObj =
       file.name && file.size
        ? {
            filename: file.name,
            filetype: file.type ? file.type:null  ,
            filesize: file.size,
            fileuuid,
          }
        : null;

    if (fileObj) postObj = merge(postObj, fileObj);

    const params = { params: { id: this.state.itemId, sendEmail } };
    let url = Constants.App.URL_ITEMS_DETAILS;
    this.props.dispatch({ type: "loading-list" });
    this.setState({ isSaving: true });
    const instance = Api.AxiosInstance.getInstance();
    instance
      .post(url, postObj, params)
      .then((resp) => {
        this.setState({ isSaving: false }, () => {
          this.redirectToParent();

          this.props.dispatch({ type: "reload-products" });
        });
      })
      .catch((error) => {
        this.props.dispatch({ type: "update-list", details: "" });
        const errorMsg =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message;
        this.setState({ isSaving: false, showError: true, errorMsg });
      });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  render() {
    const {
      parentId,
      name,
      friendlyName,
      description,
      categoryId,
      platformId,
      availableDate,
      status,
      file,
      createdBy,
      creationDate,
      modifiedBy,
      modifiedDate,
      directLink,
      curlCommand,
      wgetCommand,
    } = this.state.data;
    const {
      validationMsg,
      isSaving,
      isUploading,
      uploadProgress,
      isLoaded,
      showError,
      errorMsg,
      copiedToClipboard,
      wgetCopiedToClipboard,
      curlCopiedToClipboard,
      isFileChanged,
    } = this.state;
    const metaInfo = {
      createdBy,
      creationDate,
      modifiedBy,
      modifiedDate,
    };
    // console.log(
    //   "product options",
    //   this.props.data.productsDropDownMap,
    //   parentId,
    //   this.state.data
    // );
    if (!isLoaded) return <></>;
    return (
      <Container>
        <Form>
          <ItemDetails
            onChange={this.onChange}
            name={name}
            friendlyName={friendlyName}
            description={description}
            parentId={parentId}
            categoryId={categoryId}
            platformId={platformId}
            availableDate={availableDate}
            status={status}
            file={file}
            parentDropDownDisabled={true}
            productsOptions={this.props.data.productsDropDownMap}
            validationMsg={validationMsg}
            editMode={true}
            defaultExpiry={this.state.data.expireDate}
            metaInfo={metaInfo}
            onFileChange={this.onFileChange}
            uploadProgress={{ isUploading, uploadProgress }}
            DATE_FORMAT={this.state.DATE_FORMAT}
          />
          {directLink && (
            <Grid columns={2} stackable>
              <Grid.Row className="pb-0-im">
                <Grid.Column width={16}>
                  <Form.Field>
                    <label>Direct Link:</label>
                    <a
                      href={directLink}
                      target="_blank"
                      className="word-wrap mr-1"
                    >
                      {directLink}
                    </a>
                    <Button
                      onClick={this.copyDirectLinkToClipboard}
                      disabled={copiedToClipboard}
                    >
                      <Icon name="copy" />
                    </Button>
                  </Form.Field>
                  <Form.Field>
                    <label>Direct Download Commands:</label>
                    <a target="_blank" className="word-wrap mr-1">
                      {curlCommand}{" "}
                    </a>
                    <Button
                      onClick={this.copyCurlCommandToClipboard}
                      disabled={curlCopiedToClipboard}
                    >
                      <Icon name="copy" />
                    </Button>
                  </Form.Field>
                  <Form.Field>
                    <a target="_blank" className="word-wrap mr-1">
                      {wgetCommand}{" "}
                    </a>
                    <Button
                      onClick={this.copyWgetCommandToClipboard}
                      disabled={wgetCopiedToClipboard}
                    >
                      <Icon name="copy" />
                    </Button>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              {isFileChanged && (
                <Checkbox
                  name="sendEmail"
                  label="Send Email notification"
                  checked={this.state.sendEmail}
                  onChange={(event, data) => {
                    this.setState({ sendEmail: data.checked });
                  }}
                />
              )}
            </Grid>
          )}

          <div className="empty-height"></div>
          {showError && <Message error content={errorMsg} />}
          <Modal
            open={showError}
            onClose={() => {
              this.closeErrorPopup();
            }}
            centered={true}
            size="tiny"
            content={errorMsg}
            actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
          ></Modal>
          <div className="row">
            <Button
              className="button-primary m--no-margin-left-im"
              type="submit"
              disabled={isSaving}
              onClick={() => this.showpopupWarning()}
            >
              Save
            </Button>
            <Button
              className="button-basic"
              type="reset"
              disabled={isSaving}
              onClick={(e) => {
                e.preventDefault();
                this.redirectToParent();
              }}
            >
              Cancel
            </Button>
          </div>
          <br />
          <Confirm
            open={this.state.popupWarning}
            onCancel={this.hidepopupWarning}
            onConfirm={() => this.hidepopupWarning(this.saveDetails)}
            content="Are you sure you want to update this item?"
          />
        </Form>
        {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
        <Confirm
          open={this.state.showFriendlyNameWarningPopup}
          onCancel={() => this.hideFNWarning(this.clearFriendlyName)}
          onConfirm={() => this.hideFNWarning(this.updateFriendlyName)}
          content={this.state.friendlyNameWarningMsg}
          confirmButton="Transfer"
        />
      </Container>
    );
  }
}

export default ProductsContext.Consumer(ItemInfo);
