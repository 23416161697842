import Api from "@/api";
import { InputField } from "@/common/Form";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Form, Grid, Segment, Confirm } from "semantic-ui-react";
import { validate } from "validate.js";
import { normalValidation } from "./../../../../validation";

class EditTags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: null,
      createdBy: null,
      modifiedBy: null,
      creationDate: null,
      modificationDate: null,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: "",
      msgSpanText: "",
      msgSpanColor: "black",
      itemLoadXHR: false,
      cancelButton: "Cancel",
      popupWarning: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    
    if (id) {
        
        let params = {
            params: {
              id
            }
        };
        
        Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_ADMIN_TAGS_INFO, params)
        .then(resp => {
            let data = resp.data;
            this.setState({
                id: data.id,
                creationDate: data.creationDate,
                createdBy: data.createdBy,
                modificationDate: data.modificationDate,
                modifiedBy: data.modifiedBy,
                name: data.name,
            });
        });
    }
    
  }

  save = () => {
    const {
        id,
        name,
        createdBy,
        modifiedBy,
        creationDate,
        modificationDate
    } = this.state;
    let data = {
        id,
        name,
        createdBy,
        modifiedBy,
        creationDate,
        modificationDate,
        actionType: "UPDATE_TAG"
    };
    let params = {
        params: {
            id,
        }
    };

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_TAGS_EDIT, data, params)
      .then(resp => {
        let msg = "";
        let color = "black";
        if (resp.data.id > 0) {
          msg = "Tag Edited";
          color = "green";
        } else {
          msg = "Request failed, Please try later.";
          color = "red";
        }

        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
          cancelButton: "Back to Listing"
        });
      });
  };

  handleChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value
    });
  };
  showpopupWarning = () => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (result) {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red"
      });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }
  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  validateForm = event => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red"
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">
            Tag Management
            <Link to="/admin/tags/" className="btn-back-main">
              <FontAwesomeIcon icon={faArrowLeft} title="Back" name="back" />
              &nbsp;Back
            </Link>
          </div>
          <div className="empty-height-small" />

          <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Name"
                    errorMsg={this.state.validationMsg.name}
                    isError={this.state.validationMsg.name}
                    name="name"
                    id="name"
                    maxlength="32"
                    value={this.state.name}
                    onChange={this.handleChange}
                    isRequired
                    placeholder="Enter New Name"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="submit"
                    value="Update"
                    className="button-primary"
                    onClick={this.showpopupWarning}
                  />
                  <Link to="/admin/tags">
                    <button className="button-basic">
                      {this.state.cancelButton}
                    </button>
                  </Link>
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        <Confirm
            open={this.state.popupWarning}
            onCancel={this.hidepopupWarning}
            onConfirm={() => this.hidepopupWarning(this.validateForm)}
            content={"Are you sure you want to edit tag?"}
        />
      </React.Fragment>
    );
  }
}

export default EditTags;
