import React from "react";
import PropTypes from "prop-types"
import { Modal, Button, Segment, Grid } from "semantic-ui-react";
import { UserSelection } from "../../../../common/user-selection";

class BriefcaseSwitch extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      recipients: [],
      errorMsg: "Please select one or more users to switch briefcase item",
      showError: false,
    };
  }

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  onSelection = (users) => {
    this.setState({ recipients: users });
  };

  switchBriefcase = (recipients) => {
    const { onSwitch } = this.props;
    if(recipients.length==0){
      this.setState({ showError: true });
      return;
    }
    onSwitch(recipients)
  }

  render() {
    const { recipients, showError, errorMsg } = this.state;
    const { onClose, onSwitch, show } = this.props;
    return(
      <React.Fragment>
      <Modal open={ show }
        onClose={ onClose }
        centered={ true }
        size="tiny"
      >
        <Modal.Header>Switch Briefcase</Modal.Header>
        <Modal.Content>
          <Grid>  
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <UserSelection label="Switch To" isRequired={ true } selectionType="single-select" onChange={ this.onSelection } />
              </Grid.Column>
          </Grid.Row>
        </Grid>  
         
        </Modal.Content>
         <Modal.Actions className="m--align-right">
            <Button onClick={ () => this.switchBriefcase(recipients) } className="button-primary mr-0-im">Switch</Button>
          </Modal.Actions>
      </Modal>
      <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

BriefcaseSwitch.propTypes = {
  /** Callback which is fired when Popup is closed. */
  onClose: PropTypes.func.isRequired,
  /** Callback which is fired when `switch` button is clicked. */
  onSwitch: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
}

export default BriefcaseSwitch;