import Api from "@/api";
import Constants from "@/config/constants";
import EditForm from "@/modules/admin/users/components/forms/users/edit";
import React, { Component } from "react";
import { Segment, Tab } from "semantic-ui-react";
import ContactTypeForm from "./contact";
import Packages from "./packages";

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      data: {},
    };
  }

  componentDidMount() {
    // fetching to check for userType no other need to fetch data,
    // can be done by some other api call
    // which return status if the id belongs to customer?
    const { id } = this.props.match.params;
    let userRequestOptions = {};
    if (id != null) {
      userRequestOptions.params = { id: id };
      Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_ADMIN_USER_SINGLE, userRequestOptions)
        .then((resp) => {
          this.setState({ form: resp.data });
        });
    }
  }
  isUserTypeCustomer() {
    const { form } = this.state;
    return parseInt(form.userType) === 1 ? true : false;
  }

  render() {
    let editTab = {
      menuItem: "Edit User",
      render: () => (
        <Tab.Pane>
          <EditForm {...this.props} />
        </Tab.Pane>
      ),
    };
    {
      /** hiding this contact tab because not needed**
    let contactTab = {
      menuItem: "Contact Info",
      render: () => (
        <Tab.Pane>
          <ContactTypeForm {...this.state} />
        </Tab.Pane>
      )
    };*/
    }
    let packageTab = {
      menuItem: "Assigned Packages",
      render: () => (
        <Tab.Pane>
          <Packages {...this.props} />
        </Tab.Pane>
      ),
    };

    let allTabs = [editTab, packageTab];
    // pkg and contact tab only visible to customer type user
    let panes = this.isUserTypeCustomer() === true ? allTabs : [editTab];
    return (
      <Segment className="base-segment-wrapper">
        <Tab panes={panes} />
      </Segment>
    );
  }
}

export default EditUser;
