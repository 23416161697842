import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Label,
  Modal,
  Segment
} from "semantic-ui-react";
import { validate } from "validate.js";
import { get, isEmpty } from "lodash";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import ReactPasswordStrength from "react-password-strength";
import { removeEmptyKeys } from "../../common/utils/utils";
import urls from "../../config/constants";
import ErrorMessage from "@/common/Form/subcomponents/error-message";


class ResetPassword extends React.Component {
  validationConfig = {
    password: {
      presence: true,
      length: { minimum: 5 }
    },
    confirmPassword: {
      presence: true,
      equality: "password"
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      password: null,
      showSuccess: false,
      validationMsg: {}
    };
  }

  onPasswordChange = (passwordState, feedback) => {
    this.setState({
      password: passwordState.password,
      passwordScore: get(feedback, ["score"])
    });
  };

  componentDidMount() {
    Axios.post(urls.App.URL_FORGET_PASSWORD_TOKEN, {
      token: get(this.props, ["match", "params", "token"])
    })
      .then(resp => {
        this.setState({ user: resp.data.username });
      })
      .catch(e => {});
  }

  onSubmit = event => {
    event.preventDefault();
    const { password } = this.state;
    let result = validate(removeEmptyKeys(this.state), this.validationConfig);
    if (isEmpty(result)) {
      this.setState({ validationMsg: {}, disabled: true }, () => {
        Axios.post(urls.App.URL_RESET_PASSWORD, {
          token: get(this.props, ["match", "params", "token"]),
          password: password
        })
          .then(resp => {
            this.setState({ showSuccess: true });
          })
          .catch(e => {});
      });
    } else {
      this.setState({ validationMsg: result });
    }
  };

  render() {
    const { disabled, showSuccess, user, validationMsg } = this.state;
    return (
      <React.Fragment>
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 500 }}>
            <Header as="h2" color="black" textAlign="center">
              {/* <Image src="/logo-1.png" /> */}
              Reset Password
            </Header>
            <Form size="large" onSubmit={this.onSubmit} loading={disabled} classNamr="login-wrapper">
              <Segment stacked>
                <Form.Input
                  fluid
                  placeholder="E-mail address or username"
                  value={user}
                  disabled
                  className="mb-3-im"
                />
                {/*{validationMsg.password && (
                  <Label basic color="red" pointing="below">
                    {validationMsg.password}
                  </Label>
                )}*/}
                {/*{validationMsg.passwordScore && (
                  <Label basic color="red" pointing="below">
                    Your password is too weak. Please select a strong password.
                  </Label>
                )}*/}
                <Form.Field required error={validationMsg.password || validationMsg.passwordScore}>

                <ReactPasswordStrength
                  minLength={5}
                  minScore={2}
                  scoreWords={["weak", "okay", "good", "strong", "stronger"]}
                  changeCallback={this.onPasswordChange}
                  inputProps={{ autoComplete: "off", placeholder: "Password" }}
                  userInputs={[user]}
                />
                {/*{validationMsg.confirmPassword && (
                  <Label basic color="red" pointing="below">
                    {validationMsg.confirmPassword}
                  </Label>
                )}*/}
                <ErrorMessage
                  isError={validationMsg.password}
                  errorMsg={validationMsg.password}
                />
                </Form.Field>

                <Form.Field required error={validationMsg.confirmPassword}>

                <Form.Input
                  fluid
                  className="m-0"
                  placeholder="Confirm Password"
                  type="password"
                  onChange={e => {
                    this.setState({ confirmPassword: e.target.value });
                  }}
                />
                <ErrorMessage
                  isError={validationMsg.confirmPassword}
                  errorMsg={validationMsg.confirmPassword}
                />
                </Form.Field>
                <Button
                  className="m--no-margin-im"
                    primary
                    fluid
                    size="large">
                  Submit
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
        <Modal
          open={showSuccess}
          onClose={() => {
            this.props.history.push("/login");
          }}
          centered={true}
          size="tiny"
          content="Your password has been reset."
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(ResetPassword);
