const Reducer = (state, action) => {
  switch (action.type) {
    case "is-admin":
      return {
        ...state,
        isAdmin: true,
      };
    case "route-update":
      return {
        ...state,
        selectedProduct: action.id,
        selectedProductData: action.product,
        isProductsLoading: false,
        showTree: false,
      };
    case "default-browser":
      return {
        ...state,
        selectedProduct: action.id,
        selectedProductData: action.product,
        isProductsLoading: false,
      };
    case "baseurl-update":
      return {
        ...state,
        baseUrl: action.url,
      };
    case "breadcrumbs-update":
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      };
    case "reload-products":
      return {
        ...state,
        reloadProducts: true,
        isProductsLoading: true,
        // setting the loader to false. bug# 56661
        isListLoading: false,
      };
    case "loading-products":
      return {
        ...state,
        isProductsLoading: true,
      };
    case "update-products":
      return {
        ...state,
        products: action.products,
        rawProducts: action.rawProducts,
        productsDropDownMap: action.productsDropDownMap,
        productParentMap: action.productParentMap,
        isProductsLoading: false,
        reloadProducts: false,
        isModuleReady: true,
      };
    case "loading-list":
      return {
        ...state,
        isListLoading: true,
      };
    case "update-list":
      return {
        ...state,
        details: action.details,
        isListLoading: false,
      };
    case "expand-products":
      return {
        ...state,
        expand: action.expand,
      };
    default:
      throw new Error("Unexpected action");
  }
};

export default Reducer;
