import React from "react";
import { Form } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import ErrorMessage from "../common/Form/subcomponents/error-message";

const DatePickerWithTimezone = ({
  selected,
  onChange,
  timezone,
  isRequired,
  width,
  isError,
  label,
  minDate,
  errorMsg,
  ...props
}) => (
  <Form.Field required={isRequired} width={width} error={isError}>
    <label>{label}</label>
    <DatePicker
      selected={selected ? setLocalZone(selected, timezone) : null}
      minDate={setLocalZone(minDate, timezone)}
      onChange={(v, e) => {
        onChange(v ? setOtherZone(v, timezone) : null, e);
      }}
      {...props}
    />
    {/* <ErrorMessage isError={isError} errorMsg={errorMsg} /> */}
  </Form.Field>
);

const setLocalZone = (date, timezone) => {
  const dateWithoutZone = moment
    .tz(date, timezone)
    .format("YYYY-MM-DDTHH:mm:ss.SSS");
  const localZone = moment(dateWithoutZone).format("Z");
  const dateWithLocalZone = [dateWithoutZone, localZone].join("");
  return new Date(dateWithLocalZone);
};

const setOtherZone = (date, timezone) => {
  const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const otherZone = moment.tz(date, timezone).format("Z");
  const dateWithOtherZone = [dateWithoutZone, otherZone].join("");

  return new Date(dateWithOtherZone);
};

export default DatePickerWithTimezone;
