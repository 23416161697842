import Api from "@/api";
import FileIcon from "@/common/file-icon";
import Constants from "@/config/constants";
import { isEmpty } from "lodash";
import React from "react";
import Moment from "react-moment";
import {dateToPacific} from "@/utils/dateTime";
import { Button, Confirm, Image, List, Modal, Segment } from "semantic-ui-react";

class PackageItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignedProducts: { content: [] },
      assignedItems: { content: [] },
      groups: {},
      items: [],
      errorMsg: null,
      showError: false,
      selectedItems: [],
      msg: null,
      action: null,
      deleteWarning: false,
      data: {},
      redirectTo: false,
      groupId: null
    };
  }

  componentDidMount() {
    this.fetchAssignedItems();
  }

  // transformData
  transformData = data => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map(el => {
        let row = Object.assign({}, el);
        row.itemType = (
          <FileIcon
            filename={row.filename ? row.filename : "other"}
            mimetype={row.itemType}
          ></FileIcon>
        );
        row.creationDate = (
          <React.Fragment>
            <>{dateToPacific(row.creationDate,"MM/DD/YYYY hh:mm a")}</>
          </React.Fragment>
        );
        row.actions = (
          <a onClick={() => this.showDeleteWarningAndSetItem(row)}>
            {
              <span
                style={{ margin: "5px" }}
                className="icon-delete"
                title="Remove"
                name="remove"
              />
            }
          </a>
        );

        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  fetchAssignedItems = () => {
    if (this.props.packageId > 0) {
      // Need to add this constants file
      const { URL_PACKAGE_DETAILS } = Constants.App;
      const params = { params: { id: this.props.packageId,
        page: 0,
        target:"_items",sort:"",direction:""
      } };
      const instance = Api.AxiosInstance.getInstance();
      instance.get(URL_PACKAGE_DETAILS, params).then(resp => {
        const transformedData = this.transformData({
          content: resp.data.items
        });
        this.setState({
          assignedItems: transformedData,
          isLoaded: true,
          items: resp.data.items
        });
      });
    }
  };

  doAssign = data => {
    this.setState(
      {
        selectedItems: data,
        action: "ADD_ITEM",
        msg: "Something went wrong while assigning new group(s) to package."
      },
      () => {
        this.updatePackages();
      }
    );
  };

  onUnassign = () => {
    const { selectedItems } = this.state;

    if (isEmpty(selectedItems)) {
      this.setState({
        showError: true,
        errorMsg:
          "No Item selected for deletion. Please select one or more Items."
      });
    } else {
      this.showDeleteWarningAndSetValues(
        "DELETE_ITEM",
        "Something went wrong while unassigning the item(s) to package."
      );
    }
  };
  showDeleteWarningAndSetItem = row => {
    this.setState({
      deleteWarning: true,
      selectedItem: row,
      msg: "Something went wrong while unassigning the item(s) to package.",
      action: "DELETE_ITEM"
    });
  };
  showDeleteWarningAndSetValues = (action, msg) => {
    this.setState({
      msg: msg,
      action: action,
      deleteWarning: true
    });
  };

  hideDeleteWarning = cb => {
    const { action, msg } = this.state;

    this.setState(
      {
        deleteWarning: false
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  updatePackages = () => {
    const { action, msg, selectedItem } = this.state;
    const params = { params: { id: this.props.packageId } };
    let itemIds = [];
    itemIds.push({ id: selectedItem["id"] });
    //selectedItem.map(item => itemIds.push({ id: item.id }));
    let url = Constants.App.URL_PACKAGE_DETAILS;
    let payload = { action: action, items: itemIds };
    Api.AxiosInstance.getInstance()
      .post(url, payload, params)
      .then(resp => {
        this.fetchAssignedItems();
      })
      .catch(error => {
        this.setState({
          showError: true,
          errorMsg: msg
        });
      });
  };

  onPackageSelection = e => {
    this.setState({ selectedItems: e.data });
  };

  closeErrorPopup = () => {
    this.setState({ showError: false });
  };

  render() {
    const { assignedItems, errorMsg, showError, items } = this.state;
    return (
      <React.Fragment>
        
          <List animated selection divided verticalAlign="middle" className="mt-1">
            {items.map(item => (
              <List.Item>
                <List.Content floated="right">
                  <Button
                    onClick={() => this.showDeleteWarningAndSetItem(item)}
                  >
                    Remove
                  </Button>
                </List.Content>
                <Image>
                  <FileIcon
                    filename={item.filename ? item.filename : "other"}
                    mimetype={item.itemTypw}
                  ></FileIcon>
                </Image>
                <List.Content>
                  <List.Header as="a">{item.name} </List.Header>
                  {/*{item.description}*/}
                </List.Content>
              </List.Item>
            ))}
          </List>
       

        <Modal
          open={showError}
          onClose={() => {
            this.closeErrorPopup();
          }}
          centered={true}
          size="tiny"
          content={errorMsg}
          actions={[{ key: "warningDismiss", content: "OK", primary: true }]}
        ></Modal>

        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.updatePackages)}
          content={`Are you sure you want to delete this Item?`}
        />
      </React.Fragment>
    );
  }
}

export default PackageItems;
