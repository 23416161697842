import Api from "@/api";
import Tableview from "@/common/tableview";
import Constants from "@/config/constants";
import {CardviewMobile} from "@/common/cardview";
import React from "react";
import { Confirm, Segment, Grid, Responsive } from "semantic-ui-react";
import {
  default as Add,
  default as Edit
} from "./../components/forms/categories";

class ItemCategories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      createMode: false,
      editMode: false,
      editId: null,
      categoryId: null,
      deleteWarning: false,
      columns: [
        { name: "Name", key: "name", width: "5", showTooltip: true, cb: () => {
          this.sortByHeader("name", "Name");
        }, },
        { name: "Description", key: "description", width: "9", showTooltip: true, cb: () => {
          this.sortByHeader("description", "Description");
        }, },
        { name: "Actions", key: "actions" }
      ],
       responsive_columns: [
        { name: "Name", key: "name", width: "5" },
        { name: "Description", key: "description", width: "9" }
      ],

      listings: { content: [], number: 0 },
      itemLoadXHR: false,
      isDescSearch: true,
      sortBy: "",
    };
  }

  componentDidMount() {
    this.getList();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.state.columns = this.state.columns.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.getList();
    });
   
  };

  getList = () => {
    return Api.AxiosInstance.paginate(
      Constants.App.URL_ADMIN_ITEM_CATEGORIES_LISTING +
        "?page=" +
        this.state.pageNum +
        "&sort="+
        this.state.sortBy
    ).then(resp => {
      this.setState({
        listings: this.transformData(resp.data)
      });
    });
  };

  transformData = data => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map(el => {
        let row = Object.assign({}, el);
        row.actions = (
          <React.Fragment>
            <span
              className="icon-edit"
              onClick={e => this.edit(e, row.id)}
              style={{ margin: "3px" }}
              title="Edit"
              name="edit"
            />
            <span
              className="icon-delete"
              onClick={e => this.showDeleteWarning(e, row.id)}
              style={{ margin: "3px" }}
              title="Remove"
              name="remove"
            />
          </React.Fragment>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  onPageChange = e => {
    this.setState({ pageNum: e.page }, this.getList);
  };

  create = () => {
    this.setState({
      createMode: true,
      items: { content: [] }
    });
  };

  edit = (e, id) => {
    e.preventDefault();
    this.setState({
      editMode: true,
      editId: id,
      items: { content: [] }
    });
  };

  remove = () => {
    // e.preventDefault();
    const { categoryId } = this.state;
    return Api.AxiosInstance.getInstance()
      .get(Constants.App.URL_ADMIN_ITEM_CATEGORY_REMOVE + "?id=" + categoryId)
      .then(resp => {
        this.getList();
      });
  };
  showDeleteWarning = (e, id) => {
    this.setState({
      categoryId: id,
      deleteWarning: true
    });
  };
  hideDeleteWarning = cb => {
    this.setState(
      {
        deleteWarning: false
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  createCancellationHandler = e => {
    e.preventDefault();
    this.setState({
      createMode: false
    });
    this.getList();
  };

  editCancellationHandler = e => {
    e.preventDefault();
    this.setState({
      editMode: false,
      editId: null
    });
    this.getList();
  };

  render() {
    return (
      <React.Fragment>
        {this.state.listings.content &&
          !this.state.editMode &&
          !this.state.createMode && (

           <Segment className="base-segment-wrapper">
            <Grid stackable>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="header">
                    <div className="side-marker" />
                    Item Categories

                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                >
                  <div className="control-action-wrapper">
                <input
                  type="button"
                  className="button-basic"
                  value="Add New"
                  onClick={this.create}
                />
                  </div>
                </Grid.Column>
              </Grid.Row>


               <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16}>

                  <Responsive minWidth={1024}>  
                     <div className="table-wrapper-with-pagination">
                <Tableview
                  paginate
                  data={this.state.listings}
                  columns={this.state.columns}
                  onPaginationChange={this.onPageChange}
                  noDataMessage="No items"
                />
              </div>
                  </Responsive>
                  <Responsive maxWidth={1023}>
                    <CardviewMobile
                        data={this.state.listings}
                        columns={this.state.responsive_columns}
                        // paginate
                        onPaginationChange={e => this.onPageChange(e)}
                    />
                  </Responsive>

                </Grid.Column>
              </Grid.Row>
            </Grid>





             
            </Segment>
          )}
        {this.state.createMode && (
          <Add
            createCancellationHandler={this.createCancellationHandler}
            mode="create"
          />
        )}
        {this.state.editMode && (
          <Edit
            id={this.state.editId}
            createCancellationHandler={this.editCancellationHandler}
            mode="edit"
          />
        )}
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.remove)}
          content="Are you sure you wish to delete this item category?"
        />
      </React.Fragment>
    );
  }
}

export default ItemCategories;
