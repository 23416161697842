import Login from "./login";
import { connect } from "react-redux";
import Api from "../../api";
import { withRouter } from "react-router-dom";
import { generateStateAndNonce, receiveAccessToken } from "../../api/onelogin";
import { configSelector } from "../../common/selectors";

const UserApi = Api.User;

const mapDispatchToProps = (dispatch) => {
  return {
    login: (
      username,
      password,
      code,
      isLoginScreen,
      type,
      twoFactorRenewalCycle,
      addTrustedDevice
    ) =>
      dispatch(
        UserApi.login(
          username,
          password,
          code,
          isLoginScreen,
          type,
          twoFactorRenewalCycle,
          addTrustedDevice
        )
      ),
    logout: () => dispatch(UserApi.logout()),
    oAuthLogin: (type, code) => dispatch(UserApi.oAuthLogin(type, code)),
    generateParams: () => dispatch(generateStateAndNonce()),
    receiveTokens: (accessToken, idToken) => {
      dispatch(receiveAccessToken(accessToken));
      dispatch(UserApi.receiveIdToken(idToken));
    },
    close2FaPopup: () => dispatch(UserApi.close2FaPopup()),
    gettingOktaToken: () => dispatch(UserApi.gettingOktaToken()),
    recievedOktaToken: () => dispatch(UserApi.recievedOktaToken()),
  };
};

const mapStateToProps = (state, props) => ({
  user: state.user,
  config: configSelector(state, props),
  token: state.token.idToken,
  accessToken: state.token.accessToken,
  state: state.token.state,
  nonce: state.token.nonce,
  oktaLoader: state.token.oktaLoader,
  oktaUserDisabled: state.token.oktaUserDisabled,
  oktaErrorOnVerification: state.token.oktaErrorOnVerification
});

const mappedLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Login));

export default {
  name: "Login",
  path: "/login",
  component: mappedLogin,
  navbarVisibility: false,
};
