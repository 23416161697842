import Api from '@/api';
import { InputField, ErrorMessage } from '@/common/Form';
import { removeEmptyKeys, validateDomain } from '@/common/utils/utils';
import Constants from '@/config/constants';
import LocalStore from "@/store/persistence/local-storage";

import {
  faArrowLeft,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Confirm,
  Form,
  Grid,
  Segment,
  Tab,
  Divider,
  Popup,
  Icon, Input,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { validate } from 'validate.js';
import { normalValidation } from './../../../../validation';

// TODO: clean this component and break this into sub modules
class GroupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      description: null,
      domain: null,
      watermark: 1,
      dateStamp: true,
      community_feature_enabled: 1,
      tech_support: 'ENABLED',
      tier1: 1,
      group_type: 'CUSTOMER',
      status: 'ACTIVE',
      created_by: null,
      creation_date: null,
      modification_date: null,
      modified_by: null,
      msgSpanText: '',
      msgSpanColor: 'black',
      msgSpanTextAssign: '',
      msgSpanColorAssign: 'black',
      selectedUsers: null,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: '',
      usersPageNum: 0,
      userId: null,
      deleteWarning: false,
      cancelButton: 'Cancel',
      showDomainError: false,
      groupName: null,
      ndaInformation: false,
      userColumns: [
        { name: 'Username', key: 'username', width: '4' },
        { name: 'First Name', key: 'firstName', width: '4' },
        { name: 'Last Name', key: 'lastName', width: '3' },
        { name: 'Company', key: 'company', width: '4' },
        { name: 'Actions', key: 'remove', width: '1' },
      ],
      userListings: { content: [], number: 0 },
      packagesPageNum: 0,
      packageColumns: [
        { name: 'Select', key: 'select', width: '1' },
        { name: 'Package Name', key: 'packageName', width: '6' },
        { name: 'Assigned By', key: 'createdBy', width: '4' },
        { name: 'Assigned On', key: 'creationDate', width: '5' },
      ],
      packageListings: { content: [], number: 0 },
      popupWarning:false
    };
  }

  componentDidMount() {
    console.log('propss', this.props);
  }

  loadConfigOptions = () => {
    const token = LocalStore.getValue("token");
    this.props.loadApplicationConfig(token);
  };
  save = () => {
    const { id } = this.props.match.params;
    const {
      name,
      description,
      community_feature_enabled,
      tier1,
      group_type,
      status,
      createdBy,
      tech_support,
      creationDate,
      modificationDate,
      modifiedBy,
      domain,
      watermark,
      dateStamp,
      line1,
      line2,
      line3,
    } = this.state;
    let data = {
      id,
      name,
      description,
      communityFeatureEnabled: community_feature_enabled,
      techSupport: tech_support === 'ENABLED' ? 1 : 0,
      tier1,
      groupType: group_type,
      status,
      createdBy,
      creationDate,
      modificationDate,
      modifiedBy,
      domain,
      watermark: watermark ? 1 : 0,
      dateStamp: dateStamp ? 1 : 0,
      line1,
      line2,
      line3,
    };

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_ADD_GROUP, data)
      .then((resp) => {
        let msg = '';
        let color = 'black';
        if (resp.data.id > 0) {
          msg = this.props.id ? 'Group Updated' : 'Added new group';
          color = 'green';
        } else {
          msg = 'Request failed, Please try later.';
          color = 'red';
        }

        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
          cancelButton: 'Back to Listing',
        });
        this.loadConfigOptions();
      }).catch((e, d) => {
          let msg = e.response.data.message;
          let color = 'red';
          this.setState({
            msgSpanText: msg,
            msgSpanColor: color,
            loading: false,
            cancelButton: 'Back to Listing',
          });
        });
  };
  onGenericChange = (event, eventData) => {
    this.setState({ [eventData.name]: eventData.value });
  };
  hideDeleteWarning = (cb) => {
    this.setState(
      {
        deleteWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  hideDomainError = (cb) => {
    this.setState(
      {
        showDomainError: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  removeDomain = () => {
    const { domain } = this.state;
    let params = {
      params: {
        domain,
      },
    };
    if (domain) {
      return Api.AxiosInstance.getInstance()
        .get('/admin/group/remove-domain', params)
        .then((resp) => {
          if (resp.data) {
            this.setState({
              showDomainError: false,
              groupName: null,
            });
          }
        })
        .catch((resp) => {
          console.log('error');
        });
    }
    return;
  };
  checkDomainAvailability = () => {
    const { domain } = this.state;
    let params = {
      params: {
        domain,
      },
    };
    if (domain) {
      return Api.AxiosInstance.getInstance()
        .get('/admin/group/check-domain', params)
        .then((resp) => {
          console.log('safs', resp);
          if (resp.data) {
            this.setState({
              showDomainError: true,
              groupName: resp.data.name,
            });
          } else
            this.setState({
              showDomainError: false,
              groupName: null,
            });
        })
        .catch((resp) => {
          console.log('error');
        });
    }
    return;
  };

  handleDomainChange = (e) => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    }, () => {
      const validated = validateDomain(value);
      this.setState({
        validationMsg: {domain: !validated ? "Please enter a valid domain." : null}
      })
    });
  };
  onByteChange = (event, eventData) => {
    let value = eventData.value ? 1 : 0;
    this.setState({
      [eventData.name]: value,
    });
  };

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    });
  };

  handleStatusChange = (e) => {
    this.setState({
      status: e.target.value,
    });
  };

  handleTechSupportChange = (e) => {
    this.setState({
      tech_support: e.target.value,
    });
  };

  handleGroupTypeChange = (e) => {
    this.setState({
      group_type: e.target.value,
    });
  };

  userSelectionCallback = (users) => {
    this.setState({
      selectedUsers: users,
    });
  };

  showpopupWarning = () => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (result) {
      this.setState({
        validationMsg: result,
        msgSpanText:
          'There are some errors with the submission. Please fix and retry.',
        msgSpanColor: 'red',
      });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }
  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };

  validateForm = (event) => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    const invalid_domain = !validateDomain(this.state.domain);
    if (!result && !invalid_domain) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: {...result, domain: invalid_domain ? "Please enter a valid domain." : null},
        msgSpanText:
          'There are some errors with the submission. Please fix and retry.',
        msgSpanColor: 'red',
      });
    }
  };

  getForm = () => {
    return (
      <React.Fragment>
        <row className="base-segment-wrapper">
          <div className="empty-height-small" />

          <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Name"
                    errorMsg={this.state.validationMsg.name}
                    isError={this.state.validationMsg.name}
                    name="name"
                    id="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    maxlength={32}
                    isRequired
                    placeholder="Enter Group Name"
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Description"
                    errorMsg={this.state.validationMsg.description}
                    isError={this.state.validationMsg.description}
                    name="description"
                    id="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    maxlength={32}
                    placeholder="Enter Group Description"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <InputField
                    label="Domains (comma separated)"
                    errorMsg={this.state.validationMsg.domain}
                    isError={this.state.validationMsg.domain}
                    name="domain"
                    id="domain"
                    value={this.state.domain}
                    onChange={this.handleDomainChange}
                    maxlength={250}
                    placeholder="Domains (comma separated)"
                  />
                  {/* <Button onClick={(e) => this.checkDomainAvailability()}>
                    Check Availability
                    </Button> */}
                  {/* <Confirm
                    open={this.state.showDomainError}
                    onCancel={this.hideDomainError}
                    onConfirm={() => this.hideDomainError(this.removeDomain)}
                    content={`Are you sure you want to delete the domain from other group?`}
                  /> */}

                  {this.state.showDomainError && (
                    <>
                      <label color="red">
                        <em>
                          This domain is already Asssociated with other group{' '}
                        </em>{' '}
                      </label>
                      <Button onClick={(e) => this.removeDomain()}>
                        {' '}
                        Delete Domain from other Group?{' '}
                      </Button>
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label style={{ fontWeight: 'bold' }}>
                    Status <span style={{ color: 'red' }}>*</span>
                  </label>

                  <div className="field">
                    <select
                      className="ui fluid dropdown"
                      id="status"
                      name="status"
                      value={this.state.status}
                      onChange={this.handleStatusChange}
                    >
                      <option value="ACTIVE">Active</option>
                      <option value="DISABLED">Disabled</option>
                    </select>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <label style={{ fontWeight: 'bold' }}>
                    Support
                  </label>

                  <div className="field">
                    <select
                        className="ui fluid dropdown"
                        id="tech_support"
                        name="tech_support"
                        value={this.state.tech_support}
                        onChange={this.handleTechSupportChange}
                    >
                      <option value="ENABLED">Enabled</option>
                      <option value="DISABLED">Disabled</option>
                    </select>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="label-signup-policy">
                  <Checkbox
                    name="watermark"
                    label="Enable PDF watermark for Group"
                    checked={this.state.watermark}
                    // isRequired
                    onChange={(event, data) =>
                      this.onByteChange(event, {
                        name: data.name,
                        value: data.checked,
                      })
                    }
                  />
                </div>
              </Grid.Column>

              {this.state.watermark ? (
                <React.Fragment>
                  <>
                    <InputField
                      // errorMsg={validationMsg.line1}
                      //isError={validationMsg.line1}
                      label={`Watermark Line 1`}
                      name="line1"
                      maxlength={'64'}
                      onChange={this.handleChange}
                      popup={true}
                      content={
                        'Default: ' + this.props.config.maps.pdf_watermark_line1
                      }
                      // isRequired
                    />
                  </>
                  <InputField
                    label="Watermark Line 2"
                    name="line2"
                    maxlength={64}
                    popup={true}
                    content={'Default: {Firstname} {Lastname}'}
                    onChange={this.handleChange}
                  />
                  <InputField
                    label="Watermark Line 3"
                    name="line3"
                    popup={true}
                    content={'Default: {Company Name}'}
                    onChange={this.handleChange}
                    maxlength={64}
                  />
                  <Form.Field>
                    <Checkbox
                      name="dateStamp"
                      label="Date Stamp"
                      checked={this.state.dateStamp}
                      onChange={(event, data) =>
                        this.onByteChange(event, {
                          name: data.name,
                          value: data.checked,
                        })
                      }
                    />
                  </Form.Field>
                </React.Fragment>
              ) : (
                ''
              )}
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="label-signup-policy">
                  <Checkbox
                    name="ndaInformation"
                    label="Add NDA Information"
                    checked={this.state.ndaInformation}
                    onChange={(event, data) =>
                      this.onByteChange(event, {
                        name: data.name,
                        value: data.checked,
                      })
                    }
                  />
                </div>
              </Grid.Column>
              {this.state.ndaInformation ? (

// Link to NDA
// a. Field for URL from CLM system
// New NDA FIle (PDF file only)
// Company Name
// Information Being Disclosed (Max 1000)
// Effective Date
// Duration of NDA (years)
// Status Notification Emails
// Status (Default: ACTIVE)
                <React.Fragment>
                    <InputField
                      label={`Link to NDA`}
                      name="Link to NDA"
                      maxlength={'64'}
                      onChange={this.handleChange}
                    />
                  <Form.Field>
               
                  </Form.Field>
                </React.Fragment>
              ) : (
                ''
              )}
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="button"
                    className="button-primary"
                    value="Save"
                    onClick={this.showpopupWarning}
                  />
                  &nbsp;&nbsp;
                  <Link to="/admin/user-groups">
                    <button className="button-basic">
                      {this.state.cancelButton}
                    </button>
                  </Link>
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Confirm
             open={this.state.popupWarning}
             onCancel={this.hidepopupWarning}
             onConfirm={() => this.hidepopupWarning(this.validateForm)}
             content="Are you sure you want to update group?"
            />
          </Form>
        </row>
      </React.Fragment>
    );
  };

  render() {
    let tab1Title = 'Create Group';
    let tab1 = {
      menuItem: tab1Title,
      render: () => <Tab.Pane>{this.getForm()}</Tab.Pane>,
    };
    let panes = [tab1];

    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">
            Group Management
            <Link to="/admin/user-groups" className="btn-back-main">
              <FontAwesomeIcon icon={faArrowLeft} title="Back" name="back" />
              &nbsp;Back
            </Link>
          </div>
          <div className="empty-height" />

          <Tab panes={panes} />
        </Segment>
        <Confirm
          open={this.state.deleteWarning}
          onCancel={this.hideDeleteWarning}
          onConfirm={() => this.hideDeleteWarning(this.remove)}
          content="Are you sure you want to delete this Group?"
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadApplicationConfig: (token) => {
      dispatch(Api.Config.loadApplicationConfig(token));
    },
  };
};
const mapStateToProps = (state) => ({
  config: state.common.config,
});

const GroupFormMapped = connect(mapStateToProps, mapDispatchToProps)(GroupForm);
export default GroupFormMapped;
