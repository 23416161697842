import React from "react";
import PackageList from "../components/package-list";
import urls from "@/config/constants";

const PackageListWrapper = () => {
  const { URL_PACKAGE_REPORT_LIST } = urls.App;
  return (
    <PackageList
      title="Package Report"
      fetchUrl={URL_PACKAGE_REPORT_LIST}
    />
  );
};

export default PackageListWrapper;
