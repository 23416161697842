import Package from "@/common/package/index";
import PropTypes from "prop-types";
import React from "react";
import { matchPath, Route, withRouter } from "react-router-dom";
import { Grid, Menu, Responsive, Segment, Sidebar } from "semantic-ui-react";
import validate from "validate.js";
import Config from "@/config/constants";
import Product from "../product";

const ADMINISTRATORS = Config.AccessRole.ADMINISTRATORS;

class PageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actimeMenuItem: null,
      activeMenuHeader: null,
      isSideMenuVisible: false,
      packagePaneVisible: false,
    };
  }

  componentDidMount = () => {
    const { menuItems } = this.props;
    this.determineMenuItemToSelect(menuItems);
  };

  componentDidUpdate(oldProps, oldState) {
    // @TODO - make this to update on link change, add <Link> in Menu Item in the render function
    if(oldProps.location && this.props.location
      && oldProps.location.pathname !== this.props.location.pathname) {
        this.determineMenuItemToSelect(this.props.menuItems);
    }
  }

  determineMenuItemToSelect = (menuItems) => {
    const { location, baseUrl } = this.props;
    Object.keys(menuItems).forEach((key) => {
      menuItems[key].forEach((item) => {
        const path = item.relativePath;
        const locationItem = matchPath(location.pathname, baseUrl + path);
        if (locationItem) {
          this.setState({
            activeMenuHeader: key,
            actimeMenuItem: item.name,
            packagePaneVisible: item.includePackagePane,
          });
        }
      });
    });
  };

  toggleSideMenu = () => {
    this.setState({
      isSideMenuVisible: !this.state.isSideMenuVisible,
    });
  };

  onMenuItemClick = (name, path, header, subheader, includePackagePane) => {
    const { baseUrl } = this.props;
    // this work is needed to be done for only media devices
    this.setState({ isSideMenuVisible: false });
    this.setState(
      {
        actimeMenuItem: subheader,
        activeMenuHeader: header,
        packagePaneVisible: includePackagePane,
      },
      () => {
        this.props.history.push(baseUrl + path);
      }
    );
  };

  render() {
    const {
      actimeMenuItem,
      activeMenuHeader,
      isSideMenuVisible,
      packagePaneVisible,
    } = this.state;
    const { baseUrl, menuItems } = this.props;
    return (
      <React.Fragment>
        <Responsive minWidth={320} maxWidth={1024}>
          <Segment className="hide-container" style={{}}>
            <Grid centered>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={13}
                  widescreen={13}
                >
                  {isSideMenuVisible ? (
                    <span></span>
                  ) : (
                    <span
                      className="icon-menu btn-mobile-sub-nav-open"
                      onClick={this.toggleSideMenu}
                    />
                  )}
                  {Object.keys(menuItems).map((_key) => (
                    <React.Fragment key={`fragment--${_key}`}>
                      {menuItems[_key].map((_menuItem) => (
                        <Route
                          key={`menu-item-mobile-${_key}-${_menuItem.name}`}
                          exact={_menuItem.exact}
                          path={baseUrl + _menuItem.relativePath}
                          component={_menuItem.subPageComponent}
                        />
                      ))}
                    </React.Fragment>
                  ))}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Sidebar
              as={Menu}
              animation="overlay"
              direction="right"
              vertical
              visible={isSideMenuVisible}
              className="sub-nav-right-wrapper mobile-view"
            >
              <Menu
                vertical
                tabular="right"
                borderless
                compact
                className="secondary-nav-menu"
              >
                <span
                  className="icon-ico-menu-close btn-mobile-sub-nav-close"
                  onClick={this.toggleSideMenu}
                />
                {Object.keys(menuItems).map((_key) => (
                  <Menu.Item key={`menu-section-${_key}`}>
                    <Menu.Header key={`menu-header-${_key}`}>
                      <span
                        className={
                          _key === activeMenuHeader
                            ? "ico-box-wrap ico-side-nav active"
                            : "ico-box-wrap ico-side-nav"
                        }
                      >
                        <span className={_key}></span>
                      </span>
                      <span className="nav-text">
                        {_key == "NDAs"
                          ? (_key = "NDAs")
                          : validate.capitalize(validate.prettify(_key))}
                      </span>
                    </Menu.Header>

                    <Menu.Menu
                      key={`menu-submenu-${_key}`}
                      className="secondary-nav-menu-sub-nav"
                    >
                      {menuItems[_key].map(
                        (_menuItem) =>
                          !_menuItem.hide && (
                            <Menu.Item
                              key={`submenu-item-mobile-${_key}-${_menuItem.name}`}
                              active={actimeMenuItem === _menuItem.name}
                              className={
                                _menuItem.name === actimeMenuItem
                                  ? "active"
                                  : "inactive"
                              }
                              onClick={(event, data) =>
                                this.onMenuItemClick(
                                  data.name,
                                  _menuItem.clickPath || _menuItem.relativePath,
                                  _key,
                                  _menuItem.name,
                                  _menuItem.includePackagePane
                                )
                              }
                            >
                              {_menuItem.name}
                            </Menu.Item>
                          )
                      )}
                    </Menu.Menu>
                  </Menu.Item>
                ))}
              </Menu>
            </Sidebar>
          </Segment>
        </Responsive>
        <Responsive minWidth={1025} stackable="true" size="large" borderless>
          <Segment className="hide-container" style={{}}>
            <Grid columns="2" centered>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={13}
                  widescreen={13}
                >
                  {Object.keys(menuItems).map((_key) => (
                    <React.Fragment key={`fragment--${_key}`}>
                      {menuItems[_key].map((_menuItem) => (
                        <Route
                          key={`menu-item-${_key}-${_menuItem.name}`}
                          exact={_menuItem.exact}
                          path={baseUrl + _menuItem.relativePath}
                          component={_menuItem.subPageComponent}
                        />
                      ))}
                    </React.Fragment>
                  ))}
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={4}
                  largeScreen={3}
                  widescreen={3}
                  className="pl-0-im"
                >
                  <Segment className="sub-nav-right-wrapper">
                    <Menu
                      vertical
                      tabular="right"
                      borderless
                      compact
                      className="secondary-nav-menu"
                    >
                      {Object.keys(menuItems).map((_key) => (
                        <Menu.Item key={`menu-section-${_key}`}>
                          <Menu.Header key={`menu-header-${_key}`}>
                            <span
                              className={
                                _key === activeMenuHeader
                                  ? "ico-box-wrap ico-side-nav active"
                                  : "ico-box-wrap ico-side-nav"
                              }
                            >
                              <span className={_key}></span>
                            </span>
                            <span className="nav-text">
                              {_key == "NDAs"
                                ? (_key = "NDAs")
                                : validate.capitalize(validate.prettify(_key))}
                            </span>
                          </Menu.Header>

                          <Menu.Menu
                            key={`menu-submenu-${_key}`}
                            className="secondary-nav-menu-sub-nav"
                          >
                            {menuItems[_key].map(
                              (_menuItem) =>
                                !_menuItem.hide && (
                                  <Menu.Item
                                    key={`submenu-item-${_key}-${_menuItem.name}`}
                                    active={actimeMenuItem === _menuItem.name}
                                    className={
                                      _menuItem.name === actimeMenuItem
                                        ? "active"
                                        : "inactive"
                                    }
                                    onClick={(event, data) =>
                                      this.onMenuItemClick(
                                        data.name,
                                        _menuItem.clickPath ||
                                          _menuItem.relativePath,
                                        _key,
                                        _menuItem.name,
                                        _menuItem.includePackagePane
                                      )
                                    }
                                  >
                                    {_menuItem.name}
                                  </Menu.Item>
                                )
                            )}
                          </Menu.Menu>
                        </Menu.Item>
                      ))}
                    </Menu>
                  </Segment>
                  {/* Additional Panel For Products Module
                    TODO: additional module should be rendered on URL basis*/}

                  {ADMINISTRATORS.indexOf(this.props.authority) >= 0 &&
                    packagePaneVisible && (
                      <Segment>
                        <Package />
                      </Segment>
                    )}
                    {/* Additional Panel For Folders
                    TODO: additional module should be rendered on URL basis*/}
                  {ADMINISTRATORS.indexOf(this.props.authority) >= 0 &&
                    packagePaneVisible && (
                      <Segment>
                        <Product/>
                      </Segment>
                    )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Responsive>
      </React.Fragment>
    );
  }
}


PageTemplate.propTypes = {
  /** base URL of the page  */
  baseUrl: PropTypes.string.isRequired,
  /** Add icon (.png format) with the same name as of MENU_ITEMS in static folder of Public.
   place profile, myFriends icons in eg: public/static/profile.png */
  /** Contains the name, relative path and sub-page component. */
  /**
   * SAMPLE:
   * MENU_ITEMS = {
      profile: [
        { name: 'View Profile', relativePath:'/', exact: true, subPageComponent: <ViewProfile /> },
        { name: 'Edit Profile', relativePath:'/pending', subPageComponent: <EditProfile /> },
      ],
      myFriends: [
        { name: 'View Friends', relativePath:'/friends', exact: true, subPageComponent: <ViewFriends /> },
        { name: 'Pending Requests', relativePath:'/friends-pending', subPageComponent: <PendingFriends /> },
      ],
    };
  *
  */
  menuItems: PropTypes.object.isRequired,
};

export default withRouter(PageTemplate);
