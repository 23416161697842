import Licenses from "./licenses";
import { connect } from "react-redux";
import Api from "../../api";
import Config from "../../config/constants";

const UserApi = Api.User;

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(UserApi.login(username, password)),
    logout: () => dispatch(UserApi.logout()),
    oAuthLogin: (type, code) => dispatch(UserApi.oAuthLogin(type, code)),
  };
};
const baseUrl = "/licenses";

const mapStateToProps = (state) => ({
  user: state.user,
  config: state.common.config,
  baseUrl: baseUrl,
});

const mappedLicenses = connect(mapStateToProps, mapDispatchToProps)(Licenses);

export default {
  name: "Licenses",
  configKey: "license_menu_item_title",
  path: baseUrl,
  component: mappedLicenses,
  isPrivateRoute: true,
  accessRoles: Config.AccessRole.ADMINISTRATORS,
  navbarVisibility: false,
};
