import React from 'react';
import { Icon } from 'semantic-ui-react';
import MimeTypeMap from './utils/file-extensions-mime-types';
import FontsIcon from './utils/font-mapping.js';

const FONT_ARRAY = {
  'application/pdf': 'icon-pdf',
  'application/x-pdf': 'icon-pdf',
  PDF: 'icon-pdf',
  link: 'icon-file-link',
  MP4: 'icon-video',
  'PDF file': 'icon-pdf',
  'application/vnd.jupyter': 'icon-file-jupyter',
  'text/x-python': 'icon-file-python',
  multiple: 'icon-multiple-files',
  'application/x-rar-compressed': 'icon-winrar',
  'application/x-zip-compressed': 'icon-winzip',
  'application/zip,application/x-zip-compressed': 'icon-winzip',
  'application/x-compressed-tar,application/x-gzip-compressed,application/x-compressed,application/x-gtar,application/x-gzip':
    'icon-winzip',
  'application/x-tar': 'icon-cpio',
  'application/x-cpio': 'icon-cpio',
  'audio/wav': 'icon-video',
  'video/mp4': 'icon-video',
  'image/jpeg': 'icon-jpg',
  'image/png': 'icon-png',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'icon-word',
  'text/plain': 'icon-file-copy',
  'application/all': 'icon-file-copy',
  'text/html': 'icon-html-5',
  'application/x-msdownload': 'icon-microsoft-executeable',
  'application/octet-stream': 'icon-linux',
  'application/vnd.ms-powerpoint': 'icon-powerpoint',
  'application/powerpoint,application/mspowerpoint,application/vnd.ms-powerpoint':
    'icon-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'icon-powerpoint',
  'application/msword': 'icon-word',
  'application/msword,application/word,application/vnd.ms-word,application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'icon-word',
  'application/vnd.ms-excel': 'icon-excel',
  'application/x-ms-excel,application/vnd.ms-excel': 'icon-excel',
  'application/excel,application/msexcel,application/vnd.ms-excel':
    'icon-excel',
  'application/x-gzip': 'icon-winzip',
  'application/x-bzip2': 'icon-winzip',
  'application/zip': 'icon-winzip',
  'image/svg+xml': 'icon-svg',
  'application/x-rpm': 'icon-rpm-file-format-symbol',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
  'icon-excel',
  'application/vnd.debian.binary-package' : 'icon-debian',
  'application/x-debian-package': 'icon-debain',
};
function createSpanElements(n) {
  var elements = [];
  for (var i = 1; i <= n; i++) {
    elements.push(<span className={'path' + i}></span>);
  }
  return elements;
}
export default function ({ mimetype, filename }) {
  if (!mimetype) {
    // handling where filename extension in uppercase
    const extension = filename.split('.').pop().toLowerCase();
    mimetype = MimeTypeMap['.' + extension];
    //  console.log('icon', MimeTypeMap, mimetype, filename, extension)
  } // else console.log('fileType', mimetype);

  if (FONT_ARRAY[mimetype])
    return (
      <span className={FONT_ARRAY[mimetype]}>
        {createSpanElements(FontsIcon[FONT_ARRAY[mimetype]])}
      </span>
    );
  else
    return <span className="icon-others-files">{createSpanElements(2)}</span>;
}
