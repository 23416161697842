import React from "react";
import PropTypes from "prop-types";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import {Button, Confirm, Dimmer, Grid, GridColumn, GridRow, Loader, Modal, Responsive} from "semantic-ui-react";
import Axios from "axios";
import Constants from "../config/constants";
import { Header, SideBar } from "./groq";
import LocalStore from "@/store/persistence/local-storage";
import PageNotFound from "../modules/404";
import Api from "../api";
import AccessRoute from "../config/routes/access-route";
import PublicRoute from "../config/routes/public-route";
import PrivateRoute from "../config/routes/private-route";
import PDFviwer from "../common/pdf-viewer";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayPage: false,
      visible: false,
    };
  }

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  hideSideBar = () => {
    this.setState({ visible: false });
  };

  componentDidMount() {
    this.attemptAutoLogin();
  }

  attemptAutoLogin = () => {
    // first check if there is a token in local storage.
    const token = LocalStore.getValue("token");

    // if token is null means there is no user. show the dynamic route.
    if (!token){
      this.setState({ displayPage: true });
      // There is no user. Explicitly passing null to avoid confusion.
      this.props.loadApplicationConfig(null);
    // now verify if the token is correct.
    } else {
      Axios.get(Constants.App.URL_LOGGEDIN_USER, {
        headers: { Authorization: token },
      })
      .then((resp) => {
        Api.AxiosInstance.create(token);
        this.props.saveUser(resp.data);
        this.props.loadApplicationConfig(token);
      })
      .catch((e) => {
        this.props.logout({"error": true, "message":"unable to authenticate token while attempt autologin"});
        // Authentication failed. Explicitly passing null to avoid confusion.
        this.props.loadApplicationConfig(null);
      })
      .finally(() => {
        this.setState({ displayPage: true })
        this.props.checkIfSupportEnabledForTheUser()
      });
    }
  };

  closeSupportErrorDialog() {
    this.props.showHideSupportErrorDialog(false);
  }

  overlayClicked = () => {
    this.props.hideMainOverlay();
    this.props.hideSidebarNotifications();
  }

  render() {
    const { isLoggedIn } = this.props.user;
    const { displayPage, visible } = this.state;
    const { routes, accessRole, config } = this.props;

    // if a user is logged in, in that case we will have a value in this.props.accessRole
    // otherwise we will use a default value of highest privileged access role.
    const curAccessRole = accessRole || "websilo-sysadmin";

    return (
      <div>
        {displayPage && (
          <Router>
            <div className="fp-wrapper">
              <div className="fp-panel-main">
                <Header
                  routes={routes}
                  toggle={this.toggleVisibility}
                  visible={visible}
                  userAccessRole={curAccessRole}
                  {...this.props}
                />
              </div>{' '}
            </div>
            <Responsive maxWidth={1024}>
              <div className="sidebar">
                <SideBar
                  routes={routes}
                  visible={visible}
                  hide={this.hideSideBar}
                  userAccessRole={curAccessRole}
                />
              </div>
            </Responsive>
            {
              <div style={{ minHeight: '80vh' }}>
                <Switch>
                  {routes.map((route, index) => {
                    if (route.isPrivateRoute) {
                      if (route.accessRoles.indexOf(curAccessRole) >= 0) {
                        return (
                          <PrivateRoute
                            key={`route--${index}`}
                            {...route}
                            isLoggedIn={isLoggedIn}
                            fallback="/login"
                          />
                        );
                      } else return null;
                    } else if (route.isAccessRoute)
                      return (
                        <AccessRoute
                          key={`route--${index}`}
                          {...route}
                          isLoggedIn={isLoggedIn}
                          fallback="/dashboard"
                        />
                      );
                    else
                      return <PublicRoute key={`route--${index}`} {...route} />;
                  })}
                  <Route render={PageNotFound.LoginRedirect} />
                </Switch>
              </div>
            }
            <Modal
              onClose={() => this.closeSupportErrorDialog()}
              open={
                config.supportErrorDialog &&
                config.supportErrorMsg.search('phone') < 0
              }
              size={'small'}
            >
              <Modal.Content scrolling>{config.supportErrorMsg}</Modal.Content>
              <Modal.Actions>
                <Button
                  content="Ok"
                  onClick={() => this.closeSupportErrorDialog()}
                  positive
                />
              </Modal.Actions>
            </Modal>
            <Modal
              onClose={() => this.closeSupportErrorDialog()}
              open={
                config.supportErrorDialog &&
                config.supportErrorMsg.search('phone') > -1
              }
              size={'small'}
            >
              <Modal.Content scrolling>
                A valid phone number is required for support. You can update
                your phone number under My Activities > Profile. If you continue
                to have this issue, please email{' '}
                <a href="mailto:support@groq.com">support@groq.com</a>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Ok"
                  onClick={() => this.closeSupportErrorDialog()}
                  positive
                />
              </Modal.Actions>
            </Modal>
            {config.showLoader && (
              <Dimmer active inverted className={'main-loader'}>
                <Loader />
              </Dimmer>
            )}
            {config.showOverlay && (
              <Dimmer active inverted onClick={this.overlayClicked} className={'main-overlay'}>
                &nbsp;
              </Dimmer>
            )}
          </Router>
        )}
      </div>
    );
  }
}

Layout.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Layout;
