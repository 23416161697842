import React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import {
  Button,
  Container,
  Input,
  Modal,
  Segment,
  Grid,
  Responsive,
} from "semantic-ui-react";
import { CardviewMobile } from "@/common/cardview";
import Tableview from "../../../../../common/tableview";
import { parameterStringify } from "../../../../../common/utils/utils";
import Api from "@api";
import urls from "@constants";
import { merge } from "lodash";

class ProductListing extends React.Component {
  Constants = {
    COLUMNS: [
      { name: "Product Name", key: "name", showTooltip: true, cb: () => {
        this.sortByHeader("name", "Product Name");
      }, },
      { name: "Description", key: "description", showTooltip: true, cb: () => {
        this.sortByHeader("description", "Description");
      }, },
      { name: "status", key: "status", showTooltip: true, cb: () => {
        this.sortByHeader("status", "status");
      }, },
    ],
    RESPONSIVE_COLUMNS: [
      { name: "Product Name", key: "name" },
      { name: "Description", key: "description" },
      { name: "status", key: "status" },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      ProductList: {},
      configOption: {},
      selectedProducts: [],
      selectedStatedProducts: [],
      selectedProductsIndexes: [],
      checkedProductsIds: [],
      isDescSearch: true,
      sortBy: "creationDate,DESC",
    };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  sortByHeader = (key, title) => {
    let query = `${key},DESC`;
    const isDesc = this.state.isDescSearch;
    if (!isDesc) {
      query = `${key},ASC`;
    }
    this.setState({ sortBy: query }, () => {
      this.Constants.COLUMNS = this.Constants.COLUMNS.map((obj) => {
        if (obj.name == title) {
          return {
            ...obj,
            isDesc: isDesc,
            isActive: true,
          };
        } else
          return {
            ...obj,
            isActive: false,
          };
      });
      this.setState({ isDescSearch: !isDesc });
      this.fetchUsers();
    });
   
  };
  
  fetchUsers = () => {
    const { URL_PRODUCT_PAGED_LIST } = urls.App;
    let { configOption } = this.state;
    const { entity, entityId } = this.props;
    configOption = merge(configOption,{sort:this.state.sortBy}) 

    const paramsForPerspective = {
      entity,
      entityId,
    };
    // entityId concat configOptions
    const params = {
      params: merge(
        configOption,
        entity && entityId ? paramsForPerspective : {}
      ),
    };
    const parameterizedUrl = `${URL_PRODUCT_PAGED_LIST}`;
    const instance = Api.AxiosInstance.getInstance();
    return instance.get(parameterizedUrl, params).then((resp) => {
      this.setState({
        ProductList: resp.data,
      });
    });
  };

  onItemSelection = (e) => {
    let selectedStatedProducts = this.state.selectedStatedProducts;
    let ids = this.state.checkedProductsIds;
    if (!e.changedContent) return;

    if (e.changedValue) {
      const alreadyPushed = selectedStatedProducts.find(
        (element) => element.id == e.changedContent.id
      );
      if (!alreadyPushed) {
        selectedStatedProducts.push(e.changedContent);
        ids.push(e.changedContent.id);
      }
    } else {
      selectedStatedProducts = selectedStatedProducts.filter((entity) => {
        return entity.id !== e.changedContent.id;
      });

      ids = selectedStatedProducts.map((s) => s.id);
    }
    this.setState({ checkedProductsIds: ids });

    this.setState({
      selectedStatedProducts: selectedStatedProducts,
    });
  };

  onAllItemSelection = (selectedProducts, flag) => {
    let {selectedStatedProducts} = this.state;
    let ids=[];
    if(flag){
      selectedProducts.map((prod)=>{
        selectedStatedProducts = selectedStatedProducts.filter((entity) => {
          return entity.id !== prod.id;
        })
      });
    }else{
      selectedProducts.map((prod)=>{
          if (prod) {
            const alreadyPushed = selectedStatedProducts.find(
              (element) => element.id == prod.id
            );
            if (!alreadyPushed) {
              selectedStatedProducts.push(prod);
            } }
          } );
    }
    ids = selectedStatedProducts.map((s) => s.id);
    this.setState({
      selectedStatedProducts: selectedStatedProducts,
      checkedProductsIds: ids,
       });
  }

  onPageChange = (e) => {
    const { configOption } = this.state;
    this.setState(
      { configOption: update(configOption, { page: { $set: e.page } }) },
      this.fetchUsers
    );
  };

  onSearchClicked = () => {
    // always fetch the first page while searching.
    // You never know if it has got more than one page data or not.
    this.onPageChange({ page: 0 });
  };

  onSearchTextChange = (event) => {
    const { configOption } = this.state;
    this.setState({
      configOption: update(configOption, {
        search: { $set: event.target.value },
      }),
    });
  };

  resetSearchConfig = () => {
    this.setState({ configOption: {} });
  };

  onModalClose = () => {
    this.resetSearchConfig();
    const { onDismiss } = this.props;
    if (onDismiss) onDismiss();
    this.setState({
      selectedStatedProducts: [],
      checkedProductsIds: [],
    });
  };

  render() {
    const { 
      ProductList,
      selectedStatedProducts,
      selectedProductsIndexes,
      checkedProductsIds, } = this.state;
    const { onSave, show, selectionType } = this.props;
    const { COLUMNS, RESPONSIVE_COLUMNS } = this.Constants;

    return (
      <Modal
        open={show}
        onMount={this.fetchUsers}
        onClose={this.onModalClose}
        centered={true}
        size="large"
      >
        <Modal.Header>List of Products</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row className="common-filter-wrapper ui form">
              <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6}>
                {" "}
                <div class="field">
                  <input
                    placeholder="Search"
                    className="search-bar"
                    onChange={(e) => this.onSearchTextChange(e)}
                  ></input>
                </div>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6}>
                {" "}
                <div class="field">
                  <button
                    className="button-primary"
                    onClick={this.onSearchClicked}
                  >
                    Search
                  </button>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Responsive minWidth={1024}>
                  <div className="table-wrapper-with-pagination mt-2">
                    <Tableview
                      paginate
                      selectionType={selectionType}
                      data={ProductList}
                      columns={COLUMNS}
                      onSelectionChange={this.onItemSelection}
                      onAllSelectionChange={this.onAllItemSelection}
                      onPaginationChange={this.onPageChange}
                      noDataMessage="No product found."
                      checkedIndexes={selectedProductsIndexes}
                      checkedIds={checkedProductsIds}
                    />
                  </div>
                </Responsive>

                <Responsive maxWidth={1023}>
                  <CardviewMobile
                    paginate
                    selectionType={selectionType}
                    data={ProductList}
                    columns={RESPONSIVE_COLUMNS}
                    onSelectionChange={this.onItemSelection}
                    onAllSelectionChange={this.onAllItemSelection}
                    onPaginationChange={this.onPageChange}
                    noDataMessage="No product found."
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                className="mt-2"
              >
                <Button
                  className="button-primary m--no-margin-left-im"
                  onClick={() => {
                    onSave(selectedStatedProducts);
                    this.setState({
                      selectedStatedProducts: [],
                      checkedProductsIds: [],
                    });
                  }}
                >
                  Save
                </Button>
                <Button className="button-basic" onClick={this.onModalClose}>
                  Close
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

ProductListing.propTypes = {
  /** Callback which is fired when Popup is closed. */
  onDismiss: PropTypes.func.isRequired,
  /** Callback which is fired when `resend` button is clicked. */
  onSave: PropTypes.func.isRequired,
  /** Should the popup be displayed */
  show: PropTypes.bool.isRequired,
  /** single-select = radio buttons, multi-select = checkboxes */
  selectionType: PropTypes.oneOf(["single-select", "multi-select"]),
  /** Perspective: */
  entity: PropTypes.oneOf(["ProductItem", "ItemProduct"]),
  /** */
  entityId: PropTypes.number,
};

ProductListing.defaultProps = {
  selectionType: "multi-select",
};

export default ProductListing;
