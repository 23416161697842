import { parsePhoneNumberFromString } from "libphonenumber-js";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import InputField from "./input-field";

class PhoneField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isError: false,
      number: "",
    };
  }

  onChange = (e, data) => {
    const { country, onChange } = this.props;
    onChange(e, data);
    //  not needed any more as per basecamp todo:
    //  https://3.basecamp.com/3953713/buckets/19656542/todos/3417617649
    // if (!isEmpty(data.value)) {
    //   if (!country) {
    //     const errorMsg = "Please select a country first";
    //     this.setErrorAndNotify(e, data, errorMsg, onChange);
    //   } else {
    //     const phoneNum = parsePhoneNumberFromString(data.value, country);
    //     if (!phoneNum) {
    //       const errorMsg = "Invalid phone number";
    //       this.setErrorAndNotify(e, data, errorMsg, onChange);
    //     } else if (phoneNum.country === country && phoneNum.isValid()) {
    //       this.setState({ error: null, isError: false }, () => {
    //         onChange(e, data);
    //       });
    //     } else {
    //       const errorMsg = "Phone number is not valid for the selected country";
    //       this.setErrorAndNotify(e, data, errorMsg, onChange);
    //     }
    //   }
    // } else {
    //   this.setState({ error: null, isError: false }, () => {
    //     onChange(e, data);
    //   });
    // }
  };

  setErrorAndNotify = (e, data, errorMsg, onChange) => {
    this.setState({ error: errorMsg, isError: true }, () => {
      onChange(e, data);
    });
  };

  render() {
    const consolidatedErrorMsg = this.state.error || this.props.errorMsg;
    const consolidatedIsError = this.state.isError || this.props.isError;
    const modifiedProps = {
      ...this.props,
      isError: consolidatedIsError,
      errorMsg: consolidatedErrorMsg,
      onChange: this.onChange,
    };
    return <InputField {...modifiedProps} maxlength="30" />;
  }
}

PhoneField.propTypes = {
  /** Used to validate the phone number against a country standard */
  country: PropTypes.string.isRequired,
  /** Message which will be displayed in case of error. */
  errorMsg: PropTypes.string,
  /** If true, will change the color of field to Red and will display ErrorMsg. */
  isError: PropTypes.bool,
  /** Will show red asteric in the label text */
  isRequired: PropTypes.bool,
  /** Label text */
  label: PropTypes.string,
  /** Will be passed in OnChange callback */
  name: PropTypes.string.isRequired,
  /** Callback which will be fired in case of any change in value */
  onChange: PropTypes.func.isRequired,
  /** Value to display in input field */
  value: PropTypes.string,
  /** Width of the input field */
  width: PropTypes.string,
  /** id of the input field */
  id: PropTypes.string,
  /** type of the input field */
  type: PropTypes.string,
  /** input field disabled*/
  disabled: PropTypes.bool,
};

export default PhoneField;
