import Axios from "axios";
import Config from "@api/common/config"
import Constants from "@/config/constants";
import Actions from "@/store/actions";
import LocalStore from "@/store/persistence/local-storage";
import { checkIfSupportEnabledForTheUser } from "../support";

//check the validity of the token / load the user object
function getUserObject(token) {
  return Axios.get(Constants.App.URL_LOGGEDIN_USER, {
    headers: { Authorization: token },
  }).then(resp => {
    LocalStore.setValue('uid', resp.data.principal.id)
    return resp;
  })
}

//logout
function logout(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.USER.USER_LOGOUT,
      payload
    });
    dispatch({
      type: Actions.Token.CLEAR_ALL,
    });
  };
}

function close2FaPopup() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.USER.USER_CLOSE_POPUP,
    });
  };
}

function login(
  username,
  password,
  code,
  isLoginScreen,
  type,
  timeout,
  addTrustedDevice
) {
  return (dispatch, getState) => {
    //state is logging in
    dispatch({
      type: Actions.USER.USER_LOGGING_IN,
    });
    let isNewBrowser = true;
    if (getCookie("isNewBrowser") && getCookie("username") === username) {
      isNewBrowser = false;
    }
    const returnTo = LocalStore.getValue("returnTo");
    Axios.post(Constants.App.URL_LOGIN_EMAIL, {
      usernameOrEmail: username,
      password,
      code,
      isLoginScreen,
      type,
      isNewBrowser,
      returnTo,
    })
      .then((resp) => {
        const payload = resp.data;

        if (payload === "TWO_FACTOR_AUTH_REQUIRED") {
          dispatch({
            type: Actions.USER.TWO_FACTOR_AUTH_REQUIRED,
            data: { username, password },
          });
        } else if (payload === "INVALID_2FA_CODE") {
          dispatch({
            type: Actions.USER.INVALID_2FA_CODE,
            data: { username, password },
          });

          // if we need to check something
        } else {
          payload.associatedNdaStatus === "EXPIRED"?
            LocalStore.setValue("NDAExpired", true):
            LocalStore.setValue("NDAExpired", false)

          const accessToken = payload.tokenType + " " + payload.accessToken;
          // redirect to return to page, zendesk helpcenter

          if (payload.returnTo && payload.returnTo.length > 0) {
            if (payload.returnTo.startsWith("http")) {
              payload.showDashboard = false;
              // when user back to groq portal it should be signed in.
              dispatch({
                type: Actions.USER.USER_DATA_SUCCESS,
                payload: payload,
              });
              LocalStore.setValue("loggedInToHelpDesk", true);
              window.location.href = payload.returnTo;
              return;
            } else {
              dispatch({
                type: Actions.USER.DISABLED_HELPDESK_NOTIF,
                payload: JSON.parse(payload.returnTo).error,
              });
            }
          }

          getUserObject(accessToken)
            .then((resp) => {
              if (isNewBrowser && addTrustedDevice) {
                const d = new Date();
                d.setTime(d.getTime() + timeout * 24 * 60 * 60 * 1000);
                const expires = "expires=" + d.toGMTString();
                document.cookie = `username=${username};expires='+${expires}+';path=/`;
                document.cookie = `isNewBrowser=${false};expires='+${expires}+';path=/`;
                document.cookie = `userAgent=${navigator.userAgent};expires='+${expires}+';path=/`;
              }
              LocalStore.setValue("routeFrom", "login")
              dispatch({
                type: Actions.USER.USER_DATA_SUCCESS,
                payload: payload,
              });
              dispatch({
                type: Actions.USER.USER_LOGIN,
                payload: resp.data,
              });
              dispatch(checkIfSupportEnabledForTheUser());
              dispatch(Config.loadApplicationConfig(accessToken));
            })
            .catch((e) => {
              dispatch({
                type: Actions.USER.USER_DATA_FAILURE,
                payload: e,
              });
            });
        }
      })
      .catch((e) => {
        if (e.response.data.message === "Bad credentials") {
          e.response.data.message = "Invalid username or password";
        } else {
          e.response.data.message = e.response.data.message;
        }
        dispatch({
          type: Actions.USER.USER_DATA_FAILURE,
          payload: e.response.data,
        });
      });
  };
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function loginWithCode(username, password, code, type, isNewBrowser) {
  return (dispatch, getState) => {
    //state is logging in
    dispatch({
      type: Actions.USER.USER_LOGGING_IN,
    });
    const returnTo = LocalStore.getValue("returnTo");
    Axios.post(Constants.App.URL_LOGIN_EMAIL, {
      usernameOrEmail: username,
      password,
      code,
      type,
      isNewBrowser,
      returnTo,
    })
      .then((resp) => {
        const payload = resp.data;
        // redirect to return to page, zendesk helpcenter
        if (payload.returnTo && payload.returnTo.length > 0) {
          payload.showDashboard = false;
          dispatch({
            type: Actions.USER.USER_DATA_SUCCESS,
            payload: payload,
          });

          LocalStore.setValue("loggedInToHelpDesk", true);
          window.location.href = payload.returnTo;
          return;
        }
        dispatch({
          type: Actions.USER.USER_DATA_SUCCESS,
          payload: payload,
        });
        const accessToken = payload.tokenType + " " + payload.accessToken;

        getUserObject(accessToken)
          .then((resp) => {
            dispatch({
              type: Actions.USER.USER_LOGIN,
              payload: resp.data,
            });
            dispatch(checkIfSupportEnabledForTheUser());
            dispatch(Config.loadApplicationConfig(accessToken));
          })
          .catch((e) => {
            dispatch({
              type: Actions.USER.USER_DATA_FAILURE,
              payload: e.response.data,
            });
          });
      })
      .catch((e) => {
        dispatch({
          type: Actions.USER.USER_DATA_FAILURE,
          payload: e.response.data,
        });
      });
  };
}
function receiveIdToken(idToken) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.Token.RECEIVE_ID_TOKEN,
      payload: { idToken },
    });

    const returnTo = LocalStore.getValue("returnTo");
    let params = {
      token: idToken,
      returnTo,
    };

    Axios.get(Constants.App.URL_SSO_VERIFICATION, {
      params,
    })
      .then((resp) => {
        const payload = resp.data;
        const { tokenType, accessToken, returnTo, supportEnabled } = payload;
        
        let showErrDialog = false;
        // redirect to return to page, zendesk helpcenter
        if (payload.returnTo && payload.returnTo.length > 0) {
          if (payload.returnTo.startsWith("http")) {
            payload.showDashboard = false;

            if (accessToken != null) {
              dispatch({
                type: Actions.USER.USER_DATA_SUCCESS,
                payload: payload,
              });
            }

            if (supportEnabled) {
              LocalStore.setValue("loggedInToHelpDesk", true);
            }

            window.location.href = returnTo;
            return;
          } else {
            showErrDialog = true;
          }
        }
        if (accessToken != null) {
          const accessTokenObj = tokenType + " " + accessToken;
          dispatch({
            type: Actions.USER.USER_DATA_SUCCESS,
            payload: payload,
          });
          getUserObject(accessTokenObj)
            .then((resp) => {
              dispatch({
                type: Actions.USER.USER_LOGIN,
                payload: resp.data,
              });

              dispatch(checkIfSupportEnabledForTheUser());
              dispatch(Config.loadApplicationConfig(accessTokenObj));

              if (showErrDialog) {
                dispatch({
                  type: Actions.Config.SHOW_HIDE_ERROR_DIALOG,
                  payload: { state: true, errMsg: JSON.parse(payload.returnTo).error }
                });
              }
            })
            .catch((e) => {
              dispatch({
                type: Actions.USER.USER_DATA_FAILURE,
                payload: e.response.data,
              });
            });
        }
      })
      .catch((e) => {
        dispatch({
          type: Actions.Token.OKTA_USER_DISABLED,
          payload: e.response.data.message,
        });
        dispatch({
          type: Actions.USER.USER_DATA_FAILURE,
          payload: e.response.data,
        });
      });
  };
}

function oAuthLogin(type, { access_token, id_token }) {
  return (dispatch, getState) => {
    //state is logging in
    dispatch({
      type: Actions.USER.USER_LOGGING_IN,
    });
    //post the code
    Axios.post(Constants.App.URL_GOOGLE_AUTH_CODE, {
      // code: code
      access_token,
      id_token,
    })
      .then((resp) => {
        const payload = resp.data;

        dispatch({
          type: Actions.USER.USER_DATA_SUCCESS,
          payload: payload,
        });

        const accessToken = payload.tokenType + " " + payload.accessToken;

        getUserObject(accessToken)
          .then((resp) => {
            dispatch({
              type: Actions.USER.USER_LOGIN,
              payload: resp.data,
            });

            dispatch(checkIfSupportEnabledForTheUser());
            dispatch(Config.loadApplicationConfig(accessToken));
          })
          .catch((e) => {
            dispatch({
              type: Actions.USER.USER_DATA_FAILURE,
              payload: e.response.data,
            });
          });
      })
      .catch((e) => {
        dispatch({
          type: Actions.USER.USER_DATA_FAILURE,
          payload: e.response.data,
        });
      });
  };
}
function gettingOktaToken() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.Token.GETTING_OKTA_TOKEN,
    });
  };
}
function recievedOktaToken() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.Token.RECIEVED_OKTA_TOKEN,
    });
  };
}

const unsubscribeUser = (id) => async (dispatch) => {
  dispatch({
    type: Actions.Token.USER_UNSUBSCRIBE_INITIATE,
  });
  try {
    const response = await Axios.get(`${Constants.App.UNSUBSCRIBE_URL}/${id}`);
    dispatch({
      type: Actions.Token.USER_UNSUBSCRIBE_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: Actions.Token.USER_UNSUBSCRIBE_FAILURE,
      payload: e.response.data.message,
    });
  }
};

const reloadUserObject = () => {
  return (dispatch, getState) => {
    console.log('getuserobject', LocalStore.getValue("token"))
    getUserObject(LocalStore.getValue("token")).then((resp) => {
      dispatch({
        type: Actions.USER.USER_LOGIN,
        payload: resp.data,
      });
    })
    .catch((e) => {
      dispatch({
        type: Actions.USER.USER_DATA_FAILURE,
        payload: e,
      });
    });
  }
}

export default {
  getUserObject,
  logout,
  login,
  oAuthLogin,
  loginWithCode,
  receiveIdToken,
  close2FaPopup,
  gettingOktaToken,
  recievedOktaToken,
  unsubscribeUser,
  reloadUserObject,
};
