import React from "react";
import PageTemplate from "../../common/page";
// import MainPage from "./pages/main.js";
import AddVideoFolder from "./pages/add";
import EditVideoFolder from "./pages/edit";
import UploadVideos from "./pages/upload";
import MainPage from "./pages/list";
import ViewPage from "./pages/view";
// import BriefcaseLog from "./pages/briefcase-log";
// import DownloadLog from "./pages/download-log";
// import PackageList from "./pages/package-list";

const MENU_ITEMS = {
  Videos: [
    {
      name: "Videos",
      relativePath: "/",
      exact: true,
      subPageComponent: MainPage,
    },
    {
      name: "Update Video Directory",
      relativePath: "/list/edit/:id/:videoId",
      exact: true,
      hide: true,
      subPageComponent: EditVideoFolder,
    },
    {
      name: "Update Video Directory",
      relativePath: "/list/edit/:id",
      exact: true,
      hide: true,
      subPageComponent: EditVideoFolder,
    },
    {
      name: "Add List",
      relativePath: "/add/list",
      hide: true,
      subPageComponent: AddVideoFolder,
    },
    {
      name: "Upload Video",
      relativePath: "/upload/:id",
      exact: true,
      hide: true,
      subPageComponent: UploadVideos,
    },
    {
      name: "View Video",
      relativePath: "/view/:id",
      exact: true,
      hide: true,
      subPageComponent: ViewPage,
    },
  ],
};

const Videos = ({ baseUrl }) => {
  return <PageTemplate baseUrl={baseUrl} menuItems={MENU_ITEMS} />;
};

export default Videos;

function AccessReport() {
  return <div>Access Videos</div>;
}

function BriefcaseLog() {
  return <div> List Items</div>;
}

function DownloadLog() {
  return <div>Permissions</div>;
}
function EditPage() {
  return <div> Edit Page will be shown here</div>;
}
