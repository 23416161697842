import React, { useEffect, useState } from "react";
import {
  Divider,
  Segment,
  Responsive,
  Accordion,
  Icon,
} from "semantic-ui-react";
import Tableview from "@/common/tableview";
import { Cardview } from "@/common/cardview";
import { dateToPacific } from "@/utils/dateTime.js";
import { getArticles } from "../../../api/zendesk";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {redirectToHelpdeskURL} from "../../../common/utils/utils";
import Actions from "../../../store/actions";

const COLUMNS = [
  {
    name: "Subject",
    key: "textToDisplay",
    showTooltip: true,
    width: "7",
  },
  {
    name: "Updated On",
    key: "updatedOn",
    showTooltip: false,
  },
  {
    name: "Actions",
    key: "action",
    width: "2",
  },
];

export default () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.zendeskReducer);
  const loading = useSelector((state) => state.zendeskReducer.loading);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    dispatch(getArticles(0));
  }, []);
  const redirectToArticle = (url) => {
    return () => {
      dispatch({
        type: Actions.Config.SHOW_HIDE_LOADER,
        payload: true
      });
      redirectToHelpdeskURL(url).catch((error) => {
        dispatch({
          type: Actions.Config.SHOW_HIDE_ERROR_DIALOG,
          payload: { state: true, errMsg: error.response.data.error }
        });
        dispatch({
          type: Actions.Config.SHOW_HIDE_LOADER,
          payload: false
        });
      })
    }
  }
  const transformData = (data) => {
    let transformedData = Object.assign({}, data);
    let content = [];
    if (data) {
      transformedData.content.map((el) => {
        let row = Object.assign({}, el);
        row.textToDisplay = (
          <a href="javascript:void(0)" onClick={redirectToArticle(el.url)}>
            {row.textToDisplay}
          </a>
        );
        row.updatedOn = (
          <>{dateToPacific(el.updatedOn, "MM/DD/YYYY hh:mm a")}</>
        );
        row.action = (
          <a href="javascript:void(0)" onClick={redirectToArticle(el.url)}>
            <span
              className="icon-external-link2"
              style={{
                margin: "3px",
              }}
              title="Open link"
              name="Open"
            />
          </a>
        );
        content.push(row);
      });
    }
    transformedData.content = content;
    return transformedData;
  };

  const normalizedData =
    !_.isEmpty(store.articles) && transformData(store.articles);
  return (
    <Segment className="dashboard-card">
      <Responsive minWidth={1024}>
        <div className="side-marker" />
        <div className="header">Top Articles</div>

        <div className="table-wrapper">
          <Tableview
            data={normalizedData}
            columns={COLUMNS}
            loading={loading}
            
          />
        </div>

        {normalizedData && normalizedData.content && (
          <React.Fragment>
            <Divider />
            <em>Showing top {normalizedData.content.length} records</em>
          </React.Fragment>
        )}
      </Responsive>
      <Responsive maxWidth={1023}>
        <Accordion>
          <Accordion.Title onClick={(e) => setIsCollapsed(!isCollapsed)}>
            <Icon
              name={!isCollapsed ? "caret right" : "caret down"}
              color="blue"
            />
            <div className="side-marker" />
            <div className="header">Top Articles</div>
          </Accordion.Title>
          <Accordion.Content active={isCollapsed}>
            <Cardview
              data={normalizedData}
              columns={COLUMNS}
              loading={loading}
            />

            {normalizedData && normalizedData.content && (
              <React.Fragment>
                <Divider />
                <em>Showing top {normalizedData.content.length} records</em>
              </React.Fragment>
            )}
          </Accordion.Content>
        </Accordion>
      </Responsive>
    </Segment>
  );
};
