import Api from "@/api";
import { InputField } from "@/common/Form";
import { removeEmptyKeys } from "@/common/utils/utils";
import Constants from "@/config/constants";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Form, Grid, Segment, Confirm, GridColumn, Checkbox, Divider, Popup, GridRow } from "semantic-ui-react";
import { validate } from "validate.js";
import { normalValidation } from "./../../../../validation";
import { DropdownField } from "@/common/Form";

class EditUserTypes extends React.Component {
  permissions = {
    WebsiloNonNdaCustomer: [
      { label: "Read Access to Items", checked: false },
      { label: "Write Access to Items", checked: false },
      { label: "Read Access to Packages", checked: false },
      { label: "Write Access to Packages", checked: false },
      { label: "Read Access to Videos", checked: false },
      { label: "Write Access to Videos", checked: false },
      { label: "Send Briefcase", checked: false },
      { label: "Read other Briefcase Inboxes", checked: false },
      { label: "Read Users", checked: false },
      { label: "Write Users", checked: false, tooltip: "can create and update user information" },
      { label: "Read Groups", checked: false },
      { label: "Write Groups", checked: false },
      { label: "Read NDA", checked: false },
      { label: "Write NDA", checked: false, tooltip: "can create, update and sign NDA form" },
      { label: "Modify System Settings", checked: false },
      { label: "Read System Settings", checked: false },
    ],
    WebsiloPublicNonNdaCustomer: [
      { label: "Read Access to Items", checked: true },
      { label: "Write Access to Items", checked: false },
      { label: "Read Access to Packages", checked: false },
      { label: "Write Access to Packages", checked: false },
      { label: "Read Access to Videos", checked: true },
      { label: "Write Access to Videos", checked: false },
      { label: "Send Briefcase", checked: false },
      { label: "Read other Briefcase Inboxes", checked: false },
      { label: "Read Users", checked: false },
      { label: "Write Users", checked: false, tooltip: "can create and update user information" },
      { label: "Read Groups", checked: false },
      { label: "Write Groups", checked: false },
      { label: "Read NDA", checked: false },
      { label: "Write NDA", checked: false, tooltip: "can create, update and sign NDA form" },
      { label: "Modify System Settings", checked: false },
      { label: "Read System Settings", checked: false },
    ],
    WebsiloEmployee: [
      { label: "Read Access to Items", checked: true },
      { label: "Write Access to Items", checked: false },
      { label: "Read Access to Packages", checked: false },
      { label: "Write Access to Packages", checked: false },
      { label: "Read Access to Videos", checked: true },
      { label: "Write Access to Videos", checked: false },
      { label: "Send Briefcase", checked: true },
      { label: "Read other Briefcase Inboxes", checked: false },
      { label: "Read Users", checked: true },
      { label: "Write Users", checked: false, tooltip: "can create and update user information" },
      { label: "Read Groups", checked: true },
      { label: "Write Groups", checked: false },
      { label: "Read NDA", checked: false },
      { label: "Write NDA", checked: false, tooltip: "can create, update and sign NDA form" },
      { label: "Modify System Settings", checked: false },
      { label: "Read System Settings", checked: false },
    ],
    WebsiloCustomer: [
      { label: "Read Access to Items", checked: true },
      { label: "Write Access to Items", checked: false },
      { label: "Read Access to Packages", checked: false },
      { label: "Write Access to Packages", checked: false },
      { label: "Read Access to Videos", checked: true },
      { label: "Write Access to Videos", checked: false },
      { label: "Send Briefcase", checked: true },
      { label: "Read other Briefcase Inboxes", checked: false },
      { label: "Read Users", checked: false },
      { label: "Write Users", checked: false, tooltip: "can create and update user information" },
      { label: "Read Groups", checked: false },
      { label: "Write Groups", checked: false },
      { label: "Read NDA", checked: false },
      { label: "Write NDA", checked: false, tooltip: "can create, update and sign NDA form" },
      { label: "Modify System Settings", checked: false },
      { label: "Read System Settings", checked: false },
    ],
    WebsiloAdmin: [
      { label: "Read Access to Items", checked: true },
      { label: "Write Access to Items", checked: true },
      { label: "Read Access to Packages", checked: true }, ,
      { label: "Write Access to Packages", checked: true },
      { label: "Read Access to Videos", checked: true },
      { label: "Write Access to Videos", checked: true },
      { label: "Send Briefcase", checked: true },
      { label: "Read other Briefcase Inboxes", checked: true },
      { label: "Read Users", checked: true },
      { label: "Write Users", checked: true },
      { label: "Read Groups", checked: true },
      { label: "Write Groups", checked: true },
      { label: "Read NDA", checked: true },
      { label: "Write NDA", checked: true, tooltip: "can create, update and sign NDA form" },
      { label: "Modify System Settings", checked: false },
      { label: "Read System Settings", checked: false },
    ],
    WebsiloAccountApprover: [
      { label: "Read Access to Items", checked: true },
      { label: "Write Access to Items", checked: false },
      { label: "Read Access to Packages", checked: false },
      { label: "Write Access to Packages", checked: false },
      { label: "Read Access to Videos", checked: true },
      { label: "Write Access to Videos", checked: false },
      { label: "Send Briefcase", checked: true },
      { label: "Read other Briefcase Inboxes", checked: false },
      { label: "Read Users", checked: true },
      { label: "Write Users", checked: true },
      { label: "Read Groups", checked: true },
      { label: "Write Groups", checked: true },
      { label: "Read NDA", checked: true },
      { label: "Write NDA", checked: true, tooltip: "can create, update and sign NDA form" },
      { label: "Modify System Settings", checked: false },
      { label: "Read System Settings", checked: false },
    ],
    WebsiloSysadmin: [
      { label: "Read Access to Items", checked: true },
      { label: "Write Access to Items", checked: true },
      { label: "Read Access to Packages", checked: true },
      { label: "Write Access to Packages", checked: true },
      { label: "Read Access to Videos", checked: true },
      { label: "Write Access to Videos", checked: true },
      { label: "Send Briefcase", checked: true },
      { label: "Read other Briefcase Inboxes", checked: true },
      { label: "Read Users", checked: true },
      { label: "Write Users", checked: true },
      { label: "Read Groups", checked: true },
      { label: "Write Groups", checked: true },
      { label: "Read NDA", checked: true },
      { label: "Write NDA", checked: true, tooltip: "can create, update and sign NDA form" },
      { label: "Modify System Settings", checked: true },
      { label: "Read System Settings", checked: true },
    ],
  };
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      name: null,
      description: null,
      accessRole: null,
      createdBy: null,
      modifiedBy: null,
      creationDate: null,
      modificationDate: null,
      loading: false,
      validationMsg: {},
      showError: false,
      errorMsg: "",
      msgSpanText: "",
      msgSpanColor: "black",
      itemLoadXHR: false,
      cancelButton: "Cancel",
      popupWarning: false,
      accessRoleOptions: [
        {key: 1, text:"websilo-sysadmin", value:"websilo-sysadmin"},
        {key: 2, text:"websilo-employee", value:"websilo-employee"},
        {key: 3, text:"websilo-account-approver", value:"websilo-account-approver"},
        {key: 4, text:"websilo-admin", value:"websilo-admin"},
        {key: 5, text:"websilo-non-nda-customer", value:"websilo-non-nda-customer"},
        {key: 6, text:"websilo-public-non-nda-customer", value:"websilo-public-non-nda-customer"},
        {key: 7, text:"websilo-customer", value:"websilo-customer"},

      ],
    };
  }

  componentDidMount() {

    const { id } = this.props.match.params;

    if (id) {
      let params = {
        params: {
          id
        }
      };
      Api.AxiosInstance.getInstance()
        .get(Constants.App.URL_ADMIN_USER_TYPE_SINGLE, params)
        .then(resp => {
          let data = resp.data;
          this.setState({
            id: data.id,
            name: data.name,
            description: data.description,
            accessRole: data.accessRole,
            createdBy: data.createdBy,
            modifiedBy: data.modifiedBy,
            creationDate: data.creationDate,
            modificationDate: data.modificationDate,
            role: this.humanize(data.accessRole),
          });
        });
    }
  }

  save = () => {
    const {
      id,
      name,
      description,
      accessRole,
      createdBy,
      modifiedBy,
      creationDate,
      modificationDate
    } = this.state;
    let data = {
      id,
      name,
      description,
      accessRole,
      createdBy,
      modifiedBy,
      creationDate,
      modificationDate
    };

    return Api.AxiosInstance.getInstance()
      .post(Constants.App.URL_ADMIN_USER_TYPE_SAVE, data)
      .then(resp => {
        let msg = "";
        let color = "black";
        if (resp.data.id > 0) {
          msg = "User Type updated";
          color = "green";
        } else {
          msg = "Request failed, Please try later.";
          color = "red";
        }

        this.setState({
          msgSpanText: msg,
          msgSpanColor: color,
          loading: false,
          cancelButton: "Back to Listing"
        });
      });
  };

  showpopupWarning = () => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (result) {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red"
      });
      return;
    }
    this.setState(
      {
        popupWarning: true,
      },);
  }

  hidepopupWarning = (cb) => {
    this.setState(
      {
        popupWarning: false,
      },
      () => {
        if (cb instanceof Function) cb();
      }
    );
  };
  handleChange = (e, data) => {
    const value = data.value;
    this.setState({
      [e.target.name]: value
    });
  };
  handleDropDownChange = (e, data) => {
    const value = data.value;
    this.setState({
      accessRole: value
    });
  };
  humanize = (str) => {
    var i, frags = str.split('-');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join("");

  }
  validateForm = event => {
    let result;
    result = validate(removeEmptyKeys(this.state), normalValidation);
    if (!result) {
      this.setState({ validationMsg: {}, loading: true }, () => this.save());
    } else {
      this.setState({
        validationMsg: result,
        msgSpanText:
          "There are some errors with the submission. Please fix and retry.",
        msgSpanColor: "red"
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Segment className="base-segment-wrapper">
          <div className="side-marker" />
          <div className="header">
            User Type Management
            <Link to="/admin/user-types-listing" className="btn-back-main">
              <FontAwesomeIcon icon={faArrowLeft} title="Back" name="back" />
              &nbsp;Back
            </Link>
          </div>
          <div className="empty-height-small" />

          <Form loading={this.state.loading}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Name"
                    errorMsg={this.state.validationMsg.name}
                    isError={this.state.validationMsg.name}
                    name="name"
                    id="name"
                    maxlength="32"
                    value={this.state.name}
                    onChange={this.handleChange}
                    isRequired
                    placeholder="Enter User Type"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <InputField
                    label="Description"
                    errorMsg={this.state.validationMsg.description}
                    isError={this.state.validationMsg.description}
                    name="description"
                    id="description"
                    maxlength="50"
                    value={this.state.description}
                    onChange={this.handleChange}
                    placeholder="Enter User Type Description"
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <DropdownField
                    id="accessRole"
                    errorMsg={this.state.validationMsg.accessRole}
                    isError={this.state.validationMsg.accessRole}
                    label="Access Role"
                    name="accessRole"
                    value={this.state.accessRole}
                    onChange={this.handleDropDownChange}
                    options={this.state.accessRoleOptions}
                    isRequired
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <GridColumn mobile={16} tablet={8} computer={8} >
                  <Grid>
                    <GridRow>
                      {this.state.role && this.permissions[this.state.role].map((x) => (
                        <GridColumn mobile={16} tablet={16} computer={8} style={{ display: "flex" }}>
                          <Checkbox
                            checked={x.checked}
                            toggle
                            label={""}
                            readOnly
                            className=""
                          />
                          <Popup
                            className="grid-tooltip"
                            position="bottom left"
                            content={x.tooltip ? x.tooltip : x.label}
                            trigger={
                              <span style={{ display: "inline-block", marginTop: "-2" }}>{x.label}</span>
                            }
                          />
                        </GridColumn>
                      ))}
                    </GridRow>
                  </Grid>
                </GridColumn>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <input
                    type="submit"
                    value="Save"
                    className="button-primary"
                    onClick={this.showpopupWarning}
                  />
                  <Link to="/admin/user-types-listing">
                    <button className="button-basic">
                      {this.state.cancelButton}
                    </button>
                  </Link>
                  &nbsp;&nbsp;
                  <span id="msgspan" style={{ color: this.state.msgSpanColor }}>
                    {this.state.msgSpanText}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
        <Confirm
          open={this.state.popupWarning}
          onCancel={this.hidepopupWarning}
          onConfirm={() => this.hidepopupWarning(this.validateForm)}
          content="Are you sure you want to update user type?"
        />
      </React.Fragment>
    );
  }
}

export default EditUserTypes;
