import Products from "./_products";
import { connect } from "react-redux";
import Config from "@/config/constants";
import { authoritySelector } from "@/common/selectors";
import ProductConstants from "./constants";

const mapDispatchToProps = dispatch => {
  return {};
};

const baseUrl = ProductConstants.URL_BASE;

const mapStateToProps = state => ({
  authority: authoritySelector(state),
  baseUrl: baseUrl
});

const mappedProducts = connect(mapStateToProps, mapDispatchToProps)(Products);
export default {
  name: "Products",
  configKey: "products_menu_item_title",
  path: baseUrl, // ProductConstants.URL_BROWSER,
  component: mappedProducts,
  isPrivateRoute: true,
  accessRoles: Config.AccessRole.SPECIAL_ROLES
};
