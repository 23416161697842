import React from "react";
import InitiateNDA from "./components/initiate-nda";
import { Segment, Grid, Confirm } from "semantic-ui-react";
import WelcomePopup from "./components/welcome-popup";
import RecentDownloads from "./components/recent-downloads";
import RecentUploads from "./components/recent-uploads";
import ZendeskTickets from "./components/zendesk-tickets";
import Articles from "./components/articles";
import {useDispatch, useSelector} from "react-redux";
import { useState } from "react";
import LocalStore from "../../store/persistence/local-storage";

const NonNdaCustomer = ({ userId , authority}) => {
  const [showNDAExpiredError, setshowNDAExpiredError] = useState(LocalStore.getValue("NDAExpired"));

  const supportEnabled = useSelector(
    (state) => state.common.config.supportEnabled
  );

  const dispatch = useDispatch(); 
  return (
    <Segment basic className="mt-2-im">
      <Grid centered>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={12} largeScreen={12}>
            <InitiateNDA authority={authority}/>
           { authority =="websilo-public-non-nda-customer" && 
           <>
            <RecentDownloads />
            <RecentUploads />
           </>
            }
            {authority =="websilo-public-non-nda-customer" && supportEnabled && (
                <>
                  <ZendeskTickets />
                  <Articles />
                </>
              )
            }
            
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <WelcomePopup value='non-nda-customer-popup' userId={userId} />
      {
      <Confirm 
      open={ showNDAExpiredError}
      size={"small"}
      cancelButton={null}
      onConfirm={()=>{
        LocalStore.setValue("NDAExpired",false);
        setshowNDAExpiredError(LocalStore.getValue("NDAExpired"))}}
      content={"The NDA between Groq and your organization has expired. Please contact portal_admin@groq.com to renew access"}
      />
    }
    </Segment>
  );
};

export default NonNdaCustomer;
