import Axios from "axios";
import Constants from "../../config/constants";
import actions from "../../store/actions";
import LocalStore from "@/store/persistence/local-storage";

export const getTickets = () => async (dispatch) => {
  dispatch({
    type: actions.Zendesk.INITIATE_GET_TICKETS,
  });
  try {
    const token = LocalStore.getValue("token");
    const response = await Axios.get(
      `${Constants.App.API_URL}zendesk/tickets`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    dispatch({
      type: actions.Zendesk.GET_TICKETS_SUCCESS,
      payload: response.data,
    });
  } catch (ex) {
    dispatch({
      type: actions.Zendesk.GET_TICKETS_FAILURE,
    });
  }
};
